import moment from 'moment';

type Duration = {
    seconds: number;
    minutes?: number;
    hours?: number;
    days?: number;
};

export const calculateDuration = (startTime: string, endTime: string) => {
    const startDate = moment(startTime);
    const endDate = moment(endTime);
    const duration = moment.duration(endDate.diff(startDate));

    const durationSeconds = duration.seconds();
    const durationMinutes = duration.minutes();
    const durationHours = duration.hours();
    const durationDays = duration.days();

    const durationObject = { seconds: durationSeconds } as Duration;
    const durationArrayWithUnits = [`${durationSeconds} s`];

    if (durationMinutes > 0) {
        durationObject.minutes = durationMinutes;
        durationArrayWithUnits.unshift(`${durationMinutes} m`);
    }

    if (durationHours > 0) {
        durationObject.hours = durationHours;
        durationArrayWithUnits.unshift(`${durationHours} h`);
    }

    if (durationDays > 0) {
        durationObject.days = durationDays;
        durationArrayWithUnits.unshift(`${durationDays} d`);
    }

    return {
        durationObject,
        durationStringWithUnits: durationArrayWithUnits.join(' '),
    };
};
