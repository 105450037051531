import { Sort } from 'ducks/common/types';
import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import React from 'react';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Column } from '@material-table/core';

export interface ColumnType extends Column<any> {
    field: string;
    onClick?: () => void;
}

const renderColumn = (column: ColumnType, sort?: Sort) => {
    return (
        <TableCell key={column.field}>
            <TableSortLabel
                IconComponent={ArrowDropDown}
                hideSortIcon={column.sorting === false}
                active={sort?.value === column.field}
                direction={sort?.direction}
                onClick={column.onClick}>
                {column.title}
            </TableSortLabel>
        </TableCell>
    );
};

export const renderColumns = (
    columns: Array<ColumnType>,
    sort?: Sort,
    selection?: boolean,
) => (
    <TableHead>
        <TableRow>
            {selection ? <TableCell key="selection" /> : null}
            {columns.map(column => renderColumn(column, sort))}
        </TableRow>
    </TableHead>
);
