import React, { useEffect, useCallback, useMemo } from 'react';
import { TableComponent } from 'components/common';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentHistoryColumns } from './columns';
import PaymentHistoryPagination from './PaymentHistoryPagination';
import { FetchedUserPayment } from 'ducks/paymentHistory/types';
import {
    selectPaymentHistorySort,
    selectPaymentHistoryTableData,
} from 'ducks/paymentHistory';
import {
    fetchUserPaymentHistory,
    resetUserPaymentHistory,
    addPaymentTable,
} from 'ducks/paymentHistory';
import StripeErrorDetails from './StripeErrorDetails';

interface Props {
    userId: number;
    profileId: number;
}

export const PaymentTableContext = React.createContext({
    userId: 0,
    profileId: 0,
});

export const PaymentTable: React.FC<Props> = ({
    userId,
    profileId = 1,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const PaymentTableContextValue = useMemo(
        () => ({ userId, profileId }),
        [userId, profileId],
    );

    const sortSelector = useCallback(
        state => selectPaymentHistorySort(state, userId, profileId),
        [profileId, userId],
    );

    const tableDataSelector = useCallback(
        state => selectPaymentHistoryTableData(state, userId, profileId),
        [profileId, userId],
    );

    const sort = useSelector(sortSelector);
    const columns = getPaymentHistoryColumns(t, dispatch, userId, profileId);
    const tableData = useSelector(tableDataSelector);
    useEffect(() => {
        dispatch(addPaymentTable({ userId, profileId }));
        dispatch(fetchUserPaymentHistory({ userId, profileId }));
        return () => {
            dispatch(resetUserPaymentHistory());
        };
    }, [dispatch, userId, profileId]);

    const getDetails = useCallback(
        ({ rowData }: { rowData: FetchedUserPayment }) => (
            <StripeErrorDetails rowProps={rowData} />
        ),
        [],
    );

    const detailPanel = useMemo(
        () => [
            ({ stripeError }: FetchedUserPayment) => ({
                disabled: !stripeError,
                render: getDetails,
                icon: 'add',
                openIcon: 'remove',
            }),
        ],
        [getDetails],
    );

    return (
        <PaymentTableContext.Provider value={PaymentTableContextValue}>
            <TableComponent
                columns={columns}
                detailPanel={detailPanel}
                pagination={PaymentHistoryPagination}
                data={tableData}
                title={t('usersList.paymentHistory.title')}
                sort={sort}
                options={{
                    emptyRowsWhenPaging: false,
                }}
                hasToolbarSpace={false}
            />
        </PaymentTableContext.Provider>
    );
};
