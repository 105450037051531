import request from 'helpers/request';
import { Company, FetchedRecipient, Invitation } from './types';
import { generateUniqueFileName, getFileFromResponse } from '../../tools/file';
import {
    COMPANIES_EXPORT_URL,
    COMPANIES_URL,
    SERVICES_URL,
    VEHICLES_URL,
} from 'constants/api';
import { prepareFormData } from './mapper';

export const getCompanies = (params: any): Promise<string> =>
    request.get(COMPANIES_URL, { params });

export const getFreeVehicles = (serviceId: number): Promise<string> =>
    request.get(`${VEHICLES_URL}/lld/free?serviceId=${serviceId}`);

export const getCompany = (companyId: number): Promise<string> =>
    request.get(`${COMPANIES_URL}/${companyId}`);

export const postCompany = (params: Company): Promise<string> => {
    const formData = prepareFormData(params);

    return request.post(COMPANIES_URL, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};

export const getInvitations = (params: any): Promise<string> => {
    return request.get('/backoffice/user/invitation', { params });
};

export const assignService = ({
    serviceId,
    companyId,
    domainSubscriptionDuration,
    domainRecognition,
}: {
    serviceId: number;
    companyId: number;
    domainSubscriptionDuration: number;
    domainRecognition: boolean;
}): Promise<string> =>
    request.post(
        `/backoffice/services/${serviceId}/assignTo/${companyId}?domainRecognition=${domainRecognition}&domainSubscriptionDuration=${domainSubscriptionDuration}`,
    );
export const unlinkCompanyService = ({
    serviceId,
}: {
    serviceId: number;
}): Promise<string> =>
    request.post(`${SERVICES_URL}/${serviceId}/unlinkCompany`);

export const postInviteUsers = (params: Invitation): Promise<string> => {
    const formData = new FormData();
    const mappedInvitations = params.invitations.map(inv => {
        const { files, ...invitations } = inv;
        const filenames = files?.map(({ file }: { file: File }) => {
            const newFileName = generateUniqueFileName();
            formData.append('files', file, newFileName);
            return newFileName;
        });
        return { ...invitations, documents: filenames };
    });

    formData.append(
        'request',
        new Blob(
            [JSON.stringify({ ...params, invitations: mappedInvitations })],
            {
                type: 'application/json',
            },
        ),
    );

    return request.post('/backoffice/user/invitation', formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};

export const postImportInviteUsers = ({
    file,
    serviceId,
}: {
    serviceId: number;
    file: FormData;
}): Promise<string> =>
    request.post(
        `/backoffice/user/invitation/import?serviceId=${serviceId}`,
        file,
        {
            headers: {
                'content-type': 'multipart/form-data',
            },
        },
    );

export const exportCompaniesService = (params: any): Promise<any> =>
    request
        .get(COMPANIES_EXPORT_URL, { params })
        .then(response => getFileFromResponse(response));

export const deleteCompanyService = (companyId: number): Promise<any> =>
    request.delete(`${COMPANIES_URL}/${companyId}`);

export const updateCompanyService = (params: Company): Promise<string> => {
    const formData = prepareFormData(params);

    return request.post(`${COMPANIES_URL}/${params.id}`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};

export const anonymizeCompanyService = (companyId: number): Promise<any> =>
    request.patch(`${COMPANIES_URL}/${companyId}/anonymize`);

export const getAllCompanies = (): Promise<string> =>
    request.get(`${COMPANIES_URL}/all?`);

export const getEmailRecipients = (companyId: number): Promise<string> =>
    request.get(`${COMPANIES_URL}/${companyId}/email/recipients`);

export const addEmailRecipientService = ({
    companyId,
    recipients,
}: {
    companyId: number;
    recipients: FetchedRecipient[];
}): Promise<string> =>
    request.post(`${COMPANIES_URL}/${companyId}/email/recipients`, {
        emailRecipients: recipients,
    });

export const deleteEmailRecipientService = ({
    companyId,
    email,
    type,
}: {
    companyId: number;
    email: string;
    type: string;
}): Promise<string> =>
    request.delete(
        `${COMPANIES_URL}/${companyId}/email/recipients?email=${email}&type=${type}`,
    );

export const resendInvitationService = ({
    invitationId,
    vehicleId,
}: {
    invitationId: number;
    vehicleId: number;
}): Promise<string> =>
    request.post(`/backoffice/user/invitation/${invitationId}/resend`, {
        lldVehicleId: vehicleId,
    });

export const deleteInvitationService = ({
    invitationId,
}: {
    invitationId: number;
}): Promise<string> =>
    request.delete(`/backoffice/user/invitation`, {
        params: { invitationId },
    });
