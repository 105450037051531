import React, { useState } from 'react';
import { Prompt, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurrentVehicle,
    selectVehicleHasUnsavedChanges,
    setVehicleHasUnsavedChanges,
    updateVehicle,
} from 'ducks/vehicles/common';
import {
    ButtonComponent,
    FormGrid,
    LinkComponent,
    SelectField,
    TextField,
} from 'components/common';
import { Box, Typography } from '@material-ui/core';
import { RootState } from 'ducks/root';
import { useTranslation } from 'react-i18next';
import {
    AvailableForCompanyUpdateStatus,
    AvailableForServiceUpdateStatus,
    vehicleAvailableStatusMap,
    VehicleStatus,
    VehicleTypes,
} from 'ducks/vehicles/common/types';
import { Form, FormSpy } from 'react-final-form';
import {
    composeValidators,
    isStationIdRequired,
    isValidVehicleAdapterFormat,
    required,
    validateServices,
} from 'tools/validationRules';
import { fetchServicesByCompany } from 'ducks/services';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { FormButtonGroup } from 'components/common/ButtonGroup';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { FormState } from 'final-form';
import { StationTypes } from 'ducks/stations/types';

export const VehicleUpdateForm: React.FC = () => {
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit =
        accessToPages[
            vehicleId ? Permissions.VEHICLE_UPDATE : Permissions.VEHICLE_CREATE
        ];
    const currentVehicle = useSelector(selectCurrentVehicle);
    const isVae = currentVehicle?.type === VehicleTypes.VAE;
    const isBike = currentVehicle?.type === VehicleTypes.BIKE;
    const isScooter = currentVehicle?.type === VehicleTypes.SCOOTER;
    const isBikeOrVae = isBike || isVae;

    const [isServicesDisabled, setIsServicesDisabled] = useState(false);

    React.useEffect(() => {
        if (
            !allowedToEdit ||
            (currentVehicle?.status &&
                !AvailableForServiceUpdateStatus.includes(
                    currentVehicle?.status,
                ))
        ) {
            setIsServicesDisabled(true);
        } else {
            setIsServicesDisabled(false);
        }
    }, [setIsServicesDisabled, currentVehicle, allowedToEdit]);

    React.useEffect(() => {
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    React.useEffect(() => {
        currentVehicle?.companyId &&
            dispatch(
                fetchServicesByCompany({
                    companyId: currentVehicle.companyId,
                    onlyEnabled: false,
                }),
            );
    }, [dispatch, currentVehicle]);

    const { servicesById, serviceIds } = useSelector(
        (state: RootState) => state.service,
    );

    const [newCompanyId, setCompanyId] = React.useState<
        string | number | undefined
    >(currentVehicle?.companyId);

    React.useEffect(() => {
        if (!currentVehicle?.servicesAllIds.length) {
            setCompanyId(undefined);
        }
    }, [currentVehicle]);

    const handleConfirm = React.useCallback(
        ({
            services,
            status,
            model,
            adapter,
            stationType,
        }: {
            services: number[];
            status: VehicleStatus;
            model?: string;
            stationType?: string;
            adapter?: string;
        }) => {
            const isSimple =
                !isBikeOrVae || stationType === StationTypes.Simple;

            const calculatedAdapter = isSimple ? undefined : adapter;
            const calculatedModel = !isBikeOrVae ? undefined : model;

            const updateFields: {
                vehicleId: number | string;
                status: VehicleStatus;
                services: number[];
                lockQrCode?: string;
                model?: string;
                stationType?: string;
                adapter?: string;
            } = {
                vehicleId,
                services,
                status,
                model: calculatedModel,
                adapter: calculatedAdapter,
            };

            if (!isScooter && currentVehicle?.lockQrCode) {
                updateFields.lockQrCode = currentVehicle?.lockQrCode;
            }

            if (!isScooter) {
                updateFields.stationType = stationType;
            }

            if (
                ((currentVehicle?.companyName &&
                    typeof newCompanyId === 'undefined') ||
                    newCompanyId) &&
                services.length === 0
            ) {
                dispatch(
                    openModal({
                        modalType: Modals.CHANGE_CONFIRMATION,
                        modalProps: {
                            handleConfirm: () => {
                                dispatch(updateVehicle(updateFields));
                                dispatch(closeModal());
                            },
                            message: 'vehicles.emptyServicesError',
                        },
                    }),
                );
                return;
            }

            dispatch(updateVehicle(updateFields));
        },
        [
            isBikeOrVae,
            currentVehicle?.companyName,
            currentVehicle?.lockQrCode,
            newCompanyId,
            dispatch,
            isScooter,
            vehicleId,
        ],
    );

    const servicesValidations = React.useCallback(
        (values: number[]) => validateServices(values, servicesById),
        [servicesById],
    );

    const statusOptions = React.useMemo(
        () =>
            (currentVehicle?.status &&
                vehicleAvailableStatusMap[currentVehicle.status].map(
                    (status: VehicleStatus) => ({
                        label: t(`scooterStatuses.${status}`),
                        value: status,
                    }),
                )) || [{ label: '', value: '' }],
        [currentVehicle, t],
    );
    const serviceOptions = React.useMemo(
        () =>
            serviceIds &&
            serviceIds.map(id => ({
                value: id,
                label: servicesById[id].name,
            })),
        [serviceIds, servicesById],
    );

    React.useEffect(() => {
        return () => {
            dispatch(setVehicleHasUnsavedChanges(false));
        };
    }, [dispatch]);

    const scooterHasUnsavedChanges = useSelector(
        selectVehicleHasUnsavedChanges,
    );

    const initialValues = React.useMemo(
        () => ({
            ...currentVehicle,
            services: currentVehicle?.servicesAllIds,
            stationType: currentVehicle?.stationType || StationTypes.Simple,
        }),
        [currentVehicle],
    );
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const companyOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );

    React.useEffect(() => {
        newCompanyId &&
            dispatch(
                fetchServicesByCompany({
                    companyId: newCompanyId,
                    onlyEnabled: false,
                }),
            );
    }, [newCompanyId, dispatch]);

    useNotificationOnPageLeave(scooterHasUnsavedChanges);

    const resetField = React.useCallback((args, state, { changeValue }) => {
        changeValue(state, args[0].field, () => []);
    }, []);

    const mutators = React.useMemo(
        () => ({
            resetField,
        }),
        [resetField],
    );

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            modifiedSinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setVehicleHasUnsavedChanges(
                    submitSucceeded ? modifiedSinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    const stationTypeOptions = React.useMemo(
        () => [
            {
                label: t(StationTypes.Simple),
                value: StationTypes.Simple,
            },
            {
                label: t(StationTypes.DUCKT),
                value: StationTypes.DUCKT,
            },
            {
                label: t(StationTypes.KNOTT),
                value: StationTypes.KNOTT,
            },
        ],
        [t],
    );

    return (
        <>
            <Prompt
                when={scooterHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={handleConfirm}
                initialValues={initialValues}
                mutators={mutators}
                render={({ handleSubmit, valid, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                modifiedSinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        {currentVehicle && (
                            <FormGrid columnAmount={2}>
                                <Box>
                                    <Typography variant="h3">
                                        {t('vehicles.id')}
                                    </Typography>
                                    <Typography>{currentVehicle.id}</Typography>
                                </Box>
                                {!isScooter && (
                                    <Box>
                                        <Typography variant="h3">
                                            {t('vehicles.type')}
                                        </Typography>
                                        <Typography>
                                            {currentVehicle.type}
                                        </Typography>
                                    </Box>
                                )}
                                <Box>
                                    <Typography variant="h3">
                                        {t('vehicles.serial')}
                                    </Typography>
                                    <Typography>
                                        {currentVehicle.serial}
                                    </Typography>
                                </Box>
                                {isScooter && (
                                    <Box>
                                        <Typography variant="h3">
                                            {t('vehicles.plate')}
                                        </Typography>
                                        <Typography>
                                            {currentVehicle.plate}
                                        </Typography>
                                    </Box>
                                )}
                                {(isVae || isScooter) && (
                                    <Box>
                                        <Typography variant="h3">
                                            {t('vehicles.battery')}
                                        </Typography>
                                        <Typography>
                                            {currentVehicle.batteryLevel}
                                        </Typography>
                                    </Box>
                                )}
                                <Box>
                                    <Typography variant="h3">
                                        {t('vehicles.gps')}
                                    </Typography>
                                    {currentVehicle.lat &&
                                    currentVehicle.lng ? (
                                        <LinkComponent
                                            text={`${currentVehicle.lat}, ${currentVehicle.lng}`}
                                            handleClick={event => {
                                                event.stopPropagation();
                                                dispatch(
                                                    openModal({
                                                        modalType:
                                                            Modals.MAP_WITH_POINT,
                                                        modalProps: {
                                                            position: {
                                                                lat: currentVehicle.lat,
                                                                lng: currentVehicle.lng,
                                                            },
                                                            title: t(
                                                                'vehicles.vehiclePosition',
                                                            ),
                                                        },
                                                    }),
                                                );
                                            }}
                                        />
                                    ) : (
                                        <Typography>-,-</Typography>
                                    )}
                                </Box>
                                <Box>
                                    <Typography variant="h3">
                                        {t('vehicles.totalKilometers')}
                                    </Typography>
                                    <Typography>
                                        {currentVehicle.totalKilometers}
                                    </Typography>
                                </Box>
                                {isScooter && (
                                    <Box>
                                        <Typography variant="h3">
                                            {t('vehicles.totalMinutes')}
                                        </Typography>
                                        <Typography>
                                            {currentVehicle.totalMinutes}
                                        </Typography>
                                    </Box>
                                )}
                                <Box>
                                    <Typography variant="h3">
                                        {t('vehicles.totalOrders')}
                                    </Typography>
                                    <Typography>
                                        {currentVehicle.totalOrders}
                                    </Typography>
                                </Box>
                                {!isScooter && (
                                    <Box>
                                        <Typography variant="h3">
                                            {t('bikes.stationId')}
                                        </Typography>
                                        <Typography>
                                            {currentVehicle.stationId}
                                        </Typography>
                                    </Box>
                                )}
                                {currentVehicle.companyName &&
                                !AvailableForCompanyUpdateStatus.includes(
                                    currentVehicle.status,
                                ) ? (
                                    <Box>
                                        <Typography variant="h3">
                                            {t('vehicles.companyName')}
                                        </Typography>
                                        <Typography>
                                            {currentVehicle.companyName}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <SelectField
                                        name="companyId"
                                        label={t('vehicles.companyName')}
                                        options={companyOptions}
                                        disabled={!allowedToEdit}
                                        handleChange={value => {
                                            setCompanyId(value);
                                            form.mutators.resetField({
                                                field: 'services',
                                            });
                                            setIsServicesDisabled(false);
                                        }}
                                    />
                                )}
                                {currentVehicle.status && (
                                    <SelectField
                                        validators={composeValidators(required)}
                                        name="status"
                                        label={t('vehicles.status')}
                                        options={statusOptions}
                                        disabled={!allowedToEdit}
                                        isRequired
                                    />
                                )}
                                <SelectField
                                    validators={composeValidators(
                                        servicesValidations,
                                    )}
                                    multiple
                                    name="services"
                                    label={t('vehicles.services')}
                                    options={serviceOptions}
                                    disabled={isServicesDisabled}
                                />
                                {isBikeOrVae && (
                                    <TextField
                                        name="model"
                                        label={t('vehicles.model')}
                                    />
                                )}
                                {isBikeOrVae && (
                                    <TextField
                                        name="adapter"
                                        label={t('vehicles.adapter')}
                                        disabled={
                                            values?.stationType ===
                                            StationTypes.Simple
                                        }
                                        validators={composeValidators(
                                            isStationIdRequired,
                                            isValidVehicleAdapterFormat,
                                        )}
                                    />
                                )}

                                {isBikeOrVae && (
                                    <SelectField
                                        validators={required}
                                        name="stationType"
                                        label={t('vehicles.stationType')}
                                        options={stationTypeOptions}
                                        isRequired
                                    />
                                )}
                            </FormGrid>
                        )}
                        <FormButtonGroup>
                            {allowedToEdit && (
                                <ButtonComponent
                                    handleClick={handleSubmit}
                                    text={t('save')}
                                    isDisabled={
                                        !scooterHasUnsavedChanges || !valid
                                    }
                                />
                            )}
                        </FormButtonGroup>
                    </form>
                )}
            />
        </>
    );
};
