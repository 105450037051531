import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    createBOUser,
    createBOUserSuccess,
    createBOUserError,
    fetchBOUsers,
    fetchBOUsersError,
    fetchBOUsersSuccess,
    requestExportBoUsers,
    requestExportBoUsersSuccess,
    fetchBOUser,
    fetchBOUserSuccess,
    fetchBOUserError,
    updateBOUser,
    updateBOUserSuccess,
    updateBOUserError,
    toggleBOUserSuccess,
    toggleBOUserError,
    toggleBOUserEnable,
    updateLeaveDate,
    updateLeaveDateSuccess,
    updateLeaveDateError,
    setPage,
} from './boUsersSlice';
import { BoUserResponse, EditUser, FetchedBoUser, Filters } from './types';
import {
    exportBoUsers,
    getBoUser,
    getUsers,
    postUser,
    updateBoUserLeaveDate,
    updateBoUserService,
    updateBoUserStatus,
} from './service';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import {
    mapFetchedBoUser,
    mapFetchedBoUsers,
    prepareDataForUserCreation,
} from './mapper';
import { Pagination, Sort } from '../common/types';
import { mapFilters, mapPagination, mapSorting } from '../common/mappers';
import { mapFetchedPagination } from '../common/pagination';
import {
    selectFilters,
    selectSort,
    selectPagination,
    selectCurrentBoUser,
} from './selectors';
import { setSort, setFilter } from './index';
import { downloadBlob } from 'tools/file';

function* createUserSaga({ payload }: PayloadAction<EditUser>) {
    try {
        yield call(postUser, {
            ...prepareDataForUserCreation(payload),
        });
        yield put(createBOUserSuccess());
        yield put(push(routes.BO_USERS));
    } catch (error) {
        yield put(createBOUserError({ error: (error as Error)?.message }));
    }
}

function* fetchBoUsersSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: BoUserResponse = yield call(getUsers, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { boUserIds, boUsersById } = mapFetchedBoUsers(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchBOUsersSuccess({
                boUserIds,
                boUsersById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchBOUsersError({ error: (error as Error)?.message }));
    }
}

function* exportBoUsersSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const { file, fileName } = yield call(
            exportBoUsers,
            mapFilters(filters),
        );
        downloadBlob(file, fileName);
        yield put(requestExportBoUsersSuccess());
    } catch (error) {
        yield put(fetchBOUsersError({ error: (error as Error)?.message }));
    }
}

function* fetchBOUserSaga({ payload }: PayloadAction<number>) {
    try {
        const data: FetchedBoUser = yield call(getBoUser, payload);
        const fetchedUser = mapFetchedBoUser(data);
        yield put(fetchBOUserSuccess(fetchedUser));
    } catch (error) {
        yield put(fetchBOUserError({ error: (error as Error)?.message }));
    }
}

function* updateBOUserSaga({ payload }: PayloadAction<EditUser>) {
    try {
        const {
            user: { id },
        } = yield select(selectCurrentBoUser);

        const preparedData = prepareDataForUserCreation(payload);
        yield call(updateBoUserService, id, preparedData);
        yield put(updateBOUserSuccess());
        yield put(push(routes.BO_USERS));
    } catch (error) {
        yield put(updateBOUserError({ error: (error as Error)?.message }));
    }
}

function* toggleBOUserStatusSaga({
    payload,
}: PayloadAction<{ id: number; isEnabled: boolean }>) {
    try {
        yield call(updateBoUserStatus, {
            id: payload.id,
            isEnabled: payload.isEnabled,
        });
        yield put(toggleBOUserSuccess());
        yield put(fetchBOUser(payload.id));
    } catch (error) {
        yield put(toggleBOUserError({ error: (error as Error)?.message }));
    }
}

function* updateLeaveDateSaga({
    payload,
}: PayloadAction<{ id: number; date: string }>) {
    try {
        yield call(updateBoUserLeaveDate, {
            id: payload.id,
            date: payload.date,
        });
        yield put(updateLeaveDateSuccess());
        yield put(fetchBOUser(payload.id));
    } catch (error) {
        yield put(updateLeaveDateError({ error: (error as Error)?.message }));
    }
}

export default function* boUsersSagas() {
    yield takeLatest(createBOUser.type, createUserSaga);
    yield takeLatest(fetchBOUsers.type, fetchBoUsersSaga);
    yield takeLatest(setSort.type, fetchBoUsersSaga);
    yield takeLatest(setFilter.type, fetchBoUsersSaga);
    yield takeLatest(requestExportBoUsers.type, exportBoUsersSaga);
    yield takeLatest(fetchBOUser.type, fetchBOUserSaga);
    yield takeLatest(updateBOUser.type, updateBOUserSaga);
    yield takeLatest(toggleBOUserEnable.type, toggleBOUserStatusSaga);
    yield takeLatest(updateLeaveDate.type, updateLeaveDateSaga);
    yield takeLatest(setPage.type, fetchBoUsersSaga);
}
