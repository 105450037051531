import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapComponent } from 'components/common/Map';
import { Marker } from '@react-google-maps/api';
import { vehicleRoutes } from '../../../constants/common';
import {
    addToSelectedVehicles,
    StringFilterTypes,
    selectFilters,
    selectSelectedVehicles,
    selectVehicleIds,
    selectRandomizedVehicles,
    selectVehiclesBoundCoordinates,
} from 'ducks/vehicles/common';
import { vehicleIconConfig } from './config';
import { Vehicle } from 'ducks/vehicles/common/types';
import { generatePath } from 'react-router';

const containerStyle = {
    height: '70vh',
};

export const VehiclesMap = () => {
    const dispatch = useDispatch();

    const redirectToVehicle = React.useCallback((vehicle: Vehicle) => {
        return window.open(
            generatePath(vehicleRoutes[vehicle.type], {
                vehicleId: vehicle.id,
            }),
            '_blank',
        );
    }, []);

    const vehicleIds = useSelector(selectVehicleIds);
    const randomizedVehicles = useSelector(selectRandomizedVehicles);
    const filters = useSelector(selectFilters);
    const selected = useSelector(selectSelectedVehicles);
    const statusFilter = filters[StringFilterTypes.Status];
    const vehicleCoords = useSelector(selectVehiclesBoundCoordinates);

    const getIcon = React.useCallback(
        ({ status, type, id }: Vehicle) => {
            if (selected.indexOf(id) !== -1) {
                return vehicleIconConfig[type]['selected'];
            } else {
                return vehicleIconConfig[type][status];
            }
        },
        [selected],
    );
    return (
        <MapComponent
            boundCoordinates={vehicleCoords}
            containerStyle={containerStyle}>
            {vehicleIds.map(id => {
                const vehicle = randomizedVehicles[id];
                return (
                    vehicle.lat &&
                    vehicle.lng && (
                        <Marker
                            icon={{
                                url: getIcon(vehicle),
                            }}
                            position={{
                                lat: vehicle.lat,
                                lng: vehicle.lng,
                            }}
                            title={vehicle.serial}
                            key={vehicle.serial}
                            onDblClick={() => redirectToVehicle(vehicle)}
                            onClick={() =>
                                statusFilter &&
                                dispatch(addToSelectedVehicles(id))
                            }
                        />
                    )
                );
            })}
        </MapComponent>
    );
};
