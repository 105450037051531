import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/locks';
import { LockFromList, SortingTypes } from 'ducks/locks/types';
import { Box } from '@material-ui/core';
import { Check, LinkComponent } from 'components/common';
import { VehicleTypes } from 'ducks/vehicles/common/types';
import { routes } from 'constants/common';

const renderCheck = (isDisplayed: boolean) =>
    isDisplayed && (
        <Box textAlign="center">
            <Check />
        </Box>
    );

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('locks.id'),
            field: SortingTypes.ID,
            render: (rowProps: LockFromList) => rowProps.lock.id,
            onClick: () =>
                dispatch(
                    setSort({
                        value: SortingTypes.ID,
                    }),
                ),
        },
        {
            title: t('locks.qrCode'),
            field: 'qrCode',
            render: (rowProps: LockFromList) => rowProps.lock.qrCode,
            sorting: false,
        },
        {
            title: t('locks.vehicle'),
            field: 'vehicle',
            // eslint-disable-next-line react/display-name
            render: (rowProps: LockFromList) => (
                <LinkComponent
                    text={
                        rowProps.vehicle?.serial ? rowProps.vehicle.serial : ''
                    }
                    handleClick={event => {
                        event.stopPropagation();
                        window.open(
                            routes[VehicleTypes.BIKE].replace(
                                ':vehicleId',
                                `${rowProps.vehicle.id}`,
                            ),
                            '_blank',
                        );
                    }}
                />
            ),
            sorting: false,
        },
        {
            title: t('locks.batteryLevel'),
            field: SortingTypes.BatteryLevel,
            render: (rowProps: LockFromList) => rowProps.lock.batteryLevel,
            onClick: () =>
                dispatch(
                    setSort({
                        value: SortingTypes.BatteryLevel,
                    }),
                ),
        },
        {
            title: t('locks.bikeStatus'),
            field: 'bikeStatus',
            render: (rowProps: LockFromList) =>
                rowProps.vehicle?.type === VehicleTypes.BIKE
                    ? rowProps.vehicle?.status
                    : '',
            sorting: false,
        },
        {
            title: t('locks.bikeStation'),
            field: 'bikeStation',
            render: (rowProps: LockFromList) =>
                rowProps.vehicle?.type === VehicleTypes.BIKE
                    ? rowProps.station?.name
                    : '',
            sorting: false,
        },
        {
            title: t('locks.status'),
            field: SortingTypes.LockStatus,
            render: (rowProps: LockFromList) =>
                t(`locks.lockStatus.${rowProps.lock.status}`),
            onClick: () =>
                dispatch(
                    setSort({
                        value: SortingTypes.LockStatus,
                    }),
                ),
        },
        {
            title: t('locks.alarmStatus'),
            field: 'isAlarmActive',
            render: (rowProps: LockFromList) =>
                renderCheck(rowProps.lock.isAlarmActive),
            sorting: false,
        },
        {
            title: t('locks.closeStatus'),
            field: 'isClosed',
            render: (rowProps: LockFromList) =>
                rowProps.lock.isClosed ? t('bikes.closed') : t('bikes.open'),
            sorting: false,
        },
        {
            title: t('locks.lastDate'),
            field: SortingTypes.LastDate,
            render: (rowProps: LockFromList) =>
                rowProps.lock.lastSync
                    ? new Date(rowProps.lock.lastSync).toLocaleString()
                    : '',
            onClick: () =>
                dispatch(
                    setSort({
                        value: SortingTypes.LastDate,
                    }),
                ),
        },
    ];
};
