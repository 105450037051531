import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { TripsList } from './TripList';
import {
    fetchStatistics,
    fetchTripsMaxDuration,
    selectFilters,
    setServiceFilter,
    fetchTrips,
} from 'ducks/trips';
import { NumberOfRented } from './NumberOfRented';
import { NumberByStatus } from './NumberByStatus';
import { TripsOverdueList } from './TripsOverdueList';
import { SelectComponent } from '../../common';
import {
    fetchAllServices,
    selectAllServicesById,
    selectAllServicesIds,
} from 'ducks/services';
import { useTranslation } from 'react-i18next';

export const MainPage: React.FC = () => {
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToViewTrips = accessToPages[Permissions.TRIP_VIEW];
    const dispatch = useDispatch();
    const { t } = useTranslation();
    React.useEffect(() => {
        dispatch(fetchStatistics());
    }, [dispatch]);
    React.useEffect(() => {
        dispatch(fetchAllServices({ onlyEnabled: false }));
    }, [dispatch]);
    const allServicesById = useSelector(selectAllServicesById);
    const allServiceIds = useSelector(selectAllServicesIds);

    const serviceOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allServiceIds.map(id => ({
                value: id,
                label: allServicesById[id].name,
            })),
        ],
        [allServiceIds, allServicesById],
    );

    const setServiceIdsFilter = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setServiceFilter({
                    value: Number(e.target.value),
                }),
            );
            if (allowedToViewTrips) {
                dispatch(fetchTripsMaxDuration());
                dispatch(fetchTrips());
            }
            dispatch(fetchStatistics());
        },
        [dispatch, allowedToViewTrips],
    );
    const { services } = useSelector(selectFilters);
    return (
        <>
            <Box width="200px" mb="20px">
                <SelectComponent
                    label={t('vehicles.services')}
                    onChange={setServiceIdsFilter}
                    value={services || ''}
                    options={serviceOptions}
                />
            </Box>
            <Box display="flex">
                {allowedToViewTrips && (
                    <Box mr="30px">
                        <Box mb="30px">
                            <TripsList />
                        </Box>
                        <Box mb="30px">
                            <TripsOverdueList />
                        </Box>
                    </Box>
                )}
                <Box>
                    <NumberOfRented />
                    <NumberByStatus />
                </Box>
            </Box>
        </>
    );
};
