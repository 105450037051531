import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from 'ducks/common/pagination';
import { SortDirection } from 'ducks/common/types';
import { FilterTypes } from './types';
import { Moment } from 'moment';
import { TripState } from './index';
const defaultFilters = {};
export const initialState: TripState = {
    loading: false,
    error: '',
    tripIds: [],
    tripsById: {},
    maxTripIds: [],
    maxTripsById: {},
    currentTrip: null,
    statistics: null,
    filters: defaultFilters,
    sort: {
        value: 'startTime',
        direction: SortDirection.Desc,
    },
    overdueSort: {
        value: 'startTime',
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
};

const tripSlice = createSlice({
    name: 'trips',
    initialState,
    reducers: {
        fetchTrips(state) {
            state.loading = true;
            state.error = '';
        },
        fetchTripsSuccess(state, action) {
            state.tripIds = action.payload.tripIds;
            state.tripsById = action.payload.tripsById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchTripsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchTripsMaxDuration(state) {
            state.loading = true;
            state.error = '';
        },
        fetchTripsMaxDurationSuccess(state, action) {
            state.maxTripIds = action.payload.tripIds;
            state.maxTripsById = action.payload.tripsById;
            state.loading = false;
        },
        fetchTripsMaxDurationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchStatistics(state) {
            state.loading = true;
            state.error = '';
        },
        fetchStatisticsSuccess(state, action) {
            state.statistics = action.payload;
            state.loading = false;
        },
        fetchStatisticsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchTrip: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: id => ({
                payload: id,
            }),
        },
        fetchTripSuccess(state, action) {
            state.currentTrip = action.payload;
            state.loading = false;
        },
        fetchTripError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        requestExportTrips(state) {
            state.loading = true;
            state.error = '';
        },
        requestExportTripsSuccess(state) {
            state.loading = false;
        },
        setMaxDurationFilter(state) {
            state.filters[FilterTypes.MaxDurationOverdue] =
                !state.filters[FilterTypes.MaxDurationOverdue];
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string | string[] | boolean;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setServiceFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                value: number;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [FilterTypes.Services]: payload.value,
            };
            state.pagination = defaultPagination;
            state.loading = true;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        setOverdueSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            if (state.overdueSort.value !== payload.value) {
                state.overdueSort.direction = SortDirection.Asc;
            } else {
                state.overdueSort.direction =
                    state.overdueSort.direction === SortDirection.Asc
                        ? SortDirection.Desc
                        : SortDirection.Asc;
            }
            state.overdueSort.value = payload.value;
            state.loading = true;
        },
        stopTrip: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({
                tripId,
                endDate,
            }: {
                tripId: number;
                endDate: string | Moment;
            }) => ({
                payload: { tripId, endDate },
            }),
        },
        stopTripSuccess(state) {
            state.loading = false;
        },
        stopTripError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
    },
});

export const {
    fetchTrips,
    fetchTripsError,
    fetchTripsSuccess,
    requestExportTrips,
    requestExportTripsSuccess,
    setFilter,
    setSort,
    setOverdueSort,
    setPage,
    fetchTrip,
    fetchTripSuccess,
    fetchTripError,
    resetFilters,
    stopTrip,
    stopTripSuccess,
    stopTripError,
    fetchStatistics,
    fetchStatisticsError,
    fetchStatisticsSuccess,
    fetchTripsMaxDurationSuccess,
    fetchTripsMaxDuration,
    fetchTripsMaxDurationError,
    setMaxDurationFilter,
    setServiceFilter,
} = tripSlice.actions;

export const tripReducer = tripSlice.reducer;
