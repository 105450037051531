import { DaysOfWeek, Schedule, ScheduleBody } from 'ducks/services/types';
import { ErrorIntervalFields, checkIfOverlap } from 'tools/validationRules';
import i18next from 'i18next';

type Error = {
    entries: ErrorIntervalFields[];
};
type AccumulatorErrorFields = {
    endTime: string;
    startTime: string;
    index: number;
};
type Accumulator = {
    [key in DaysOfWeek]: AccumulatorErrorFields[];
};
export type TimeFields = {
    endTime: string;
    startTime: string;
};

export type Time = {
    entries: Schedule[];
};
export const checkIfStartTimeIsLaterThanEnd = (values: Time) => {
    const errors: Error = { entries: [] };
    values.entries.map((value: TimeFields, index: number) => {
        if (
            value.startTime &&
            value.endTime &&
            value.startTime >= value.endTime
        ) {
            errors.entries[index] = {
                endTime: i18next.t('errors.endTime'),
                startTime: '',
            };
        }
        return errors;
    });
    return errors;
};

export const checkIfSchedulePeriodsOverlap = (values: ScheduleBody) => {
    const errors: Error = { entries: [] };
    const groupedByDayOfTheWeek = values.entries.reduce<Accumulator>(
        (acc, value: Schedule, index: number) => ({
            ...acc,
            [value.dayOfWeek]: [
                ...acc[value.dayOfWeek],
                {
                    startTime: value.startTime,
                    endTime: value.endTime,
                    index,
                },
            ],
        }),
        {
            FRIDAY: [],
            MONDAY: [],
            SATURDAY: [],
            SUNDAY: [],
            THURSDAY: [],
            TUESDAY: [],
            WEDNESDAY: [],
        },
    );
    Object.values(groupedByDayOfTheWeek).forEach(
        (val: AccumulatorErrorFields[]) => {
            for (let i = 0; i < val.length - 1; i++) {
                for (let j = i + 1; j < val.length; j++) {
                    if (checkIfOverlap(val[i], val[j])) {
                        errors.entries[val[i].index] = {
                            endTime: i18next.t('errors.periodsOverlap'),
                            startTime: i18next.t('errors.periodsOverlap'),
                        };
                        errors.entries[val[j].index] = {
                            endTime: i18next.t('errors.periodsOverlap'),
                            startTime: i18next.t('errors.periodsOverlap'),
                        };
                    }
                }
            }
        },
    );
    return errors;
};
