import { Pagination, Sort } from '../common/types';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { mapById, mapSorting } from '../common/mappers';
import { mapFetchedPagination, mapPagination } from '../common/pagination';
import {
    fetchPartners,
    fetchPartnersSuccess,
    fetchPartnersError,
    selectSort,
    selectPagination,
    setSort,
    setPage,
    requestExportPartners,
    requestExportPartnersSuccess,
    createPartner,
    createPartnerSuccess,
    createPartnerError,
    fetchPartner,
    fetchPartnerSuccess,
    fetchPartnerError,
    deletePartner,
    deletePartnerSuccess,
    deletePartnerError,
    togglePartnerEnable,
    togglePartnerSuccess,
    togglePartnerError,
    updatePartner,
    updatePartnerSuccess,
    updatePartnerError,
} from './index';
import {
    getPartners,
    exportPartners,
    createPartnerService,
    getPartner,
    deletePartnerService,
    updatePartnerService,
    updatePartnerStatus,
} from './service';
import { downloadBlob } from 'tools/file';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { Content, NewPartner, Partner, PartnerCallerTypes } from './types';

function* fetchPartnersSaga() {
    try {
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: Content = yield call(getPartners, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
        });
        const { allIds, byId } = mapById(data.content);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchPartnersSuccess({
                allIds,
                byId,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchPartnersError({ error: (error as Error)?.message }));
    }
}

function* exportPartnersSaga() {
    try {
        const { file, fileName } = yield call(exportPartners);
        downloadBlob(file, fileName);
        yield put(requestExportPartnersSuccess());
    } catch (error) {
        yield put(fetchPartnersError({ error: (error as Error)?.message }));
    }
}

function* createPartnerSaga({ payload }: PayloadAction<NewPartner>) {
    try {
        yield call(createPartnerService, {
            ...payload,
            companies:
                payload.caller === PartnerCallerTypes.MOBILE
                    ? payload.companies
                    : [],
        });
        yield put(createPartnerSuccess());
        yield put(push(routes.PARTNERS));
    } catch (error) {
        yield put(createPartnerError({ error: (error as Error)?.message }));
    }
}

function* fetchPartnerSaga({ payload }: PayloadAction<number>) {
    try {
        const data: Partner = yield call(getPartner, payload);
        yield put(fetchPartnerSuccess(data));
    } catch (error) {
        yield put(fetchPartnerError({ error: (error as Error)?.message }));
    }
}

function* deletePartnerSaga({ payload }: PayloadAction<number>) {
    try {
        yield call(deletePartnerService, payload);
        yield put(deletePartnerSuccess());
        yield put(push(routes.PARTNERS));
    } catch (error) {
        yield put(deletePartnerError({ error: (error as Error)?.message }));
    }
}

function* togglePartnerEnableSaga({
    payload,
}: PayloadAction<{ id: number; isEnabled: boolean }>) {
    try {
        yield call(updatePartnerStatus, {
            id: payload.id,
            isEnabled: payload.isEnabled,
        });
        yield put(togglePartnerSuccess());
        yield put(fetchPartner(payload.id));
    } catch (error) {
        yield put(togglePartnerError({ error: (error as Error)?.message }));
    }
}

function* updatePartnerSaga({ payload }: PayloadAction<Partner>) {
    try {
        yield call(updatePartnerService, payload.id, {
            ...payload,
            companies:
                payload.caller === PartnerCallerTypes.MOBILE
                    ? payload.companies
                    : [],
        });
        yield put(updatePartnerSuccess());
        yield put(push(routes.PARTNERS));
    } catch (error) {
        yield put(updatePartnerError({ error: (error as Error)?.message }));
    }
}

export default function* partnersSagas() {
    yield takeLatest(fetchPartners.type, fetchPartnersSaga);
    yield takeLatest(setSort.type, fetchPartnersSaga);
    yield takeLatest(setPage.type, fetchPartnersSaga);
    yield takeLatest(requestExportPartners.type, exportPartnersSaga);
    yield takeLatest(createPartner.type, createPartnerSaga);
    yield takeLatest(fetchPartner.type, fetchPartnerSaga);
    yield takeLatest(deletePartner.type, deletePartnerSaga);
    yield takeLatest(togglePartnerEnable.type, togglePartnerEnableSaga);
    yield takeLatest(updatePartner.type, updatePartnerSaga);
}
