import request from 'helpers/request';
import { NewVehicle, VehicleCommands, VehicleStatus } from './types';
import { getFileFromResponse } from 'tools/file';
import {
    VEHICLES_EXPORT_URL,
    VEHICLES_IMPORT_URL,
    VEHICLES_UPDATE_STATUS_URL,
    VEHICLES_URL,
} from 'constants/api';
import qs from 'qs';

export const getVehicles = (params: any): Promise<string> => {
    return request.get(
        `${VEHICLES_URL}?${qs.stringify(params, { indices: false })}`,
    );
};
export const getAllVehicles = (params: any): Promise<string> => {
    return request.get(
        `${VEHICLES_URL}/all?${qs.stringify(params, { indices: false })}`,
    );
};

export const exportVehiclesService = (params: any): Promise<any> =>
    request
        .get(
            `${VEHICLES_EXPORT_URL}?${qs.stringify(params, {
                indices: false,
            })}`,
        )
        .then(response => getFileFromResponse(response));

export const updateVehiclesStatuses = (params: any): Promise<string> =>
    request.patch(VEHICLES_UPDATE_STATUS_URL, { ...params });

export const patchVehicle = ({
    vehicleId,
    ...data
}: {
    vehicleId: number;
    status: VehicleStatus;
    services: number[];
    lockQrCode?: string;
}): Promise<string> => request.patch(`${VEHICLES_URL}/${vehicleId}`, data);

export const postVehicles = (vehicles: NewVehicle[]): Promise<string> =>
    request.post(VEHICLES_URL, {
        vehicles,
    });

export const postImportVehicles = ({
    file,
}: {
    file: FormData;
}): Promise<string> =>
    request.post(VEHICLES_IMPORT_URL, file, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });

export const getVehicle = (id: number): Promise<string> =>
    request.get(`${VEHICLES_URL}/${id}`);

export const getVehicleStateService = (id: number): Promise<string> =>
    request.get(`${VEHICLES_URL}/${id}/sync`);

export const sendCommandService = (params: {
    command: VehicleCommands;
    vehicleId: number;
}): Promise<string> =>
    request.post(
        `${VEHICLES_URL}/sendCommand?command=${params.command}&vehicleId=${params.vehicleId}`,
    );
