import { useField } from 'react-final-form';
import { composeValidators, required } from 'tools/validationRules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    RecipientTypes,
    SelectedService,
    UsageTypes,
} from 'ducks/services/types';
import { SelectComponent } from '../../../common/Select';
import { shortRentalFields } from '../FormContent';
import { FormApi } from 'final-form';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

type ServiceType = {
    form: FormApi<SelectedService>;
    id?: number;
};
export const TypeField: React.FC<ServiceType> = ({ form, id }: ServiceType) => {
    const { t } = useTranslation();
    const { input } = useField('type', {
        validate: composeValidators(required),
    });
    const options = React.useMemo(
        () => [
            {
                label: t('serviceList.SHORT_TERM_USAGE'),
                value: UsageTypes.SHORT_TERM_USAGE,
            },
            {
                label: t('serviceList.LONG_TERM_USAGE'),
                value: UsageTypes.LONG_TERM_USAGE,
            },
        ],
        [t],
    );
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = id
        ? accessToPages[Permissions.SERVICE_UPDATE]
        : accessToPages[Permissions.SERVICE_CREATE];
    return (
        <SelectComponent
            {...input}
            disabled={!!id || !allowedToEdit}
            onChange={e => {
                input.onChange(e.target.value);
                if (e.target.value === UsageTypes.LONG_TERM_USAGE) {
                    shortRentalFields.map(({ field }) =>
                        form.mutators.resetBooleanField({
                            field,
                        }),
                    );
                    form.mutators.resetBooleanField({
                        field: 'isBookingAllowed',
                    });
                    form.mutators.resetBooleanField({
                        field: 'isAutocloseTrip',
                    });
                    form.mutators.setField({
                        field: 'recipientType',
                        value: RecipientTypes.B_TO_B,
                    });
                    form.mutators.resetField({
                        field: 'zones',
                    });
                    form.mutators.setField({
                        field: 'maxTripDuration',
                        value: 0,
                    });
                }
            }}
            label={`${t('serviceList.type')}*`}
            options={options}
        />
    );
};
