import { createSlice } from '@reduxjs/toolkit';
import { AgreementsState } from './index';

export const initialState: AgreementsState = {
    currentAgreements: { policy: null, cgau: null },
    featureAgreements: { policy: null, cgau: null },
    agreementsHasUnsavedChanges: false,
    loading: false,
    error: '',
};

const agreementsSlice = createSlice({
    name: 'agreements',
    initialState,
    reducers: {
        fetchAgreements(state) {
            state.loading = true;
            state.error = '';
        },
        fetchAgreementsSuccess(state, action) {
            state.featureAgreements = action.payload.featureAgreements;
            state.currentAgreements = action.payload.currentAgreements;
            state.loading = false;
        },
        fetchAgreementsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updatePolicy: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: values => ({
                payload: values,
            }),
        },
        updatePolicySuccess(state) {
            state.loading = false;
            state.error = '';
        },
        updatePolicyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateCGAU: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: values => ({
                payload: values,
            }),
        },
        updateCGAUSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        updateCGAUError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setAgreementsHasUnsavedChanges(state, action) {
            state.agreementsHasUnsavedChanges = action.payload;
        },
    },
});

export const {
    fetchAgreements,
    fetchAgreementsSuccess,
    fetchAgreementsError,
    updatePolicy,
    updatePolicySuccess,
    updatePolicyError,
    updateCGAU,
    updateCGAUSuccess,
    updateCGAUError,
    setAgreementsHasUnsavedChanges,
} = agreementsSlice.actions;

export const agreementsReducer = agreementsSlice.reducer;
