import React, { ReactElement } from 'react';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import { Box, createStyles, Theme } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
    dateFormatConstant,
    timeFormatConstant,
} from '../../../constants/common';
interface FieldProps {
    label?: string;
    disabled?: boolean;
    id: string;
    helperText?: string;
    hasError?: boolean;
    getDisabledTime?: (time: Moment) => boolean;
    value?: string | Date;
    onChange?: (value: Moment | string) => void;
    timeFormat?: boolean;
    dateFormat?: string | boolean;
    closeOnSelect?: boolean;
    labelComponent?: ReactElement;
    displayTimeZone?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            width: '100%',
            boxSizing: 'border-box',
            padding: '9.32px 14px',
            border: '#c4c4c4 2px solid',
            borderRadius: '4px',
        },
        invalid: {
            border: 'red 2px solid',
        },
        labelSpan: {
            overflow: 'hidden',
            fontSize: '10px',
            lineHeight: 1.5,
            boxSizing: 'border-box',
            whiteSpace: 'nowrap',
            marginRight: 'auto',
            textOverflow: 'ellipsis',
            fontWeight: 400,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
        label: {
            textAlign: 'left',
            width: '100%',
            marginBottom: '5px',
            display: 'inline-flex',
            flexDirection: 'column',
        },
        grey: {
            color: 'rgba(0, 0, 0, 0.38)',
        },
        error: {
            width: '100%',
            textAlign: 'left',
            margin: 0,
            fontSize: '10px',
            fontWeight: 400,
            color: `${theme.palette.error.main}`,
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        },
    }),
);

export const DateTimeInput: React.FC<FieldProps> = ({
    label,
    disabled,
    id,
    helperText,
    getDisabledTime,
    hasError,
    value,
    onChange,
    timeFormat = true,
    closeOnSelect,
    labelComponent,
    displayTimeZone,
    ...props
}: FieldProps) => {
    const classes = useStyles();
    const inputProps = React.useMemo(
        () => ({
            disabled,
            label,
            className: classes.input,
        }),
        [disabled, label, classes.input],
    );

    return (
        <label htmlFor={id} className={classes.label}>
            <Box display="flex">
                <span
                    className={classNames(classes.labelSpan, {
                        [classes.grey]: disabled,
                    })}>
                    {label}
                </span>
                {labelComponent}
            </Box>
            <Datetime
                {...props}
                inputProps={{
                    ...inputProps,
                    className: classNames(classes.input, {
                        [classes.invalid]: hasError,
                    }),
                }}
                isValidDate={getDisabledTime}
                dateFormat={dateFormatConstant}
                timeFormat={timeFormat && timeFormatConstant}
                value={value ? moment(value) : ''}
                onChange={onChange}
                closeOnSelect={closeOnSelect}
                displayTimeZone={displayTimeZone}
                renderInput={props => {
                    return (
                        <input
                            {...props}
                            value={
                                value
                                    ? moment(new Date(value)).format(
                                          timeFormat
                                              ? `${dateFormatConstant} ${timeFormatConstant}`
                                              : dateFormatConstant,
                                      )
                                    : ''
                            }
                        />
                    );
                }}
            />
            <p className={classes.error}>{helperText}</p>
        </label>
    );
};
