import { createSlice } from '@reduxjs/toolkit';
import { AppVersionState } from './types';

export const initialState: AppVersionState = {
    loading: false,
    error: '',
    applicationVersionHasUnsavedChanges: false,
};

const applicationVersionSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        manageApplicationVersion: {
            reducer: state => {
                state.loading = true;
            },
            prepare: payload => ({
                payload,
            }),
        },
        manageApplicationVersionSuccess(state) {
            state.loading = false;
            state.applicationVersionHasUnsavedChanges = false;
        },
        manageApplicationVersionFailure(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setApplicationVersionHasUnsavedChanges(state, action) {
            state.applicationVersionHasUnsavedChanges = action.payload;
        },
        resetError(state) {
            state.error = '';
        },
    },
});
export const {
    setApplicationVersionHasUnsavedChanges,
    manageApplicationVersion,
    manageApplicationVersionFailure,
    manageApplicationVersionSuccess,
    resetError,
} = applicationVersionSlice.actions;

export const appVersionReducer = applicationVersionSlice.reducer;
