import React from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'react-final-form';
import { SelectComponent } from '../../Select';
import { FieldValidator } from 'final-form';
interface Option {
    label: string;
    value: string | number;
}
interface FieldProps {
    name: string;
    label: string;
    validators?: FieldValidator<string>;
    options: Option[];
    multiple?: boolean;
    disabled?: boolean;
    handleChange?: (value: string | number) => void;
    isRequired?: boolean;
}
export const SelectField: React.FC<FieldProps> = ({
    name,
    label,
    options,
    validators,
    multiple,
    disabled,
    handleChange,
    isRequired,
}: FieldProps) => {
    const { t } = useTranslation();
    const {
        input,
        meta: { error, modified, valid },
    } = useField(name, {
        validate: validators,
    });

    return (
        <SelectComponent
            value={input.value || (multiple ? [] : '')}
            onChange={e => {
                input.onChange(e.target.value);
                handleChange && handleChange(e.target.value);
            }}
            label={`${t(label)}${isRequired ? '*' : ''}`}
            valid={valid}
            multiple={multiple}
            options={options}
            disabled={disabled}
            hasError={!!(error && modified)}
            helperText={error && modified ? error : ''}
        />
    );
};
