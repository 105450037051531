import { VehicleBookingResponse, VehicleBookingAccumulator } from './types';

export const mapFetchedVehicleBooking = ({
    content,
}: VehicleBookingResponse): VehicleBookingAccumulator => {
    return content.reduce<VehicleBookingAccumulator>(
        (acc, bookingItem) => {
            return {
                bookingById: {
                    ...acc.bookingById,
                    [bookingItem.booking.id]: {
                        ...bookingItem,
                    },
                },
                bookingIds: [...acc.bookingIds, bookingItem.booking.id],
            };
        },
        { bookingIds: [], bookingById: {} },
    );
};
