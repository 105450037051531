import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/news';
import { News } from 'ducks/news/types';
import { Box } from '@material-ui/core';
import { Check } from '../../common/CheckIcon';

const renderCheck = (isDisplayed: boolean) =>
    isDisplayed && (
        <Box textAlign="center">
            <Check />
        </Box>
    );

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('news.id'),
            field: 'id',
            sorting: false,
            render: (rowProps: News) => rowProps.id,
        },
        {
            title: t('news.companyName'),
            field: 'companyName',
            sorting: false,
            render: (rowProps: News) => rowProps.companyName,
        },
        {
            title: t('news.title'),
            field: 'title',
            sorting: false,
            render: (rowProps: News) => rowProps.title,
        },
        {
            title: t('news.type'),
            field: 'type',
            render: (rowProps: News) => t(`newsTypes.${rowProps.type}`),
            sorting: false,
        },
        {
            title: t('news.start'),
            field: 'start',
            // eslint-disable-next-line react/display-name
            render: (rowProps: News) => (
                <span>{new Date(rowProps.start).toLocaleString()}</span>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'start',
                    }),
                ),
        },
        {
            title: t('news.end'),
            field: 'end',
            // eslint-disable-next-line react/display-name
            render: (rowProps: News) => (
                <span>{new Date(rowProps.end).toLocaleString()}</span>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'end',
                    }),
                ),
        },
        {
            title: t('news.isLoopEnabled'),
            field: 'isLoopEnabled',
            render: (rowProps: News) => renderCheck(rowProps.isLoopEnabled),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isLoopEnabled',
                    }),
                ),
        },
    ];
};
