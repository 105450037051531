import { Route, Redirect, RouteProps } from 'react-router-dom';
import React, { Fragment } from 'react';

import { HeaderComponent } from 'components/common/Header';
import { routes } from '../../../constants/common';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { Menu } from '../../common';
import { ModalContainer } from '../../common/ModalContainer';
import { Permissions } from 'ducks/groups/types';
import { selectIsUserLoggedIn, selectAccessToPages } from 'ducks/auth';

type ICommonLayoutProps = {
    component: React.FC;
    permission?: Permissions;
    isAccessAllowed?: boolean;
} & RouteProps;

export const CommonLayoutComponent = ({
    component: Component,
    permission,
    isAccessAllowed = true,
    ...restProps
}: ICommonLayoutProps) => {
    const accessToPages = useSelector(selectAccessToPages);

    const isRedirectionAllowed =
        isAccessAllowed && (permission ? accessToPages[permission] : true);

    const isLoggedIn = useSelector(selectIsUserLoggedIn);
    if (!isLoggedIn) {
        return <Redirect to={routes.LOGIN} />;
    }

    return !isRedirectionAllowed ? (
        <Redirect to={routes.MAIN_PAGE} />
    ) : (
        <Fragment>
            <HeaderComponent />
            <Box
                flex="1 0 auto"
                mr="60px"
                mt="30px"
                ml="100px"
                display="flex"
                justifyContent="center"
                flexDirection="column">
                <Menu />
                <Route
                    {...restProps}
                    render={props => <Component {...props} />}
                />
                <ModalContainer />
            </Box>
        </Fragment>
    );
};
