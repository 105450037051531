import { TFunction } from 'i18next';
import React from 'react';
import { ScooterMaintenance } from 'ducks/scooterMaintenance/types';
import { generatePath } from 'react-router-dom';
import { routes } from 'constants/common';
import { LinkComponent } from 'components/common/Link';

export const getColumns = (t: TFunction, isScooter: boolean) => {
    const columns = [
        {
            title: t('scooterMaintenance.id'),
            field: 'id',
            sorting: false,
            cellStyle: {
                width: `calc((100% - 0px) / 10)`,
            },
            headerStyle: {
                width: `calc((100% - 0px) / 10)`,
            },
        },
        {
            title: t('scooterMaintenance.type'),
            field: 'type',
            sorting: false,
            render: (rowProps: ScooterMaintenance) =>
                t(`scooterMaintenance.types.${rowProps.type}`),
        },
        {
            title: t('scooterMaintenance.timeStamp'),
            field: 'updated',
            sorting: false,
            render: (rowProps: ScooterMaintenance) =>
                rowProps.updated
                    ? new Date(rowProps.updated).toLocaleString()
                    : '-',
        },
        {
            title: t('scooterMaintenance.user'),
            field: 'boUser',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: ScooterMaintenance) => {
                return (
                    <LinkComponent
                        text={`${rowProps.boUser} - ${rowProps.boUserEmail}`}
                        handleClick={event => {
                            event.stopPropagation();
                            window.open(
                                rowProps.boUser
                                    ? generatePath(routes.BO_USER, {
                                          userId: rowProps.boUser,
                                      })
                                    : routes.BO_USERS,
                                '_blank',
                            );
                        }}
                    />
                );
            },
        },
    ];

    if (isScooter) {
        columns.push(
            {
                title: t('scooterMaintenance.vehicleSerialNumber'),
                field: 'serial',
                sorting: false,
                cellStyle: {
                    width: `calc((100% - 0px) / 6)`,
                },
                headerStyle: {
                    width: `calc((100% - 0px) / 6)`,
                },
            },
            {
                title: t('scooterMaintenance.vehiclePlateNumber'),
                field: 'plate',
                sorting: false,
                cellStyle: {
                    width: `calc((100% - 0px) / 6)`,
                },
                headerStyle: {
                    width: `calc((100% - 0px) / 6)`,
                },
            },
        );
    }

    return columns;
};
