export type ThemeType =
    | 'default'
    | 'violet'
    | 'pink'
    | 'blue'
    | 'yellow'
    | 'red'
    | 'green';

export enum Modals {
    CONNECT_SERVICES = 'CONNECT_SERVICES',
    ZONE_MAP = 'ZONE_MAP',
    CHANGE_CONFIRMATION = 'CHANGE_CONFIRMATION',
    MAP_WITH_POINT = 'MAP_WITH_POINT',
    EDIT_DATE_MODAL = 'EDIT_DATE_MODAL',
    UNBIND_BIKE = 'UNBIND_BIKE',
    ADD_EMAIL_RECIPIENT = 'ADD_EMAIL_RECIPIENT',
    CHANGE_VEHICLE = 'CHANGE_VEHICLE',
    ADD_PENALTY = 'ADD_PENALTY',
    STATION_SYNC_CONFIRM = 'STATION_SYNC_CONFIRM',
}

export enum NotificationTypes {
    SUCCESS = 'success',
    ERROR = 'error',
}

export type UIState = {
    theme: string;
    openedModals: { modalType: Modals; modalProps?: any }[];
    notification: { message: string; type: NotificationTypes };
};
