import { Accumulator, ScooterMaintenanceResponse } from './types';

export const mapFetchedMaintenance = ({
    content,
}: ScooterMaintenanceResponse): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, { maintenance, ...maintenanceData }) => {
            return {
                maintenanceById: {
                    ...acc.maintenanceById,
                    [maintenance.id]: {
                        ...maintenance,
                        ...maintenanceData,
                        updated: maintenanceData.updateStamp.updated,
                    },
                },
                maintenanceIds: [...acc.maintenanceIds, maintenance.id],
            };
        },
        { maintenanceIds: [], maintenanceById: {} },
    );
};
