import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    EventsState,
    CommonEventTypes,
    Sorting,
    SourceTypes,
    StringFilterTypes,
} from './types';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';

const defaultFilters = {};
export const initialState: EventsState = {
    loading: false,
    error: '',
    eventIds: [],
    eventsById: {},
    filters: defaultFilters,
    sort: {
        value: Sorting.id,
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        fetchEvents(state) {
            state.loading = true;
        },
        fetchEventsSuccess(state, action) {
            state.eventIds = action.payload.eventIds;
            state.eventsById = action.payload.eventsById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchEventsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setStringFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: StringFilterTypes;
                value: string | CommonEventTypes | SourceTypes;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
    },
});
export const {
    setStringFilter,
    setPage,
    setSort,
    resetFilters,
    fetchEvents,
    fetchEventsError,
    fetchEventsSuccess,
} = eventSlice.actions;

export const eventReducer = eventSlice.reducer;
