import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
    setStringFilter,
    StringFilterTypes,
    exportCompanies,
    selectFilters,
} from 'ducks/companies';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import AddIcon from '@material-ui/icons/Add';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

export const CompanySearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);
    const redirectToCompanyCreation = () => dispatch(push(routes.NEW_COMPANY));
    const saveList = () => dispatch(exportCompanies());
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length !== 1) {
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.Name,
                    value: e.target.value,
                }),
            );
        }
    };
    const accessToPages = useSelector(selectAccessToPages);

    return (
        <FormGrid columnAmount={2} rowHeight="20px" padding="10px 0">
            <SearchComponent
                placeholder={t('search')}
                onChange={handleSearch}
                defaultValue={filters[StringFilterTypes.Name]}
            />
            <FormGrid columnAmount={2} padding="0" rowHeight="20px">
                {accessToPages[Permissions.COMPANY_CREATE] && (
                    <ButtonComponent
                        text={t('company.add')}
                        startIcon={<AddIcon />}
                        handleClick={redirectToCompanyCreation}
                    />
                )}
                {accessToPages[Permissions.COMPANY_EXPORT] && (
                    <ButtonComponent
                        text={t('download')}
                        startIcon={<SaveAltIcon />}
                        handleClick={saveList}
                    />
                )}
            </FormGrid>
        </FormGrid>
    );
};
