import { TFunction } from 'i18next';
import React from 'react';

export const getColumns = (t: TFunction) => {
    return [
        {
            title: t('invitations.email'),
            field: 'email',
            sorting: false,
            cellStyle: {
                paddingRight: 3,
                paddingLeft: 10,
                maxWidth: 225,
            },
            headerStyle: {
                paddingRight: 3,
                paddingLeft: 10,
            },
            disableClick: true,
        },
        {
            title: t('invitations.firstName'),
            field: 'firstName',
            sorting: false,
            disableClick: true,
        },
        {
            title: t('invitations.lastName'),
            field: 'lastName',
            sorting: false,
            disableClick: true,
        },
        {
            title: t('invitations.serviceName'),
            field: 'serviceName',
            sorting: false,
            disableClick: true,
        },
        {
            title: t('invitations.startTime'),
            field: 'startTime',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: { startTime: string }) => (
                <span>
                    {rowProps.startTime
                        ? new Date(rowProps.startTime).toLocaleString()
                        : '-'}
                </span>
            ),
            disableClick: true,
        },
        {
            title: t('invitations.endTime'),
            field: 'endTime',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: { endTime: string }) => (
                <span>
                    {rowProps.endTime
                        ? new Date(rowProps.endTime).toLocaleString()
                        : '-'}
                </span>
            ),
            disableClick: true,
        },
        {
            title: t('invitations.lldVehicleId'),
            field: 'lldVehicleId',
            sorting: false,
            disableClick: true,
        },
        {
            title: t('invitations.language'),
            field: 'language',
            sorting: false,
            disableClick: true,
            headerStyle: {
                paddingRight: 3,
                paddingLeft: 3,
            },
            cellStyle: {
                paddingRight: 3,
                paddingLeft: 3,
            },
        },
        {
            title: t('invitations.confirmationCode'),
            field: 'confirmationCode',
            sorting: false,
            disableClick: true,
        },
        {
            title: t('invitations.expirationTime'),
            field: 'expirationTime',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: { expirationTime: string }) => (
                <span>
                    {rowProps.expirationTime
                        ? new Date(rowProps.expirationTime).toLocaleString()
                        : '-'}
                </span>
            ),
            disableClick: true,
        },
    ];
};
