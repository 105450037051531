import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableComponent } from 'components/common/Table';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { selectMaintenanceTableData } from 'ducks/scooterMaintenance/selectors';
import {
    FilterTypes,
    resetFilters,
    setFilters,
} from 'ducks/scooterMaintenance';
import { Container } from './Container';
import { useParams } from 'react-router';
import { MaintenanceTypes } from 'ducks/scooterMaintenance/types';

export const MaintenanceList: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const data = useSelector(selectMaintenanceTableData);

    const columns = useMemo(() => {
        return getColumns(t);
    }, [t]);

    const { vehicleId } = useParams<{ vehicleId: string }>();
    useEffect(() => {
        if (vehicleId) {
            dispatch(
                setFilters([
                    {
                        filterType: FilterTypes.VehicleIds,
                        value: vehicleId,
                    },
                    {
                        filterType: FilterTypes.Type,
                        value: MaintenanceTypes.REPAIR,
                    },
                ]),
            );
        }
    }, [vehicleId, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, [dispatch]);

    return (
        <>
            <TableComponent
                columns={columns}
                data={data}
                title={t('scooterMaintenance.vehicleTitle')}
                container={Container}
                hasToolbarSpace={false}
                pageSize={data.length < 5 ? data.length : 5}
            />
        </>
    );
};
