import { createStyles, Toolbar, withStyles } from '@material-ui/core';

export const StyledToolbar = withStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            padding: '0',
            height: '64px',
        },
    }),
)(Toolbar);
