import { VehicleStatus } from 'ducks/vehicles/common/types';
import { Pagination, Sort } from 'ducks/common/types';
import { BoUser } from '../boUsers/types';
import { Profile, User } from '../users/types';

export type EventsById = {
    [code: number]: Event;
};

export type EventsState = {
    loading: boolean;
    error: string;
    eventIds: number[];
    eventsById: EventsById;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
};

export enum ScooterEventTypes {
    turnOn = 'TURN_ON',
    turnOff = 'TURN_OFF',
    openSaddle = 'OPEN_SADDLE',
    closeSaddle = 'CLOSE_SADDLE',
    startTailbox = 'START_TAILBOX',
    closeTailbox = 'CLOSE_TAILBOX',
    statusChange = 'STATUS_CHANGE',
    telemShock = 'TELEM_SHOCK',
    telemAlarm = 'TELEM_ALARM',
    telemFallenOn = 'TELEM_FALLEN_ON',
    telemFallenOff = 'TELEM_FALLEN_OFF',
}

export enum CommonEventTypes {
    startTrip = 'START_TRIP',
    closeTrip = 'CLOSE_TRIP',
    startPause = 'START_PAUSE',
    stopPause = 'STOP_PAUSE',
    statusChange = 'STATUS_CHANGE',
}

export enum BikeAndVaeEventTypes {
    tripLockOpened = 'TRIP_LOCK_OPENED',
    tripLockClosed = 'TRIP_LOCK_CLOSED',
    lockOpenSuccess = 'LOCK_OPEN_SUCCESS',
    lockBound = 'LOCK_BOUND',
    lockUnbound = 'LOCK_UNBOUND',
    lockOpened = 'LOCK_OPENED',
    lockStatusChange = 'LOCK_STATUS_CHANGE',
    lockOpenFail = 'LOCK_OPEN_FAIL',
    lockCloseFail = 'LOCK_CLOSE_FAIL',
    lockShock = 'LOCK_SHOCK',
    lockIllegalMove = 'LOCK_ILLEGAL_MOVE',
    lockCloseSuccess = 'LOCK_CLOSE_SUCCESS',
    stationVehicleLock = 'STATION_VEHICLE_LOCK',
    stationVehicleunLock = 'STATION_VEHICLE_UNLOCK',
}

export enum SourceTypes {
    backend = 'BACKEND',
    weelproApp = 'WEELPRO_APP',
    weelopsApp = 'WEELOPS_APP',
    backoffice = 'BACKOFFICE',
}

export enum StatusTypes {
    ok = 'Ok',
    error = 'ERROR',
}

export interface Event extends FetchedEvent {
    boUserId: number;
    endUserId: number;
    boUserEmail: string;
    endUsers: { email: string; type: string }[];
}
export interface FetchedEvent {
    id: number;
    type: CommonEventTypes;
    status: StatusTypes;
    sourceType: SourceTypes;
    newVehicleStatus: VehicleStatus;
    oldVehicleStatus: VehicleStatus;
    lat: number;
    lng: number;
    timestamp: string;
    creationTime: string;
}

export enum StringFilterTypes {
    SourceTypes = 'sourceTypes',
    StartTime = 'startTime',
    EndTime = 'endTime',
    EventTypes = 'eventTypes',
    UserKey = 'userKey',
}

export type Filters = {
    [StringFilterTypes.SourceTypes]?: SourceTypes;
    [StringFilterTypes.StartTime]?: string;
    [StringFilterTypes.EndTime]?: string;
    [StringFilterTypes.EventTypes]?: CommonEventTypes;
    [StringFilterTypes.UserKey]?: string;
};

export enum Sorting {
    id = 'id',
    timestamp = 'timestamp',
    oldVehicleStatus = 'oldVehicleStatus',
    newVehicleStatus = 'newVehicleStatus',
}

export type Content = {
    event: FetchedEvent;
    boUser: BoUser;
    endUser: User;
    profiles: Profile[];
};

export interface FetchedContent extends Pagination {
    content: Content[];
}
