import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
    ButtonComponent,
    LinkComponent,
    TextField,
    ThemedBoxComponent,
} from 'components/common';
import {
    required,
    isValidEmail,
    composeValidators,
} from 'tools/validationRules';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { login, resetError } from 'ducks/auth/authSlice';
import { Credentials } from 'ducks/auth/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { RootState } from 'ducks/root';
import { FormGrid } from '../../common/FormGrid';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { ErrorComponent } from '../../common/Error';
import background from 'resources/icons/connexion.png';
import poweredby from 'resources/icons/poweredbyindigo.png';
import { LoaderComponent } from '../../common/Loader';
import { useCaptcha } from '../../../hooks/useCaptcha';

const useStyles = makeStyles(() =>
    createStyles({
        hidden: {
            visibility: 'hidden',
        },
        pageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundImage: `url(
                ${background})`,
        },
    }),
);
export const LoginForm: React.FC = () => {
    const classes = useStyles();
    const { captchaPassed, Captcha, handleAttemptFail } = useCaptcha();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleConfirm = ({ email, password }: Credentials) => {
        dispatch(login({ email, password }));
    };
    const redirectToPasswordRestore = React.useCallback(
        () => dispatch(push(routes.PASSWORD_RESTORE)),
        [dispatch],
    );
    React.useEffect(
        () => () => {
            dispatch(resetError());
        },
        [dispatch],
    );
    const { error, loading } = useSelector((state: RootState) => state.auth);
    useEffect(() => {
        if (captchaPassed && error && !loading) {
            handleAttemptFail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, loading, dispatch, handleAttemptFail]);

    return (
        <Box className={classes.pageContainer}>
            <Box position="relative" display="flex" justifyContent="center">
                {loading && <LoaderComponent />}
                {!captchaPassed ? (
                    Captcha
                ) : (
                    <Form
                        onSubmit={values => {
                            dispatch(resetError());
                            handleConfirm({
                                email: values.email,
                                password: values.password,
                            });
                        }}
                        render={({
                            handleSubmit,
                            pristine,
                            valid,
                            submitSucceeded,
                            dirtySinceLastSubmit,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <ThemedBoxComponent width={400}>
                                    <Box textAlign="center">
                                        <Typography variant="h1">
                                            <p>{t('login.title')}</p>
                                        </Typography>
                                    </Box>
                                    <FormGrid columnAmount={1}>
                                        <TextField
                                            name="email"
                                            validators={composeValidators(
                                                required,
                                                isValidEmail,
                                            )}
                                            label={t('fields.email')}
                                            isRequired
                                        />
                                        <TextField
                                            name="password"
                                            validators={composeValidators(
                                                required,
                                            )}
                                            label={t('fields.password')}
                                            type="password"
                                            isRequired
                                        />
                                    </FormGrid>
                                    <ErrorComponent error={error} />
                                    <FormButtonGroup>
                                        <ButtonComponent
                                            handleClick={handleSubmit}
                                            text={t('login.submit')}
                                            isDisabled={
                                                pristine ||
                                                !valid ||
                                                loading ||
                                                (submitSucceeded &&
                                                    !dirtySinceLastSubmit)
                                            }
                                        />
                                    </FormButtonGroup>
                                    <Box mt="10px">
                                        <LinkComponent
                                            text={t('restorePassword')}
                                            handleClick={
                                                redirectToPasswordRestore
                                            }
                                        />
                                    </Box>
                                    <input
                                        type="submit"
                                        className={classes.hidden}
                                    />
                                </ThemedBoxComponent>
                            </form>
                        )}
                    />
                )}
                <Box bottom="-120px" position="absolute">
                    <img src={poweredby} alt="" width="150px" />
                </Box>
            </Box>
        </Box>
    );
};
