import React, { useEffect } from 'react';
import { Action, TableComponent } from 'components/common/Table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStations, selectIsLoading } from 'ducks/stations';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { SearchToolbar } from './SearchToolbar';
import { selectSort, selectStationsTableData } from 'ducks/stations';
import { Pagination } from './Pagination';
import { Station } from 'ducks/stations/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const StationList: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const columns = getColumns(t, dispatch);

    const sort = useSelector(selectSort);
    const data = useSelector(selectStationsTableData);
    const loading = useSelector(selectIsLoading);

    const redirectToStation = React.useCallback(
        (id: number) =>
            dispatch(push(routes.STATION.replace(':stationId', `${id}`))),
        [dispatch],
    );

    useEffect(() => {
        dispatch(fetchStations());
    }, [dispatch]);

    const actions = React.useMemo(
        (): Action<Station>[] => [
            {
                icon: 'visibility',
                tooltip: t('edit'),
                onClick: (event, rowData: Station | undefined) =>
                    rowData && redirectToStation(rowData.id),
                position: 'row',
            },
        ],
        [redirectToStation, t],
    );
    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={actions}
                onRowClick={(event, rowData: Station | undefined) =>
                    rowData && redirectToStation(rowData.id)
                }
                searchComponent={SearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={data}
                title={t('stations.stationsList')}
                sort={sort}
            />
        </>
    );
};
