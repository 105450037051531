import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import {
    ButtonComponent,
    CheckboxField,
    LinkComponent,
    TextField,
} from 'components/common';
import { isEqual } from 'lodash';
import { AddTemplateComponent } from './AddTemplates';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { RootState } from 'ducks/root';
import arrayMutators from 'final-form-arrays';
import {
    createCompany,
    selectIsLoading,
    setCompanyHasUnsavedChanges,
    updateCompany,
} from 'ducks/companies';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { Prompt, useParams } from 'react-router';
import { routes } from '../../../../constants/common';
import { FormGrid } from '../../../common/FormGrid';
import {
    composeValidators,
    fieldLength,
    isContainsLettersAndNumbers,
    isLink,
    isPhoneNumber,
    isValidDomains,
    required,
    isPhoneCallPrice,
    isCorrectIframeHtml,
} from 'tools/validationRules';
import { StyledFormControlLabel } from '../../../common/FormControlLabel';
import { colors } from 'themes/colors';
import { Languages } from 'ducks/boUsers/types';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Buttons } from './Buttons';
import { generatePath } from 'react-router-dom';
import { ColorField } from '../../../common/Form/CommonFields/ColorField';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { LoaderComponent } from '../../../common/Loader';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../../common/Error';
import { UploadFileField } from '../../../common/DropzoneField';
import { DeletableFileComponent } from '../../../common/File';
import { Template } from 'ducks/news/types';
import { Company } from 'ducks/companies/types';

const ManagersList = () => {
    const { companyManagers } = useSelector(
        (state: RootState) => state.company,
    );

    return (
        <Box>
            <Typography variant="h2">Managers</Typography>
            {companyManagers.length
                ? companyManagers.map(({ id, email }) => (
                      <Box marginTop="10px" key={id}>
                          <LinkComponent
                              href={generatePath(routes.BO_USER, {
                                  userId: id,
                              })}
                              text={email}
                              target="_blank"
                          />
                      </Box>
                  ))
                : '-'}
        </Box>
    );
};

export const CreateCompany: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accessToPages = useSelector(selectAccessToPages);
    const { companyId } = useParams<{ companyId?: string }>();
    const allowedToEdit =
        accessToPages[
            companyId ? Permissions.COMPANY_UPDATE : Permissions.COMPANY_CREATE
        ];

    const loading = useSelector(selectIsLoading);
    const handleConfirm = React.useCallback(
        (values: any) => {
            const companyValues = {
                ...values,
                reportingDashboard: values.reportingDashboard || '',
                domainLists: {
                    acceptedDomains: values.acceptedDomains
                        ? values.acceptedDomains.split(' ').join('').split(',')
                        : [],
                    rejectedDomains: values.rejectedDomains
                        ? values.rejectedDomains.split(' ').join('').split(',')
                        : [],
                },
            };

            companyId
                ? dispatch(updateCompany(companyValues))
                : dispatch(createCompany(companyValues));
        },
        [dispatch, companyId],
    );
    const emptyFields = React.useMemo(
        () => ({
            id: '',
            language: Languages.EN,
        }),
        [],
    );

    const { error, companyHasUnsavedChanges, currentCompany } = useSelector(
        (state: RootState) => state.company,
    );

    const initialValues = React.useMemo(
        () =>
            companyId && currentCompany
                ? {
                      ...currentCompany,
                      invitationTemplates:
                          currentCompany.invitationTemplates || [],
                      welcomeTemplates: currentCompany.welcomeTemplates || [],
                      acceptedDomains:
                          currentCompany.domainLists.acceptedDomains &&
                          currentCompany.domainLists.acceptedDomains.join(','),
                      rejectedDomains:
                          currentCompany.domainLists.rejectedDomains &&
                          currentCompany.domainLists.rejectedDomains.join(','),
                  }
                : {
                      isManager: false,
                      domainLists: {
                          acceptedDomains: '',
                          rejectedDomains: '',
                      },
                      invitationTemplates: [],
                      welcomeTemplates: [],
                      boColor: colors.default,
                      zendeskTickets: false,
                      appColor: {
                          actionButtonColor: colors.default,
                          backgroundColor: colors.default,
                          topBarColor: colors.default,
                      },
                  },
        [companyId, currentCompany],
    );
    React.useEffect(() => {
        return () => {
            dispatch(setCompanyHasUnsavedChanges(false));
        };
    }, [dispatch]);

    useNotificationOnPageLeave(companyHasUnsavedChanges);
    const setUnsavedChanges = React.useCallback(
        ({ pristine, values }: FormState<Company>) => {
            return dispatch(
                setCompanyHasUnsavedChanges(
                    !pristine ||
                        !isEqual(
                            initialValues.invitationTemplates,
                            values.invitationTemplates,
                        ) ||
                        !isEqual(
                            initialValues.welcomeTemplates,
                            values.welcomeTemplates,
                        ),
                ),
            );
        },
        [dispatch, initialValues],
    );

    return (
        <>
            <Prompt
                when={companyHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={handleConfirm}
                initialValues={initialValues}
                mutators={{
                    ...arrayMutators,
                }}
                render={({
                    handleSubmit,
                    valid,
                    values,
                    form,
                    form: {
                        mutators: { push, remove },
                    },
                }) => (
                    <form onSubmit={handleSubmit}>
                        {loading && <LoaderComponent />}
                        <FormSpy
                            subscription={{
                                values: true,
                                pristine: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <FormSpy
                            subscription={{
                                initialValues: true,
                            }}
                            onChange={({ initialValues }) =>
                                form.reset(initialValues)
                            }
                        />
                        <Box paddingLeft="20px">
                            <Typography variant="h1">
                                <p>
                                    {companyId
                                        ? t('updateCompany')
                                        : t('createCompany')}
                                </p>
                            </Typography>
                        </Box>
                        <FormGrid columnAmount={3} padding="0">
                            <FormGrid columnAmount={1}>
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="name"
                                    validators={composeValidators(
                                        required,
                                        (value: string) =>
                                            value && fieldLength(value, 2, 50),
                                    )}
                                    label={t('company.name')}
                                    isRequired
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="city"
                                    validators={composeValidators(
                                        required,
                                        (value: string) =>
                                            value && fieldLength(value, 2, 30),
                                    )}
                                    label={t('company.city')}
                                    isRequired
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="address"
                                    validators={composeValidators(
                                        required,
                                        (value: string) =>
                                            value && fieldLength(value, 2, 120),
                                    )}
                                    label={t('company.address')}
                                    isRequired
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="zipCode"
                                    validators={composeValidators(
                                        required,
                                        (value: string) =>
                                            isContainsLettersAndNumbers(
                                                value,
                                                t('errors.zipCode'),
                                            ),
                                    )}
                                    label={t('company.zipCode')}
                                    isRequired
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="siret"
                                    validators={composeValidators(
                                        required,
                                        (value: string) =>
                                            isContainsLettersAndNumbers(
                                                value,
                                                t('errors.siret'),
                                            ),
                                    )}
                                    label={t('company.siret')}
                                    isRequired
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="assistancePhone"
                                    validators={composeValidators(
                                        isPhoneNumber,
                                    )}
                                    label={t('company.assistancePhone')}
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="phoneCallPrice"
                                    type="number"
                                    validators={composeValidators(
                                        isPhoneCallPrice,
                                        (value: string) =>
                                            value &&
                                            fieldLength(`${value}`, 1, 100),
                                    )}
                                    label={t('company.phoneCallPrice')}
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="reportingDashboard"
                                    label={t('company.reportingDashboard')}
                                    validators={isCorrectIframeHtml}
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="insuranceLink"
                                    validators={composeValidators(isLink)}
                                    label={t('company.insuranceLink')}
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="insuranceKeyField"
                                    label={t('company.insuranceKeyField')}
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="refOrderSupplier"
                                    label={t('company.refOrderSupplier')}
                                />
                                <CheckboxField
                                    disabled={!allowedToEdit}
                                    field="zendeskTickets"
                                    label="company.zendeskTickets"
                                />
                                {companyId && <ManagersList />}
                            </FormGrid>
                            <FormGrid columnAmount={1}>
                                <ColorField
                                    disabled={!allowedToEdit}
                                    name="appColor.actionButtonColor"
                                    validators={composeValidators(required)}
                                    label={t('company.actionButtonColor')}
                                    isRequired
                                />
                                <ColorField
                                    disabled={!allowedToEdit}
                                    name="appColor.backgroundColor"
                                    validators={composeValidators(required)}
                                    label={t('company.backgroundColor')}
                                    isRequired
                                />
                                <ColorField
                                    disabled={!allowedToEdit}
                                    name="appColor.topBarColor"
                                    validators={composeValidators(required)}
                                    label={t('company.topBarColor')}
                                    isRequired
                                />
                                <ColorField
                                    disabled={!allowedToEdit}
                                    name="boColor"
                                    validators={composeValidators(required)}
                                    label={t('company.boColor')}
                                    isRequired
                                />
                                <div>
                                    {allowedToEdit && (
                                        <StyledFormControlLabel
                                            labelPlacement="top"
                                            label={t('company.appLogo')}
                                            control={
                                                <UploadFileField
                                                    name="appLogo"
                                                    isDropzone={false}
                                                />
                                            }
                                        />
                                    )}
                                    {values.appLogo && (
                                        <DeletableFileComponent
                                            image={values.appLogo}
                                            showDeleteButton={false}
                                            key={values.appLogo?.url}
                                        />
                                    )}
                                </div>
                                <div>
                                    {allowedToEdit && (
                                        <StyledFormControlLabel
                                            labelPlacement="top"
                                            label={t('company.boLogo')}
                                            control={
                                                <UploadFileField
                                                    name="boLogo"
                                                    isDropzone={false}
                                                />
                                            }
                                        />
                                    )}
                                    {values.boLogo && (
                                        <DeletableFileComponent
                                            image={values.boLogo}
                                            showDeleteButton={false}
                                            key={values.boLogo?.url}
                                        />
                                    )}
                                </div>
                            </FormGrid>
                            <FormGrid columnAmount={1}>
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="acceptedDomains"
                                    validators={composeValidators(
                                        isValidDomains,
                                    )}
                                    label={t('company.acceptedDomains')}
                                />
                                <TextField
                                    disabled={!allowedToEdit}
                                    name="rejectedDomains"
                                    validators={composeValidators(
                                        isValidDomains,
                                    )}
                                    label={t('company.rejectedDomains')}
                                />
                                <Typography variant="h2">
                                    <p>{t('company.invitationTemplates')}</p>
                                </Typography>
                                {values.invitationTemplates.map(
                                    (_: any, index: number) => (
                                        <Box key={index} textAlign="center">
                                            <AddTemplateComponent
                                                index={index}
                                                prefix="invitationTemplates"
                                            />
                                            {allowedToEdit && (
                                                <IconButton
                                                    onClick={() =>
                                                        remove(
                                                            'invitationTemplates',
                                                            index,
                                                        )
                                                    }>
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    ),
                                )}
                                {allowedToEdit && (
                                    <ButtonComponent
                                        handleClick={() =>
                                            push(
                                                'invitationTemplates',
                                                emptyFields,
                                            )
                                        }
                                        text={t('add')}
                                        startIcon={<AddIcon />}
                                    />
                                )}
                                <Typography variant="h2">
                                    <p>{t('company.welcomeTemplates')}</p>
                                </Typography>
                                {values.welcomeTemplates.map(
                                    (template: Template, index: number) => (
                                        <Box key={index} textAlign="center">
                                            <AddTemplateComponent
                                                index={index}
                                                prefix="welcomeTemplates"
                                            />
                                            {allowedToEdit && (
                                                <IconButton
                                                    onClick={() =>
                                                        remove(
                                                            'welcomeTemplates',
                                                            index,
                                                        )
                                                    }>
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    ),
                                )}
                                {allowedToEdit && (
                                    <ButtonComponent
                                        handleClick={() =>
                                            push(
                                                'welcomeTemplates',
                                                emptyFields,
                                            )
                                        }
                                        text={t('add')}
                                        startIcon={<AddIcon />}
                                    />
                                )}
                            </FormGrid>
                        </FormGrid>
                        <Buttons handleSubmit={handleSubmit} valid={valid} />
                        <Box textAlign="center">
                            <ErrorComponent error={error} />
                        </Box>
                    </form>
                )}
            />
        </>
    );
};
