import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from 'ducks/common/pagination';
import { SortDirection } from 'ducks/common/types';
import { FilterTypes, StringFilterTypes, UserState } from './types';
const defaultFilters = {
    areGroupsAccepted: false,
    areNewsAccepted: false,
    arePartnersAccepted: false,
    searchText: '',
    companyId: '',
};
export const initialState: UserState = {
    loading: false,
    error: '',
    documentError: '',
    userProfileError: {},
    userIds: [],
    usersById: {},
    documents: {},
    currentUser: null,
    userHasUnsavedChanges: false,
    profileHasUnsavedChanges: false,
    filters: defaultFilters,
    sort: {
        value: '',
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
};

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        fetchUsers(state) {
            state.loading = true;
            state.error = '';
        },
        fetchUsersSuccess(state, action) {
            state.userIds = action.payload.userIds;
            state.usersById = action.payload.usersById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchUsersError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        requestExportUsers(state) {
            state.loading = true;
            state.error = '';
        },
        requestExportUsersSuccess(state) {
            state.loading = false;
        },
        setFilter(state, { payload }: PayloadAction<FilterTypes>) {
            state.filters[payload] = !state.filters[payload];
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setStringFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: StringFilterTypes;
                value: string;
            }>,
        ) {
            state.filters[payload.filterType] = payload.value;
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        fetchUser: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.userProfileError = {};
            },
            prepare: id => ({
                payload: id,
            }),
        },
        fetchUserSuccess(state, action) {
            state.currentUser = action.payload;
            state.loading = false;
        },
        fetchUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchUserDocument: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.userProfileError = {};
            },
            prepare: ({ userId, filename }) => ({
                payload: { userId, filename },
            }),
        },
        fetchUserDocumentSuccess(state, action) {
            state.documents = {
                ...state.documents,
                [action.payload.filename]: action.payload.file,
            };
            state.loading = false;
        },
        fetchUserDocumentError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateUserDocument: {
            reducer: state => {
                state.loading = true;
                state.documentError = '';
                state.userProfileError = {};
            },
            prepare: ({ userId, file }) => ({
                payload: { userId, file },
            }),
        },
        updateUserDocumentSuccess(state) {
            state.loading = false;
        },
        updateUserDocumentError(state, action) {
            state.documentError = action.payload.error;
            state.loading = false;
        },
        deleteUserDocument: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.userProfileError = {};
            },
            prepare: ({ userId, filename }) => ({
                payload: { userId, filename },
            }),
        },
        addEndUserPenalty: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ userId, amount, comment, profileId, tripId }) => ({
                payload: { userId, comment, amount, profileId, tripId },
            }),
        },
        deleteUserDocumentSuccess(state) {
            state.loading = false;
        },
        deleteUserDocumentError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        anonymizeUser(state) {
            state.loading = true;
            state.error = '';
        },
        anonymizeUserSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        anonymizeUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateUser: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: company => ({
                payload: company,
            }),
        },
        deleteCreditCard: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (userId, profileId) => ({
                payload: { userId, profileId },
            }),
        },
        updateUserSuccess(state) {
            state.loading = false;
            state.error = '';
            state.userHasUnsavedChanges = false;
        },
        updateUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        toggleUserEnable: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (id, isEnabled) => ({
                payload: { id, isEnabled },
            }),
        },
        toggleUserSuccess(state) {
            state.loading = false;
        },
        toggleUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        toggleUserProfileStatus: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (id, profileId, status) => ({
                payload: { id, profileId, status },
            }),
        },
        toggleUserProfileSuccess(state) {
            state.loading = false;
        },
        toggleUserProfileError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setUserHasUnsavedChanges(state, action) {
            state.userHasUnsavedChanges = action.payload;
        },
        redirectToUsersList(state) {
            state.loading = true;
            state.error = '';
        },
        redirectToUsersListSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        anonymizeProfile: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: profileId => ({
                payload: profileId,
            }),
        },
        anonymizeProfileSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        anonymizeProfileError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateUserProfile: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (profile, servicesForDeletion) => ({
                payload: {
                    profile,
                    servicesForDeletion,
                },
            }),
        },
        updateUserProfileSuccess(state) {
            state.loading = false;
            state.error = '';
            state.profileHasUnsavedChanges = false;
        },
        updateUserProfileError(state, action) {
            state.userProfileError = {
                [action.payload.profileId]: action.payload.error,
            };
            state.loading = false;
        },
        setProfileHasUnsavedChanges(state, action) {
            state.profileHasUnsavedChanges = action.payload;
        },
        resetCurrentUser(state) {
            state.currentUser = null;
            state.documents = {};
            state.loading = false;
        },
        updateSubscription: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (subscriptionId, profileId, params) => ({
                payload: {
                    subscriptionId,
                    profileId,
                    params,
                },
            }),
        },
        updateSubscriptionSuccess(state) {
            state.loading = false;
            state.error = '';
            state.profileHasUnsavedChanges = false;
        },
        updateSubscriptionError(state, action) {
            state.userProfileError = {
                [action.payload.profileId]: action.payload.error,
            };
            state.loading = false;
        },
        resetUserProfileError(state) {
            state.userProfileError = {};
        },
    },
});

export const {
    fetchUsers,
    fetchUsersError,
    fetchUsersSuccess,
    requestExportUsers,
    requestExportUsersSuccess,
    setFilter,
    setStringFilter,
    setSort,
    setPage,
    resetFilters,
    fetchUser,
    fetchUserSuccess,
    fetchUserError,
    updateUser,
    updateUserSuccess,
    updateUserError,
    anonymizeUser,
    anonymizeUserSuccess,
    anonymizeUserError,
    setUserHasUnsavedChanges,
    redirectToUsersList,
    redirectToUsersListSuccess,
    anonymizeProfile,
    anonymizeProfileSuccess,
    anonymizeProfileError,
    updateUserProfile,
    updateUserProfileSuccess,
    updateUserProfileError,
    setProfileHasUnsavedChanges,
    resetCurrentUser,
    toggleUserEnable,
    toggleUserError,
    toggleUserSuccess,
    toggleUserProfileStatus,
    toggleUserProfileError,
    toggleUserProfileSuccess,
    updateSubscription,
    updateSubscriptionSuccess,
    updateSubscriptionError,
    resetUserProfileError,
    fetchUserDocument,
    fetchUserDocumentError,
    fetchUserDocumentSuccess,
    deleteUserDocument,
    deleteUserDocumentError,
    deleteUserDocumentSuccess,
    updateUserDocument,
    updateUserDocumentError,
    updateUserDocumentSuccess,
    deleteCreditCard,
    addEndUserPenalty,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
