import { UsersAccumulator, FetchedCurrentUser, UserResponse } from './types';

export const mapFetchedUsers = ({
    content,
}: UserResponse): UsersAccumulator => {
    return content.reduce<UsersAccumulator>(
        (acc, user) => {
            const { isEnabled } = user;
            return {
                usersById: {
                    ...acc.usersById,
                    [user.id]: {
                        status: isEnabled,
                        ...user,
                    },
                },
                userIds: [...acc.userIds, user.id],
            };
        },
        { userIds: [], usersById: {} },
    );
};

export const mapFetchedUser = ({
    user,
    profiles,
    ...data
}: FetchedCurrentUser) => {
    const preparedProfiles =
        profiles.length > 0
            ? profiles.map(({ profile, ...fetchedProfileData }) => {
                  const subscriptions = fetchedProfileData.subscriptions.map(
                      ({ subscription, service, ...subscriptionData }) => {
                          return {
                              ...subscription,
                              ...subscriptionData,
                              serviceName: service.name,
                              serviceType: service.type,
                              serviceId: service.id,
                              serviceTimezone: service.timezone,
                          };
                      },
                  );

                  return {
                      ...fetchedProfileData,
                      ...profile,
                      subscriptions,
                  };
              })
            : [];
    return {
        user: { status: `${user.isEnabled}`, ...user },
        profiles: preparedProfiles,
        ...data,
    };
};
