import { Pagination, Sort } from 'ducks/common/types';

export type FetchedLog = {
    id: number;
    entityType: LogEntityType;
    eventType: LogEventType;
    method: string;
    path: string;
    created: string;
    boUpdater: number;
    modifiedId: number;
};

export type LogsById = {
    [code: number]: FetchedLog;
};

export enum FilterTypes {
    Email = 'email',
    StartDate = 'startDate',
    EndDate = 'endDate',
    Entity = 'entity',
}

export type Filters = {
    [FilterTypes.Email]: string;
    [FilterTypes.StartDate]: string;
    [FilterTypes.EndDate]: string;
    [FilterTypes.Entity]?: LogEntityType;
};

export enum Sorting {
    Created = 'created',
    Id = 'ID',
}

export type LogsState = {
    loading: boolean;
    error: string;
    logsIds: number[];
    logsById: LogsById;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
};

export type AdminLogsResponse = {
    content: FetchedLog[];
} & Pagination;

export enum LogEntityType {
    SERVICES = 'SERVICES',
    COMPANIES = 'COMPANIES',
    INVITATIONS = 'INVITATIONS',
    VEHICLES = 'VEHICLES',
    LOCKS = 'LOCKS',
    BO_USERS = 'BO_USERS',
    PROFILES = 'PROFILES',
    OTHER = 'OTHER',
    EMAIL_RECIPIENTS = 'EMAIL_RECIPIENTS',
}

export enum LogEventType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    INVITATIONS = 'DELETE',
    VEHICLES = 'LOGIN',
}
