import { VehicleTypes } from '../ducks/vehicles/common/types';

export const vehicleRoutes = {
    [VehicleTypes.SCOOTER]: '/scooter/:vehicleId',
    [VehicleTypes.BIKE]: '/bike/:vehicleId',
    [VehicleTypes.VAE]: '/vae/:vehicleId',
};

export const routes = {
    LOGIN: '/connexion',
    MAIN_PAGE: '/home-page',
    SERVICES: '/services',
    SERVICE: '/service/:id',
    COMPANY: '/company/:companyId',
    APP_VERSION: '/app-version',
    ADMIN_LOGGER: '/admin-logger',
    COMPANIES: '/companies',
    NEW_SERVICE: '/service/create',
    NEW_SCOOTER: '/scooter/create',
    NEW_COMPANY: '/company/create',
    BO_USERS: '/backoffice-users',
    BO_USER: '/backoffice-users/:userId',
    NEW_BO_USER: '/backoffice-user/create',
    NEW_COMPANY_SERVICE: '/company-invitation/:companyId/new',
    INVITE_TO_SERVICE: '/company-invitation/:companyId/:serviceId',
    SCOOTERS: '/scooters',
    PAGE_NOT_FOUND: '/404',
    SERVICE_CONFIGURATION: '/service/:id/configure',
    TRIPS: '/trips',
    TRIP: '/trips/:tripId',
    NEW_ZONE: '/zone/create',
    ZONES: '/zones',
    PASSWORD_RESTORE: '/restore-password',
    ZONE: '/zones/:zoneId',
    USERS: '/users',
    USER: '/users/:userId',
    VEHICLES_MAP: '/vehicles-map',
    STATIONS: '/stations',
    STATION: '/stations/:stationId',
    NEW_STATION: '/stations/create',
    GROUPS: '/groups',
    GROUP: '/groups/:groupId',
    NEW_GROUP: '/groups/create',
    SCOOTER_MAINTENANCE: '/scooter-maintenance',
    PROFILE: '/profile',
    LEGAL_DOCUMENTS: '/legal-documents',
    BIKES_AND_VAE: '/bikes-and-vae',
    NEW_BIKE_OR_VAE: '/bikes-and-vae/create',
    NEWS: '/news',
    SWAGGER: '/swagger',
    SWAGGER_ADMIN: '/swagger-admin',
    NEW_NEWS: '/news/create',
    ONE_NEWS: '/news/:newsId',
    LOCKS: '/locks',
    LOCK: '/locks/:lockId',
    NEW_LOCK: '/locks/create',
    PARTNERS: '/partners',
    PARTNER: '/partners/:partnerId',
    NEW_PARTNER: '/partners/create',
    PUSH: '/pushes',
    REPORT_DASHBOARD: '/report-dashboard',
    ...vehicleRoutes,
};

export const PAGE_SIZE = 20;

export const BYTES_IN_MB = 1048576;

export const drawerWidth = 230;

export const STATUS_OPTIONS = [
    {
        label: 'Enabled',
        value: 'true',
    },
    {
        label: 'Disabled',
        value: 'false',
    },
];

export const dateFormatConstant = 'YYYY-MM-DD';
export const timeFormatConstant = 'HH:mm';
