import { TagComponent } from '../../common/Tag';
import React, { Dispatch } from 'react';
import { Check } from '../../common/CheckIcon';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/services';
import { Box } from '@material-ui/core';
import { Service } from '../../../ducks/services/types';

const renderCheck = (isDisplayed: boolean) =>
    isDisplayed && (
        <Box textAlign="center">
            <Check />
        </Box>
    );

const renderAction = (isEnabled: boolean, t: TFunction) => (
    <TagComponent
        isSelected={isEnabled}
        label={isEnabled ? t('serviceList.active') : t('serviceList.inactive')}
    />
);

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('serviceList.companyName'),
            field: 'companyName',
            sorting: false,
        },
        {
            title: t('serviceList.serviceName'),
            field: 'name',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'name',
                    }),
                ),
        },
        {
            title: t('serviceList.status'),
            field: 'birthCity',
            render: (rowProps: Service) => renderAction(rowProps.isEnabled, t),
            sorting: false,
        },
        {
            title: t('serviceList.loop'),
            field: 'isLoopEnabled',
            render: (rowProps: Service) => renderCheck(rowProps.isLoopEnabled),
            sorting: false,
        },
        {
            title: t('serviceList.station'),
            field: 'isStationConnected',
            render: (rowProps: Service) =>
                renderCheck(rowProps.isStationConnected),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isStationConnected',
                    }),
                ),
        },
        {
            title: t('serviceList.booking'),
            field: 'isBookingAllowed',
            render: (rowProps: Service) =>
                renderCheck(rowProps.isBookingAllowed),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isBookingAllowed',
                    }),
                ),
        },
        {
            title: t('serviceList.pause'),
            field: 'isPauseAllowed',
            render: (rowProps: Service) => renderCheck(rowProps.isPauseAllowed),
            sorting: false,
        },
        {
            title: t('serviceList.specificMapLayer'),
            field: 'specificMapLayer',
            render: (rowProps: Service) =>
                renderCheck(rowProps.specificMapLayer),
            sorting: false,
        },
        {
            title: t('serviceList.domainRecognition'),
            field: 'isDomainRecognitionEnabled',
            render: (rowProps: Service) =>
                renderCheck(rowProps.isDomainRecognitionEnabled),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isDomainRecognitionEnabled',
                    }),
                ),
        },
        {
            title: t('serviceList.geofence'),
            field: 'isGeofenceEnabled',
            render: (rowProps: Service) =>
                renderCheck(rowProps.isGeofenceEnabled),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isGeofenceEnabled',
                    }),
                ),
        },
        {
            title: t('serviceList.recipientType'),
            field: 'recipientType',
            sorting: false,
        },
        {
            title: t('serviceList.type'),
            field: 'type',
            render: (rowProps: Service) => t(`serviceList.${rowProps.type}`),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'type',
                    }),
                ),
        },
    ];
};
