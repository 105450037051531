import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchTripsMaxDuration,
    resetFilters,
    selectOverdueSort,
    setFilter,
    selectTableMaxTrips,
    selectIsLoading,
} from 'ducks/trips';
import { TableComponent } from 'components/common/Table';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { FetchedTrip, FilterTypes } from 'ducks/trips/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';
import { Container } from './Container';
import { Box } from '@material-ui/core';
import { LinkComponent } from '../../common/Link';

export const TripsOverdueList: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const columns = getColumns(t, dispatch, true);
    const data = useSelector(selectTableMaxTrips);
    const sort = useSelector(selectOverdueSort);
    const loading = useSelector(selectIsLoading);

    useEffect(() => {
        dispatch(fetchTripsMaxDuration());
    }, [dispatch]);

    const redirectToTrip = (id: number) =>
        dispatch(push(routes.TRIP.replace(':tripId', `${id}`)));

    const redirectToOverdueList = useCallback(() => {
        dispatch(resetFilters());
        dispatch(
            setFilter({
                filterType: FilterTypes.MaxDurationOverdue,
                value: true,
            }),
        );
        dispatch(push(routes.TRIPS));
    }, [dispatch]);
    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'visibility',
                        tooltip: t('tripList.seeDetails'),
                        onClick: (event, rowData: FetchedTrip) =>
                            rowData && redirectToTrip(rowData.id),
                    },
                ]}
                onRowClick={(event, rowData: FetchedTrip | undefined) =>
                    rowData && redirectToTrip(rowData.id)
                }
                columns={columns}
                data={data}
                pageSize={data.length}
                title={t('homePage.maxOverdue')}
                sort={sort}
                container={Container}
            />
            <Box>
                <LinkComponent
                    handleClick={redirectToOverdueList}
                    text={t('homePage.seeAllTripsOverdue')}
                />
            </Box>
        </>
    );
};
