import { RootState } from '../root';
import { createSelector } from '@reduxjs/toolkit';
import i18next from 'i18next';

export const selectFilters = (state: RootState) =>
    state.vehicleTelemetryEvents.filters;
export const selectSort = (state: RootState) =>
    state.vehicleTelemetryEvents.sort;
export const selectIsLoading = (state: RootState) =>
    state.vehicleTelemetryEvents.loading;
export const selectPagination = (state: RootState) =>
    state.vehicleTelemetryEvents.pagination;

const selectTelemetryEventsIds = (state: RootState) =>
    state.vehicleTelemetryEvents.telemetryEventIds;

const selectTelemetryEventsById = (state: RootState) =>
    state.vehicleTelemetryEvents.telemetryEventsById;

export const selectTelemetryEventsTableData = createSelector(
    selectTelemetryEventsIds,
    selectTelemetryEventsById,
    (telemetryEventIds, telemetryEventsById) =>
        telemetryEventIds.map(id => ({
            ...telemetryEventsById[id],
        })),
);

export const selectTelemetryEventsFiltersError = createSelector(
    selectFilters,
    filters => {
        const isSomeFilterEmpty = Object.values(filters).some(
            filterValue => filterValue === '',
        );

        if (isSomeFilterEmpty) {
            return String(i18next.t('scooterTelemetryEvents.filtersError'));
        }
        return '';
    },
);
