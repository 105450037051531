import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CurrentDocumentVersion } from './CurrentDocumentVersion';
import { FutureCGAUForm } from './FutureCGAUForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgreements, selectIsLoading } from 'ducks/agreements';
import { FuturePolicyForm } from './FuturePolicyForm';
import { useTranslation } from 'react-i18next';
import { LoaderComponent } from '../../common/Loader';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';

// TODO add permissions for legal documents
export const LegalDocuments = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToUpdate = accessToPages[Permissions.AGREEMENT_UPDATE];

    useEffect(() => {
        dispatch(fetchAgreements());
    }, [dispatch]);
    const loading = useSelector(selectIsLoading);

    return (
        <Box display="flex" justifyContent="space-evenly">
            {loading && <LoaderComponent />}
            <CurrentDocumentVersion />
            {allowedToUpdate && (
                <Box>
                    <Typography align="center" variant="h2">
                        {t('legalDocuments.featureVersion')}
                    </Typography>
                    <FutureCGAUForm />
                    <FuturePolicyForm />
                </Box>
            )}
        </Box>
    );
};
