import { Form, FormSpy } from 'react-final-form';
import React from 'react';
import { ScheduleComponent } from './ScheduleComponent';
import { ButtonComponent, ThemedBoxComponent } from '../../common';
import { Prompt, useParams } from 'react-router';
import {
    fetchServiceSchedule,
    selectIsLoading,
    selectServiceError,
    selectServiceHasUnsavedChanges,
    setServiceHasUnsavedChanges,
    updateServiceSchedule,
    selectCurrentServiceSchedule,
} from 'ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash';
import {
    checkIfSchedulePeriodsOverlap,
    checkIfStartTimeIsLaterThanEnd,
} from './formValidation';

import { DaysOfWeek, ScheduleBody } from 'ducks/services/types';
import AddIcon from '@material-ui/icons/Add';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { ErrorComponent } from '../../common/Error';

export const ServiceSchedule = () => {
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const currentServiceSchedule = useSelector(selectCurrentServiceSchedule);
    const error = useSelector(selectServiceError);
    const serviceHasUnsavedChanges = useSelector(
        selectServiceHasUnsavedChanges,
    );

    const { id } = useParams<{ id?: string }>();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = accessToPages[Permissions.SERVICE_UPDATE];

    const { t } = useTranslation();

    const redirectToService = () =>
        dispatch(push(routes.SERVICE.replace(':id', `${id}`)));

    const emptyFields = React.useMemo(
        () => ({
            dayOfWeek: DaysOfWeek.MONDAY,
            startTime: '',
            endTime: '',
        }),
        [],
    );

    React.useEffect(() => {
        id && dispatch(fetchServiceSchedule(id));
        return () => {
            dispatch(setServiceHasUnsavedChanges(false));
        };
    }, [dispatch, id]);

    const initialValues = React.useMemo(
        () => ({
            entries: currentServiceSchedule.length
                ? currentServiceSchedule
                : [],
        }),
        [currentServiceSchedule],
    );

    const handleConfirm = (values: ScheduleBody) => {
        dispatch(updateServiceSchedule(values, Number(id)));
    };

    useNotificationOnPageLeave(serviceHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 25%" flex="1 0 auto">
            {loading && <LoaderComponent />}
            <Prompt
                when={serviceHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />

            <Form
                onSubmit={handleConfirm}
                mutators={{
                    ...arrayMutators,
                }}
                initialValues={{ ...initialValues }}
                validate={(values: ScheduleBody) => {
                    const endTime = checkIfStartTimeIsLaterThanEnd(values);
                    const overlap = checkIfSchedulePeriodsOverlap(values);
                    return {
                        entries: [...overlap.entries, ...endTime.entries],
                    };
                }}
                render={({
                    handleSubmit,
                    valid,
                    values,
                    form: {
                        mutators: { push, remove },
                    },
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{ values: true }}
                            onChange={({ values }) => {
                                dispatch(
                                    setServiceHasUnsavedChanges(
                                        !isEqual(
                                            initialValues.entries,
                                            values.entries,
                                        ),
                                    ),
                                );
                            }}
                        />
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToService}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>{t('configure')}</p>
                            </Typography>
                        </Box>
                        {values.entries.map((_: any, index: number) => (
                            <Box key={index}>
                                <ScheduleComponent index={index} />
                                {allowedToEdit && (
                                    <IconButton
                                        onClick={() =>
                                            remove('entries', index)
                                        }>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ))}
                        {allowedToEdit && (
                            <FormButtonGroup>
                                <ButtonComponent
                                    handleClick={() =>
                                        push('entries', emptyFields)
                                    }
                                    text={t('add')}
                                    startIcon={<AddIcon />}
                                />
                                <ButtonComponent
                                    handleClick={handleSubmit}
                                    text={t('save')}
                                    isDisabled={
                                        !valid ||
                                        loading ||
                                        isEqual(
                                            initialValues.entries,
                                            values.entries,
                                        )
                                    }
                                />
                            </FormButtonGroup>
                        )}
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
