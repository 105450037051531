import React from 'react';
import { composeValidators, required, isTime } from 'tools/validationRules';
import { SelectField, TextField } from '../../common';
import { useTranslation } from 'react-i18next';
import { Box, createStyles } from '@material-ui/core';
import { DaysOfWeek } from 'ducks/services/types';
import { makeStyles } from '@material-ui/core/styles';
import { FormGrid } from '../../common/FormGrid';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

type ScheduleProps = {
    index: number;
};

const useStyles = makeStyles(() =>
    createStyles({
        fullLine: {
            gridColumnStart: 1,
            gridColumnEnd: 3,
        },
    }),
);
export const ScheduleComponent: React.FC<ScheduleProps> = ({
    index,
}: ScheduleProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const options = React.useMemo(
        () =>
            Object.keys(DaysOfWeek).map(day => ({
                label: t(`daysOfWeek.${day}`),
                value: day,
            })),
        [t],
    );
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = accessToPages[Permissions.SERVICE_UPDATE];
    return (
        <FormGrid columnAmount={2}>
            <Box className={classes.fullLine}>
                <SelectField
                    disabled={!allowedToEdit}
                    name={`entries.${index}.dayOfWeek`}
                    validators={composeValidators(required)}
                    label={t('configureService.dayOfWeek')}
                    options={options}
                    isRequired
                />
            </Box>
            <TextField
                disabled={!allowedToEdit}
                name={`entries.${index}.startTime`}
                validators={composeValidators(required, isTime)}
                type="time"
                label={t('configureService.startTime')}
                isRequired
            />
            <TextField
                disabled={!allowedToEdit}
                name={`entries.${index}.endTime`}
                validators={composeValidators(required, isTime)}
                type="time"
                label={t('configureService.endTime')}
                isRequired
            />
        </FormGrid>
    );
};
