import React from 'react';
import {
    composeValidators,
    required,
    fieldLength,
} from 'tools/validationRules';
import { SelectField, TextField } from '../../../common';
import { useTranslation } from 'react-i18next';
import { Languages } from 'ducks/boUsers/types';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

type ScheduleProps = {
    index: number;
    prefix: string;
    id?: number;
};

export const AddTemplateComponent: React.FC<ScheduleProps> = ({
    index,
    prefix,
    id,
}: ScheduleProps) => {
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = id
        ? accessToPages[Permissions.SERVICE_UPDATE]
        : accessToPages[Permissions.SERVICE_CREATE];
    const { t } = useTranslation();
    const options = React.useMemo(
        () =>
            Object.values(Languages).map(language => ({
                label: language,
                value: language,
            })),
        [],
    );
    return (
        <Box
            display="grid"
            gridGap="10px 20px"
            alignItems="flex-start"
            gridTemplateColumns="repeat(2, minmax(0, 1fr))">
            <TextField
                disabled={!allowedToEdit}
                name={`${prefix}.description`}
                validators={composeValidators(required, (value: string) =>
                    fieldLength(value, 10, 120),
                )}
                label={t('serviceList.description')}
                isRequired
            />
            <SelectField
                disabled={!allowedToEdit}
                name={`${prefix}.language`}
                validators={composeValidators(required)}
                label={t('serviceList.language')}
                options={options}
                isRequired
            />
        </Box>
    );
};
