import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    fetchNews,
    fetchNewsSuccess,
    fetchNewsError,
    fetchOneNews,
    fetchOneNewsError,
    fetchOneNewsSuccess,
    createNews,
    createNewsSuccess,
    createNewsError,
    setPage,
    setSort,
    setStringFilter,
    deleteNewsSuccess,
    deleteNewsError,
    deleteNews,
    updateNewsSuccess,
    updateNewsError,
    updateNews,
} from './newsSlice';
import {
    getNews,
    getOneNews,
    postNews,
    putNews,
    deleteNewsService,
} from './service';
import { News, Filters, Content, ServiceResponse } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { mapFetchedPagination, mapPagination } from '../common/pagination';
import { mapFilters } from '../common/mappers';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { Pagination, Sort } from '../common/types';
import { mapSorting } from '../common/mappers';
import { mapFetchedNews, mapNews } from './mapper';

function* fetchNewsSaga() {
    try {
        const sort: Sort = yield select(selectSort);
        const filters: Filters = yield select(selectFilters);
        const pagination: Pagination = yield select(selectPagination);
        const data: ServiceResponse = yield call(getNews, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { allIds, byId } = mapFetchedNews(data.content);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchNewsSuccess({
                allIds,
                byId,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchNewsError());
    }
}

function* fetchOneNewsSaga({ payload }: PayloadAction<number>) {
    try {
        const data: Content = yield call(getOneNews, payload);
        yield put(fetchOneNewsSuccess(mapNews(data)));
    } catch (error) {
        yield put(fetchOneNewsError({ error: (error as Error)?.message }));
    }
}

function* createNewsSaga({ payload }: PayloadAction<News>) {
    try {
        const data: Content = yield call(postNews, {
            ...payload,
        });
        yield put(createNewsSuccess());
        yield put(
            push(routes.ONE_NEWS.replace(':newsId', String(data.news.id))),
        );
    } catch (error) {
        yield put(createNewsError({ error: (error as Error)?.message }));
    }
}

function* deleteNewsSaga({ payload }: PayloadAction<number>) {
    try {
        yield call(deleteNewsService, payload);
        yield put(deleteNewsSuccess());
        yield put(push(routes.NEWS));
    } catch (error) {
        yield put(deleteNewsError({ error: (error as Error)?.message }));
    }
}

function* updateNewsSaga({ payload }: PayloadAction<News>) {
    try {
        yield call(putNews, payload);
        yield put(updateNewsSuccess());
    } catch (error) {
        yield put(updateNewsError({ error: (error as Error).message }));
    }
}

export default function* newsSagas() {
    yield takeLatest(fetchNews.type, fetchNewsSaga);
    yield takeLatest(fetchOneNews.type, fetchOneNewsSaga);
    yield takeLatest(createNews.type, createNewsSaga);
    yield takeLatest(setPage.type, fetchNewsSaga);
    yield takeLatest(setSort.type, fetchNewsSaga);
    yield takeLatest(setStringFilter.type, fetchNewsSaga);
    yield takeLatest(deleteNews.type, deleteNewsSaga);
    yield takeLatest(updateNews.type, updateNewsSaga);
}
