import React from 'react';
import { composeValidators, isNumber, required } from 'tools/validationRules';
import { SelectField, TextField } from '../../../common';
import { useTranslation } from 'react-i18next';
import { Languages } from 'ducks/boUsers/types';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { useParams } from 'react-router';

type ScheduleProps = {
    index: number;
    prefix: string;
};

export const AddTemplateComponent: React.FC<ScheduleProps> = ({
    index,
    prefix,
}: ScheduleProps) => {
    const accessToPages = useSelector(selectAccessToPages);
    const { companyId } = useParams<{ companyId?: string }>();
    const allowedToEdit =
        accessToPages[
            companyId ? Permissions.COMPANY_UPDATE : Permissions.COMPANY_CREATE
        ];
    const { t } = useTranslation();
    const options = React.useMemo(
        () =>
            Object.values(Languages).map(language => ({
                label: language,
                value: language,
            })),
        [],
    );
    return (
        <Box
            display="grid"
            gridGap="10px 20px"
            alignItems="flex-start"
            gridTemplateColumns="repeat(2, minmax(0, 1fr))">
            <TextField
                disabled={!allowedToEdit}
                name={`${prefix}.${index}.id`}
                validators={composeValidators(required, isNumber)}
                isRequired
                label={t('company.templateId')}
            />
            <SelectField
                disabled={!allowedToEdit}
                name={`${prefix}.${index}.language`}
                validators={composeValidators(required)}
                isRequired
                label={t('company.language')}
                options={options}
            />
        </Box>
    );
};
