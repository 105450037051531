import { PAGE_SIZE } from '../../constants/common';
import { Pagination, SortDirection, SortStore } from './types';

export const defaultPagination = {
    totalPages: 0,
    totalElements: 0,
    size: PAGE_SIZE,
    number: 0,
};

export const commonSetSort = (state: SortStore, value: string) => {
    if (state.sort.value !== value) {
        state.sort.direction = SortDirection.Asc;
    } else {
        state.sort.direction =
            state.sort.direction === SortDirection.Asc
                ? SortDirection.Desc
                : SortDirection.Asc;
    }
    state.sort.value = value;
};

export const mapFetchedPagination = ({
    size,
    totalElements,
    totalPages,
    number,
}: Pagination): Pagination => ({
    size,
    totalElements,
    totalPages,
    number,
});

export const mapPagination = (pagination: Pagination) => {
    return {
        size: pagination.size,
        page: pagination.number,
    };
};
