import request from 'helpers/request';
import {
    AGREEMENTS_URL,
    CURRENT_AGREEMENTS_URL,
    FUTURE_AGREEMENTS_URL,
} from 'constants/api';
import { Agreement, AgreementTypes, InitialAgreement } from './types';

export const getCurrentAgreements = (type: AgreementTypes): Promise<string> =>
    request.get(CURRENT_AGREEMENTS_URL, { params: { type } });

export const getFutureAgreements = (type: AgreementTypes): Promise<string> =>
    request.get(FUTURE_AGREEMENTS_URL, { params: { type } });

export const updateAgreementsService = (
    type: AgreementTypes,
    params: Agreement | InitialAgreement,
): Promise<string> =>
    request.put(`${AGREEMENTS_URL}`, {
        ...params,
        type,
    });
