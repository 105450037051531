import React from 'react';
import { FormGrid, SearchComponent } from 'components/common';
import { useDispatch } from 'react-redux';
import { setStringFilter, StringFilterTypes } from 'ducks/events';
import { useTranslation } from 'react-i18next';

export const EventsSearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.UserKey,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    return (
        <FormGrid columnAmount={2} rowHeight="20px" padding="10px 0">
            <SearchComponent
                placeholder={t('search')}
                onChange={handleSearch}
            />
        </FormGrid>
    );
};
