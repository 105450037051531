import request from 'helpers/request';
import { BOOKING_URL } from 'constants/api';
import qs from 'qs';
import { getFileFromResponse } from 'tools/file';

export const getVehicleBooking = (params: any): Promise<string> => {
    return request.get(
        `${BOOKING_URL}/vehicle?${qs.stringify(params, { indices: false })}`,
    );
};

export const exportBooking = (params: any): Promise<any> => {
    return request
        .get(
            `${BOOKING_URL}/export?${qs.stringify(params, { indices: false })}`,
        )
        .then(response => getFileFromResponse(response));
};

export const cancelBooking = (id: number): Promise<string> => {
    return request.patch(`${BOOKING_URL}/${id}/cancel`);
};
