import { PaginationComponent } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { setInvitationPage, selectInvitationPagination } from 'ducks/companies';
import React from 'react';

export const InvitationPagination = (props: unknown) => {
    const dispatch = useDispatch();

    const pagination = useSelector(selectInvitationPagination);

    const handleSetPage = (page: number) => {
        dispatch(setInvitationPage({ page }));
    };
    return (
        <PaginationComponent
            {...props}
            pagination={pagination}
            setPage={handleSetPage}
        />
    );
};
