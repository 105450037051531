import { Company } from './types';

export const prepareFormData = (params: Company) => {
    const formData = new FormData();
    const { appLogo, boLogo, ...company } = params;
    appLogo &&
        appLogo.file &&
        formData.append('logos', appLogo.file, 'appLogo');
    boLogo && boLogo.file && formData.append('logos', boLogo.file, 'boLogo');

    formData.append(
        'request',
        new Blob([JSON.stringify(company)], {
            type: 'application/json',
        }),
    );
    return formData;
};
