import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';
import { GroupState } from './index';
import { PermissionsByCategory } from './types';

export const initialState: GroupState = {
    loading: false,
    error: '',
    groupIds: [],
    groupsById: {},
    allGroupIds: [],
    allGroupsById: {},
    permissions: {} as PermissionsByCategory,
    pagination: defaultPagination,
    sort: {
        value: 'name',
        direction: SortDirection.Asc,
    },
    groupHasUnsavedChanges: false,
    currentGroup: null,
};

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        fetchGroups(state) {
            state.loading = true;
        },
        fetchGroupsSuccess(state, action) {
            state.groupIds = action.payload.groupIds;
            state.groupsById = action.payload.groupsById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchGroupsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchPermissions(state) {
            state.loading = true;
        },
        fetchPermissionsSuccess(state, action) {
            state.permissions = action.payload;
            state.loading = false;
        },
        fetchPermissionsError(state) {
            state.loading = false;
        },
        fetchGroup: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: groupId => ({
                payload: groupId,
            }),
        },
        fetchGroupSuccess(state, action) {
            state.loading = false;
            state.currentGroup = action.payload;
        },
        fetchGroupError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        createGroup: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.groupHasUnsavedChanges = true;
            },
            prepare: group => ({
                payload: group,
            }),
        },
        createGroupSuccess(state) {
            state.loading = false;
            state.groupHasUnsavedChanges = false;
        },
        createGroupError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateGroup: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.groupHasUnsavedChanges = true;
            },
            prepare: zone => ({
                payload: zone,
            }),
        },
        updateGroupSuccess(state) {
            state.loading = false;
            state.groupHasUnsavedChanges = false;
        },
        updateGroupError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        resetCurrentGroup(state) {
            state.currentGroup = null;
        },
        setGroupHasUnsavedChanges(state, action) {
            state.groupHasUnsavedChanges = action.payload;
        },
        deleteGroup: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: groupId => ({
                payload: groupId,
            }),
        },
        deleteGroupSuccess(state) {
            state.loading = false;
            state.currentGroup = null;
            state.groupHasUnsavedChanges = false;
        },
        deleteGroupError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchAllGroups(state) {
            state.loading = true;
            state.error = '';
        },
        fetchAllGroupsSuccess(state, action) {
            state.allGroupsById = action.payload.byId;
            state.allGroupIds = action.payload.allIds;
            state.loading = false;
        },
        fetchAllGroupsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetError(state) {
            state.error = '';
        },
    },
});

export const {
    fetchGroups,
    fetchGroupsSuccess,
    fetchGroupsError,
    fetchPermissions,
    fetchPermissionsError,
    fetchPermissionsSuccess,
    createGroup,
    createGroupError,
    createGroupSuccess,
    fetchGroup,
    fetchGroupError,
    fetchGroupSuccess,
    setPage,
    setSort,
    setGroupHasUnsavedChanges,
    resetCurrentGroup,
    updateGroup,
    updateGroupError,
    updateGroupSuccess,
    deleteGroup,
    deleteGroupError,
    deleteGroupSuccess,
    fetchAllGroups,
    fetchAllGroupsError,
    fetchAllGroupsSuccess,
    resetError,
} = groupSlice.actions;

export const groupReducer = groupSlice.reducer;
