import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Prompt, useHistory } from 'react-router';
import { ThemedBoxComponent } from '../../common/ThemedBox';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { Languages } from 'ducks/boUsers/types';
import { Box, Typography } from '@material-ui/core';
import {
    ButtonComponent,
    FormGrid,
    SelectField,
    TextField,
} from '../../common';
import {
    composeValidators,
    isValidPassword,
    required,
} from 'tools/validationRules';
import {
    logout,
    selectEnforcePasswordChange,
    selectError,
    selectIsLoading,
    selectMyUser,
    setMyUserHasUnsavedChanges,
    updateMyUser,
} from 'ducks/auth';
import {
    selectErrorCode,
    selectIsMyUserHasUnsavedChanges,
} from 'ducks/auth/selectors';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { EditMyUser } from 'ducks/auth/types';
import { toLineWithBreaks } from 'tools/toLineWithBreaks';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import { useFormMutators } from 'hooks/useFormMutators';
import { routes } from '../../../constants/common';

export const MyProfile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const myUser = useSelector(selectMyUser);
    const loading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    const errorCode = useSelector(selectErrorCode);
    const userHasUnsavedChanges = useSelector(selectIsMyUserHasUnsavedChanges);
    const mustChangeTemporaryPassword = useSelector(
        selectEnforcePasswordChange,
    );
    const [passwordError, setPasswordError] = useState('');
    const failsCount = useRef(0);
    const temporaryPasswordChangeRef = useRef(mustChangeTemporaryPassword);

    useEffect(() => {
        if (
            temporaryPasswordChangeRef.current &&
            !mustChangeTemporaryPassword
        ) {
            history.push(routes.MAIN_PAGE);
        }
    }, [mustChangeTemporaryPassword, history]);

    useEffect(() => {
        if (errorCode === 409) {
            if (failsCount.current >= 2) {
                dispatch(logout());
            } else {
                failsCount.current += 1;
            }
        }
    }, [dispatch, errorCode]);

    const languageOptions = React.useMemo(
        () =>
            Object.values(Languages).map(language => ({
                label: language,
                value: language,
            })),
        [],
    );

    const handleConfirm = useCallback(
        (values: EditMyUser) => {
            if (
                values.newPassword !== values.repeatedNewPassword ||
                values.oldPassword !== values.repeatedOldPassword
            ) {
                setPasswordError(t('myProfile.passwordsError'));
                return;
            }
            dispatch(updateMyUser(values));
        },
        [setPasswordError, dispatch, t],
    );

    const companies = useMemo(
        () => toLineWithBreaks(myUser?.companies || []),
        [myUser],
    );

    const groups = useMemo(
        () => toLineWithBreaks(myUser?.groups || []),
        [myUser],
    );

    const initialValues = React.useMemo(() => {
        return {
            language: myUser?.user.language || Languages.EN,
            email: myUser?.user.email || '',
            newPassword: '',
            repeatedNewPassword: '',
            oldPassword: '',
            repeatedOldPassword: '',
            companies,
            groups,
        };
    }, [myUser, companies, groups]);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setMyUserHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    useNotificationOnPageLeave(userHasUnsavedChanges);

    const mutators = useFormMutators();

    return (
        <ThemedBoxComponent m="0 25%">
            {loading && <LoaderComponent />}
            <Prompt
                when={userHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={(values: EditMyUser) => {
                    handleConfirm(values);
                }}
                initialValues={initialValues}
                mutators={mutators}
                render={({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                dirtySinceLastSubmit: true,
                                submitSucceeded: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Box display="inline-flex">
                            <Typography variant="h1">
                                {t('myProfile.title')}
                            </Typography>
                        </Box>
                        <FormGrid columnAmount={2}>
                            <TextField
                                disabled
                                name="email"
                                label={t('myProfile.email')}
                                withoutCircleIcon
                            />
                            <SelectField
                                name="language"
                                label={t('myProfile.language')}
                                options={languageOptions}
                            />
                            <TextField
                                name="companies"
                                label={t('myProfile.companies')}
                                multiline
                                rows={3}
                                disabled
                                withoutCircleIcon
                            />
                            <TextField
                                name="groups"
                                label={t('myProfile.groups')}
                                multiline
                                rows={3}
                                disabled
                                withoutCircleIcon
                            />
                        </FormGrid>
                        <Typography align="center" variant="h2">
                            {t('myProfile.password')}
                        </Typography>
                        <FormGrid columnAmount={2}>
                            <TextField
                                name="newPassword"
                                placeholder={t('myProfile.passwordPlaceholder')}
                                validators={composeValidators(isValidPassword)}
                                label={t('myProfile.newPassword')}
                            />
                            <TextField
                                name="repeatedNewPassword"
                                placeholder={t(
                                    'myProfile.repeatPasswordPlaceholder',
                                )}
                                validators={composeValidators(isValidPassword)}
                                label={t('myProfile.repeatPassword')}
                            />
                            <TextField
                                name="oldPassword"
                                placeholder={t(
                                    'myProfile.oldPasswordPlaceholder',
                                )}
                                validators={composeValidators(
                                    isValidPassword,
                                    (
                                        value: any,
                                        allValues: { newPassword: string },
                                    ) => {
                                        const { newPassword } = allValues;
                                        return !!newPassword
                                            ? required(value)
                                            : '';
                                    },
                                )}
                                label={t('myProfile.oldPassword')}
                            />
                            <TextField
                                name="repeatedOldPassword"
                                placeholder={t(
                                    'myProfile.repeatOldPasswordPlaceholder',
                                )}
                                validators={composeValidators(isValidPassword)}
                                label={t('myProfile.repeatOldPassword')}
                            />
                        </FormGrid>
                        <FormButtonGroup>
                            <ButtonComponent
                                handleClick={handleSubmit}
                                text={t('update')}
                                isDisabled={
                                    !userHasUnsavedChanges || !valid || loading
                                }
                            />
                        </FormButtonGroup>
                        <ErrorComponent error={passwordError} />
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
