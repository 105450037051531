import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { selectFilters, setStringFilter, StringFilterTypes } from 'ducks/news';
import AddIcon from '@material-ui/icons/Add';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { Box } from '@material-ui/core';

export const SearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToCreate = accessToPages[Permissions.NEWS_CREATE];
    const redirectToNewsCreation = React.useCallback(
        () => dispatch(push(routes.NEW_NEWS)),
        [dispatch],
    );

    const handleSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length !== 1) {
                dispatch(
                    setStringFilter({
                        filterType: StringFilterTypes.Search,
                        value: e.target.value,
                    }),
                );
            }
        },
        [dispatch],
    );

    return (
        <FormGrid columnAmount={2} padding="10px 0" rowHeight="20px">
            <SearchComponent
                placeholder={t('search')}
                onChange={handleSearch}
                defaultValue={filters[StringFilterTypes.Search]}
            />
            {allowedToCreate && (
                <Box textAlign="right">
                    <ButtonComponent
                        text={t('add')}
                        startIcon={<AddIcon />}
                        handleClick={redirectToNewsCreation}
                    />
                </Box>
            )}
        </FormGrid>
    );
};
