import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

export const LoaderComponent: React.FC = () => (
    <Box
        zIndex={100}
        display="flex"
        borderRadius="25px"
        top={0}
        left={0}
        bottom={0}
        right={0}
        bgcolor="rgba(0, 0, 0, 0.1)"
        position="absolute">
        <Box m="auto">
            <CircularProgress />
        </Box>
    </Box>
);
