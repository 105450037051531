import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableComponent } from 'components/common/Table';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import {
    selectLogsTableData,
    selectSort,
    selectIsLoading,
    fetchLogs,
} from 'ducks/logs';
import { LogsSearchToolbar } from './LogsSearchToolbar';
import { LogsToolbar } from './LogsToolbar';
import { LogPagination } from './Pagination';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const AdminLogger: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const columns = getColumns(t, dispatch);
    const tableData = useSelector(selectLogsTableData);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    useEffect(() => {
        dispatch(fetchLogs());
    }, [dispatch]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                toolbar={LogsToolbar}
                searchComponent={LogsSearchToolbar}
                columns={columns}
                pagination={LogPagination}
                data={tableData}
                title={t('logs.title')}
                sort={sort}
            />
        </>
    );
};
