import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid, SelectComponent } from 'components/common';
import { selectFilters } from 'ducks/boUsers/selectors';
import { FilterTypes } from 'ducks/boUsers/types';
import { fetchBOUsers, resetFilters, setFilter } from 'ducks/boUsers';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { useParams } from 'react-router';
import {
    fetchAllGroups,
    selectAllGroupIds,
    selectAllGroupsById,
} from 'ducks/groups';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';

export const Toolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companyId } = useParams<{ companyId?: string }>();
    const filters = useSelector(selectFilters);
    const allGroupIds = useSelector(selectAllGroupIds);
    const allGroupsById = useSelector(selectAllGroupsById);
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);

    useEffect(() => {
        dispatch(fetchAllCompanies());
        dispatch(fetchAllGroups());
    }, [dispatch]);

    const groupOptions = useMemo<
        { label: string; value: number | string }[]
    >(() => {
        return [
            {
                label: '-',
                value: '',
            },
            ...allGroupIds.map(id => ({
                label: allGroupsById[id].name,
                value: id,
            })),
        ];
    }, [allGroupIds, allGroupsById]);

    const companyOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );

    const statusOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            {
                label: 'Enabled',
                value: 'true',
            },
            {
                label: 'Disabled',
                value: 'false',
            },
        ],
        [],
    );

    const customerStatusOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            {
                label: t('backofficeUsers.isManager'),
                value: 'true',
            },
            {
                label: t('backofficeUsers.notManager'),
                value: 'false',
            },
        ],
        [t],
    );

    const handleChangeFilter = (
        e: React.ChangeEvent<HTMLInputElement>,
        filterType: FilterTypes,
    ) => {
        dispatch(
            setFilter({
                filterType,
                value: e.target.value,
            }),
        );
    };

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        companyId
            ? dispatch(
                  setFilter({
                      filterType: FilterTypes.CompanyId,
                      value: companyId,
                  }),
              )
            : dispatch(fetchBOUsers());
    }, [dispatch, companyId]);

    return (
        <FormGrid columnAmount={5} padding="10px 0">
            <SelectComponent
                label={t('backofficeUsers.status')}
                onChange={e => handleChangeFilter(e, FilterTypes.Status)}
                value={filters.status}
                options={statusOptions}
            />
            <SelectComponent
                label={t('backofficeUsers.customerStatus')}
                onChange={e =>
                    handleChangeFilter(e, FilterTypes.CustomerStatus)
                }
                value={filters.customerStatus}
                options={customerStatusOptions}
            />
            <SelectComponent
                label={t('backofficeUsers.group')}
                onChange={e => handleChangeFilter(e, FilterTypes.GroupId)}
                value={filters.groupId}
                options={groupOptions}
            />
            {!companyId && (
                <SelectComponent
                    label={t('backofficeUsers.company')}
                    onChange={e => handleChangeFilter(e, FilterTypes.CompanyId)}
                    value={filters.companyId}
                    options={companyOptions}
                />
            )}
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};
