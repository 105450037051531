import { takeLatest, call, put } from 'redux-saga/effects';
import { sendPushSuccess, sendPushError, sendPush } from './pushesSlice';
import { postPush } from './service';
import { Push } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { setNotification } from 'ducks/ui';
import i18next from 'i18next';
import { NotificationTypes } from '../ui/types';

function* sendPushSaga({ payload }: PayloadAction<Push>) {
    try {
        const data: {
            endUserIds: number[];
        } = yield call(postPush, payload);
        yield put(sendPushSuccess());
        yield put(
            setNotification({
                message: i18next.t('pushes.pushNotification', {
                    num: data.endUserIds.length,
                }),
                type: NotificationTypes.SUCCESS,
            }),
        );
    } catch (error) {
        yield put(sendPushError({ error: (error as Error)?.message }));
    }
}
export default function* scooterSagas() {
    yield takeLatest(sendPush.type, sendPushSaga);
}
