import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';
import { PartnersState } from './types';

export const initialState: PartnersState = {
    loading: false,
    error: '',
    partnersIds: [0],
    partnersById: {},
    currentPartner: null,
    partnerHasUnsavedChanges: false,
    sort: {
        value: 'name',
        direction: SortDirection.Asc,
    },
    pagination: defaultPagination,
};

const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {
        fetchPartners(state) {
            state.loading = true;
            state.error = '';
        },
        fetchPartnersSuccess(state, action) {
            state.partnersIds = action.payload.allIds;
            state.partnersById = action.payload.byId;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchPartnersError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        requestExportPartners(state) {
            state.loading = true;
            state.error = '';
        },
        requestExportPartnersSuccess(state) {
            state.loading = false;
        },
        createPartner: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: partner => ({
                payload: partner,
            }),
        },
        createPartnerSuccess(state) {
            state.partnerHasUnsavedChanges = false;
            state.loading = false;
        },
        createPartnerError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setPartnerHasUnsavedChanges(state, action) {
            state.partnerHasUnsavedChanges = action.payload;
        },
        fetchPartner: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: id => ({
                payload: id,
            }),
        },
        fetchPartnerSuccess(state, action) {
            state.currentPartner = action.payload;
            state.loading = false;
        },
        fetchPartnerError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        deletePartner: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: newsId => ({
                payload: newsId,
            }),
        },
        deletePartnerSuccess(state) {
            state.loading = false;
            state.partnerHasUnsavedChanges = false;
            state.error = '';
        },
        deletePartnerError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        togglePartnerEnable: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (id, isEnabled) => ({
                payload: { id, isEnabled },
            }),
        },
        togglePartnerSuccess(state) {
            state.loading = false;
        },
        togglePartnerError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updatePartner: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: partner => ({
                payload: partner,
            }),
        },
        updatePartnerSuccess(state) {
            state.loading = false;
            state.error = '';
            state.partnerHasUnsavedChanges = false;
        },
        updatePartnerError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
    },
});
export const {
    fetchPartners,
    fetchPartnersError,
    fetchPartnersSuccess,
    setPage,
    setSort,
    requestExportPartners,
    requestExportPartnersSuccess,
    createPartner,
    createPartnerSuccess,
    createPartnerError,
    setPartnerHasUnsavedChanges,
    fetchPartner,
    fetchPartnerSuccess,
    fetchPartnerError,
    deletePartner,
    deletePartnerSuccess,
    deletePartnerError,
    togglePartnerEnable,
    togglePartnerSuccess,
    togglePartnerError,
    updatePartner,
    updatePartnerSuccess,
    updatePartnerError,
} = partnersSlice.actions;

export const partnersReducer = partnersSlice.reducer;
