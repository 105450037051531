import { Chip, createStyles, Theme, withStyles } from '@material-ui/core';
import React from 'react';
import { colors } from 'themes/colors';

interface ITagInterface {
    label: string;
    isSelected: boolean;
}

const StyledTag = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: colors.white,
            border: `2px solid ${theme.palette.primary.main}`,
            backgroundColor: `${theme.palette.primary.main}`,
            borderRadius: '8px',
            height: '28px',
            width: '100%',
        },
        outlined: {
            backgroundColor: `transparent`,
            color: `${colors.black}`,
        },
    }),
)(Chip);

export const TagComponent: React.FC<ITagInterface> = ({
    label,
    isSelected,
}: ITagInterface) => (
    <StyledTag label={label} variant={isSelected ? 'default' : 'outlined'} />
);
