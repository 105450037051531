import React from 'react';

export const useNotificationOnPageLeave = (condition: boolean) => {
    React.useEffect(() => {
        const restrictFromLeaving = (event: Event) => {
            if (condition) {
                event.returnValue = true;
            }
        };
        window.addEventListener('beforeunload', restrictFromLeaving);
        return () =>
            window.removeEventListener('beforeunload', restrictFromLeaving);
    }, [condition]);
};
