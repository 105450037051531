import { DaysOfWeek } from 'ducks/services/types';
import { TFunction } from 'i18next';

const DaysOfWeekArray = [
    DaysOfWeek.SUNDAY,
    DaysOfWeek.MONDAY,
    DaysOfWeek.TUESDAY,
    DaysOfWeek.WEDNESDAY,
    DaysOfWeek.THURSDAY,
    DaysOfWeek.FRIDAY,
    DaysOfWeek.SATURDAY,
];

const getWeekDaysByBytes = (value: any, t: TFunction) => {
    if (value === 254) {
        return t('all');
    }
    const byteStringArray = (value as number).toString(2).split('');
    byteStringArray.reverse();
    const weekdays = byteStringArray.reduce(
        (acc: string[], value: string, index: number) => {
            if (value === '1') {
                acc.push(t('daysOfWeek.' + DaysOfWeekArray[index - 1]));
            }
            return acc;
        },
        [],
    );
    return weekdays.join(', ');
};

const configMapper = (name: string, value: any, t: TFunction) => {
    if (value) {
        let result;
        switch (name) {
            case 'days_when_communication_is_enabled':
                result = getWeekDaysByBytes(value, t);
                break;

            case 'enable_stop_hour':
            case 'enable_start_hour':
                result = `${((value + 11) % 12) + 1} ${
                    value >= 12 ? 'PM' : 'AM'
                }`;
                break;

            default:
                result = value;
                break;
        }
        return result;
    }

    return value;
};

export default configMapper;
