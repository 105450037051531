import request from 'helpers/request';
import { USERS_EXPORT_URL, USERS_URL } from 'constants/api';
import { getFileFromResponse, getFileToDisplayFromResponse } from 'tools/file';
import { ProfileStatuses } from './types';
import qs from 'qs';

export const getUsers = ({ sort, ...params }: any): Promise<string> => {
    const [sortField] = sort.split(',');

    if (!sortField || sortField === '') {
        return request.get(USERS_URL, { params });
    }

    return request.get(USERS_URL, { params: { sort, ...params } });
};

export const exportUsers = (params: any): Promise<any> =>
    request
        .get(USERS_EXPORT_URL, { params })
        .then(response => getFileFromResponse(response));

export const getUser = (id: number): Promise<string> =>
    request.get(`${USERS_URL}/${id}`);

export const anonymizeUserService = (id: number): Promise<string> =>
    request.patch(`${USERS_URL}/${id}/anonymize`);

export const updateUserStatusService = ({
    id,
    isEnabled,
}: {
    id: number;
    isEnabled: boolean;
}): Promise<string> =>
    request.patch(`${USERS_URL}/${id}/updateStatus?isEnabled=${isEnabled}`);

export const updateUserService = (id: number, params: any): Promise<string> =>
    request.put(`${USERS_URL}/${id}`, { ...params });

export const requestDeleteUserService = (
    userId: number,
    serviceId: number,
): Promise<string> =>
    request.delete(`${USERS_URL}/${userId}/subscriptions/${serviceId}`);

export const anonymizeProfileService = (
    userId: number,
    profileId: number,
): Promise<string> =>
    request.delete(`${USERS_URL}/${userId}/profiles/${profileId}`);

export const updateUserProfileEmailService = ({
    userId,
    profileId,
    email,
}: any): Promise<string> =>
    request.patch(
        `${USERS_URL}/${userId}/profiles/${profileId}/updateEmail?email=${email}`,
    );

export const updateUserProfileStatusService = ({
    userId,
    profileId,
    status,
}: {
    userId: number;
    profileId: number;
    status: ProfileStatuses;
}): Promise<string> =>
    request.patch(
        `${USERS_URL}/v2/${userId}/profiles/${profileId}/updateStatus?status=${status}`,
    );

export const updateSubscriptionService = ({
    userId,
    subscriptionId,
    params,
}: {
    userId: number;
    subscriptionId: number;
    params: { startDate?: string; endDate?: string };
}): Promise<string> =>
    request.patch(
        `${USERS_URL}/${userId}/subscriptions/${subscriptionId}`,
        params,
    );
export const postUserDocument = ({
    userId,
    file,
}: {
    userId: number;
    file: File;
}): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append(
        'request',
        new Blob([JSON.stringify({ endUserId: userId })], {
            type: 'application/json',
        }),
    );
    return request.post(`${USERS_URL}/documents`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};
export const getUserDocument = ({
    userId,
    filename,
}: {
    userId: number;
    filename: string;
}): Promise<any> => {
    return request
        .get(`${USERS_URL}/${userId}/documents/${filename}`, {
            responseType: 'arraybuffer',
        })
        .then(response => getFileToDisplayFromResponse(response, filename));
};
export const removeUserDocument = ({
    userId,
    filename,
}: {
    userId: number;
    filename: string;
}): Promise<string> => {
    return request.delete(`${USERS_URL}/${userId}/documents/${filename}`);
};

export const deleteEndUserCreditCard = ({
    endUser,
    profileId,
}: {
    endUser: number;
    profileId: number;
}): Promise<any> =>
    request.delete(`${USERS_URL}/${endUser}/profiles/${profileId}/card`);

export const addPenalty = ({
    userId,
    profileId,
    params,
}: {
    userId: number;
    profileId: number;
    params: { amount: number; comment?: string; tripId?: number };
}): Promise<string> => {
    return request.patch(
        `${USERS_URL}/${userId}/profiles/${profileId}/addPenalty?${qs.stringify(
            params,
            { indices: false },
        )}`,
    );
};
