import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'ducks/root';
import { Modal, DialogContent } from '@material-ui/core';
import { modalsConfig } from './config';
import { closeModal } from 'ducks/ui';

export const ModalContainer: React.FC = () => {
    const { openedModals } = useSelector((state: RootState) => state.ui);
    const SpecificModal = modalsConfig[openedModals[0]?.modalType];
    const modalProps = {
        ...openedModals[0]?.modalProps,
        handleClose: () => dispatch(closeModal()),
    };
    const dispatch = useDispatch();
    return openedModals[0] ? (
        <Modal
            style={{ display: 'flex' }}
            open={true}
            onClose={() => dispatch(closeModal())}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <DialogContent>
                <SpecificModal {...modalProps} />
            </DialogContent>
        </Modal>
    ) : (
        <></>
    );
};
