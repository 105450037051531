import request from 'helpers/request';
import { GROUPS_URL, PERMISSIONS_URL } from 'constants/api';
import { Group } from './types';

export const getGroups = ({ sort, ...params }: any): Promise<string> =>
    request.get(GROUPS_URL, { params: { sort, ...params } });

export const getPermissions = (): Promise<string> => {
    return request.get(PERMISSIONS_URL);
};

export const postGroup = (params: Group): Promise<string> =>
    request.post(`${GROUPS_URL}`, { ...params });

export const getAllGroups = (): Promise<string> =>
    request.get(`${GROUPS_URL}/all`);

export const getGroup = (id: number): Promise<string> =>
    request.get(`${GROUPS_URL}/${id}`);

export const putGroup = (params: Group): Promise<string> =>
    request.put(`${GROUPS_URL}/${params.id}`, { ...params });

export const deleteGroupService = (id: number): Promise<string> =>
    request.delete(`${GROUPS_URL}/${id}`);
