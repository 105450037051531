import {
    composeValidators,
    fieldLength,
    isNumber,
    maxValue,
    minValue,
    required,
} from 'tools/validationRules';

export const validations = {
    name: composeValidators(required, (value: string) =>
        fieldLength(value, 2, 50),
    ),
    description: composeValidators(
        (value: string) => value && fieldLength(value, 10, 120),
    ),
    borderColor: composeValidators(required),
    transparency: composeValidators(
        required,
        isNumber,
        (value: string) => minValue(value, 0),
        (value: string) => maxValue(value, 100),
    ),
    latitude: composeValidators(
        required,
        isNumber,
        (value: string) => minValue(value, -90),
        (value: string) => maxValue(value, 90),
    ),
    longitude: composeValidators(
        required,
        isNumber,
        (value: string) => minValue(value, -180),
        (value: string) => maxValue(value, 180),
    ),
    serviceId: composeValidators(required),
};
