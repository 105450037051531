import request from 'helpers/request';

export const getVehicleTelemetryEvents = (
    params: any,
    vehicleId: number,
): Promise<string> =>
    request.get(`/backoffice/vehicles/telemetry/events`, {
        params: {
            ...params,
            vehicleId,
        },
    });
