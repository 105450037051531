import React, { useCallback } from 'react';
import {
    ButtonComponent,
    FormGrid,
    SelectField,
    TextField,
} from '../../common';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Languages } from 'ducks/boUsers/types';
import arrayMutators from 'final-form-arrays';
import { Form, FormSpy } from 'react-final-form';
import AddIcon from '@material-ui/icons/Add';
import { composeValidators, required, isLink } from 'tools/validationRules';
import { VersionField } from './VersionField';
import DeleteIcon from '@material-ui/icons/Delete';
import { Agreement, InitialAgreement } from 'ducks/agreements/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { Prompt } from 'react-router';
import { setAgreementsHasUnsavedChanges } from 'ducks/agreements';
import { selectAgreementsHasUnsavedChanges } from 'ducks/agreements/selectors';
import { FormState } from 'final-form';

type FutureFormProps = {
    title: string;
    initialValues: Agreement | InitialAgreement;
    handleUpdate: (values: Agreement | InitialAgreement) => void;
    prefix: string;
};

export const FutureForm: React.FC<FutureFormProps> = ({
    title,
    initialValues,
    handleUpdate,
    prefix,
}: FutureFormProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToUpdate = accessToPages[Permissions.AGREEMENT_UPDATE];
    const agreementsHasUnsavedChanges = useSelector(
        selectAgreementsHasUnsavedChanges,
    );

    const options = React.useMemo(
        () =>
            Object.values(Languages).map(language => ({
                label: language,
                value: language,
            })),
        [],
    );

    const emptyFields = React.useMemo(
        () => ({
            language: Languages.EN,
            url: '',
        }),
        [],
    );

    const handleUpdateCGAU = useCallback(
        (values: Agreement | InitialAgreement) => {
            handleUpdate(values);
        },
        [handleUpdate],
    );

    useNotificationOnPageLeave(agreementsHasUnsavedChanges);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setAgreementsHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    return (
        <>
            <Prompt
                when={agreementsHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={values => {
                    handleUpdateCGAU(values);
                }}
                initialValues={initialValues}
                mutators={{
                    ...arrayMutators,
                }}
                render={({
                    handleSubmit,
                    valid,
                    values,
                    form: {
                        mutators: { push, remove },
                    },
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <FormGrid columnAmount={1} rowHeight="20px">
                            <Typography align="left" variant="h3">
                                {title}
                            </Typography>
                            <Box
                                display="grid"
                                gridGap="10px 20px"
                                alignItems="flex-start"
                                gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                                <VersionField id={`${prefix} - date`} />
                            </Box>
                            {values.links &&
                                values.links.map((_: any, index: number) => {
                                    return (
                                        <Box
                                            key={`${prefix} - ${index}`}
                                            display="grid"
                                            gridGap="10px 20px"
                                            alignItems="flex-start"
                                            gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                                            <SelectField
                                                name={`links.${index}.language`}
                                                label={t(
                                                    'legalDocuments.language',
                                                )}
                                                options={options}
                                            />
                                            <Box
                                                display="flex"
                                                alignItems="center">
                                                <TextField
                                                    name={`links.${index}.url`}
                                                    validators={composeValidators(
                                                        isLink,
                                                        required,
                                                    )}
                                                    label={t(
                                                        'legalDocuments.link',
                                                    )}
                                                    id={`${prefix} - ${index}`}
                                                    isRequired
                                                />
                                                {allowedToUpdate && (
                                                    <Box width="48px">
                                                        <IconButton
                                                            onClick={() =>
                                                                remove(
                                                                    'links',
                                                                    index,
                                                                )
                                                            }>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            <FormButtonGroup>
                                <ButtonComponent
                                    text={t('add')}
                                    isDisabled={!allowedToUpdate}
                                    startIcon={<AddIcon />}
                                    handleClick={() =>
                                        push('links', emptyFields)
                                    }
                                />
                                <ButtonComponent
                                    text={t('update')}
                                    handleClick={handleSubmit}
                                    isDisabled={
                                        !valid ||
                                        !agreementsHasUnsavedChanges ||
                                        !allowedToUpdate
                                    }
                                />
                            </FormButtonGroup>
                        </FormGrid>
                    </form>
                )}
            />
        </>
    );
};
