import React from 'react';
import CrossIcon from '@material-ui/icons/Clear';
import { createStyles, SvgIcon, withStyles } from '@material-ui/core';
import { colors } from 'themes/colors';

const StyledSvgIcon = withStyles(() =>
    createStyles({
        root: {
            color: colors.red,
        },
    }),
)(SvgIcon);

export const Cross: React.FC = () => (
    <StyledSvgIcon>
        <CrossIcon />
    </StyledSvgIcon>
);
