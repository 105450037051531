import request from 'helpers/request';
import { USERS_URL } from 'constants/api';

export const getPaymentHistory = ({
    userId,
    params,
    profileId,
}: {
    userId: number;
    profileId: number;
    params: any;
}): Promise<string> => {
    return request.get(
        `${USERS_URL}/${userId}/profiles/${profileId}/paymentHistory`,
        { params },
    );
};
