import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'ducks/root';
import { Form, FormSpy } from 'react-final-form';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton, Typography } from '@material-ui/core';
import { NewUserComponent } from './NewUserComponent';
import arrayMutators from 'final-form-arrays';
import { ButtonComponent, ThemedBoxComponent } from '../../common';
import {
    inviteUsers,
    selectCompanyError,
    selectInvitationHasUnsavedChanges,
    selectIsLoading,
    setActiveCompanyTab,
    setCurrentCompanyId,
} from 'ducks/companies';
import { Languages } from 'ducks/boUsers/types';
import {
    checkIfSchedulePeriodsOverlap,
    checkIfStartTimeIsLaterThanEnd,
} from './formValidation';
import { Invitation } from 'ducks/companies/types';
import { Prompt, useParams } from 'react-router';
import {
    fetchAvailableVehicles,
    setInvitationHasUnsavedChanges,
} from 'ducks/companies';
import { ImportUsersComponent } from './ImportUsers';
import { fetchService } from 'ducks/services';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import BackIcon from '@material-ui/icons/ArrowBack';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { UsageTypes } from '../../../ducks/services/types';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import { resetError } from 'ducks/companies';
import { useFormMutators } from 'hooks/useFormMutators';

export const AddUsersComponent: React.FC = () => {
    const { t } = useTranslation();
    const { serviceId, companyId } =
        useParams<{ companyId?: string; serviceId?: string }>();
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const error = useSelector(selectCompanyError);
    const invitationHasUnsavedChanges = useSelector(
        selectInvitationHasUnsavedChanges,
    );

    const { currentService } = useSelector((state: RootState) => state.service);

    useNotificationOnPageLeave(invitationHasUnsavedChanges);

    React.useEffect(() => {
        dispatch(resetError());
        dispatch(setCurrentCompanyId({ id: Number(companyId) }));
        dispatch(fetchService(serviceId));
    }, [dispatch, serviceId, companyId]);

    React.useEffect(() => {
        currentService.type === UsageTypes.LONG_TERM_USAGE &&
            dispatch(fetchAvailableVehicles(serviceId));
    }, [dispatch, serviceId, currentService]);

    const handleConfirm = (values: Invitation) => {
        dispatch(inviteUsers(values));
    };

    const redirectToCompany = React.useCallback(() => {
        dispatch(setActiveCompanyTab(1));
        dispatch(push(routes.COMPANY.replace(':companyId', companyId || '')));
    }, [companyId, dispatch]);

    const emptyFields = React.useMemo(
        () => ({
            language: Languages.EN,
        }),
        [],
    );

    const initialValues = React.useMemo(
        () => ({
            invitations: [],
            serviceId: serviceId,
        }),
        [serviceId],
    );

    React.useEffect(() => {
        return () => {
            dispatch(setInvitationHasUnsavedChanges(false));
        };
    }, [dispatch]);

    const basicMutators = useFormMutators();

    const mutators = React.useMemo(
        () => ({
            ...basicMutators,
            ...arrayMutators,
        }),
        [basicMutators],
    );

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setInvitationHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    return (
        <ThemedBoxComponent m="0 auto">
            {loading && <LoaderComponent />}

            <Prompt
                when={invitationHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                mutators={{
                    ...mutators,
                }}
                initialValues={initialValues}
                validate={(values: Invitation) => {
                    const endTime = checkIfStartTimeIsLaterThanEnd(values);
                    const overlap = checkIfSchedulePeriodsOverlap(values);
                    return {
                        invitations: [
                            ...overlap.invitations,
                            ...endTime.invitations,
                        ],
                    };
                }}
                onSubmit={handleConfirm}
                render={({
                    values,
                    handleSubmit,
                    valid,
                    form: {
                        mutators: { push, remove, resetField },
                    },
                }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToCompany}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {t('invitations.title')}
                                    {` (${currentService.name})`}
                                </p>
                            </Typography>
                        </Box>
                        <Typography variant="h2">{t('importFile')}</Typography>
                        <ImportUsersComponent />
                        <Typography variant="h2">{`-${t('or')}-`}</Typography>
                        {values.invitations.map((_: any, index: number) => (
                            <>
                                <NewUserComponent
                                    resetField={fieldName =>
                                        resetField({ field: fieldName })
                                    }
                                    index={index}
                                    values={values}
                                    remove={remove}
                                />
                                <Box>
                                    <IconButton
                                        onClick={() =>
                                            remove('invitations', index)
                                        }>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </>
                        ))}
                        <FormButtonGroup>
                            {values.invitations.length < 10 && (
                                <ButtonComponent
                                    handleClick={() =>
                                        push('invitations', emptyFields)
                                    }
                                    text={t('add')}
                                    startIcon={<AddIcon />}
                                />
                            )}
                            <ButtonComponent
                                handleClick={handleSubmit}
                                text={t('save')}
                                isDisabled={
                                    !invitationHasUnsavedChanges ||
                                    !valid ||
                                    loading
                                }
                            />
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
