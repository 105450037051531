import { LockFromList, Accumulator } from './types';

export const mapFetchedLocks = (content: LockFromList[]): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, { lock, vehicle, station }) => {
            return {
                locksById: {
                    ...acc.locksById,
                    [lock.id]: { lock, vehicle, station },
                },
                lockIds: [...acc.lockIds, lock.id],
            };
        },
        { lockIds: [], locksById: {} },
    );
};
