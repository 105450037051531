import { useField } from 'react-final-form';
import { composeValidators, required } from 'tools/validationRules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    RecipientTypes,
    SelectedService,
    UsageTypes,
} from 'ducks/services/types';
import { SelectComponent } from '../../../common/Select';
import { FormApi } from 'final-form';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

type RecipientType = {
    id?: number;
    type: UsageTypes;
    form: FormApi<SelectedService>;
};
export const RecipientTypeField: React.FC<RecipientType> = ({
    type,
    id,
    form,
}: RecipientType) => {
    const { t } = useTranslation();
    const { input } = useField('recipientType', {
        validate: composeValidators(required),
    });
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = id
        ? accessToPages[Permissions.SERVICE_UPDATE]
        : accessToPages[Permissions.SERVICE_CREATE];
    const options = React.useMemo(
        () => [
            {
                label: t(RecipientTypes.B_TO_B),
                value: RecipientTypes.B_TO_B,
            },
            {
                label: t(RecipientTypes.B_TO_C),
                value: RecipientTypes.B_TO_C,
                disabled: type === UsageTypes.LONG_TERM_USAGE,
            },
            {
                label: t(RecipientTypes.B_TO_B_TO_C),
                value: RecipientTypes.B_TO_B_TO_C,
                disabled: type === UsageTypes.LONG_TERM_USAGE,
            },
        ],
        [t, type],
    );
    return (
        <SelectComponent
            {...input}
            disabled={!!id || !allowedToEdit}
            onChange={e => {
                input.onChange(e.target.value);
                if (e.target.value !== RecipientTypes.B_TO_C) {
                    form.mutators.resetBooleanField({
                        field: 'isBookingAllowed',
                    });
                }
                if (e.target.value === RecipientTypes.B_TO_B) {
                    form.mutators.resetBooleanField({
                        field: 'isOpenToPublic',
                    });
                }
            }}
            label={`${t('serviceList.recipientType')}*`}
            options={options}
        />
    );
};
