import { PaginationComponent } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { selectPagination, setPage } from 'ducks/users';

export const Pagination = (props: unknown) => {
    const dispatch = useDispatch();
    const pagination = useSelector(selectPagination);

    const handleSetPage = (page: number) => {
        dispatch(setPage({ page }));
    };

    return (
        <PaginationComponent
            {...props}
            pagination={pagination}
            setPage={handleSetPage}
        />
    );
};
