import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from 'components/common';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { requestExportTrips, setFilter } from 'ducks/trips';
import { FilterTypes } from 'ducks/trips/types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useParams } from 'react-router';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { selectFilters } from 'ducks/trips/selectors';

export const TripSearchToolbar = () => {
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);

    const handleExportTrips = () => {
        dispatch(requestExportTrips());
    };
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToExport = accessToPages[Permissions.TRIP_EXPORT];
    const handleVehicleSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setFilter({
                    filterType: FilterTypes.VehicleKey,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    const handleUserSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setFilter({
                    filterType: FilterTypes.UserKey,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    return (
        <FormGrid
            columnAmount={vehicleId ? 2 : 3}
            padding="10px 0"
            rowHeight="20px">
            {!vehicleId && (
                <SearchComponent
                    id="vehicleSearch"
                    placeholder={t('tripList.vehiclePlaceholder')}
                    onChange={handleVehicleSearch}
                    defaultValue={filters[FilterTypes.VehicleKey]}
                />
            )}
            <SearchComponent
                id="userSearch"
                placeholder={t('tripList.userPlaceholder')}
                onChange={handleUserSearch}
                defaultValue={filters[FilterTypes.UserKey]}
            />
            {allowedToExport && (
                <Box textAlign="right">
                    <ButtonComponent
                        text={t('download')}
                        startIcon={<SaveAltIcon />}
                        handleClick={handleExportTrips}
                    />
                </Box>
            )}
        </FormGrid>
    );
};
