import request from 'helpers/request';
import { getFileFromResponse } from 'tools/file';
import { STATS_URL, TRIPS_EXPORT_URL, TRIPS_URL } from 'constants/api';
import { Moment } from 'moment';
import qs from 'qs';

export const getTrips = (params: any): Promise<string> =>
    request.get(`${TRIPS_URL}?${qs.stringify(params, { indices: false })}`);

export const exportTrips = (params: any): Promise<any> =>
    request
        .get(TRIPS_EXPORT_URL, { params })
        .then(response => getFileFromResponse(response));

export const getTrip = (id: number): Promise<string> =>
    request.get(`${TRIPS_URL}/${id}`);

export const getStatistics = (params: any): Promise<string> =>
    request.get(STATS_URL, { params });

export const stopTripService = ({
    tripId,
    endDate,
}: {
    tripId: number;
    endDate: string | Moment;
}): Promise<string> => request.patch(`${TRIPS_URL}/${tripId}/end`, { endDate });
