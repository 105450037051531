import { ProfileStatuses } from 'ducks/users/types';
import { TFunction } from 'i18next';

export const getStatusOptions = (t: TFunction) => [
    {
        label: t(`enabled`),
        value: ProfileStatuses.ENABLED,
    },
    {
        label: t(`blocked`),
        value: ProfileStatuses.BLOCKED,
    },
    {
        label: t(`disabled`),
        value: ProfileStatuses.DISABLED,
    },
];
