import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid, SelectComponent } from 'components/common';
import { FilterTypes, TripStatuses } from 'ducks/trips/types';
import { selectFilters } from 'ducks/trips/selectors';
import {
    fetchTrips,
    resetFilters,
    setFilter,
    setMaxDurationFilter,
} from 'ducks/trips';
import { VehicleTypes } from 'ducks/vehicles/common/types';
import { RootState } from 'ducks/root';
import { fetchAllZones } from 'ducks/zones';
import { useParams } from 'react-router';
import { DateTimeInput } from '../../common/DateTimeInput';
import moment, { Moment } from 'moment';
import { getDisabledTime } from 'tools/date';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';

export const TripToolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    React.useEffect(() => {
        dispatch(fetchAllZones());
    }, [dispatch]);
    const { allZonesById } = useSelector((state: RootState) => state.zone);
    const {
        vehicleTypes,
        statuses,
        startTime,
        endTime,
        startZone,
        endZone,
        maxDurationOverdue,
    } = useSelector(selectFilters);

    const vehicleTypeOptions = useMemo(() => {
        return [
            {
                label: '-',
                value: '',
            },
            {
                label: t('tripList.bike'),
                value: VehicleTypes.BIKE,
            },
            {
                label: t('tripList.vae'),
                value: VehicleTypes.VAE,
            },
            {
                label: t('tripList.scooter'),
                value: VehicleTypes.SCOOTER,
            },
        ];
    }, [t]);

    const tripStatusOptions = useMemo(() => {
        return [
            {
                label: t('tripList.statuses.started'),
                value: TripStatuses.STARTED,
            },
            {
                label: t('tripList.statuses.paused'),
                value: TripStatuses.PAUSED,
            },
            {
                label: t('tripList.statuses.finished'),
                value: TripStatuses.FINISHED,
            },
        ];
    }, [t]);

    const isEndTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                min: moment(startTime).startOf('day'),
                max: moment().endOf('day'),
            }),
        [startTime],
    );

    const isStartTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                max: moment().endOf('day'),
            }),
        [],
    );

    const zoneOptions = useMemo(() => {
        return [
            {
                label: '-',
                value: '',
            },
            ...Object.entries(allZonesById).map(([key, value]) => ({
                label: value.name,
                value: key,
            })),
        ];
    }, [allZonesById]);

    const handleChangeTime = React.useCallback(
        (e: Moment | string, filterType: FilterTypes) => {
            const filterValue =
                filterType === FilterTypes.StartTime
                    ? moment(e).startOf('day').toISOString()
                    : moment(e).endOf('day').toISOString();
            return dispatch(
                setFilter({
                    filterType: filterType,
                    value: filterValue,
                }),
            );
        },
        [dispatch],
    );
    const handleToggleFilter = () => {
        dispatch(setMaxDurationFilter());
    };
    const handleChangeFilter = (
        e: React.ChangeEvent<HTMLInputElement>,
        filterType: FilterTypes,
    ) => {
        dispatch(
            setFilter({
                filterType,
                value: e.target.value,
            }),
        );
    };

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        if (vehicleId) {
            dispatch(
                setFilter({
                    filterType: FilterTypes.VehicleIds,
                    value: vehicleId,
                }),
            );
        } else {
            dispatch(fetchTrips());
        }
    }, [dispatch, vehicleId]);

    return (
        <FormGrid columnAmount={8} rowHeight="20px" padding="10px 0">
            {!vehicleId && (
                <SelectComponent
                    label={t('tripList.vehicleType')}
                    onChange={e =>
                        handleChangeFilter(e, FilterTypes.VehicleTypes)
                    }
                    value={vehicleTypes || ''}
                    options={vehicleTypeOptions}
                />
            )}

            <SelectComponent
                multiple
                label={t('tripList.status')}
                onChange={e => handleChangeFilter(e, FilterTypes.Statuses)}
                value={statuses || []}
                options={tripStatusOptions}
            />
            <DateTimeInput
                id="startDate"
                value={startTime || ''}
                timeFormat={false}
                onChange={e => handleChangeTime(e, FilterTypes.StartTime)}
                label={t('tripList.startDate')}
                getDisabledTime={isStartTimeDisabled}
            />
            <DateTimeInput
                id="endDate"
                value={endTime || ''}
                timeFormat={false}
                onChange={e => handleChangeTime(e, FilterTypes.EndTime)}
                label={t('tripList.endDate')}
                getDisabledTime={isEndTimeDisabled}
            />
            <SelectComponent
                label={t('tripList.startZone')}
                onChange={e => handleChangeFilter(e, FilterTypes.StartZone)}
                value={startZone || ''}
                options={zoneOptions}
            />
            <SelectComponent
                label={t('tripList.endZone')}
                onChange={e => handleChangeFilter(e, FilterTypes.EndZone)}
                value={endZone || ''}
                options={zoneOptions}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!maxDurationOverdue}
                        onChange={handleToggleFilter}
                    />
                }
                label={
                    <Typography variant="body1">
                        {t('tripList.maxDurationOverdue')}
                    </Typography>
                }
            />
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};
