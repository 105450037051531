import { TFunction } from 'i18next';
import { FetchedLog, Sorting } from 'ducks/logs/types';
import { setSort } from 'ducks/logs';
import { Dispatch } from 'react';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('logs.id'),
            field: 'id',
            onClick: () =>
                dispatch(
                    setSort({
                        value: Sorting.Id,
                    }),
                ),
        },
        {
            title: t('logs.created'),
            field: 'created',
            onClick: () =>
                dispatch(
                    setSort({
                        value: Sorting.Created,
                    }),
                ),
            render: (rowProps: FetchedLog) =>
                new Date(rowProps.created).toLocaleString() || '-',
        },
        {
            title: t('logs.entity'),
            field: 'entity',
            sorting: false,
            render: (rowProps: FetchedLog) =>
                t(`logs.entityTypes.${rowProps.entityType}`),
        },
        {
            title: t('logs.eventType'),
            field: 'eventType',
            sorting: false,
            render: (rowProps: FetchedLog) =>
                t(`logs.eventTypes.${rowProps.eventType}`),
        },
        {
            title: t('logs.boUpdater'),
            field: 'boUpdater',
            sorting: false,
        },
        {
            title: t('logs.modifiedId'),
            field: 'modifiedId',
            sorting: false,
        },
    ];
};
