import React, { useCallback } from 'react';
import { ButtonComponent } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import { selectAccessToPages } from '../../../ducks/auth';
import { Permissions } from '../../../ducks/groups/types';

export const SearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const redirectToGroupCreation = useCallback(() => {
        dispatch(push(routes.NEW_GROUP));
    }, [dispatch]);
    const accessToPages = useSelector(selectAccessToPages);

    const allowedToCreate = accessToPages[Permissions.GROUP_CREATE];
    return allowedToCreate ? (
        <Box display="flex" justifyContent="flex-end">
            <ButtonComponent
                text={t('add')}
                startIcon={<AddIcon />}
                handleClick={redirectToGroupCreation}
            />
        </Box>
    ) : (
        <></>
    );
};
