import { RootState } from '../root';
import { createSelector } from '@reduxjs/toolkit';

export const selectGroup = (state: RootState) => state.group;

export const selectGroupError = (state: RootState) => state.group.error;

export const selectIsLoading = (state: RootState) => state.group.loading;

export const selectGroupIds = (state: RootState) => state.group.groupIds;

export const selectGroupsById = (state: RootState) => state.group.groupsById;

export const selectAllGroupIds = (state: RootState) => state.group.allGroupIds;

export const selectAllGroupsById = (state: RootState) =>
    state.group.allGroupsById;

export const selectPagination = (state: RootState) => state.group.pagination;

export const selectSort = (state: RootState) => state.group.sort;

export const selectGroupsTableData = createSelector(
    selectGroupIds,
    selectGroupsById,
    (groupIds, groupsById) =>
        groupIds.map(id => ({
            ...groupsById[id],
        })),
);

export const selectPermissions = (state: RootState) => state.group.permissions;

export const selectCurrentGroup = (state: RootState) =>
    state.group.currentGroup;

export const selectGroupHasUnsavedChanges = (state: RootState) =>
    state.group.groupHasUnsavedChanges;
