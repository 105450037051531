export enum SortDirection {
    Asc = 'asc',
    Desc = 'desc',
}
export type Sort = {
    value: string;
    direction: SortDirection;
};

export type Pagination = {
    totalPages: number;
    totalElements: number;
    size: number;
    number: number;
};

export type SortStore = {
    sort: Sort;
};

interface ById<T> {
    [id: number]: T;
}

export interface Accumulator<T> {
    byId: ById<T>;
    allIds: number[];
}

export const DateTimeFormatter = new Intl.DateTimeFormat('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 2,
});
