import i18n from 'i18next';
import translationEN from './resources/locales/en/en.json';
import translationFR from './resources/locales/fr/fr.json';
import translationNL from './resources/locales/nl/nl.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: translationEN,
    },
    fr: {
        translation: translationFR,
    },
    nl: {
        translation: translationNL,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr',
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
