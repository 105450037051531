import { News, Content, FetchedNews } from './types';
import { Sort, Accumulator } from 'ducks/common/types';
import { generateUniqueFileName } from '../../tools/file';
export const mapNews = ({ company, news }: Content): FetchedNews => ({
    ...news,
    companyName: company?.name,
    companyId: company?.id,
    isEnabled: company?.isEnabled,
    file: { url: String(news.imageUrl) },
});

export const mapFetchedNews = (
    content: Content[],
): Accumulator<FetchedNews> => {
    return content.reduce<Accumulator<FetchedNews>>(
        (acc, { company, news }) => {
            return {
                byId: {
                    ...acc.byId,
                    [news.id]: mapNews({ company, news }),
                },
                allIds: [...acc.allIds, news.id],
            };
        },
        { allIds: [], byId: {} },
    );
};

export const mapSorting = (sort: Sort) => {
    return { sort: sort.value + ',' + sort.direction };
};

export const prepareFormData = (params: News) => {
    const formData = new FormData();
    const { file, ...news } = params;
    file &&
        file.file &&
        formData.append('file', file.file, generateUniqueFileName());

    formData.append(
        'request',
        new Blob([JSON.stringify(news)], {
            type: 'application/json',
        }),
    );
    return formData;
};
