import request from 'helpers/request';
import { NewUser } from './types';
import { BO_USERS_EXPORT_URL, BO_USERS_URL } from 'constants/api';
import { getFileFromResponse } from 'tools/file';

export const postUser = (params: NewUser): Promise<string> =>
    request.post(BO_USERS_URL, { ...params });

export const getUsers = ({ sort, ...params }: any): Promise<string> => {
    const [sortField] = sort.split(',');

    if (!sortField || sortField === '') {
        return request.get(BO_USERS_URL, { params });
    }

    return request.get(BO_USERS_URL, { params: { sort, ...params } });
};

export const exportBoUsers = (params: any): Promise<any> =>
    request
        .get(BO_USERS_EXPORT_URL, { params })
        .then(response => getFileFromResponse(response));

export const getBoUser = (id: number): Promise<string> =>
    request.get(`${BO_USERS_URL}/${id}`);

export const updateBoUserStatus = ({
    id,
    isEnabled,
}: {
    id: number;
    isEnabled: boolean;
}): Promise<string> =>
    request.patch(`${BO_USERS_URL}/${id}/updateStatus?isEnabled=${isEnabled}`);

export const updateBoUserService = (id: number, params: any): Promise<string> =>
    request.put(`${BO_USERS_URL}/${id}`, { ...params });

export const updateBoUserLeaveDate = ({ id, date }: any): Promise<string> =>
    request.patch(`${BO_USERS_URL}/${id}/updateLeaveDate?date=${date}`);
