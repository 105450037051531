import { Pagination, Sort } from '../common/types';
import { BoUser } from '../boUsers/types';
import { Profile } from '../users/types';

export enum FilterTypes {
    Type = 'type',
    VehicleIds = 'vehicleIds',
    Search = 'search',
}

export enum MaintenanceTypes {
    UNAVAILABLE = 'UNAVAILABLE',
    SANITY_CHECK = 'SANITY_CHECK',
    DEPLOY = 'DEPLOY',
    PARKING_DEPLOYMENT = 'PARKING_DEPLOYMENT',
    SWAPPING = 'SWAPPING',
    BIND = 'BIND',
    REPAIR = 'REPAIR',
    DESTRUCTION = 'DESTRUCTION',
    DEACTIVATE = 'DEACTIVATE',
    WORKSHOP = 'WORKSHOP',
    LOAD = 'LOAD',
    CLEANING = 'CLEANING',
}

export type Filters = {
    [FilterTypes.Type]: MaintenanceTypes;
    [FilterTypes.VehicleIds]: string[];
    [FilterTypes.Search]: string;
};

export type ScooterMaintenance = {
    id: number;
    type: string;
    serial: string;
    plate: string;
    duration: string;
    boUser: number;
    boUserEmail: string;
    updated: string;
    created: string;
    description?: string;
};

type Maintenance = {
    id: number;
    type: string;
    serial: string;
    plate: string;
    duration: string;
    created: string;
    description?: string;
};

type UpdateStamp = {
    updated: string;
    boUpdater: BoUser;
    endUpdater: Profile;
};

export type FetchedMaintenance = {
    maintenance: Maintenance;
    boUser: number;
    boUserEmail: string;
    updateStamp: UpdateStamp;
};

export type MaintenanceById = {
    [code: number]: ScooterMaintenance;
};

export type ScooterMaintenanceState = {
    loading: boolean;
    error: string;
    maintenanceIds: number[];
    maintenanceById: MaintenanceById;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
};

export enum ScooterMaintenanceType {
    sanityCheck = 'SANITY_CHECK',
    deploy = 'DEPLOY',
    parkingDeployment = 'PARKING_DEPLOYMENT',
    swapping = 'SWAPPING',
    bind = 'BIND',
    repair = 'REPAIR',
    destruction = 'DESTRUCTION',
    deactivate = 'DEACTIVATE',
    workshop = 'WORKSHOP',
    load = 'LOAD',
    cleaning = 'CLEANING',
    unavailable = 'UNAVAILABLE',
}

export type ScooterMaintenanceResponse = {
    content: FetchedMaintenance[];
} & Pagination;

export type Accumulator = {
    maintenanceIds: number[];
    maintenanceById: MaintenanceById;
};
