import React, { Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ButtonComponent } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/common/ModalContainer/styles';
import { useDispatch, useSelector } from 'react-redux';
import { unbindLock, resetError } from 'ducks/locks';
import { SelectComponent } from '../../common';
import { LockStatus } from 'ducks/locks/types';
import { selectLockError } from 'ducks/locks/selectors';
import { ErrorComponent } from '../../common/Error';

interface ModalProps {
    isOpen: boolean;
    lockId: number;
    handleClose: () => void;
}

// eslint-disable-next-line react/display-name
export const UnbindBikeModal = React.forwardRef(
    ({ handleClose, lockId }: ModalProps, ref: Ref<HTMLDivElement>) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const error = useSelector(selectLockError);
        const [lockStatus, setLockStatus] = useState('');

        useEffect(() => {
            return () => {
                dispatch(resetError());
            };
        }, [dispatch]);

        const statusOptions = useMemo(() => {
            return Object.values(LockStatus).map(status => ({
                label: t(`locks.lockStatus.${status}`),
                value: status,
            }));
        }, [t]);

        const handleUnbindVehicle = useCallback(() => {
            dispatch(unbindLock({ lockId, status: lockStatus }));
        }, [dispatch, lockId, lockStatus]);

        const handleChangeStatus = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setLockStatus(e.target.value);
            },
            [setLockStatus],
        );

        return (
            <div ref={ref} className={classes.modal}>
                <Box textAlign="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box marginBottom="20px">
                    <Typography variant="h2">
                        {t('bikes.unbindVehicle')}
                    </Typography>
                </Box>
                <Box width="300px" margin="0 auto">
                    <SelectComponent
                        value={lockStatus}
                        onChange={e => handleChangeStatus(e)}
                        options={statusOptions}
                    />
                </Box>
                <Box textAlign="center" marginTop="30px">
                    <ButtonComponent
                        handleClick={handleClose}
                        text={t('cancel')}
                    />
                    &emsp;
                    <ButtonComponent
                        handleClick={handleUnbindVehicle}
                        text={t('bikes.unbind')}
                        isDisabled={!lockStatus}
                    />
                </Box>
                <ErrorComponent error={error} />
            </div>
        );
    },
);
