import React, {
    useEffect,
    useMemo,
    Fragment,
    useCallback,
    useState,
} from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrip, stopTrip } from 'ducks/trips';
import { Box, IconButton, Typography } from '@material-ui/core';
import {
    selectCurrentTrip,
    selectCurrentTripService,
    selectIsLoading,
} from 'ducks/trips/selectors';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { routes } from 'constants/common';
import { useStyles } from './styles';
import { ButtonComponent, FormGrid, LinkComponent } from 'components/common';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { calculateDuration } from 'tools/calculateDuration';
import { Modals, openModal } from 'ducks/ui';
import { DateTimeInput } from 'components/common/DateTimeInput';
import { getDisabledTime } from 'tools/date';
import { TripStatuses } from 'ducks/trips/types';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { LoaderComponent } from '../../common/Loader';
import { ErrorComponent } from '../../common/Error';
import { ZoneIdsWithLinks } from './ZoneWithLinks';

const MainInformation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [newDate, setNewDate] = useState<Moment | string>(moment(new Date()));
    const [dateError, setDateError] = useState('');
    const trip = useSelector(selectCurrentTrip);
    const vehicleRout = get(routes, trip?.vehicle?.type || '', routes.TRIPS);
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToStopTrip =
        accessToPages[Permissions.TRIP_UPDATE_STATUS] &&
        (trip?.trip?.status === TripStatuses.STARTED ||
            trip?.trip?.status === TripStatuses.PAUSED);

    useEffect(() => {
        return () => {
            setDateError('');
        };
    }, [setDateError]);

    const isTimeDisabled = useCallback(
        (current: Moment) =>
            getDisabledTime({
                current,
                min: moment(trip?.trip?.startTime).startOf('day'),
                max: moment().startOf('day'),
            }),
        [trip],
    );

    const handleChangeEndDate = useCallback(
        (date: string | Moment) => {
            setNewDate(date);
        },
        [setNewDate],
    );

    const handleStopTrip = useCallback(() => {
        if (moment(newDate).isBefore(moment(trip?.trip?.startTime))) {
            setDateError(t('tripList.endDateBeforeStartError'));
            return;
        }

        if (moment(newDate).isAfter(moment())) {
            setDateError(t('tripList.endDateAfterCurrentError'));
            return;
        }

        const endDate =
            newDate === '' ? moment().format() : moment(newDate).format();
        dispatch(stopTrip({ tripId: trip?.trip?.id, endDate }));
    }, [newDate, dispatch, trip, setDateError, t]);

    return (
        <div className={classes.tripBox}>
            <Box
                display="grid"
                gridGap="10px 20px"
                alignItems="center"
                gridAutoRows="minmax(20px, auto)"
                gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                padding="10px 20px">
                <Typography>
                    {`${t('tripList.id')}: ${trip?.trip?.id || '-'}`}
                </Typography>
                <Box display="flex">
                    <Typography>{`${t('tripList.userId')}:`}&ensp;</Typography>
                    <LinkComponent
                        href={generatePath(routes.USER, {
                            userId: trip?.endUser?.id || '-',
                        })}
                        target="_blank"
                        text={`${trip?.endUser?.id} - ${trip?.profile?.email}`}
                    />
                </Box>
                <Typography>
                    {`${t('tripList.status')}: ${
                        t(
                            `tripList.statuses.${trip?.trip?.status.toLowerCase()}`,
                        ) || '-'
                    }`}
                </Typography>
                <Box display="flex">
                    <Typography>
                        {`${t('tripList.vehicleSn')}:`}&ensp;
                    </Typography>
                    <LinkComponent
                        href={generatePath(vehicleRout, {
                            vehicleId: trip?.vehicle?.id || '-',
                        })}
                        target="_blank"
                        text={trip?.vehicle?.serial || ''}
                    />
                </Box>
                <Box display="flex">
                    <Typography>
                        {`${t('tripList.vehiclePlate')}:`}&ensp;
                    </Typography>
                    <LinkComponent
                        href={generatePath(vehicleRout, {
                            vehicleId: trip?.vehicle?.id || '-',
                        })}
                        target="_blank"
                        text={trip?.vehicle?.plate || ''}
                    />
                </Box>
                {allowedToStopTrip && (
                    <Box>
                        <Box display="flex" alignItems="flex-end">
                            <Box width="150px" marginRight="20px">
                                <DateTimeInput
                                    id="change-date-input"
                                    value={moment(newDate).toDate()}
                                    onChange={handleChangeEndDate}
                                    getDisabledTime={isTimeDisabled}
                                    label={t('tripList.endDate')}
                                />
                            </Box>
                            <Box height="36px" marginBottom="7px">
                                <ButtonComponent
                                    text={t('tripList.stopTrip')}
                                    handleClick={handleStopTrip}
                                />
                            </Box>
                        </Box>
                        <Box marginTop="5px">
                            <ErrorComponent error={dateError} />
                        </Box>
                    </Box>
                )}
            </Box>
        </div>
    );
};

const PausesList = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const trip = useSelector(selectCurrentTrip);

    return (
        <div className={classes.tripBox}>
            <Typography className={classes.pausesTitle}>
                {t('tripList.pauseList')}
            </Typography>
            {trip?.trip?.pauses.length > 0 ? (
                <FormGrid columnAmount={5} rowHeight="20px">
                    <Typography align="center">
                        {t('tripList.startDate')}
                    </Typography>
                    <Typography align="center">
                        {t('tripList.endDate')}
                    </Typography>
                    <Typography align="center">
                        {t('tripList.duration')}
                    </Typography>
                    <Typography align="center">{t('tripList.lat')}</Typography>
                    <Typography align="center">{t('tripList.lng')}</Typography>
                    {trip?.trip?.pauses.map((item: any) => {
                        return (
                            <Fragment key={item.start}>
                                <Typography align="center">
                                    {item.start
                                        ? new Date(item.start).toLocaleString()
                                        : '-'}
                                </Typography>
                                <Typography align="center">
                                    {item.end
                                        ? new Date(item.end).toLocaleString()
                                        : '-'}
                                </Typography>
                                <Typography align="center">
                                    {item.duration || '-'}
                                </Typography>
                                <Typography align="center">
                                    {item.lat || '-'}
                                </Typography>
                                <Typography align="center">
                                    {item.lng || '-'}
                                </Typography>
                            </Fragment>
                        );
                    })}
                </FormGrid>
            ) : (
                <Typography align="center">{t('tripList.noData')}</Typography>
            )}
        </div>
    );
};

const TripInformation = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const trip = useSelector(selectCurrentTrip);
    const loading = useSelector(selectIsLoading);
    const currentTripService = useSelector(selectCurrentTripService);

    const tripDuration = useMemo(() => {
        if (trip?.trip?.startTime && trip?.trip?.endTime) {
            return calculateDuration(trip?.trip?.startTime, trip?.trip?.endTime)
                .durationStringWithUnits;
        }
        return '-';
    }, [trip]);
    const dispatch = useDispatch();
    const serviceOptions = useMemo(() => {
        const options = [];
        currentTripService?.isGeofenceEnabled &&
            options.push(t('serviceList.geofence'));
        currentTripService?.isStationConnected &&
            options.push(t('serviceList.station'));
        currentTripService?.isLoopEnabled &&
            options.push(t('serviceList.loop'));
        currentTripService?.isBookingAllowed &&
            options.push(t('serviceList.booking'));
        currentTripService?.isMultirentAllowed &&
            options.push(t('serviceList.multirent'));
        currentTripService?.isPauseAllowed &&
            options.push(t('serviceList.pause'));
        return options;
    }, [currentTripService, t]);

    return (
        <div className={classes.tripRow}>
            <div className={`${classes.tripBox} ${classes.companyColumn}`}>
                {loading && <LoaderComponent />}
                <FormGrid columnAmount={1} rowHeight="20px">
                    <Box display="flex">
                        <Typography>
                            {`${t('tripList.companyName')}:`}&ensp;
                        </Typography>
                        <LinkComponent
                            href={generatePath(routes.COMPANY, {
                                companyId: trip?.company?.id || '-',
                            })}
                            target="_blank"
                            text={trip?.company?.name || ''}
                        />
                    </Box>
                    <Typography>
                        {`${t('tripList.service')}: ${
                            currentTripService?.id || ''
                        } - ${currentTripService?.name || ''}`}
                    </Typography>
                    <Typography>
                        {`${t('tripList.serviceType')}: ${
                            t(`serviceList.${currentTripService?.type}`) || '-'
                        }`}
                    </Typography>
                    <Typography>
                        {`${t('tripList.serviceOption')}: ${
                            serviceOptions.join(', ') || '-'
                        }`}
                    </Typography>
                    <Typography>
                        {`${t('tripList.vehicleType')}: ${
                            t(
                                `tripList.${trip?.vehicle?.type.toLowerCase()}`,
                            ) || '-'
                        }`}
                    </Typography>
                    <Typography>
                        {`${t('tripList.bookingId')}: ${
                            trip?.booking?.id || '-'
                        }`}
                    </Typography>
                </FormGrid>
            </div>
            <div className={classes.tripColumn}>
                <div className={classes.tripBox}>
                    <FormGrid columnAmount={3} rowHeight="20px">
                        <Typography>
                            {`${t('tripList.startDate')}: ${
                                trip?.trip?.startTime
                                    ? moment(trip?.trip?.startTime).format(
                                          'DD/MM/YYYY HH:mm',
                                      )
                                    : '-'
                            }`}
                        </Typography>
                        <Typography>
                            {`${t('tripList.endDate')}: ${
                                trip?.trip?.endTime
                                    ? moment(trip?.trip?.endTime).format(
                                          'DD/MM/YYYY HH:mm',
                                      )
                                    : '-'
                            }`}
                        </Typography>
                        <Typography>
                            {`${t('tripList.duration')}: ${
                                tripDuration || '-'
                            }`}
                        </Typography>
                        <Typography>
                            {`${t('tripList.mileageStart')}: `}
                            {trip?.trip?.startMileage
                                ? `${trip?.trip?.startMileage} km`
                                : '-'}
                        </Typography>
                        <Typography>
                            {`${t('tripList.mileageEnd')}: `}
                            {trip?.trip?.endMileage
                                ? `${trip?.trip?.endMileage} km`
                                : '-'}
                        </Typography>
                        <Typography>
                            {`${t('tripList.distance')}: `}
                            {trip?.trip?.kilometers
                                ? `${trip?.trip?.kilometers} km`
                                : '-'}
                        </Typography>
                    </FormGrid>
                </div>
                <div className={classes.tripRow}>
                    <div className={`${classes.tripBox} ${classes.fullWidth}`}>
                        <FormGrid columnAmount={1} rowHeight="20px">
                            <ZoneIdsWithLinks
                                title={t('tripList.zoneStart')}
                                zones={trip?.trip?.startZones || []}
                            />
                            <ZoneIdsWithLinks
                                title={t('tripList.zoneEnd')}
                                zones={trip?.trip?.endZones || []}
                            />
                            &nbsp;
                            <Typography>
                                {`${t('tripList.startStation')}: ${
                                    trip?.trip?.startStation?.id || ''
                                } - ${trip?.trip?.startStation?.name || ''}`}
                            </Typography>
                            <Typography>
                                {`${t('tripList.endStation')}: ${
                                    trip?.trip?.endStation?.id || ''
                                } - ${trip?.trip?.endStation?.name || ''}`}
                            </Typography>
                            &nbsp;
                            <LinkComponent
                                text={`${t('tripList.gpsAtStartPoint')}: ${
                                    trip?.trip?.startLat || '-'
                                }, ${trip?.trip?.startLng || '-'}`}
                                handleClick={event => {
                                    event.stopPropagation();
                                    dispatch(
                                        openModal({
                                            modalType: Modals.MAP_WITH_POINT,
                                            modalProps: {
                                                position: {
                                                    lat: trip?.trip?.startLat,
                                                    lng: trip?.trip?.startLng,
                                                },
                                                title: t(
                                                    'tripList.gpsAtStartPoint',
                                                ),
                                            },
                                        }),
                                    );
                                }}
                            />
                            <LinkComponent
                                text={`${t('tripList.gpsAtEndPoint')}: ${
                                    trip?.trip?.endLat || '-'
                                }, ${trip?.trip?.endLng || '-'}`}
                                handleClick={event => {
                                    event.stopPropagation();
                                    dispatch(
                                        openModal({
                                            modalType: Modals.MAP_WITH_POINT,
                                            modalProps: {
                                                position: {
                                                    lat: trip?.trip?.endLat,
                                                    lng: trip?.trip?.endLng,
                                                },
                                                title: t(
                                                    'tripList.gpsAtEndPoint',
                                                ),
                                            },
                                        }),
                                    );
                                }}
                            />
                        </FormGrid>
                    </div>
                    <div className={`${classes.tripBox} ${classes.fullWidth}`}>
                        <FormGrid columnAmount={1} rowHeight="20px">
                            <Typography>
                                {`${t('tripList.calories')}: ${
                                    trip?.trip?.calories || '-'
                                }`}
                            </Typography>
                            <Typography>
                                {`${t('tripList.co2')}: ${
                                    trip?.trip?.co2 || '-'
                                }`}
                            </Typography>
                            <Typography>{`${t('tripList.startBatteryLevel')}: ${
                                trip?.trip?.startBatteryLevel || '-'
                            }`}</Typography>
                            <Typography>{`${t('tripList.endBatteryLevel')}: ${
                                trip?.trip?.endBatteryLevel || '-'
                            }`}</Typography>
                        </FormGrid>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TripDetails = () => {
    const classes = useStyles();
    const { tripId } = useParams<{ tripId?: string }>();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(fetchTrip(tripId));
    }, [dispatch, tripId]);

    const redirectToList = () => {
        dispatch(push(routes.TRIPS));
    };

    return (
        <>
            <Box display="flex" alignItems="center">
                <IconButton
                    onClick={redirectToList}
                    className={classes.backButton}>
                    <BackIcon />
                </IconButton>
                {t('tripList.backToList')}
            </Box>
            <MainInformation />
            <TripInformation />
            <PausesList />
        </>
    );
};
