import { Languages } from '../boUsers/types';
import { RecipientTypes, UsageTypes } from '../services/types';
import {
    VehiclesById,
    VehicleStatus,
    VehicleTypes,
} from '../vehicles/common/types';
import { Pagination, Sort } from '../common/types';

export type CompaniesById = {
    [code: number]: Company;
};
export type InvitationsById = {
    [id: number]: InvitationBody;
};

export type Template = {
    id: number;
    language: Languages;
};

export type CompanyState = {
    loading: boolean;
    error: string;
    companyIds: number[];
    invitationIds: number[];
    companiesById: CompaniesById;
    allCompanyIds: number[];
    allCompaniesById: CompaniesById;
    invitationsById: InvitationsById;
    currentCompany: Company;
    companyServices: Service[];
    companyManagers: Manager[];
    invitationPagination: Pagination;
    freeVehicles: VehiclesById;
    currentCompanyId: number;
    invitationHasUnsavedChanges: boolean;
    companyHasUnsavedChanges: boolean;
    activeCompanyTab: number;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
    emailRecipients: any;
};
export type Manager = {
    created: string;
    email: string;
    id: number;
};

export type Service = {
    id: number;
    name: string;
    type: UsageTypes;
    recipientType: RecipientTypes;
    created: string;
    timezone: string;
};
export type Domains = {
    acceptedDomains: string[];
    rejectedDomains: string[];
};
export type Company = {
    domainLists: Domains;
    address: string;
    appColor: string;
    appLogo: FileProperties;
    assistancePhone: string;
    boColor: string;
    boLogo: FileProperties;
    city: string;
    created: string;
    id: number;
    insuranceKeyField: string;
    insuranceLink: string;
    isEnabled: boolean;
    name: string;
    siret: string;
    zipCode: string;
    invitationTemplates: Template[];
    welcomeTemplates: Template[];
    rejectedDomains?: string[];
    acceptedDomains?: string[];
    refOrderSupplier: string;
    zendeskTickets?: boolean;
    reportingDashboard: string | null;
};

export type Content = {
    company: Company;
    services?: Service[];
    managers: Manager[];
};

export type ServiceListContent = {
    id: number;
    managers: Manager[];
    name: string;
    servicesCount: number;
    usersCount: number;
    domainLists: Domains;
};

export type CompaniesPagination = {
    totalPages: number;
    totalElements: number;
    size: number;
    number: number;
};

export type ServiceResponse = {
    content: ServiceListContent[];
} & CompaniesPagination;

export type ServiceInvitationsResponse = {
    content: InvitationBody[];
} & Pagination;

export type FreeVehicle = {
    id: number;
    serial: string;
    plate: string;
    type: VehicleTypes;
    status: VehicleStatus;
    created: string;
};

export type Accumulator = {
    companiesIds: number[];
    companiesById: CompaniesById;
};

export type InvitationAccumulator = {
    invitationIds: number[];
    invitationsById: InvitationsById;
};
export type InvitationBody = {
    id: number;
    email: string;
    endTime: string;
    firstName: string;
    language: Languages;
    lastName: string;
    numberOfVehiclesAvailableAtTheSameTime: number;
    startTime: string;
    lldVehicleId: number;
    expirationTime: string;
    serviceName: string;
    confirmationCode: string;
    files: FileProperties[];
    serviceId: number;
    rfidPassNumber?: string;
};

export type Invitation = {
    serviceId: number;
    invitations: InvitationBody[];
};

export type Assignment = {
    serviceId: number;
    domainRecognition: boolean;
    domainSubscriptionDuration: number;
};

export enum StringFilterTypes {
    Name = 'name',
}

export type Filters = {
    [StringFilterTypes.Name]: string;
};

export interface FileProperties {
    file: File;
    url: string;
    type: string;
}

export type EmailsAccumulator = {
    fallonState: string[];
    lowBattery: string[];
    shockAlarm: string[];
    speedTooHigh: string[];
};

export type FetchedRecipient = {
    email: string;
    fallonState: boolean;
    lowBattery: boolean;
    shockAlarm: boolean;
    speedTooHigh: boolean;
};

export type AlarmReasons = {
    fallonState: boolean;
    lowBattery: boolean;
    shockAlarm: boolean;
    speedTooHigh: boolean;
};
