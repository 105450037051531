import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { FetchedUser } from 'ducks/users/types';
import { Typography } from '@material-ui/core';
import { setSort } from 'ducks/users';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('usersList.userId'),
            field: 'id',
            sorting: false,
        },
        {
            title: t('usersList.name'),
            field: 'firstName',
            render: (rowProps: FetchedUser) =>
                `${rowProps.firstName} ${rowProps.lastName}`,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'firstName',
                    }),
                ),
        },
        {
            title: t('usersList.phoneNumber'),
            field: 'phone',
            sorting: false,
        },
        {
            title: t('usersList.proEmail'),
            field: 'proEmail',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedUser) => {
                const proEmailArray =
                    rowProps.proEmail && rowProps.proEmail.split('@');
                return proEmailArray ? (
                    <>
                        <Typography>{proEmailArray[0]}</Typography>
                        <Typography>{`@${proEmailArray[1]}`}</Typography>
                    </>
                ) : (
                    <></>
                );
            },
        },
        {
            title: t('usersList.persEmail'),
            field: 'persEmail',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedUser) => {
                const persEmailArray =
                    rowProps.persEmail && rowProps.persEmail.split('@');
                return persEmailArray ? (
                    <>
                        <Typography>{persEmailArray[0]}</Typography>
                        <Typography>{`@${persEmailArray[1]}`}</Typography>
                    </>
                ) : (
                    ''
                );
            },
        },
        {
            title: t('usersList.language'),
            field: 'language',
            sorting: false,
        },
        {
            title: t('usersList.status'),
            field: 'status',
            render: (rowProps: FetchedUser) =>
                rowProps.status ? 'Enabled' : 'Disabled',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'status',
                    }),
                ),
        },
    ];
};
