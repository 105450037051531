import request from 'helpers/request';
import { LOCKS_URL, LOCKS_EXPORT_URL, LOCKS_IMPORT_URL } from 'constants/api';
import { getFileFromResponse } from 'tools/file';
import { LockStatus, NewLock, LockCommands } from './types';

export const unbindVehicleService = ({
    lockId,
    status,
}: {
    lockId: number;
    status: string;
}): Promise<string> =>
    request.patch(`${LOCKS_URL}/${lockId}/unbind?status=${status}`);

export const getLocks = (params: any): Promise<string> =>
    request.get(LOCKS_URL, { params });

export const exportLocksService = (params: any): Promise<any> =>
    request
        .get(LOCKS_EXPORT_URL, { params })
        .then(response => getFileFromResponse(response));

export const postLock = (params: NewLock): Promise<string> =>
    request.post(`${LOCKS_URL}`, { ...params });

export const postImportLocks = ({
    file,
}: {
    file: FormData;
}): Promise<string> =>
    request.post(LOCKS_IMPORT_URL, file, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });

export const putLock = (params: NewLock, id: number): Promise<string> =>
    request.put(`${LOCKS_URL}/${id}`, { ...params });

export const updateLockStatus = (
    status: LockStatus,
    locks: number[],
): Promise<string> =>
    request.patch(`${LOCKS_URL}/updateStatus`, { locks, status });

export const getLock = (id: number): Promise<string> =>
    request.get(`${LOCKS_URL}/${id}`);

export const getConfig = (id: number): Promise<string> =>
    request.get(`${LOCKS_URL}/${id}/config`);

export const sendLockCommand = (
    id: number,
    command: LockCommands,
): Promise<string> =>
    request.post(`${LOCKS_URL}/${id}/sendCommand?`, null, {
        params: { command },
    });
