import { Accumulator, Content, Zone } from './types';

export const mapFetchedZones = (content: Content[]): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, item) => {
            return {
                zonesById: {
                    ...acc.zonesById,
                    [item.zone.id]: mapZone(item),
                },
                zoneIds: [...acc.zoneIds, item.zone.id],
            };
        },
        { zoneIds: [], zonesById: {} },
    );
};

export const mapZone = ({ zone, services }: Content): Zone => ({
    ...zone,
    services: services.map(({ id }) => id),
});
