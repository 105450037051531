import { TableComponent } from '../../common';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { fetchLocks } from 'ducks/locks';
import { LockFromList } from 'ducks/locks/types';
import { fetchAllCompanies } from 'ducks/companies';
import {
    selectLocksTableData,
    selectSort,
    selectIsLoading,
} from 'ducks/locks/selectors';
import { Pagination } from './Pagination';
import { SearchToolbar } from './SearchToolbar';
import { Toolbar } from './Toolbar';
import { Action } from '../../common/Table';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const LocksList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const tableData = useSelector(selectLocksTableData);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);
    const columns = getColumns(t, dispatch);
    const redirectToLock = useCallback(
        (id: number) => {
            dispatch(push(routes.LOCK.replace(':lockId', `${id}`)));
        },
        [dispatch],
    );

    const actions = useMemo((): Action<LockFromList>[] => {
        return [
            {
                icon: 'edit',
                tooltip: t('serviceList.edit'),
                onClick: (event, rowData: LockFromList | undefined) =>
                    rowData && redirectToLock(rowData.lock.id),
            },
        ];
    }, [redirectToLock, t]);

    React.useEffect(() => {
        dispatch(fetchLocks());
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={actions}
                onRowClick={(event, rowData: LockFromList | undefined) => {
                    return rowData && redirectToLock(rowData.lock.id);
                }}
                toolbar={Toolbar}
                searchComponent={SearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={tableData}
                title={t('locks.title')}
                sort={sort}
            />
        </>
    );
};
