import { createStyles, FormControlLabel, withStyles } from '@material-ui/core';

export const StyledFormControlLabel = withStyles(() =>
    createStyles({
        root: {
            width: '100%',
            textAlign: 'left',
            margin: 0,
            marginBottom: '5px',
            '& p': {
                fontSize: '10px',
                lineHeight: 1.2,
            },
        },
        label: {
            marginRight: 'auto',
            fontSize: '10px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '100%',
        },
    }),
)(FormControlLabel);
