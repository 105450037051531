import { Pagination, Sort } from '../common/types';

export type FetchedTelemetryEvent = {
    msgCode: ScooterTelemetryEventsType | BikeAndVaeTelemetryEventsType;
    type: string;
    timestamp: string;
    fields: Record<string, unknown>;
    id: number;
};

export interface TelemetryEvent<T extends keyof Events> {
    type: string;
    timestamp: string;
    id: number;
    msgCode: T;
    fields: Events[T];
}

export enum ScooterTelemetryEventsType {
    location = 'LOCATION',
    mileage = 'MILEAGE',
    speed = 'SPEED',
    batteryLevel = 'BATTERY_LEVEL',
}
export enum BikeAndVaeTelemetryEventsType {
    lockreport = 'LOCK_REPORT',
    lockconfig = 'LOCK_CONFIG',
    lockprocessordata = 'LOCK_PROCESSOR_DATA',
    lockltedata = 'LOCK_LTE_DATA',
    lockunsupportedevent = 'LOCK_UNSUPPORTED_EVENT',
    lockbadcontent = 'LOCK_BAD_CONTENT',
    lockReportOpenSuccess = 'LOCK_REPORT_OPEN_SUCCESS',
    lockreportopenfail = 'LOCK_REPORT_OPEN_FAIL',
    lockreportclosesuccess = 'LOCK_REPORT_CLOSE_SUCCESS',
    lockreportclosefail = 'LOCK_REPORT_CLOSE_FAIL',
    lockreportillegalmove = 'LOCK_REPORT_ILLEGAL_MOVE',
    lockreportshock = 'LOCK_REPORT_SHOCK',
    lockcommandack = 'LOCK_COMMAND_ACK',
    lockcommandnack = 'LOCK_COMMAND_NACK',
    lockgetvaedata = 'LOCK_GET_VAE_DATA',
    stationvehiclelock = 'STATION_VEHICLE_LOCK',
    stationvehicleunlock = 'STATION_VEHICLE_UNLOCK',
}

export type Events = {
    [ScooterTelemetryEventsType.batteryLevel]: {
        timestamp: string;
        battery1Level: number;
        battery2Level: number;
        batteryLevel: number;
        dashboardBatteryLevel: number;
    };
    [ScooterTelemetryEventsType.location]: {
        timestamp: string;
        location: number[];
    };
    [ScooterTelemetryEventsType.mileage]: {
        timestamp: string;
        mileage: number;
    };
    [ScooterTelemetryEventsType.speed]: {
        timestamp: string;
        speed: number;
    };
};

export type TelemetryEventsById = {
    [code: number]: FetchedTelemetryEvent;
};

export enum FilterTypes {
    MessageCodes = 'messageCodes',
    StartTime = 'startTime',
    EndTime = 'endTime',
}

export enum Sorting {
    timestamp = 'timestamp',
}

export type Filters = {
    [FilterTypes.MessageCodes]: ScooterTelemetryEventsType;
    [FilterTypes.StartTime]: string;
    [FilterTypes.EndTime]: string;
};

export type VehicleTelemetryEventsState = {
    loading: boolean;
    error: string;
    telemetryEventIds: number[];
    telemetryEventsById: TelemetryEventsById;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
};

export type TelemetryEventsResponse<T extends keyof Events> = {
    content: TelemetryEvent<T>[];
} & Pagination;

export type Accumulator = {
    telemetryEventIds: number[];
    telemetryEventsById: TelemetryEventsById;
};
