import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from 'ducks/common/pagination';
import { SortDirection } from 'ducks/common/types';
import { getTableId } from './helpers';
import { PaymentState, PaymentTable } from './types';

export const defaultPaymentSort = {
    value: 'date',
    direction: SortDirection.Desc,
};

export const defaultPaymentPagination = { ...defaultPagination, size: 5 };

const initialTableValue: PaymentTable = {
    userId: null,
    profileId: null,

    paymentIds: [],
    paymentsById: {},
    sort: {
        ...defaultPaymentSort,
    },
    pagination: { ...defaultPaymentPagination },
};

export const initialState: PaymentState = {
    error: '',
    loading: false,
    tables: {},
};

const paymentSlice = createSlice({
    name: 'paymentHistory',
    initialState,
    reducers: {
        addPaymentTable(state, { payload: { profileId, userId } }) {
            state.tables[getTableId(userId, profileId)] = {
                ...initialTableValue,
                userId,
                profileId,
            };
        },
        fetchUserPaymentHistory(
            state,
            action: PayloadAction<{ userId: number; profileId: number }>,
        ) {
            state.loading = true;
            state.error = '';
        },
        fetchUserPaymentHistorySuccess(
            state,
            {
                payload: {
                    profileId,
                    userId,
                    paymentsById,
                    pagination,
                    paymentIds,
                },
            },
        ) {
            state.tables[getTableId(userId, profileId)] = {
                ...state.tables[getTableId(userId, profileId)],
                paymentsById,
                pagination,
                paymentIds,
            };
            state.loading = false;
        },
        fetchUserPaymentHistoryError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetUserPaymentHistory() {
            return { ...initialState };
        },
        setPage(
            state,
            {
                payload: { profileId, userId, page },
            }: PayloadAction<{
                page: number;
                userId: number;
                profileId: number;
            }>,
        ) {
            state.tables[getTableId(userId, profileId)].pagination.number =
                page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload: { profileId, userId, value },
            }: PayloadAction<{
                value: string;
                userId: number;
                profileId: number;
            }>,
        ) {
            commonSetSort(state.tables[getTableId(userId, profileId)], value);
            state.loading = true;
        },
    },
});

export const {
    setSort,
    setPage,
    fetchUserPaymentHistory,
    resetUserPaymentHistory,
    fetchUserPaymentHistoryError,
    fetchUserPaymentHistorySuccess,
    addPaymentTable,
} = paymentSlice.actions;

export const paymentReducer = paymentSlice.reducer;
