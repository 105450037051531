import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from 'components/common';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { selectFilters, setFilter } from 'ducks/stations';
import { FilterTypes } from 'ducks/stations/types';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

export const SearchToolbar = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);

    const handleSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setFilter({
                    filterType: FilterTypes.Search,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    const handleAddStation = React.useCallback(() => {
        dispatch(push(routes.NEW_STATION));
    }, [dispatch]);
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToCreate = accessToPages[Permissions.STATION_CREATE];
    return (
        <FormGrid columnAmount={2} padding="10px 0" rowHeight="20px">
            <SearchComponent
                placeholder={t('stations.station')}
                onChange={handleSearch}
                defaultValue={filters[FilterTypes.Search]}
            />
            {allowedToCreate && (
                <Box textAlign="right">
                    <ButtonComponent
                        text={t('stations.addStation')}
                        startIcon={<AddIcon />}
                        handleClick={handleAddStation}
                    />
                </Box>
            )}
        </FormGrid>
    );
};
