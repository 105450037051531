import React from 'react';
import {
    composeValidators,
    fieldLength,
    isExpiredDate,
    isNumber,
    isValidEmail,
    minValue,
    required,
} from 'tools/validationRules';
import { useTranslation } from 'react-i18next';
import { Languages } from 'ducks/boUsers/types';
import { FormGrid, SelectField, TextField } from '../../common';
import { useSelector } from 'react-redux';
import { RootState } from 'ducks/root';
import { UsageTypes } from 'ducks/services/types';
import { DateTimeField } from 'components/common/Form/CommonFields/DateTimeField';
import moment from 'moment';
import { UploadFileField } from '../../common/DropzoneField';
import { DeletableFileComponent } from '../../common/File';
import { FileProperties, Invitation } from 'ducks/companies/types';
import { HelpComponent } from 'components/common/HelpComponent';
import { selectVehiclesForInvitation } from 'ducks/companies';

type ScheduleProps = {
    index: number;
    values: Invitation;
    remove: (name: string, index: number) => void;
    resetField: (fieldName: string) => void;
};

export const NewUserComponent: React.FC<ScheduleProps> = ({
    index,
    values,
    remove,
}: ScheduleProps) => {
    const { t } = useTranslation();
    const { currentService } = useSelector((state: RootState) => state.service);
    const vehicleOptions = useSelector(selectVehiclesForInvitation);

    const languageOptions = React.useMemo(
        () =>
            Object.values(Languages).map(language => ({
                label: language,
                value: language,
            })),
        [],
    );

    const handleDeleteImage = React.useCallback(
        deleteIndex => {
            remove(`invitations.${index}.files`, deleteIndex);
        },
        [index, remove],
    );

    return (
        <>
            <FormGrid columnAmount={4}>
                <TextField
                    name={`invitations.${index}.email`}
                    validators={composeValidators(
                        required,
                        isValidEmail,
                        (value: string) => fieldLength(value, 2, 63),
                    )}
                    label={t('invitations.email')}
                    isRequired
                />
                <TextField
                    name={`invitations.${index}.firstName`}
                    validators={composeValidators(required, (value: string) =>
                        fieldLength(value, 2, 30),
                    )}
                    label={t('invitations.firstName')}
                    isRequired
                />
                <TextField
                    name={`invitations.${index}.lastName`}
                    validators={composeValidators(required, (value: string) =>
                        fieldLength(value, 2, 30),
                    )}
                    label={t('invitations.lastName')}
                    isRequired
                />
                <SelectField
                    name={`invitations.${index}.language`}
                    validators={composeValidators(required)}
                    label={t('invitations.language')}
                    options={languageOptions}
                    isRequired
                />
                <DateTimeField
                    id="startTime"
                    name={`invitations.${index}.startTime`}
                    validators={composeValidators(required, isExpiredDate)}
                    label={`${t('invitations.startTime')}, service time zone (${
                        currentService.timezone
                    })`}
                    min={moment().startOf('day')}
                    labelComponent={
                        <HelpComponent
                            tooltipText={t('timeZoneTooltipText', {
                                timeZone: currentService.timezone,
                            })}
                        />
                    }
                    displayTimeZone={currentService.timezone}
                    isRequired
                />
                <DateTimeField
                    name={`invitations.${index}.endTime`}
                    id="endTime"
                    min={moment().startOf('day')}
                    validators={composeValidators(isExpiredDate)}
                    label={`${t('invitations.endTime')}, service time zone (${
                        currentService.timezone
                    })`}
                    labelComponent={
                        <HelpComponent
                            tooltipText={t('timeZoneTooltipText', {
                                timeZone: currentService.timezone,
                            })}
                        />
                    }
                    displayTimeZone={currentService.timezone}
                />
                {currentService.type !== UsageTypes.SHORT_TERM_USAGE && (
                    <SelectField
                        name={`invitations.${index}.lldVehicleId`}
                        validators={composeValidators(required)}
                        label={t('invitations.lldVehicle')}
                        options={vehicleOptions}
                        isRequired
                    />
                )}
                <TextField
                    name={`invitations.${index}.numberOfVehiclesAvailableAtTheSameTime`}
                    type="number"
                    validators={composeValidators(
                        required,
                        isNumber,
                        (value: string) => minValue(value, 1),
                    )}
                    InputProps={{
                        inputProps: { min: 1 },
                    }}
                    label={t(
                        'invitations.numberOfVehiclesAvailableAtTheSameTime',
                    )}
                    isRequired
                />
                <TextField
                    name={`invitations.${index}.rfidPassNumber`}
                    label={t('invitations.rfidPassNumber')}
                />
            </FormGrid>

            <FormGrid columnAmount={5}>
                {!(values.invitations[index].files?.length >= 5) && (
                    <UploadFileField
                        name={`invitations.${index}.files`}
                        existingValues={values.invitations[index].files || []}
                    />
                )}
                {values.invitations[index].files?.map(
                    (image: FileProperties, index: number) => (
                        <DeletableFileComponent
                            image={image}
                            onDelete={() => {
                                handleDeleteImage(index);
                            }}
                            key={image.url}
                        />
                    ),
                )}
            </FormGrid>
        </>
    );
};
