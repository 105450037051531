import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    requestExportTrips,
    fetchTrips,
    fetchTripsError,
    fetchTripsSuccess,
    requestExportTripsSuccess,
    setFilter,
    fetchTrip,
    fetchTripSuccess,
    fetchTripError,
    setSort,
    setPage,
    stopTrip,
    stopTripSuccess,
    fetchStatisticsError,
    fetchStatisticsSuccess,
    fetchStatistics,
    fetchTripsMaxDurationSuccess,
    fetchTripsMaxDurationError,
    fetchTripsMaxDuration,
    setMaxDurationFilter,
    setOverdueSort,
    selectOverdueSort,
} from './index';
import {
    exportTrips,
    getStatistics,
    getTrip,
    getTrips,
    stopTripService,
} from './service';
import { selectFilters, selectPagination, selectSort } from './selectors';
import {
    FetchedCurrentTrip,
    FetchedStatistics,
    Filters,
    TripResponse,
} from './types';
import { mapFilters, mapPagination, mapSorting } from 'ducks/common/mappers';
import {
    mapFetchedTrip,
    mapFetchedTrips,
    mapStatisticsByStatus,
} from './mapper';
import { mapFetchedPagination } from 'ducks/common/pagination';
import { downloadBlob } from 'tools/file';
import { PayloadAction } from '@reduxjs/toolkit';
import { Pagination, Sort } from '../common/types';
import { Moment } from 'moment';
import { NotificationTypes } from 'ducks/ui/types';
import { setNotification } from 'ducks/ui';
import { stopTripError } from './tripSlice';

function* fetchTripsSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: TripResponse = yield call(getTrips, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { tripIds, tripsById } = mapFetchedTrips(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchTripsSuccess({
                tripIds,
                tripsById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchTripsError({ error: (error as Error)?.message }));
    }
}
function* fetchTripsMaxDurationSaga() {
    try {
        const { services }: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectOverdueSort);

        const data: TripResponse = yield call(getTrips, {
            size: 5,
            page: 0,
            maxDurationOverdue: true,
            ...mapFilters({ services }),
            ...mapSorting(sort),
        });
        const { tripIds, tripsById } = mapFetchedTrips(data);

        yield put(
            fetchTripsMaxDurationSuccess({
                tripIds,
                tripsById,
            }),
        );
    } catch (error) {
        yield put(
            fetchTripsMaxDurationError({ error: (error as Error)?.message }),
        );
    }
}

function* fetchStatisticsSaga() {
    try {
        const { services }: Filters = yield select(selectFilters);
        const data: FetchedStatistics = yield call(
            getStatistics,
            mapFilters({
                serviceIds: services,
            }),
        );
        yield put(fetchStatisticsSuccess(mapStatisticsByStatus(data)));
    } catch (error) {
        yield put(fetchStatisticsError({ error: (error as Error)?.message }));
    }
}

function* exportTripsSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const { file, fileName } = yield call(exportTrips, mapFilters(filters));
        downloadBlob(file, fileName);
        yield put(requestExportTripsSuccess());
    } catch (error) {
        yield put(fetchTripsError({ error: (error as Error)?.message }));
    }
}

function* fetchTripSaga({ payload }: PayloadAction<number>) {
    try {
        const data: FetchedCurrentTrip = yield call(getTrip, payload);
        const preparedData = mapFetchedTrip(data);
        yield put(fetchTripSuccess(preparedData));
    } catch (error) {
        yield put(fetchTripError({ error: (error as Error)?.message }));
    }
}

function* stopTripSaga({
    payload,
}: PayloadAction<{
    tripId: number;
    endDate: string | Moment;
}>) {
    try {
        yield call(stopTripService, {
            tripId: payload.tripId,
            endDate: payload.endDate,
        });
        yield put(stopTripSuccess());
        yield put(fetchTrip(payload.tripId));
    } catch (error) {
        yield put(stopTripError({ error: (error as Error)?.message }));
        yield put(
            setNotification({
                message: (error as Error)?.message,
                type: NotificationTypes.ERROR,
            }),
        );
    }
}

export default function* tripSagas() {
    yield takeLatest(fetchTrips.type, fetchTripsSaga);
    yield takeLatest(fetchTripsMaxDuration.type, fetchTripsMaxDurationSaga);
    yield takeLatest(setFilter.type, fetchTripsSaga);
    yield takeLatest(setOverdueSort.type, fetchTripsMaxDurationSaga);
    yield takeLatest(setMaxDurationFilter.type, fetchTripsSaga);
    yield takeLatest(requestExportTrips.type, exportTripsSaga);
    yield takeLatest(fetchTrip.type, fetchTripSaga);
    yield takeLatest(setSort.type, fetchTripsSaga);
    yield takeLatest(setPage.type, fetchTripsSaga);
    yield takeLatest(stopTrip.type, stopTripSaga);
    yield takeLatest(fetchStatistics.type, fetchStatisticsSaga);
}
