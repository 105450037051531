import request from 'helpers/request';
import { getFileFromResponse } from 'tools/file';
import { LOGS_EXPORT_URL, LOGS_URL } from 'constants/api';

export const getLogs = (params: any): Promise<string> =>
    request.get(LOGS_URL, { params });

export const exportLogs = (params: any): Promise<any> =>
    request
        .get(LOGS_EXPORT_URL, { params })
        .then(response => getFileFromResponse(response));
