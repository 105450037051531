import { DeletableFileComponent } from '../../common/File';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import {
    fetchUserDocument,
    deleteUserDocument,
    selectCurrentUserDocumentsById,
} from 'ducks/users';
import { useParams } from 'react-router';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { RootState } from 'ducks/root';
interface Document {
    filename: string;
}
export const DocumentContainer: React.FC<Document> = ({
    filename,
}: Document) => {
    const { userId } = useParams<{ userId?: string }>();
    const dispatch = useDispatch();

    const currentUserDocumentById = useSelector((state: RootState) =>
        selectCurrentUserDocumentsById(state, filename),
    );

    const accessToPages = useSelector(selectAccessToPages);
    useEffect(() => {
        dispatch(fetchUserDocument({ userId, filename }));
    }, [dispatch, filename, userId]);
    const allowedToEdit = accessToPages[Permissions.END_USER_UPDATE];
    const handleDeleteImage = React.useCallback(() => {
        dispatch(deleteUserDocument({ userId, filename }));
        dispatch(closeModal());
    }, [dispatch, userId, filename]);

    const handleClickDelete = React.useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: () => handleDeleteImage(),
                    message: 'usersList.documentsDeleteMessage',
                },
            }),
        );
    }, [dispatch, handleDeleteImage]);

    return currentUserDocumentById ? (
        <DeletableFileComponent
            showDeleteButton={allowedToEdit}
            key={currentUserDocumentById?.url}
            image={currentUserDocumentById}
            onDelete={handleClickDelete}
        />
    ) : null;
};
