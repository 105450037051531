import React from 'react';
import { getColumns } from './columns';
import { VehiclesListComponent } from '../VehiclesList';
import { VehicleTypes } from 'ducks/vehicles/common/types';
import { useTranslation } from 'react-i18next';

export const ScootersListComponent = () => {
    const { t } = useTranslation();
    const types = React.useMemo(() => [VehicleTypes.SCOOTER], []);
    return (
        <VehiclesListComponent
            getColumns={getColumns}
            vehicleTypes={types}
            title={t('vehicles.title')}
        />
    );
};
