import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from 'components/common';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { requestExportLogs, setFilter } from 'ducks/logs';
import { FilterTypes } from 'ducks/logs/types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { selectFilters } from 'ducks/logs/selectors';

export const LogsSearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);

    const handleExportLogs = () => {
        dispatch(requestExportLogs());
    };
    const handleEmailSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setFilter({
                    filterType: FilterTypes.Email,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    return (
        <FormGrid columnAmount={2} padding="10px 0" rowHeight="20px">
            <SearchComponent
                id="emailSearch"
                placeholder={t('logs.searchPlaceholder')}
                onChange={handleEmailSearch}
                defaultValue={filters[FilterTypes.Email]}
            />

            <Box textAlign="right">
                <ButtonComponent
                    text={t('download')}
                    startIcon={<SaveAltIcon />}
                    handleClick={handleExportLogs}
                />
            </Box>
        </FormGrid>
    );
};
