import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../../themes/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            width: '40%',
            maxWidth: '600px',
            margin: 'auto',
            textAlign: 'center',
            border: `2px solid ${theme.palette.secondary.main}`,
            borderRadius: '25px',
            padding: '20px',
            backgroundColor: colors.white,
            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
            '&:focus': {
                outline: 'none',
            },
            transform: 'translateX(-50%) translateY(-50%)',
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
    }),
);
