import {
    StationResponse,
    Accumulator,
    Station,
    FetchedStation,
    StationActivationStatuses,
    StationTypes,
} from './types';

export const mapFetchedStations = ({
    content,
}: StationResponse): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, station) => {
            return {
                stationsById: {
                    ...acc.stationsById,
                    [station.station.id]: mapStation(station),
                },
                stationIds: [...acc.stationIds, station.station.id],
            };
        },
        { stationIds: [], stationsById: {} },
    );
};

export const mapStation = (content: FetchedStation): Station => {
    return {
        ...content.station,
        serviceId: content.service?.id,
        serviceName: content.service?.name,
        statusOfActivation:
            content.station.statusOfActivation ||
            StationActivationStatuses.ACTIVE,
        stationType: content.station.stationType || StationTypes.Simple,
    };
};
