import { RootState } from '../root';
import { createSelector } from 'reselect';

export const selectMyUser = (state: RootState) => state.auth.myUser;
export const selectMyUserGroupPermissions = (state: RootState) =>
    state.auth.myUser?.groups[0].permissions || [];
export const selectMyUserId = (state: RootState) => state.auth.myUser?.user.id;
export const selectMyCompanyLogo = createSelector(selectMyUser, myUser =>
    myUser?.user.isManager
        ? myUser?.companies[0]?.boLogo && `${myUser?.companies[0]?.boLogo}`
        : '',
);
export const selectMyCompanyName = createSelector(selectMyUser, myUser =>
    myUser?.user.isManager ? myUser?.companies[0]?.name : '',
);
export const selectMyCompanyId = createSelector(selectMyUser, myUser =>
    myUser?.user.isManager ? myUser?.companies[0]?.id : null,
);
export const selectMyUserInfo = (state: RootState) => state.auth.myUser?.user;
export const selectIsSuperAdmin = (state: RootState) =>
    state.auth.myUser?.user.isSuperAdmin || false;
export const selectIsManager = (state: RootState) =>
    state.auth.myUser?.user.isManager || false;
export const selectEnforcePasswordChange = (state: RootState) =>
    state.auth.myUser?.user.forcePasswordChange || false;
export const selectAccessToPages = (state: RootState) =>
    state.auth.accessToPages;
export const selectIsUserLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectIsLoading = (state: RootState) => state.auth.loading;
export const selectIsAccountLoading = (state: RootState) =>
    state.auth.accountLoading;
export const selectError = (state: RootState) => state.auth.error;
export const selectErrorCode = (state: RootState) => state.auth.errorCode;
export const selectIsMyUserHasUnsavedChanges = (state: RootState) =>
    state.auth.myUserHasUnsavedChanges;
