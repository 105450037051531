import { VehicleStatus, VehicleTypes } from 'ducks/vehicles/common/types';
import bookedBike from 'resources/icons/bike/booked.svg';
import boundBike from 'resources/icons/bike/bound.svg';
import brokenBike from 'resources/icons/bike/broken.svg';
import deployedBike from 'resources/icons/bike/deployed.svg';
import destroyedBike from 'resources/icons/bike/destroyed.svg';
import inactiveBike from 'resources/icons/bike/inactive.svg';
import parkedBike from 'resources/icons/bike/parked.svg';
import pausedBike from 'resources/icons/bike/paused.svg';
import repairBike from 'resources/icons/bike/repair.svg';
import riddenBike from 'resources/icons/bike/ridden.svg';
import selectedBike from 'resources/icons/bike/selected.svg';
import stolenBike from 'resources/icons/bike/stolen.svg';
import unavailableBike from 'resources/icons/bike/unavailable.svg';
import bookedScooter from 'resources/icons/scooter/booked.svg';
import boundScooter from 'resources/icons/scooter/bound.svg';
import brokenScooter from 'resources/icons/scooter/broken.svg';
import deployedScooter from 'resources/icons/scooter/deployed.svg';
import destroyedScooter from 'resources/icons/scooter/destroyed.svg';
import inactiveScooter from 'resources/icons/scooter/inactive.svg';
import parkedScooter from 'resources/icons/scooter/parked.svg';
import pausedScooter from 'resources/icons/scooter/paused.svg';
import repairScooter from 'resources/icons/scooter/repair.svg';
import riddenScooter from 'resources/icons/scooter/ridden.svg';
import selectedScooter from 'resources/icons/scooter/selected.svg';
import stolenScooter from 'resources/icons/scooter/stolen.svg';
import unavailableScooter from 'resources/icons/scooter/unavailable.svg';
import bookedVae from 'resources/icons/vae/booked.svg';
import boundVae from 'resources/icons/vae/bound.svg';
import brokenVae from 'resources/icons/vae/broken.svg';
import deployedVae from 'resources/icons/vae/deployed.svg';
import destroyedVae from 'resources/icons/vae/destroyed.svg';
import inactiveVae from 'resources/icons/vae/inactive.svg';
import parkedVae from 'resources/icons/vae/parked.svg';
import pausedVae from 'resources/icons/vae/paused.svg';
import repairVae from 'resources/icons/vae/repair.svg';
import riddenVae from 'resources/icons/vae/ridden.svg';
import selectedVae from 'resources/icons/vae/selected.svg';
import stolenVae from 'resources/icons/vae/stolen.svg';
import unavailableVae from 'resources/icons/vae/unavailable.svg';

export const vehicleIconConfig = {
    [VehicleTypes.SCOOTER]: {
        [VehicleStatus.inactive]: inactiveScooter,
        [VehicleStatus.bound]: boundScooter,
        [VehicleStatus.deployed]: deployedScooter,
        [VehicleStatus.ridden]: riddenScooter,
        [VehicleStatus.booked]: bookedScooter,
        [VehicleStatus.paused]: pausedScooter,
        [VehicleStatus.unavailable]: unavailableScooter,
        [VehicleStatus.broken]: brokenScooter,
        [VehicleStatus.repair]: repairScooter,
        [VehicleStatus.parked]: parkedScooter,
        [VehicleStatus.stolen]: stolenScooter,
        [VehicleStatus.lost]: stolenScooter,
        [VehicleStatus.destroyed]: destroyedScooter,
        selected: selectedScooter,
    },
    [VehicleTypes.BIKE]: {
        [VehicleStatus.inactive]: inactiveBike,
        [VehicleStatus.bound]: boundBike,
        [VehicleStatus.deployed]: deployedBike,
        [VehicleStatus.ridden]: riddenBike,
        [VehicleStatus.booked]: bookedBike,
        [VehicleStatus.paused]: pausedBike,
        [VehicleStatus.unavailable]: unavailableBike,
        [VehicleStatus.broken]: brokenBike,
        [VehicleStatus.repair]: repairBike,
        [VehicleStatus.parked]: parkedBike,
        [VehicleStatus.stolen]: stolenBike,
        [VehicleStatus.lost]: stolenBike,
        [VehicleStatus.destroyed]: destroyedBike,
        selected: selectedBike,
    },
    [VehicleTypes.VAE]: {
        [VehicleStatus.inactive]: inactiveVae,
        [VehicleStatus.bound]: boundVae,
        [VehicleStatus.deployed]: deployedVae,
        [VehicleStatus.ridden]: riddenVae,
        [VehicleStatus.booked]: bookedVae,
        [VehicleStatus.paused]: pausedVae,
        [VehicleStatus.unavailable]: unavailableVae,
        [VehicleStatus.broken]: brokenVae,
        [VehicleStatus.repair]: repairVae,
        [VehicleStatus.parked]: parkedVae,
        [VehicleStatus.stolen]: stolenVae,
        [VehicleStatus.lost]: stolenVae,
        [VehicleStatus.destroyed]: destroyedVae,
        selected: selectedVae,
    },
};
