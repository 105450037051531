import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { FetchedUserPayment } from 'ducks/paymentHistory/types';
import { setSort } from 'ducks/paymentHistory';

export const getPaymentHistoryColumns = (
    t: TFunction,
    dispatch: Dispatch<any>,
    userId: number,
    profileId: number,
) => {
    return [
        {
            title: t('usersList.paymentHistory.date'),
            field: 'date',
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedUserPayment) => (
                <span>{new Date(rowProps.date).toLocaleString()}</span>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'date',
                        userId,
                        profileId,
                    }),
                ),
        },
        {
            title: t('usersList.paymentHistory.action'),
            field: 'action',
            sorting: false,
        },
        {
            title: t('usersList.paymentHistory.amount'),
            field: 'amount',
            sorting: false,
        },
        {
            title: t('usersList.paymentHistory.transactionId'),
            field: 'transactionId',
            sorting: false,
        },
        {
            title: t('usersList.paymentHistory.status'),
            field: 'status',
            sorting: false,
        },
        {
            title: t('usersList.paymentHistory.comment'),
            field: 'comment',
            sorting: false,
        },
        {
            title: t('usersList.paymentHistory.tripId'),
            field: 'trip',
            sorting: false,
        },
        {
            title: t('usersList.paymentHistory.stripeError'),
            field: 'stripeError',
            sorting: false,
            render: () => null,
        },
    ];
};
