import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectComponent } from 'components/common';
import {
    FilterTypes,
    setFilter,
    selectFilters,
    resetFilters,
    fetchScooterMaintenance,
} from 'ducks/scooterMaintenance';
import { Box } from '@material-ui/core';
import { ScooterMaintenanceType } from 'ducks/scooterMaintenance/types';
import { useParams } from 'react-router';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';

export const Toolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const filters = useSelector(selectFilters);

    const maintenanceTypeOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...Object.values(ScooterMaintenanceType).map(type => ({
                label: t(`scooterMaintenance.types.${type}`),
                value: type,
            })),
        ],
        [t],
    );

    const handleChangeFilter = (
        e: React.ChangeEvent<HTMLInputElement>,
        filterType: FilterTypes,
    ) => {
        dispatch(
            setFilter({
                filterType,
                value: e.target.value,
            }),
        );
    };

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());

        if (vehicleId) {
            dispatch(
                setFilter({
                    filterType: FilterTypes.VehicleIds,
                    value: vehicleId,
                }),
            );
        }

        dispatch(fetchScooterMaintenance());
    }, [dispatch, vehicleId]);

    return (
        <Box display="flex" alignItems="center">
            <Box width="170px" padding="10px 0">
                <SelectComponent
                    label={t('scooterMaintenance.type')}
                    onChange={e => handleChangeFilter(e, FilterTypes.Type)}
                    value={filters.type}
                    options={maintenanceTypeOptions}
                />
            </Box>
            {!!filters.type && (
                <Box marginTop="10px" marginLeft="10px">
                    <ResetFiltersButton
                        handleResetFilters={handleResetFilters}
                    />
                </Box>
            )}
        </Box>
    );
};
