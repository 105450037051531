import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/events';
import { Check, LinkComponent } from 'components/common';
import { Modals, openModal } from 'ducks/ui';
import { Event, Sorting } from 'ducks/events/types';
import { Box } from '@material-ui/core';
import { Cross } from 'components/common/CrossIcon';
import { DateTimeFormatter } from 'ducks/common/types';
const renderStatus = (status: string) => (
    <Box textAlign="center">{status === 'OK' ? <Check /> : <Cross />}</Box>
);

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('events.id'),
            field: 'id',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'id',
                    }),
                ),
        },
        {
            title: t('events.sourceType'),
            field: 'sourceType',
            sorting: false,
            render: (rowProps: Event) =>
                t(`eventSource.${rowProps.sourceType}`),
        },
        {
            title: t('events.type'),
            field: 'type',
            sorting: false,
            render: (rowProps: Event) => t(`eventTypes.${rowProps.type}`),
        },
        {
            title: t('events.status'),
            field: 'status',
            sorting: false,
            render: (rowProps: Event) => renderStatus(rowProps.status),
        },
        {
            title: t('events.oldVehicleStatus'),
            field: 'oldVehicleStatus',
            render: (rowProps: Event) =>
                rowProps.oldVehicleStatus
                    ? t(`scooterStatuses.${rowProps.oldVehicleStatus}`)
                    : '-',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'oldVehicleStatus',
                    }),
                ),
        },
        {
            title: t('events.newVehicleStatus'),
            field: 'newVehicleStatus',
            render: (rowProps: Event) =>
                rowProps.newVehicleStatus
                    ? t(`scooterStatuses.${rowProps.newVehicleStatus}`)
                    : '-',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'newVehicleStatus',
                    }),
                ),
        },
        {
            title: t('events.gps'),
            field: 'gps',
            // eslint-disable-next-line react/display-name
            render: (rowProps: Event) => (
                <LinkComponent
                    text={`${rowProps.lat || '-'}, ${rowProps.lng || '-'}`}
                    handleClick={() =>
                        dispatch(
                            openModal({
                                modalType: Modals.MAP_WITH_POINT,
                                modalProps: {
                                    position: {
                                        lat: rowProps.lat,
                                        lng: rowProps.lng,
                                    },
                                    title: t('events.eventPosition'),
                                },
                            }),
                        )
                    }
                />
            ),
            sorting: false,
        },
        {
            title: t('events.timestamp'),
            field: 'timestamp',
            // eslint-disable-next-line react/display-name
            render: (rowProps: Event) => (
                <span>
                    {rowProps.timestamp
                        ? DateTimeFormatter.format(new Date(rowProps.timestamp))
                        : '-'}
                </span>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: Sorting.timestamp,
                    }),
                ),
        },
        {
            title: t('events.boUserId'),
            field: 'boUserId',
            sorting: false,
        },
        {
            title: t('events.boUserEmail'),
            field: 'boUserEmail',
            sorting: false,
        },
        {
            title: t('events.endUserId'),
            field: 'endUserId',
            sorting: false,
        },
        {
            title: t('events.endUserEmails'),
            field: 'endUserEmails',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: Event) =>
                rowProps.endUsers?.map(({ email, type }, index) => (
                    <span key={index}>{`${email}, ${type} \n`}</span>
                )),
        },
    ];
};
