import {
    composeValidators,
    fieldLength,
    isNumber,
    maxValue,
    minValue,
    required,
    isValidStationIdFormat,
    isStationIdRequired,
    isUrl,
} from 'tools/validationRules';

export const validations = {
    name: composeValidators(required, (value: string) =>
        fieldLength(value, 2, 50),
    ),
    totalPlaces: composeValidators(isNumber, (value: string) =>
        minValue(value, 0),
    ),
    latitude: composeValidators(
        required,
        isNumber,
        (value: string) => minValue(value, -90),
        (value: string) => maxValue(value, 90),
    ),
    longitude: composeValidators(
        required,
        isNumber,
        (value: string) => minValue(value, -180),
        (value: string) => maxValue(value, 180),
    ),
    serviceId: composeValidators(required),
    stationId: composeValidators(isValidStationIdFormat, isStationIdRequired),
    dockType: composeValidators(required),
    rfIdUrl: composeValidators(
        (value: string) => (value ? isUrl(value) : ''),
        (value: string) => (value ? fieldLength(value, 0, 50) : ''),
    ),
};
