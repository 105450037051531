import { RootState } from '../root';
import { createSelector } from '@reduxjs/toolkit';
import { EmailsAccumulator, FetchedRecipient } from './types';
import { VehicleTypes } from '../vehicles/common/types';

export const selectInvitationPagination = (state: RootState) =>
    state.company.invitationPagination;
export const selectPagination = (state: RootState) => state.company.pagination;
export const selectFilters = (state: RootState) => state.company.filters;
export const selectSort = (state: RootState) => state.company.sort;
export const selectIsLoading = (state: RootState) => state.company.loading;

export const selectCompanyIds = (state: RootState) => state.company.companyIds;

export const selectCompaniesById = (state: RootState) =>
    state.company.companiesById;

export const selectAllCompanyIds = (state: RootState) =>
    state.company.allCompanyIds;

export const selectAllCompaniesById = (state: RootState) =>
    state.company.allCompaniesById;

export const selectCurrentCompany = (state: RootState) =>
    state.company.currentCompany;
export const selectCurrentCompanyId = (state: RootState) =>
    state.company.currentCompanyId;

export const selectCurrentCompanyReportDashboard = (state: RootState) =>
    selectCurrentCompany(state).reportingDashboard || undefined;

export const selectCompanyError = (state: RootState) => state.company.error;
export const selectFreeVehiclesById = (state: RootState) =>
    state.company.freeVehicles;
export const selectVehiclesForInvitation = createSelector(
    selectFreeVehiclesById,
    freeVehicles =>
        Object.values(freeVehicles).map(vehicle => ({
            value: vehicle.id,
            label:
                vehicle.type === VehicleTypes.SCOOTER
                    ? vehicle.plate || ''
                    : vehicle.serial,
        })),
);

export const selectInvitationHasUnsavedChanges = (state: RootState) =>
    state.company.invitationHasUnsavedChanges;

export const selectEmailRecipients = (state: RootState) =>
    state.company.emailRecipients;

export const selectEmailRecipientsMappedByAlarmReason = (state: RootState) => {
    return Object.values(state.company.emailRecipients).length > 0
        ? state.company.emailRecipients.reduce(
              (acc: EmailsAccumulator, recipient: FetchedRecipient) => {
                  if (recipient.fallonState) {
                      acc.fallonState.push(recipient.email);
                  }

                  if (recipient.lowBattery) {
                      acc.lowBattery.push(recipient.email);
                  }

                  if (recipient.shockAlarm) {
                      acc.shockAlarm.push(recipient.email);
                  }

                  if (recipient.speedTooHigh) {
                      acc.speedTooHigh.push(recipient.email);
                  }
                  return acc;
              },
              {
                  fallonState: [],
                  lowBattery: [],
                  shockAlarm: [],
                  speedTooHigh: [],
              },
          )
        : {};
};
