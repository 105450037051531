import React, { ReactNode } from 'react';
import { Box, AppBar } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectMyCompanyLogo } from 'ducks/auth';
import { StyledToolbar } from '../Toolbar';
import { MenuComponent } from './Menu';
import { linkRegexp } from 'tools/validationRules';
import indigoweel from 'resources/icons/indigoweel.png';

interface IHeaderProps {
    children?: ReactNode;
}

export const HeaderComponent: React.FC<IHeaderProps> = ({
    children,
}: IHeaderProps) => {
    const myCompanyLogo = useSelector(selectMyCompanyLogo);
    const cleanedLink = React.useMemo(
        () => (linkRegexp.test(myCompanyLogo) ? myCompanyLogo : ''),
        [myCompanyLogo],
    );
    return (
        <AppBar color="default" position="sticky">
            <StyledToolbar>
                <Box
                    display="flex"
                    alignItems="center"
                    flexGrow="1"
                    mr="20px"
                    ml="20px">
                    <Box display="flex" alignItems="center">
                        <img
                            src={cleanedLink || indigoweel}
                            height="50px"
                            alt=""
                        />
                    </Box>
                    {children}
                    <MenuComponent />
                </Box>
            </StyledToolbar>
        </AppBar>
    );
};
