import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { updateLeaveDate } from 'ducks/boUsers';
import { useParams } from 'react-router';
import { DateTimeField } from '../../common/Form/CommonFields/DateTimeField';
import moment, { Moment } from 'moment';

type LeaveDateType = {
    changeLeaveDate: (value: string) => void;
};

export const LeaveDateInput = ({ changeLeaveDate }: LeaveDateType) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userId } = useParams<{ userId?: string }>();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = accessToPages[Permissions.BO_USER_UPDATE];

    const handleChangeLeaveDate = useCallback(
        (e: Moment | string) => {
            const value = e ? moment(e).endOf('day').format('YYYY-MM-DD') : '';
            dispatch(
                openModal({
                    modalType: Modals.CHANGE_CONFIRMATION,
                    modalProps: {
                        handleConfirm: () => {
                            dispatch(closeModal());
                            changeLeaveDate(value);
                            dispatch(updateLeaveDate(userId, value));
                        },
                        message: t('backofficeUsers.addLeaveDateMessage'),
                        confirmButtonName: t('save'),
                    },
                }),
            );
        },
        [dispatch, t, changeLeaveDate, userId],
    );

    return (
        <DateTimeField
            name="leaveDate"
            id="leaveDate"
            label={t('backofficeUsers.leaveDate')}
            max={moment().endOf('day')}
            disabled={!allowedToEdit}
            handleChange={handleChangeLeaveDate}
            timeFormat={false}
            closeOnSelect={true}
        />
    );
};
