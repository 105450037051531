import { Pagination, Sort } from '../common/types';

export enum PartnerCallerTypes {
    SWAGGER = 'SWAGGER',
    BO = 'BO',
    MOBILE = 'MOBILE',
}

export type NewPartner = {
    name: string;
    key: string;
    active: string;
    caller: PartnerCallerTypes;
    companies: number[];
};

export type PartnerCompany = {
    id: number;
    name: string;
    isEnabled: boolean;
    createdString: string;
};

export type Partner = {
    id: number;
    name: string;
    key: string;
    isEnabled: boolean;
    created: string;
    caller: PartnerCallerTypes | null;
    companies: PartnerCompany[];
};

export interface Content extends Pagination {
    content: Partner[];
}

export type PartnersById = {
    [code: number]: Partner;
};

export type PartnersState = {
    loading: boolean;
    error: string;
    partnersIds: number[];
    partnersById: PartnersById;
    currentPartner: Partner | null;
    partnerHasUnsavedChanges: boolean;
    sort: Sort;
    pagination: Pagination;
};
