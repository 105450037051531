import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { routes } from '../../constants/common';
import { LoginForm } from 'components/pages/Login';
import { ThemeProvider } from '@material-ui/styles';
import {
    createMuiTheme,
    CircularProgress,
    Box,
    Snackbar,
} from '@material-ui/core';
import { getTheme } from 'themes/themes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, history } from 'ducks/root';
import { AuthLayoutComponent } from '../layouts/AuthLayoutComponent';
import { CommonLayoutComponent } from '../layouts/CommonLayoutComponent';
import { ServiceListComponent } from '../pages/ServiceList';
import { CreateServiceForm } from '../pages/CreateService';
import { BackofficeUsersList } from '../pages/BackofficeUsersList';
import { CreateBackofficeUser } from '../pages/CreateBackofficeUser';
import { ServiceSchedule } from '../pages/ServiceSchedule';
import { ScootersListComponent } from '../pages/ScootersList';
import { CreateCompanyComponent } from '../pages/CreateCompany';
import { CompaniesList } from '../pages/CompaniesList';
import { AddUsersComponent } from '../pages/InviteUsers';
import { CreateScooterComponent } from '../pages/CreateScooter';
import { TripDetails } from '../pages/TripDetails';
import { ScooterDetailsComponent } from '../pages/ScooterDetails';
import { TripsList } from '../pages/TripsList';
import { UserDetails } from '../pages/UserDetails';
import { CreateZoneComponent } from '../pages/CreateZone';
import { ZoneList } from '../pages/ZoneList';
import { VehiclesMapComponent } from '../pages/VehiclesMap';
import { StationList } from '../pages/StationList';
import { CreateStationComponent } from '../pages/CreateStation';
import { GroupProfilesList } from '../pages/GroupProfilesList';
import {
    fetchMyUser,
    selectIsAccountLoading,
    selectIsManager,
    selectIsSuperAdmin,
    selectEnforcePasswordChange,
} from 'ducks/auth';
import { CreateGroup } from '../pages/CreateGroup';
import { LegalDocuments } from '../pages/LegalDocuments';
import { Permissions } from 'ducks/groups/types';
import { MainPage } from '../pages/MainPage';
import { MyProfile } from '../pages/MyProfile';
import { BikesAndVAEList } from '../pages/BikesAndVAEList';
import { CreateNews } from '../pages/CreateNews';
import { NewsList } from '../pages/NewsList';
import { ResetPasswordForm } from '../pages/ResetPasswordPage';
import { BikeAndVaeDetails } from '../pages/BikeAndVaeDetails';
import { PartnersList } from '../pages/PartnersList';
import { CreatePartner } from '../pages/CreatePartner';
import { ManageAppVersion } from '../pages/ApplicationVersion';
import { LocksList } from '../pages/LocksList';
import { CreateLock } from '../pages/CreateLock';
import { UpdateLockContainer } from '../pages/UpdateLock/UpdateLockContainer';
import { PushNotification } from '../pages/PushNotification';
import { ReportDashboard } from '../pages/ReportDashboard';
import { selectNotificationMessage, setNotification } from 'ducks/ui';
import { AdminLogger } from '../pages/AdminLogger';
import { Swagger } from '../pages/Swagger';
import MuiAlert from '@material-ui/lab/Alert';

export const AppComponent: React.FC = () => {
    const { theme: currentTheme } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchMyUser());
    }, [dispatch]);
    const isLoading = useSelector(selectIsAccountLoading);
    const notification = useSelector(selectNotificationMessage);
    const isManager = useSelector(selectIsManager);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const shouldForcePasswordChange = useSelector(selectEnforcePasswordChange);

    const handleSnackBarClosing = React.useCallback(
        () =>
            dispatch(
                setNotification({
                    message: '',
                    type: notification.type,
                }),
            ),
        [dispatch, notification.type],
    );
    return (
        <ThemeProvider theme={createMuiTheme(getTheme(currentTheme))}>
            <Snackbar
                open={!!notification.message}
                onClose={handleSnackBarClosing}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MuiAlert
                    severity={notification.type}
                    onClose={handleSnackBarClosing}>
                    {notification.message}
                </MuiAlert>
            </Snackbar>

            {isLoading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <ConnectedRouter history={history}>
                    <Switch>
                        <AuthLayoutComponent
                            path={routes.LOGIN}
                            component={LoginForm}
                        />
                        <AuthLayoutComponent
                            path={routes.PASSWORD_RESTORE}
                            component={ResetPasswordForm}
                        />
                        <CommonLayoutComponent
                            path={routes.PROFILE}
                            component={MyProfile}
                        />
                        {shouldForcePasswordChange && (
                            <Redirect from="/" to={routes.PROFILE} />
                        )}
                        <CommonLayoutComponent
                            path={routes.SERVICES}
                            permission={Permissions.SERVICE_VIEW}
                            component={ServiceListComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.SERVICE_CONFIGURATION}
                            permission={Permissions.SERVICE_VIEW}
                            component={ServiceSchedule}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_SERVICE}
                            permission={Permissions.SERVICE_CREATE}
                            component={CreateServiceForm}
                        />
                        <CommonLayoutComponent
                            path={routes.SERVICE}
                            permission={Permissions.SERVICE_VIEW}
                            component={CreateServiceForm}
                        />
                        <CommonLayoutComponent
                            path={routes.BO_USER}
                            permission={Permissions.BO_USER_VIEW}
                            component={CreateBackofficeUser}
                        />
                        <CommonLayoutComponent
                            path={routes.BO_USERS}
                            permission={Permissions.BO_USER_VIEW}
                            component={BackofficeUsersList}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_BO_USER}
                            permission={Permissions.BO_USER_CREATE}
                            component={CreateBackofficeUser}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_COMPANY}
                            permission={Permissions.COMPANY_CREATE}
                            component={CreateCompanyComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.COMPANY}
                            permission={Permissions.COMPANY_VIEW}
                            component={CreateCompanyComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.COMPANIES}
                            permission={Permissions.COMPANY_VIEW}
                            component={CompaniesList}
                        />
                        <CommonLayoutComponent
                            path={routes.SCOOTERS}
                            permission={Permissions.VEHICLE_VIEW}
                            component={ScootersListComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_SCOOTER}
                            permission={Permissions.VEHICLE_CREATE}
                            component={CreateScooterComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.SCOOTER}
                            permission={Permissions.VEHICLE_VIEW}
                            component={ScooterDetailsComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.INVITE_TO_SERVICE}
                            permission={Permissions.INVITATION_VIEW}
                            component={AddUsersComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.TRIP}
                            permission={Permissions.TRIP_VIEW}
                            component={TripDetails}
                        />
                        <CommonLayoutComponent
                            path={routes.TRIPS}
                            permission={Permissions.TRIP_VIEW}
                            component={TripsList}
                        />
                        <CommonLayoutComponent
                            path={routes.USER}
                            permission={Permissions.END_USER_VIEW}
                            component={UserDetails}
                        />
                        <CommonLayoutComponent
                            path={routes.APP_VERSION}
                            permission={Permissions.MANAGE_APP_VERSION}
                            component={ManageAppVersion}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_ZONE}
                            permission={Permissions.ZONE_CREATE}
                            component={CreateZoneComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.ZONE}
                            permission={Permissions.ZONE_VIEW}
                            component={CreateZoneComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.ZONES}
                            permission={Permissions.ZONE_VIEW}
                            component={ZoneList}
                        />
                        <CommonLayoutComponent
                            path={routes.VEHICLES_MAP}
                            permission={Permissions.VEHICLE_VIEW}
                            component={VehiclesMapComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_STATION}
                            permission={Permissions.STATION_CREATE}
                            component={CreateStationComponent}
                        />
                        <CommonLayoutComponent
                            path={routes.STATION}
                            permission={Permissions.STATION_VIEW}
                            component={CreateStationComponent}
                        />
                        <CommonLayoutComponent
                            exact
                            permission={Permissions.SERVICE_VIEW}
                            path={routes.STATIONS}
                            component={StationList}
                        />
                        <CommonLayoutComponent
                            exact
                            permission={Permissions.GROUP_VIEW}
                            path={routes.GROUPS}
                            component={GroupProfilesList}
                        />
                        <CommonLayoutComponent
                            exact
                            path={routes.NEW_GROUP}
                            permission={Permissions.GROUP_CREATE}
                            component={CreateGroup}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.GROUP_VIEW}
                            path={routes.GROUP}
                            component={CreateGroup}
                        />
                        <CommonLayoutComponent
                            path={routes.MAIN_PAGE}
                            component={MainPage}
                        />
                        <CommonLayoutComponent
                            path={routes.LEGAL_DOCUMENTS}
                            component={LegalDocuments}
                            permission={Permissions.AGREEMENT_VIEW}
                        />
                        <CommonLayoutComponent
                            exact
                            permission={Permissions.NEWS_VIEW}
                            path={routes.NEWS}
                            component={NewsList}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_NEWS}
                            component={CreateNews}
                            permission={Permissions.NEWS_CREATE}
                        />
                        <CommonLayoutComponent
                            path={routes.ONE_NEWS}
                            component={CreateNews}
                            permission={Permissions.NEWS_VIEW}
                        />
                        <CommonLayoutComponent
                            path={routes.NEW_BIKE_OR_VAE}
                            permission={Permissions.VEHICLE_CREATE}
                            component={CreateScooterComponent}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.VEHICLE_VIEW}
                            path={routes.BIKE}
                            component={BikeAndVaeDetails}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.VEHICLE_VIEW}
                            path={routes.VAE}
                            component={BikeAndVaeDetails}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.VEHICLE_VIEW}
                            path={routes.BIKES_AND_VAE}
                            component={BikesAndVAEList}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.PARTNER_CREATE}
                            path={routes.NEW_PARTNER}
                            component={CreatePartner}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.PARTNER_VIEW}
                            path={routes.PARTNER}
                            component={CreatePartner}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.PARTNER_VIEW}
                            path={routes.PARTNERS}
                            component={PartnersList}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.VEHICLE_CREATE}
                            path={routes.NEW_LOCK}
                            component={CreateLock}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.VEHICLE_UPDATE}
                            path={routes.LOCK}
                            component={UpdateLockContainer}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.VEHICLE_VIEW}
                            path={routes.LOCKS}
                            component={LocksList}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.SEND_PUSH}
                            path={routes.PUSH}
                            component={PushNotification}
                        />
                        <CommonLayoutComponent
                            path={routes.ADMIN_LOGGER}
                            component={AdminLogger}
                            isAccessAllowed={isSuperAdmin}
                        />
                        <CommonLayoutComponent
                            path={routes.REPORT_DASHBOARD}
                            component={ReportDashboard}
                            isAccessAllowed={isManager}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.SWAGGER_ADMIN}
                            path={routes.SWAGGER_ADMIN}
                            component={Swagger}
                        />
                        <CommonLayoutComponent
                            permission={Permissions.SWAGGER_PARTNER}
                            path={routes.SWAGGER}
                            component={Swagger}
                        />
                        <Redirect exact from="/" to={routes.MAIN_PAGE} />
                    </Switch>
                </ConnectedRouter>
            )}
        </ThemeProvider>
    );
};
