import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
    ButtonComponent,
    LinkComponent,
    TextField,
    ThemedBoxComponent,
} from 'components/common';
import {
    required,
    isValidEmail,
    composeValidators,
} from 'tools/validationRules';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetError, restorePassword } from 'ducks/auth/authSlice';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { FormGrid } from '../../common/FormGrid';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { selectError, selectIsLoading } from '../../../ducks/auth';
import { LoaderComponent } from '../../common/Loader';
import { ErrorComponent } from '../../common/Error';
import background from 'resources/icons/connexion.png';
import poweredby from 'resources/icons/poweredbyindigo.png';
import { useCaptcha } from '../../../hooks/useCaptcha';

const useStyles = makeStyles(() =>
    createStyles({
        hidden: {
            visibility: 'hidden',
        },
        pageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundSize: 'cover',
            backgroundImage: `url(
                ${background})`,
        },
    }),
);
export const ResetPasswordForm: React.FC = () => {
    const classes = useStyles();
    const { captchaPassed, Captcha, handleAttemptFail } = useCaptcha();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleConfirm = React.useCallback(
        ({ email }: { email: string }) => {
            dispatch(restorePassword({ email }));
        },
        [dispatch],
    );
    const redirectToLogin = React.useCallback(
        () => dispatch(push(routes.LOGIN)),
        [dispatch],
    );
    React.useEffect(
        () => () => {
            dispatch(resetError());
        },
        [dispatch],
    );
    const loading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    useEffect(() => {
        if (captchaPassed && error && !loading) {
            handleAttemptFail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, loading, dispatch, handleAttemptFail]);

    return (
        <Box className={classes.pageContainer}>
            <Box position="relative" display="flex" justifyContent="center">
                {loading && <LoaderComponent />}
                {!captchaPassed ? (
                    Captcha
                ) : (
                    <Form
                        onSubmit={values => {
                            dispatch(resetError());
                            handleConfirm({
                                email: values.email,
                            });
                        }}
                        render={({ handleSubmit, pristine, valid }) => (
                            <form onSubmit={handleSubmit}>
                                <ThemedBoxComponent width={400}>
                                    <Box textAlign="center">
                                        <Typography variant="h1">
                                            <p>{t('restorePassword')}</p>
                                        </Typography>
                                    </Box>
                                    <FormGrid columnAmount={1}>
                                        <TextField
                                            name="email"
                                            validators={composeValidators(
                                                required,
                                                isValidEmail,
                                            )}
                                            label={t('fields.email')}
                                            isRequired
                                        />
                                    </FormGrid>
                                    <Box textAlign="center">
                                        <ButtonComponent
                                            handleClick={handleSubmit}
                                            text={t('confirm')}
                                            isDisabled={
                                                pristine || !valid || loading
                                            }
                                        />
                                    </Box>
                                    <Box mt="10px">
                                        <ErrorComponent error={error} />
                                    </Box>
                                    <Box mt="10px">
                                        <LinkComponent
                                            text={t('login.title')}
                                            handleClick={redirectToLogin}
                                        />
                                    </Box>
                                    <input
                                        type="submit"
                                        className={classes.hidden}
                                    />
                                </ThemedBoxComponent>
                            </form>
                        )}
                    />
                )}
                <Box bottom="-120px" position="absolute">
                    <img src={poweredby} alt="" width="150px" />
                </Box>
            </Box>
        </Box>
    );
};
