import { UserPaymentHistoryResponse, PaymentAccumulator } from './types';

export const mapFetchedPaymentHistory = ({
    content,
}: UserPaymentHistoryResponse): PaymentAccumulator => {
    return content.reduce<PaymentAccumulator>(
        (acc, payment) => {
            return {
                paymentsById: {
                    ...acc.paymentsById,
                    [payment.id]: {
                        ...payment,
                    },
                },
                paymentIds: [...acc.paymentIds, payment.id],
            };
        },
        { paymentIds: [], paymentsById: {} },
    );
};
