import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetError } from 'ducks/auth/authSlice';
import SwaggerUI from 'swagger-ui-react';
import { SWAGGER_URL, BASE_URL } from 'config';
import 'swagger-ui-react/swagger-ui.css';
import { useRouteMatch } from 'react-router-dom';
import { routes } from 'constants/common';

export const Swagger: React.FC = () => {
    const match = useRouteMatch(routes.SWAGGER_ADMIN);
    const isAdminRoute = !!match?.isExact;
    const dispatch = useDispatch();
    useEffect(
        () => () => {
            dispatch(resetError());
        },
        [dispatch],
    );

    const swaggerUrl = useMemo(() => {
        return (
            (SWAGGER_URL || BASE_URL) +
            'v2/api-docs?group=' +
            (isAdminRoute ? 'ADMIN' : 'PARTNER')
        );
    }, [isAdminRoute]);

    const requestInterceptor = useCallback((req: any) => {
        if (req.loadSpec) {
            const jwt = localStorage.getItem('key');
            // Add the basic auth header to spec fetches
            req.headers.Authorization = `Bearer ${jwt}`;
        }
        return req;
    }, []);

    return (
        <SwaggerUI
            key={swaggerUrl}
            url={swaggerUrl}
            requestInterceptor={requestInterceptor}
            defaultModelsExpandDepth={isAdminRoute ? undefined : -1}
        />
    );
};
