import React from 'react';
import { TextField, MenuItem, Checkbox, SelectProps } from '@material-ui/core';
import { StyledFormControlLabel } from '../FormControlLabel';

interface ISelectOption<T> {
    label: string;
    value: T;
    disabled?: boolean;
}
interface ISelectInterface<T> {
    id?: string;
    label?: string;
    value: T[] | T;
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
    options: ISelectOption<T>[];
    placeholder?: string;
    multiple?: boolean;
    disabled?: boolean;
    hasError?: boolean;
    valid?: boolean;
    helperText?: string;
}

export const SelectComponent = <T extends string | number>({
    label,
    value,
    onChange,
    options,
    multiple,
    disabled,
    hasError,
    helperText,
    id,
}: ISelectInterface<T>) => {
    const renderValue = React.useCallback(
        selected => {
            const selectedValues = selected as T[];
            return options
                .filter(({ value }) => selectedValues.includes(value))
                .reduce(
                    (acc, { label }) => (acc ? `${acc}, ${label}` : label),
                    '',
                );
        },
        [options],
    );

    const SelectionProps: SelectProps = multiple
        ? {
              multiple: true,
              renderValue,
          }
        : {
              multiple: false,
          };

    return (
        <StyledFormControlLabel
            labelPlacement="top"
            control={
                <TextField
                    error={hasError}
                    fullWidth
                    value={value}
                    id={id || label}
                    select
                    onChange={onChange}
                    disabled={disabled}
                    margin="none"
                    size="small"
                    helperText={helperText}
                    variant="outlined"
                    SelectProps={SelectionProps}>
                    {options.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            disabled={option.disabled}>
                            {multiple && Array.isArray(value) && (
                                <Checkbox
                                    checked={value.indexOf(option.value) > -1}
                                />
                            )}
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            }
            label={label}
        />
    );
};
