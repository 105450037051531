import { Button, Box } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface IButtonInterface {
    text: string;
    handleClick?: () => void;
    outlined?: boolean;
    isDisabled?: boolean;
    isPrimary?: boolean;
    startIcon?: ReactElement;
}

export const ButtonComponent: React.FC<IButtonInterface> = ({
    handleClick,
    text,
    outlined,
    isDisabled,
    isPrimary,
    startIcon,
}: IButtonInterface) => (
    <Button
        variant={outlined ? 'outlined' : 'contained'}
        disabled={isDisabled}
        color={isPrimary ? 'primary' : 'secondary'}
        onClick={handleClick}
        startIcon={startIcon}>
        <Box
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            maxWidth="fit-content">
            {text}
        </Box>
    </Button>
);
