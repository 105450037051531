import React, { useCallback, useContext } from 'react';
import { PaginationComponent } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { selectPaymentHistoryPagination, setPage } from 'ducks/paymentHistory';
import { PaymentTableContext } from './PaymentTable';

const PaymentHistoryPagination = () => {
    const dispatch = useDispatch();

    const { userId, profileId } = useContext(PaymentTableContext);

    const paginationSelector = useCallback(
        state => selectPaymentHistoryPagination(state, userId, profileId),
        [profileId, userId],
    );

    const pagination = useSelector(paginationSelector);

    const handleSetPage = (page: number) => {
        dispatch(setPage({ page, userId, profileId }));
    };
    return (
        <PaginationComponent pagination={pagination} setPage={handleSetPage} />
    );
};

export default PaymentHistoryPagination;
