import { RootState } from '../root';
import { createSelector } from 'reselect';

export const selectPagination = (state: RootState) => state.partners.pagination;
export const selectSort = (state: RootState) => state.partners.sort;

export const selectPartnersIds = (state: RootState) =>
    state.partners.partnersIds;

export const selectPartnersById = (state: RootState) =>
    state.partners.partnersById;

export const selectPartnersError = (state: RootState) => state.partners.error;

export const selectPartnersTableData = createSelector(
    selectPartnersIds,
    selectPartnersById,
    (partnersIds, partnersById) =>
        partnersIds.map(id => ({ ...partnersById[id] })),
);

export const selectPartnerHasUnsavedChanges = (state: RootState) =>
    state.partners.partnerHasUnsavedChanges;

export const selectCurrentPartner = (state: RootState) =>
    state.partners.currentPartner;

export const selectIsLoading = (state: RootState) => state.partners.loading;
