import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    VehiclesState,
    StringFilterTypes,
    VehicleStatus,
    VehicleTypes,
} from './types';
import { commonSetSort, defaultPagination } from '../../common/pagination';
import { SortDirection } from '../../common/types';
import { isEqual } from 'lodash';

const defaultFilters = {};
export const initialState: VehiclesState = {
    loading: false,
    error: '',
    newStatus: null,
    selected: [],
    vehicleIds: [],
    vehiclesById: {},
    filters: defaultFilters,
    sort: {
        value: 'updated',
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
    vehicleHasUnsavedChanges: false,
    currentVehicle: null,
    fetchAll: false,
    activeVehicleTab: 0,
    currentVehicleState: null,
};

const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        fetchVehicles(state) {
            state.loading = true;
        },
        fetchVehiclesSuccess(state, action) {
            state.vehicleIds = action.payload.vehicleIds;
            state.vehiclesById = action.payload.vehiclesById;
            state.pagination = action.payload.pagination || {};
            state.loading = false;
        },
        fetchVehiclesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setSelectedVehicles(state, action) {
            state.selected = action.payload;
        },
        addToSelectedVehicles(state, action) {
            const index = state.selected.indexOf(action.payload);
            if (index > -1) {
                state.selected.splice(index, 1);
            } else {
                state.selected = [...state.selected, action.payload];
            }
        },
        setStringFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: StringFilterTypes;
                value: string | VehicleStatus;
            }>,
        ) {
            state.newStatus = null;
            state.selected = [];
            state.loading = true;
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.pagination = defaultPagination;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.selected = [];
            state.loading = true;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.selected = [];
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setNewStatus(state, { payload }) {
            state.newStatus = payload;
        },
        updateVehiclesStatus(state) {
            state.loading = true;
            state.error = '';
        },
        updateVehiclesStatusSuccess(state) {
            state.loading = false;
            state.selected = [];
            state.newStatus = null;
            state.filters = defaultFilters;
            state.error = '';
        },
        updateVehiclesStatusError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateVehicle: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.vehicleHasUnsavedChanges = true;
            },
            prepare: payload => ({
                payload,
            }),
        },
        updateVehicleSuccess(state, action) {
            state.loading = false;
            state.currentVehicle = action.payload;
            state.error = '';
        },
        updateVehicleError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        exportVehicles(state) {
            state.loading = true;
            state.error = '';
        },
        exportVehiclesSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        exportVehiclesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        createVehicle: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.vehicleHasUnsavedChanges = true;
            },
            prepare: service => ({
                payload: service,
            }),
        },
        createVehicleSuccess(state) {
            state.loading = false;
            state.vehicleHasUnsavedChanges = false;
        },
        createVehicleError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setVehicleHasUnsavedChanges(state, action) {
            state.vehicleHasUnsavedChanges = action.payload;
        },
        importVehicles: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.vehicleHasUnsavedChanges = true;
            },
            prepare: ({ file }) => ({
                payload: { file },
            }),
        },
        importVehiclesSuccess(state) {
            state.loading = false;
            state.vehicleHasUnsavedChanges = false;
        },
        importVehiclesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchVehicle: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: id => ({
                payload: id,
            }),
        },
        fetchVehicleSuccess(state, action) {
            state.loading = false;
            state.currentVehicle = action.payload;
            state.error = '';
        },
        fetchVehicleError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
            state.fetchAll = false;
            state.selected = [];
            state.error = '';
        },
        setCurrentVehicleType: {
            reducer: (
                state,
                action: PayloadAction<{
                    isRefreshCheck?: boolean;
                    types: VehicleTypes[];
                }>,
            ) => {
                const { isRefreshCheck = true } = action.payload;
                if (
                    isRefreshCheck &&
                    !isEqual(
                        state.filters[StringFilterTypes.Types],
                        action.payload.types,
                    )
                ) {
                    state.filters = defaultFilters;
                    state.pagination = defaultPagination;
                    state.fetchAll = false;
                    state.selected = [];
                    state.error = '';
                }
                state.filters = {
                    ...state.filters,
                    [StringFilterTypes.Types]: action.payload.types,
                };
                state.selected = [];
                state.loading = true;
            },
            prepare: ({ isRefreshCheck, types }) => {
                return {
                    payload: { isRefreshCheck, types },
                };
            },
        },
        setFetchAllVehicles(state, action) {
            state.fetchAll = action.payload;
        },
        setActiveVehicleTab(state, action) {
            state.activeVehicleTab = action.payload;
        },
        sendCommand: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ vehicleId, command }) => ({
                payload: { vehicleId, command },
            }),
        },
        sendCommandSuccess(state, action) {
            state.loading = false;
            state.currentVehicleState = action.payload;
            state.error = '';
        },
        sendCommandError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        getVehicleState: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ vehicleId }) => ({
                payload: { vehicleId },
            }),
        },
        getVehicleStateSuccess(state, action) {
            state.loading = false;
            state.currentVehicleState = action.payload;
            if (state.currentVehicle) {
                state.currentVehicle.stationId =
                    action.payload.station?.id || '';
                state.currentVehicle.stationName =
                    action.payload.station?.name || '';
            }
            state.error = '';
        },
        getVehicleStateError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetError(state) {
            state.error = '';
        },
    },
});
export const {
    setCurrentVehicleType,
    setSelectedVehicles,
    fetchVehicles,
    fetchVehiclesError,
    fetchVehiclesSuccess,
    setStringFilter,
    setPage,
    setSort,
    setNewStatus,
    updateVehicle,
    updateVehicleSuccess,
    updateVehicleError,
    fetchVehicle,
    fetchVehicleError,
    fetchVehicleSuccess,
    updateVehiclesStatus,
    updateVehiclesStatusError,
    updateVehiclesStatusSuccess,
    exportVehicles,
    exportVehiclesError,
    exportVehiclesSuccess,
    createVehicle,
    createVehicleSuccess,
    createVehicleError,
    importVehicles,
    importVehiclesError,
    importVehiclesSuccess,
    setVehicleHasUnsavedChanges,
    resetFilters,
    addToSelectedVehicles,
    setFetchAllVehicles,
    setActiveVehicleTab,
    sendCommand,
    sendCommandError,
    sendCommandSuccess,
    getVehicleState,
    getVehicleStateError,
    getVehicleStateSuccess,
    resetError,
} = vehicleSlice.actions;

export const vehicleReducer = vehicleSlice.reducer;
