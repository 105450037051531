import i18next from 'i18next';
import { Invitation, InvitationBody } from '../../../ducks/companies/types';

export type ErrorIntervalFields = {
    endTime?: string;
    startTime?: string;
    lldVehicleId?: string;
};

type Error = {
    invitations: ErrorIntervalFields[];
};

export const checkIfStartTimeIsLaterThanEnd = (values: Invitation) => {
    const errors: Error = { invitations: [] };
    values.invitations.map((value: InvitationBody, index: number) => {
        if (
            value.startTime &&
            value.endTime &&
            value.startTime >= value.endTime
        ) {
            errors.invitations[index] = {
                endTime: i18next.t('errors.endTime'),
                startTime: '',
            };
        }
        return errors;
    });
    return errors;
};

export const checkIfSchedulePeriodsOverlap = (values: Invitation) => {
    const errors: Error = { invitations: [] };

    values.invitations.forEach((invitation: InvitationBody, i: any) => {
        for (let j = i + 1; j < values.invitations.length; j++) {
            if (
                invitation.lldVehicleId &&
                invitation.lldVehicleId === values.invitations[j].lldVehicleId
            ) {
                errors.invitations[i] = {
                    lldVehicleId: i18next.t('errors.takenVehicle'),
                };
                errors.invitations[j] = {
                    lldVehicleId: i18next.t('errors.takenVehicle'),
                };
            }
        }
    });

    return errors;
};
