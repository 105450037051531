import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid } from '../../common';
import {
    fetchCompany,
    setCurrentCompanyId,
    resetCurrentCompany,
} from 'ducks/companies';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';
import { IFrame } from 'components/common/IFrame';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { selectMyCompanyId } from 'ducks/auth/selectors';
import { selectIsLoading } from 'ducks/companies/selectors';
import { selectCurrentCompanyReportDashboard } from '../../../ducks/companies/selectors';

export const useStyles = makeStyles(() =>
    createStyles({
        iframe: {
            width: '100%',
            height: 'calc(100vh - 150px)',
        },
    }),
);

export const ReportDashboard: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles();
    const companyId = useSelector(selectMyCompanyId);
    const loading = useSelector(selectIsLoading);

    const reportingDashboardLink = useSelector(
        selectCurrentCompanyReportDashboard,
    );

    useEffect(() => {
        companyId && dispatch(setCurrentCompanyId({ id: companyId }));
        companyId && dispatch(fetchCompany());
        return () => {
            dispatch(resetCurrentCompany());
        };
    }, [dispatch, companyId]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <FormGrid columnAmount={1} padding="0" rowHeight="40px">
                <Typography variant="h1">
                    {t('reportingDashboard.title')}
                </Typography>
                <IFrame
                    className={classes.iframe}
                    elementString={reportingDashboardLink}
                />
            </FormGrid>
        </>
    );
};
