import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { ButtonComponent } from '../index';
import { Button } from '@material-ui/core';
import { FormButtonGroup } from '../ButtonGroup';

interface Import {
    setSelectedFile: (file: File | undefined | null) => void;
    selectedFile?: File | undefined | null;
    handleImport: () => void;
    acceptedFiles?: string;
}

export const ImportComponent: React.FC<Import> = ({
    setSelectedFile,
    selectedFile,
    handleImport,
    acceptedFiles = '.csv',
}: Import) => {
    const { t } = useTranslation();

    const fileInputRef = useRef(null);

    return (
        <FormButtonGroup>
            <Box>
                <Button variant="contained" component="label" color="primary">
                    <Box
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxWidth="fit-content">
                        {t('selectFile')}
                    </Box>
                    <input
                        style={{ display: 'none' }}
                        accept={acceptedFiles}
                        type="file"
                        value={undefined}
                        ref={fileInputRef}
                        onChange={event => {
                            setSelectedFile(
                                event.target.files && event.target.files[0],
                            );
                        }}
                        onClick={event => {
                            const target = event.target as HTMLTextAreaElement;
                            target.value = '';
                        }}
                    />
                </Button>
            </Box>
            <Box position="relative" mb="10px">
                <ButtonComponent
                    text={t('importFile')}
                    handleClick={handleImport}
                    isDisabled={!selectedFile}
                />
                {selectedFile && (
                    <Box
                        position="absolute"
                        overflow="hidden"
                        width="100%"
                        fontSize="12px"
                        title={selectedFile.name}
                        textOverflow="ellipsis">
                        {selectedFile.name}
                    </Box>
                )}
            </Box>
        </FormButtonGroup>
    );
};
