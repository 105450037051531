import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    icon: {
        color: 'grey',
        fontSize: 16,
    },
}));

interface HelpComponentProps {
    tooltipText: string;
}

export const HelpComponent: React.FC<HelpComponentProps> = ({
    tooltipText,
}: HelpComponentProps) => {
    const classes = useStyles();
    return (
        <Tooltip title={tooltipText}>
            <HelpOutlineIcon fontSize="small" className={classes.icon} />
        </Tooltip>
    );
};
