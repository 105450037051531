import React, { SyntheticEvent, useCallback } from 'react';
import { TableComponent } from '../../../common';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { RootState } from 'ducks/root';
import { InvitationPagination } from './pagination';
import { InvitationBody } from 'ducks/companies/types';
import { Typography } from '@material-ui/core';
import {
    deleteInvitation,
    fetchInvitations,
    resendInvitation,
    selectIsLoading,
} from 'ducks/companies';
import { FullScreenLoaderComponent } from '../../../common/Loader/FullScreenLoader';
import { Action } from '../../../common/Table';
import { Permissions } from 'ducks/groups/types';
import DeleteIcon from '@material-ui/icons/Delete';
import { selectAccessToPages } from 'ducks/auth';
import { Modals, openModal } from 'ducks/ui';
import { useParams } from 'react-router';

export const CompanyInvitations = () => {
    const { companyId } = useParams<{ companyId?: string }>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const columns = getColumns(t);
    React.useEffect(() => {
        companyId && dispatch(fetchInvitations());
    }, [dispatch, companyId]);
    const { invitationIds, invitationsById } = useSelector(
        (state: RootState) => state.company,
    );
    const loading = useSelector(selectIsLoading);
    const handleResendInvitation = useCallback(
        (rowData: InvitationBody) => {
            dispatch(
                resendInvitation({
                    invitationId: rowData.id,
                    serviceId: rowData.serviceId,
                    vehicleId: rowData.lldVehicleId,
                }),
            );
        },
        [dispatch],
    );
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToDelete = accessToPages[Permissions.INVITATION_DELETE];
    const allowedToCreate = accessToPages[Permissions.INVITATION_CREATE];

    const handleDelete = React.useCallback(
        (id: number) => {
            dispatch(deleteInvitation(id));
        },
        [dispatch],
    );
    const handleClickDelete = React.useCallback(
        (id: number) => {
            dispatch(
                openModal({
                    modalType: Modals.CHANGE_CONFIRMATION,
                    modalProps: {
                        handleConfirm: () => handleDelete(id),
                        message: 'invitations.delete',
                    },
                }),
            );
        },
        [dispatch, handleDelete],
    );

    const actions = React.useMemo((): Action<InvitationBody>[] => {
        const actionsArray = [];
        if (allowedToCreate) {
            actionsArray.push({
                // eslint-disable-next-line react/display-name
                icon: () => (
                    <Typography color="primary">
                        {t('invitations.resend')}
                    </Typography>
                ),
                tooltip: t('invitations.resend'),
                onClick: (
                    event: SyntheticEvent,
                    rowData: InvitationBody | undefined,
                ) => rowData && handleResendInvitation(rowData),
            });
        }
        if (allowedToDelete) {
            actionsArray.push({
                // eslint-disable-next-line react/display-name
                icon: () => <DeleteIcon />,
                tooltip: t('delete'),
                onClick: (
                    event: SyntheticEvent<Element, Event>,
                    rowData: InvitationBody | undefined,
                ) => rowData && handleClickDelete(rowData.id),
            });
        }
        return actionsArray;
    }, [
        t,
        allowedToDelete,
        allowedToCreate,
        handleClickDelete,
        handleResendInvitation,
    ]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={actions}
                onRowClick={(event, rowData: InvitationBody | undefined) =>
                    rowData && handleResendInvitation(rowData)
                }
                columns={columns}
                data={invitationIds.map(id => ({ ...invitationsById[id] }))}
                title={t('invitations.title')}
                pagination={InvitationPagination}
            />
        </>
    );
};
