import React from 'react';
import { useTranslation } from 'react-i18next';
import { BikeAndVaeTelemetryEventsType } from 'ducks/vehicleTelemetryEvents/types';
import { Toolbar } from '../Common/Toolbar';

export const BikesAndVaeToolbar: React.FC = () => {
    const { t } = useTranslation();

    const messageCodeOptions = React.useMemo<
        { label: string; value: BikeAndVaeTelemetryEventsType | '' }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...Object.values(BikeAndVaeTelemetryEventsType).map(type => ({
                label: t(`bikeAndVaeTelemetryEvents.types.${type}`),
                value: type,
            })),
        ],
        [t],
    );

    return <Toolbar messageCodeOptions={messageCodeOptions} type="vae" />;
};
