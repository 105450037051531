import { TableComponent } from '../../common';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo } from 'react';
import { getColumns } from './columns';
import { Pagination } from './Pagination';
import {
    selectGroupsTableData,
    selectSort,
    selectIsLoading,
} from 'ducks/groups/selectors';
import { fetchGroups } from 'ducks/groups';
import { SearchToolbar } from './SearchToolbar';
import { Action } from '../../common/Table';
import { Group } from 'ducks/groups/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const GroupProfilesList: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tableData = useSelector(selectGroupsTableData);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);
    const columns = useMemo(() => getColumns(t, dispatch), [t, dispatch]);

    useEffect(() => {
        dispatch(fetchGroups());
    }, [dispatch]);

    const redirectToGroup = useCallback(
        (id: number) =>
            dispatch(push(routes.GROUP.replace(':groupId', `${id}`))),
        [dispatch],
    );

    const actions = React.useMemo(
        (): Action<Group>[] => [
            {
                icon: 'visibility',
                tooltip: t('tripList.seeDetails'),
                onClick: (event, rowData: Group | undefined) =>
                    rowData && redirectToGroup(rowData.id),
            },
        ],
        [redirectToGroup, t],
    );

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={actions}
                onRowClick={(event, rowData: Group | undefined) =>
                    rowData && redirectToGroup(rowData.id)
                }
                searchComponent={SearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={tableData}
                title={t('groups.title')}
                sort={sort}
            />
        </>
    );
};
