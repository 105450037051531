import {
    Accumulator,
    FetchedCurrentTrip,
    FetchedStatistics,
    Pause,
    StatsByStatus,
    TripResponse,
} from './types';
import { calculateDuration } from 'tools/calculateDuration';
import { VehicleStatus, VehicleTypes } from '../vehicles/common/types';

export const mapFetchedTrips = ({ content }: TripResponse): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, trip) => {
            const {
                startTime,
                endTime,
                startBatteryLevel,
                endBatteryLevel,
                endUser,
                startStation,
                endStation,
            } = trip;
            const slicedStartTime = startTime ? startTime.slice(0, 10) : '-';
            const slicedEndTime = endTime ? endTime.slice(0, 10) : '-';
            const time = `${slicedStartTime}/${slicedEndTime}`;
            const batteryLevels = `${startBatteryLevel || '-'}/${
                endBatteryLevel || '-'
            }`;
            const stationIds = `${startStation?.id || '-'}/${
                endStation?.id || '-'
            }`;
            const userName = `${endUser.firstName} ${endUser.lastName}`;

            return {
                tripsById: {
                    ...acc.tripsById,
                    [trip.id]: {
                        time,
                        stationIds,
                        batteryLevels,
                        userName,
                        ...trip,
                    },
                },
                tripIds: [...acc.tripIds, trip.id],
            };
        },
        { tripIds: [], tripsById: {} },
    );
};

export const mapFetchedTrip = (trip: FetchedCurrentTrip) => {
    const { pauses } = trip.trip;
    const preparedPauses = pauses.map((item: Pause) => {
        if (item.start && item.end) {
            const duration = calculateDuration(
                item.start,
                item.end,
            ).durationStringWithUnits;
            return { duration, ...item };
        }
        return { duration: '-', ...item };
    });

    return { ...trip, trip: { ...trip.trip, pauses: preparedPauses } };
};

export const mapStatisticsByStatus = (statistics: FetchedStatistics) => {
    const byStatus = Object.values(VehicleStatus).reduce<StatsByStatus[]>(
        (acc, status) => {
            const scooterAmount =
                statistics.vehicles[VehicleTypes.SCOOTER]?.[status];
            const bikeAmount = statistics.vehicles[VehicleTypes.BIKE]?.[status];
            const vaeAmount = statistics.vehicles[VehicleTypes.VAE]?.[status];
            if (scooterAmount || bikeAmount || vaeAmount) {
                return [
                    ...acc,
                    {
                        status,
                        [VehicleTypes.SCOOTER]: scooterAmount,
                        [VehicleTypes.VAE]: vaeAmount,
                        [VehicleTypes.BIKE]: bikeAmount,
                    },
                ];
            } else return acc;
        },
        [],
    );
    const rented = [];
    const scooterAmountToday = statistics.todayTrips[VehicleTypes.SCOOTER];
    const bikeAmountToday = statistics.todayTrips[VehicleTypes.BIKE];
    const vaeAmountToday = statistics.todayTrips[VehicleTypes.VAE];
    const scooterAmountYesterday =
        statistics.yesterdayTrips[VehicleTypes.SCOOTER];
    const bikeAmountYesterday = statistics.yesterdayTrips[VehicleTypes.BIKE];
    const vaeAmountYesterday = statistics.yesterdayTrips[VehicleTypes.VAE];

    if (bikeAmountToday || scooterAmountToday || vaeAmountToday) {
        rented.push({
            date: 'today',
            [VehicleTypes.SCOOTER]: scooterAmountToday,
            [VehicleTypes.BIKE]: bikeAmountToday,
            [VehicleTypes.VAE]: vaeAmountToday,
        });
    }
    if (bikeAmountYesterday || scooterAmountYesterday || vaeAmountYesterday) {
        rented.push({
            date: 'yesterday',
            [VehicleTypes.SCOOTER]: scooterAmountYesterday,
            [VehicleTypes.BIKE]: bikeAmountYesterday,
            [VehicleTypes.VAE]: vaeAmountYesterday,
        });
    }

    return {
        rented,
        byStatus,
    };
};
