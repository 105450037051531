import React from 'react';
import { useDispatch } from 'react-redux';
import {
    setVehicleHasUnsavedChanges,
    importVehicles,
} from 'ducks/vehicles/common';
import { ImportComponent } from 'components/common/ImportComponent';

export const ImportScootersComponent: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = React.useState<
        File | undefined | null
    >();

    React.useEffect(() => {
        selectedFile && dispatch(setVehicleHasUnsavedChanges(true));
    }, [dispatch, selectedFile]);

    const handleImport = React.useCallback(() => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile, selectedFile.name);
            dispatch(importVehicles({ file: formData }));
        }
    }, [dispatch, selectedFile]);

    return (
        <ImportComponent
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            handleImport={handleImport}
        />
    );
};
