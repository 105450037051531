import React from 'react';

export const useFormMutators = () => {
    const resetBooleanField = React.useCallback(
        (args, state, { changeValue }) => {
            changeValue(state, args[0].field, () => false);
        },
        [],
    );

    const resetField = React.useCallback((args, state, { changeValue }) => {
        changeValue(state, args[0].field, () => null);
    }, []);

    const setField = React.useCallback((args, state, { changeValue }) => {
        changeValue(state, args[0].field, () => args[0].value);
    }, []);

    const mutators = React.useMemo(
        () => ({ resetBooleanField, resetField, setField }),
        [resetBooleanField, resetField, setField],
    );

    return mutators;
};
