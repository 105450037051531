import React, { RefObject } from 'react';

export const useOutsideClicker = (
    ref: RefObject<HTMLDivElement>,
    action: () => void,
    condition: boolean,
) => {
    React.useEffect(() => {
        function handleClickOutside({ target }: MouseEvent) {
            if (
                condition &&
                ref.current &&
                !ref.current.contains(target as HTMLDivElement)
            ) {
                action();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, action, condition]);
};
