import React, { useCallback, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FetchedTelemetryEvent } from 'ducks/vehicleTelemetryEvents/types';
import { LinkComponent } from 'components/common';
import { useDispatch } from 'react-redux';
import { DetailPanel } from '../../../common/Table/DetailPanel';
import { Modals, openModal } from 'ducks/ui';
interface Interface {
    rowProps: FetchedTelemetryEvent;
}

const useStyles = makeStyles(() =>
    createStyles({
        column: {
            wordBreak: 'break-word',
            maxWidth: '45%',
        },
    }),
);

export const DetailsPanel: React.FC<Interface> = ({ rowProps }: Interface) => {
    const dispatch = useDispatch();
    const { column } = useStyles();

    const dispatchOpenModal = useCallback(
        (lat, lng) => {
            dispatch(
                openModal({
                    modalType: Modals.MAP_WITH_POINT,
                    modalProps: {
                        position: {
                            lat,
                            lng,
                        },
                    },
                }),
            );
        },
        [dispatch],
    );

    const fields = useMemo(
        () =>
            Object.entries(rowProps.fields).map(([key, value], index) => {
                return (
                    <Box
                        key={key + index}
                        display="flex"
                        padding="15px"
                        justifyContent="space-between">
                        <div className={column}>{key}</div>
                        <div className={column}>
                            {(key === 'location' && Array.isArray(value) && (
                                <LinkComponent
                                    text={`${value[0] || '-'}, ${
                                        value[1] || '-'
                                    }`}
                                    handleClick={event => {
                                        event.stopPropagation();
                                        dispatchOpenModal(value[0], value[1]);
                                    }}
                                />
                            )) ||
                                ((typeof value === 'string' ||
                                    typeof value === 'number') &&
                                    value) ||
                                (typeof value === 'boolean' && String(value)) ||
                                (Array.isArray(value) && value.join(', '))}
                        </div>
                    </Box>
                );
            }),
        [rowProps.fields, column, dispatchOpenModal],
    );
    return <DetailPanel>{fields}</DetailPanel>;
};
