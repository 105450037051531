import { Box, capitalize, Typography } from '@material-ui/core';
import React from 'react';
interface Props {
    error?: string;
}
export const ErrorComponent: React.FC<Props> = React.memo(
    ({ error }: Props) => (
        <Box color="error.main">
            <Typography>{error && capitalize(error)}</Typography>
        </Box>
    ),
);

ErrorComponent.displayName = 'ErrorComponent';
