import { colors } from './colors';

interface Palette {
    primary: PaletteColor;
    secondary?: PaletteColor;
}

interface PaletteColor {
    main: string;
    contrastText?: string;
}

type Theme = { palette: Partial<Palette>; overrides: any };

const defaultPalette = {
    primary: {
        main: colors.default,
    },
    secondary: {
        main: colors.default,
    },
};

const defaultStyle = {
    palette: defaultPalette,
    typography: {
        body1: {
            fontSize: '14px',
        },
        h1: {
            fontSize: '32px',
            fontWeight: 'bold',
            fontFamily: 'Quicksand',
        },
        h2: {
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'Quicksand',
        },
        h3: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: 'Quicksand',
        },
        button: {
            textTransform: 'none',
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                '& fieldset': {
                    borderWidth: '2px',
                },
            },
        },
        MuiTable: {
            root: {
                fontSize: '14px',
            },
        },
        MuiTableCell: {
            footer: {
                borderBottom: 'none',
            },
            root: {
                '&:not(:first-of-type)': {
                    padding: '16px 8px',
                    textAlign: 'center',
                },
            },
            head: {
                fontWeight: 'bold',
                fontFamily: 'Quicksand',
            },
        },
        MuiTableRow: {
            hover: {
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.15) !important',
                },
            },
        },
        MuiTableFooter: {
            root: {
                justifyContent: 'center',
            },
        },
        MuiList: {
            root: {
                fontSize: '14px',
            },
        },
        MuiFormHelperText: {
            contained: {
                margin: '0 !important',
            },
        },
        MuiFormControlLabel: {
            root: {
                width: 'fit-content',
            },
        },
    },
};

export const getTheme = (color: string): Theme => ({
    ...defaultStyle,
    palette: {
        ...defaultPalette,
        primary: {
            main: color,
            contrastText: colors.white,
        },
        secondary: { main: color, contrastText: colors.white },
    },
    overrides: {
        ...defaultStyle.overrides,
        MuiTableSortLabel: {
            icon: {
                color: `${color} !important`,
                fontSize: '23px',
            },
        },
    },
});
