import React from 'react';
import { getColumns } from './columns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchZones, selectIsLoading, selectSort } from 'ducks/zones';
import { RootState } from '../../../ducks/root';
import { ZonePagination } from './Pagination';
import { TableComponent } from '../../common/Table';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { ZoneSearchToolbar } from './ZoneSearchToolbar';
import { ZoneToolbarComponent } from './Toolbar';
import { Zone } from '../../../ducks/zones/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const ZoneList = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const columns = getColumns(t, dispatch);

    const { zonesById, zoneIds } = useSelector(
        (state: RootState) => state.zone,
    );
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    React.useEffect(() => {
        dispatch(fetchZones());
    }, [dispatch]);

    const redirectToZone = (id: number) =>
        dispatch(push(routes.ZONE.replace(':zoneId', `${id}`)));

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'edit',
                        tooltip: t('serviceList.edit'),
                        onClick: (event, rowData: Zone) =>
                            rowData && redirectToZone(rowData.id),
                    },
                ]}
                onRowClick={(event, rowData: Zone | undefined) =>
                    rowData && redirectToZone(rowData.id)
                }
                searchComponent={ZoneSearchToolbar}
                toolbar={ZoneToolbarComponent}
                columns={columns}
                pagination={ZonePagination}
                data={zoneIds.map(id => ({
                    ...zonesById[id],
                }))}
                title={t('zones.tableTitle')}
                sort={sort}
            />
        </>
    );
};
