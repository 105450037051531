import { Pagination, Sort } from '../common/types';
import { Company } from '../companies/types';
import { Group } from '../groups/types';
import { Permissions } from '../groups/types';

export enum Languages {
    EN = 'EN',
    FR = 'FR',
    NL = 'NL',
}

export type NewUser = {
    email: string;
    language: Languages;
    companies: string[];
    groups: string[];
    isManager: boolean;
    isSuperAdmin: boolean;
};

export interface EditUser extends BoUser {
    companies: string[] | string;
    groups: string;
    leaveDate?: string;
}

export type BoUser = {
    id: number;
    isEnabled: boolean;
    email: string;
    isManager: boolean;
    language: Languages;
    isSuperAdmin: boolean;
    forcePasswordChange: boolean;
};

export type FetchedBoUser = {
    user: BoUser;
    companies: Company[];
    groups: Group[];
    permissions: Permissions[];
};

export type CurrentBoUser = {
    user: EditUser;
    companies: Company[];
    groups: Group[];
};

export type BoUsersById = {
    [code: number]: FetchedBoUser;
};

export enum FilterTypes {
    CustomerStatus = 'customerStatus',
    Status = 'status',
    GroupId = 'groupId',
    CompanyId = 'companyId',
    SearchText = 'searchText',
}

export type Filters = {
    [FilterTypes.CustomerStatus]: string;
    [FilterTypes.Status]: string;
    [FilterTypes.GroupId]: string;
    [FilterTypes.CompanyId]: string;
    [FilterTypes.SearchText]: string;
};

export type BoUserState = {
    loading: boolean;
    error: string;
    boUserIds: number[];
    boUsersById: BoUsersById;
    currentUser: CurrentBoUser;
    userHasUnsavedChanges: boolean;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
};

export type BoUserResponse = {
    content: FetchedBoUser[];
} & Pagination;

export type Accumulator = {
    boUserIds: number[];
    boUsersById: BoUsersById;
};
