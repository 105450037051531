import { TFunction } from 'i18next';

export const getColumns = (t: TFunction) => {
    return [
        {
            title: t('serviceList.serviceName'),
            field: 'name',
            sorting: false,
        },
        {
            title: t('serviceList.recipientType'),
            field: 'recipientType',
            sorting: false,
        },
        {
            title: t('serviceList.type'),
            field: 'type',
            render: (rowProps: { type: string }) =>
                t(`serviceList.${rowProps.type}`),
            sorting: false,
        },
    ];
};
