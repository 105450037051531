import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';
import { StringFilterTypes } from './types';
const defaultFilters = {
    name: '',
};
export const initialState = {
    loading: false,
    error: '',
    companyHasUnsavedChanges: false,
    companyIds: [],
    invitationIds: [],
    invitationsById: {},
    companiesById: {},
    currentCompanyId: 0,
    currentCompany: {
        domainLists: {
            acceptedDomains: [],
            rejectedDomains: [],
        },
        invitationTemplates: [],
        welcomeTemplates: [],
    },
    companyServices: [],
    companyManagers: [],
    freeVehicles: {},
    invitationPagination: defaultPagination,
    invitationHasUnsavedChanges: false,
    activeCompanyTab: 0,
    filters: defaultFilters,
    sort: {
        value: 'name',
        direction: SortDirection.Asc,
    },
    pagination: defaultPagination,
    allCompaniesById: {},
    allCompanyIds: [],
    emailRecipients: {},
};

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        fetchCompanies(state) {
            state.loading = true;
            state.error = '';
        },
        fetchCompaniesSuccess(state, action) {
            state.companyIds = action.payload.companyIds;
            state.companiesById = action.payload.companiesById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchCompaniesError(state) {
            state.loading = false;
        },
        fetchAvailableVehicles: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: serviceId => ({
                payload: serviceId,
            }),
        },
        fetchAvailableVehiclesSuccess(state, action) {
            state.freeVehicles = action.payload.byId;
            state.loading = false;
        },
        fetchAvailableVehiclesError(state) {
            state.loading = false;
        },
        fetchCompany(state) {
            state.loading = true;
            state.error = '';
        },
        fetchCompanySuccess(state, action) {
            state.currentCompany = action.payload.company;
            state.companyServices = action.payload.companyServices;
            state.companyManagers = action.payload.companyManagers;
            state.loading = false;
        },
        fetchCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        createCompany: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: company => ({
                payload: company,
            }),
        },
        createCompanySuccess(state, action) {
            state.currentCompany = action.payload.company;
            state.companyServices = action.payload.companyServices;
            state.companyHasUnsavedChanges = false;
            state.loading = false;
        },
        createCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        inviteUsers: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.invitationHasUnsavedChanges = true;
            },
            prepare: invitations => ({
                payload: invitations,
            }),
        },
        inviteUsersSuccess(state) {
            state.loading = false;
            state.invitationHasUnsavedChanges = false;
        },
        inviteUsersError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        importInviteUsers: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.invitationHasUnsavedChanges = true;
            },
            prepare: ({ file, serviceId }) => ({
                payload: { file, serviceId },
            }),
        },
        importInviteUsersSuccess(state) {
            state.loading = false;
            state.invitationHasUnsavedChanges = false;
        },
        importInviteUsersError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        assignServiceToCompany: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({
                companyId,
                serviceId,
                domainSubscriptionDuration,
                domainRecognition,
            }) => ({
                payload: {
                    companyId,
                    serviceId,
                    domainSubscriptionDuration,
                    domainRecognition,
                },
            }),
        },
        assignServiceToCompanySuccess(state) {
            state.loading = false;
        },
        assignServiceToCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        unlinkCompany: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ serviceId }) => ({
                payload: {
                    serviceId,
                },
            }),
        },
        unlinkCompanySuccess(state) {
            state.loading = false;
        },
        unlinkCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchInvitations(state) {
            state.loading = true;
            state.error = '';
        },
        fetchInvitationsSuccess(state, action) {
            state.invitationIds = action.payload.invitationIds;
            state.invitationsById = action.payload.invitationsById;
            state.invitationPagination = action.payload.pagination;
            state.loading = false;
        },
        fetchInvitationsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setInvitationPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.invitationPagination.number = payload.page;
            state.loading = true;
        },
        setCurrentCompanyId(state, { payload }: PayloadAction<{ id: number }>) {
            state.currentCompanyId = payload.id;
        },
        setInvitationHasUnsavedChanges(state, action) {
            state.invitationHasUnsavedChanges = action.payload;
        },
        setCompanyHasUnsavedChanges(state, action) {
            state.companyHasUnsavedChanges = action.payload;
        },
        setActiveCompanyTab(state, action) {
            state.activeCompanyTab = action.payload;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setStringFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: StringFilterTypes;
                value: string;
            }>,
        ) {
            state.filters[payload.filterType] = payload.value;
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        exportCompanies(state) {
            state.loading = true;
            state.error = '';
        },
        exportCompaniesSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        exportCompaniesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        deleteCompany(state) {
            state.loading = true;
            state.error = '';
        },
        deleteCompanySuccess(state) {
            state.loading = false;
            state.companyHasUnsavedChanges = false;
            state.error = '';
        },
        deleteCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateCompany: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: company => ({
                payload: company,
            }),
        },
        updateCompanySuccess(state) {
            state.companyHasUnsavedChanges = false;
            state.loading = false;
        },
        updateCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        anonymizeCompany(state) {
            state.loading = true;
            state.error = '';
        },
        anonymizeCompanySuccess(state) {
            state.loading = false;
            state.companyHasUnsavedChanges = false;
            state.error = '';
        },
        anonymizeCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        fetchAllCompanies(state) {
            state.loading = true;
            state.error = '';
        },
        fetchAllCompaniesSuccess(state, action) {
            state.allCompaniesById = action.payload.byId;
            state.allCompanyIds = action.payload.allIds;
            state.loading = false;
        },
        fetchAllCompaniesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetError(state) {
            state.error = '';
        },
        resetCurrentCompany(state) {
            state.currentCompany = {
                ...initialState.currentCompany,
            };
        },
        addEmailRecipient: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: recipients => ({
                payload: recipients,
            }),
        },
        addEmailRecipientSuccess(state) {
            state.loading = false;
        },
        addEmailRecipientError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        deleteEmailRecipient: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ email, type }) => ({
                payload: { email, type },
            }),
        },
        deleteEmailRecipientSuccess(state) {
            state.loading = false;
        },
        deleteEmailRecipientError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        deleteInvitation: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: id => ({
                payload: id,
            }),
        },
        deleteInvitationSuccess(state) {
            state.loading = false;
        },
        deleteInvitationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchEmailRecipients(state) {
            state.loading = true;
            state.error = '';
        },
        fetchEmailRecipientsSuccess(state, action) {
            state.emailRecipients = action.payload.emailRecipients;
            state.loading = false;
        },
        fetchEmailRecipientsError(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
        resendInvitation: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({
                invitationId,
                vehicleId,
                serviceId,
            }: {
                invitationId: number;
                vehicleId: number;
                serviceId: number;
            }) => ({
                payload: { invitationId, vehicleId, serviceId },
            }),
        },
        resendInvitationSuccess(state) {
            state.loading = false;
        },
        resendInvitationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
    },
});
export const {
    fetchCompanies,
    fetchCompaniesError,
    fetchCompaniesSuccess,
    inviteUsers,
    inviteUsersError,
    inviteUsersSuccess,
    fetchCompany,
    fetchCompanySuccess,
    fetchCompanyError,
    importInviteUsers,
    importInviteUsersError,
    importInviteUsersSuccess,
    assignServiceToCompany,
    assignServiceToCompanyError,
    assignServiceToCompanySuccess,
    fetchAvailableVehicles,
    fetchAvailableVehiclesError,
    fetchAvailableVehiclesSuccess,
    fetchInvitations,
    fetchInvitationsError,
    fetchInvitationsSuccess,
    setInvitationPage,
    setCurrentCompanyId,
    setInvitationHasUnsavedChanges,
    setCompanyHasUnsavedChanges,
    createCompany,
    createCompanyError,
    createCompanySuccess,
    setActiveCompanyTab,
    setPage,
    setSort,
    setStringFilter,
    exportCompanies,
    exportCompaniesSuccess,
    exportCompaniesError,
    deleteCompany,
    deleteCompanySuccess,
    deleteCompanyError,
    updateCompany,
    updateCompanySuccess,
    updateCompanyError,
    anonymizeCompany,
    anonymizeCompanySuccess,
    anonymizeCompanyError,
    resetFilters,
    fetchAllCompanies,
    fetchAllCompaniesError,
    fetchAllCompaniesSuccess,
    unlinkCompany,
    unlinkCompanyError,
    unlinkCompanySuccess,
    resetError,
    resetCurrentCompany,
    addEmailRecipient,
    addEmailRecipientSuccess,
    addEmailRecipientError,
    deleteEmailRecipient,
    deleteEmailRecipientSuccess,
    deleteEmailRecipientError,
    fetchEmailRecipients,
    fetchEmailRecipientsSuccess,
    fetchEmailRecipientsError,
    resendInvitation,
    resendInvitationSuccess,
    resendInvitationError,
    deleteInvitation,
    deleteInvitationError,
    deleteInvitationSuccess,
} = companySlice.actions;

export const companyReducer = companySlice.reducer;
