import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { drawerWidth } from '../../../constants/common';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        paper: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            opacity: '1',
            position: 'fixed',
            top: '150px',
            height: 'auto',
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
            border: '2px solid',
            borderLeft: 'none',
            borderColor: theme.palette.secondary.main,
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(4) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },
        toolbar: {
            display: 'flex',
        },
        list: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
        div: {
            position: 'relative',
            height: '100%',
        },
        absoluteDiv: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        verticalButton: {
            transform: 'translateX(-50%) translateY(-50%) rotate(-90deg) ',
            position: 'absolute',
            top: '50%',
            left: '50%',
            color: 'white',
        },
        hidden: {
            opacity: 0,
        },
    }),
);
