import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    fetchServicesSuccess,
    fetchServicesError,
    fetchServices,
    setFilter,
    setStringFilter,
    setSort,
    setPage,
    createServiceSuccess,
    createService,
    createServiceError,
    fetchServiceSuccess,
    fetchServiceError,
    fetchService,
    updateService,
    updateServiceSuccess,
    updateServiceError,
    toggleServiceError,
    toggleServiceSuccess,
    toggleServiceEnable,
    fetchServiceSchedule,
    fetchServiceScheduleSuccess,
    fetchServiceScheduleError,
    updateServiceSchedule,
    updateServiceScheduleSuccess,
    updateServiceScheduleError,
    fetchNotLinkedServicesError,
    fetchNotLinkedServicesSuccess,
    fetchNotLinkedServices,
    fetchServicesByCompanySuccess,
    fetchServicesByCompanyError,
    fetchServicesByCompany,
    fetchAllServices,
    fetchAllServicesSuccess,
    fetchAllServicesError,
} from './serviceSlice';
import { mapFetchedServices, mapSorting } from './mapper';
import { mapFetchedPagination, mapPagination } from '../common/pagination';
import { mapFilters } from '../common/mappers';
import {
    getAllServices,
    getNotLinkedServices,
    getService,
    getServices,
    getServicesByCompany,
    getServiceSchedule,
    postServiceSchedule,
    putService,
    toggleService,
} from './service';
import {
    FetchedService,
    Filters,
    ScheduleBody,
    SelectedService,
    ServiceDetails,
    ServiceResponse,
    UsageTypes,
} from './types';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { postService } from './service';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { mapById } from '../common/mappers';
import { Pagination, Sort } from '../common/types';

function* fetchServicesSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: ServiceResponse = yield call(getServices, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { serviceIds, servicesById } = mapFetchedServices(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchServicesSuccess({
                serviceIds,
                servicesById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchServicesError({ error: (error as Error)?.message }));
    }
}
function* fetchAllServicesSaga({
    payload,
}: PayloadAction<{ onlyEnabled: boolean; type: UsageTypes }>) {
    try {
        const data: FetchedService[] = yield call(getAllServices, payload);
        const { allIds, byId } = mapById(data);
        yield put(
            fetchAllServicesSuccess({
                allIds,
                byId,
            }),
        );
    } catch (error) {
        yield put(fetchAllServicesError({ error: (error as Error)?.message }));
    }
}

function* createServiceSaga({ payload }: PayloadAction<SelectedService>) {
    try {
        yield call(postService, payload);
        yield put(createServiceSuccess());
        yield put(push(routes.SERVICES));
    } catch (error) {
        yield put(createServiceError({ error: (error as Error)?.message }));
    }
}
function* toggleServiceSaga({
    payload,
}: PayloadAction<{ id: number; enable: boolean }>) {
    try {
        yield call(toggleService, payload.id, payload.enable);
        yield put(toggleServiceSuccess());
        yield put(fetchService(payload.id));
    } catch (error) {
        yield put(toggleServiceError({ error: (error as Error)?.message }));
    }
}
function* getServiceSaga({ payload }: PayloadAction<number>) {
    try {
        const data: ServiceDetails = yield call(getService, payload);
        yield put(
            fetchServiceSuccess({
                ...data.service,
                timezone:
                    data.service.timezone === 'Z'
                        ? '+00:00'
                        : data.service.timezone,
                zones: data.zones.map((zone: any) => zone.id),
            }),
        );
    } catch (error) {
        yield put(fetchServiceError({ error: (error as Error)?.message }));
    }
}
function* getNotLinkedServicesSaga() {
    try {
        const data: { id: number }[] = yield call(getNotLinkedServices);
        const { allIds, byId } = mapById(data);
        yield put(fetchNotLinkedServicesSuccess({ allIds, byId }));
    } catch (error) {
        yield put(
            fetchNotLinkedServicesError({ error: (error as Error)?.message }),
        );
    }
}
function* getServiceScheduleSaga({ payload }: PayloadAction<number>) {
    try {
        const data: ScheduleBody = yield call(getServiceSchedule, payload);
        yield put(fetchServiceScheduleSuccess(data));
    } catch (error) {
        yield put(
            fetchServiceScheduleError({ error: (error as Error)?.message }),
        );
    }
}

function* updateServiceScheduleSaga({
    payload: { schedule, id },
}: PayloadAction<{ schedule: ScheduleBody; id: number }>) {
    try {
        yield call(postServiceSchedule, id, schedule);
        yield put(updateServiceScheduleSuccess());
        yield put(push(routes.SERVICE.replace(':id', `${id}`)));
    } catch (error) {
        yield put(
            updateServiceScheduleError({ error: (error as Error)?.message }),
        );
    }
}

function* updateServiceSaga({ payload }: PayloadAction<SelectedService>) {
    try {
        yield call(putService, payload.id, {
            ...payload,
            forceUpdate: true,
        });
        yield put(updateServiceSuccess());
        yield put(push(routes.SERVICES));
    } catch (error) {
        yield put(updateServiceError({ error: (error as Error)?.message }));
    }
}

function* fetchServicesByCompanySaga({
    payload,
}: PayloadAction<{ companyId: number; onlyEnabled: boolean }>) {
    try {
        const data: { id: number }[] = yield call(
            getServicesByCompany,
            payload.companyId,
            payload.onlyEnabled,
        );
        const { allIds, byId } = mapById(data);
        yield put(fetchServicesByCompanySuccess({ allIds, byId }));
    } catch (error) {
        yield put(
            fetchServicesByCompanyError({ error: (error as Error)?.message }),
        );
    }
}
export default function* serviceSagas() {
    yield takeLatest(fetchServices.type, fetchServicesSaga);
    yield takeLatest(setFilter.type, fetchServicesSaga);
    yield takeLatest(setStringFilter.type, fetchServicesSaga);
    yield takeLatest(setSort.type, fetchServicesSaga);
    yield takeLatest(setPage.type, fetchServicesSaga);
    yield takeLatest(createService.type, createServiceSaga);
    yield takeLatest(fetchService.type, getServiceSaga);
    yield takeLatest(updateService.type, updateServiceSaga);
    yield takeLatest(toggleServiceEnable.type, toggleServiceSaga);
    yield takeLatest(fetchServiceSchedule.type, getServiceScheduleSaga);
    yield takeLatest(updateServiceSchedule.type, updateServiceScheduleSaga);
    yield takeLatest(fetchNotLinkedServices.type, getNotLinkedServicesSaga);
    yield takeLatest(fetchServicesByCompany.type, fetchServicesByCompanySaga);
    yield takeLatest(fetchAllServices.type, fetchAllServicesSaga);
}
