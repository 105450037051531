import React, { Ref } from 'react';
import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Marker } from '@react-google-maps/api';
import { useStyles } from 'components/common/ModalContainer/styles';
import { MapComponent } from '../Map';

export type MapWithPoint = {
    handleClose: () => void;
    position: { lat: number; lng: number };
    title: string;
};

// eslint-disable-next-line react/display-name
export const MapWithPointModal = React.forwardRef(
    (
        { position, title, handleClose }: MapWithPoint,
        ref: Ref<HTMLDivElement>,
    ) => {
        const classes = useStyles();

        return (
            <div ref={ref} className={classes.modal}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h1">{title}</Typography>
                    <Box textAlign="right">
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <MapComponent boundCoordinates={[position]}>
                    <Marker position={position} />
                </MapComponent>
            </div>
        );
    },
);
