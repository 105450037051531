import { Pagination, Sort } from '../common/types';
import { Company, FileProperties } from '../companies/types';

export enum ProfileStatuses {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    BLOCKED = 'BLOCKED',
}

export enum FilterTypes {
    AreGroupsAccepted = 'areGroupsAccepted',
    AreNewsAccepted = 'areNewsAccepted',
    ArePartnersAccepted = 'arePartnersAccepted',
}

export enum StringFilterTypes {
    Search = 'searchText',
    CompanyId = 'companyId',
}

export enum DateTypes {
    StartDate = 'startDate',
    EndDate = 'endDate',
}

export type Filters = {
    [FilterTypes.AreGroupsAccepted]: boolean;
    [FilterTypes.AreNewsAccepted]: boolean;
    [FilterTypes.ArePartnersAccepted]: boolean;
    [StringFilterTypes.Search]: string;
    [StringFilterTypes.CompanyId]: string;
};

export type FetchedUser = {
    id: number;
    phone?: string;
    language?: string;
    firstName: string;
    lastName: string;
    isEnabled?: boolean;
    status?: boolean;
    areNewsAccepted: boolean;
    arePartnersAccepted: boolean;
    areGroupsAccepted: boolean;
    created: string;
    arePushAccepted?: boolean;
    arePushEnabled?: boolean;
    proEmail?: string;
    persEmail?: string;
    documents?: string[];
    stripeId?: string;
    rfidPassNumber?: string;
};

export type UsersById = {
    [code: number]: FetchedUser;
};

export interface User extends FetchedUser {
    companyName: string;
}

export enum SubscriptionStatuses {
    revoked = 'REVOKED',
    granted = 'GRANTED',
}

export type Profile = {
    id: number;
    email: string;
    type: string;
    isEnabled: boolean;
    status?: boolean;
};

export type Subscription = {
    id: number;
    startDate: string;
    endDate: string;
    serviceId: number;
    serviceName: string;
    serviceType: string;
    serviceTimezone: string;
    company: Company;
    status: SubscriptionStatuses;
};

export type FetchedSubscription = {
    subscription: any;
    service: any;
    company: any;
};

export type CurrentUserProfile = {
    id: number;
    email: string;
    type: string;
    isEnabled?: boolean;
    status: ProfileStatuses;
    subscriptions: Subscription[];
    cardDetails: FetchedCardDetails | null;
};

export type FetchedCurrentUserProfile = {
    profile: Profile;
    subscriptions: FetchedSubscription[];
    cardDetails: FetchedCardDetails | null;
};

export type CurrentUser = {
    user: User;
    profiles: CurrentUserProfile[];
};

export type FetchedCardDetails = {
    alias: string | null;
    expirationDate: string | null;
    last4Numbers: string | null;
    isDeleteInProgress: boolean | null;
    daysTillDelete: number | null;
};

export type FetchedCurrentUser = {
    user: User;
    profiles: FetchedCurrentUserProfile[];
};

export type UserProfileError = {
    [profileId: number]: string;
};

export type UserState = {
    loading: boolean;
    error: string;
    documentError: string;
    userProfileError: UserProfileError;
    userIds: number[];
    usersById: UsersById;
    filters: Filters;
    sort: Sort;
    documents: { [filename: string]: FileProperties };
    pagination: Pagination;
    // eslint-disable-next-line @typescript-eslint/ban-types
    currentUser: CurrentUser | null;
    userHasUnsavedChanges: boolean;
    profileHasUnsavedChanges: boolean;
};

export type UserResponse = {
    content: FetchedUser[];
} & Pagination;

export type UsersAccumulator = {
    userIds: number[];
    usersById: UsersById;
};
