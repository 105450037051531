import { RootState } from '../root';
import { createSelector } from 'reselect';

export const selectTrips = (state: RootState) => {
    return { tripsById: state.trip.tripsById, tripIds: state.trip.tripIds };
};

export const selectFilters = (state: RootState) => state.trip.filters;
export const selectSort = (state: RootState) => state.trip.sort;
export const selectOverdueSort = (state: RootState) => state.trip.overdueSort;
export const selectIsLoading = (state: RootState) => state.trip.loading;
export const selectPagination = (state: RootState) => state.trip.pagination;

export const selectCurrentTrip = (state: RootState) => state.trip.currentTrip;
export const selectCurrentTripService = (state: RootState) =>
    state.trip.currentTrip?.service;
export const selectStatistics = (state: RootState) => state.trip.statistics;
export const selectStatisticsByStatus = createSelector(
    selectStatistics,
    statistics => statistics?.byStatus?.map(stat => ({ ...stat })),
);
export const selectStatisticsRented = createSelector(
    selectStatistics,
    statistics => statistics?.rented?.map(stat => ({ ...stat })),
);

export const selectMaxTripsIds = (state: RootState) => state.trip.maxTripIds;
export const selectMaxTripsById = (state: RootState) => state.trip.maxTripsById;
export const selectTableMaxTrips = createSelector(
    selectMaxTripsIds,
    selectMaxTripsById,
    (ids, byId) => ids.map(id => ({ ...byId[id] })),
);
