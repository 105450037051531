import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@material-ui/core';
import { selectFilters, setStringFilter, StringFilterTypes } from 'ducks/zones';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

export const ZoneSearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);

    const redirectToZoneCreation = () => dispatch(push(routes.NEW_ZONE));

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length >= 2 || e.target.value.length === 0) {
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.Name,
                    value: e.target.value,
                }),
            );
        }
    };
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToCreate = accessToPages[Permissions.ZONE_CREATE];
    return (
        <FormGrid columnAmount={2} padding="10px 0" rowHeight="20px">
            <SearchComponent
                placeholder={t('zones.searchPlaceholder')}
                onChange={handleSearch}
                defaultValue={filters[StringFilterTypes.Name]}
            />
            {allowedToCreate && (
                <Box textAlign="right">
                    <ButtonComponent
                        text={t('zones.addZone')}
                        startIcon={<AddIcon />}
                        handleClick={redirectToZoneCreation}
                    />
                </Box>
            )}
        </FormGrid>
    );
};
