import request from 'helpers/request';
import { STATIONS_URL } from 'constants/api';
import { Station, StationActivationStatuses } from './types';

export const getStations = ({ sort, ...params }: any): Promise<string> => {
    const [sortField] = sort.split(',');

    if (!sortField || sortField === '') {
        return request.get(STATIONS_URL, { params });
    }

    return request.get(STATIONS_URL, { params: { sort, ...params } });
};

export const postStation = (params: Station): Promise<string> => {
    return request.post(STATIONS_URL, { ...params });
};

export const syncStation = ({
    name,
    serviceId,
}: {
    name: string;
    serviceId: number;
}): Promise<string> =>
    request.get(`${STATIONS_URL}/sync/service/${serviceId}/station/${name}`);

export const getStation = (id: number): Promise<string> =>
    request.get(`${STATIONS_URL}/${id}`);

export const putStation = (id: number, params: Station): Promise<string> =>
    request.put(`${STATIONS_URL}/${id}`, { ...params });

export const deleteStationService = (id: number): Promise<string> =>
    request.delete(`${STATIONS_URL}/${id}`);

export const addBeamService = ({
    stationId,
    beamId,
    readerName,
}: {
    stationId: number;
    beamId: string;
    readerName?: string;
}): Promise<string> =>
    request.patch(`${STATIONS_URL}/${stationId}/addBeam`, {
        beamId,
        readerName,
    });

export const deleteBeamService = ({
    stationId,
    beamId,
}: {
    stationId: number;
    beamId: string;
}): Promise<string> =>
    request.patch(`${STATIONS_URL}/${stationId}/deleteBeam`, { beamId });

export const putStationActivationStatus = (
    id: number,
    activationStatus: StationActivationStatuses,
): Promise<string> =>
    request.put(`${STATIONS_URL}/${id}/activation-status`, null, {
        params: { activationStatus },
    });
