import { RootState } from '../root';
import { createSelector } from '@reduxjs/toolkit';

export const selectLockError = (state: RootState) => state.lock.error;

export const selectLocksById = (state: RootState) => state.lock.locksById;
export const selectLockIds = (state: RootState) => state.lock.lockIds;

export const selectFilters = (state: RootState) => state.lock.filters;
export const selectPagination = (state: RootState) => state.lock.pagination;
export const selectSort = (state: RootState) => state.lock.sort;
export const selectLockConfig = (state: RootState) =>
    state.lock.currentLockConfig;
export const selectIsLoading = (state: RootState) => state.lock.loading;

export const selectLocksTableData = createSelector(
    selectLockIds,
    selectLocksById,
    (lockIds, locksById) =>
        lockIds.map(id => ({
            ...locksById[id],
        })),
);

export const selectLockHasUnsavedChanges = (state: RootState) =>
    state.lock.lockHasUnsavedChanges;

export const selectCurrentLock = (state: RootState) => state.lock.currentLock;
export const selectCurrentLockVehicle = (state: RootState) =>
    state.lock.currentLockVehicle;
