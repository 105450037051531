import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterTypes, ScheduleBody, StringFilterTypes } from './types';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';

const defaultFilters = {
    [FilterTypes.DomainRecognition]: false,
    [FilterTypes.GeofenceEnabled]: false,
    [FilterTypes.BookingAllowed]: false,
    [FilterTypes.StationConnected]: false,
    [FilterTypes.PauseAllowed]: false,
    [StringFilterTypes.Enabled]: '',
    [StringFilterTypes.Types]: '',
    [StringFilterTypes.ServiceName]: '',
    [StringFilterTypes.Company]: '',
};
export const initialState = {
    loading: false,
    error: '',
    currentService: {
        id: null,
    },
    serviceIds: [],
    allServicesById: {},
    allServiceIds: [],
    notLinkedServicesById: {},
    notLinkedServicesIds: [],
    servicesById: {},
    currentServiceSchedule: [],
    serviceHasUnsavedChanges: false,
    filters: defaultFilters,
    sort: {
        value: 'name',
        direction: SortDirection.Asc,
    },
    pagination: defaultPagination,
};

const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        fetchServices(state) {
            state.loading = true;
            state.error = '';
        },
        fetchServicesSuccess(state, action) {
            state.serviceIds = action.payload.serviceIds;
            state.servicesById = action.payload.servicesById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchServicesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchNotLinkedServices(state) {
            state.loading = true;
            state.error = '';
        },
        fetchNotLinkedServicesSuccess(state, action) {
            state.notLinkedServicesById = action.payload.byId;
            state.notLinkedServicesIds = action.payload.allIds;
            state.loading = false;
        },
        fetchNotLinkedServicesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchServicesByCompany: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ companyId, onlyEnabled }) => ({
                payload: { companyId, onlyEnabled },
            }),
        },
        fetchServicesByCompanySuccess(state, action) {
            state.servicesById = action.payload.byId;
            state.serviceIds = action.payload.allIds;
            state.loading = false;
        },
        fetchServicesByCompanyError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchService: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: id => ({
                payload: id,
            }),
        },
        fetchServiceSuccess(state, action) {
            state.currentService = action.payload;
            state.loading = false;
        },
        fetchServiceError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchServiceSchedule: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: id => ({
                payload: id,
            }),
        },
        fetchServiceScheduleSuccess(state, action) {
            state.currentServiceSchedule = action.payload;
            state.loading = false;
        },
        fetchServiceScheduleError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateServiceSchedule: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.serviceHasUnsavedChanges = true;
            },
            prepare: (schedule: ScheduleBody, id: number) => ({
                payload: {
                    schedule,
                    id,
                },
            }),
        },
        updateServiceScheduleSuccess(state) {
            state.loading = false;
            state.serviceHasUnsavedChanges = false;
        },
        updateServiceScheduleError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        createService: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.serviceHasUnsavedChanges = true;
            },
            prepare: service => ({
                payload: service,
            }),
        },
        createServiceSuccess(state) {
            state.loading = false;
            state.serviceHasUnsavedChanges = false;
        },
        createServiceError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        toggleServiceEnable: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (id, enable) => ({
                payload: { id, enable },
            }),
        },
        toggleServiceSuccess(state) {
            state.loading = false;
        },
        toggleServiceError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateService: {
            reducer: state => {
                state.loading = true;
                state.serviceHasUnsavedChanges = true;
                state.error = '';
            },
            prepare: service => ({
                payload: service,
            }),
        },
        updateServiceSuccess(state) {
            state.loading = false;
            state.serviceHasUnsavedChanges = false;
        },
        updateServiceError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setFilter(state, { payload }: PayloadAction<FilterTypes>) {
            state.filters[payload] = !state.filters[payload];
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setStringFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: StringFilterTypes;
                value: string;
            }>,
        ) {
            state.filters[payload.filterType] = payload.value;
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setServiceHasUnsavedChanges(state, action) {
            state.serviceHasUnsavedChanges = action.payload;
        },
        fetchAllServices: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ onlyEnabled, type }) => ({
                payload: { onlyEnabled, type },
            }),
        },
        fetchAllServicesSuccess(state, action) {
            state.allServicesById = action.payload.byId;
            state.allServiceIds = action.payload.allIds;
            state.loading = false;
        },
        fetchAllServicesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
    },
});
export const {
    fetchServices,
    fetchServicesError,
    fetchServicesSuccess,
    fetchService,
    fetchServiceError,
    fetchServiceSuccess,
    updateService,
    updateServiceError,
    updateServiceSuccess,
    setFilter,
    setStringFilter,
    setSort,
    setPage,
    createService,
    createServiceError,
    createServiceSuccess,
    toggleServiceEnable,
    toggleServiceError,
    toggleServiceSuccess,
    fetchServiceSchedule,
    fetchServiceScheduleError,
    fetchServiceScheduleSuccess,
    updateServiceSchedule,
    updateServiceScheduleError,
    updateServiceScheduleSuccess,
    fetchNotLinkedServices,
    fetchNotLinkedServicesError,
    fetchNotLinkedServicesSuccess,
    setServiceHasUnsavedChanges,
    fetchServicesByCompany,
    fetchServicesByCompanyError,
    fetchServicesByCompanySuccess,
    fetchAllServices,
    fetchAllServicesError,
    fetchAllServicesSuccess,
    resetFilters,
} = serviceSlice.actions;

export const serviceReducer = serviceSlice.reducer;
