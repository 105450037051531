export enum AppType {
    IOS = 'IOS',
    ANDROID = 'ANDROID',
}
export type AppVersionType = {
    required: boolean;
    type: AppType;
    version: string;
};

export type AppVersionState = {
    loading: boolean;
    error: string;
    applicationVersionHasUnsavedChanges: boolean;
};
