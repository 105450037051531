import { TableComponent } from '../../common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServices, selectIsLoading, selectSort } from 'ducks/services';
import { RootState } from 'ducks/root';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { ServiceToolbarComponent } from './Toolbar';
import { ServiceSearchToolbar } from './ServiceSearchToolbar';
import { fetchAllCompanies } from 'ducks/companies';
import { ServicePagination } from './Pagination';
import { Service } from 'ducks/services/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const ServiceListComponent = () => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const redirectToService = (id: number) =>
        dispatch(push(routes.SERVICE.replace(':id', `${id}`)));

    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);
    const columns = getColumns(t, dispatch);

    React.useEffect(() => {
        dispatch(fetchServices());
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    const { servicesById, serviceIds } = useSelector(
        (state: RootState) => state.service,
    );

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'edit',
                        tooltip: t('serviceList.edit'),
                        onClick: (event, rowData: Service) =>
                            rowData && redirectToService(rowData.id),
                    },
                ]}
                onRowClick={(event, rowData: Service | undefined) =>
                    rowData && redirectToService(rowData.id)
                }
                toolbar={ServiceToolbarComponent}
                searchComponent={ServiceSearchToolbar}
                columns={columns}
                pagination={ServicePagination}
                data={serviceIds.map(id => ({
                    ...servicesById[id],
                }))}
                title={t('serviceList.title')}
                sort={sort}
            />
        </>
    );
};
