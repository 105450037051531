import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LockState, FilterTypes, NewLock, LockCommands } from './types';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';

const defaultFilters = {
    batteryLevelTo: '',
    statuses: '',
    closeStatus: '',
    lastSync: '',
    search: '',
    companyId: '',
};

export const initialState: LockState = {
    loading: false,
    error: '',
    currentLock: null,
    currentLockConfig: null,
    currentLockVehicle: null,
    lockIds: [],
    locksById: {},
    pagination: defaultPagination,
    lockHasUnsavedChanges: false,
    sort: {
        value: 'created',
        direction: SortDirection.Desc,
    },
    filters: defaultFilters,
};

const lockSlice = createSlice({
    name: 'lock',
    initialState,
    reducers: {
        unbindLock: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({
                lockId,
                status,
            }: {
                lockId: number;
                status: string;
            }) => ({
                payload: { lockId, status },
            }),
        },
        unbindLockSuccess(state) {
            state.loading = false;
        },
        unbindLockError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetError(state) {
            state.error = '';
        },
        fetchLocks(state) {
            state.loading = true;
            state.error = '';
        },
        fetchLocksSuccess(state, action) {
            state.lockIds = action.payload.lockIds;
            state.locksById = action.payload.locksById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchLocksError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.pagination = defaultPagination;
            state.loading = true;
        },
        exportLocks(state) {
            state.loading = true;
            state.error = '';
        },
        exportLocksSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        exportLocksError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        createLock: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.lockHasUnsavedChanges = true;
            },
            prepare: lock => ({
                payload: lock,
            }),
        },
        createLockSuccess(state) {
            state.loading = false;
            state.lockHasUnsavedChanges = false;
        },
        createLockError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setLockHasUnsavedChanges(state, action) {
            state.lockHasUnsavedChanges = action.payload;
        },
        importLocks: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.lockHasUnsavedChanges = true;
            },
            prepare: ({ file }) => ({
                payload: { file },
            }),
        },
        importLocksSuccess(state) {
            state.loading = false;
            state.lockHasUnsavedChanges = false;
        },
        importLocksError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchLock: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: lockId => ({
                payload: lockId,
            }),
        },
        fetchLockSuccess(state, action) {
            state.loading = false;
            state.currentLock = action.payload.lock;
            state.currentLockVehicle = action.payload.vehicle;
        },
        fetchLockError(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
        fetchConfig: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: lockId => ({
                payload: lockId,
            }),
        },
        fetchConfigSuccess(state, action) {
            state.loading = false;
            state.currentLockConfig = action.payload;
        },
        fetchConfigError(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
        updateLock: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (lock: NewLock, id: number) => ({
                payload: { lock, id },
            }),
        },
        updateLockSuccess(state) {
            state.loading = false;
            state.lockHasUnsavedChanges = false;
        },
        updateLockError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetCurrentLock(state) {
            state.currentLock = null;
            state.currentLockVehicle = null;
            state.currentLockConfig = null;
        },
        sendCommand: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (command: LockCommands) => ({
                payload: command,
            }),
        },
        sendCommandSuccess(state) {
            state.loading = false;
        },
        sendCommandError(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    sendCommand,
    sendCommandSuccess,
    sendCommandError,
    unbindLock,
    unbindLockSuccess,
    unbindLockError,
    resetError,
    fetchLocks,
    fetchLocksSuccess,
    fetchLocksError,
    setSort,
    setPage,
    exportLocks,
    exportLocksSuccess,
    exportLocksError,
    setFilter,
    resetFilters,
    createLock,
    createLockError,
    createLockSuccess,
    setLockHasUnsavedChanges,
    importLocks,
    importLocksError,
    importLocksSuccess,
    fetchLockError,
    fetchLockSuccess,
    fetchLock,
    updateLock,
    updateLockError,
    updateLockSuccess,
    resetCurrentLock,
    fetchConfig,
    fetchConfigError,
    fetchConfigSuccess,
} = lockSlice.actions;

export const lockReducer = lockSlice.reducer;
