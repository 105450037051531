import React, { SyntheticEvent } from 'react';
import { ButtonComponent, TableComponent } from '../../../common';
import { push } from 'connected-react-router';
import { routes } from '../../../../constants/common';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { RootState } from 'ducks/root';
import { useParams } from 'react-router';
import { Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddPersonIcon from '@material-ui/icons/PersonAdd';
import { Modals, openModal } from 'ducks/ui';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { Service } from 'ducks/services/types';
import DeleteIcon from '@material-ui/icons/Delete';
import { unlinkCompany, selectIsLoading } from 'ducks/companies';
import { Action } from '../../../common/Table';
import { FullScreenLoaderComponent } from '../../../common/Loader/FullScreenLoader';

export const CompanyServices = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accessToPages = useSelector(selectAccessToPages);
    const loading = useSelector(selectIsLoading);
    const allowedToLink = accessToPages[Permissions.SERVICE_LINK];
    const allowedToInvite = accessToPages[Permissions.INVITATION_CREATE];
    const { companyId } = useParams<{ companyId?: string }>();
    const redirectToServiceEdit = React.useCallback(
        (id: number) =>
            dispatch(
                push(
                    routes.INVITE_TO_SERVICE.replace(
                        ':companyId',
                        `${companyId}`,
                    ).replace(':serviceId', `${id}`),
                ),
            ),
        [dispatch, companyId],
    );
    const openConnectServiceModal = React.useCallback(
        () =>
            dispatch(
                openModal({
                    modalType: Modals.CONNECT_SERVICES,
                    modalProps: { companyId },
                }),
            ),
        [companyId, dispatch],
    );

    const columns = getColumns(t);
    const { companyServices } = useSelector(
        (state: RootState) => state.company,
    );
    const handleDelete = React.useCallback(
        (id: number) => {
            dispatch(unlinkCompany({ serviceId: id }));
        },
        [dispatch],
    );

    const handleClickDelete = React.useCallback(
        (id: number) => {
            dispatch(
                openModal({
                    modalType: Modals.CHANGE_CONFIRMATION,
                    modalProps: {
                        handleConfirm: () => handleDelete(id),
                        message: 'company.unlinkService',
                    },
                }),
            );
        },
        [dispatch, handleDelete],
    );

    const actions = React.useMemo((): Action<Service>[] => {
        const actionsArray = [];
        if (allowedToInvite) {
            actionsArray.push({
                // eslint-disable-next-line react/display-name
                icon: () => <AddPersonIcon />,
                tooltip: t('serviceList.edit'),
                onClick: (
                    event: SyntheticEvent<Element, Event>,
                    rowData: Service | undefined,
                ) => rowData && redirectToServiceEdit(rowData.id),
            });
        }
        if (allowedToLink) {
            actionsArray.push({
                // eslint-disable-next-line react/display-name
                icon: () => <DeleteIcon />,
                tooltip: t('delete'),
                onClick: (
                    event: SyntheticEvent<Element, Event>,
                    rowData: Service | undefined,
                ) => rowData && handleClickDelete(rowData.id),
            });
        }
        return actionsArray;
    }, [
        redirectToServiceEdit,
        t,
        allowedToInvite,
        allowedToLink,
        handleClickDelete,
    ]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            {allowedToLink && (
                <Box textAlign="right">
                    <ButtonComponent
                        startIcon={<AddIcon />}
                        text={t('company.addService')}
                        handleClick={openConnectServiceModal}
                    />
                </Box>
            )}
            <TableComponent
                actions={actions}
                onRowClick={(event, rowData: Service | undefined) =>
                    rowData && redirectToServiceEdit(rowData.id)
                }
                columns={columns}
                pageSize={companyServices.length}
                data={companyServices.map(service => ({ ...service }))}
                title={t('serviceList.title')}
            />
        </>
    );
};
