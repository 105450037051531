import { Pagination, Sort } from '../common/types';
import { FetchedVehicle, Vehicle } from '../vehicles/common/types';

export type LockState = {
    loading: boolean;
    error: string;
    currentLock: FetchedLock | null;
    lockIds: number[];
    locksById: LocksById;
    sort: Sort;
    pagination: Pagination;
    filters: Filters;
    lockHasUnsavedChanges: boolean;
    currentLockVehicle: Vehicle | null;
    currentLockConfig: Record<string, Record<string, string>> | null;
};

export enum LockStatus {
    inactive = 'INACTIVE',
    active = 'ACTIVE',
    lost = 'LOST',
    destroyed = 'DESTROYED',
    unavailable = 'UNAVAILABLE',
    stolen = 'STOLEN',
}

export interface NewLock {
    qrCode: string;
    version: string;
    status?: LockStatus;
    comment?: string;
}
export interface Lock extends NewLock {
    id: number;
    beamId: string;
    bluetoothMac: string;
    ICCID: string;
    processorUUID: string;
    lteFirmwareVersion: string;
    lteId: string;
    deviceType: string;
    registerTime: string;
    communicationTime: string;
    batteryLevel: number;
    firmwareVersion: string;
    status: LockStatus;
    lat: number;
    lng: number;
    isClosed: boolean;
    isAlarmActive: boolean;
    created: string;
    lastSync: string;
}

export type FetchedLock = {
    batteryLevel: number;
    id: number;
    isAlarmActive: boolean;
    isClosed: boolean;
    lastSync: string;
    qrCode: string;
    status: LockStatus;
    created: string;
    version: string;
    beamId: number;
    comment?: string;
};

export interface FetchedLocks extends Pagination {
    content: LockFromList[];
}

export type LocksById = {
    [code: number]: LockFromList;
};

type LockStation = {
    id: number;
    created: string;
    name: string;
};

export type LockFromList = {
    lock: FetchedLock;
    vehicle: FetchedVehicle;
    station?: LockStation;
};

export enum FilterTypes {
    Battery = 'batteryLevelTo',
    Status = 'statuses',
    CloseStatus = 'closeStatus',
    LastDate = 'lastSync',
    Search = 'search',
    Company = 'companyId',
}

export enum SortingTypes {
    ID = 'ID',
    BatteryLevel = 'batteryLevel',
    LockStatus = 'status',
    LastDate = 'lastSync',
}

export type Filters = {
    [FilterTypes.Battery]: string;
    [FilterTypes.Status]: string;
    [FilterTypes.CloseStatus]: string;
    [FilterTypes.LastDate]: string;
    [FilterTypes.Company]: string;
    [FilterTypes.Search]: string;
};

export type Accumulator = {
    lockIds: number[];
    locksById: LocksById;
};

export enum LockCommands {
    ASK_CONFIG = 'ASK_CONFIG',
    ASK_PROCESSOR_DATA = 'ASK_PROCESSOR_DATA',
    ASK_LTE_DATA = 'ASK_LTE_DATA',
    ASK_ERROR_LOG = 'ASK_ERROR_LOG',
    RESET_PROCESSOR = 'RESET_PROCESSOR',
    ASK_VAE_DATA = 'ASK_VAE_DATA',
    ASK_REPORT_DATA = 'ASK_REPORT_DATA',
    START_GPS_ACQUISITION = 'START_GPS_ACQUISITION',
}
