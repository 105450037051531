import request from 'helpers/request';
import { ScheduleBody, SelectedService, UsageTypes } from './types';
import qs from 'qs';

export const getServices = (params: any): Promise<string> =>
    request.get('/backoffice/services', { params });

export const getAllServices = (params: {
    onlyEnabled: boolean;
    type?: UsageTypes;
}): Promise<string> =>
    request.get(
        `/backoffice/services/all?${qs.stringify(params, { indices: false })}`,
    );

export const getService = (id: number): Promise<string> =>
    request.get(`/backoffice/services/${id}`);

export const getServicesByCompany = (
    companyId: number,
    onlyEnabled = false,
): Promise<string> =>
    request.get(
        `/backoffice/services/byCompany?companyId=${companyId}&onlyEnabled=${onlyEnabled}`,
    );

export const getNotLinkedServices = (): Promise<string> =>
    request.get(`/backoffice/services/notLinked`);

export const getServiceSchedule = (id: number): Promise<string> =>
    request.get(`/backoffice/services/${id}/configuration/dailySchedule`);

export const postServiceSchedule = (
    id: number,
    params: ScheduleBody,
): Promise<string> =>
    request.post(`/backoffice/services/${id}/configuration/dailySchedule`, {
        ...params,
    });

export const postService = (params: SelectedService): Promise<string> =>
    request.post('/backoffice/services/v2', { ...params });

export const putService = (id: number, params: any): Promise<string> =>
    request.put(`/backoffice/services/v2/${id}`, { ...params });

export const toggleService = (id: number, enable: boolean): Promise<string> =>
    request.post(`/backoffice/services/${id}/setEnable/${enable}`);
