import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    setPage,
    setSort,
    fetchUserPaymentHistory,
    fetchUserPaymentHistoryError,
    fetchUserPaymentHistorySuccess,
} from './index';
import {
    selectPaymentHistorySort,
    selectPaymentHistoryPagination,
} from './selectors';
import { Pagination, Sort } from '../common/types';
import { mapPagination, mapSorting } from '../common/mappers';
import { mapFetchedPagination } from '../common/pagination';
import { UserPaymentHistoryResponse } from './types';
import { getPaymentHistory } from './service';
import { mapFetchedPaymentHistory } from './mapper';

function* fetchPaymentHistorySaga({
    payload: { profileId, userId },
}: PayloadAction<{ profileId: number; userId: number }>) {
    try {
        const sort: Sort = yield select(
            selectPaymentHistorySort,
            userId,
            profileId,
        );
        const pagination: Pagination = yield select(
            selectPaymentHistoryPagination,
            userId,
            profileId,
        );

        const data: UserPaymentHistoryResponse = yield call(getPaymentHistory, {
            userId,
            profileId,
            params: { ...mapSorting(sort), ...mapPagination(pagination) },
        });
        const { paymentIds, paymentsById } = mapFetchedPaymentHistory(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchUserPaymentHistorySuccess({
                paymentIds,
                paymentsById,
                pagination: fetchedPagination,
                userId,
                profileId,
            }),
        );
    } catch (error) {
        yield put(
            fetchUserPaymentHistoryError({ error: (error as Error)?.message }),
        );
    }
}

export default function* paymentSagas() {
    yield takeEvery(fetchUserPaymentHistory.type, fetchPaymentHistorySaga);
    yield takeLatest(setSort.type, fetchPaymentHistorySaga);
    yield takeLatest(setPage.type, fetchPaymentHistorySaga);
}
