import React from 'react';
import { ButtonComponent } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { anonymizeCompany, deleteCompany } from 'ducks/companies';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { RootState } from 'ducks/root';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { selectAccessToPages } from '../../../../ducks/auth';
import { Permissions } from '../../../../ducks/groups/types';
import { FormButtonGroup } from '../../../common/ButtonGroup';

interface ButtonProps {
    valid: boolean;
    handleSubmit: () => void;
}

export const Buttons: React.FC<ButtonProps> = ({
    valid,
    handleSubmit,
}: ButtonProps) => {
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = accessToPages[Permissions.COMPANY_UPDATE];
    const allowedToCreate = accessToPages[Permissions.COMPANY_CREATE];
    const allowedToDelete = accessToPages[Permissions.COMPANY_DELETE];

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companyId } = useParams<{ companyId?: string }>();
    const { loading, currentCompany, companyHasUnsavedChanges } = useSelector(
        (state: RootState) => state.company,
    );
    const isCompanyActionButtonsDisabled =
        !companyHasUnsavedChanges ||
        !valid ||
        !currentCompany.isEnabled ||
        loading;

    const handleDelete = () => {
        dispatch(deleteCompany());
        dispatch(closeModal());
    };

    const handleAnonymize = () => {
        dispatch(anonymizeCompany());
    };

    return (
        <>
            {companyId ? (
                <FormButtonGroup>
                    {allowedToDelete && (
                        <ButtonComponent
                            handleClick={handleAnonymize}
                            text={t('company.anonymize')}
                        />
                    )}
                    {allowedToDelete && (
                        <ButtonComponent
                            handleClick={() =>
                                dispatch(
                                    openModal({
                                        modalType: Modals.CHANGE_CONFIRMATION,
                                        modalProps: {
                                            handleConfirm: handleDelete,
                                            message: 'company.deleteMessage',
                                        },
                                    }),
                                )
                            }
                            text={t('delete')}
                            isDisabled={loading}
                            startIcon={<CancelIcon />}
                        />
                    )}
                    {allowedToEdit && (
                        <ButtonComponent
                            handleClick={handleSubmit}
                            text={t('save')}
                            isDisabled={isCompanyActionButtonsDisabled}
                            startIcon={<SaveIcon />}
                        />
                    )}
                </FormButtonGroup>
            ) : (
                allowedToCreate && (
                    <FormButtonGroup>
                        <ButtonComponent
                            handleClick={handleSubmit}
                            text={t('create')}
                            isDisabled={
                                !companyHasUnsavedChanges || !valid || loading
                            }
                        />
                    </FormButtonGroup>
                )
            )}
        </>
    );
};
