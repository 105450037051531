import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort, setOverdueSort } from 'ducks/trips';
import { FetchedTrip } from 'ducks/trips/types';
import { LinkComponent } from '../../common/Link';
import { generatePath } from 'react-router';
import { routes } from '../../../constants/common';
import { VehicleTypes } from 'ducks/vehicles/common/types';
import { get } from 'lodash';

export const getColumns = (
    t: TFunction,
    dispatch: Dispatch<any>,
    isOverdue: boolean,
) => {
    return [
        {
            title: t('tripList.tripId'),
            field: 'id',
            sorting: false,
        },
        {
            title: t('tripList.vehicleType'),
            field: 'vehicleType',
            render: (rowProps: FetchedTrip) =>
                t(`tripList.${rowProps.vehicle?.type.toLowerCase()}`),
            onClick: () =>
                dispatch(
                    isOverdue
                        ? setOverdueSort({ value: 'vehicleType' })
                        : setSort({
                              value: 'vehicleType',
                          }),
                ),
        },
        {
            title: t('invitations.lldVehicle'),
            field: '',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedTrip) => (
                <LinkComponent
                    handleClick={event => event.stopPropagation()}
                    href={generatePath(
                        get(
                            routes,
                            rowProps?.vehicle?.type || '',
                            routes.TRIPS,
                        ),
                        {
                            vehicleId: rowProps?.vehicle?.id || '-',
                        },
                    )}
                    target="_blank"
                    text={
                        (rowProps.vehicle?.type === VehicleTypes.SCOOTER
                            ? rowProps.vehicle?.plate
                            : rowProps.vehicle?.serial) || ''
                    }
                />
            ),
        },
        {
            title: t('tripList.startDate'),
            field: 'startTime',
            render: (rowProps: FetchedTrip) =>
                new Date(rowProps.startTime).toLocaleString(),
            onClick: () =>
                dispatch(
                    isOverdue
                        ? setOverdueSort({ value: 'startTime' })
                        : setSort({
                              value: 'startTime',
                          }),
                ),
        },
        {
            title: t('tripList.userName'),
            field: 'userName',
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedTrip) => (
                <LinkComponent
                    handleClick={event => event.stopPropagation()}
                    href={generatePath(routes.USER, {
                        userId: rowProps.endUser?.id || '-',
                    })}
                    target="_blank"
                    text={`${rowProps.endUser.firstName} ${rowProps.endUser.lastName}`}
                />
            ),
            sorting: false,
        },
        {
            title: t('tripList.startZone'),
            field: 'startZones',
            render: (rowProps: FetchedTrip) =>
                rowProps.startZones?.map(({ name, id }) => (
                    // eslint-disable-next-line react/jsx-key
                    <LinkComponent
                        handleClick={event => event.stopPropagation()}
                        href={generatePath(routes.ZONE, {
                            zoneId: id || '-',
                        })}
                        target="_blank"
                        text={name || '-'}
                    />
                )),
        },
        {
            title: t('tripList.startStation'),
            field: 'startStation',
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedTrip) => (
                <LinkComponent
                    handleClick={event => event.stopPropagation()}
                    href={generatePath(routes.STATION, {
                        stationId: rowProps.startStation?.id || '-',
                    })}
                    target="_blank"
                    text={rowProps.startStation?.name}
                />
            ),
        },
    ];
};
