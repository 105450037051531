import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {
    fetchPermissions,
    selectPermissions,
    createGroup,
    fetchGroup,
    resetCurrentGroup,
    setGroupHasUnsavedChanges,
    selectCurrentGroup,
    updateGroup,
    deleteGroup,
    selectGroupHasUnsavedChanges,
    selectGroupError,
    resetError,
    selectIsLoading,
} from 'ducks/groups';
import { CheckboxField } from '../../common/Form/FormCheckboxField';
import { Form, FormSpy } from 'react-final-form';
import { ButtonComponent } from '../../common/Button';
import { useTranslation } from 'react-i18next';
import { FormGrid, TextField, ThemedBoxComponent } from '../../common';
import { validations } from './validations';
import { StyledAccordion } from '../../common/Accordion';
import SaveIcon from '@material-ui/icons/Save';
import { isEqual } from 'lodash';
import {
    Typography,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    Box,
    IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Prompt, useParams } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { useNotificationOnPageLeave } from '../../../hooks/useNotificationOnPageLeave';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { ErrorComponent } from '../../common/Error';
import { useFormMutators } from 'hooks/useFormMutators';

export const CreateGroup: React.FC = () => {
    const dispatch = useDispatch();
    const permissions = useSelector(selectPermissions);
    const currentGroup = useSelector(selectCurrentGroup);
    const { t } = useTranslation();
    const { groupId } = useParams<{ groupId?: string }>();
    const groupHasUnsavedChanges = useSelector(selectGroupHasUnsavedChanges);
    const error = useSelector(selectGroupError);
    const accessToPages = useSelector(selectAccessToPages);
    const loading = useSelector(selectIsLoading);
    const allowedToEdit =
        accessToPages[
            groupId ? Permissions.GROUP_UPDATE : Permissions.GROUP_CREATE
        ];
    const allowedToDelete = accessToPages[Permissions.GROUP_DELETE];

    React.useEffect(() => {
        dispatch(fetchPermissions());
        dispatch(resetError());
        if (groupId) {
            dispatch(fetchGroup(groupId));
        }
        return () => {
            dispatch(resetCurrentGroup());
            dispatch(setGroupHasUnsavedChanges(false));
        };
    }, [dispatch, groupId]);

    const redirectToGroups = React.useCallback(
        () => dispatch(push(routes.GROUPS)),
        [dispatch],
    );

    const initialValues = React.useMemo(
        () =>
            groupId
                ? {
                      ...currentGroup,
                      permissions:
                          currentGroup?.permissions.reduce(
                              (acc, perm) => ({ ...acc, [perm]: true }),
                              {},
                          ) || {},
                  }
                : {
                      permissions: {},
                  },
        [groupId, currentGroup],
    );

    const [submittedValues, setSubmittedValues] = React.useState(initialValues);

    const handleConfirm = React.useCallback(
        values => {
            const formedValues = {
                ...values,
                permissions: Object.keys(values.permissions).filter(
                    key => values.permissions[key],
                ),
            };
            dispatch(
                groupId ? updateGroup(formedValues) : createGroup(formedValues),
            );
            setSubmittedValues(values);
        },
        [dispatch, groupId],
    );

    const handleDelete = React.useCallback(() => {
        dispatch(deleteGroup(groupId));
        dispatch(closeModal());
    }, [dispatch, groupId]);

    const handleClickDelete = React.useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: handleDelete,
                    message: 'groups.deleteMessage',
                },
            }),
        );
    }, [dispatch, handleDelete]);

    const { setField: setBooleanField } = useFormMutators();

    const mutators = React.useMemo(
        () => ({
            setBooleanField,
        }),
        [setBooleanField],
    );

    useNotificationOnPageLeave(groupHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 15%">
            {loading && <LoaderComponent />}
            <Prompt
                when={groupHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                initialValues={initialValues}
                mutators={mutators}
                onSubmit={handleConfirm}
                render={({
                    handleSubmit,
                    valid,
                    form,
                    values,
                    initialValues,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToGroups}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {t(
                                        groupId
                                            ? 'groups.updateGroup'
                                            : 'groups.addGroup',
                                    )}
                                </p>
                            </Typography>
                        </Box>
                        <FormSpy
                            subscription={{ values: true, initialValues: true }}
                            onChange={({ values, initialValues }) => {
                                dispatch(
                                    setGroupHasUnsavedChanges(
                                        !isEqual(initialValues, values),
                                    ),
                                );
                            }}
                        />
                        <FormGrid columnAmount={2}>
                            <TextField
                                disabled={!allowedToEdit}
                                name="name"
                                validators={validations.name}
                                label={t('groups.name')}
                                isRequired
                            />
                            <TextField
                                disabled={!allowedToEdit}
                                name="description"
                                validators={validations.description}
                                label={t('groups.description')}
                            />
                        </FormGrid>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    disabled={!allowedToEdit}
                                    checked={Object.values(Permissions)
                                        .map(
                                            perm => values['permissions'][perm],
                                        )
                                        .every(val => val)}
                                    onChange={event => {
                                        Object.values(Permissions).map(perm =>
                                            form.mutators.setBooleanField({
                                                field: `permissions.${perm}`,
                                                value: event.target.checked,
                                            }),
                                        );
                                    }}
                                />
                            }
                            label={
                                <Typography variant="h2">
                                    {t('selectAll')}
                                </Typography>
                            }
                        />

                        {Object.entries(permissions).map(
                            ([category, permissions]) => (
                                <StyledAccordion
                                    key={category}
                                    TransitionProps={{ unmountOnExit: true }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}>
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onFocus={event =>
                                                event.stopPropagation()
                                            }
                                            onClick={event =>
                                                event.stopPropagation()
                                            }
                                            control={
                                                <Checkbox
                                                    disabled={!allowedToEdit}
                                                    checked={permissions
                                                        .map(
                                                            perm =>
                                                                values[
                                                                    'permissions'
                                                                ][
                                                                    perm
                                                                        .permission
                                                                ],
                                                        )
                                                        .every(val => val)}
                                                    onChange={event => {
                                                        permissions.map(perm =>
                                                            form.mutators.setBooleanField(
                                                                {
                                                                    field: `permissions.${perm.permission}`,
                                                                    value: event
                                                                        .target
                                                                        .checked,
                                                                },
                                                            ),
                                                        );
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography variant="h2">
                                                    {t(
                                                        `permissionEntities.${category}`,
                                                    )}
                                                </Typography>
                                            }
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGrid
                                            fullWidth
                                            columnAmount={3}
                                            rowHeight="20px"
                                            padding="10px 0">
                                            {permissions.map(
                                                ({ permission, value }) => (
                                                    <CheckboxField
                                                        disabled={
                                                            !allowedToEdit
                                                        }
                                                        key={permission}
                                                        field={`permissions.${permission}`}
                                                        label={`permissionActions.${value}`}
                                                    />
                                                ),
                                            )}
                                        </FormGrid>
                                    </AccordionDetails>
                                </StyledAccordion>
                            ),
                        )}
                        {!groupId && accessToPages[Permissions.GROUP_CREATE] ? (
                            <FormButtonGroup>
                                <ButtonComponent
                                    handleClick={handleSubmit}
                                    text={t('create')}
                                    isDisabled={
                                        isEqual(initialValues, values) ||
                                        !valid ||
                                        isEqual(submittedValues, values) ||
                                        Object.keys(values.permissions).filter(
                                            key => values.permissions[key],
                                        ).length < 1 ||
                                        loading
                                    }
                                />
                            </FormButtonGroup>
                        ) : (
                            <FormButtonGroup>
                                {allowedToDelete && (
                                    <ButtonComponent
                                        handleClick={handleClickDelete}
                                        text={t('delete')}
                                        startIcon={<DeleteIcon />}
                                        isDisabled={loading}
                                    />
                                )}
                                {allowedToEdit && (
                                    <ButtonComponent
                                        handleClick={handleSubmit}
                                        text={t('update')}
                                        isDisabled={
                                            loading ||
                                            isEqual(initialValues, values) ||
                                            isEqual(submittedValues, values) ||
                                            !valid ||
                                            Object.keys(
                                                values.permissions,
                                            ).filter(
                                                key => values.permissions[key],
                                            ).length < 1
                                        }
                                        startIcon={<SaveIcon />}
                                    />
                                )}
                            </FormButtonGroup>
                        )}
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
