import React from 'react';
import { createStyles, Link, Theme, withStyles } from '@material-ui/core';
import { colors } from 'themes/colors';
import { Variant } from '@material-ui/core/styles/createTypography';
import { linkForRouterRegexp, linkRegexp } from 'tools/validationRules';

interface IButtonInterface {
    text: string;
    children?: React.ReactNode;
    handleClick?: (e: any) => void;
    variant?: Variant;
    href?: string;
    target?: string;
}

const StyledLink = withStyles((theme: Theme) =>
    createStyles({
        underlineNone: {
            position: 'relative',
            color: colors.black,
            display: 'inline-flex',
            '&:hover, &:active': {
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: '-3px',
                    left: 0,
                    height: '3px',
                    width: '100%',
                    background: `linear-gradient(left, ${colors.white} 0%, ${theme.palette.secondary.main} 100%)`,
                    borderRadius: '1px',
                },
            },
        },
    }),
)(Link);

export const LinkComponent: React.FC<IButtonInterface> = ({
    handleClick,
    text,
    children,
    variant = 'body1',
    href,
    target = '_self',
}: IButtonInterface) => {
    const cleanedHref = React.useMemo(
        () =>
            href &&
            (linkRegexp.test(href) || linkForRouterRegexp.test(href)
                ? href
                : ''),
        [href],
    );
    return (
        <StyledLink
            target={target}
            href={cleanedHref}
            onClick={handleClick}
            variant={variant}
            underline="none">
            {children}
            {text}
        </StyledLink>
    );
};
