import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid, SelectComponent } from 'components/common';
import { FilterTypes, BookingStatus } from 'ducks/booking/types';
import { selectFilters } from 'ducks/booking/selectors';
import { resetFilters, setFilter } from 'ducks/booking';
import { useParams } from 'react-router';
import { DateTimeInput } from '../../common/DateTimeInput';
import moment, { Moment } from 'moment';
import { getDisabledTime } from 'tools/date';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';

export const Toolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { vehicleId } = useParams<{ vehicleId: string }>();

    const { statuses, startTime, endTime } = useSelector(selectFilters);

    const bookingStatusOptions = useMemo(() => {
        return [
            {
                label: t('vehicles.statuses.booked'),
                value: BookingStatus.BOOKED,
            },
            {
                label: t('vehicles.statuses.canceled'),
                value: BookingStatus.CANCELED,
            },
            {
                label: t('vehicles.statuses.expired'),
                value: BookingStatus.EXPIRED,
            },
            {
                label: t('vehicles.statuses.completed'),
                value: BookingStatus.COMPLETED,
            },
        ];
    }, [t]);

    const isEndTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                min: moment(startTime).startOf('day'),
                max: moment().endOf('day'),
            }),
        [startTime],
    );

    const isStartTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                max: moment().endOf('day'),
            }),
        [],
    );

    const handleChangeTime = React.useCallback(
        (e: Moment | string, filterType: FilterTypes) => {
            const filterValue =
                filterType === FilterTypes.StartTime
                    ? moment(e).startOf('day').toISOString()
                    : moment(e).endOf('day').toISOString();
            return dispatch(
                setFilter({
                    filterType: filterType,
                    value: filterValue,
                }),
            );
        },
        [dispatch],
    );

    const handleChangeFilter = (
        e: React.ChangeEvent<HTMLInputElement>,
        filterType: FilterTypes,
    ) => {
        dispatch(
            setFilter({
                filterType,
                value: e.target.value,
            }),
        );
    };

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        dispatch(
            setFilter({
                filterType: FilterTypes.VehicleId,
                value: vehicleId,
            }),
        );
    }, [dispatch, vehicleId]);

    return (
        <FormGrid columnAmount={8} rowHeight="20px" padding="10px 0">
            <DateTimeInput
                id="startDate"
                value={startTime || ''}
                timeFormat={false}
                onChange={e => handleChangeTime(e, FilterTypes.StartTime)}
                label={t('vehicles.startDate')}
                getDisabledTime={isStartTimeDisabled}
            />
            <DateTimeInput
                id="endDate"
                value={endTime || ''}
                timeFormat={false}
                onChange={e => handleChangeTime(e, FilterTypes.EndTime)}
                label={t('vehicles.endDate')}
                getDisabledTime={isEndTimeDisabled}
            />
            <SelectComponent
                multiple
                label={t('vehicles.status')}
                onChange={e => handleChangeFilter(e, FilterTypes.Statuses)}
                value={statuses || []}
                options={bookingStatusOptions}
            />
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};

export default Toolbar;
