import { RootState } from '../root';
import { createSelector } from '@reduxjs/toolkit';

export const selectEventIds = (state: RootState) => state.event.eventIds;

export const selectEventsById = (state: RootState) => state.event.eventsById;

export const selectFilters = (state: RootState) => state.event.filters;

export const selectSort = (state: RootState) => state.event.sort;

export const selectIsLoading = (state: RootState) => state.event.loading;

export const selectEventError = (state: RootState) => state.event.error;

export const selectPagination = (state: RootState) => state.event.pagination;

export const selectEventsTableData = createSelector(
    selectEventIds,
    selectEventsById,
    (eventIds, eventsById) =>
        eventIds.map(id => ({
            ...eventsById[id],
        })),
);
