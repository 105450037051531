import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    fetchGroups,
    fetchGroupsError,
    fetchGroupsSuccess,
    setSort,
    setPage,
    fetchPermissions,
    fetchPermissionsError,
    fetchPermissionsSuccess,
    fetchGroup,
    fetchGroupError,
    fetchGroupSuccess,
    createGroupError,
    createGroupSuccess,
    createGroup,
    mapByCategory,
    updateGroup,
    updateGroupSuccess,
    updateGroupError,
    deleteGroup,
    deleteGroupSuccess,
    deleteGroupError,
    fetchAllGroups,
    fetchAllGroupsSuccess,
    fetchAllGroupsError,
} from './index';
import { Pagination, Sort } from '../common/types';
import { mapById, mapPagination, mapSorting } from '../common/mappers';
import { mapFetchedPagination } from '../common/pagination';
import { selectPagination, selectSort } from './selectors';
import {
    getGroup,
    getGroups,
    getPermissions,
    postGroup,
    putGroup,
    deleteGroupService,
    getAllGroups,
} from './service';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { FetchedGroup, FetchedGroups, Group, Permissions } from './types';
import { fetchMyUser } from '../auth';

function* fetchGroupsSaga() {
    try {
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: FetchedGroups = yield call(getGroups, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
        });
        const { allIds, byId } = mapById(data.content);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchGroupsSuccess({
                groupIds: allIds,
                groupsById: byId,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchGroupsError({ error: (error as Error)?.message }));
    }
}

function* fetchPermissionsSaga() {
    try {
        const data: Permissions[] = yield call(getPermissions);
        yield put(fetchPermissionsSuccess(mapByCategory(data)));
    } catch (error) {
        yield put(fetchPermissionsError());
    }
}

function* createGroupSaga({ payload }: PayloadAction<Group>) {
    try {
        yield call(postGroup, payload);
        yield put(createGroupSuccess());
        yield put(push(routes.GROUPS));
    } catch (error) {
        yield put(createGroupError({ error: (error as Error)?.message }));
    }
}

function* fetchGroupSaga({ payload }: PayloadAction<number>) {
    try {
        const data: FetchedGroup = yield call(getGroup, payload);
        yield put(fetchGroupSuccess(data.group));
    } catch (error) {
        yield put(fetchGroupError({ error: (error as Error)?.message }));
    }
}

function* updateGroupSaga({ payload }: PayloadAction<Group>) {
    try {
        yield call(putGroup, payload);
        yield put(updateGroupSuccess());
        yield put(fetchMyUser());
        yield put(push(routes.GROUPS));
    } catch (error) {
        yield put(updateGroupError({ error: (error as Error)?.message }));
    }
}

function* deleteGroupSaga({ payload }: PayloadAction<number>) {
    try {
        yield call(deleteGroupService, payload);
        yield put(deleteGroupSuccess());
        yield put(push(routes.GROUPS));
    } catch (error) {
        yield put(deleteGroupError({ error: (error as Error)?.message }));
    }
}
function* fetchAllGroupsSaga() {
    try {
        const data: { id: number }[] = yield call(getAllGroups);
        const { allIds, byId } = mapById(data);
        yield put(
            fetchAllGroupsSuccess({
                allIds,
                byId,
            }),
        );
    } catch (error) {
        yield put(fetchAllGroupsError({ error: (error as Error)?.message }));
    }
}
export default function* groupSagas() {
    yield takeLatest(fetchGroups.type, fetchGroupsSaga);
    yield takeLatest(setSort.type, fetchGroupsSaga);
    yield takeLatest(setPage.type, fetchGroupsSaga);
    yield takeLatest(fetchPermissions.type, fetchPermissionsSaga);
    yield takeLatest(createGroup.type, createGroupSaga);
    yield takeLatest(fetchGroup.type, fetchGroupSaga);
    yield takeLatest(updateGroup.type, updateGroupSaga);
    yield takeLatest(deleteGroup.type, deleteGroupSaga);
    yield takeLatest(fetchAllGroups.type, fetchAllGroupsSaga);
}
