import i18next from 'i18next';
import { FieldState } from 'final-form';
import { ServicesById, UsageTypes } from '../ducks/services/types';
import { StationTypes } from 'ducks/stations/types';

export const required = (value: string): string =>
    value != null && value.length !== 0
        ? ''
        : i18next.t('errors.fieldRequired');

export const requiredNumber = (value: string | number): string =>
    Number(value) ? '' : i18next.t('errors.fieldRequired');

export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string): string =>
    emailRegex.test(email) ? '' : i18next.t('errors.emailFormat');

export const alphaNumericRegex = /^[0-9a-zA-Z]+$/;

export const isValidStationIdFormat = (value: string): string =>
    alphaNumericRegex.test(value) ? '' : i18next.t('errors.stationIdFormat');

export const isValidVehicleAdapterFormat = (value: string): string =>
    alphaNumericRegex.test(value)
        ? ''
        : i18next.t('errors.vehicleAdapterFormat');

export const isStationIdRequired = (value: string, all: any): string =>
    all?.stationType === StationTypes.Simple ? '' : required(value);

export const serialRegex = /^[0-9a-zA-Z]{4,20}$/;

export const isValidSerial = (serial: string): string =>
    serialRegex.test(serial) ? '' : i18next.t('errors.serial');

export const plateTwoThreeTwoRegex =
    /^[a-zA-Z0-9]{2}-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{2}$/;
export const plateOneThreeThreeRegex =
    /^[a-zA-Z0-9]-[a-zA-Z0-9]{3}-[a-zA-Z0-9]{3}$/;
export const plateOneThreeThreeWithSpaceRegex =
    /^[a-zA-Z0-9]-[a-zA-Z0-9]{3} [a-zA-Z0-9]{3}$/;

export const isValidPlate = (plate: string): string =>
    plateTwoThreeTwoRegex.test(plate) ||
    plateOneThreeThreeRegex.test(plate) ||
    plateOneThreeThreeWithSpaceRegex.test(plate)
        ? ''
        : i18next.t('errors.plate');

export const zipCodeRegexp = /^\d{5}$/;

export const isValidZipCode = (code: string): string =>
    zipCodeRegexp.test(code) ? '' : i18next.t('errors.zipCode');

export const siretRegexp = /^\d{14}$/;

export const isValidSiret = (code: string): string =>
    siretRegexp.test(code) ? '' : i18next.t('errors.siret');

export const domainRegExp =
    /(^[*][a-zA-Z0-9_.+-]+|^[*])@([a-zA-Z0-9-])+\.+[a-zA-Z0-9]{2,}$/;

export const isValidDomains = (domains: string): string =>
    domains &&
    domains
        .split(' ')
        .join('')
        .split(',')
        .some(domain => !domainRegExp.test(domain))
        ? i18next.t('errors.domain')
        : '';

export const linkRegexp =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

export const urlWithPossiblePortRegexp =
    /https?:\/\/(?:w{1,3}\.)?[^\s.]+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))/;

export const httpsRegexp =
    /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

export const linkForRouterRegexp = /^[a-zA-Z0-9/]{1,256}/;

export const isLink = (link: string) =>
    linkRegexp.test(link) || !link ? '' : i18next.t('errors.wrongLinkFormat');

export const blobLink =
    /^blob:https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\/[-a-zA-Z0-9@:%._+~#=]{1,256}/;

const timezoneRegex =
    /^[+-]([0][0-9]:[0-9]{2}|[1][0-7]:[0-9]{2}|[1][8]:[0]{2})\b$/;

const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export const isUrl = (string: string) =>
    urlWithPossiblePortRegexp.test(string)
        ? ''
        : i18next.t('errors.wrongUrlFormat');

export const isTimeZone = (timezone: string): string =>
    timezoneRegex.test(timezone) ? '' : i18next.t('errors.wrongTimezone');

export const isVersion = (version: string): string =>
    /^\d{1,2}(\.\d{1,2}){0,2}$/.test(version)
        ? ''
        : i18next.t('errors.wrongVersionFormat');

export const fieldLength = (value: string, min: number, max: number): string =>
    value && value.length >= min && value.length <= max
        ? ''
        : i18next.t('errors.length', { min, max });

export const minValue = (value: string | number, min: number): string =>
    Number(value) >= min ? '' : i18next.t('errors.minValue', { min });

export const maxValue = (value: string, max: number): string =>
    Number(value) <= max ? '' : i18next.t('errors.maxValue', { max });

export const isNumber = (value: string) =>
    !isNaN(Number(value)) ? '' : i18next.t('errors.invalidNumberValue');

export const isTime = (time: string) =>
    timeRegex.test(time) ? '' : i18next.t('errors.incorrectTimeFormat');

export const isExpiredDate = (
    value: string,
    all: any,
    meta: FieldState<string>,
) =>
    !value ||
    !meta.modified ||
    new Date(value).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
        ? ''
        : i18next.t('errors.dateExpired');

export const isExpiredTime = (
    value: Date,
    all: any,
    meta: FieldState<string>,
) =>
    !meta.modified || new Date(value) >= new Date()
        ? ''
        : i18next.t('errors.dateExpired');

export const composeValidators =
    (...validators: any[]) =>
    (
        value: string,
        // eslint-disable-next-line @typescript-eslint/ban-types
        allValues: object,
        meta?: FieldState<string>,
    ): any[] =>
        validators.reduce(
            (error, validator) => error || validator(value, allValues, meta),
            undefined,
        );

export type ErrorIntervalFields = {
    endTime: string;
    startTime: string;
};

export const checkIfOverlap = (
    interval1: ErrorIntervalFields,
    interval2: ErrorIntervalFields,
) =>
    !(
        interval1.endTime <= interval2.startTime ||
        interval2.endTime <= interval1.startTime
    );

const phoneNumberRegex = /^\+?[0-9]{6,15}$/;

export const isPhoneNumber = (phone: string) =>
    phoneNumberRegex.test(phone) || !phone ? '' : i18next.t('errors.phone');

export const validateServices = (
    services: number[],
    servicesById: ServicesById,
) =>
    services &&
    services.filter(
        service => servicesById[service]?.type === UsageTypes.LONG_TERM_USAGE,
    ).length > 1
        ? i18next.t('errors.multipleLLD')
        : '';

export const lettersAndNumbersRegex = /^[0-9a-zA-Z]*$/;

export const isContainsLettersAndNumbers = (
    code: string,
    errorMessage: string,
): string => (lettersAndNumbersRegex.test(code) ? '' : errorMessage);

const passwordRegex =
    /^(?=.*[A-Z])(?=.*\d)(?=.*[ \\!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d \\!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

export const isValidPassword = (password: string) =>
    passwordRegex.test(password) || !password
        ? ''
        : i18next.t('myProfile.newPasswordError');

export const lockQrCodeRegex = /^[0-9A-Z]{4,10}$/;

export const isValidLockQrCode = (lockQrCode: string): string =>
    lockQrCodeRegex.test(lockQrCode) || !lockQrCode
        ? ''
        : i18next.t('errors.lockQrCode');

export const bikeOrVaeSerialRegex = /^[0-9]{7}$/;

export const isValidBikeOrVaeSerial = (serial: string): string =>
    bikeOrVaeSerialRegex.test(serial)
        ? ''
        : i18next.t('errors.bikeOrVaeSerial');

export const phoneCallPriceRegex = /^\d+\.\d+/;
export const positiveNumberRegex = /^\d+/;

export const isPhoneCallPrice = (price: string): string =>
    !price || phoneCallPriceRegex.test(price) || positiveNumberRegex.test(price)
        ? ''
        : i18next.t('errors.phoneCallPrice');

export const isCorrectIframeHtml = (html: string): string => {
    if (!html) return '';
    const iframeElement = new DOMParser().parseFromString(html, 'text/html')
        .body.firstChild as HTMLImageElement | undefined;
    if (iframeElement?.tagName !== 'IFRAME')
        return i18next.t('errors.wrongIframeFormat');

    return httpsRegexp.test(iframeElement?.src)
        ? ''
        : i18next.t('errors.wrongIframeSrc');
};
