import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { colors } from '../../../themes/colors';
import { Menu, TextField } from '@material-ui/core';

export const StyledMenu = withStyles((theme: Theme) =>
    createStyles({
        paper: {
            border: `2px solid ${theme.palette.secondary.main}`,
            borderRadius: '25px',
            padding: '20px',
            backgroundColor: colors.white,
            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
            minWidth: 'fit-content',
        },
    }),
)(Menu);
export const StyledSelect = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiInput-underline': {
                fontSize: theme.typography.h2.fontSize,
                fontFamily: theme.typography.h2.fontFamily,
                fontWeight: theme.typography.h2.fontWeight,
                lineHeight: '1.3',
                '&::before': {
                    content: 'none',
                },
                '&::after': {
                    content: 'none',
                },
                '& .MuiSelect-selectMenu': {
                    padding: '0 24px 0 0',
                },
            },
        },
    }),
)(TextField);
