import {
    composeValidators,
    isExpiredDate,
    required,
} from 'tools/validationRules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { useParams } from 'react-router';
import { DateTimeField } from 'components/common/Form/CommonFields/DateTimeField';
import moment from 'moment';

export const CgauVersionField: React.FC = () => {
    const { t } = useTranslation();
    const accessToPages = useSelector(selectAccessToPages);
    const { id } = useParams<{ id?: string }>();
    const allowedToEdit = id
        ? accessToPages[Permissions.SERVICE_UPDATE]
        : accessToPages[Permissions.SERVICE_CREATE];
    return (
        <DateTimeField
            id="cgauVersion"
            name="cgauVersion"
            validators={composeValidators(required, isExpiredDate)}
            disabled={!allowedToEdit}
            label={t('serviceList.cgauVersion')}
            min={moment().startOf('day')}
            timeFormat={false}
            isRequired
        />
    );
};
