import { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/groups';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('groups.id'),
            field: 'id',
            sorting: false,
            cellStyle: {
                width: 20,
            },
        },
        {
            title: t('groups.name'),
            field: 'name',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'name',
                    }),
                ),
            cellStyle: {
                width: `calc((100% - 0px) / 3)`,
            },
            headerStyle: {
                width: `calc((100% - 0px) / 3)`,
            },
        },
        {
            title: t('groups.description'),
            field: 'description',
            sorting: false,
            cellStyle: {
                width: `calc((100% - 0px) / 2)`,
            },
            headerStyle: {
                width: `calc((100% - 0px) / 2)`,
            },
        },
    ];
};
