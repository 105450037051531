import { createStyles, Theme, withStyles } from '@material-ui/core';
import { colors } from 'themes/colors';
import Tab from '@material-ui/core/Tab';

export const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderRight: `1px solid`,
            borderLeft: `1px solid`,
            borderColor: colors.white,
        },
        selected: {
            backgroundColor: colors.white,
            color: theme.palette.primary.main,
            border: `2px solid`,
            borderColor: theme.palette.primary.main,
            borderBottom: 'none',
            overflow: 'visible',
            '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '-2px',
                left: 0,
                height: '3px',
                width: '100%',
                background: colors.white,
            },
        },
    }),
)(Tab);
