import { Accumulator, Pagination, Sort } from './types';

export const mapSorting = (sort: Sort) => {
    return { sort: sort.value + ',' + sort.direction };
};

export const mapPagination = (pagination: Pagination) => {
    return {
        size: pagination.size,
        page: pagination.number,
    };
};

export const mapFilters = <T extends Record<string, unknown>>(filters: T) => {
    return (
        Object.entries(filters)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([_, value]) => value)
            .reduce((res, [key, value]) => {
                return { ...res, [key]: value };
            }, {})
    );
};

export const mapById = <T extends { id: number }>(mappedValue: T[]) =>
    mappedValue?.reduce<Accumulator<T>>(
        (acc, value) => {
            return {
                byId: {
                    ...acc.byId,
                    [value.id]: value,
                },
                allIds: [...acc.allIds, value.id],
            };
        },
        { allIds: [], byId: {} },
    ) || { allIds: [], byId: {} };
