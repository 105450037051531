import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ButtonComponent,
    CheckboxField,
    FormGrid,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from '../../common';
import { fetchAllServices } from 'ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { RootState } from 'ducks/root';
import { Prompt, useParams } from 'react-router';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { ImportZonesComponent } from './ImportZone';
import {
    createZone,
    deleteZone,
    fetchZone,
    resetCurrentZone,
    selectImportedZones,
    selectIsLoading,
    selectZoneError,
    selectZoneHasUnsavedChanges,
    setZoneHasUnsavedChanges,
    updateZone,
    resetError,
} from 'ducks/zones';
import { MapComponent } from '../../common/Map';
import { Polygon } from '@react-google-maps/api';
import { colors } from 'themes/colors';
import { Zone } from 'ducks/zones/types';
import { ColorField } from '../../common/Form/CommonFields/ColorField';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { selectCurrentZone } from 'ducks/zones/selectors';
import { validations } from './validations';
import { UsageTypes } from 'ducks/services/types';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';

interface ZoneForm extends Zone {
    zone: string;
}
export const CreateZoneComponent: React.FC = () => {
    const { zoneId } = useParams<{ zoneId?: string }>();
    const currentZone = useSelector(selectCurrentZone);
    const zoneHasUnsavedChanges = useSelector(selectZoneHasUnsavedChanges);
    const importedZones = useSelector(selectImportedZones);
    const error = useSelector(selectZoneError);
    const loading = useSelector(selectIsLoading);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit =
        accessToPages[
            zoneId ? Permissions.ZONE_UPDATE : Permissions.ZONE_CREATE
        ];
    const allowedToDelete = accessToPages[Permissions.ZONE_DELETE];
    const handleConfirm = React.useCallback(
        (values: ZoneForm) => {
            zoneId
                ? dispatch(
                      updateZone({
                          ...values,
                          border: importedZones[values.zone]
                              ? importedZones[values.zone]
                              : values.border,
                      }),
                  )
                : dispatch(
                      createZone({
                          ...values,
                          border: importedZones[values.zone],
                      }),
                  );
        },
        [dispatch, importedZones, zoneId],
    );

    React.useEffect(() => {
        resetError();
        if (zoneId) {
            dispatch(fetchZone(zoneId));
        }
        dispatch(
            fetchAllServices({
                onlyEnabled: false,
                type: UsageTypes.SHORT_TERM_USAGE,
            }),
        );
        return () => {
            dispatch(resetCurrentZone());
            dispatch(setZoneHasUnsavedChanges(false));
        };
    }, [dispatch, zoneId]);

    const redirectToZones = React.useCallback(
        () => dispatch(push(routes.ZONES)),
        [dispatch],
    );

    const { allServicesById, allServiceIds } = useSelector(
        (state: RootState) => state.service,
    );

    const handleDelete = React.useCallback(() => {
        dispatch(deleteZone(zoneId));
        dispatch(closeModal());
    }, [dispatch, zoneId]);

    const handleClickDelete = React.useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: handleDelete,
                    message: 'zones.deleteMessage',
                },
            }),
        );
    }, [dispatch, handleDelete]);

    const initialValues = React.useMemo(
        () =>
            zoneId
                ? currentZone
                : {
                      services: [],
                      border: '',
                      borderColor: colors.default,
                      transparency: 50,
                      isForbidden: false,
                  },
        [zoneId, currentZone],
    );

    const serviceOptions = React.useMemo(
        () =>
            allServiceIds.map(id => ({
                value: id,
                label: allServicesById[id].name,
            })),
        [allServiceIds, allServicesById],
    );

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setZoneHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    useNotificationOnPageLeave(zoneHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 25%">
            {loading && <LoaderComponent />}
            <Prompt
                when={zoneHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={handleConfirm}
                initialValues={initialValues}
                render={({ handleSubmit, valid, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToZones}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {t(
                                        zoneId
                                            ? 'zones.updateZone'
                                            : 'zones.addZone',
                                    )}
                                </p>
                            </Typography>
                        </Box>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <FormGrid columnAmount={2} padding="0px 20px">
                            <TextField
                                disabled={!allowedToEdit}
                                name="name"
                                validators={validations.name}
                                label={t('zones.name')}
                                isRequired
                            />
                            <TextField
                                disabled={!allowedToEdit}
                                name="description"
                                validators={validations.description}
                                label={t('zones.description')}
                            />
                            <ColorField
                                disabled={!allowedToEdit}
                                name="fillColor"
                                label={t('zones.fillColor')}
                            />
                            <ColorField
                                disabled={!allowedToEdit}
                                name="borderColor"
                                validators={validations.borderColor}
                                isRequired
                                label={t('zones.borderColor')}
                            />
                            <TextField
                                disabled={!allowedToEdit}
                                name="transparency"
                                validators={validations.transparency}
                                isRequired
                                type="number"
                                label={t('zones.transparency')}
                                InputProps={{
                                    inputProps: { min: 0, max: 100 },
                                }}
                            />
                            <SelectField
                                disabled={!allowedToEdit}
                                multiple
                                name="services"
                                label={t('zones.services')}
                                options={serviceOptions}
                            />
                            <CheckboxField
                                disabled={!allowedToEdit}
                                field="isForbidden"
                                label="zones.forbiddenZone"
                            />
                        </FormGrid>
                        {allowedToEdit && <ImportZonesComponent />}
                        <Box m="10px 20px 20px">
                            {(importedZones[values?.zone] ||
                                currentZone?.border) && (
                                <MapComponent
                                    boundCoordinates={
                                        importedZones[values.zone] ||
                                        currentZone?.border
                                    }>
                                    <Polygon
                                        path={
                                            importedZones[values.zone] ||
                                            currentZone?.border
                                        }
                                        options={{
                                            fillOpacity: values.fillColor
                                                ? 1 - values.transparency / 100
                                                : 0,
                                            strokeColor: values.borderColor,
                                            fillColor: values.fillColor,
                                        }}
                                    />
                                </MapComponent>
                            )}
                        </Box>
                        {!zoneId ? (
                            allowedToEdit && (
                                <FormButtonGroup>
                                    <ButtonComponent
                                        handleClick={handleSubmit}
                                        text={t('create')}
                                        isDisabled={
                                            loading ||
                                            !zoneHasUnsavedChanges ||
                                            !valid ||
                                            !importedZones[values.zone]
                                        }
                                    />
                                </FormButtonGroup>
                            )
                        ) : (
                            <FormButtonGroup>
                                {allowedToDelete && (
                                    <ButtonComponent
                                        handleClick={handleClickDelete}
                                        text={t('delete')}
                                        startIcon={<DeleteIcon />}
                                        isDisabled={loading}
                                    />
                                )}
                                {allowedToEdit && (
                                    <ButtonComponent
                                        handleClick={handleSubmit}
                                        text={t('update')}
                                        isDisabled={
                                            loading ||
                                            !valid ||
                                            !zoneHasUnsavedChanges
                                        }
                                        startIcon={<SaveIcon />}
                                    />
                                )}
                            </FormButtonGroup>
                        )}
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
