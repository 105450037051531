import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ScooterTelemetryEventsType } from 'ducks/vehicleTelemetryEvents/types';
import { selectTelemetryEventsFiltersError } from 'ducks/vehicleTelemetryEvents/selectors';
import { Toolbar } from '../Common/Toolbar';

export const ScooterToolbar: React.FC = () => {
    const { t } = useTranslation();

    const filtersError = useSelector(selectTelemetryEventsFiltersError);

    const messageCodeOptions = React.useMemo<
        { label: string; value: ScooterTelemetryEventsType | '' }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...Object.values(ScooterTelemetryEventsType).map(type => ({
                label: t(`scooterTelemetryEvents.types.${type}`),
                value: type,
            })),
        ],
        [t],
    );

    return (
        <Toolbar
            messageCodeOptions={messageCodeOptions}
            filtersError={String(filtersError)}
            type="scooter"
        />
    );
};
