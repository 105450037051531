import { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/booking';
import { FetchedVehicleBooking } from 'ducks/booking/types';

enum DateFields {
    Created = 'created',
    End = 'end',
}

const renderBookingDate =
    (fieldName: DateFields) => (rowProps: FetchedVehicleBooking) => {
        const field = rowProps.booking[fieldName];
        return field ? new Date(field).toLocaleString() : '-';
    };

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('vehicles.id'),
            field: 'id',
            sorting: false,
            render: (rowProps: FetchedVehicleBooking) => rowProps.booking.id,
        },
        {
            title: t('vehicles.status'),
            field: 'userName',
            sorting: false,
            render: (rowProps: FetchedVehicleBooking) =>
                t(`vehicles.statuses.${rowProps.booking.status.toLowerCase()}`),
        },
        {
            title: t('vehicles.startDate'),
            field: 'startTime',
            render: renderBookingDate(DateFields.Created),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'startTime',
                    }),
                ),
        },
        {
            title: t('vehicles.endDate'),
            field: 'endTime',
            render: renderBookingDate(DateFields.End),
            sorting: false,
        },
        {
            title: t('vehicles.userId'),
            field: 'userId',
            sorting: false,
            render: (rowProps: FetchedVehicleBooking) => rowProps.endUser.id,
        },
        {
            title: t('vehicles.email'),
            field: 'email',
            sorting: false,
            render: (rowProps: FetchedVehicleBooking) => rowProps.profile.email,
        },
    ];
};
