import { call, put, select, takeLatest, debounce } from 'redux-saga/effects';
import {
    fetchVehicleTelemetryEvents,
    selectFilters,
    selectSort,
    selectPagination,
    fetchVehicleTelemetryEventsSuccess,
    fetchVehicleTelemetryEventsError,
    setFilter,
    setPage,
    setSort,
    resetFilters,
} from './index';
import { Pagination, Sort } from '../common/types';
import { mapFilters, mapPagination, mapSorting } from '../common/mappers';
import { mapFetchedPagination } from '../common/pagination';
import {
    Filters,
    ScooterTelemetryEventsType,
    TelemetryEventsResponse,
} from './types';
import { getVehicleTelemetryEvents } from './service';
import { selectCurrentVehicle } from '../vehicles/common';
import { selectTelemetryEventsFiltersError } from './selectors';
import { mapFetchedTelemetryEvents } from './mapper';
import { Vehicle, VehicleTypes } from '../vehicles/common/types';

function* fetchVehicleTelemetryEventsSaga() {
    try {
        const currentVehicle: Vehicle = yield select(selectCurrentVehicle);
        const { id: vehicleId } = currentVehicle;
        const filtersError: string = yield select(
            selectTelemetryEventsFiltersError,
        );
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);

        if (filtersError && currentVehicle.type === VehicleTypes.SCOOTER) {
            yield put(
                fetchVehicleTelemetryEventsError({ error: filtersError }),
            );
            return;
        }

        const data: TelemetryEventsResponse<ScooterTelemetryEventsType> =
            yield call(
                getVehicleTelemetryEvents,
                {
                    ...mapSorting(sort),
                    ...mapPagination(pagination),
                    ...mapFilters(filters),
                },
                vehicleId,
            );
        const { telemetryEventIds, telemetryEventsById } =
            mapFetchedTelemetryEvents(data);
        const fetchedPagination = mapFetchedPagination(data);
        yield put(
            fetchVehicleTelemetryEventsSuccess({
                telemetryEventIds,
                telemetryEventsById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(
            fetchVehicleTelemetryEventsError({
                error: (error as Error)?.message,
            }),
        );
    }
}

function* changeFiltersWatcher() {
    yield put(fetchVehicleTelemetryEvents());
}

export default function* vehicleTelemetryEventsSagas() {
    yield debounce(
        300,
        fetchVehicleTelemetryEvents.type,
        fetchVehicleTelemetryEventsSaga,
    );
    yield takeLatest(setFilter.type, changeFiltersWatcher);
    yield takeLatest(resetFilters.type, changeFiltersWatcher);
    yield takeLatest(setPage.type, changeFiltersWatcher);
    yield takeLatest(setSort.type, changeFiltersWatcher);
}
