import { useField } from 'react-final-form';

import { InputComponent } from '../../Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValidator } from 'final-form';
import { InputProps } from '@material-ui/core';
interface FieldProps {
    name: string;
    label: string;
    validators?: FieldValidator<string>;
    type?: string;
    InputProps?: InputProps;
    disabled?: boolean;
    placeholder?: string;
    multiline?: boolean;
    rows?: number;
    withoutCircleIcon?: boolean;
    id?: string;
    isRequired?: boolean;
}
export const TextField: React.FC<FieldProps> = ({
    name,
    label,
    validators,
    type = 'text',
    disabled,
    InputProps,
    placeholder,
    multiline,
    rows,
    withoutCircleIcon,
    id,
    isRequired,
}: FieldProps) => {
    const { t } = useTranslation();
    const {
        input,
        meta: { error, touched, valid, submitFailed },
    } = useField(name, {
        validate: validators,
    });
    return (
        <InputComponent
            {...input}
            disabled={disabled}
            InputProps={InputProps}
            hasError={!!((error && touched) || (error && submitFailed))}
            type={type}
            label={`${t(label)}${isRequired ? '*' : ''}`}
            valid={valid}
            id={id}
            helperText={
                (error && touched) || (error && submitFailed) ? error : ''
            }
            placeholder={placeholder}
            multiline={multiline}
            rows={rows}
            withoutCircleIcon={withoutCircleIcon}
        />
    );
};
