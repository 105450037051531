import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectField } from '../../common';
import { PartnerCallerTypes } from 'ducks/partners/types';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';

interface SelectPartnerCompaniesInterface {
    caller: PartnerCallerTypes | null;
    disabled?: boolean;
}

const SelectPartnerCompanies: React.FC<SelectPartnerCompaniesInterface> = ({
    caller,
    disabled,
}: SelectPartnerCompaniesInterface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const wrongCaller = caller !== PartnerCallerTypes.MOBILE;
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const companyOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );

    useEffect(() => {
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    return (
        <SelectField
            name="companies"
            label={t('partners.companies')}
            multiple
            options={companyOptions}
            disabled={wrongCaller || disabled}
        />
    );
};

const Component = React.memo(SelectPartnerCompanies);
Component.displayName = 'SelectPartnerCompanies';

export default Component;
