import { createSlice } from '@reduxjs/toolkit';
import { PushState } from './index';

export const initialState: PushState = {
    loading: false,
    error: '',
    pushHasUnsavedChanges: false,
};

const pushesSlice = createSlice({
    name: 'push',
    initialState,
    reducers: {
        sendPush: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: push => ({
                payload: push,
            }),
        },
        sendPushSuccess(state) {
            state.pushHasUnsavedChanges = false;
            state.loading = false;
        },
        sendPushError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setPushHasUnsavedChanges(state, action) {
            state.pushHasUnsavedChanges = action.payload;
        },
        resetError(state) {
            state.error = '';
        },
    },
});
export const {
    sendPush,
    sendPushError,
    sendPushSuccess,
    setPushHasUnsavedChanges,
    resetError,
} = pushesSlice.actions;

export const pushReducer = pushesSlice.reducer;
