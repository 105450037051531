import Box from '@material-ui/core/Box';
import React from 'react';
import { TabsContext } from '../TabsContext';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({
    index,
    children,
}: TabPanelProps) => {
    const value = React.useContext(TabsContext);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};
