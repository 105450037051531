import { useField } from 'react-final-form';

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValidator } from 'final-form';
import { InputProps } from '@material-ui/core';
import { ColorInputComponent } from '../../ColorInput';

interface FieldProps {
    name: string;
    label: string;
    validators?: FieldValidator<string>;
    type?: string;
    InputProps?: InputProps;
    disabled?: boolean;
    isRequired?: boolean;
}
export const ColorField: React.FC<FieldProps> = ({
    name,
    label,
    validators,
    disabled,
    isRequired,
}: FieldProps) => {
    const { t } = useTranslation();
    const {
        input,
        meta: { error, modified, valid },
    } = useField(name, {
        validate: validators,
    });
    return (
        <ColorInputComponent
            {...input}
            disabled={disabled}
            hasError={!!(error && modified)}
            type="color"
            label={`${t(label)}${isRequired ? '*' : ''}`}
            valid={valid}
            helperText={error && modified ? error : ''}
        />
    );
};
