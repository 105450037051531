import { TableComponent } from '../../common';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { fetchBOUsers, selectIsLoading, setFilter } from 'ducks/boUsers';
import { selectBoUsers, selectSort } from 'ducks/boUsers/selectors';
import { getColumns } from './columns';
import { Pagination } from './Pagination';
import { Toolbar } from './Toolbar';
import { SearchToolbar } from './SearchToolbar';
import { useParams } from 'react-router';
import { FetchedBoUser, FilterTypes } from 'ducks/boUsers/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const BackofficeUsersList: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companyId } = useParams<{ companyId?: string }>();
    const { boUsersById, boUserIds } = useSelector(selectBoUsers);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);
    const columns = getColumns(t, dispatch);

    useEffect(() => {
        companyId
            ? dispatch(
                  setFilter({
                      filterType: FilterTypes.CompanyId,
                      value: companyId,
                  }),
              )
            : dispatch(fetchBOUsers());
    }, [dispatch, companyId]);

    const redirectToUser = (id: number) =>
        dispatch(push(routes.BO_USER.replace(':userId', `${id}`)));

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'visibility',
                        tooltip: t('tripList.seeDetails'),
                        onClick: (event, rowData: FetchedBoUser) =>
                            rowData && redirectToUser(rowData.user.id),
                    },
                ]}
                onRowClick={(event, rowData: FetchedBoUser | undefined) =>
                    rowData && redirectToUser(rowData.user.id)
                }
                toolbar={Toolbar}
                searchComponent={SearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={boUserIds.map(id => ({
                    ...boUsersById[id],
                }))}
                title={t('backofficeUsers.title')}
                sort={sort}
            />
        </>
    );
};
