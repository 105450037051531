import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentVehicle } from '../../../ducks/vehicles/common';
import { ThemedBoxComponent } from '../../common';
import React from 'react';
import { UpdateLock } from './index';
import { Box, IconButton, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { useTranslation } from 'react-i18next';

export const UpdateLockContainer = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const currentVehicle = useSelector(selectCurrentVehicle);
    const { vehicleId, lockId } =
        useParams<{ vehicleId: string; lockId: string }>();

    const currentLockId = vehicleId
        ? Number(currentVehicle?.lockId)
        : Number(lockId);

    const redirectToLocks = React.useCallback(
        () => dispatch(push(routes.LOCKS)),
        [dispatch],
    );

    return vehicleId ? (
        <Box textAlign="center">
            <UpdateLock lockId={currentLockId} />
        </Box>
    ) : (
        <ThemedBoxComponent m="0 25%" position="relative">
            <Box display="inline-flex">
                <Box display="flex" alignItems="center">
                    <IconButton onClick={redirectToLocks}>
                        <BackIcon />
                    </IconButton>
                </Box>
                <Typography variant="h1">
                    <p>{t('locks.updateLock')}</p>
                </Typography>
            </Box>
            <UpdateLock lockId={currentLockId} />
        </ThemedBoxComponent>
    );
};
