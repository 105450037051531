import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrips, setFilter } from 'ducks/trips';
import { TableComponent } from 'components/common/Table';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import {
    selectTrips,
    selectSort,
    selectIsLoading,
} from 'ducks/trips/selectors';
import { TripSearchToolbar } from './TripSearchToolbar';
import { TripToolbar } from './TripToolbar';
import { TripPagination } from './Pagination';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { FetchedTrip, FilterTypes } from 'ducks/trips/types';
import { useParams } from 'react-router';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const TripsList: React.FC = () => {
    const dispatch = useDispatch();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const { t } = useTranslation();
    const columns = getColumns(t, dispatch, vehicleId);
    const { tripsById, tripIds } = useSelector(selectTrips);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    React.useEffect(() => {
        if (vehicleId) {
            dispatch(
                setFilter({
                    filterType: FilterTypes.VehicleIds,
                    value: vehicleId,
                }),
            );
        }
    }, [vehicleId, dispatch]);

    useEffect(() => {
        dispatch(fetchTrips());
    }, [dispatch]);

    const redirectToTrip = (id: number) =>
        dispatch(push(routes.TRIP.replace(':tripId', `${id}`)));

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'visibility',
                        tooltip: t('tripList.seeDetails'),
                        onClick: (event, rowData: FetchedTrip) =>
                            rowData && redirectToTrip(rowData.id),
                    },
                ]}
                onRowClick={(event, rowData: FetchedTrip | undefined) =>
                    rowData && redirectToTrip(rowData.id)
                }
                toolbar={TripToolbar}
                searchComponent={TripSearchToolbar}
                columns={columns}
                pagination={TripPagination}
                data={tripIds.map(id => ({
                    ...tripsById[id],
                }))}
                title={t('tripList.title')}
                sort={sort}
            />
        </>
    );
};
