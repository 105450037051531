import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/vehicles/common';
import { LinkComponent } from '../../common';
import { routes } from '../../../constants/common';
import { VehicleStatus, Vehicle } from 'ducks/vehicles/common/types';
import { Modals, openModal } from 'ducks/ui';
import { Station } from 'ducks/stations/types';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('vehicles.id'),
            field: 'ID',
            render: (rowProps: Station) => rowProps.id,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'ID',
                    }),
                ),
        },
        {
            title: t('vehicles.serial'),
            field: 'serial',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'serial',
                    }),
                ),
        },
        {
            title: t('vehicles.type'),
            field: 'type',
            sorting: false,
        },
        {
            title: t('vehicles.lockQrCode'),
            field: 'lockQrCode',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: Vehicle) => (
                <LinkComponent
                    text={`${rowProps.lockQrCode}-${rowProps.lockStatus}`}
                    handleClick={event => {
                        event.stopPropagation();
                        window.open(
                            routes.LOCK.replace(
                                ':lockId',
                                `${rowProps.lockId}`,
                            ),
                            '_blank',
                        );
                    }}
                />
            ),
        },
        {
            title: t('vehicles.gps'),
            field: t('gps'),
            // eslint-disable-next-line react/display-name
            render: (rowProps: { lat: number; lng: number }) => (
                <LinkComponent
                    text={`${rowProps.lat || '-'}, ${rowProps.lng || '-'}`}
                    handleClick={event => {
                        event.stopPropagation();
                        dispatch(
                            openModal({
                                modalType: Modals.MAP_WITH_POINT,
                                modalProps: {
                                    position: {
                                        lat: rowProps.lat,
                                        lng: rowProps.lng,
                                    },
                                    title: t('vehicles.vehiclePosition'),
                                },
                            }),
                        );
                    }}
                />
            ),
            sorting: false,
        },
        {
            title: t('vehicles.battery'),
            field: 'batteryLevel',
            sorting: false,
            render: (rowProps: Vehicle) => rowProps.batteryLevel || '-',
        },
        {
            title: t('vehicles.lockBattery'),
            field: 'lockBatteryLevel',
            sorting: false,
            render: (rowProps: Vehicle) => rowProps.lockBatteryLevel || '-',
        },
        {
            title: t('vehicles.status'),
            field: 'status',
            // eslint-disable-next-line react/display-name
            render: (rowProps: { status: VehicleStatus }) => (
                <span>{t(`scooterStatuses.${rowProps.status}`)}</span>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'status',
                    }),
                ),
        },
        {
            title: t('vehicles.companyName'),
            field: 'companyName',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: { companyName: string; companyId: number }) => (
                <LinkComponent
                    text={rowProps.companyName}
                    handleClick={event => {
                        event.stopPropagation();
                        window.open(
                            routes.COMPANY.replace(
                                ':companyId',
                                `${rowProps.companyId}`,
                            ),
                            '_blank',
                        );
                    }}
                />
            ),
        },
        {
            title: t('bikes.stationName'),
            field: 'stationName',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: Vehicle) => (
                <LinkComponent
                    text={`${rowProps.stationName || '-'}`}
                    handleClick={event => {
                        event.stopPropagation();
                        window.open(
                            routes.STATION.replace(
                                ':stationId',
                                `${rowProps.stationId}`,
                            ),
                            '_blank',
                        );
                    }}
                />
            ),
        },
    ];
};
