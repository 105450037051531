import { Sort, Pagination } from '../common/types';
import { Languages } from '../boUsers/types';

export type ServicesById = {
    [code: number]: Service;
};

export enum FilterTypes {
    StationConnected = 'stationConnected',
    GeofenceEnabled = 'geofenceEnabled',
    BookingAllowed = 'bookingAllowed',
    PauseAllowed = 'pauseAllowed',
    DomainRecognition = 'domainRecognition',
}

export enum StringFilterTypes {
    Types = 'types',
    ServiceName = 'name',
    Company = 'companies',
    Enabled = 'enabled',
}

export enum RecipientTypes {
    B_TO_B = 'B_TO_B',
    B_TO_C = 'B_TO_C',
    B_TO_B_TO_C = 'B_TO_B_TO_C',
}
export enum UsageTypes {
    SHORT_TERM_USAGE = 'SHORT_TERM_USAGE',
    LONG_TERM_USAGE = 'LONG_TERM_USAGE',
}

export type Filters = {
    [FilterTypes.BookingAllowed]: boolean;
    [FilterTypes.GeofenceEnabled]: boolean;
    [FilterTypes.StationConnected]: boolean;
    [FilterTypes.PauseAllowed]: boolean;
    [FilterTypes.DomainRecognition]: boolean;
    [StringFilterTypes.Enabled]: string;
    [StringFilterTypes.Types]: string;
    [StringFilterTypes.ServiceName]: string;
    [StringFilterTypes.Company]: string;
};

export type ServiceState = {
    loading: boolean;
    error: string;
    serviceIds: number[];
    notLinkedServicesIds: number[];
    notLinkedServicesById: ServicesById;
    allServiceIds: number[];
    allServicesById: ServicesById;
    servicesById: ServicesById;
    currentService: Service;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
    currentServiceSchedule: Schedule[];
    serviceHasUnsavedChanges: boolean;
};

export type ServiceDescription = {
    language: Languages;
    description: string;
};

export enum DaysOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export type Schedule = {
    id?: number;
    endTime: string;
    startTime: string;
    dayOfWeek: DaysOfWeek;
};

export type ScheduleBody = {
    entries: Schedule[];
};

export interface SelectedService extends FetchedService {
    zones?: number[];
    timezone: string;
}

export type FetchedService = {
    cgauUrl: string;
    cgauVersion: string;
    created?: string;
    daySchedules?: Schedule[];
    description?: string;
    id: number;
    bookingDurations?: number[];
    isBookingAllowed: boolean;
    isEnabled: boolean;
    isGeofenceEnabled: boolean;
    isLoopEnabled: boolean;
    isMultirentAllowed: boolean;
    isAutocloseTrip: boolean;
    isPauseAllowed: boolean;
    isDomainRecognitionEnabled: boolean;
    isStationConnected: boolean;
    specificMapLayer: boolean;
    maxTripDuration: number;
    name: string;
    recipientType: RecipientTypes;
    type: UsageTypes;
    timezone: string;
    isFreeToInvitee: boolean;
    isOpenToPublic: boolean;
    descriptionTemplates: ServiceDescription[];
    isCreditCardRequired: boolean;
};

export type Company = {
    created: string;
    id: number;
    name: string;
};

export type ServiceDetails = {
    company: Company;
    service: FetchedService;
    zones: {
        created: string;
        id: number;
        isForbidden: boolean;
        name: string;
    }[];
};

export type Content = {
    company: Company;
    service: FetchedService;
};

export interface Service extends FetchedService {
    companyName: string;
}

export type ServiceResponse = {
    content: Content[];
} & Pagination;

export type Accumulator = {
    serviceIds: number[];
    servicesById: ServicesById;
};
