import { Route, Redirect, RouteProps } from 'react-router-dom';
import React from 'react';

import { routes } from '../../../constants/common';
import { useSelector } from 'react-redux';
import { RootState } from 'ducks/root';

type ICommonLayoutProps = {
    component: React.FC;
} & RouteProps;

export const AuthLayoutComponent = ({
    component: Component,
    ...restProps
}: ICommonLayoutProps) => {
    const { isLoggedIn } = useSelector((state: RootState) => state.auth);
    return isLoggedIn ? (
        <Redirect to={routes.MAIN_PAGE} />
    ) : (
        <Route {...restProps} render={props => <Component {...props} />} />
    );
};
