import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { FetchedBoUser } from 'ducks/boUsers/types';
import { setSort } from 'ducks/boUsers';
import { Box } from '@material-ui/core';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('backofficeUsers.id'),
            field: 'id',
            sorting: false,
            render: (rowProps: FetchedBoUser) => rowProps.user.id,
        },
        {
            title: t('backofficeUsers.email'),
            field: 'email',
            sorting: false,
            render: (rowProps: FetchedBoUser) => rowProps.user.email,
        },
        {
            title: t('backofficeUsers.status'),
            field: 'isEnabled',
            render: (rowProps: FetchedBoUser) =>
                rowProps.user.isEnabled ? 'Enabled' : 'Disabled',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isEnabled',
                    }),
                ),
        },
        {
            title: t('backofficeUsers.customerStatus'),
            field: 'isManager',
            sorting: false,
            render: (rowProps: FetchedBoUser) =>
                rowProps.user.isManager
                    ? t('backofficeUsers.isManager')
                    : t('backofficeUsers.notManager'),
        },
        {
            title: t('backofficeUsers.companies'),
            field: 'companies',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedBoUser) => (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center">
                    {rowProps.companies.map(({ id, name }) => (
                        <span key={id}>{name}</span>
                    ))}
                </Box>
            ),
        },
        {
            title: t('backofficeUsers.group'),
            field: 'GROUP_NAME',
            // eslint-disable-next-line react/display-name
            render: (rowProps: FetchedBoUser) => (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center">
                    {rowProps.groups.map(({ id, name }) => (
                        <span key={id}>{name}</span>
                    ))}
                </Box>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'GROUP_NAME',
                    }),
                ),
        },
    ];
};
