import { Pagination, Sort } from '../../common/types';
import { Service } from '../../services/types';
import { Station, StationTypes } from '../../stations/types';

export enum VehicleTypes {
    BIKE = 'BIKE',
    VAE = 'VAE',
    SCOOTER = 'SCOOTER',
}
export enum VehicleStatus {
    inactive = 'INACTIVE',
    bound = 'BOUND',
    deployed = 'DEPLOYED',
    booked = 'BOOKED',
    ridden = 'RIDDEN',
    paused = 'PAUSED',
    unavailable = 'UNAVAILABLE',
    broken = 'BROKEN',
    repair = 'REPAIR',
    parked = 'PARKED',
    lost = 'LOST',
    stolen = 'STOLEN',
    destroyed = 'DESTROYED',
}

export const AllowedForCreationVehicleStatus = [
    VehicleStatus.inactive,
    VehicleStatus.bound,
    VehicleStatus.deployed,
    VehicleStatus.unavailable,
    VehicleStatus.broken,
    VehicleStatus.repair,
    VehicleStatus.parked,
    VehicleStatus.lost,
    VehicleStatus.stolen,
    VehicleStatus.destroyed,
];

export const AvailableForServiceUpdateStatus = [
    VehicleStatus.unavailable,
    VehicleStatus.inactive,
    VehicleStatus.bound,
    VehicleStatus.deployed,
    VehicleStatus.broken,
    VehicleStatus.repair,
    VehicleStatus.parked,
];

export const AvailableForUnbind = [
    VehicleStatus.unavailable,
    VehicleStatus.stolen,
    VehicleStatus.broken,
];

export const AvailableForCompanyUpdateStatus = [
    VehicleStatus.parked,
    VehicleStatus.unavailable,
];

export const vehicleAvailableStatusMap = {
    [VehicleStatus.inactive]: [VehicleStatus.inactive],
    [VehicleStatus.bound]: [VehicleStatus.bound],
    [VehicleStatus.deployed]: [VehicleStatus.parked, VehicleStatus.deployed],
    [VehicleStatus.unavailable]: [
        VehicleStatus.lost,
        VehicleStatus.parked,
        VehicleStatus.deployed,
        VehicleStatus.unavailable,
    ],
    [VehicleStatus.broken]: [VehicleStatus.destroyed, VehicleStatus.broken],
    [VehicleStatus.lost]: [
        VehicleStatus.stolen,
        VehicleStatus.destroyed,
        VehicleStatus.lost,
    ],
    [VehicleStatus.repair]: [VehicleStatus.repair],
    [VehicleStatus.stolen]: [VehicleStatus.broken, VehicleStatus.stolen],
    [VehicleStatus.parked]: [VehicleStatus.parked],
    [VehicleStatus.booked]: [VehicleStatus.booked],
    [VehicleStatus.destroyed]: [VehicleStatus.destroyed],
    [VehicleStatus.booked]: [VehicleStatus.booked],
    [VehicleStatus.ridden]: [VehicleStatus.ridden, VehicleStatus.deployed],
    [VehicleStatus.paused]: [VehicleStatus.paused],
};

export type VehicleResponse = {
    content: Content[];
} & Pagination;

export type Company = {
    id: number;
    name: string;
};
export type User = {
    firstName: string;
    id: number;
    isEnabled: true;
    lastName: string;
    phone: string;
};
export type FetchedVehicle = {
    batteryLevel: number;
    connected?: boolean;
    id: number;
    lat: number;
    lng: number;
    meters: number;
    plate?: string;
    serial: string;
    status: VehicleStatus;
    type: VehicleTypes;
    totalKilometers: number;
    totalMinutes: number;
    totalOrders: number;
    lastCommunicationTime: string;
    stationType?: StationTypes;
};
export interface Vehicle extends FetchedVehicle {
    companyName: string;
    companyId: number;
    userName?: string;
    userLastName?: string;
    userId?: number | string;
    updated: string;
    servicesById: { [code: number]: Service };
    servicesAllIds: number[];
    lockQrCode?: string;
    lockStatus?: string;
    lockId?: number | string;
    lockIsClosed?: boolean | null;
    lockLastCommunication?: string;
    lockAlarmStatus?: boolean | null;
    stationId: number | string;
    stationName?: string;
    type: VehicleTypes;
    lockBatteryLevel: number | string;
    lastSync?: string;
    model?: string;
    adapter?: string;
}
export type Update = {
    updated: string;
};
export type Content = {
    company: Company;
    services: Service[];
    user?: User;
    vehicle: FetchedVehicle;
    updateStamp: Update;
    lock: Lock;
    station: Station;
};
export type Lock = {
    id: number;
    qrCode: string;
    status: string;
    isClosed: boolean;
    lastSync: string;
    isAlarmActive: boolean;
    batteryLevel: number;
    beamId?: string;
};

export type VehiclesById = {
    [code: number]: Vehicle;
};

export type Accumulator = {
    vehicleIds: number[];
    vehiclesById: VehiclesById;
};

export enum StringFilterTypes {
    Company = 'companyIds',
    Status = 'statuses',
    Search = 'vehicleKey',
    Types = 'types',
    ServiceIds = 'serviceIds',
    BatteryLevel = 'maxBatteryLevel',
    LastGpsDate = 'lastGpsDate',
}

export type Filters = {
    [StringFilterTypes.Company]?: number;
    [StringFilterTypes.Status]?: VehicleStatus;
    [StringFilterTypes.Search]?: string;
    [StringFilterTypes.Types]?: VehicleTypes[];
    [StringFilterTypes.ServiceIds]?: number[];
    [StringFilterTypes.BatteryLevel]?: number;
    [StringFilterTypes.LastGpsDate]?: string;
};

export interface NewVehicle {
    plate: string;
    serial: string;
    status: VehicleStatus;
    services: number[];
    type: VehicleTypes;
    lockQrCode: string;
    model: string;
    stationType: string;
    adapter: string;
}

export type NiuDetails = {
    isBatteryOn: boolean;
    batteryLevel: number;
    estimatedMeters: number;
    falloffStatus: boolean;
    isIgnitionOn: boolean;
    totalMeters: number;
    lat: number;
    long: number;
    isLocatingActive: boolean;
    isOnline: boolean;
    isSaddleOpen: boolean;
    isSecured: boolean;
    speed: number;
    isTailboxLidOpen: boolean;
    isTailboxOpen: boolean;
    isAlarmOn: boolean;
    gpsUpdateDate: string;
    ecuBatteryLevel: string;
    ecuSoftVer: string;
    gpsSignal: string;
    gsmSignal: string;
};

export type vaeDetails = {
    isIgnitionOn: boolean;
};

export type CurrentVehicleState = {
    lock: Lock;
    niuDetails: NiuDetails;
    vehicle: FetchedVehicle;
    vaeDetails?: vaeDetails;
    updateStamp: Update;
    station: Station | null;
};

export type VehiclesState = {
    loading: boolean;
    error: string;
    newStatus: VehicleStatus | null;
    vehicleIds: number[];
    selected: number[];
    vehiclesById: VehiclesById;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
    vehicleHasUnsavedChanges: boolean;
    currentVehicle: Vehicle | null;
    fetchAll: boolean;
    activeVehicleTab: number;
    currentVehicleState: CurrentVehicleState | null;
};

export enum VehicleCommands {
    ignitionOn = 'IGNITION_ON',
    ignitionOff = 'IGNITION_OFF',
    locate = 'LOCATE',
    tailboxOn = 'TAILBOX_ON',
    saddleOn = 'SADDLE_ON',
    tailboxOff = 'TAILBOX_OFF',
    openLock = 'OPEN',
    openDockStationLock = 'OPEN_DS',
}
