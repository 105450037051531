const API_PREFIX = '/backoffice';

export const TRIPS_URL = `${API_PREFIX}/trips`;
export const STATS_URL = `${API_PREFIX}/homepage/statistic`;
export const TRIPS_EXPORT_URL = `${TRIPS_URL}/export`;

export const LOGS_URL = `${API_PREFIX}/logs`;
export const LOGS_EXPORT_URL = `${LOGS_URL}/export`;

export const VEHICLES_URL = `${API_PREFIX}/vehicles`;
export const VEHICLES_EXPORT_URL = `${VEHICLES_URL}/export`;
export const VEHICLES_IMPORT_URL = `${VEHICLES_URL}/import`;
export const VEHICLES_UPDATE_STATUS_URL = `${VEHICLES_URL}/updateStatus`;

export const NIU_URL = `${API_PREFIX}/niu`;
export const NIU_SEND_COMMAND_URL = `${NIU_URL}/sendCommand`;
export const NIU_GET_SCOOTER_URL = `${NIU_URL}/getScooter`;

export const COMPANIES_URL = `${API_PREFIX}/companies`;
export const COMPANIES_EXPORT_URL = `${API_PREFIX}/companies/export`;

export const SERVICES_URL = `${API_PREFIX}/services`;

export const USERS_URL = `${API_PREFIX}/end-users`;
export const USERS_EXPORT_URL = `${USERS_URL}/export`;

export const ZONES_URL = `${API_PREFIX}/zones`;

export const BO_USERS_URL = `${API_PREFIX}/bo-users`;
export const BO_USERS_EXPORT_URL = `${API_PREFIX}/bo-users/export`;

export const STATIONS_URL = `${API_PREFIX}/stations`;

export const EVENTS_URL = `${API_PREFIX}/vehicle/events`;

export const PUSH_URL = `${USERS_URL}/pushes`;

export const NEWS_URL = `${API_PREFIX}/news`;

export const GROUPS_URL = `${API_PREFIX}/groups`;
export const PERMISSIONS_URL = `${GROUPS_URL}/permissions`;

export const SCOOTER_MAINTENANCE_URL = `${API_PREFIX}/maintenances`;

export const UPDATE_ACCOUNT_URL = `${API_PREFIX}/bo-users/updateAccount`;

export const APP_VERSION_URL = `${API_PREFIX}/app-versions`;

export const AGREEMENTS_URL = `${API_PREFIX}/agreements`;
export const CURRENT_AGREEMENTS_URL = `${API_PREFIX}/agreements/current`;
export const FUTURE_AGREEMENTS_URL = `${API_PREFIX}/agreements/future`;
export const AUTH_URL = 'backoffice-auth';

export const PARTNERS_URL = `${API_PREFIX}/partners`;
export const PARTNERS_EXPORT_URL = `${API_PREFIX}/partners/export`;

export const LOCKS_URL = `${API_PREFIX}/locks`;
export const LOCKS_EXPORT_URL = `${API_PREFIX}/locks/export`;
export const LOCKS_IMPORT_URL = `${API_PREFIX}/locks/import`;

export const BOOKING_URL = `${API_PREFIX}/bookings`;
