import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ButtonComponent } from '../../../common';
import { useTranslation } from 'react-i18next';
import { Modals, openModal } from 'ducks/ui';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteEmailRecipient,
    fetchEmailRecipients,
    selectIsLoading,
} from 'ducks/companies';
import { selectEmailRecipientsMappedByAlarmReason } from 'ducks/companies/selectors';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { LoaderComponent } from '../../../common/Loader';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        margin: theme.spacing(1),
    },
}));

interface ListItemProps {
    title: string;
    alarmReason: string;
}

const ListItem: React.FC<ListItemProps> = ({
    title,
    alarmReason,
}: ListItemProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToAddEmail = accessToPages[Permissions.COMPANY_UPDATE];
    const recipients = useSelector(selectEmailRecipientsMappedByAlarmReason);
    const recipientAlarmReasons = useMemo(() => {
        const alarmReasons = {
            fallonState: false,
            lowBattery: false,
            shockAlarm: false,
            speedTooHigh: false,
        };

        if (alarmReason === 'fallonState') {
            alarmReasons.fallonState = true;
        }

        if (alarmReason === 'lowBattery') {
            alarmReasons.lowBattery = true;
        }

        if (alarmReason === 'shockAlarm') {
            alarmReasons.shockAlarm = true;
        }

        if (alarmReason === 'speedTooHigh') {
            alarmReasons.speedTooHigh = true;
        }

        return alarmReasons;
    }, [alarmReason]);

    const handleDelete = useCallback(
        (email: string) => () => {
            dispatch(deleteEmailRecipient({ email, type: alarmReason }));
        },
        [dispatch, alarmReason],
    );

    const handleAdd = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.ADD_EMAIL_RECIPIENT,
                modalProps: {
                    alarmReasons: recipientAlarmReasons,
                    currentAlarmReason: alarmReason,
                },
            }),
        );
    }, [dispatch, recipientAlarmReasons, alarmReason]);

    return (
        <Box marginBottom="20px">
            <Typography variant="h2">{title}</Typography>
            <Box display="flex" flexWrap="wrap">
                {recipients[alarmReason] &&
                    recipients[alarmReason].map((email: string) => {
                        return allowedToAddEmail ? (
                            <Chip
                                key={`${alarmReason}-${email}`}
                                label={email}
                                onDelete={handleDelete(email)}
                                className={classes.chip}
                            />
                        ) : (
                            <Chip
                                key={`${alarmReason}-${email}`}
                                label={email}
                                className={classes.chip}
                            />
                        );
                    })}
            </Box>
            {!allowedToAddEmail &&
                recipients[alarmReason] &&
                recipients[alarmReason].length === 0 && (
                    <Box marginLeft="10px">
                        <Typography variant="body2">
                            {t('tripList.noData')}
                        </Typography>
                    </Box>
                )}
            {allowedToAddEmail && (
                <Box marginTop="10px">
                    <ButtonComponent
                        text={t('add')}
                        outlined
                        handleClick={handleAdd}
                    />
                </Box>
            )}
        </Box>
    );
};

export const CompanyEmailRecipients = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);

    useEffect(() => {
        dispatch(fetchEmailRecipients());
    }, [dispatch]);

    return (
        <>
            <Box marginBottom="50px">
                <Typography variant="h1">
                    {t('company.emailRecipients')}
                </Typography>
            </Box>
            <Box justifyContent="space-between" width="80%" margin="0 auto">
                {loading && <LoaderComponent />}
                <ListItem
                    title={t('company.shockAlarm')}
                    alarmReason="shockAlarm"
                />
                <ListItem
                    title={t('company.fallonState')}
                    alarmReason="fallonState"
                />
                <ListItem
                    title={t('company.speedToHigh')}
                    alarmReason="speedTooHigh"
                />
                <ListItem
                    title={t('company.lowBattery')}
                    alarmReason="lowBattery"
                />
            </Box>
        </>
    );
};
