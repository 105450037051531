import { Pagination, Sort } from 'ducks/common/types';
import { User } from 'ducks/vehicles/common/types';

export enum BookingStatus {
    BOOKED = 'BOOKED',
    CANCELED = 'CANCELED',
    EXPIRED = 'EXPIRED',
    COMPLETED = 'COMPLETED',
}

export type Booking = {
    created?: string;
    end?: string;
    id: number;
    status: BookingStatus;
    vehicleId: number;
};

export type ProfileSummary = {
    email: string;
    id: number;
};

export type FetchedVehicleBooking = {
    booking: Booking;
    endUser: User;
    profile: ProfileSummary;
};

export type VehicleBookingById = {
    [code: number]: FetchedVehicleBooking;
};

export enum FilterTypes {
    StartTime = 'startTime',
    EndTime = 'endTime',
    Statuses = 'statuses',
    VehicleId = 'vehicleId',
    Email = 'email',
}

export type Filters = {
    [FilterTypes.StartTime]?: string;
    [FilterTypes.EndTime]?: string;
    [FilterTypes.Statuses]?: string[];
    [FilterTypes.Email]?: string;
};

export type VehicleBookingTable = {
    sort: Sort;
    pagination: Pagination;
    bookingIds: number[];
    bookingById: VehicleBookingById;
    filters: Filters;
};

export type BookingState = {
    loading: boolean;
    error: string;
} & VehicleBookingTable;

export type VehicleBookingResponse = {
    content: FetchedVehicleBooking[];
} & Pagination;

export type VehicleBookingAccumulator = {
    bookingIds: number[];
    bookingById: VehicleBookingById;
};
