import React from 'react';
import CrossIcon from '@material-ui/icons/Clear';
import { Box, IconButton } from '@material-ui/core';

interface ResetButtonProps {
    handleResetFilters: () => void;
}

export const ResetFiltersButton: React.FC<ResetButtonProps> = ({
    handleResetFilters,
}: ResetButtonProps) => {
    return (
        <Box>
            <IconButton onClick={handleResetFilters}>
                <CrossIcon />
            </IconButton>
        </Box>
    );
};
