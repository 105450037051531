import React from 'react';
import { useDispatch } from 'react-redux';
import { ImportComponent } from '../../../common/ImportComponent';
import { parseZoneDocument } from 'tools/kmlFileParser';
import { SelectField } from '../../../common';
import { setZoneHasUnsavedChanges, setImportedZones } from 'ducks/zones';
import { Box } from '@material-ui/core';

export const ImportZonesComponent: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = React.useState<
        File | undefined | null
    >();
    const [zoneNames, setZoneNames] = React.useState<string[]>([]);
    React.useEffect(() => {
        selectedFile && dispatch(setZoneHasUnsavedChanges(true));
        return () => {
            dispatch(setImportedZones({}));
        };
    }, [dispatch, selectedFile]);
    const handleImport = () => {
        if (selectedFile) {
            parseZoneDocument(selectedFile).then(({ zones, zoneNames }) => {
                dispatch(setImportedZones(zones));
                setZoneNames(zoneNames);
            });
        }
    };
    const zonesOptions = React.useMemo(() => {
        return zoneNames.map(zone => ({
            label: zone,
            value: zone,
        }));
    }, [zoneNames]);

    return (
        <>
            <ImportComponent
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                handleImport={handleImport}
                acceptedFiles=".kml"
            />
            <Box padding="0 20px 10px 20px">
                {!!zoneNames.length && (
                    <SelectField
                        name="zone"
                        label="zones.border"
                        options={zonesOptions}
                    />
                )}
            </Box>
        </>
    );
};
