import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

export const configureAppStore = (initialState = {}) => {
    const reduxSagaMonitorOptions = {};
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const routerMiddleware = createRouterMiddleware(history);
    const middlewares = [sagaMiddleware, routerMiddleware];

    const store = configureStore({
        reducer: rootReducer(history),
        middleware: [...getDefaultMiddleware(), ...middlewares],
        preloadedState: initialState,
        devTools: process.env.NODE_ENV !== 'production',
    });

    return { store, runSaga: sagaMiddleware.run };
};
