import { Moment } from 'moment';

export const getDisabledTime = ({
    current,
    min,
    max,
}: {
    current: Moment;
    min?: string | Moment;
    max?: string | Moment;
}) => {
    if (min && max) {
        return current.isSameOrAfter(min) && current.isSameOrBefore(max);
    }
    if (min) {
        return current.isSameOrAfter(min);
    }
    if (max) {
        return current.isSameOrBefore(max);
    }
    return true;
};
