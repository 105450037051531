import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentAgreements } from 'ducks/agreements/selectors';
import { AgreementLink } from 'ducks/agreements/types';
import { LinkComponent } from '../../common/Link';

export const CurrentDocumentVersion = () => {
    const { t } = useTranslation();
    const { policy, cgau } = useSelector(selectCurrentAgreements);

    return (
        <Box width="500px">
            <Typography align="center" variant="h2">
                {t('legalDocuments.currentVersion')}
            </Typography>
            <Box>
                <Typography align="left" variant="h3">
                    {t('legalDocuments.cgau')}
                </Typography>
                {cgau?.links &&
                    cgau.links.map(({ language, url }: AgreementLink) => {
                        return (
                            <Box display="flex" key={language}>
                                <Box margin="10px 20px">
                                    <Typography align="left">
                                        {language}
                                    </Typography>
                                </Box>
                                <Box margin="10px 20px">
                                    <LinkComponent
                                        href={url}
                                        target="_blank"
                                        text={url}
                                    />
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
            <Box>
                <Typography align="left" variant="h3">
                    {t('legalDocuments.policy')}
                </Typography>
                <Box>
                    {policy?.links &&
                        policy.links.map(({ language, url }: AgreementLink) => {
                            return (
                                <Box display="flex" key={language}>
                                    <Box margin="10px 20px">
                                        <Typography align="left">
                                            {language}
                                        </Typography>
                                    </Box>
                                    <Box margin="10px 20px">
                                        <LinkComponent
                                            href={url}
                                            target="_blank"
                                            text={url}
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
            </Box>
        </Box>
    );
};
