import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Zone } from '../../../ducks/zones/types';
import { LinkComponent } from '../../common/Link';
import { generatePath } from 'react-router';
import { routes } from '../../../constants/common';

type ZoneIdsProps = {
    title: string;
    zones: Zone[];
};

export const ZoneIdsWithLinks: React.FC<ZoneIdsProps> = ({
    title,
    zones,
}: ZoneIdsProps) => {
    return (
        <Box display="flex">
            <Typography>{`${title}: `}</Typography>
            &nbsp;
            {zones.map(({ id, name }: Zone, index) => (
                <>
                    <LinkComponent
                        href={generatePath(routes.ZONE, {
                            zoneId: id,
                        })}
                        target="_blank"
                        key={id}
                        text={`${id} - ${name}${
                            index === zones.length - 1 ? '' : ', '
                        }`}
                    />
                    &nbsp;
                </>
            ))}
        </Box>
    );
};
