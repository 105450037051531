import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { createStyles, SvgIcon, withStyles } from '@material-ui/core';
import { colors } from 'themes/colors';

const StyledSvgIcon = withStyles(() =>
    createStyles({
        root: {
            color: colors.green,
        },
    }),
)(SvgIcon);

export const Check: React.FC = () => (
    <StyledSvgIcon>
        <CheckIcon />
    </StyledSvgIcon>
);
