import React, { Ref, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ButtonComponent } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/common/ModalContainer/styles';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserProfileError, updateSubscription } from 'ducks/users';
import moment, { Moment } from 'moment';
import { RootState } from 'ducks/root';
import { selectUserProfileErrorById } from 'ducks/users/selectors';
import { DateTimeInput } from 'components/common/DateTimeInput';
import { getDisabledTime } from 'tools/date';
import { DateTypes } from 'ducks/users/types';
import { HelpComponent } from 'components/common/HelpComponent';
import { ErrorComponent } from '../../common/Error';

interface ModalProps {
    handleClose: () => void;
    date: Moment;
    dateType: string;
    subscriptionId: number;
    unchangedDate: {
        startDate?: string;
        endDate?: string;
    };
    serviceTimeZone: string;
    profileId: number;
}

// eslint-disable-next-line react/display-name
export const EditDateModal = React.forwardRef(
    (
        {
            handleClose,
            dateType,
            date,
            subscriptionId,
            unchangedDate,
            serviceTimeZone,
            profileId,
        }: ModalProps,
        ref: Ref<HTMLDivElement>,
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const profileError = useSelector((state: RootState) =>
            selectUserProfileErrorById(state, profileId),
        );
        const [newDate, setNewDate] = useState<Moment | string>(date || '');
        const [error, setError] = useState('');

        useEffect(() => {
            return () => {
                dispatch(resetUserProfileError());
            };
        }, [dispatch]);

        const handleClick = useCallback(() => {
            const time = new Date(moment(newDate).toString())
                .toTimeString()
                .slice(0, 8);
            const date = moment(
                new Date(moment(newDate).toString()).toDateString(),
            ).format('YYYY-MM-DD');
            const dateString = `${date}T${time}`;

            const params = {
                removeEndDate: false,
                [dateType]: dateString,
                ...unchangedDate,
            };
            if (moment(newDate).isBefore(new Date())) {
                setError(t('usersList.profile.dateError'));
                return;
            }
            if (dateType === DateTypes.EndDate && newDate === '') {
                params.removeEndDate = true;
            }

            setError('');
            dispatch(
                updateSubscription(subscriptionId, profileId, {
                    ...params,
                }),
            );
        }, [
            newDate,
            dateType,
            subscriptionId,
            dispatch,
            unchangedDate,
            profileId,
            t,
        ]);

        const handleChange = useCallback(
            (date: string | Moment) => {
                setNewDate(date);
            },
            [setNewDate],
        );

        const isTimeDisabled = useCallback(
            (current: Moment) =>
                getDisabledTime({ current, min: moment().format() }),
            [],
        );

        return (
            <div ref={ref} className={classes.modal}>
                <Box textAlign="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h2">
                    {t('usersList.profile.changeDate')}
                </Typography>
                <Box
                    width="300px"
                    display="flex"
                    justifyContent="center"
                    margin="20px auto">
                    <DateTimeInput
                        id="change-date-input"
                        value={(newDate && moment(newDate).toDate()) || ''}
                        onChange={handleChange}
                        getDisabledTime={isTimeDisabled}
                        label={`${t('usersList.profile.date')}, ${t(
                            'serviceTimeZone',
                        )} (${serviceTimeZone})`}
                        labelComponent={
                            <HelpComponent
                                tooltipText={t('timeZoneTooltipText', {
                                    timeZone: serviceTimeZone,
                                })}
                            />
                        }
                    />
                </Box>
                <Box mb="10px" textAlign="center" marginTop="15px">
                    <ErrorComponent error={error || profileError} />
                </Box>
                <Box textAlign="center" marginTop="30px">
                    <ButtonComponent
                        handleClick={handleClose}
                        text={t('cancel')}
                    />
                    &emsp;
                    <ButtonComponent
                        handleClick={handleClick}
                        text={t('save')}
                        isDisabled={!newDate}
                    />
                </Box>
            </div>
        );
    },
);
