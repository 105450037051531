import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultPagination } from '../common/pagination';
import { FilterTypes } from './types';

const defaultFilters = {
    type: '',
    vehicleIds: [],
    search: '',
};
export const initialState = {
    loading: false,
    error: '',
    maintenanceIds: [],
    maintenanceById: {},
    filters: defaultFilters,
    pagination: defaultPagination,
};

const scooterMaintenanceSlice = createSlice({
    name: 'scooterMaintenance',
    initialState,
    reducers: {
        fetchScooterMaintenance(state) {
            state.loading = true;
            state.error = '';
        },
        fetchScooterMaintenanceSuccess(state, action) {
            state.maintenanceIds = action.payload.maintenanceIds;
            state.maintenanceById = action.payload.maintenanceById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchScooterMaintenanceError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.loading = true;
            state.pagination = defaultPagination;
        },
        setFilters(
            state,
            {
                payload,
            }: PayloadAction<
                {
                    filterType: FilterTypes;
                    value: string;
                }[]
            >,
        ) {
            payload.forEach(({ filterType, value }) => {
                state.filters = {
                    ...state.filters,
                    [filterType]: value,
                };
            });

            state.loading = true;
            state.pagination = defaultPagination;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
    },
});
export const {
    fetchScooterMaintenance,
    fetchScooterMaintenanceError,
    fetchScooterMaintenanceSuccess,
    setFilter,
    setFilters,
    setPage,
    resetFilters,
} = scooterMaintenanceSlice.actions;

export const scooterMaintenanceReducer = scooterMaintenanceSlice.reducer;
