import { Languages } from '../boUsers/types';
import { Pagination, Sort } from '../common/types';
import { Company, FileProperties } from '../companies/types';

export type NewsById = {
    [code: number]: News;
};

export type Template = {
    id: number;
    language: Languages;
};

export type NewsState = {
    loading: boolean;
    error: string;
    newsIds: number[];
    newsById: NewsById;
    currentNews: News | null;
    newsHasUnsavedChanges: boolean;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
};

export type News = {
    id: number;
    file: FileProperties;
    end: string;
    start: string;
    type: NewsTypes;
    companyId: number;
    companyName: string;
    title: string;
    isLoopEnabled: boolean;
    content: string;
    imageUrl?: string;
    isEnabled?: boolean;
};

export type FetchedNews = {
    id: number;
    file: { url: string };
    end: string;
    start: string;
    type: NewsTypes;
    companyId: number;
    companyName: string;
    title: string;
    isLoopEnabled: boolean;
    content: string;
    imageUrl?: string;
    isEnabled?: boolean;
};

export type Content = {
    company: Company;
    news: News;
};

export type ServiceResponse = {
    content: Content[];
} & Pagination;

export enum NewsTypes {
    POPUP = 'POPUP',
    BANNER = 'BANNER',
}
export enum StringFilterTypes {
    Company = 'companyId',
    Type = 'newsType',
    Search = 'title',
}

export type Filters = {
    [StringFilterTypes.Company]?: string;
    [StringFilterTypes.Type]?: string;
    [StringFilterTypes.Search]?: string;
};
