import { ButtonComponent, FormGrid } from 'components/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { Check } from 'components/common/CheckIcon';
import { Cross } from 'components/common/CrossIcon';
import { selectCurrentVehicle, getVehicleState } from 'ducks/vehicles/common';
import { selectCurrentVehicleState } from 'ducks/vehicles/common/selectors';

export const NIUInfoComponent = () => {
    const dispatch = useDispatch();
    const currentVehicle = useSelector(selectCurrentVehicle);
    const currentVehicleState = useSelector(selectCurrentVehicleState);
    React.useEffect(() => {
        currentVehicle &&
            dispatch(getVehicleState({ vehicleId: currentVehicle.id }));
    }, [dispatch, currentVehicle]);
    const fetchScooterState = React.useCallback(
        () =>
            currentVehicle &&
            dispatch(getVehicleState({ vehicleId: currentVehicle.id })),
        [currentVehicle, dispatch],
    );
    const { t } = useTranslation();
    return (
        <Box textAlign="center">
            {currentVehicleState && (
                <FormGrid columnAmount={2} rowHeight="30px">
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.alarmStatus')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.isAlarmOn ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.motorStatus')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails
                                    ?.isIgnitionOn ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.onlineState')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.isOnline ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.batteryStatus')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.isBatteryOn ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.saddleStatus')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails
                                    ?.isSaddleOpen ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.tailboxStatus')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails
                                    ?.isTailboxOpen ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.falloffState')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails
                                    ?.falloffStatus ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.secureStatus')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.isSecured ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.lastGPSDate')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.gpsUpdateDate
                                    ? new Date(
                                          currentVehicleState.niuDetails.gpsUpdateDate,
                                      ).toLocaleString()
                                    : '-'}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.ecuBatteryLevel')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.ecuBatteryLevel
                                    ? `${currentVehicleState.niuDetails.ecuBatteryLevel}%`
                                    : '-'}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.ecuSoftVer')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.ecuSoftVer
                                    ? currentVehicleState.niuDetails.ecuSoftVer
                                    : '-'}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.gpsSignal')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.gpsSignal
                                    ? currentVehicleState.niuDetails.gpsSignal
                                    : '-'}
                            </span>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.gsmSignal')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState.niuDetails?.gsmSignal
                                    ? currentVehicleState.niuDetails.gsmSignal
                                    : '-'}
                            </span>
                        </Typography>
                    </Box>
                </FormGrid>
            )}

            <ButtonComponent
                text={t('vehicles.sync')}
                handleClick={fetchScooterState}
            />
        </Box>
    );
};
