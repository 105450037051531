import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/stations';
import { Station, StationActivationStatuses } from 'ducks/stations/types';
import { LinkComponent } from '../../common/Link';
import { Modals, openModal } from 'ducks/ui';
import { Box } from '@material-ui/core';
import { Check } from '../../common/CheckIcon';

const renderCheck = (isDisplayed: boolean) =>
    isDisplayed && (
        <Box textAlign="center">
            <Check />
        </Box>
    );

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('stations.id'),
            field: 'ID',
            render: (rowProps: Station) => rowProps.id,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'ID',
                    }),
                ),
        },
        {
            title: t('stations.stationUId'),
            field: 'ducktStationUid',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'ducktStationUid',
                    }),
                ),
        },
        {
            title: t('stations.name'),
            field: 'name',
            render: (rowProps: Station) => rowProps.name,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'name',
                    }),
                ),
        },
        {
            title: t('stations.availablePlaces'),
            field: 'availablePlaces',
            render: (rowProps: Station) => rowProps.availablePlaces,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'availablePlaces',
                    }),
                ),
        },
        {
            title: t('stations.totalPlaces'),
            field: 'totalPlaces',
            render: (rowProps: Station) => rowProps.totalPlaces,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'totalPlaces',
                    }),
                ),
        },
        {
            title: t('stations.fillingRate'),
            field: 'fillRate',
            render: (rowProps: Station) =>
                rowProps.fillRate >= 0
                    ? `${rowProps.fillRate.toFixed(2)}%`
                    : '-',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'fillRate',
                    }),
                ),
        },
        {
            title: t('stations.gps'),
            field: 'gps',
            // eslint-disable-next-line react/display-name
            render: (rowProps: Station) => (
                <LinkComponent
                    text={`${rowProps.lat || '-'}, ${rowProps.lng || '-'}`}
                    handleClick={event => {
                        event.stopPropagation();
                        dispatch(
                            openModal({
                                modalType: Modals.MAP_WITH_POINT,
                                modalProps: {
                                    position: {
                                        lat: rowProps.lat,
                                        lng: rowProps.lng,
                                    },
                                    title: t('stations.stationPosition'),
                                },
                            }),
                        );
                    }}
                />
            ),
            sorting: false,
        },
        {
            title: t('stations.service'),
            field: 'service',
            render: (rowProps: Station) => rowProps.serviceName,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'service',
                    }),
                ),
        },
        {
            title: t('stations.activated'),
            field: 'statusOfActivation',
            render: (rowProps: Station) =>
                renderCheck(
                    rowProps.statusOfActivation ===
                        StationActivationStatuses.ACTIVE,
                ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'statusOfActivation',
                    }),
                ),
        },
        {
            title: t('stations.dockStationType'),
            field: 'stationType',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'stationType',
                    }),
                ),
        },
        {
            title: t('stations.connected'),
            field: 'isConnected',
            render: (rowProps: Station) => renderCheck(rowProps.isConnected),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isConnected',
                    }),
                ),
        },
    ];
};
