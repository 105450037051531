import React, { useMemo } from 'react';
import {
    composeValidators,
    isValidPlate,
    isValidSerial,
    required,
    validateServices,
    isValidLockQrCode,
    isValidBikeOrVaeSerial,
    isStationIdRequired,
    isValidVehicleAdapterFormat,
} from 'tools/validationRules';
import { useTranslation } from 'react-i18next';
import {
    ButtonComponent,
    FormGrid,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from '../../common';
import {
    AllowedForCreationVehicleStatus,
    NewVehicle,
    VehicleStatus,
    VehicleTypes,
} from 'ducks/vehicles/common/types';
import { fetchServicesByCompany } from 'ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { RootState } from 'ducks/root';
import {
    createVehicle,
    selectIsLoading,
    selectVehicleError,
    selectVehicleHasUnsavedChanges,
    setVehicleHasUnsavedChanges,
    resetError,
} from 'ducks/vehicles/common';
import { Prompt } from 'react-router';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { ImportScootersComponent } from './ImportScooters';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { useHistory } from 'react-router';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import { StationTypes } from 'ducks/stations/types';

export const CreateScooterComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { servicesById, serviceIds } = useSelector(
        (state: RootState) => state.service,
    );
    const scooterHasUnsavedChanges = useSelector(
        selectVehicleHasUnsavedChanges,
    );
    const error = useSelector(selectVehicleError);
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const loading = useSelector(selectIsLoading);

    const { pathname } = history.location as { pathname: string };
    const isBikeCreationPage = useMemo(
        () => pathname === routes.NEW_BIKE_OR_VAE,
        [pathname],
    );

    const handleConfirm = React.useCallback(
        ({ serial, ...values }: NewVehicle & { company: number }) => {
            const isSimple =
                !isBikeCreationPage ||
                values.stationType === StationTypes.Simple;
            const params = {
                ...values,
                serial: serial.toUpperCase(),
                status: isBikeCreationPage
                    ? values.lockQrCode
                        ? VehicleStatus.bound
                        : VehicleStatus.inactive
                    : values.status,
                stationType: values.stationType,
                adapter: isSimple ? undefined : values.adapter,
            };

            if (params.plate) {
                params.plate = params.plate.toUpperCase();
            }

            if (!isBikeCreationPage) {
                params.type = VehicleTypes.SCOOTER;
            }

            if (values.company && values.services.length === 0) {
                dispatch(
                    openModal({
                        modalType: Modals.CHANGE_CONFIRMATION,
                        modalProps: {
                            handleConfirm: () => {
                                dispatch(createVehicle([{ ...params }]));
                                dispatch(closeModal());
                            },
                            message: 'vehicles.emptyServicesError',
                        },
                    }),
                );
                return;
            }

            dispatch(createVehicle([{ ...params }]));
        },
        [dispatch, isBikeCreationPage],
    );

    React.useEffect(() => {
        dispatch(resetError());
        return () => {
            dispatch(setVehicleHasUnsavedChanges(false));
        };
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    const redirectToVehiclesList = React.useCallback(
        () =>
            isBikeCreationPage
                ? dispatch(push(routes.BIKES_AND_VAE))
                : dispatch(push(routes.SCOOTERS)),
        [dispatch, isBikeCreationPage],
    );

    const companyOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );
    const [companyId, setCompanyId] = React.useState<number | string | null>(
        null,
    );
    React.useEffect(() => {
        companyId &&
            dispatch(fetchServicesByCompany({ companyId, onlyEnabled: false }));
    }, [companyId, dispatch]);

    const initialValues = React.useMemo(
        () => ({
            status: VehicleStatus.inactive,
            services: [],
            stationType: StationTypes.Simple,
        }),
        [],
    );

    const statusOptions = React.useMemo(
        () =>
            AllowedForCreationVehicleStatus.map(status => ({
                label: t(`scooterStatuses.${status}`),
                value: status,
            })),
        [t],
    );
    const serviceOptions = React.useMemo(
        () =>
            serviceIds.map(id => ({
                value: id,
                label: servicesById[id].name,
            })),
        [serviceIds, servicesById],
    );
    const vehicleTypeOptions = useMemo(() => {
        return [
            {
                label: '-',
                value: '',
            },
            {
                label: t('tripList.bike'),
                value: VehicleTypes.BIKE,
            },
            {
                label: t('tripList.vae'),
                value: VehicleTypes.VAE,
            },
        ];
    }, [t]);

    const servicesValidations = React.useCallback(
        (values: number[]) => validateServices(values, servicesById),
        [servicesById],
    );

    const resetField = React.useCallback((args, state, { changeValue }) => {
        changeValue(state, args[0].field, () => []);
    }, []);

    const mutators = React.useMemo(
        () => ({
            resetField,
        }),
        [resetField],
    );

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setVehicleHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    useNotificationOnPageLeave(scooterHasUnsavedChanges);

    const stationTypeOptions = React.useMemo(
        () => [
            {
                label: t(StationTypes.Simple),
                value: StationTypes.Simple,
            },
            {
                label: t(StationTypes.DUCKT),
                value: StationTypes.DUCKT,
            },
            {
                label: t(StationTypes.KNOTT),
                value: StationTypes.KNOTT,
            },
        ],
        [t],
    );

    return (
        <ThemedBoxComponent m="0 25%">
            {loading && <LoaderComponent />}
            <Prompt
                when={scooterHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={handleConfirm}
                initialValues={initialValues}
                mutators={mutators}
                render={({ handleSubmit, valid, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToVehiclesList}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {isBikeCreationPage
                                        ? t('bikes.createBike')
                                        : t('vehicles.createScooter')}
                                </p>
                            </Typography>
                        </Box>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Typography variant="h2">{t('importFile')}</Typography>
                        <ImportScootersComponent />
                        <Typography variant="h2">{`-${t('or')}-`}</Typography>
                        <FormGrid columnAmount={2}>
                            <TextField
                                name="serial"
                                validators={composeValidators(
                                    required,
                                    isBikeCreationPage
                                        ? isValidBikeOrVaeSerial
                                        : isValidSerial,
                                )}
                                label={t('vehicles.serial')}
                                isRequired
                            />
                            {isBikeCreationPage && (
                                <SelectField
                                    validators={composeValidators(required)}
                                    name="type"
                                    label={t('vehicles.type')}
                                    options={vehicleTypeOptions}
                                    isRequired
                                />
                            )}
                            {!isBikeCreationPage && (
                                <TextField
                                    name="plate"
                                    validators={composeValidators(
                                        required,
                                        isValidPlate,
                                    )}
                                    label={t('vehicles.plate')}
                                    isRequired
                                />
                            )}
                            {!isBikeCreationPage && (
                                <SelectField
                                    validators={composeValidators(required)}
                                    name="status"
                                    label={t('vehicles.status')}
                                    options={statusOptions}
                                    isRequired
                                />
                            )}
                            {isBikeCreationPage && (
                                <TextField
                                    name="lockQrCode"
                                    validators={composeValidators(
                                        isValidLockQrCode,
                                    )}
                                    label={t('vehicles.lockQrCode')}
                                />
                            )}
                            {isBikeCreationPage && (
                                <TextField
                                    name="model"
                                    label={t('vehicles.model')}
                                />
                            )}
                            {isBikeCreationPage && (
                                <TextField
                                    name="adapter"
                                    label={t('vehicles.adapter')}
                                    disabled={
                                        values?.stationType ===
                                        StationTypes.Simple
                                    }
                                    validators={composeValidators(
                                        isStationIdRequired,
                                        isValidVehicleAdapterFormat,
                                    )}
                                />
                            )}

                            {isBikeCreationPage && (
                                <SelectField
                                    validators={required}
                                    name="stationType"
                                    label={t('vehicles.stationType')}
                                    options={stationTypeOptions}
                                    isRequired
                                />
                            )}
                            <SelectField
                                name="company"
                                label={t('vehicles.companyName')}
                                options={companyOptions}
                                handleChange={value => {
                                    setCompanyId(value);
                                    form.mutators.resetField({
                                        field: 'services',
                                    });
                                }}
                            />
                            {values.company && (
                                <SelectField
                                    multiple
                                    validators={composeValidators(
                                        servicesValidations,
                                    )}
                                    name="services"
                                    label={t('vehicles.services')}
                                    options={serviceOptions}
                                />
                            )}
                        </FormGrid>
                        <FormButtonGroup>
                            <ButtonComponent
                                handleClick={handleSubmit}
                                text={t('create')}
                                isDisabled={
                                    loading ||
                                    !scooterHasUnsavedChanges ||
                                    !valid
                                }
                            />
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
