import React, { forwardRef, ReactNode, SyntheticEvent } from 'react';
import MaterialTable from '@material-table/core';
import {
    Box,
    createStyles,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import { colors } from 'themes/colors';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import { Sort } from 'ducks/common/types';
import { PAGE_SIZE } from '../../../constants/common';
import { ColumnType, renderColumns } from './column';

export interface Action<T> {
    icon: string | (() => React.ReactElement<any>);
    tooltip: string;
    onClick: (event: SyntheticEvent, rowData: T) => void;
    position?: 'row';
}

interface DetailPanel<T> {
    render: ({ rowData }: { rowData: T }) => string | React.ReactNode;
    disabled?: boolean;
    icon?: string | React.ComponentType<any>;
    openIcon?: string | React.ComponentType<any>;
    tooltip?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface ITableProps<T extends object> {
    columns: Array<ColumnType>;
    data: any;
    title: string;
    sort?: Sort;
    actions?: Action<T>[];
    toolbar?: React.FC;
    pagination?: React.FC;
    container?: React.FC<IContainerProps>;
    searchComponent?: React.FC;
    selection?: boolean;
    onSelectionChange?: any;
    onRowClick?: (event?: React.MouseEvent, rowData?: T) => void;
    options?: any;
    detailPanel?:
        | (({ rowData }: { rowData: T }) => React.ReactNode)
        | (DetailPanel<T> | ((rowData: T) => DetailPanel<T>))[];
    pageSize?: number;
    hasToolbarSpace?: boolean;
}

interface IContainerProps {
    children: ReactNode;
}

const StyledContainer = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: '25px 25px 0 0',
            backgroundColor: colors.white,
            borderBottom: 'none',
            overflow: 'hidden',
            marginTop: 'auto',
            '& .Mui-disabled': {
                display: 'none',
            },
        },
    }),
)(Box);

const Container: React.FC<IContainerProps> = ({
    children,
}: IContainerProps) => <StyledContainer>{children}</StyledContainer>;

// eslint-disable-next-line @typescript-eslint/ban-types
export const TableComponent = <T extends object>({
    columns,
    data,
    title,
    actions,
    selection,
    onSelectionChange,
    onRowClick,
    toolbar: Toolbar,
    searchComponent: SearchComponent,
    sort,
    pagination: Pagination,
    options,
    detailPanel,
    pageSize = PAGE_SIZE,
    container: PropContainer,
    hasToolbarSpace = true,
}: ITableProps<T>) => {
    return (
        <>
            {SearchComponent && <SearchComponent />}
            <Typography variant="h1">{title}</Typography>
            {Toolbar ? <Toolbar /> : hasToolbarSpace && <Box m="20px" />}
            <MaterialTable
                key={pageSize}
                onRowClick={onRowClick}
                detailPanel={detailPanel}
                components={{
                    Container: PropContainer || Container,
                    Header: () => renderColumns(columns, sort, selection),
                    // eslint-disable-next-line react/display-name
                    Pagination: () => <></>,
                }}
                columns={columns}
                data={data}
                title={title}
                icons={{
                    // eslint-disable-next-line react/display-name
                    PreviousPage: forwardRef((props, ref) => (
                        <ArrowLeft {...props} ref={ref} />
                    )),
                    // eslint-disable-next-line react/display-name
                    NextPage: forwardRef((props, ref) => (
                        <ArrowRight {...props} ref={ref} />
                    )),
                }}
                options={{
                    showTitle: false,
                    actionsColumnIndex: -1,
                    detailPanelColumnAlignment: 'right',
                    rowStyle: {
                        backgroundColor: '#EEE',
                        borderTop: 'white solid',
                        borderBottom: 'white solid',
                    },
                    search: false,
                    toolbar: false,
                    pageSize: pageSize,
                    showSelectAllCheckbox: true,
                    selection: selection,
                    ...options,
                }}
                onSelectionChange={onSelectionChange}
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                actions={actions}
            />
            {Pagination ? <Pagination /> : <Box m="20px" />}
        </>
    );
};
