import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    composeValidators,
    isExpiredDate,
    required,
} from 'tools/validationRules';
import { DateTimeField } from '../../common/Form/CommonFields/DateTimeField';
import moment from 'moment';
import { Permissions } from '../../../ducks/groups/types';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from '../../../ducks/auth';

type VersionFieldProps = {
    id: string;
};

export const VersionField: React.FC<VersionFieldProps> = ({
    id,
}: VersionFieldProps) => {
    const { t } = useTranslation();

    const accessToPages = useSelector(selectAccessToPages);

    const allowedToEdit = accessToPages[Permissions.AGREEMENT_UPDATE];
    return (
        <DateTimeField
            id={id}
            name="version"
            disabled={!allowedToEdit}
            label={t('legalDocuments.date')}
            timeFormat={false}
            min={moment().startOf('day')}
            validators={composeValidators(required, isExpiredDate)}
            isRequired
        />
    );
};
