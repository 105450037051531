import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid, InputComponent, SelectComponent } from '../../common';
import { setFilter, resetFilters, fetchLocks } from 'ducks/locks';
import { selectFilters } from 'ducks/locks/selectors';
import { FilterTypes, LockStatus } from 'ducks/locks/types';
import moment from 'moment-timezone';
import { DateTimeInput } from 'components/common/DateTimeInput';
import { isMoment, Moment } from 'moment';
import { getDisabledTime } from 'tools/date';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';
import { Box } from '@material-ui/core';
import { selectAllCompaniesById, selectAllCompanyIds } from 'ducks/companies';

export const Toolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateError, setDateError] = useState('');
    const filters = useSelector(selectFilters);

    const statusOptions = useMemo(() => {
        return [
            {
                label: '-',
                value: '',
            },
            ...Object.values(LockStatus).map(status => ({
                label: t(`locks.lockStatus.${status}`),
                value: status,
            })),
        ];
    }, [t]);

    const closeStatusOptions = useMemo(() => {
        return [
            {
                label: '-',
                value: '',
            },
            {
                label: t('bikes.open'),
                value: 'false',
            },
            {
                label: t('bikes.closed'),
                value: 'true',
            },
        ];
    }, [t]);

    const handleChangeFilter = (
        e: React.ChangeEvent<HTMLInputElement>,
        filterType: FilterTypes,
    ) => {
        dispatch(
            setFilter({
                filterType,
                value: e.target.value,
            }),
        );
    };

    const handleChangeTime = useCallback(
        (time: string | Moment, filterType: FilterTypes) => {
            if (time === '') {
                return dispatch(
                    setFilter({
                        filterType,
                        value: '',
                    }),
                );
            }

            if (!!time && !isMoment(time)) {
                setDateError(t('errors.incorrectTimeFormat'));
                return;
            }

            if (moment(time).isAfter(new Date())) {
                setDateError(t('errors.dateAfterToday'));
                return;
            }

            if (isMoment(time)) {
                setDateError('');
                dispatch(
                    setFilter({
                        filterType,
                        value: `${moment(time).toISOString()}`,
                    }),
                );
            }
        },
        [dispatch, setDateError, t],
    );

    const isTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                max: moment().endOf('day'),
            }),
        [],
    );

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        dispatch(fetchLocks());
    }, [dispatch]);

    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const companyOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );

    return (
        <FormGrid columnAmount={6} padding="10px 0">
            <SelectComponent
                label={t('locks.companyName')}
                onChange={e => handleChangeFilter(e, FilterTypes.Company)}
                value={filters[FilterTypes.Company || 0]}
                options={companyOptions}
            />
            <SelectComponent
                label={t('locks.status')}
                onChange={e => handleChangeFilter(e, FilterTypes.Status)}
                value={filters[FilterTypes.Status] || ''}
                options={statusOptions}
            />
            <SelectComponent
                label={t('locks.closeStatus')}
                onChange={e => handleChangeFilter(e, FilterTypes.CloseStatus)}
                value={filters[FilterTypes.CloseStatus] || ''}
                options={closeStatusOptions}
            />
            <InputComponent
                hasError={
                    Number(filters[FilterTypes.Battery]) < 0 ||
                    isNaN(Number(filters[FilterTypes.Battery]))
                }
                withoutCircleIcon
                onChange={e => handleChangeFilter(e, FilterTypes.Battery)}
                InputProps={{
                    inputProps: { min: 0 },
                }}
                type="number"
                label={t('locks.batteryLevel')}
                value={filters[FilterTypes.Battery]}
            />
            <DateTimeInput
                id="lastDate"
                value={filters[FilterTypes.LastDate]}
                onChange={e => handleChangeTime(e, FilterTypes.LastDate)}
                label={t('locks.lastDate')}
                getDisabledTime={isTimeDisabled}
                hasError={!!dateError}
                helperText={t(dateError)}
            />
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};
