import React, { useCallback } from 'react';
import { ButtonComponent, FormGrid } from 'components/common';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import AddIcon from '@material-ui/icons/Add';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { requestExportPartners } from 'ducks/partners';
import { Box } from '@material-ui/core';

export const SearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToCreate = accessToPages[Permissions.PARTNER_CREATE];
    const allowedToExport = accessToPages[Permissions.PARTNER_EXPORT];

    const redirectToPartnerCreation = useCallback(() => {
        dispatch(push(routes.NEW_PARTNER));
    }, [dispatch]);

    const handleExportPartners = useCallback(() => {
        dispatch(requestExportPartners());
    }, [dispatch]);

    return (
        <Box display="flex" justifyContent="flex-end">
            <FormGrid
                columnAmount={allowedToCreate && allowedToExport ? 2 : 1}
                padding="0"
                rowHeight="20px">
                {allowedToCreate && (
                    <ButtonComponent
                        text={t('add')}
                        startIcon={<AddIcon />}
                        handleClick={redirectToPartnerCreation}
                    />
                )}
                {allowedToExport && (
                    <ButtonComponent
                        text={t('download')}
                        startIcon={<SaveAltIcon />}
                        handleClick={handleExportPartners}
                    />
                )}
            </FormGrid>
        </Box>
    );
};
