import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {
    createLock,
    setLockHasUnsavedChanges,
    selectLockHasUnsavedChanges,
    selectLockError,
    resetError,
    selectIsLoading,
} from 'ducks/locks';
import { Form, FormSpy } from 'react-final-form';
import { ButtonComponent } from '../../common/Button';
import { useTranslation } from 'react-i18next';
import { FormGrid, TextField, ThemedBoxComponent } from '../../common';
import { Typography, Box, IconButton } from '@material-ui/core';
import { Prompt } from 'react-router';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { selectAccessToPages } from 'ducks/auth';
import { validations } from './validations';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { FormState } from 'final-form';
import { NewLock } from 'ducks/locks/types';
import { Permissions } from 'ducks/groups/types';
import { ImportLocksComponent } from './ImportLocks';
import { ErrorComponent } from '../../common/Error';

export const CreateLock: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lockHasUnsavedChanges = useSelector(selectLockHasUnsavedChanges);
    const error = useSelector(selectLockError);
    const accessToPages = useSelector(selectAccessToPages);
    const loading = useSelector(selectIsLoading);

    const allowedToEdit = accessToPages[Permissions.VEHICLE_CREATE];

    React.useEffect(() => {
        dispatch(resetError());
        return () => {
            dispatch(setLockHasUnsavedChanges(false));
        };
    }, [dispatch]);

    const redirectToLocks = React.useCallback(
        () => dispatch(push(routes.LOCKS)),
        [dispatch],
    );

    const initialValues = React.useMemo(
        () => ({
            qrCode: '',
            version: '',
        }),
        [],
    );

    const handleConfirm = React.useCallback(
        (values: NewLock) => {
            dispatch(
                createLock({
                    ...values,
                }),
            );
        },
        [dispatch],
    );

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setLockHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    useNotificationOnPageLeave(lockHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 25%" position="relative">
            {loading && <LoaderComponent />}
            <Prompt
                when={lockHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                initialValues={initialValues}
                onSubmit={handleConfirm}
                render={({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToLocks}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>{t('locks.addLock')}</p>
                            </Typography>
                        </Box>
                        <Typography variant="h2">{t('importFile')}</Typography>
                        <ImportLocksComponent />
                        <Typography variant="h2">{`-${t('or')}-`}</Typography>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                dirtySinceLastSubmit: true,
                                submitSucceeded: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <FormGrid columnAmount={2}>
                            <TextField
                                disabled={!allowedToEdit}
                                name="qrCode"
                                validators={validations.qrCode}
                                isRequired
                                label={t('locks.qrCode')}
                            />
                            <TextField
                                disabled={!allowedToEdit}
                                name="version"
                                validators={validations.version}
                                label={t('locks.version')}
                                isRequired
                            />
                        </FormGrid>

                        <FormButtonGroup>
                            <ButtonComponent
                                handleClick={handleSubmit}
                                text={t('create')}
                                isDisabled={!lockHasUnsavedChanges || !valid}
                            />
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
