import {
    composeValidators,
    fieldLength,
    required,
} from 'tools/validationRules';

export const validations = {
    name: composeValidators(required, (value: string) =>
        fieldLength(value, 2, 50),
    ),
    key: composeValidators(required),
    caller: composeValidators(required),
};
