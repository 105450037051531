import React, { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { useStyles } from 'components/common/ModalContainer/styles';
import {
    SelectField,
    ButtonComponent,
    TextField,
    CheckboxField,
    FormGrid,
} from '../../../../common';
import {
    composeValidators,
    isNumber,
    minValue,
    required,
} from 'tools/validationRules';
import { Assignment } from 'ducks/companies/types';
import { fetchNotLinkedServices } from 'ducks/services';
import { assignServiceToCompany } from 'ducks/companies';
import { RootState } from 'ducks/root';
import { Box, Typography } from '@material-ui/core';
import { RecipientTypes, UsageTypes } from 'ducks/services/types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

type ConnectService = {
    companyId: number;
    handleClose: () => void;
};

// eslint-disable-next-line react/display-name
export const ConnectServiceComponent = React.forwardRef(
    ({ handleClose, companyId }: ConnectService, ref: Ref<HTMLDivElement>) => {
        const classes = useStyles();
        const { t } = useTranslation();

        const dispatch = useDispatch();

        React.useEffect(() => {
            dispatch(fetchNotLinkedServices());
        }, [dispatch]);

        const handleConfirm = ({
            domainRecognition,
            domainSubscriptionDuration,
            serviceId,
        }: Assignment) => {
            handleClose();
            dispatch(
                assignServiceToCompany({
                    serviceId,
                    companyId,
                    domainSubscriptionDuration,
                    domainRecognition,
                }),
            );
        };

        const initialValues = React.useMemo(
            () => ({
                domainRecognition: false,
                domainSubscriptionDuration: 0,
            }),
            [],
        );
        const { notLinkedServicesIds, notLinkedServicesById } = useSelector(
            (state: RootState) => state.service,
        );
        const serviceOptions = React.useMemo<
            { label: string; value: number | string }[]
        >(
            () => [
                {
                    label: '-',
                    value: '',
                },
                ...notLinkedServicesIds.map(id => ({
                    value: id,
                    label: notLinkedServicesById[id].name,
                })),
            ],
            [notLinkedServicesById, notLinkedServicesIds],
        );

        return (
            <div ref={ref} className={classes.modal}>
                <Box textAlign="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Form
                    mutators={{
                        setField: (args, state, { changeValue }) => {
                            changeValue(
                                state,
                                args[0].field,
                                () => args[0].value,
                            );
                        },
                    }}
                    initialValues={initialValues}
                    onSubmit={handleConfirm}
                    render={({ handleSubmit, valid, values, form }) => (
                        <FormGrid columnAmount={1}>
                            <Typography variant="h1">
                                {t('invitations.connectService')}
                            </Typography>
                            <SelectField
                                name={t('serviceId')}
                                validators={composeValidators(required)}
                                label={t('invitations.serviceName')}
                                multiple={false}
                                handleChange={() => {
                                    form.mutators.setField({
                                        field: 'domainRecognition',
                                        value: false,
                                    });
                                    form.mutators.setField({
                                        field: 'domainSubscriptionDuration',
                                        value: 0,
                                    });
                                }}
                                options={serviceOptions}
                                isRequired
                            />
                            {values.serviceId &&
                                notLinkedServicesById[values.serviceId].type ===
                                    UsageTypes.SHORT_TERM_USAGE &&
                                notLinkedServicesById[values.serviceId]
                                    .recipientType ===
                                    RecipientTypes.B_TO_B && (
                                    <CheckboxField
                                        field="domainRecognition"
                                        label={t(
                                            'invitations.domainRecognition',
                                        )}
                                        handleChange={(value: boolean) => {
                                            if (!value) {
                                                form.mutators.setField({
                                                    field: 'domainSubscriptionDuration',
                                                    value: 0,
                                                });
                                            }
                                        }}
                                    />
                                )}
                            {values.domainRecognition && (
                                <TextField
                                    name="domainSubscriptionDuration"
                                    validators={composeValidators(
                                        isNumber,
                                        (value: string) => minValue(value, 0),
                                    )}
                                    type="number"
                                    label={t('company.maximumContractDuration')}
                                    InputProps={{
                                        inputProps: { min: 0 },
                                    }}
                                />
                            )}
                            <Box textAlign="center">
                                <ButtonComponent
                                    isDisabled={!valid}
                                    text={t('save')}
                                    handleClick={handleSubmit}
                                />
                            </Box>
                        </FormGrid>
                    )}
                />
            </div>
        );
    },
);
