import React from 'react';
import { ToggleButtonComponent } from '../../common/ToggleButton';
import { useDispatch, useSelector } from 'react-redux';
import { FilterTypes, selectFilters, setFilter } from 'ducks/zones';
import { StyledToolbar } from '../../common';
import { useTranslation } from 'react-i18next';

export const ZoneToolbarComponent: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleToggleFilter = (type: FilterTypes) => {
        dispatch(setFilter(type));
    };
    const filters = useSelector(selectFilters);

    return (
        <StyledToolbar>
            <ToggleButtonComponent
                text={t('zones.forbiddenZone')}
                handleClick={() => handleToggleFilter(FilterTypes.isForbidden)}
                isSelected={filters[FilterTypes.isForbidden]}
            />
        </StyledToolbar>
    );
};
