import {
    composeValidators,
    fieldLength,
    isExpiredTime,
    required,
} from 'tools/validationRules';
import i18next from 'i18next';

export const validations = {
    title: composeValidators(required, (value: string) =>
        fieldLength(value, 2, 30),
    ),
    content: composeValidators(
        required,
        (value: string) => value && fieldLength(value, 10, 500),
    ),
    start: composeValidators(required, isExpiredTime),
    end: composeValidators(required, isExpiredTime),
};

export const checkIfStartTimeIsLaterThanEnd = <
    T extends { start: string; end: string },
>(
    value: T,
) => {
    let errors: T | Record<string, string> = {};
    if (
        value.start &&
        value.end &&
        new Date(value.start) >= new Date(value.end)
    ) {
        errors = {
            end: i18next.t('errors.endTime'),
            start: '',
        };
    }
    return errors;
};
