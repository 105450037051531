import React from 'react';
import { TablePagination } from '@material-ui/core';
import { Pagination } from 'ducks/common/types';
import { SelectComponent } from '../index';
import { Box } from '@material-ui/core';

interface IPaginationProps {
    pagination: Pagination;
    setPage: (page: number) => void;
}

export const PaginationComponent: React.FC<IPaginationProps> = ({
    pagination,
    setPage,
    ...props
}: IPaginationProps) => {
    const tablePages = React.useMemo(() => {
        const pages = [];
        let i = 0;
        while (i < pagination.totalPages) {
            pages.push({ label: `${i + 1}`, value: i });
            i++;
        }
        return pages;
    }, [pagination]);

    if (pagination.totalElements === 0) return null;
    return (
        <Box display="flex" justifyContent="center">
            <TablePagination
                {...props}
                rowsPerPage={pagination.size}
                count={pagination.totalElements}
                page={pagination.number}
                labelDisplayedRows={() => (
                    <SelectComponent
                        value={pagination.number}
                        onChange={e => {
                            window.scrollTo(0, 0);
                            setPage(Number(e.target.value));
                        }}
                        options={tablePages}
                    />
                )}
                rowsPerPageOptions={[pagination.size]}
                onChangePage={(e, page) => {
                    window.scrollTo(0, 0);
                    setPage(page);
                }}
            />
        </Box>
    );
};
