import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        buttonGroup: {
            display: 'flex',
            justifyContent: 'center',
            '&>*:not(:last-child)': {
                marginRight: '20px',
            },
            marginTop: '10px',
            marginBottom: '10px',
        },
    }),
);

export type ButtonGroupProps = {
    children: ReactNode;
};

export const FormButtonGroup: React.FC<ButtonGroupProps> = ({
    children,
}: ButtonGroupProps) => {
    const classes = useStyles();
    return <Box className={classes.buttonGroup}>{children}</Box>;
};
