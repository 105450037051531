import { routes } from '../../../constants/common';
import { Permissions } from 'ducks/groups/types';
import { AccessToPages } from 'ducks/auth/types';

export const MenuConfig = (
    accessToPages: AccessToPages,
    isSuperAdmin: boolean,
    isManager: boolean,
) => {
    return [
        {
            name: 'menu.companiesAndServices',
            visible:
                accessToPages[Permissions.COMPANY_VIEW] ||
                accessToPages[Permissions.SERVICE_VIEW] ||
                accessToPages[Permissions.COMPANY_VIEW] ||
                accessToPages[Permissions.SEND_PUSH] ||
                isManager,
            content: [
                {
                    title: 'menu.services',
                    rout: routes.SERVICES,
                    visible: accessToPages[Permissions.SERVICE_VIEW],
                },
                {
                    title: 'menu.companies',
                    rout: routes.COMPANIES,
                    visible: accessToPages[Permissions.COMPANY_VIEW],
                },
                {
                    title: 'menu.news',
                    rout: routes.NEWS,
                    visible: accessToPages[Permissions.NEWS_VIEW],
                },
                {
                    title: 'menu.pushes',
                    rout: routes.PUSH,
                    visible: accessToPages[Permissions.SEND_PUSH],
                },
                {
                    title: 'menu.reportingDashboard',
                    rout: routes.REPORT_DASHBOARD,
                    visible: isManager,
                },
            ],
        },
        {
            name: 'menu.vehicles',
            visible: true,
            content: [
                {
                    title: 'menu.dashboard',
                    rout: routes.MAIN_PAGE,
                    visible: true,
                },
                {
                    title: 'menu.scooters',
                    rout: routes.SCOOTERS,
                    visible: accessToPages[Permissions.VEHICLE_VIEW],
                },
                {
                    title: 'menu.bikesAndVAE',
                    rout: routes.BIKES_AND_VAE,
                    visible: accessToPages[Permissions.VEHICLE_VIEW],
                },
                {
                    title: 'menu.trips',
                    rout: routes.TRIPS,
                    visible: accessToPages[Permissions.TRIP_VIEW],
                },
                {
                    title: 'menu.vehiclesMap',
                    rout: routes.VEHICLES_MAP,
                    visible: accessToPages[Permissions.VEHICLE_VIEW],
                },
                {
                    title: 'menu.locks',
                    rout: routes.LOCKS,
                    visible: accessToPages[Permissions.VEHICLE_VIEW],
                },
            ],
        },
        {
            name: 'menu.administration',
            visible:
                accessToPages[Permissions.BO_USER_VIEW] ||
                accessToPages[Permissions.GROUP_VIEW] ||
                accessToPages[Permissions.PARTNER_VIEW] ||
                accessToPages[Permissions.MANAGE_APP_VERSION] ||
                isSuperAdmin ||
                accessToPages[Permissions.SWAGGER_PARTNER] ||
                accessToPages[Permissions.SWAGGER_ADMIN],
            content: [
                {
                    title: 'menu.boUsers',
                    rout: routes.BO_USERS,
                    visible: accessToPages[Permissions.BO_USER_VIEW],
                },
                {
                    title: 'menu.groups',
                    rout: routes.GROUPS,
                    visible: accessToPages[Permissions.GROUP_VIEW],
                },
                {
                    title: 'menu.partners',
                    rout: routes.PARTNERS,
                    visible: accessToPages[Permissions.PARTNER_VIEW],
                },
                {
                    title: 'menu.appVersion',
                    rout: routes.APP_VERSION,
                    visible: accessToPages[Permissions.MANAGE_APP_VERSION],
                },
                {
                    title: 'menu.logs',
                    rout: routes.ADMIN_LOGGER,
                    visible: isSuperAdmin,
                },
                {
                    title: 'menu.swagger',
                    rout: routes.SWAGGER,
                    visible: accessToPages[Permissions.SWAGGER_PARTNER],
                },
                {
                    title: 'menu.swaggerAdmin',
                    rout: routes.SWAGGER_ADMIN,
                    visible: accessToPages[Permissions.SWAGGER_ADMIN],
                },
            ],
        },
        {
            name: 'menu.zones',
            visible:
                accessToPages[Permissions.ZONE_VIEW] ||
                accessToPages[Permissions.STATION_VIEW],
            content: [
                {
                    title: 'menu.zones',
                    rout: routes.ZONES,
                    visible: accessToPages[Permissions.ZONE_VIEW],
                },
                {
                    title: 'menu.stations',
                    rout: routes.STATIONS,
                    visible: accessToPages[Permissions.STATION_VIEW],
                },
            ],
        },
        {
            name: 'menu.legalDocuments',
            visible: accessToPages[Permissions.AGREEMENT_VIEW],
            content: [
                {
                    title: 'menu.legalDocuments',
                    rout: routes.LEGAL_DOCUMENTS,
                    visible: accessToPages[Permissions.AGREEMENT_VIEW],
                },
            ],
        },
    ];
};
