import { Content, Vehicle, Accumulator } from './types';
import { mapById } from '../../common/mappers';

export const mapVehicle = ({
    company,
    vehicle,
    user,
    updateStamp,
    services,
    lock,
    station,
}: Content): Vehicle => {
    const { byId, allIds } = mapById(services);
    return {
        companyName: company?.name,
        companyId: company?.id,
        userName: user?.firstName || '',
        userLastName: user?.lastName || '',
        userId: user?.id || '',
        updated: updateStamp.updated,
        servicesById: byId,
        servicesAllIds: allIds,
        lockQrCode: lock?.qrCode || '',
        lockStatus: lock?.status || '',
        lockId: lock?.id || '',
        lockIsClosed: lock?.isClosed || null,
        lockLastCommunication: lock?.lastSync || '',
        lockAlarmStatus: lock?.isAlarmActive || null,
        stationId: station?.id || '',
        stationName: station?.name || '',
        lockBatteryLevel: lock?.batteryLevel || '',
        ...vehicle,
    };
};

export const mapFetchedVehicles = (data: Content[]): Accumulator => {
    return data.reduce<Accumulator>(
        (acc, item) => {
            return {
                vehiclesById: {
                    ...acc.vehiclesById,
                    [item.vehicle.id]: mapVehicle(item),
                },
                vehicleIds: [...acc.vehicleIds, item.vehicle.id],
            };
        },
        { vehicleIds: [], vehiclesById: {} },
    );
};
