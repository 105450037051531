import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
    setSort,
    setPage,
    setFilter,
    fetchVehicleBooking,
    fetchVehicleBookingSuccess,
    fetchVehicleBookingError,
    requestExportBookingSuccess,
    requestExportBooking,
    requestCancelBooking,
    requestCancelBookingSuccess,
} from './index';
import { cancelBooking, exportBooking, getVehicleBooking } from './service';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { Filters, VehicleBookingResponse } from './types';
import { mapFilters, mapPagination, mapSorting } from 'ducks/common/mappers';
import { mapFetchedVehicleBooking } from './mappers';
import { mapFetchedPagination } from 'ducks/common/pagination';
import { Pagination, Sort } from '../common/types';
import { downloadBlob } from 'tools/file';

function* fetchVehicleBookingSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: VehicleBookingResponse = yield call(getVehicleBooking, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { bookingIds, bookingById } = mapFetchedVehicleBooking(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchVehicleBookingSuccess({
                bookingIds,
                bookingById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(
            fetchVehicleBookingError({ error: (error as Error)?.message }),
        );
    }
}

function* exportBookingSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const { file, fileName } = yield call(
            exportBooking,
            mapFilters(filters),
        );
        downloadBlob(file, fileName);
        console.log(file);
        yield put(requestExportBookingSuccess());
    } catch (error) {
        console.log(error);
        yield put(
            fetchVehicleBookingError({ error: (error as Error)?.message }),
        );
    }
}

function* cancelBookingSaga({ payload }: PayloadAction<number>) {
    try {
        yield call(cancelBooking, payload);

        yield put(requestCancelBookingSuccess());
        yield put(requestExportBooking());
    } catch (error) {
        console.log(error);
        yield put(
            fetchVehicleBookingError({ error: (error as Error)?.message }),
        );
    }
}

export default function* bookingSagas() {
    yield takeLatest(requestExportBooking.type, exportBookingSaga);
    yield takeLatest(fetchVehicleBooking.type, fetchVehicleBookingSaga);
    yield takeLatest(setFilter.type, fetchVehicleBookingSaga);
    yield takeLatest(setSort.type, fetchVehicleBookingSaga);
    yield takeLatest(setPage.type, fetchVehicleBookingSaga);
    yield takeLatest(requestCancelBooking.type, cancelBookingSaga);
}
