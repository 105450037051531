import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';
import { FilterTypes } from './types';

export const defaultFilters = {
    customerStatus: '',
    status: '',
    groupId: '',
    companyId: '',
    searchText: '',
};
export const initialState = {
    boUserIds: [],
    boUsersById: {},
    currentUser: {},
    loading: false,
    error: '',
    userHasUnsavedChanges: false,
    filters: defaultFilters,
    sort: {
        value: '',
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
};

const boUsersSlice = createSlice({
    name: 'boUsers',
    initialState,
    reducers: {
        createBOUser: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.userHasUnsavedChanges = true;
            },
            prepare: service => ({
                payload: service,
            }),
        },
        createBOUserSuccess(state) {
            state.loading = false;
            state.userHasUnsavedChanges = false;
        },
        createBOUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setUserHasUnsavedChanges(state, action) {
            state.userHasUnsavedChanges = action.payload;
        },
        fetchBOUsers(state) {
            state.loading = true;
            state.error = '';
        },
        fetchBOUsersSuccess(state, action) {
            state.boUserIds = action.payload.boUserIds;
            state.boUsersById = action.payload.boUsersById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchBOUsersError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string;
            }>,
        ) {
            state.loading = true;
            state.filters[payload.filterType] = payload.value;
            state.pagination = defaultPagination;
        },
        requestExportBoUsers(state) {
            state.loading = true;
            state.error = '';
        },
        requestExportBoUsersSuccess(state) {
            state.loading = false;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        fetchBOUser: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: id => ({
                payload: id,
            }),
        },
        fetchBOUserSuccess(state, action) {
            state.currentUser = action.payload;
            state.loading = false;
        },
        fetchBOUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateBOUser: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: user => ({
                payload: user,
            }),
        },
        updateBOUserSuccess(state) {
            state.loading = false;
            state.error = '';
            state.userHasUnsavedChanges = false;
        },
        updateBOUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetCurrentBoUser(state) {
            state.currentUser = {};
            state.loading = false;
        },
        toggleBOUserEnable: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (id, isEnabled) => ({
                payload: { id, isEnabled },
            }),
        },
        toggleBOUserSuccess(state) {
            state.loading = false;
        },
        toggleBOUserError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateLeaveDate: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: (id, date) => ({
                payload: { id, date },
            }),
        },
        updateLeaveDateSuccess(state) {
            state.loading = false;
        },
        updateLeaveDateError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
    },
});
export const {
    createBOUser,
    createBOUserError,
    createBOUserSuccess,
    setUserHasUnsavedChanges,
    fetchBOUsers,
    fetchBOUsersError,
    fetchBOUsersSuccess,
    setFilter,
    setSort,
    setPage,
    requestExportBoUsers,
    requestExportBoUsersSuccess,
    resetFilters,
    fetchBOUser,
    fetchBOUserSuccess,
    fetchBOUserError,
    updateBOUser,
    updateBOUserSuccess,
    updateBOUserError,
    resetCurrentBoUser,
    toggleBOUserEnable,
    toggleBOUserError,
    toggleBOUserSuccess,
    updateLeaveDate,
    updateLeaveDateSuccess,
    updateLeaveDateError,
} = boUsersSlice.actions;

export const boUsersReducer = boUsersSlice.reducer;
