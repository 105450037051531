import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    anonymizeUser,
    fetchUser,
    redirectToUsersList,
    resetCurrentUser,
    setUserHasUnsavedChanges,
    toggleUserEnable,
    updateUser,
} from 'ducks/users';
import { useParams } from 'react-router';
import { Box, IconButton, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Form, FormSpy } from 'react-final-form';
import { ThemedBoxComponent } from 'components/common/ThemedBox';
import { ButtonComponent, FormGrid, TextField } from 'components/common';
import SaveIcon from '@material-ui/icons/Save';
import { FetchedUser } from 'ducks/users/types';
import { Prompt } from 'react-router-dom';
import { UneditableInformation } from './UneditableInformation';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { UserProfiles } from './UserProfiles';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { validations } from './validations';
import {
    selectCurrentUser,
    selectIsUserLoading,
    selectProfileHasUnsavedChanges,
    selectUserError,
    selectUserHasUnsavedChanges,
} from 'ducks/users/selectors';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { DocumentsManagementComponent } from './DocumentsManagement';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';

export const UserDetails = () => {
    const { userId } = useParams<{ userId?: string }>();
    const dispatch = useDispatch();

    const accessToPages = useSelector(selectAccessToPages);

    const allowedToDelete = accessToPages[Permissions.END_USER_DELETE];
    const allowedToEdit = accessToPages[Permissions.END_USER_UPDATE];
    const allowedToUpdateStatus =
        accessToPages[Permissions.END_USER_UPDATE_STATUS];
    const { t } = useTranslation();
    const error = useSelector(selectUserError);
    const userHasUnsavedChanges = useSelector(selectUserHasUnsavedChanges);
    const profileHasUnsavedChanges = useSelector(
        selectProfileHasUnsavedChanges,
    );
    const currentUser = useSelector(selectCurrentUser);
    const loading = useSelector(selectIsUserLoading);

    useEffect(() => {
        dispatch(fetchUser(userId));
        return () => {
            dispatch(resetCurrentUser());
        };
    }, [dispatch, userId]);

    const redirectToList = useCallback(() => {
        dispatch(redirectToUsersList());
    }, [dispatch]);

    const handleAnonymize = useCallback(() => {
        dispatch(anonymizeUser());
    }, [dispatch]);

    const toggleEnable = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: () => {
                        dispatch(
                            toggleUserEnable(
                                userId,
                                !currentUser?.user.isEnabled,
                            ),
                        );
                        dispatch(closeModal());
                    },
                    message: t('usersList.changeStatusMessage'),
                },
            }),
        );
    }, [dispatch, userId, currentUser, t]);

    const handleConfirm = useCallback(
        (values: FetchedUser) => {
            dispatch(updateUser(values));
        },
        [dispatch],
    );

    useNotificationOnPageLeave(
        userHasUnsavedChanges || profileHasUnsavedChanges,
    );

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setUserHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    return (
        <>
            <Prompt
                when={userHasUnsavedChanges || profileHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Box display="inline-flex">
                <Box display="flex" alignItems="center">
                    <IconButton onClick={redirectToList}>
                        <BackIcon />
                    </IconButton>
                </Box>
                <Typography variant="h1">{t('usersList.userInfo')}</Typography>
            </Box>
            <FormGrid columnAmount={2}>
                <Form
                    onSubmit={handleConfirm}
                    initialValues={currentUser?.user}
                    render={({ handleSubmit, valid }) => (
                        <form onSubmit={handleSubmit}>
                            <FormSpy
                                subscription={{
                                    pristine: true,
                                    submitSucceeded: true,
                                    dirtySinceLastSubmit: true,
                                }}
                                onChange={setUnsavedChanges}
                            />
                            <ThemedBoxComponent>
                                {loading && <LoaderComponent />}

                                <FormGrid columnAmount={1} rowHeight="50px">
                                    <TextField
                                        disabled={!allowedToEdit}
                                        name="firstName"
                                        validators={validations.firstName}
                                        label={t('usersList.firstName')}
                                        isRequired
                                    />
                                    <TextField
                                        disabled={!allowedToEdit}
                                        name="lastName"
                                        validators={validations.lastName}
                                        label={t('usersList.lastName')}
                                        isRequired
                                    />
                                    <TextField
                                        name="phone"
                                        validators={validations.phone}
                                        label={t('usersList.phoneNumber')}
                                        disabled={!allowedToEdit}
                                        isRequired
                                    />
                                    <TextField
                                        name="datanalytics"
                                        validators={validations.datanalytics}
                                        label={t('usersList.datanalytics')}
                                        disabled={!allowedToEdit}
                                    />
                                    <TextField
                                        name="rfidPassNumber"
                                        validators={validations.rfidPassNumber}
                                        label={t('usersList.rfidPassNumber')}
                                        disabled={!allowedToEdit}
                                    />
                                    <FormButtonGroup>
                                        {allowedToUpdateStatus && (
                                            <ButtonComponent
                                                text={t(
                                                    currentUser?.user?.isEnabled
                                                        ? 'disable'
                                                        : 'enable',
                                                )}
                                                handleClick={toggleEnable}
                                            />
                                        )}
                                        {allowedToDelete && (
                                            <ButtonComponent
                                                handleClick={handleAnonymize}
                                                text={t('company.anonymize')}
                                            />
                                        )}
                                        {allowedToEdit && (
                                            <ButtonComponent
                                                handleClick={handleSubmit}
                                                text={t('save')}
                                                startIcon={<SaveIcon />}
                                                isDisabled={
                                                    !userHasUnsavedChanges ||
                                                    !valid ||
                                                    loading
                                                }
                                            />
                                        )}
                                    </FormButtonGroup>
                                    <ErrorComponent error={error} />
                                </FormGrid>
                            </ThemedBoxComponent>
                        </form>
                    )}
                />
                <ThemedBoxComponent>
                    <UneditableInformation />
                </ThemedBoxComponent>
            </FormGrid>
            <FormGrid columnAmount={1}>
                <DocumentsManagementComponent />
            </FormGrid>

            <Box display="flex" paddingLeft="20px" marginTop="20px">
                <Typography variant="h2">
                    {t('usersList.profile.profiles')}
                </Typography>
            </Box>
            {currentUser?.profiles && currentUser?.profiles.length > 0 ? (
                <FormGrid columnAmount={1}>
                    {currentUser.profiles.map(profile => {
                        return (
                            <UserProfiles key={profile.id} profile={profile} />
                        );
                    })}
                </FormGrid>
            ) : (
                <Box display="flex" paddingLeft="20px">
                    -
                </Box>
            )}
        </>
    );
};
