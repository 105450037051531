import React, { useMemo } from 'react';
import CardMedia from '@material-ui/core/CardMedia';

interface IIFrameInterface {
    elementString?: string;
    className?: string;
    style?: React.CSSProperties;
}

export const IFrame: React.FC<IIFrameInterface> = ({
    elementString,
    style,
    className,
}: IIFrameInterface) => {
    const calculatedSrc = useMemo(() => {
        if (!elementString) return '';
        const iframeElement = new DOMParser().parseFromString(
            elementString,
            'text/html',
        ).body.firstChild as HTMLImageElement | undefined;
        if (!iframeElement) return '';
        return iframeElement.src;
    }, [elementString]);

    if (!calculatedSrc) return null;
    return (
        <CardMedia
            component="iframe"
            style={style}
            src={calculatedSrc}
            className={className}
            frameBorder="0"
            allowFullScreen
        />
    );
};
