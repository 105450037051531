import { Box, createStyles, Theme, withStyles } from '@material-ui/core';
import { colors } from '../../../themes/colors';
import React, { ReactNode } from 'react';

const StyledContainer = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `2px solid ${theme.palette.primary.main}`,
            borderRadius: '25px',
            backgroundColor: colors.white,
            overflow: 'hidden',
        },
    }),
)(Box);

interface IContainerProps {
    children: ReactNode;
}
export const Container: React.FC<IContainerProps> = ({
    children,
}: IContainerProps) => <StyledContainer>{children}</StyledContainer>;
