import { useSelector } from 'react-redux';
import { selectStatisticsRented } from 'ducks/trips';
import { TableComponent } from '../../common';
import React from 'react';
import i18next from 'i18next';
import { VehicleTypes } from 'ducks/vehicles/common/types';
import { Container } from './Container';
import { useTranslation } from 'react-i18next';

export const NumberOfRented = () => {
    const statistics = useSelector(selectStatisticsRented);
    const { t } = useTranslation();
    const columns = React.useMemo(
        () => [
            {
                title: i18next.t('date'),
                field: 'date',
                sorting: false,
            },
            {
                title: i18next.t('tripList.bike'),
                field: VehicleTypes.BIKE,
                sorting: false,
            },
            {
                title: i18next.t('tripList.vae'),
                field: VehicleTypes.VAE,
                sorting: false,
            },
            {
                title: i18next.t('tripList.scooter'),
                field: VehicleTypes.SCOOTER,
                sorting: false,
            },
        ],
        [],
    );
    return (
        <TableComponent
            title={t('homePage.numberRented')}
            columns={columns}
            data={statistics || []}
            container={Container}
            pageSize={statistics?.length}
        />
    );
};
