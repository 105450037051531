import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import { CAPTCHA_ATTEMPTS_LIMIT, CAPTCHA_KEY } from '../config';

export const useCaptcha = () => {
    const [captchaPassed, setPassed] = useState(true);
    const countRef = useRef(0);

    /**
     * Ref is used to fix missing dependencies warnings
     * in useEffects with this function.
     */
    const handleAttemptFail = useCallback(() => {
        const count = countRef.current + 1;
        if (count >= CAPTCHA_ATTEMPTS_LIMIT) {
            setPassed(false);
        }
        countRef.current = count;
    }, []);

    /**
     * Function is called when:
     * - Captcha is passed
     * - Captcha value is expired (with null as argument)
     */
    const handlePass = useCallback((value: string | null) => {
        setPassed(!!value);
    }, []);

    const Captcha = useMemo(
        () => <ReCaptcha sitekey={CAPTCHA_KEY} onChange={handlePass} hl="fr" />,
        [handlePass],
    );

    return useMemo(
        () => ({ captchaPassed, handleAttemptFail, Captcha }),
        [captchaPassed, handleAttemptFail, Captcha],
    );
};
