import React, { useCallback } from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import AddIcon from '@material-ui/icons/Add';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { exportLocks, setFilter } from 'ducks/locks';
import { FilterTypes } from 'ducks/locks/types';
import { selectFilters } from 'ducks/locks/selectors';

export const SearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accessToPages = useSelector(selectAccessToPages);
    const filters = useSelector(selectFilters);

    const redirectToLockCreation = useCallback(
        () => dispatch(push(routes.NEW_LOCK)),
        [dispatch],
    );

    const handleSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setFilter({
                    filterType: FilterTypes.Search,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    const handleExport = useCallback(() => dispatch(exportLocks()), [dispatch]);

    return (
        <FormGrid columnAmount={2} rowHeight="20px" padding="10px 0">
            <SearchComponent
                placeholder={t('search')}
                onChange={handleSearch}
                defaultValue={filters[FilterTypes.Search]}
            />
            <FormGrid columnAmount={2} rowHeight="20px" padding="0">
                {accessToPages[Permissions.VEHICLE_CREATE] && (
                    <ButtonComponent
                        text={t('add')}
                        startIcon={<AddIcon />}
                        handleClick={redirectToLockCreation}
                    />
                )}
                {accessToPages[Permissions.VEHICLE_EXPORT] && (
                    <ButtonComponent
                        text={t('download')}
                        startIcon={<SaveAltIcon />}
                        handleClick={handleExport}
                    />
                )}
            </FormGrid>
        </FormGrid>
    );
};
