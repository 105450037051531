import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableComponent } from 'components/common/Table';
import { useTranslation } from 'react-i18next';
import { routes } from 'constants/common';
import { fetchUsers, selectIsLoading, setStringFilter } from 'ducks/users';
import { getColumns } from './columns';
import { selectUsers, selectSort } from 'ducks/users/selectors';
import { UserToolbar } from './UserToolbar';
import { UserSearchToolbar } from './UserSearchToolbar';
import { Pagination } from './Pagination';
import { useParams, useHistory } from 'react-router';
import { User, StringFilterTypes } from 'ducks/users/types';
import { FullScreenLoaderComponent } from '../../../common/Loader/FullScreenLoader';

export const CompanyUsers = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { companyId } = useParams<{ companyId?: string }>();
    const history = useHistory();
    const columns = getColumns(t, dispatch);
    const { usersById, userIds } = useSelector(selectUsers);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    useEffect(() => {
        companyId
            ? dispatch(
                  setStringFilter({
                      filterType: StringFilterTypes.CompanyId,
                      value: companyId,
                  }),
              )
            : dispatch(fetchUsers());
    }, [dispatch, companyId]);

    const redirectToUser = useCallback(
        (id: number) =>
            history.push({
                pathname: routes.USER.replace(':userId', `${id}`),
                state: { companyId },
            }),
        [history, companyId],
    );

    return (
        <>
            {loading && <FullScreenLoaderComponent />}

            <TableComponent
                actions={[
                    {
                        icon: 'visibility',
                        tooltip: t('tripList.seeDetails'),
                        onClick: (event, rowData: User) =>
                            rowData && redirectToUser(rowData.id),
                    },
                ]}
                onRowClick={(event, rowData: User | undefined) =>
                    rowData && redirectToUser(rowData.id)
                }
                toolbar={UserToolbar}
                searchComponent={UserSearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={userIds.map(id => ({
                    ...usersById[id],
                }))}
                title={t('usersList.title')}
                sort={sort}
            />
        </>
    );
};
