import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid, SelectComponent } from '../../../common';
import {
    resetFilters,
    selectFilters,
    setFilter,
} from 'ducks/vehicleTelemetryEvents';
import {
    BikeAndVaeTelemetryEventsType,
    FilterTypes,
    ScooterTelemetryEventsType,
} from 'ducks/vehicleTelemetryEvents/types';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { DateTimeInput } from '../../../common/DateTimeInput';
import { isMoment, Moment } from 'moment';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';

interface Toolbar {
    messageCodeOptions: {
        label: string;
        value: ScooterTelemetryEventsType | BikeAndVaeTelemetryEventsType | '';
    }[];
    filtersError?: string;
    type: 'scooter' | 'vae';
}
export const Toolbar: React.FC<Toolbar> = ({
    messageCodeOptions,
    filtersError,
    type,
}: Toolbar) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { startTime, endTime, ...filters } = useSelector(selectFilters);
    React.useEffect(() => {
        if (type === 'scooter') {
            dispatch(
                setFilter({
                    filterType: FilterTypes.MessageCodes,
                    value: ScooterTelemetryEventsType.location,
                }),
            );
        }
        dispatch(
            setFilter({
                filterType: FilterTypes.StartTime,
                value: new Date(
                    new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
                ).toISOString(),
            }),
        );
        dispatch(
            setFilter({
                filterType: FilterTypes.EndTime,
                value: new Date(new Date().getTime()).toISOString(),
            }),
        );
    }, [dispatch, type]);
    const getCommonError = useCallback(time => {
        if (!!time && !isMoment(moment(time))) {
            return 'errors.incorrectTimeFormat';
        }
        if (moment(time).isAfter(new Date())) {
            return 'errors.dateAfterToday';
        }
        return '';
    }, []);

    const endTimeError = useMemo(() => {
        if (moment(endTime).isBefore(moment(startTime))) {
            return 'errors.endTime';
        }
        return getCommonError(endTime);
    }, [startTime, endTime, getCommonError]);

    const startTimeError = useMemo(() => {
        return getCommonError(new Date(startTime));
    }, [startTime, getCommonError]);

    const handleChangeTime = useCallback(
        (time: string | Moment, filterType: FilterTypes) => {
            //this code doesn't allow us to send requests if date is not valid
            if (time === '') {
                return dispatch(
                    setFilter({
                        filterType,
                        value: '',
                    }),
                );
            }
            if (isMoment(time)) {
                dispatch(
                    setFilter({
                        filterType,
                        value: new Date(time.toString()).toISOString(),
                    }),
                );
            }
        },
        [dispatch],
    );

    const handleChangeMessageCodeFilter = useCallback(
        e => {
            dispatch(
                setFilter({
                    filterType: FilterTypes.MessageCodes,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
    }, [dispatch]);

    return (
        <Box display="flex" alignItems="center">
            <FormGrid columnAmount={3} padding="10px 0">
                <SelectComponent
                    label={t('scooterTelemetryEvents.type')}
                    onChange={handleChangeMessageCodeFilter}
                    value={filters[FilterTypes.MessageCodes] || ''}
                    options={messageCodeOptions}
                />
                <DateTimeInput
                    label={t('tripList.startDate')}
                    id="startDate"
                    value={startTime || ''}
                    hasError={!!startTimeError}
                    helperText={t(startTimeError)}
                    onChange={e => {
                        handleChangeTime(e, FilterTypes.StartTime);
                    }}
                />
                <DateTimeInput
                    label={t('tripList.endDate')}
                    id="endDate"
                    value={endTime || ''}
                    hasError={!!endTimeError}
                    helperText={t(endTimeError)}
                    onChange={e => {
                        handleChangeTime(e, FilterTypes.EndTime);
                    }}
                />
            </FormGrid>
            <Box margin="10px 10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
            <Box
                mb="10px"
                mt="10px"
                color="error.main"
                display="flex"
                alignItems="center"
                height="100%">
                <Typography>{filtersError}</Typography>
            </Box>
        </Box>
    );
};
