import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid, SelectComponent } from 'components/common';
import {
    selectFilters,
    setStringFilter,
    resetFilters,
    fetchNews,
} from 'ducks/news';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { useParams } from 'react-router';

import { NewsTypes, StringFilterTypes } from 'ducks/news/types';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';

export const Toolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companyId } = useParams<{ companyId?: string }>();
    const filters = useSelector(selectFilters);
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);

    useEffect(() => {
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    const companyOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );

    const typeOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...Object.values(NewsTypes).map(type => ({
                label: type,
                value: type,
            })),
        ],
        [],
    );

    const handleChangeFilter = (
        e: React.ChangeEvent<HTMLInputElement>,
        filterType: StringFilterTypes,
    ) => {
        dispatch(
            setStringFilter({
                filterType,
                value: e.target.value,
            }),
        );
    };

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        dispatch(fetchNews());
    }, [dispatch]);

    return (
        <FormGrid columnAmount={3} padding="10px 0">
            <SelectComponent
                label={t('news.type')}
                onChange={e => handleChangeFilter(e, StringFilterTypes.Type)}
                value={filters[StringFilterTypes.Type] || ''}
                options={typeOptions}
            />
            {!companyId && (
                <SelectComponent
                    label={t('news.companyName')}
                    onChange={e =>
                        handleChangeFilter(e, StringFilterTypes.Company)
                    }
                    value={filters[StringFilterTypes.Company] || ''}
                    options={companyOptions}
                />
            )}
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};
