import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { AppComponent } from './components/app/App';
import { configureAppStore } from 'ducks/root/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import rootSaga from 'ducks/root/rootSaga';

const { store, runSaga } = configureAppStore();
runSaga(rootSaga);

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <AppComponent />
        </Provider>
    </I18nextProvider>,
    document.getElementById('root'),
);
