import { RootState } from '../root';

export const selectBoUsers = (state: RootState) => {
    return {
        boUsersById: state.boUser.boUsersById,
        boUserIds: state.boUser.boUserIds,
    };
};

export const selectFilters = (state: RootState) => state.boUser.filters;
export const selectSort = (state: RootState) => state.boUser.sort;
export const selectPagination = (state: RootState) => state.boUser.pagination;
export const selectError = (state: RootState) => state.boUser.error;
export const selectIsLoading = (state: RootState) => state.boUser.loading;
export const selectUserHasUnsavedChanges = (state: RootState) =>
    state.boUser.userHasUnsavedChanges;

export const selectCurrentBoUser = (state: RootState) =>
    state.boUser.currentUser;
