import { call, put, takeLatest } from 'redux-saga/effects';
import {
    fetchAgreements,
    fetchAgreementsError,
    fetchAgreementsSuccess,
    updateCGAU,
    updateCGAUSuccess,
    updatePolicy,
    updatePolicySuccess,
} from './index';
import {
    getCurrentAgreements,
    getFutureAgreements,
    updateAgreementsService,
} from './service';
import { Agreement, AgreementTypes } from './types';
import { PayloadAction } from '@reduxjs/toolkit';

function* fetchAgreementsSaga() {
    try {
        const currentPolicy: Agreement = yield call(
            getCurrentAgreements,
            AgreementTypes.APPLICATION_POLICY,
        );

        const currentCGAU: Agreement = yield call(
            getCurrentAgreements,
            AgreementTypes.APPLICATION_CGAU,
        );

        const futurePolicy: Agreement = yield call(
            getFutureAgreements,
            AgreementTypes.APPLICATION_POLICY,
        );

        const futureCGAU: Agreement = yield call(
            getFutureAgreements,
            AgreementTypes.APPLICATION_CGAU,
        );

        yield put(
            fetchAgreementsSuccess({
                featureAgreements: {
                    policy: futurePolicy,
                    cgau: futureCGAU,
                },
                currentAgreements: {
                    policy: currentPolicy,
                    cgau: currentCGAU,
                },
            }),
        );
    } catch (error) {
        yield put(fetchAgreementsError({ error: (error as Error)?.message }));
    }
}

function* updatePolicySaga({ payload }: PayloadAction<Agreement>) {
    try {
        yield call(updateAgreementsService, AgreementTypes.APPLICATION_POLICY, {
            ...payload,
            version: new Date(payload.version).toISOString(),
        });

        yield put(updatePolicySuccess());
        yield put(fetchAgreements());
    } catch (error) {
        yield put(fetchAgreementsError({ error: (error as Error)?.message }));
    }
}

function* updateCGAUSaga({ payload }: PayloadAction<Agreement>) {
    try {
        yield call(updateAgreementsService, AgreementTypes.APPLICATION_CGAU, {
            ...payload,
            version: new Date(payload.version).toISOString(),
        });

        yield put(updateCGAUSuccess());
        yield put(fetchAgreements());
    } catch (error) {
        yield put(fetchAgreementsError({ error: (error as Error)?.message }));
    }
}

export default function* agreementsSagas() {
    yield takeLatest(fetchAgreements.type, fetchAgreementsSaga);
    yield takeLatest(updatePolicy.type, updatePolicySaga);
    yield takeLatest(updateCGAU.type, updateCGAUSaga);
}
