import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    fetchStations,
    fetchStationsError,
    fetchStationsSuccess,
    selectSort,
    selectPagination,
    setFilter,
    setSort,
    setPage,
    selectFilters,
    selectCurrentStation,
    createStationSuccess,
    createStation,
    createStationError,
    fetchStation,
    fetchStationSuccess,
    fetchStationError,
    updateStation,
    updateStationError,
    updateStationSuccess,
    deleteStation,
    deleteStationSuccess,
    deleteStationError,
    addBeam,
    addBeamSuccess,
    addBeamError,
    deleteBeam,
    deleteBeamSuccess,
    deleteBeamError,
    changeStationActivationStatus,
    fetchSyncStation,
    fetchSyncStationError,
} from './index';
import { Pagination, Sort } from '../common/types';
import { mapFilters, mapPagination, mapSorting } from '../common/mappers';
import { mapFetchedPagination } from '../common/pagination';
import {
    addBeamService,
    deleteBeamService,
    deleteStationService,
    getStation,
    getStations,
    postStation,
    putStation,
    putStationActivationStatus,
    syncStation,
} from './service';
import {
    FetchedStation,
    Filters,
    Station,
    StationActivationStatuses,
    StationResponse,
} from './types';
import { mapFetchedStations, mapStation } from './mapper';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { RequestError } from 'helpers/request';

function* fetchStationsSaga() {
    try {
        const sort: Sort = yield select(selectSort);
        const filters: Filters = yield select(selectFilters);
        const pagination: Pagination = yield select(selectPagination);
        const data: StationResponse = yield call(getStations, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { stationIds, stationsById } = mapFetchedStations(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchStationsSuccess({
                stationIds,
                stationsById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchStationsError({ error: (error as Error)?.message }));
    }
}

function* createStationSaga({ payload }: PayloadAction<Station>) {
    try {
        yield call(postStation, {
            ...payload,
        });
        yield put(createStationSuccess());
        yield put(push(routes.STATIONS));
    } catch (error) {
        yield put(
            createStationError({
                error:
                    (error as RequestError)?.data?.technicalMessage ||
                    (error as RequestError)?.message,
            }),
        );
    }
}

function* fetchStationSaga({ payload }: PayloadAction<number>) {
    try {
        const data: FetchedStation = yield call(getStation, payload);
        yield put(fetchStationSuccess(mapStation(data)));
    } catch (error) {
        yield put(fetchStationError({ error: (error as Error)?.message }));
    }
}

function* updateStationSaga({ payload }: PayloadAction<Station>) {
    try {
        const data: FetchedStation = yield call(putStation, payload.id, {
            ...payload,
        });
        yield put(updateStationSuccess(mapStation(data)));
        yield put(push(routes.STATIONS));
    } catch (error) {
        yield put(
            updateStationError({
                error:
                    (error as RequestError)?.data?.technicalMessage ||
                    (error as RequestError)?.message,
            }),
        );
    }
}

function* deleteStationSaga({ payload }: PayloadAction<number>) {
    try {
        yield call(deleteStationService, payload);
        yield put(deleteStationSuccess());
        yield put(push(routes.STATIONS));
    } catch (error) {
        yield put(deleteStationError({ error: (error as Error)?.message }));
    }
}

function* addBeamSaga({
    payload,
}: PayloadAction<{
    stationId: number;
    beamId: string;
    readerName?: string;
}>) {
    try {
        yield call(addBeamService, {
            stationId: payload.stationId,
            beamId: payload.beamId,
            readerName: payload.readerName,
        });
        yield put(addBeamSuccess());
        yield put(fetchStation(payload.stationId));
    } catch (error) {
        yield put(fetchStation(payload.stationId));
        yield put(addBeamError({ error: (error as Error)?.message }));
    }
}

function* deleteBeamSaga({
    payload,
}: PayloadAction<{
    stationId: number;
    beamId: string;
}>) {
    try {
        yield call(deleteBeamService, {
            stationId: payload.stationId,
            beamId: payload.beamId,
        });
        yield put(deleteBeamSuccess());
        yield put(fetchStation(payload.stationId));
    } catch (error) {
        yield put(fetchStation(payload.stationId));
        yield put(deleteBeamError({ error: (error as Error)?.message }));
    }
}

function* changeStationActivationStatusSaga({
    payload: { activationStatus, id },
}: PayloadAction<{ activationStatus: StationActivationStatuses; id: number }>) {
    try {
        const data: FetchedStation = yield call(
            putStationActivationStatus,
            id,
            activationStatus,
        );
        yield put(fetchStationSuccess(mapStation(data)));
    } catch (error) {
        yield put(fetchStationError({ error: (error as Error)?.message }));
    }
}

function* fetchSyncStationSaga() {
    try {
        const { name, id, serviceId }: Station = yield select(
            selectCurrentStation,
        );

        yield call(syncStation, { name, serviceId });

        yield put(fetchStation(id));
    } catch (error) {
        yield put(
            fetchSyncStationError({
                error:
                    (error as RequestError)?.data?.technicalMessage ||
                    (error as RequestError)?.message,
            }),
        );
    }
}

export default function* stationsSagas() {
    yield takeLatest(fetchStations.type, fetchStationsSaga);
    yield takeLatest(setFilter.type, fetchStationsSaga);
    yield takeLatest(setSort.type, fetchStationsSaga);
    yield takeLatest(setPage.type, fetchStationsSaga);
    yield takeLatest(createStation.type, createStationSaga);
    yield takeLatest(fetchStation.type, fetchStationSaga);
    yield takeLatest(updateStation.type, updateStationSaga);
    yield takeLatest(deleteStation.type, deleteStationSaga);
    yield takeLatest(addBeam.type, addBeamSaga);
    yield takeLatest(deleteBeam.type, deleteBeamSaga);
    yield takeLatest(
        changeStationActivationStatus.type,
        changeStationActivationStatusSaga,
    );
    yield takeLatest(fetchSyncStation.type, fetchSyncStationSaga);
}
