import React from 'react';
import {
    TextField,
    InputAdornment,
    Typography,
    createStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from 'themes/colors';
import { StyledFormControlLabel } from '../FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (value: string | undefined) =>
    makeStyles(() =>
        createStyles({
            inputStyle: {
                visibility: 'hidden',
            },
            colorLabel: {
                color: value,
                fontSize: '14px !important',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            },
            colorWrap: {
                boxSizing: 'border-box',
                width: '60%',
            },
        }),
    );

interface IInputInterface {
    hasError?: boolean;
    label: string;
    type?: string;
    value?: string;
    onChange?: (
        event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    helperText?: string;
    valid?: boolean;
    id?: string;
    withoutCircleIcon?: boolean;
    disabled?: boolean;
}

export const ColorInputComponent: React.FunctionComponent<IInputInterface> = ({
    hasError,
    label,
    value,
    onChange,
    onBlur,
    helperText,
    valid,
    type,
    id,
    disabled = false,
}: IInputInterface) => {
    const classes = useStyles(value)();
    return (
        <StyledFormControlLabel
            labelPlacement="top"
            label={label}
            control={
                <TextField
                    fullWidth
                    type={type}
                    value={value}
                    error={hasError}
                    disabled={disabled}
                    id={id || label}
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={helperText}
                    InputProps={{
                        classes: { input: classes.inputStyle },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.colorWrap}>
                                <Typography
                                    className={classes.colorLabel}
                                    variant="body2">
                                    {value}
                                </Typography>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                {hasError ? (
                                    <CancelIcon
                                        color="error"
                                        fontSize="inherit"
                                    />
                                ) : (
                                    valid && (
                                        <>
                                            {!disabled && (
                                                <CancelIcon
                                                    color="action"
                                                    fontSize="inherit"
                                                    onClick={() =>
                                                        onChange && onChange()
                                                    }
                                                />
                                            )}
                                            <CheckCircleIcon
                                                fontSize="inherit"
                                                style={{ color: colors.green }}
                                            />
                                        </>
                                    )
                                )}
                            </InputAdornment>
                        ),
                    }}
                />
            }
        />
    );
};
