import React, { useCallback, useMemo } from 'react';
import {
    FormGrid,
    InputComponent,
    SearchComponent,
    SelectComponent,
} from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchVehicles,
    selectFilters,
    setCurrentVehicleType,
    setFetchAllVehicles,
    setStringFilter,
    StringFilterTypes,
    selectIsLoading,
    resetFilters,
} from 'ducks/vehicles/common';
import { useTranslation } from 'react-i18next';
import { VehiclesMap } from './Map';
import { UpdateVehicleStatusComponent } from '../../common/UpdateVehicleStatusComponent';
import { VehicleTypes } from 'ducks/vehicles/common/types';
import {
    fetchAllServices,
    selectAllServicesById,
    selectAllServicesIds,
} from 'ducks/services';
import { Typography } from '@material-ui/core';
import { DateTimeInput } from 'components/common/DateTimeInput';
import moment, { Moment } from 'moment';
import { getDisabledTime } from 'tools/date';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const VehiclesMapComponent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    React.useEffect(() => {
        dispatch(setFetchAllVehicles(true));
        dispatch(fetchVehicles());
        return () => {
            dispatch(resetFilters());
        };
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(fetchAllServices({ onlyEnabled: false }));
    }, [dispatch]);

    const allServicesById = useSelector(selectAllServicesById);
    const allServiceIds = useSelector(selectAllServicesIds);

    const serviceOptions = React.useMemo(
        () =>
            allServiceIds.map(id => ({
                value: id,
                label: allServicesById[id].name,
            })),
        [allServiceIds, allServicesById],
    );
    const handleSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.Search,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );
    const { types } = useSelector(selectFilters);
    const { serviceIds } = useSelector(selectFilters);
    const { lastGpsDate } = useSelector(selectFilters);

    const vehicleTypeOptions = useMemo(() => {
        return [
            {
                label: t('tripList.bike'),
                value: VehicleTypes.BIKE,
            },
            {
                label: t('tripList.vae'),
                value: VehicleTypes.VAE,
            },
            {
                label: t('tripList.scooter'),
                value: VehicleTypes.SCOOTER,
            },
        ];
    }, [t]);

    const setServiceIdsFilter = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) =>
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.ServiceIds,
                    value: e.target.value,
                }),
            ),
        [dispatch],
    );
    const setCurrentVehicleTypeFilter = React.useCallback(
        e => {
            dispatch(
                setCurrentVehicleType({
                    types: e.target.value,
                    isRefreshCheck: false,
                }),
            );
        },
        [dispatch],
    );
    const [batteryLevel, setBatteryLevel] = React.useState<string | null>(null);

    const setBatteryLevelFilter = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setBatteryLevel(e.target.value);
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.BatteryLevel,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    const setLastGpsDateFilter = React.useCallback(
        (e: Moment | string) =>
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.LastGpsDate,
                    value: moment(e).endOf('day').toISOString(),
                }),
            ),
        [dispatch],
    );

    const isTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                max: moment().endOf('day'),
            }),
        [],
    );

    const loading = useSelector(selectIsLoading);

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
    }, [dispatch]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <FormGrid columnAmount={1} padding="0" rowHeight="40px">
                <FormGrid columnAmount={2} padding="0" rowHeight="20px">
                    <Typography variant="h1">{t('vehicles.map')}</Typography>
                    <Box ml="auto">
                        <SearchComponent
                            placeholder={t('search')}
                            onChange={handleSearch}
                        />
                    </Box>
                </FormGrid>
                <FormGrid columnAmount={8} padding="0 0 20px" rowHeight="40px">
                    <SelectComponent
                        multiple
                        label={t('vehicles.services')}
                        onChange={setServiceIdsFilter}
                        value={serviceIds || []}
                        options={serviceOptions}
                    />
                    <SelectComponent
                        multiple
                        label={t('tripList.vehicleType')}
                        onChange={setCurrentVehicleTypeFilter}
                        value={types || []}
                        options={vehicleTypeOptions}
                    />
                    <InputComponent
                        hasError={
                            Number(batteryLevel) > 100 ||
                            Number(batteryLevel) < 0 ||
                            isNaN(Number(batteryLevel))
                        }
                        withoutCircleIcon
                        onChange={setBatteryLevelFilter}
                        InputProps={{
                            inputProps: { min: 0, max: 100 },
                        }}
                        type="number"
                        label={t('vehicles.battery')}
                    />
                    <DateTimeInput
                        id="lastGPSDate"
                        value={lastGpsDate || ''}
                        onChange={setLastGpsDateFilter}
                        label={t('vehicles.lastGPSDate')}
                        timeFormat={false}
                        getDisabledTime={isTimeDisabled}
                    />
                    <UpdateVehicleStatusComponent />
                    <Box marginTop="10px">
                        <ResetFiltersButton
                            handleResetFilters={handleResetFilters}
                        />
                    </Box>
                </FormGrid>
            </FormGrid>
            <VehiclesMap />
        </>
    );
};
