import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/vehicles/common';
import { LinkComponent } from '../../common';
import { routes } from '../../../constants/common';
import { VehicleStatus, Vehicle } from 'ducks/vehicles/common/types';
import { Modals, openModal } from 'ducks/ui';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('vehicles.id'),
            field: 'id',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'ID',
                    }),
                ),
        },
        {
            title: t('vehicles.serial'),
            field: 'serial',
            sorting: false,
        },
        {
            title: t('vehicles.plate'),
            field: 'plate',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'plate',
                    }),
                ),
        },
        {
            title: t('vehicles.gps'),
            field: t('gps'),
            // eslint-disable-next-line react/display-name
            render: (rowProps: { lat: number; lng: number }) => (
                <LinkComponent
                    text={`${rowProps.lat || '-'}, ${rowProps.lng || '-'}`}
                    handleClick={event => {
                        event.stopPropagation();
                        dispatch(
                            openModal({
                                modalType: Modals.MAP_WITH_POINT,
                                modalProps: {
                                    position: {
                                        lat: rowProps.lat,
                                        lng: rowProps.lng,
                                    },
                                    title: t('vehicles.vehiclePosition'),
                                },
                            }),
                        );
                    }}
                />
            ),
            sorting: false,
        },
        {
            title: t('vehicles.battery'),
            field: 'batteryLevel',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'batteryLevel',
                    }),
                ),
        },
        {
            title: t('vehicles.status'),
            field: 'status',
            // eslint-disable-next-line react/display-name
            render: (rowProps: { status: VehicleStatus }) => (
                <span>{t(`scooterStatuses.${rowProps.status}`)}</span>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'status',
                    }),
                ),
        },
        {
            title: t('vehicles.companyName'),
            field: 'companyName',
            // eslint-disable-next-line react/display-name
            render: (rowProps: { companyName: string; companyId: number }) => (
                <LinkComponent
                    text={rowProps.companyName}
                    handleClick={event => {
                        event.stopPropagation();
                        window.open(
                            routes.COMPANY.replace(
                                ':companyId',
                                `${rowProps.companyId}`,
                            ),
                            '_blank',
                        );
                    }}
                />
            ),
            sorting: false,
        },
        {
            title: t('vehicles.userName'),
            field: 'userName',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: Vehicle) => (
                <span>{`${rowProps.userName} ${rowProps.userLastName}`}</span>
            ),
        },
        {
            title: t('vehicles.updated'),
            field: 'updated',
            // eslint-disable-next-line react/display-name
            render: (rowProps: Vehicle) => (
                <span>{new Date(rowProps.updated).toLocaleString()}</span>
            ),
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'updated',
                    }),
                ),
        },
        {
            title: t('vehicles.lastCommunicationDate'),
            field: 'lastCommunicationTime',
            // eslint-disable-next-line react/display-name
            render: (rowProps: Vehicle) => (
                <span>
                    {rowProps.lastSync
                        ? new Date(rowProps.lastSync).toLocaleString()
                        : ''}
                </span>
            ),
        },
    ];
};
