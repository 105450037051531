import {
    call,
    put,
    select,
    takeLatest,
    all,
    takeEvery,
} from 'redux-saga/effects';
import {
    anonymizeProfile,
    anonymizeProfileError,
    anonymizeProfileSuccess,
    anonymizeUser,
    anonymizeUserError,
    anonymizeUserSuccess,
    fetchUser,
    fetchUserError,
    fetchUsers,
    fetchUsersError,
    fetchUsersSuccess,
    fetchUserSuccess,
    redirectToUsersList,
    redirectToUsersListSuccess,
    requestExportUsers,
    requestExportUsersSuccess,
    setFilter,
    setPage,
    setSort,
    setStringFilter,
    updateUserProfile,
    updateUserProfileError,
    updateUserProfileSuccess,
    updateUser,
    updateUserError,
    updateUserSuccess,
    toggleUserError,
    toggleUserSuccess,
    toggleUserEnable,
    toggleUserProfileError,
    toggleUserProfileSuccess,
    toggleUserProfileStatus,
    updateSubscription,
    updateSubscriptionError,
    updateSubscriptionSuccess,
    updateUserDocument,
    updateUserDocumentError,
    updateUserDocumentSuccess,
    deleteUserDocument,
    deleteUserDocumentError,
    deleteUserDocumentSuccess,
    fetchUserDocument,
    fetchUserDocumentSuccess,
    fetchUserDocumentError,
    deleteCreditCard,
    addEndUserPenalty,
} from './index';
import {
    selectCurrentUser,
    selectFilters,
    selectPagination,
    selectSort,
} from './selectors';
import { Pagination, Sort } from '../common/types';
import { mapFilters, mapPagination, mapSorting } from '../common/mappers';
import { mapFetchedPagination } from '../common/pagination';
import {
    CurrentUser,
    FetchedCurrentUser,
    FetchedUser,
    Filters,
    Profile,
    ProfileStatuses,
    UserResponse,
} from './types';
import {
    anonymizeProfileService,
    anonymizeUserService,
    exportUsers,
    getUser,
    getUserDocument,
    getUsers,
    postUserDocument,
    removeUserDocument,
    requestDeleteUserService,
    updateSubscriptionService,
    updateUserProfileEmailService,
    updateUserProfileStatusService,
    updateUserService,
    updateUserStatusService,
    deleteEndUserCreditCard,
    addPenalty,
} from './service';
import { mapFetchedUser, mapFetchedUsers } from './mapper';
import { downloadBlob } from 'tools/file';
import { PayloadAction } from '@reduxjs/toolkit';
import { history } from '../root';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { setActiveCompanyTab } from '../companies';
import { find } from 'lodash';
import { closeModal } from '../ui';
import { fetchUserPaymentHistory } from 'ducks/paymentHistory';
import { FileProperties } from 'ducks/companies/types';

function* fetchUsersSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: UserResponse = yield call(getUsers, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { userIds, usersById } = mapFetchedUsers(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchUsersSuccess({
                userIds,
                usersById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchUsersError({ error: (error as Error)?.message }));
    }
}

function* exportUsersSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const { file, fileName } = yield call(exportUsers, mapFilters(filters));
        downloadBlob(file, fileName);
        yield put(requestExportUsersSuccess());
    } catch (error) {
        yield put(fetchUsersError({ error: (error as Error)?.message }));
    }
}

function* fetchUserSaga({ payload }: PayloadAction<number>) {
    try {
        const data: FetchedCurrentUser = yield call(getUser, payload);
        const preparedUser = mapFetchedUser(data);
        yield put(fetchUserSuccess(preparedUser));
    } catch (error) {
        yield put(fetchUserError({ error: (error as Error)?.message }));
    }
}

function* anonymizeUserSaga() {
    try {
        const userId: number = yield select(
            state => state.user.currentUser.user.id,
        );
        yield call(anonymizeUserService, userId);
        yield put(anonymizeUserSuccess());
        yield put(redirectToUsersList());
    } catch (error) {
        yield put(anonymizeUserError({ error: (error as Error)?.message }));
    }
}

function* toggleUserStatusSaga({
    payload,
}: PayloadAction<{ id: number; isEnabled: boolean }>) {
    try {
        yield call(updateUserStatusService, {
            id: payload.id,
            isEnabled: payload.isEnabled,
        });
        yield put(toggleUserSuccess());
        yield put(fetchUser(payload.id));
    } catch (error) {
        yield put(toggleUserError({ error: (error as Error)?.message }));
    }
}

function* updateUserSaga({ payload }: PayloadAction<FetchedUser>) {
    try {
        yield call(updateUserService, payload.id, payload);
        yield put(updateUserSuccess());
        yield put(redirectToUsersList());
    } catch (error) {
        yield put(updateUserError({ error: (error as Error)?.message }));
    }
}

function* redirectToUsersListSaga() {
    try {
        const historyState = history.location.state as { companyId?: number };
        if (historyState && historyState.companyId) {
            yield put(setActiveCompanyTab(3));
            yield put(
                push(
                    routes.COMPANY.replace(
                        ':companyId',
                        `${historyState.companyId}`,
                    ),
                ),
            );
        } else {
            yield put(push(routes.COMPANIES));
        }
        yield put(redirectToUsersListSuccess());
    } catch (error) {
        yield put(updateUserError({ error: (error as Error)?.message }));
    }
}

function* anonymizeProfileSaga({ payload }: PayloadAction<number>) {
    try {
        const userId: number = yield select(
            state => state.user.currentUser.user.id,
        );
        yield call(anonymizeProfileService, userId, payload);
        yield put(anonymizeProfileSuccess());
        const user: CurrentUser = yield select(selectCurrentUser);
        yield put(fetchUser(user.user.id));
    } catch (error) {
        yield put(anonymizeProfileError({ error: (error as Error)?.message }));
    }
}

function* toggleUserProfileStatusSaga({
    payload,
}: PayloadAction<{ id: number; profileId: number; status: ProfileStatuses }>) {
    try {
        yield call(updateUserProfileStatusService, {
            userId: payload.id,
            profileId: payload.profileId,
            status: payload.status,
        });
        yield put(toggleUserProfileSuccess());
        yield put(fetchUser(payload.id));
    } catch (error) {
        yield put(toggleUserProfileError({ error: (error as Error)?.message }));
    }
}

function* updateUserProfileSaga({
    payload,
}: PayloadAction<{
    profile: Profile;
    servicesForDeletion: number[];
}>) {
    try {
        const { profile, servicesForDeletion } = payload;
        const user: CurrentUser = yield select(selectCurrentUser);
        const profileForChanges = find(user.profiles, ['id', profile.id]);

        if (profileForChanges && profileForChanges.email !== profile.email) {
            yield call(updateUserProfileEmailService, {
                userId: user.user.id,
                profileId: profileForChanges.id,
                email: profile.email,
            });
        }

        if (servicesForDeletion.length > 0) {
            yield all(
                servicesForDeletion.map((id: number) =>
                    call(requestDeleteUserService, user.user.id, id),
                ),
            );
        }

        yield put(updateUserProfileSuccess());
        yield put(redirectToUsersList());
    } catch (error) {
        yield put(
            updateUserProfileError({
                error: (error as Error)?.message,
                profileId: payload.profile.id,
            }),
        );
    }
}

function* updateSubscriptionSaga({
    payload,
}: PayloadAction<{
    subscriptionId: number;
    profileId: number;
    params: { startDate?: string; endDate?: string };
}>) {
    try {
        const user: CurrentUser = yield select(selectCurrentUser);
        yield call(updateSubscriptionService, {
            userId: user.user.id,
            subscriptionId: payload.subscriptionId,
            params: payload.params,
        });
        yield put(updateSubscriptionSuccess());
        yield put(closeModal());
        yield put(fetchUser(user.user.id));
    } catch (error) {
        yield put(
            updateSubscriptionError({
                error: (error as Error)?.message,
                profileId: payload.profileId,
            }),
        );
    }
}
function* uploadDocumentSaga({
    payload,
}: PayloadAction<{ userId: number; file: File }>) {
    try {
        const data: FetchedCurrentUser = yield call(postUserDocument, {
            file: payload.file,
            userId: payload.userId,
        });
        yield put(updateUserDocumentSuccess());
        const preparedUser = mapFetchedUser(data);
        yield put(fetchUserSuccess(preparedUser));
    } catch (error) {
        yield put(
            updateUserDocumentError({ error: (error as Error)?.message }),
        );
    }
}
function* deleteDocumentSaga({
    payload,
}: PayloadAction<{ userId: number; filename: string }>) {
    try {
        const data: FetchedCurrentUser = yield call(removeUserDocument, {
            filename: payload.filename,
            userId: payload.userId,
        });
        yield put(deleteUserDocumentSuccess());
        const preparedUser = mapFetchedUser(data);
        yield put(fetchUserSuccess(preparedUser));
    } catch (error) {
        yield put(
            deleteUserDocumentError({ error: (error as Error)?.message }),
        );
    }
}
function* fetchDocumentSaga({
    payload,
}: PayloadAction<{ userId: number; filename: string }>) {
    try {
        const data: FileProperties = yield call(getUserDocument, {
            filename: payload.filename,
            userId: payload.userId,
        });
        yield put(
            fetchUserDocumentSuccess({
                filename: payload.filename,
                file: data,
            }),
        );
    } catch (error) {
        yield put(fetchUserDocumentError({ error: (error as Error)?.message }));
    }
}

function* deleteEndUserCreditCardSaga({
    payload: { userId, profileId },
}: PayloadAction<{ userId: number; profileId: number }>) {
    try {
        yield call(deleteEndUserCreditCard, {
            endUser: userId,
            profileId: profileId,
        });
        yield put(toggleUserSuccess());
        yield put(fetchUser(userId));
        yield put(fetchUserPaymentHistory({ userId, profileId }));
    } catch (error) {
        yield put(
            updateUserProfileError({
                error: (error as Error)?.message,
                profileId,
            }),
        );
    }
}
function* addPenaltySaga({
    payload: { amount, comment, userId, profileId, tripId },
}: PayloadAction<{
    // Amount in Euros
    amount: string;
    comment?: string;
    userId: number;
    profileId: number;
    tripId?: string;
}>) {
    try {
        yield call(addPenalty, {
            userId,
            profileId,
            params: {
                // Stripe uses cents, not euros
                amount: Number(amount) * 100,
                comment,
                tripId: Number(tripId),
            },
        });
        yield put(toggleUserSuccess());
        yield put(fetchUser(userId));
        yield put(fetchUserPaymentHistory({ userId, profileId }));
    } catch (error) {
        yield put(
            updateUserProfileError({
                error: (error as Error)?.message,
                profileId,
            }),
        );
    }
}

export default function* userSagas() {
    yield takeLatest(fetchUsers.type, fetchUsersSaga);
    yield takeLatest(fetchUser.type, fetchUserSaga);
    yield takeLatest(setFilter.type, fetchUsersSaga);
    yield takeLatest(setStringFilter.type, fetchUsersSaga);
    yield takeLatest(setSort.type, fetchUsersSaga);
    yield takeLatest(setPage.type, fetchUsersSaga);
    yield takeLatest(requestExportUsers.type, exportUsersSaga);
    yield takeLatest(anonymizeUser.type, anonymizeUserSaga);
    yield takeLatest(updateUser.type, updateUserSaga);
    yield takeLatest(redirectToUsersList.type, redirectToUsersListSaga);
    yield takeLatest(anonymizeProfile.type, anonymizeProfileSaga);
    yield takeLatest(updateUserProfile.type, updateUserProfileSaga);
    yield takeLatest(toggleUserEnable.type, toggleUserStatusSaga);
    yield takeLatest(toggleUserProfileStatus.type, toggleUserProfileStatusSaga);
    yield takeLatest(updateSubscription.type, updateSubscriptionSaga);
    yield takeLatest(updateUserDocument.type, uploadDocumentSaga);
    yield takeLatest(deleteUserDocument.type, deleteDocumentSaga);
    yield takeEvery(fetchUserDocument.type, fetchDocumentSaga);
    yield takeLatest(deleteCreditCard.type, deleteEndUserCreditCardSaga);
    yield takeLatest(addEndUserPenalty.type, addPenaltySaga);
}
