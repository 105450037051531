import request from 'helpers/request';
import { News } from './types';
import { NEWS_URL } from 'constants/api';
import { prepareFormData } from './mapper';

export const getNews = (params: any): Promise<string> =>
    request.get(NEWS_URL, { params });

export const getOneNews = (newsId: number): Promise<string> =>
    request.get(`${NEWS_URL}/${newsId}`);

export const postNews = (params: News): Promise<string> => {
    const formData = prepareFormData(params);

    return request.post(NEWS_URL, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};

export const deleteNewsService = (newsId: number): Promise<any> =>
    request.delete(`${NEWS_URL}/${newsId}`);

export const putNews = (params: News): Promise<string> => {
    const formData = prepareFormData(params);

    return request.post(`${NEWS_URL}/${params.id}`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
};

export const postImage = ({
    file,
    newsId,
}: {
    newsId: number;
    file: FormData;
}): Promise<string> =>
    request.post(`${NEWS_URL}/${newsId}/news-image`, file, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
