import { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/trips';
import { FetchedTrip } from 'ducks/trips/types';

export const getColumns = (
    t: TFunction,
    dispatch: Dispatch<any>,
    vehicleId?: string,
) => {
    return vehicleId
        ? [
              {
                  title: t('tripList.tripId'),
                  field: 'id',
                  sorting: false,
              },
              {
                  title: t('tripList.userName'),
                  field: 'userName',
                  sorting: false,
              },
              {
                  title: t('tripList.startDate'),
                  field: 'startTime',
                  render: (rowProps: FetchedTrip) =>
                      new Date(rowProps.startTime).toLocaleString(),
                  onClick: () =>
                      dispatch(
                          setSort({
                              value: 'startTime',
                          }),
                      ),
              },
              {
                  title: t('tripList.endDate'),
                  field: 'endTime',
                  render: (rowProps: FetchedTrip) =>
                      new Date(rowProps.endTime).toLocaleString() || '-',
                  sorting: false,
              },
              {
                  title: t('tripList.numberOfPauses'),
                  field: 'numberOfPauses',
                  sorting: false,
              },
              {
                  title: t('tripList.status'),
                  field: 'status',
                  render: (rowProps: FetchedTrip) =>
                      t(`tripList.statuses.${rowProps.status.toLowerCase()}`),
                  onClick: () =>
                      dispatch(
                          setSort({
                              value: 'status',
                          }),
                      ),
              },
              {
                  title: t('tripList.serviceId'),
                  field: 'serviceId',
                  sorting: false,
                  render: (rowProps: FetchedTrip) => rowProps.service?.id,
              },
              {
                  title: t('tripList.stationIds'),
                  field: 'stationIds',
                  sorting: false,
              },
              {
                  title: t('tripList.batteryLevels'),
                  field: 'batteryLevels',
                  sorting: false,
              },
          ]
        : [
              {
                  title: t('tripList.tripId'),
                  field: 'id',
                  sorting: false,
              },
              {
                  title: t('tripList.userName'),
                  field: 'userName',
                  sorting: false,
              },
              {
                  title: t('tripList.vehicleSn'),
                  field: 'vehicleSn',
                  sorting: false,
                  render: (rowProps: FetchedTrip) => rowProps.vehicle?.serial,
              },
              {
                  title: t('tripList.vehiclePlate'),
                  field: 'vehiclePlate',
                  sorting: false,
                  render: (rowProps: FetchedTrip) => rowProps.vehicle?.plate,
              },
              {
                  title: t('tripList.vehicleType'),
                  field: 'vehicleType',
                  render: (rowProps: FetchedTrip) =>
                      t(`tripList.${rowProps.vehicle?.type.toLowerCase()}`),
                  onClick: () =>
                      dispatch(
                          setSort({
                              value: 'vehicleType',
                          }),
                      ),
              },
              {
                  title: t('tripList.startDate'),
                  field: 'startTime',
                  render: (rowProps: FetchedTrip) =>
                      rowProps.startTime
                          ? new Date(rowProps.startTime).toLocaleString()
                          : '-',
                  onClick: () =>
                      dispatch(
                          setSort({
                              value: 'startTime',
                          }),
                      ),
              },
              {
                  title: t('tripList.endDate'),
                  field: 'endTime',
                  render: (rowProps: FetchedTrip) =>
                      rowProps.endTime
                          ? new Date(rowProps.endTime).toLocaleString()
                          : '-',
                  sorting: false,
              },
              {
                  title: t('tripList.numberOfPauses'),
                  field: 'numberOfPauses',
                  sorting: false,
              },
              {
                  title: t('tripList.status'),
                  field: 'status',
                  render: (rowProps: FetchedTrip) =>
                      t(`tripList.statuses.${rowProps.status.toLowerCase()}`),
                  onClick: () =>
                      dispatch(
                          setSort({
                              value: 'status',
                          }),
                      ),
              },
              {
                  title: t('tripList.serviceId'),
                  field: 'serviceId',
                  sorting: false,
                  render: (rowProps: FetchedTrip) => rowProps.service?.id,
              },
              {
                  title: t('tripList.stationIds'),
                  field: 'stationIds',
                  sorting: false,
              },
              {
                  title: t('tripList.batteryLevels'),
                  field: 'batteryLevels',
                  sorting: false,
              },
          ];
};
