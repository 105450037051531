import { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/partners';
import { Partner } from 'ducks/partners/types';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('partners.id'),
            field: 'id',
            sorting: false,
        },
        {
            title: t('partners.name'),
            field: 'name',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'name',
                    }),
                ),
        },
        {
            title: t('partners.status'),
            field: 'isEnabled',
            sorting: false,
            render: (rowProps: Partner) =>
                rowProps.isEnabled
                    ? t('partners.active')
                    : t('partners.inactive'),
        },
        {
            title: t('partners.callerType'),
            field: 'caller',
            sorting: false,
            render: (rowProps: Partner) =>
                rowProps.caller ? t(`partners.caller.${rowProps.caller}`) : '-',
        },
        {
            title: t('partners.key'),
            field: 'key',
            sorting: false,
        },
    ];
};
