import { TabPanel } from '../../common/Tabs/TabPanel';
import { TabsComponent } from '../../common/Tabs';
import React from 'react';
import { CompanyServices } from './CompanyServices';
import { useParams } from 'react-router';
import {
    fetchCompany,
    setCurrentCompanyId,
    setActiveCompanyTab,
    resetCurrentCompany,
} from 'ducks/companies';
import { useDispatch, useSelector } from 'react-redux';
import { CompanyInvitations } from './CompanyInvitations';
import { CreateCompany } from './CompanyForm';
import { RootState } from 'ducks/root';
import { CompanyUsers } from './CompanyUsers';
import { BackofficeUsersList } from '../BackofficeUsersList';
import { selectAccessToPages } from '../../../ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { Box, IconButton } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { useTranslation } from 'react-i18next';
import { CompanyEmailRecipients } from './CompanyEmailRecipients';

export const CreateCompanyComponent = () => {
    const { companyId } = useParams<{ companyId?: string }>();
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (companyId && !isNaN(+companyId)) {
            dispatch(setCurrentCompanyId({ id: +companyId }));
            dispatch(fetchCompany());
        }
        return () => {
            dispatch(resetCurrentCompany());
        };
    }, [dispatch, companyId]);
    const { activeCompanyTab } = useSelector(
        (state: RootState) => state.company,
    );
    const setActiveTab = React.useCallback(
        (tab: number) => dispatch(setActiveCompanyTab(tab)),
        [dispatch],
    );
    const accessToPages = useSelector(selectAccessToPages);
    const formedTabs = React.useMemo(() => {
        const tabs = [CreateCompany];
        const titles = ['companyTabs.companyInfo'];
        if (accessToPages[Permissions.SERVICE_VIEW] && companyId) {
            tabs.push(CompanyServices);
            titles.push('companyTabs.services');
        }
        if (accessToPages[Permissions.INVITATION_VIEW] && companyId) {
            tabs.push(CompanyInvitations);
            titles.push('companyTabs.invitationTable');
        }
        if (accessToPages[Permissions.END_USER_VIEW] && companyId) {
            tabs.push(CompanyUsers);
            titles.push('companyTabs.usersTable');
        }
        if (accessToPages[Permissions.BO_USER_VIEW] && companyId) {
            tabs.push(BackofficeUsersList);
            titles.push('companyTabs.boUsersTable');
        }
        if (accessToPages[Permissions.COMPANY_VIEW] && companyId) {
            tabs.push(CompanyEmailRecipients);
            titles.push('companyTabs.emailRecipients');
        }
        return { tabs, titles };
    }, [accessToPages, companyId]);

    const redirectToList = React.useCallback(
        () => dispatch(push(routes.COMPANIES)),
        [dispatch],
    );

    const { t } = useTranslation();

    return (
        <>
            <Box display="flex" alignItems="center" mb="20px">
                <IconButton onClick={redirectToList}>
                    <BackIcon />
                </IconButton>
                {t('backToList')}
            </Box>
            <TabsComponent
                currentTab={activeCompanyTab}
                setTab={setActiveTab}
                tabTitles={formedTabs.titles}>
                {formedTabs.tabs.map((tab, i) => {
                    const Tab = tab;
                    return (
                        <TabPanel index={i} key={i}>
                            <Tab />
                        </TabPanel>
                    );
                })}
            </TabsComponent>
        </>
    );
};
