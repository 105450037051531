import React from 'react';
import { ToggleButtonComponent } from 'components/common/ToggleButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterTypes } from 'ducks/users/types';
import { selectFilters } from 'ducks/users/selectors';
import { setFilter } from 'ducks/users';
import { FormGrid } from '../../../common';

export const UserToolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);

    const handleToggleFilter = (type: FilterTypes) => {
        dispatch(setFilter(type));
    };

    return (
        <FormGrid columnAmount={5} padding="20px 0" rowHeight="20px">
            <ToggleButtonComponent
                text={t('usersList.news')}
                handleClick={() =>
                    handleToggleFilter(FilterTypes.AreNewsAccepted)
                }
                isSelected={filters.areNewsAccepted}
            />
        </FormGrid>
    );
};
