import { useField } from 'react-final-form';

import { DateTimeInput } from '../../DateTimeInput';
import React, { ReactElement } from 'react';
import { FieldValidator } from 'final-form';
import { Moment } from 'moment';
import { getDisabledTime } from 'tools/date';
interface FieldProps {
    name: string;
    label: string;
    validators?: FieldValidator<string>;
    disabled?: boolean;
    id: string;
    min?: string | Moment;
    max?: string | Moment;
    timeFormat?: boolean;
    dateFormat?: string | boolean;
    handleChange?: (value: string | Moment) => void;
    closeOnSelect?: boolean;
    labelComponent?: ReactElement;
    displayTimeZone?: string;
    isRequired?: boolean;
}
export const DateTimeField: React.FC<FieldProps> = ({
    name,
    label,
    validators,
    disabled,
    min,
    max,
    id,
    timeFormat,
    handleChange,
    closeOnSelect,
    labelComponent,
    displayTimeZone,
    isRequired,
}: FieldProps) => {
    const {
        input,
        meta: { error, submitFailed, modified },
    } = useField(name, {
        validate: validators,
    });

    const onChange = React.useCallback(
        (e: Moment | string) => {
            input.onChange(e);
            handleChange && handleChange(e);
        },
        [handleChange, input],
    );

    const isTimeDisabled = React.useCallback(
        (current: Moment) => getDisabledTime({ current, min, max }),
        [min, max],
    );

    return (
        <DateTimeInput
            {...input}
            onChange={onChange}
            disabled={disabled}
            label={`${label}${isRequired ? '*' : ''}`}
            hasError={!!(error && modified)}
            id={id}
            getDisabledTime={isTimeDisabled}
            helperText={error && (modified || submitFailed) ? error : ''}
            timeFormat={timeFormat}
            closeOnSelect={closeOnSelect}
            labelComponent={labelComponent}
            displayTimeZone={displayTimeZone}
        />
    );
};
