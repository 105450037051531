import React, { Ref, useCallback } from 'react';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { StyledMenu, StyledSelect } from './styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { logout, updateMyUser } from 'ducks/auth';
import ProfileIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitIcon from '@material-ui/icons/Input';
import { LinkComponent } from '../Link';
import { Languages } from 'ducks/boUsers/types';
import { routes } from 'constants/common';
import { push } from 'connected-react-router';

// eslint-disable-next-line react/display-name
export const MenuComponent = React.forwardRef(
    (props, ref: Ref<HTMLDivElement>) => {
        const { i18n } = useTranslation();
        const dispatch = useDispatch();
        const redirectToUser = useCallback(
            () => dispatch(push(routes.PROFILE)),
            [dispatch],
        );
        const changeLanguage = React.useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(updateMyUser({ language: e.target.value }));
            },
            [dispatch],
        );
        const handleLogout = React.useCallback(() => {
            dispatch(logout());
        }, [dispatch]);
        const { t } = useTranslation();
        const languageOptions = React.useMemo(
            () =>
                Object.values(Languages).map(language => ({
                    label: language,
                    value: language,
                })),
            [],
        );
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null,
        );
        const handleClick = React.useCallback(
            (event: React.MouseEvent<HTMLButtonElement>) => {
                setAnchorEl(event.currentTarget);
            },
            [],
        );

        const handleClose = React.useCallback(() => {
            setAnchorEl(null);
        }, []);
        return (
            <Box marginLeft="auto" display="flex" alignItems="center">
                <LinkComponent handleClick={handleClick} text="">
                    <Box mr="10px">
                        <SettingsIcon color="primary" />
                    </Box>
                    <Typography variant="h2">{t('parameters')}</Typography>
                </LinkComponent>
                <StyledMenu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <div ref={ref}>
                        <Box display="flex">
                            <Box ml="10px" mr="10px">
                                <LinkComponent
                                    handleClick={redirectToUser}
                                    text="">
                                    <Box mr="10px">
                                        <ProfileIcon color="primary" />
                                    </Box>
                                    <Typography variant="h2">
                                        {t('user')}
                                    </Typography>
                                </LinkComponent>
                            </Box>
                            <Box ml="10px" mr="10px">
                                <LinkComponent
                                    handleClick={handleLogout}
                                    text="">
                                    <Box mr="10px">
                                        <ExitIcon color="primary" />
                                    </Box>
                                    <Typography variant="h2">
                                        {t('logout')}
                                    </Typography>
                                </LinkComponent>
                            </Box>
                            <Box ml="10px" mr="10px">
                                <StyledSelect
                                    value={i18n.language.toUpperCase() || ''}
                                    select
                                    fullWidth={false}
                                    onChange={changeLanguage}
                                    size="small"
                                    variant="standard">
                                    {languageOptions.map(option => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </StyledSelect>
                            </Box>
                        </Box>
                    </div>
                </StyledMenu>
            </Box>
        );
    },
);
