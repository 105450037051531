import { Modals } from 'ducks/ui';
import { ZoneMapComponent } from '../../pages/ZoneList/ZoneMapModal';
import { ConnectServiceComponent } from '../../pages/CreateCompany/CompanyServices/ConnectService';
import { ConfirmationModal } from '../DeleteModalConfirmation/ConfirmationModal';
import { MapWithPointModal } from '../MapWithPointModal';
import { EditDateModal } from 'components/pages/UserDetails/EditDateModal';
import { UnbindBikeModal } from 'components/pages/BikeAndVaeDetails/UnbindBikeModal';
import { AddEmailModal } from 'components/pages/CreateCompany/CompanyEmailRecipients/AddEmailModal';
import { ChangeVehicleModal } from 'components/pages/CreateCompany/CompanyInvitations/ChangeVehicleModal';
import { AddPenaltyModal } from 'components/pages/UserDetails/AddPenaltyModal';
import { ConfirmSyncModal } from 'components/pages/CreateStation/ConfirmSyncModal';

export const modalsConfig = {
    [Modals.CONNECT_SERVICES]: ConnectServiceComponent,
    [Modals.ZONE_MAP]: ZoneMapComponent,
    [Modals.CHANGE_CONFIRMATION]: ConfirmationModal,
    [Modals.MAP_WITH_POINT]: MapWithPointModal,
    [Modals.EDIT_DATE_MODAL]: EditDateModal,
    [Modals.UNBIND_BIKE]: UnbindBikeModal,
    [Modals.ADD_EMAIL_RECIPIENT]: AddEmailModal,
    [Modals.CHANGE_VEHICLE]: ChangeVehicleModal,
    [Modals.ADD_PENALTY]: AddPenaltyModal,
    [Modals.STATION_SYNC_CONFIRM]: ConfirmSyncModal,
};
