import { RootState } from '../../root';
import { VehiclesById } from './types';
import { createSelector } from '@reduxjs/toolkit';

export const selectVehicleIds = (state: RootState) => state.vehicle.vehicleIds;

export const selectVehiclesById = (state: RootState) =>
    state.vehicle.vehiclesById;

export const selectFilters = (state: RootState) => state.vehicle.filters;

export const selectSort = (state: RootState) => state.vehicle.sort;

export const selectVehicleHasUnsavedChanges = (state: RootState) =>
    state.vehicle.vehicleHasUnsavedChanges;

export const selectCurrentVehicle = (state: RootState) =>
    state.vehicle.currentVehicle;

export const selectCurrentVehicleLockId = (state: RootState) =>
    state.vehicle.currentVehicle?.lockId;

export const selectVehicleError = (state: RootState) => state.vehicle.error;

export const selectIsLoading = (state: RootState) => state.vehicle.loading;

export const selectPagination = (state: RootState) => state.vehicle.pagination;

export const selectSelectedVehicles = (state: RootState) =>
    state.vehicle.selected;

export const selectNewStatus = (state: RootState) => state.vehicle.newStatus;

export const selectRandomizedVehicles = createSelector(
    selectVehicleIds,
    selectVehiclesById,
    (vehicleIds, vehiclesById) =>
        vehicleIds.reduce<VehiclesById>((acc, id) => {
            const scooter = vehiclesById[id];
            return {
                ...acc,
                [id]: {
                    ...scooter,
                    lat: scooter.lat && scooter.lat + Math.random() / 10000,
                    lng: scooter.lng && scooter.lng + Math.random() / 10000,
                },
            };
        }, {}),
);

export const selectVehiclesBoundCoordinates = createSelector(
    selectVehicleIds,
    selectRandomizedVehicles,
    (vehicleIds, randomized) =>
        vehicleIds.reduce<{ lat: number; lng: number }[]>((acc, id) => {
            if (randomized[id].lat && randomized[id].lng) {
                return [
                    ...acc,
                    {
                        lat: randomized[id].lat,
                        lng: randomized[id].lng,
                    },
                ];
            } else return acc;
        }, []),
);
export const selectVehiclesTableData = createSelector(
    selectVehicleIds,
    selectVehiclesById,
    selectSelectedVehicles,
    (vehicleIds, vehiclesById, selected) =>
        vehicleIds.map(id => ({
            ...vehiclesById[id],
            tableData: {
                selected: selected.includes(id),
                checked: selected.includes(id),
            },
        })),
);

export const selectFetchAll = (state: RootState) => state.vehicle.fetchAll;

export const selectActiveVehicleTab = (state: RootState) =>
    state.vehicle.activeVehicleTab;

export const selectCurrentVehicleState = (state: RootState) =>
    state.vehicle.currentVehicleState;
