import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    imageContainer: {
        display: 'flex',
        position: 'relative',
    },
    deleteButton: {
        position: 'absolute',
        top: '-24px',
        right: '-24px',
    },
}));
