import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from 'ducks/common/pagination';
import { SortDirection } from 'ducks/common/types';
import { FilterTypes, LogEntityType, Sorting } from './types';
import { LogsState } from './index';

const defaultFilters = {
    startDate: '',
    endDate: '',
    email: '',
};
export const initialState: LogsState = {
    loading: false,
    error: '',
    logsIds: [],
    logsById: {},
    filters: defaultFilters,
    sort: {
        value: Sorting.Created,
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
};

const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        fetchLogs(state) {
            state.loading = true;
            state.error = '';
        },
        fetchLogsSuccess(state, action) {
            state.logsIds = action.payload.allIds;
            state.logsById = action.payload.byId;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchLogsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        requestExportLogs(state) {
            state.loading = true;
            state.error = '';
        },
        requestExportLogsSuccess(state) {
            state.loading = false;
        },
        requestExportLogsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string | LogEntityType;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.pagination = defaultPagination;
            state.loading = true;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        resetError(state) {
            state.error = '';
        },
    },
});

export const {
    fetchLogs,
    fetchLogsError,
    fetchLogsSuccess,
    requestExportLogs,
    requestExportLogsError,
    requestExportLogsSuccess,
    setSort,
    setPage,
    resetError,
    resetFilters,
    setFilter,
} = logsSlice.actions;

export const logsReducer = logsSlice.reducer;
