import request from 'helpers/request';
import { Zone } from './types';
import { ZONES_URL } from '../../constants/api';

export const getZones = (params: any): Promise<string> =>
    request.get(ZONES_URL, { params });

export const postZone = (params: Zone): Promise<string> =>
    request.post(ZONES_URL, { ...params });

export const putZone = (params: Zone): Promise<string> =>
    request.put(`${ZONES_URL}/${params.id}`, { ...params });

export const getAllZones = (): Promise<string> =>
    request.get(`${ZONES_URL}/all`);

export const getZone = (id: number): Promise<string> =>
    request.get(`${ZONES_URL}/${id}`);

export const deleteZoneService = (id: number): Promise<string> =>
    request.delete(`${ZONES_URL}/${id}`);
