import { RootState } from '../root';
import { createSelector } from '@reduxjs/toolkit';

export const selectFilters = (state: RootState) =>
    state.scooterMaintenance.filters;

export const selectIsLoading = (state: RootState) =>
    state.scooterMaintenance.loading;

export const selectPagination = (state: RootState) =>
    state.scooterMaintenance.pagination;

export const selectMaintenanceIds = (state: RootState) =>
    state.scooterMaintenance.maintenanceIds;

export const selectMaintenanceById = (state: RootState) =>
    state.scooterMaintenance.maintenanceById;

export const selectMaintenanceTableData = createSelector(
    selectMaintenanceIds,
    selectMaintenanceById,
    (maintenanceIds, maintenanceById) =>
        maintenanceIds.map(id => ({
            ...maintenanceById[id],
        })),
);
