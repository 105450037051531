import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BasicInformation } from './BasicInformation';
import { TripsList } from '../TripsList';
import { ScootersEventsListComponent } from '../ScooterEvents';
import { ScooterMaintenanceList } from '../ScooterMaintenanceList';
import { ScooterTelemetryEvents } from '../TelemetryEvents/ScooterTelemetryEvents';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { routes } from 'constants/common';
import { VehicleDetails } from '../VehicleDetails';
import { VehicleBookingList } from '../VehicleBooking';

export const ScooterDetailsComponent = () => {
    const accessToPages = useSelector(selectAccessToPages);

    const { tabs, titles } = useMemo(() => {
        const tabs = [
            BasicInformation,
            ScootersEventsListComponent,
            ScooterMaintenanceList,
            ScooterTelemetryEvents,
        ];
        const titles = [
            'vehicles.basicInformation',
            'vehicles.scooterEvents',
            'vehicles.scooterMaintenance',
            'vehicles.scooterTelemetryEvents',
        ];
        if (accessToPages[Permissions.TRIP_VIEW]) {
            tabs.push(VehicleBookingList);
            titles.push('vehicles.bookingList');

            tabs.push(TripsList);
            titles.push('vehicles.scooterTrips');
        }
        return { tabs, titles };
    }, [accessToPages]);

    return (
        <VehicleDetails
            vehicleTabs={tabs}
            vehicleTitles={titles}
            listRout={routes.SCOOTERS}
        />
    );
};
