import React from 'react';
import { useField } from 'react-final-form';

import { FieldArray } from 'react-final-form-arrays';
import {
    SelectField,
    ButtonComponent,
    CheckboxField,
    TextField,
} from 'components/common';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'ducks/root';
import {
    RecipientTypes,
    SelectedService,
    UsageTypes,
} from 'ducks/services/types';
import { TypeField, RecipientTypeField, CgauVersionField } from './Fields';
import { FormGrid } from '../../common/FormGrid';
import {
    composeValidators,
    fieldLength,
    isLink,
    isNumber,
    isTimeZone,
    minValue,
    required,
} from 'tools/validationRules';
import { Box, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormApi } from 'final-form';
import { fetchAllZones } from 'ducks/zones';
import { selectAccessToPages } from '../../../ducks/auth';
import { Permissions } from '../../../ducks/groups/types';
import { useParams } from 'react-router';
import { FormSpy } from 'react-final-form';
import { AddTemplateComponent } from './Fields/AddTemplates';
import { Languages } from 'ducks/boUsers/types';
import { BookingDurations } from './Fields/BookingDurations';

export const shortRentalFields = [
    {
        field: 'isGeofenceEnabled',
        label: 'serviceList.geofence',
    },
    { field: 'isStationConnected', label: 'serviceList.station' },
    { field: 'isLoopEnabled', label: 'serviceList.loop' },
    { field: 'isPauseAllowed', label: 'serviceList.pause' },
];
const emptyFields = {
    description: '',
    language: Languages.EN,
};

interface Interface {
    values: SelectedService;
    form: FormApi<SelectedService>;
}
export const CreateServiceFormContent: React.FC<Interface> = ({
    values,
    form,
}: Interface) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchAllZones());
    }, [dispatch]);
    const accessToPages = useSelector(selectAccessToPages);
    const { id } = useParams<{ id?: string }>();
    const allowedToEdit = id
        ? accessToPages[Permissions.SERVICE_UPDATE]
        : accessToPages[Permissions.SERVICE_CREATE];
    const { allZoneIds, allZonesById } = useSelector(
        (state: RootState) => state.zone,
    );
    const zoneOptions = React.useMemo(
        () =>
            allZoneIds.map(id => ({
                label: allZonesById[id].name,
                value: id,
            })),
        [allZoneIds, allZonesById],
    );

    //condition: can't be turned on if geofence is enabled, or pause allowed
    // without station connected. Can be turned on with a loop
    const getAutoTripClosureDisabled = React.useCallback(
        (values: SelectedService) => {
            if (values.type !== UsageTypes.LONG_TERM_USAGE) {
                if (values.isGeofenceEnabled) {
                    return true;
                }
                if (values.isPauseAllowed) {
                    return !values.isStationConnected;
                }
            } else return false;
        },
        [],
    );

    const resetAutoclose = React.useCallback(
        ({ values }) => {
            if (
                values.isAutocloseTrip &&
                values.type !== UsageTypes.LONG_TERM_USAGE
            ) {
                if (values.isGeofenceEnabled) {
                    form.mutators.resetBooleanField({
                        field: 'isAutocloseTrip',
                    });
                }
                if (values.isPauseAllowed && !values.isStationConnected) {
                    form.mutators.resetBooleanField({
                        field: 'isAutocloseTrip',
                    });
                }
            }
        },
        [form.mutators],
    );

    useField('descriptionTemplatesArray', {
        validate: composeValidators(
            (value: any, allValues: { descriptionTemplates: any[] }) => {
                const { descriptionTemplates } = allValues;
                return minValue(descriptionTemplates?.length || 0, 1);
            },
        ),
    });

    useField('bookingDurationsArray', {
        validate: composeValidators(
            (
                value: any,
                allValues: {
                    isBookingAllowed: boolean;
                    bookingDurations: any[] | undefined;
                },
            ) => {
                const { bookingDurations, isBookingAllowed } = allValues;
                return isBookingAllowed
                    ? minValue(bookingDurations?.length || 0, 1)
                    : '';
            },
        ),
    });

    return (
        <>
            <FormGrid columnAmount={3}>
                <FormSpy
                    subscription={{
                        values: true,
                    }}
                    onChange={resetAutoclose}
                />
                <TextField
                    name="name"
                    validators={composeValidators(required, (value: string) =>
                        fieldLength(value, 2, 50),
                    )}
                    label={t('serviceList.serviceName')}
                    disabled={!allowedToEdit}
                    isRequired
                />
                <TextField
                    name="cgauUrl"
                    validators={composeValidators(required, isLink)}
                    label={t('serviceList.cgauUrl')}
                    disabled={!allowedToEdit}
                    isRequired
                />
                <CgauVersionField />
                <TextField
                    name="timezone"
                    validators={composeValidators(required, isTimeZone)}
                    label={t('serviceList.timezone')}
                    disabled={!allowedToEdit}
                    isRequired
                />
                <TypeField id={values.id} form={form} />
                <RecipientTypeField
                    form={form}
                    id={values.id}
                    type={values.type}
                />
                {values.type !== UsageTypes.LONG_TERM_USAGE && (
                    <>
                        <TextField
                            name="maxTripDuration"
                            validators={composeValidators(
                                required,
                                isNumber,
                                (value: string) => minValue(value, 0),
                            )}
                            type="number"
                            label={t('serviceList.maxDuration')}
                            InputProps={{
                                inputProps: { min: 0 },
                            }}
                            disabled={!allowedToEdit}
                            isRequired
                        />
                        <SelectField
                            multiple
                            name="zones"
                            validators={composeValidators(required)}
                            label={t('serviceList.zones')}
                            options={zoneOptions}
                            disabled={!allowedToEdit}
                            isRequired
                        />
                    </>
                )}
            </FormGrid>
            <FormGrid columnAmount={3} rowHeight="40px">
                <CheckboxField
                    field="isMultirentAllowed"
                    label="serviceList.multirent"
                    disabled={!allowedToEdit}
                />
                <CheckboxField
                    field="isAutocloseTrip"
                    label="serviceList.isAutocloseTrip"
                    disabled={
                        getAutoTripClosureDisabled(values) || !allowedToEdit
                    }
                />
                {values.type !== UsageTypes.LONG_TERM_USAGE &&
                    shortRentalFields.map(({ field, label }) => (
                        <CheckboxField
                            key={field}
                            field={field}
                            label={label}
                            disabled={
                                values.type === UsageTypes.LONG_TERM_USAGE ||
                                !allowedToEdit
                            }
                        />
                    ))}
                {values.type !== UsageTypes.LONG_TERM_USAGE && (
                    <CheckboxField
                        field="isBookingAllowed"
                        label="serviceList.booking"
                        disabled={!allowedToEdit}
                    />
                )}
                <CheckboxField
                    field="isCreditCardRequired"
                    label="serviceList.isCreditCardRequired"
                    disabled={!allowedToEdit}
                />
                <CheckboxField
                    field="isFreeToInvitee"
                    label="serviceList.isFreeToInvitee"
                    disabled={!allowedToEdit}
                />
            </FormGrid>
            <FormGrid columnAmount={3} rowHeight="40px">
                <CheckboxField
                    field="isOpenToPublic"
                    label="serviceList.isOpenToPublic"
                    disabled={
                        values.recipientType === RecipientTypes.B_TO_B ||
                        !allowedToEdit
                    }
                />
                <CheckboxField
                    field="specificMapLayer"
                    label="serviceList.specificMapLayer"
                    disabled={!allowedToEdit}
                />
            </FormGrid>
            {values.isBookingAllowed && (
                <FormGrid columnAmount={3} rowHeight="40px">
                    <Box>
                        <Typography variant="h2">
                            <p>{t('serviceList.bookingDurations')}*</p>
                        </Typography>
                        <FieldArray name="bookingDurations">
                            {({ fields }) => {
                                return fields.map(
                                    (name: string, index: number) => (
                                        <Box key={index} textAlign="center">
                                            <BookingDurations
                                                prefix={name}
                                                id={Number(id) || 0}
                                            />
                                            {allowedToEdit && (
                                                <IconButton
                                                    onClick={() => {
                                                        fields.remove(index);
                                                    }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    ),
                                );
                            }}
                        </FieldArray>
                        {allowedToEdit && (
                            <ButtonComponent
                                handleClick={() => {
                                    form.mutators.push('bookingDurations', '');
                                }}
                                text={t('add')}
                                startIcon={<AddIcon />}
                            />
                        )}
                    </Box>
                </FormGrid>
            )}

            <FormGrid columnAmount={1}>
                <Typography variant="h2">
                    <p>{t('serviceList.descriptionTemplates')}*</p>
                </Typography>
                <FieldArray name="descriptionTemplates">
                    {({ fields }) => {
                        return fields.map((name: string, index: number) => (
                            <Box key={index} textAlign="center">
                                <AddTemplateComponent
                                    index={index}
                                    prefix={name}
                                    id={Number(id) || 0}
                                />
                                {allowedToEdit && (
                                    <IconButton
                                        onClick={() => {
                                            fields.remove(index);
                                        }}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Box>
                        ));
                    }}
                </FieldArray>
                {allowedToEdit && (
                    <ButtonComponent
                        handleClick={() => {
                            form.mutators.push(
                                'descriptionTemplates',
                                emptyFields,
                            );
                        }}
                        text={t('add')}
                        startIcon={<AddIcon />}
                    />
                )}
            </FormGrid>
        </>
    );
};
