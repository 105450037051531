import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    unbindLock,
    unbindLockSuccess,
    unbindLockError,
    fetchLocks,
    fetchLocksSuccess,
    fetchLocksError,
    setPage,
    setFilter,
    setSort,
    exportLocks,
    exportLocksSuccess,
    exportLocksError,
    createLockSuccess,
    createLockError,
    createLock,
    importLocks,
    importLocksSuccess,
    importLocksError,
    updateLockSuccess,
    updateLockError,
    fetchLockSuccess,
    fetchLockError,
    fetchLock,
    updateLock,
    selectCurrentLock,
    fetchConfig,
    fetchConfigSuccess,
    fetchConfigError,
} from './index';
import {
    exportLocksService,
    getConfig,
    getLock,
    getLocks,
    postImportLocks,
    postLock,
    putLock,
    sendLockCommand,
    unbindVehicleService,
    updateLockStatus,
} from './service';
import { closeModal } from '../ui';
import { Pagination, Sort } from '../common/types';
import { mapFetchedPagination, mapPagination } from '../common/pagination';
import { mapFilters, mapSorting } from '../common/mappers';
import {
    FetchedLock,
    FetchedLocks,
    Filters,
    LockCommands,
    LockFromList,
    NewLock,
} from './types';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { mapFetchedLocks } from './mapper';
import { downloadBlob } from 'tools/file';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { selectCurrentVehicle, fetchVehicle } from '../vehicles/common';
import { sendCommand, sendCommandError, sendCommandSuccess } from './lockSlice';

function* unbindLockSaga({
    payload,
}: PayloadAction<{
    lockId: number;
    status: string;
}>) {
    try {
        yield call(unbindVehicleService, {
            lockId: payload.lockId,
            status: payload.status,
        });
        yield put(unbindLockSuccess());
        yield put(closeModal());
        const { id } = yield select(selectCurrentVehicle);
        yield put(fetchVehicle(id));
    } catch (error) {
        yield put(unbindLockError({ error: (error as Error)?.message }));
    }
}

function* fetchLocksSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: FetchedLocks = yield call(getLocks, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { lockIds, locksById } = mapFetchedLocks(data.content);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchLocksSuccess({
                lockIds,
                locksById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchLocksError({ error: (error as Error)?.message }));
    }
}

function* exportLocksSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const { file, fileName } = yield call(exportLocksService, {
            ...mapSorting(sort),
            ...mapFilters(filters),
        });
        downloadBlob(file, fileName);
        yield put(exportLocksSuccess());
    } catch (error) {
        yield put(exportLocksError({ error: (error as Error)?.message }));
    }
}

function* createLockSaga({ payload }: PayloadAction<NewLock>) {
    try {
        yield call(postLock, payload);
        yield put(createLockSuccess());
        yield put(push(routes.LOCKS));
    } catch (error) {
        yield put(createLockError({ error: (error as Error)?.message }));
    }
}

function* importLocksSaga({ payload }: PayloadAction<{ file: FormData }>) {
    try {
        yield call(postImportLocks, payload);
        yield put(importLocksSuccess());
        yield put(push(routes.LOCKS));
    } catch (error) {
        yield put(importLocksError({ error: (error as Error)?.message }));
    }
}

function* updateLockSaga({
    payload,
}: PayloadAction<{ lock: NewLock; id: number }>) {
    try {
        const currentLock: FetchedLock = yield select(selectCurrentLock);
        if (payload.lock.status && payload.lock.status !== currentLock.status) {
            yield call(updateLockStatus, payload.lock.status, [payload.id]);
        }
        if (
            payload.lock.version !== currentLock.version ||
            payload.lock.comment !== currentLock.comment
        ) {
            yield call(putLock, payload.lock, payload.id);
        }
        yield put(updateLockSuccess());
        yield put(push(routes.LOCKS));
    } catch (error) {
        yield put(updateLockError({ error: (error as Error)?.message }));
    }
}

function* fetchLockSaga({ payload }: PayloadAction<number>) {
    try {
        const data: LockFromList = yield call(getLock, payload);
        yield put(fetchLockSuccess(data));
    } catch (error) {
        yield put(fetchLockError({ error: (error as Error)?.message }));
    }
}

function* fetchLockConfigSaga({ payload }: PayloadAction<number>) {
    try {
        const data: Record<string, Record<string, string>> | null = yield call(
            getConfig,
            payload,
        );
        yield put(fetchConfigSuccess(data));
    } catch (error) {
        yield put(fetchConfigError({ error: (error as Error)?.message }));
    }
}

function* sendCommandSaga({ payload }: PayloadAction<LockCommands>) {
    try {
        const currentLock: FetchedLock = yield select(selectCurrentLock);
        yield call(sendLockCommand, currentLock.id, payload);

        yield put(sendCommandSuccess());
    } catch (error) {
        yield put(sendCommandError({ error: (error as Error)?.message }));
    }
}

export default function* lockSagas() {
    yield takeLatest(unbindLock.type, unbindLockSaga);
    yield takeLatest(fetchLocks.type, fetchLocksSaga);
    yield takeLatest(setPage.type, fetchLocksSaga);
    yield takeLatest(setFilter.type, fetchLocksSaga);
    yield takeLatest(setSort.type, fetchLocksSaga);
    yield takeLatest(exportLocks.type, exportLocksSaga);
    yield takeLatest(createLock.type, createLockSaga);
    yield takeLatest(importLocks.type, importLocksSaga);
    yield takeLatest(fetchLock.type, fetchLockSaga);
    yield takeLatest(updateLock.type, updateLockSaga);
    yield takeLatest(fetchConfig.type, fetchLockConfigSaga);
    yield takeLatest(sendCommand.type, sendCommandSaga);
}
