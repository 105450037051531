import { takeEvery, call, put, takeLatest, select } from 'redux-saga/effects';
import {
    loginFailure,
    loginSuccess,
    login as loginAction,
    logout,
    fetchMyUser,
    fetchMyUserSuccess,
    fetchMyUserError,
    updateMyUser,
    updateMyUserSuccess,
    updateMyUserError,
    restorePasswordFailure,
    restorePassword,
    restorePasswordSuccess,
} from './authSlice';
import {
    devalidateToken,
    login,
    resetPasswordService,
    updateMyUserService,
} from './service';
import { authError, Credentials, UserSettings } from './types';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { PayloadAction } from '@reduxjs/toolkit';
import { getMyUser } from './service';
import i18next from 'i18next';
import { setTheme } from '../ui';
import { colors } from 'themes/colors';
import { mapAccessGroups } from './mapper';
import { FetchedBoUser } from '../boUsers/types';
import { selectMyUser } from './selectors';

function* loginSaga({
    payload: { email, password },
}: PayloadAction<Credentials>) {
    try {
        yield call(login, {
            email: email,
            password: password,
        });
        yield put(loginSuccess());
        yield put(fetchMyUser());
        yield put(push(routes.MAIN_PAGE));
    } catch (error) {
        yield put(loginFailure({ error: (error as Error)?.message }));
    }
}
function* logoutSaga() {
    yield call(devalidateToken);
    yield window.localStorage.removeItem('key');
}

function* fetchMyUserSaga() {
    try {
        const data: FetchedBoUser = yield call(getMyUser);
        const accessToPages = mapAccessGroups(data.permissions || []);
        i18next.changeLanguage(data.user.language.toLowerCase());
        yield put(fetchMyUserSuccess({ myUser: data, accessToPages }));
        yield put(setTheme(data.companies[0]?.boColor || colors.default));
    } catch (error) {
        yield put(fetchMyUserError());
    }
}
function* resetPasswordSaga({
    payload: { email },
}: PayloadAction<{ email: string }>) {
    try {
        yield call(resetPasswordService, email);
        yield put(restorePasswordSuccess());
        yield put(push(routes.LOGIN));
    } catch (error) {
        yield put(restorePasswordFailure({ error: (error as Error)?.message }));
    }
}

function* updateMyUserSaga({ payload }: PayloadAction<UserSettings>) {
    try {
        const { user } = yield select(selectMyUser);
        let params = {};
        if (payload.newPassword !== '') {
            params = {
                password: payload.oldPassword,
                newPassword: payload.newPassword,
            };
        }
        if (payload.language !== user.language) {
            params = { ...params, language: payload.language };
        }
        const data: FetchedBoUser = yield call(updateMyUserService, params);
        yield put(updateMyUserSuccess({ myUser: data }));
    } catch (error) {
        yield put(
            updateMyUserError({
                error: (error as authError)?.message,
                errorCode: (error as authError)?.status,
            }),
        );
    }
}

export default function* authSagas() {
    yield takeEvery(loginAction.type, loginSaga);
    yield takeEvery(logout.type, logoutSaga);
    yield takeLatest(fetchMyUser.type, fetchMyUserSaga);
    yield takeLatest(updateMyUserSuccess.type, fetchMyUserSaga);
    yield takeLatest(updateMyUser.type, updateMyUserSaga);
    yield takeLatest(restorePassword.type, resetPasswordSaga);
}
