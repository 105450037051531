import { FormGrid } from '../../common/FormGrid';
import { ThemedBoxComponent } from '../../common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import { useTranslation } from 'react-i18next';
import { BYTES_IN_MB } from '../../../constants/common';
import { DocumentsDropzone } from '../../common/DocumentsDropzone';
import {
    selectCurrentUserDocumentsNames,
    selectIsLoading,
    selectUserDocumentError,
} from 'ducks/users/selectors';
import { updateUserDocument } from 'ducks/users';
import { useParams } from 'react-router';
import { LoaderComponent } from '../../common/Loader';
import { Typography } from '@material-ui/core';
import { DocumentContainer } from './DocumentContainer';
import { ErrorComponent } from '../../common/Error';

export const DocumentsManagementComponent: React.FC = () => {
    const { userId } = useParams<{ userId?: string }>();
    const maxSize = BYTES_IN_MB;
    const maxAmount = 5;
    const dispatch = useDispatch();
    const currentUserDocumentsNames = useSelector(
        selectCurrentUserDocumentsNames,
    );
    const error = useSelector(selectUserDocumentError);
    const [sizeError, setError] = React.useState('');

    const accessToPages = useSelector(selectAccessToPages);

    const allowedToEdit = accessToPages[Permissions.END_USER_UPDATE];
    const loading = useSelector(selectIsLoading);

    const { t } = useTranslation();

    const onDrop = React.useCallback(
        acceptedFiles => {
            const downloadedAmount = currentUserDocumentsNames
                ? currentUserDocumentsNames.length
                : 0;

            const allowedDownloads = 5 - downloadedAmount;
            setError('');
            acceptedFiles
                .filter((file: File) => {
                    if (file.size <= BYTES_IN_MB) return true;
                    else
                        setError(
                            t('errors.documentsSize', {
                                max: maxSize / BYTES_IN_MB,
                            }),
                        );
                    return false;
                })
                .slice(0, allowedDownloads)
                .map((file: File) =>
                    dispatch(updateUserDocument({ userId, file })),
                );
        },
        [currentUserDocumentsNames, dispatch, userId, t, maxSize],
    );

    return (
        <ThemedBoxComponent>
            <Typography variant="h3">
                <p>{t('usersList.documents')}</p>
            </Typography>
            {loading && <LoaderComponent />}
            <FormGrid columnAmount={maxAmount}>
                {allowedToEdit &&
                    (!currentUserDocumentsNames ||
                        currentUserDocumentsNames.length < maxAmount) && (
                        <FormGrid columnAmount={1}>
                            <DocumentsDropzone
                                onDrop={files => {
                                    onDrop(files);
                                }}
                                text={t('maximumDocuments', {
                                    maxSize: maxSize / BYTES_IN_MB,
                                    maxAmount,
                                })}
                            />
                            <ErrorComponent error={sizeError} />
                        </FormGrid>
                    )}
                {currentUserDocumentsNames?.map(name => (
                    <DocumentContainer filename={name} key={name} />
                ))}
            </FormGrid>
            <ErrorComponent error={error} />
        </ThemedBoxComponent>
    );
};
