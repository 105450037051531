import {
    composeValidators,
    fieldLength,
    required,
} from 'tools/validationRules';

export const validations = {
    text: composeValidators(
        required,
        (value: string) => value && fieldLength(value, 2, 200),
    ),
    company: composeValidators(required),
};
