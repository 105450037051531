import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    fetchEvents,
    fetchEventsSuccess,
    fetchEventsError,
    setStringFilter,
    setSort,
    setPage,
} from './eventSlice';
import { mapPagination, mapSorting, mapFilters } from '../common/mappers';
import { getEvents } from './service';
import { Pagination, Sort } from '../common/types';
import { FetchedContent, Filters } from './types';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { mapFetchedEvents } from './mappers';
import { selectCurrentVehicle } from '../vehicles/common';
import { Vehicle } from 'ducks/vehicles/common/types';

function* fetchEventsSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const currentVehicle: Vehicle = yield select(selectCurrentVehicle);
        const pagination: Pagination = yield select(selectPagination);
        const data: FetchedContent = yield call(getEvents, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
            vehicles: currentVehicle.id,
        });
        const { totalPages, totalElements, size, number } = data;
        const { allIds, byId } = mapFetchedEvents(data.content);
        yield put(
            fetchEventsSuccess({
                eventIds: allIds,
                eventsById: byId,
                pagination: { size, totalPages, totalElements, number },
            }),
        );
    } catch (error) {
        yield put(fetchEventsError({ error: (error as Error)?.message }));
    }
}

export default function* scooterSagas() {
    yield takeLatest(fetchEvents.type, fetchEventsSaga);
    yield takeLatest(setStringFilter.type, fetchEventsSaga);
    yield takeLatest(setSort.type, fetchEventsSaga);
    yield takeLatest(setPage.type, fetchEventsSaga);
}
