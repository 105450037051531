import { createStyles, Box, Theme, withStyles } from '@material-ui/core';
import { colors } from 'themes/colors';

export const ThemedBoxComponent = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `2px solid ${theme.palette.secondary.main}`,
            borderRadius: '25px',
            padding: '20px',
            backgroundColor: colors.white,
            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            minWidth: 'fit-content',
            position: 'relative',
        },
    }),
)(Box);
