import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormGrid, SelectComponent } from 'components/common';
import { FilterTypes, LogEntityType } from 'ducks/logs/types';
import { selectFilters } from 'ducks/logs/selectors';
import { fetchLogs, resetFilters, setFilter } from 'ducks/logs';
import { fetchAllZones } from 'ducks/zones';
import { DateTimeInput } from '../../common/DateTimeInput';
import moment, { isMoment, Moment } from 'moment';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';
import { getDisabledTime } from '../../../tools/date';

export const LogsToolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchAllZones());
    }, [dispatch]);
    const filters = useSelector(selectFilters);
    const startTime = filters[FilterTypes.StartDate];
    const endTime = filters[FilterTypes.EndDate];

    const tableOptions = React.useMemo<
        { label: string; value: LogEntityType | '' }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...Object.values(LogEntityType).map(type => ({
                label: t(`logs.entityTypes.${type}`),
                value: type,
            })),
        ],
        [t],
    );

    const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setFilter({
                filterType: FilterTypes.Entity,
                value: e.target.value,
            }),
        );
    };

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        dispatch(fetchLogs());
    }, [dispatch]);

    const isEndTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                min: moment(filters[FilterTypes.StartDate]).startOf('day'),
                max: moment().endOf('day'),
            }),
        [filters],
    );

    const isStartTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                max: moment().endOf('day'),
            }),
        [],
    );

    const handleChangeTime = React.useCallback(
        (e: Moment | string, filterType: FilterTypes) => {
            return dispatch(
                setFilter({
                    filterType: filterType,
                    value: moment(e).toISOString(),
                }),
            );
        },
        [dispatch],
    );
    const getCommonError = useCallback(time => {
        if (!!time && !isMoment(moment(time))) {
            return 'errors.incorrectTimeFormat';
        }
        if (moment(time).isAfter(new Date())) {
            return 'errors.dateAfterToday';
        }
        return '';
    }, []);

    const endTimeError = useMemo(() => {
        if (moment(endTime).isBefore(moment(startTime))) {
            return 'errors.endTime';
        }
        return getCommonError(endTime);
    }, [startTime, endTime, getCommonError]);

    const startTimeError = useMemo(() => {
        return getCommonError(new Date(startTime));
    }, [startTime, getCommonError]);

    return (
        <FormGrid columnAmount={4} rowHeight="20px" padding="10px 0">
            <DateTimeInput
                id="startDate"
                value={startTime || ''}
                onChange={e => handleChangeTime(e, FilterTypes.StartDate)}
                label={t('tripList.startDate')}
                getDisabledTime={isStartTimeDisabled}
                hasError={!!startTimeError}
                helperText={t(startTimeError)}
            />
            <DateTimeInput
                id="endDate"
                value={endTime || ''}
                onChange={e => handleChangeTime(e, FilterTypes.EndDate)}
                label={t('tripList.endDate')}
                getDisabledTime={isEndTimeDisabled}
                hasError={!!endTimeError}
                helperText={t(endTimeError)}
            />
            <SelectComponent
                label={t('logs.entity')}
                onChange={handleChangeFilter}
                value={filters[FilterTypes.Entity] || ''}
                options={tableOptions}
            />
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};
