import {
    PermissionActions,
    PermissionGroups,
    Permissions,
    PermissionsByCategory,
} from './types';

export const mapByCategory = (permissions: Permissions[]) => {
    return permissions.reduce<PermissionsByCategory>((acc, permission) => {
        const split = permission.split('_');
        const key = split[0] as PermissionGroups;
        const value = split[split.length - 1] as PermissionActions;
        return acc[key]
            ? {
                  ...acc,
                  [key]: [...acc[key], { value, permission }],
              }
            : {
                  ...acc,
                  [key]: [{ value, permission }],
              };
    }, {} as PermissionsByCategory);
};
