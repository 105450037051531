import React from 'react';
import { ButtonComponent, SearchComponent } from 'components/common';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { StringFilterTypes } from 'ducks/users/types';
import {
    requestExportUsers,
    selectFilters,
    setStringFilter,
} from 'ducks/users';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

export const UserSearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);

    const handleExportUsers = () => {
        dispatch(requestExportUsers());
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setStringFilter({
                filterType: StringFilterTypes.Search,
                value: e.target.value,
            }),
        );
    };
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToExport = accessToPages[Permissions.END_USER_EXPORT];

    return (
        <Box mb="10px" display="flex" justifyContent="space-between">
            <Box width="400px">
                <SearchComponent
                    placeholder={t('usersList.search')}
                    onChange={handleSearch}
                    defaultValue={filters[StringFilterTypes.Search]}
                />
            </Box>
            {allowedToExport && (
                <ButtonComponent
                    text={t('download')}
                    startIcon={<SaveAltIcon />}
                    handleClick={handleExportUsers}
                />
            )}
        </Box>
    );
};
