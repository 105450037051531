import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FetchedUserPayment } from 'ducks/paymentHistory/types';
import { DetailPanel } from 'components/common/Table/DetailPanel';

interface Interface {
    rowProps: FetchedUserPayment;
}

const useStyles = makeStyles(() =>
    createStyles({
        code: {
            whiteSpace: 'break-spaces',
        },
    }),
);

const StripeErrorDetails: React.FC<Interface> = ({
    rowProps: { stripeError },
}: Interface) => {
    const { code } = useStyles();

    return (
        <DetailPanel>
            <code className={code}>{stripeError}</code>
        </DetailPanel>
    );
};

export default React.memo(StripeErrorDetails);
