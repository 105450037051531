import { PaginationComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { selectPagination, setPage } from 'ducks/services';
import React from 'react';

export const ServicePagination = (props: unknown) => {
    const dispatch = useDispatch();

    const pagination = useSelector(selectPagination);

    const handleSetPage = (page: number) => {
        dispatch(setPage({ page }));
    };
    return (
        <PaginationComponent
            {...props}
            pagination={pagination}
            setPage={handleSetPage}
        />
    );
};
