import React, { FormEvent } from 'react';

import ToggleButton from '@material-ui/lab/ToggleButton';
import { Box, createStyles, Theme, withStyles } from '@material-ui/core';
import { colors } from 'themes/colors';
interface IToggleButtonInterface {
    text: string;
    handleClick: (e: FormEvent<HTMLButtonElement>) => void;
    isSelected: boolean;
}

const StyledToggleButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            color: colors.black,
            border: `2px solid ${theme.palette.primary.main}`,
        },
        selected: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: `${colors.white} !important`,
        },
        sizeSmall: {
            minWidth: '10%',
            borderRadius: '20px',
        },
    }),
)(ToggleButton);

export const ToggleButtonComponent: React.FC<IToggleButtonInterface> = ({
    text,
    handleClick,
    isSelected,
}: IToggleButtonInterface) => {
    return (
        <StyledToggleButton
            value="check"
            size="small"
            selected={isSelected}
            onChange={e => {
                handleClick(e);
            }}>
            <Box whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                {text}
            </Box>
        </StyledToggleButton>
    );
};
