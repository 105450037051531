import { RootState } from '../root';

export const selectFilters = (state: RootState) => state.zone.filters;
export const selectSort = (state: RootState) => state.zone.sort;
export const selectPagination = (state: RootState) => state.zone.pagination;
export const selectCurrentZone = (state: RootState) => state.zone.currentZone;
export const selectZoneError = (state: RootState) => state.zone.error;
export const selectIsLoading = (state: RootState) => state.zone.loading;
export const selectZoneHasUnsavedChanges = (state: RootState) =>
    state.zone.zoneHasUnsavedChanges;
export const selectImportedZones = (state: RootState) =>
    state.zone.importedZones;
