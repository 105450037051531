export const calculateMapCenter = (
    coordinates: { lat: number; lng: number }[],
) => {
    const longitudes: number[] = [];
    const latitudes: number[] = [];
    coordinates.forEach(({ lng, lat }) => {
        if (!isNaN(lat)) {
            longitudes.push(lng);
            latitudes.push(lat);
        }
    });

    latitudes.sort();
    longitudes.sort();

    const lowX = latitudes[0];
    const highX = latitudes[latitudes.length - 1];
    const lowY = longitudes[0];
    const highY = longitudes[longitudes.length - 1];

    const centerX = lowX + (highX - lowX) / 2;
    const centerY = lowY + (highY - lowY) / 2;

    return { lat: centerX || 0, lng: centerY || 0 };
};
