import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import {
    fetchLock,
    updateLock,
    setLockHasUnsavedChanges,
    selectLockHasUnsavedChanges,
    selectLockError,
    resetError,
    selectIsLoading,
    selectCurrentLock,
    selectCurrentLockVehicle,
    resetCurrentLock,
    sendCommand,
} from 'ducks/locks';
import { Form, FormSpy } from 'react-final-form';
import { ButtonComponent } from '../../common/Button';
import { useTranslation } from 'react-i18next';
import {
    FormGrid,
    SelectComponent,
    SelectField,
    TextField,
} from '../../common';
import { Typography, Box } from '@material-ui/core';
import { Prompt } from 'react-router';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { selectAccessToPages } from 'ducks/auth';
import { validations } from './validations';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { FormState } from 'final-form';
import { LockCommands, LockStatus, NewLock } from 'ducks/locks/types';
import { Permissions } from 'ducks/groups/types';
import { ErrorComponent } from '../../common/Error';
import { LockConfig } from './Config';
interface Lock {
    lockId: number;
}
export const UpdateLock: React.FC<Lock> = ({ lockId }: Lock) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const lockHasUnsavedChanges = useSelector(selectLockHasUnsavedChanges);
    const error = useSelector(selectLockError);
    const currentLock = useSelector(selectCurrentLock);
    const currentLockVehicle = useSelector(selectCurrentLockVehicle);

    const accessToPages = useSelector(selectAccessToPages);
    const loading = useSelector(selectIsLoading);
    const [configHidden, setConfigHidden] = React.useState(true);

    const [command, setCommand] = React.useState<LockCommands>(
        LockCommands.ASK_ERROR_LOG,
    );

    const commandTypeOptions = React.useMemo(
        () => [
            {
                label: t(`locks.${LockCommands.ASK_ERROR_LOG}`),
                value: LockCommands.ASK_ERROR_LOG,
            },
            {
                label: t(`locks.${LockCommands.ASK_REPORT_DATA}`),
                value: LockCommands.ASK_REPORT_DATA,
            },
            {
                label: t(`locks.${LockCommands.ASK_VAE_DATA}`),
                value: LockCommands.ASK_VAE_DATA,
            },
            {
                label: t(`locks.${LockCommands.RESET_PROCESSOR}`),
                value: LockCommands.RESET_PROCESSOR,
            },
            {
                label: t(`locks.${LockCommands.START_GPS_ACQUISITION}`),
                value: LockCommands.START_GPS_ACQUISITION,
            },
        ],
        [t],
    );

    const allowedToEdit = accessToPages[Permissions.VEHICLE_UPDATE];

    React.useEffect(() => {
        dispatch(fetchLock(lockId));
        dispatch(resetError());
        return () => {
            dispatch(resetCurrentLock());
            dispatch(setLockHasUnsavedChanges(false));
        };
    }, [dispatch, lockId]);

    const initialValues = React.useMemo(() => {
        return {
            version: currentLock?.version || '',
            status: currentLock?.status || '',
            comment: currentLock?.comment || '',
        };
    }, [currentLock]);

    const handleConfirm = React.useCallback(
        (values: NewLock) => {
            dispatch(
                updateLock(
                    {
                        ...values,
                    },
                    lockId,
                ),
            );
        },
        [dispatch, lockId],
    );

    const handleSendCommand = React.useCallback(() => {
        dispatch(sendCommand(command));
    }, [dispatch, command]);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setLockHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    const statusOptions = useMemo(() => {
        return Object.values(LockStatus).map(status => ({
            label: t(`locks.lockStatus.${status}`),
            value: status,
        }));
    }, [t]);

    useNotificationOnPageLeave(lockHasUnsavedChanges);

    const setNewCommand = React.useCallback(
        e => dispatch(setCommand(e.target.value)),
        [dispatch],
    );

    return (
        <>
            {loading && <LoaderComponent />}
            <Prompt
                when={lockHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                initialValues={initialValues}
                onSubmit={handleConfirm}
                render={({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                dirtySinceLastSubmit: true,
                                submitSucceeded: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <FormGrid columnAmount={2}>
                            <TextField
                                disabled={!allowedToEdit}
                                name="version"
                                validators={validations.version}
                                label={t('locks.version')}
                                isRequired
                            />
                            <SelectField
                                disabled={
                                    !allowedToEdit || !!currentLockVehicle
                                }
                                name="status"
                                label={t('locks.status')}
                                options={statusOptions}
                            />
                        </FormGrid>
                        <FormGrid columnAmount={1}>
                            <TextField
                                multiline
                                validators={validations.comment}
                                disabled={!allowedToEdit}
                                name="comment"
                                label={t('locks.comment')}
                            />
                        </FormGrid>
                        <FormGrid columnAmount={2}>
                            <Box>
                                <Typography variant="h3">
                                    {t('locks.id')}
                                </Typography>
                                <Typography>
                                    <span>{currentLock?.id}</span>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h3">
                                    {t('locks.beamId')}
                                </Typography>
                                <Typography>
                                    <span>{currentLock?.beamId || '-'}</span>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h3">
                                    {t('locks.qrCode')}
                                </Typography>
                                <Typography>
                                    <span>{currentLock?.qrCode}</span>
                                </Typography>
                            </Box>

                            <Box>
                                <Typography variant="h3">
                                    {t('locks.lastDate')}
                                </Typography>
                                <Typography>
                                    <span>
                                        {currentLock?.lastSync
                                            ? new Date(
                                                  currentLock?.lastSync,
                                              ).toLocaleString()
                                            : '-'}
                                    </span>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h3">
                                    {t('locks.created')}
                                </Typography>
                                <Typography>
                                    <span>
                                        {currentLock?.created
                                            ? new Date(
                                                  currentLock?.created,
                                              ).toLocaleString()
                                            : '-'}
                                    </span>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h3">
                                    {t('locks.batteryLevel')}
                                </Typography>
                                <Typography>
                                    <span>
                                        {currentLock?.batteryLevel || '-'}
                                    </span>
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h3">
                                    {t('locks.vehicle')}
                                </Typography>
                                <Typography>
                                    <span>
                                        {currentLockVehicle?.serial || '-'}
                                    </span>
                                </Typography>
                            </Box>
                        </FormGrid>
                        <FormButtonGroup>
                            <ButtonComponent
                                isDisabled={loading}
                                handleClick={() =>
                                    setConfigHidden(!configHidden)
                                }
                                text={
                                    configHidden
                                        ? t('getConfig')
                                        : t('hideConfig')
                                }
                            />
                            <ButtonComponent
                                isDisabled={loading || !command}
                                handleClick={handleSendCommand}
                                text={t('locks.sendCommand')}
                            />
                            <Box width={200}>
                                <SelectComponent
                                    value={command}
                                    onChange={setNewCommand}
                                    options={commandTypeOptions}
                                />
                            </Box>
                        </FormButtonGroup>
                        {!configHidden && <LockConfig lockId={lockId} />}
                        <FormButtonGroup>
                            <ButtonComponent
                                handleClick={handleSubmit}
                                text={t('update')}
                                isDisabled={!lockHasUnsavedChanges || !valid}
                            />
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </>
    );
};
