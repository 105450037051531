import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { ButtonComponent, ThemedBoxComponent } from 'components/common';
import arrayMutators from 'final-form-arrays';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@material-ui/core';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { SelectedService, UsageTypes } from 'ducks/services/types';
import {
    createService,
    fetchService,
    setServiceHasUnsavedChanges,
    toggleServiceEnable,
    updateService,
    selectIsLoading,
    selectCurrentService,
    selectServiceError,
    selectServiceHasUnsavedChanges,
} from 'ducks/services';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import SettingsIcon from '@material-ui/icons/Settings';
import BackIcon from '@material-ui/icons/ArrowBack';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { Prompt } from 'react-router-dom';
import { CreateServiceFormContent } from './FormContent';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import moment from 'moment';
import { LoaderComponent } from '../../common/Loader';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import { useFormMutators } from 'hooks/useFormMutators';

export const CreateServiceForm: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accessToPages = useSelector(selectAccessToPages);
    const loading = useSelector(selectIsLoading);
    const currentService = useSelector(selectCurrentService);
    const error = useSelector(selectServiceError);
    const serviceHasUnsavedChanges = useSelector(
        selectServiceHasUnsavedChanges,
    );
    const allowedToEdit = id
        ? accessToPages[Permissions.SERVICE_UPDATE]
        : accessToPages[Permissions.SERVICE_CREATE];
    const allowedToUpdateStatus =
        accessToPages[Permissions.SERVICE_UPDATE_STATUS];

    const handleConfirm = React.useCallback(
        (values: SelectedService) => {
            let submitValues = values;
            if (values.type === UsageTypes.LONG_TERM_USAGE) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { zones, ...clearedValues } = values;
                submitValues = clearedValues;
            }
            const formedValues = {
                ...submitValues,
                cgauVersion: new Date(
                    moment(submitValues.cgauVersion).format('YYYY-MM-DD'),
                ).toISOString(),
            };
            values.id
                ? dispatch(updateService(formedValues))
                : dispatch(createService(formedValues));
        },
        [dispatch],
    );

    const toggleEnable = () => {
        dispatch(toggleServiceEnable(id, !currentService.isEnabled));
    };

    const redirectToSchedule = React.useCallback(
        () =>
            dispatch(
                push(routes.SERVICE_CONFIGURATION.replace(':id', `${id}`)),
            ),
        [id, dispatch],
    );

    const redirectToList = React.useCallback(
        () => dispatch(push(routes.SERVICES)),
        [dispatch],
    );

    React.useEffect(() => {
        id && dispatch(fetchService(id));
    }, [dispatch, id]);
    React.useEffect(() => {
        return () => {
            dispatch(setServiceHasUnsavedChanges(false));
        };
    }, [dispatch]);
    const initialValues = React.useMemo(
        () =>
            id && currentService?.id
                ? {
                      ...currentService,
                  }
                : {
                      isPauseAllowed: false,
                      isAutocloseTrip: false,
                      isMultirentAllowed: false,
                      isGeofenceEnabled: false,
                      isStationConnected: false,
                      isLoopEnabled: false,
                      isBookingAllowed: false,
                      descriptionTemplates: [],
                      isOpenToPublic: false,
                      isFreeToInvitee: false,
                      isCreditCardRequired: false,
                      specificMapLayer: false,
                      bookingDurations: [],
                  },
        [id, currentService],
    );

    useNotificationOnPageLeave(serviceHasUnsavedChanges);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setServiceHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    const basicMutators = useFormMutators();

    const mutators = React.useMemo(
        () => ({
            ...basicMutators,
            ...arrayMutators,
        }),
        [basicMutators],
    );

    return (
        <ThemedBoxComponent m="0 25%">
            {loading && <LoaderComponent />}
            <Prompt
                when={serviceHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={handleConfirm}
                initialValues={initialValues}
                mutators={mutators}
                render={({ handleSubmit, valid, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToList}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {t(id ? 'updateService' : 'createService')}
                                </p>
                            </Typography>
                            {id && (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    ml="auto">
                                    <IconButton onClick={redirectToSchedule}>
                                        <SettingsIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                        <CreateServiceFormContent form={form} values={values} />
                        <FormButtonGroup>
                            {id && allowedToUpdateStatus && (
                                <ButtonComponent
                                    text={t(
                                        currentService.isEnabled
                                            ? 'disable'
                                            : 'enable',
                                    )}
                                    handleClick={toggleEnable}
                                    isDisabled={loading}
                                />
                            )}
                            {allowedToEdit && (
                                <ButtonComponent
                                    handleClick={handleSubmit}
                                    text={t(id ? 'save' : 'create')}
                                    isDisabled={
                                        !serviceHasUnsavedChanges ||
                                        !valid ||
                                        loading
                                    }
                                />
                            )}
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
