import { TableComponent } from '../../common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSort,
    fetchCompanies,
    selectCompanyIds,
    selectCompaniesById,
    selectIsLoading,
} from 'ducks/companies';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { CompanySearchToolbar } from './CompanySearchToolbar';
import { ScooterPagination } from './Pagination';
import { Company } from 'ducks/companies/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const CompaniesList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const redirectToCompany = (id: number) =>
        dispatch(push(routes.COMPANY.replace(':companyId', `${id}`)));

    const sort = useSelector(selectSort);
    const columns = getColumns(t, dispatch);

    React.useEffect(() => {
        dispatch(fetchCompanies());
    }, [dispatch]);

    const companyIds = useSelector(selectCompanyIds);
    const companiesById = useSelector(selectCompaniesById);
    const loading = useSelector(selectIsLoading);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'edit',
                        tooltip: t('edit'),
                        onClick: (event, rowData: Company) =>
                            rowData && redirectToCompany(rowData.id),
                        position: 'row',
                    },
                ]}
                onRowClick={(event, rowData: Company | undefined) =>
                    rowData && redirectToCompany(rowData.id)
                }
                searchComponent={CompanySearchToolbar}
                columns={columns}
                pagination={ScooterPagination}
                data={companyIds.map(id => ({
                    ...companiesById[id],
                }))}
                title={t('company.listTitle')}
                sort={sort}
            />
        </>
    );
};
