import { TabPanel } from 'components/common/Tabs/TabPanel';
import { TabsComponent } from 'components/common/Tabs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { useTranslation } from 'react-i18next';
import {
    selectActiveVehicleTab,
    setActiveVehicleTab,
} from 'ducks/vehicles/common';

type VehicleProps = {
    vehicleTabs: React.FC[];
    vehicleTitles?: string[];
    listRout: string;
};

export const VehicleDetails: React.FC<VehicleProps> = ({
    vehicleTabs,
    vehicleTitles,
    listRout,
}: VehicleProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const activeVehicleTab = useSelector(selectActiveVehicleTab);

    const formedTabs = React.useMemo(() => {
        const tabs = vehicleTabs;
        const titles = vehicleTitles
            ? vehicleTitles
            : ['vehicles.basicInformation'];
        return { tabs, titles };
    }, [vehicleTabs, vehicleTitles]);

    const redirectToList = React.useCallback(
        () => dispatch(push(listRout)),
        [listRout, dispatch],
    );

    const setActiveTab = React.useCallback(
        (tab: number) => dispatch(setActiveVehicleTab(tab)),
        [dispatch],
    );

    return (
        <>
            <Box display="flex" alignItems="center" mb="20px">
                <IconButton onClick={redirectToList}>
                    <BackIcon />
                </IconButton>
                {t('backToList')}
            </Box>
            <TabsComponent
                currentTab={activeVehicleTab}
                setTab={setActiveTab}
                tabTitles={formedTabs.titles}>
                <>
                    {formedTabs.tabs.map((tab, i) => {
                        const Tab = tab;
                        return (
                            <TabPanel index={i} key={i}>
                                <Tab />
                            </TabPanel>
                        );
                    })}
                </>
            </TabsComponent>
        </>
    );
};
