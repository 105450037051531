import { Pagination, Sort } from 'ducks/common/types';
import { Vehicle, VehicleStatus, VehicleTypes } from '../vehicles/common/types';
import { FetchedService, Company } from '../services/types';
import { Station } from '../stations/types';
import { Profile, User } from '../users/types';
import { Zone } from '../zones/types';

export type Pause = {
    start: string;
    end: string;
    lat: string;
    lng: string;
};

export type FetchedTrip = {
    id: number;
    startTime: string;
    endTime: string;
    startZones: Zone[];
    endZones: Zone[];
    startStation: Station;
    endStation: Station;
    numberOfPauses: number;
    startBatteryLevel: number;
    endBatteryLevel: number;
    endUser: User;
    service: FetchedService;
    vehicle: Vehicle;
    time?: string;
    status: TripStatuses;
};

export type FetchedCurrentTrip = {
    trip: any;
    booking: any;
    endUser: User;
    company: Company;
    vehicle: Vehicle;
    lock: any;
    service: FetchedService;
    startStation: Station;
    endStation: Station;
    profile: Profile;
};

export type TripsById = {
    [code: number]: FetchedTrip;
};

export enum FilterTypes {
    VehicleTypes = 'vehicleTypes',
    Statuses = 'statuses',
    StartZone = 'startZone',
    EndZone = 'endZone',
    StartTime = 'startTime',
    EndTime = 'endTime',
    VehicleKey = 'vehicleKey',
    VehicleIds = 'vehicleIds',
    UserKey = 'userKey',
    Services = 'services',
    MaxDurationOverdue = 'maxDurationOverdue',
}

export type Filters = {
    [FilterTypes.VehicleTypes]?: string;
    [FilterTypes.Statuses]?: string;
    [FilterTypes.StartZone]?: string;
    [FilterTypes.EndZone]?: string;
    [FilterTypes.StartTime]?: string;
    [FilterTypes.EndTime]?: string;
    [FilterTypes.VehicleKey]?: string;
    [FilterTypes.UserKey]?: string;
    [FilterTypes.VehicleIds]?: number;
    [FilterTypes.VehicleIds]?: number;
    [FilterTypes.Services]?: number;
    [FilterTypes.MaxDurationOverdue]?: boolean;
};

export enum TripStatuses {
    STARTED = 'STARTED',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
}

export type TripState = {
    loading: boolean;
    error: string;
    tripIds: number[];
    tripsById: TripsById;
    maxTripIds: number[];
    maxTripsById: TripsById;
    currentTrip: FetchedCurrentTrip | null;
    filters: Filters;
    sort: Sort;
    overdueSort: Sort;
    pagination: Pagination;
    statistics: Statistics | null;
};
export type Statistics = {
    byStatus: StatsByStatus[];
    rented: {
        date: 'today' | 'yesterday';
        [VehicleTypes.SCOOTER]: number;
        [VehicleTypes.VAE]: number;
        [VehicleTypes.BIKE]: number;
    }[];
};
export type StatsByStatus = {
    status: VehicleStatus;
    [VehicleTypes.SCOOTER]: number;
    [VehicleTypes.VAE]: number;
    [VehicleTypes.BIKE]: number;
};
export type FetchedStatistics = {
    vehicles: {
        [key in VehicleTypes]: {
            [key in VehicleStatus]: number;
        };
    };
    todayTrips: {
        [key in VehicleTypes]: number;
    };
    yesterdayTrips: {
        [key in VehicleTypes]: number;
    };
};

export type TripResponse = {
    content: FetchedTrip[];
} & Pagination;

export type Accumulator = {
    tripIds: number[];
    tripsById: TripsById;
};
