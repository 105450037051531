import { PaginationComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { selectPagination, setPage } from 'ducks/groups';

export const Pagination = (props: unknown) => {
    const dispatch = useDispatch();
    const pagination = useSelector(selectPagination);

    const handleSetPage = useCallback(
        (page: number) => {
            dispatch(setPage({ page }));
        },
        [dispatch],
    );

    return (
        <PaginationComponent
            {...props}
            pagination={pagination}
            setPage={handleSetPage}
        />
    );
};
