import React, { ReactNode } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { calculateMapCenter } from 'tools/map';
import { GOOGLE_MAP_KEY } from '../../../config';

const mapContainerStyle = {
    height: '55vh',
};

export type MapProps = {
    center?: { lat: number; lng: number };
    boundCoordinates: { lat: number; lng: number }[];
    children: ReactNode;
    containerStyle?: React.CSSProperties;
};

export const MapComponent: React.FC<MapProps> = ({
    boundCoordinates,
    children,
    containerStyle = { ...mapContainerStyle },
}: MapProps) => {
    const [map, setMap] = React.useState<google.maps.Map | null>(null);
    React.useEffect(() => {
        if (window.google) {
            const bounds = new window.google.maps.LatLngBounds();
            if (map) {
                if (boundCoordinates.length > 1) {
                    boundCoordinates.map(coord => bounds.extend(coord));
                    map.fitBounds(bounds);
                    map.setCenter(calculateMapCenter(boundCoordinates));
                    if (Number(map.getZoom()) > 17) {
                        map.setZoom(17);
                    }
                } else {
                    map.setZoom(17);
                    map.setCenter(calculateMapCenter(boundCoordinates));
                }
            }
        }
    }, [boundCoordinates, map]);

    const onMapLoad = React.useCallback(
        (map: google.maps.Map) => {
            setMap(map);
        },
        [setMap],
    );

    return (
        <LoadScript googleMapsApiKey={GOOGLE_MAP_KEY || ''}>
            <GoogleMap
                onLoad={onMapLoad}
                zoom={22}
                mapContainerStyle={containerStyle}>
                {children}
            </GoogleMap>
        </LoadScript>
    );
};
