import React, { useCallback, useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import {
    ButtonComponent,
    CheckboxField,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from 'components/common';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import {
    createBOUser,
    fetchBOUser,
    resetCurrentBoUser,
    setUserHasUnsavedChanges,
    updateBOUser,
    toggleBOUserEnable,
} from 'ducks/boUsers';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { Prompt, useParams } from 'react-router';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import BackIcon from '@material-ui/icons/ArrowBack';
import { EditUser, Languages } from 'ducks/boUsers/types';
import { FormGrid } from '../../common/FormGrid';
import {
    composeValidators,
    emailRegex,
    fieldLength,
    isValidEmail,
    required,
} from 'tools/validationRules';
import { selectAllGroupIds, selectAllGroupsById } from 'ducks/groups/selectors';
import { fetchAllGroups } from 'ducks/groups';
import {
    selectCurrentBoUser,
    selectError,
    selectIsLoading,
    selectUserHasUnsavedChanges,
} from 'ducks/boUsers';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { LeaveDateInput } from './LeaveDateInput';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { Group, Permissions } from 'ducks/groups/types';
import { Company } from 'ducks/companies/types';
import { FormApi, FormState } from 'final-form';
import { selectAccessToPages } from 'ducks/auth';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { ErrorComponent } from '../../common/Error';
import { useFormMutators } from 'hooks/useFormMutators';

export const CreateBackofficeUser: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userId } = useParams<{ userId?: string }>();
    const { user, groups, companies } = useSelector(selectCurrentBoUser);
    const loading = useSelector(selectIsLoading);
    const error = useSelector(selectError);
    const userHasUnsavedChanges = useSelector(selectUserHasUnsavedChanges);

    const allGroupIds = useSelector(selectAllGroupIds);
    const allGroupsById = useSelector(selectAllGroupsById);
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const accessToPages = useSelector(selectAccessToPages);
    const isUserAnonymized = !emailRegex.test(user?.email);

    const allowedToEdit =
        accessToPages[
            userId ? Permissions.BO_USER_UPDATE : Permissions.BO_USER_CREATE
        ];
    const allowedToUpdateStatus =
        accessToPages[Permissions.BO_USER_UPDATE_STATUS];

    useEffect(() => {
        if (userId) {
            dispatch(fetchBOUser(userId));
        }
    }, [dispatch, userId]);

    useEffect(() => {
        dispatch(fetchAllGroups());
        dispatch(fetchAllCompanies());
        return () => {
            dispatch(setUserHasUnsavedChanges(false));
            dispatch(resetCurrentBoUser());
        };
    }, [dispatch]);

    const redirectToList = useCallback(
        () => dispatch(push(routes.BO_USERS)),
        [dispatch],
    );

    const handleConfirm = useCallback(
        (values: EditUser) => {
            if (userId) {
                dispatch(updateBOUser(values));
            } else {
                dispatch(createBOUser(values));
            }
        },
        [dispatch, userId],
    );

    const changeLeaveDate = useCallback(
        (values: EditUser, form: FormApi<EditUser>) => (value: string) => {
            form.mutators.setField({
                field: 'leaveDate',
                value: value ? value : '',
            });
        },
        [],
    );

    const toggleEnable = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: () => {
                        dispatch(toggleBOUserEnable(userId, !user.isEnabled));
                        dispatch(closeModal());
                    },
                    message: t('backofficeUsers.changeStatusMessage'),
                },
            }),
        );
    }, [dispatch, userId, user, t]);

    const initialValues = React.useMemo(() => {
        if (userId && user) {
            return {
                isManager: user.isManager,
                isSuperAdmin: user.isSuperAdmin,
                companies: companies.map((company: Company) => company.id),
                groups: groups.map((group: Group) => group.id),
                language: user.language,
                email: user.email,
                leaveDate: user.leaveDate,
            };
        }
        return {
            isManager: false,
            companies: [],
            groups: [],
            language: Languages.EN,
            email: '',
            isSuperAdmin: false,
        };
    }, [companies, groups, user, userId]);

    const groupOptions = React.useMemo(
        () =>
            allGroupIds.map(id => ({
                label: allGroupsById[id].name,
                value: id,
            })),
        [allGroupIds, allGroupsById],
    );

    const companyOptions = React.useMemo(
        () =>
            allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        [allCompanyIds, allCompaniesById],
    );

    const languageOptions = React.useMemo(
        () =>
            Object.values(Languages).map(language => ({
                label: language,
                value: language,
            })),
        [],
    );

    useNotificationOnPageLeave(userHasUnsavedChanges);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setUserHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    const mutators = useFormMutators();

    return (
        <ThemedBoxComponent m="0 25%">
            {loading && <LoaderComponent />}
            <Prompt
                when={userHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={(values: EditUser) => {
                    handleConfirm(values);
                }}
                initialValues={initialValues}
                mutators={mutators}
                render={({ handleSubmit, valid, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToList}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {userId
                                        ? t('updateBOUser')
                                        : t('createBOUser')}
                                </p>
                            </Typography>
                        </Box>
                        <FormGrid columnAmount={2}>
                            <TextField
                                disabled={!allowedToEdit}
                                name="email"
                                validators={composeValidators(
                                    required,
                                    isValidEmail,
                                    (value: string) =>
                                        fieldLength(value, 5, 63),
                                )}
                                label={t('backofficeUsers.email')}
                                isRequired
                            />
                            <SelectField
                                disabled={!allowedToEdit}
                                name="groups"
                                validators={composeValidators(required)}
                                label={t('backofficeUsers.group')}
                                options={groupOptions}
                                isRequired
                            />
                            <SelectField
                                name="language"
                                validators={composeValidators(required)}
                                label={t('backofficeUsers.language')}
                                options={languageOptions}
                                disabled={!allowedToEdit}
                                isRequired
                            />
                            <SelectField
                                multiple={!values.isManager}
                                name="companies"
                                validators={composeValidators(required)}
                                label={t('backofficeUsers.companies')}
                                options={companyOptions}
                                disabled={!allowedToEdit}
                                isRequired
                            />
                            {userId && (
                                <LeaveDateInput
                                    changeLeaveDate={changeLeaveDate(
                                        values,
                                        form,
                                    )}
                                />
                            )}

                            <CheckboxField
                                key="isManager"
                                field="isManager"
                                label="backofficeUsers.isManager"
                                disabled={!!userId || !allowedToEdit}
                                handleChange={(value: boolean) => {
                                    form.mutators.setField({
                                        field: 'companies',
                                        value: value
                                            ? values?.companies[0] || ''
                                            : [],
                                    });
                                }}
                            />
                            <CheckboxField
                                key="isSuperAdmin"
                                field="isSuperAdmin"
                                label="backofficeUsers.isSuperAdmin"
                                disabled={!allowedToEdit}
                            />
                        </FormGrid>
                        <FormButtonGroup>
                            {userId && allowedToUpdateStatus && (
                                <ButtonComponent
                                    text={t(
                                        user?.isEnabled ? 'disable' : 'enable',
                                    )}
                                    handleClick={toggleEnable}
                                    isDisabled={isUserAnonymized}
                                />
                            )}
                            {allowedToEdit && (
                                <ButtonComponent
                                    handleClick={handleSubmit}
                                    text={userId ? t('save') : t('create')}
                                    isDisabled={
                                        !userHasUnsavedChanges ||
                                        !valid ||
                                        loading
                                    }
                                />
                            )}
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
