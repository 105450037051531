import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '../common/types';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { FilterTypes, StringFilterTypes } from './types';
import { ZoneState } from './index';

const defaultFilters = {
    name: '',
    isForbidden: false,
};
export const initialState: ZoneState = {
    loading: false,
    error: '',
    zoneIds: [],
    zoneHasUnsavedChanges: false,
    importedZones: {},
    zonesById: {},
    sort: {
        value: 'name',
        direction: SortDirection.Asc,
    },
    pagination: defaultPagination,
    filters: defaultFilters,
    allZonesById: {},
    allZoneIds: [],
    currentZone: null,
};

const zoneSlice = createSlice({
    name: 'zone',
    initialState,
    reducers: {
        fetchZones(state) {
            state.loading = true;
        },
        fetchZonesSuccess(state, action) {
            state.zoneIds = action.payload.zoneIds;
            state.zonesById = action.payload.zonesById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchZonesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setZoneHasUnsavedChanges(state, action) {
            state.zoneHasUnsavedChanges = action.payload;
        },
        setImportedZones(state, action) {
            state.importedZones = action.payload;
        },
        fetchZone: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: zoneId => ({
                payload: zoneId,
            }),
        },
        fetchZoneSuccess(state, action) {
            state.loading = false;
            state.currentZone = action.payload;
        },
        fetchZoneError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        deleteZone: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: zoneId => ({
                payload: zoneId,
            }),
        },
        deleteZoneSuccess(state) {
            state.loading = false;
            state.currentZone = null;
            state.zoneHasUnsavedChanges = false;
        },
        deleteZoneError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetCurrentZone(state) {
            state.currentZone = null;
        },
        createZone: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.zoneHasUnsavedChanges = true;
            },
            prepare: zone => ({
                payload: zone,
            }),
        },
        createZoneSuccess(state) {
            state.loading = false;
            state.zoneHasUnsavedChanges = false;
            state.importedZones = {};
        },
        createZoneError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateZone: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.zoneHasUnsavedChanges = true;
            },
            prepare: zone => ({
                payload: zone,
            }),
        },
        updateZoneSuccess(state) {
            state.loading = false;
            state.zoneHasUnsavedChanges = false;
            state.importedZones = {};
        },
        updateZoneError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setStringFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: StringFilterTypes;
                value: string;
            }>,
        ) {
            state.filters[payload.filterType] = payload.value;
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setFilter(state, { payload }: PayloadAction<FilterTypes>) {
            state.filters[payload] = !state.filters[payload];
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        fetchAllZones(state) {
            state.loading = true;
            state.error = '';
        },
        fetchAllZonesSuccess(state, action) {
            state.allZonesById = action.payload.byId;
            state.allZoneIds = action.payload.allIds;
            state.loading = false;
        },
        fetchAllZonesError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        resetError(state) {
            state.error = '';
        },
    },
});
export const {
    fetchZones,
    fetchZonesError,
    fetchZonesSuccess,
    setZoneHasUnsavedChanges,
    setImportedZones,
    createZone,
    createZoneError,
    createZoneSuccess,
    setStringFilter,
    setSort,
    setPage,
    setFilter,
    fetchAllZones,
    fetchAllZonesError,
    fetchAllZonesSuccess,
    resetFilters,
    fetchZone,
    fetchZoneError,
    fetchZoneSuccess,
    deleteZone,
    deleteZoneError,
    deleteZoneSuccess,
    updateZone,
    updateZoneError,
    updateZoneSuccess,
    resetCurrentZone,
    resetError,
} = zoneSlice.actions;

export const zoneReducer = zoneSlice.reducer;
