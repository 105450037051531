import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {
    createNews,
    fetchOneNews,
    resetCurrentNews,
    setNewsHasUnsavedChanges,
    selectCurrentNews,
    updateNews,
    deleteNews,
    selectNewsHasUnsavedChanges,
    selectNewsError,
    resetError,
    selectIsLoading,
} from 'ducks/news';
import { CheckboxField } from '../../common/Form/FormCheckboxField';
import { Form, FormSpy } from 'react-final-form';
import { ButtonComponent } from '../../common/Button';
import { useTranslation } from 'react-i18next';
import {
    FormGrid,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from '../../common';
import SaveIcon from '@material-ui/icons/Save';
import { Typography, Box, IconButton } from '@material-ui/core';
import { Prompt, useParams } from 'react-router';
import DeleteIcon from '@material-ui/icons/Delete';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { validations, checkIfStartTimeIsLaterThanEnd } from './validations';
import { News, NewsTypes } from 'ducks/news/types';
import { StyledFormControlLabel } from '../../common/FormControlLabel';
import { DateTimeField } from '../../common/Form/CommonFields/DateTimeField';
import moment from 'moment';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import { UploadFileField } from '../../common/DropzoneField';
import { DeletableFileComponent } from '../../common/File';
import { useFormMutators } from 'hooks/useFormMutators';

export const CreateNews: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { newsId } = useParams<{ newsId?: string }>();
    const currentNews = useSelector(selectCurrentNews);
    const newsHasUnsavedChanges = useSelector(selectNewsHasUnsavedChanges);
    const error = useSelector(selectNewsError);
    const accessToPages = useSelector(selectAccessToPages);
    const loading = useSelector(selectIsLoading);

    const allowedToEdit =
        accessToPages[
            newsId ? Permissions.NEWS_UPDATE : Permissions.NEWS_CREATE
        ];
    const allowedToDelete = accessToPages[Permissions.NEWS_DELETE];

    React.useEffect(() => {
        dispatch(resetError());
        dispatch(fetchAllCompanies());
        if (newsId) {
            dispatch(fetchOneNews(newsId));
        }
        return () => {
            dispatch(resetCurrentNews());
            dispatch(setNewsHasUnsavedChanges(false));
        };
    }, [dispatch, newsId]);

    const redirectToNews = React.useCallback(
        () => dispatch(push(routes.NEWS)),
        [dispatch],
    );

    const initialValues = React.useMemo(
        () =>
            newsId && currentNews
                ? {
                      ...currentNews,
                      end: currentNews?.end,
                      start: currentNews?.start,
                  }
                : {
                      companyId: '',
                      type: '',
                      isLoopEnabled: false,
                      files: [],
                  },
        [newsId, currentNews],
    );

    const handleConfirm = React.useCallback(
        (values: News) => {
            const end = new Date(values.end).toISOString();
            const start = new Date(values.start).toISOString();
            dispatch(
                newsId
                    ? updateNews({
                          ...values,
                          end,
                          start,
                      })
                    : createNews({
                          ...values,
                          end,
                          start,
                      }),
            );
        },
        [dispatch, newsId],
    );

    const handleDelete = React.useCallback(() => {
        dispatch(deleteNews(newsId));
        dispatch(closeModal());
    }, [dispatch, newsId]);

    const handleClickDelete = React.useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: handleDelete,
                    message: 'news.deleteMessage',
                },
            }),
        );
    }, [dispatch, handleDelete]);

    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);

    const isEnabled = currentNews?.isEnabled;
    const companyName = currentNews?.companyName;
    const companyId = currentNews?.companyId;

    const companyOptions = React.useMemo(() => {
        const result = allCompanyIds.map(id => ({
            label: allCompaniesById[id].name,
            value: id,
        }));
        if (companyName && companyId && isEnabled === false) {
            result.unshift({
                label: companyName,
                value: companyId,
            });
        }
        return result;
    }, [allCompanyIds, allCompaniesById, isEnabled, companyName, companyId]);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setNewsHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    const typeOptions = React.useMemo(
        () =>
            Object.values(NewsTypes).map(type => ({
                label: t(`newsTypes.${type}`),
                value: type,
            })),
        [t],
    );

    const mutators = useFormMutators();

    useNotificationOnPageLeave(newsHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 25%" position="relative">
            {loading && <LoaderComponent />}
            <Prompt
                when={newsHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                // in this case key is required to remount the form as soon as we
                // recreate end and start dates value (date from string) and these
                // values are not shallow equal, so form can not detect that values
                // were not changed at all
                key={newsId}
                initialValues={initialValues}
                onSubmit={handleConfirm}
                mutators={mutators}
                validate={(values: News) => {
                    return checkIfStartTimeIsLaterThanEnd(values);
                }}
                render={({ handleSubmit, valid, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToNews}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {t(
                                        newsId
                                            ? 'news.updateNews'
                                            : 'news.addNews',
                                    )}
                                </p>
                            </Typography>
                        </Box>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                dirtySinceLastSubmit: true,
                                submitSucceeded: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <FormGrid columnAmount={2}>
                            <TextField
                                disabled={!allowedToEdit}
                                name="title"
                                validators={validations.title}
                                label={t('news.title')}
                                isRequired
                            />
                            <SelectField
                                handleChange={() =>
                                    form.mutators.resetField({
                                        field: 'file',
                                    })
                                }
                                disabled={!allowedToEdit}
                                name="type"
                                label={t('news.type')}
                                options={typeOptions}
                            />
                            <DateTimeField
                                id="start"
                                min={moment().startOf('day')}
                                validators={validations.start}
                                isRequired
                                disabled={!allowedToEdit}
                                name="start"
                                label={t('news.start')}
                            />
                            <DateTimeField
                                id="end"
                                min={values.start || moment().startOf('day')}
                                disabled={!allowedToEdit}
                                name="end"
                                validators={validations.end}
                                label={t('news.end')}
                                isRequired
                            />
                            <SelectField
                                name="companyId"
                                label={t('news.companyName')}
                                options={companyOptions}
                                disabled={!allowedToEdit}
                            />
                            <Box mt="auto" mb="auto">
                                <CheckboxField
                                    key="isLoopEnabled"
                                    field="isLoopEnabled"
                                    label="news.isLoopEnabled"
                                    disabled={!allowedToEdit}
                                />
                            </Box>
                            <TextField
                                multiline
                                disabled={!allowedToEdit}
                                validators={validations.content}
                                name="content"
                                label={t('news.content')}
                                isRequired
                            />
                            <div>
                                {values.type === NewsTypes.POPUP &&
                                    allowedToEdit && (
                                        <StyledFormControlLabel
                                            labelPlacement="top"
                                            label={t('news.image')}
                                            control={
                                                <UploadFileField
                                                    name="file"
                                                    isDropzone={false}
                                                />
                                            }
                                        />
                                    )}
                                {values.file && (
                                    <DeletableFileComponent
                                        image={values.file}
                                        showDeleteButton={false}
                                        key={values.file?.url}
                                    />
                                )}
                            </div>
                        </FormGrid>
                        {!newsId ? (
                            <FormButtonGroup>
                                <ButtonComponent
                                    handleClick={handleSubmit}
                                    text={t('create')}
                                    isDisabled={
                                        !newsHasUnsavedChanges || !valid
                                    }
                                />
                            </FormButtonGroup>
                        ) : (
                            <FormButtonGroup>
                                {allowedToDelete && (
                                    <ButtonComponent
                                        handleClick={handleClickDelete}
                                        text={t('delete')}
                                        startIcon={<DeleteIcon />}
                                    />
                                )}
                                {allowedToEdit && (
                                    <ButtonComponent
                                        handleClick={handleSubmit}
                                        text={t('update')}
                                        isDisabled={
                                            loading ||
                                            !newsHasUnsavedChanges ||
                                            !valid
                                        }
                                        startIcon={<SaveIcon />}
                                    />
                                )}
                            </FormButtonGroup>
                        )}
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
