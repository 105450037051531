import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    serviceBox: {
        wordWrap: 'break-word',
    },
    deleteIcon: {
        padding: 0,
    },
    date: {
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#EEE',
        },
    },
});
