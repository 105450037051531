import { createStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import React, { ReactNode } from 'react';
export const StyledFormGrid = withStyles(() =>
    createStyles({
        root: {
            display: 'grid',
            gridGap: '10px 20px',
            alignItems: 'flex-start',
        },
    }),
)(Box);

export type FormGridProps = {
    columnAmount: number;
    rowHeight?: string;
    padding?: string;
    children: ReactNode;
    fullWidth?: boolean;
};

export const FormGrid: React.FC<FormGridProps> = ({
    columnAmount,
    rowHeight = '70px',
    children,
    padding = '10px 20px',
    fullWidth,
}: FormGridProps) => (
    <StyledFormGrid
        gridTemplateColumns={`repeat(${columnAmount}, minmax(0, 1fr))`}
        padding={padding}
        flex={fullWidth ? '1' : 'inherit'}
        gridAutoRows={`minmax(${rowHeight}, auto)`}>
        {children}
    </StyledFormGrid>
);
