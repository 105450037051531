import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    fetchScooterMaintenance,
    fetchScooterMaintenanceError,
    fetchScooterMaintenanceSuccess,
    setFilter,
    setFilters,
    setPage,
} from './index';
import { Pagination } from '../common/types';
import { mapFetchedPagination, mapPagination } from '../common/pagination';
import { mapFilters } from '../common/mappers';
import { selectFilters, selectPagination } from './selectors';
import { Filters, ScooterMaintenanceResponse } from './types';
import { getScooterMaintenance } from './service';
import { mapFetchedMaintenance } from './mapper';

function* fetchScooterMaintenanceSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const pagination: Pagination = yield select(selectPagination);
        const data: ScooterMaintenanceResponse = yield call(
            getScooterMaintenance,
            {
                ...mapPagination(pagination),
                ...mapFilters(filters),
            },
        );
        const { maintenanceIds, maintenanceById } = mapFetchedMaintenance(data);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchScooterMaintenanceSuccess({
                maintenanceIds,
                maintenanceById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(
            fetchScooterMaintenanceError({ error: (error as Error)?.message }),
        );
    }
}

export default function* scooterMaintenanceSagas() {
    yield takeLatest(fetchScooterMaintenance.type, fetchScooterMaintenanceSaga);
    yield takeLatest(setFilter.type, fetchScooterMaintenanceSaga);
    yield takeLatest(setFilters.type, fetchScooterMaintenanceSaga);
    yield takeLatest(setPage.type, fetchScooterMaintenanceSaga);
}
