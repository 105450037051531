import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { colors } from '../../themes/colors';
import { Modals, NotificationTypes, UIState } from './types';

const initialState: UIState = {
    theme: colors.default,
    openedModals: [],
    notification: { message: '', type: NotificationTypes.SUCCESS },
};

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setTheme(state, action: PayloadAction<string>) {
            state.theme = action.payload;
        },
        openModal(
            state,
            action: PayloadAction<{ modalType: Modals; modalProps?: any }>,
        ) {
            state.openedModals.unshift(action.payload);
        },
        closeModal(state) {
            state.openedModals.shift();
        },
        setNotification(
            state,
            action: PayloadAction<{ message: string; type: NotificationTypes }>,
        ) {
            state.notification = action.payload;
        },
    },
});

export const { setTheme, openModal, closeModal, setNotification } =
    uiSlice.actions;

export const uiReducer = uiSlice.reducer;
