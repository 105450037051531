import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessToPages, authStateType } from './types';
import { FetchedBoUser } from '../boUsers/types';

export const initialState: authStateType = {
    loading: false,
    accountLoading: true,
    isLoggedIn: !!localStorage.getItem('key'),
    error: '',
    errorCode: null,
    myUser: null,
    accessToPages: {},
    myUserHasUnsavedChanges: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.isLoggedIn = false;
            state.error = '';
            state.errorCode = null;
        },
        login: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ email, password }) => ({
                payload: { email, password },
            }),
        },
        loginSuccess(state) {
            state.loading = false;
            state.isLoggedIn = true;
        },
        loginFailure(state, action) {
            state.error = action.payload.error;
            state.loading = false;
            state.isLoggedIn = false;
        },
        restorePassword: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ email }) => ({
                payload: { email },
            }),
        },
        restorePasswordSuccess(state) {
            state.loading = false;
        },
        restorePasswordFailure(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchMyUser(state) {
            state.accountLoading = true;
            state.error = '';
        },
        fetchMyUserSuccess(
            state,
            action: PayloadAction<{
                myUser: FetchedBoUser;
                accessToPages: AccessToPages;
            }>,
        ) {
            state.myUser = action.payload.myUser;
            state.accessToPages = action.payload.accessToPages;
            state.accountLoading = false;
        },
        fetchMyUserError(state) {
            state.accountLoading = false;
            state.isLoggedIn = false;
        },
        updateMyUser: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.errorCode = null;
            },
            prepare: user => ({
                payload: user,
            }),
        },
        updateMyUserSuccess(
            state,
            action: PayloadAction<{
                myUser: FetchedBoUser;
            }>,
        ) {
            state.myUser = action.payload.myUser;
            state.loading = false;
            state.error = '';
            state.myUserHasUnsavedChanges = false;
        },
        updateMyUserError(state, action) {
            state.error = action.payload.error;
            state.errorCode = action.payload.errorCode;
            state.loading = false;
        },
        setMyUserHasUnsavedChanges(state, action) {
            state.myUserHasUnsavedChanges = action.payload;
        },
        resetError(state) {
            state.error = '';
        },
    },
});
export const {
    login,
    loginFailure,
    loginSuccess,
    logout,
    fetchMyUser,
    fetchMyUserError,
    fetchMyUserSuccess,
    updateMyUser,
    updateMyUserSuccess,
    updateMyUserError,
    setMyUserHasUnsavedChanges,
    restorePassword,
    restorePasswordFailure,
    restorePasswordSuccess,
    resetError,
} = authSlice.actions;

export const authReducer = authSlice.reducer;
