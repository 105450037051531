import { Pagination, Sort } from '../common/types';
import { Service } from '../services/types';
import { VehicleTypes } from '../vehicles/common/types';

export enum StationActivationStatuses {
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
}

export type Station = {
    id: number;
    name: string;
    lng: number;
    lat: number;
    serviceId: number;
    serviceName?: string;
    availablePlaces: number;
    totalPlaces: number;
    fillRate: number;
    isConnected: boolean;
    beams: FetchedBeams[];
    stationType?: string;
    ducktStationUid?: string;
    statusOfActivation?: StationActivationStatuses;
    rfidTechnology: string | null;
    rfidUrl: string | null;
    rfidToken: string | null;
};

export type FetchedStation = {
    station: Station;
    service: Service;
};

export type DucktParkedVehicleResponse = {
    adapter: string;
    lat: number;
    lng: number;
    port: string;
    stationName: string;
    status: StationActivationStatuses;
    time: string;
    vehicleSerial: string;
    vehicleType: VehicleTypes;
};

export type FetchedBeams = {
    beamId: string;
    vehicleId: number | null;
    vehicleSerial: string | number | null;
    position: number;
    vehicleType: VehicleTypes | null;
    readerName: string | null;
};

export type StationsById = {
    [code: number]: Station;
};

export enum FilterTypes {
    Search = 'search',
}

export enum StationTypes {
    Simple = 'SIMPLE',
    DUCKT = 'DUCKT',
    KNOTT = 'KNOTT',
}

export type Filters = {
    [FilterTypes.Search]: string;
};

export type StationState = {
    loading: boolean;
    error: string;
    stationIds: number[];
    stationsById: StationsById;
    pagination: Pagination;
    sort: Sort;
    filters: Filters;
    currentStation: Station | null;
    stationHasUnsavedChanges: boolean;
};

export type StationResponse = {
    content: FetchedStation[];
} & Pagination;

export type Accumulator = {
    stationIds: number[];
    stationsById: StationsById;
};
