import React, { useCallback } from 'react';
import { Form, FormSpy } from 'react-final-form';
import {
    ButtonComponent,
    CheckboxField,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from 'components/common';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { Prompt } from 'react-router';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import BackIcon from '@material-ui/icons/ArrowBack';
import { FormGrid } from '../../common/FormGrid';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { AppType, AppVersionType } from 'ducks/application/types';
import {
    selectApplicationVersionHasUnsavedChanges,
    manageApplicationVersion,
    setApplicationVersionHasUnsavedChanges,
    selectError,
} from 'ducks/application';
import { validations } from './validations';
import { resetError } from 'ducks/application';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';

export const ManageAppVersion: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const redirectToMainPage = useCallback(
        () => dispatch(push(routes.MAIN_PAGE)),
        [dispatch],
    );

    const handleConfirm = useCallback(
        (values: AppVersionType) => {
            dispatch(manageApplicationVersion(values));
        },
        [dispatch],
    );

    const initialValues = React.useMemo(
        () => ({
            required: false,
            type: AppType.IOS,
            version: '',
        }),
        [],
    );

    React.useEffect(() => {
        return () => {
            dispatch(setApplicationVersionHasUnsavedChanges(false));
            dispatch(resetError());
        };
    }, [dispatch]);

    const typeOptions = React.useMemo(
        () =>
            Object.values(AppType).map(type => ({
                label: t(`appTypes.${type}`),
                value: type,
            })),
        [t],
    );
    const applicationVersionHasUnsavedChanges = useSelector(
        selectApplicationVersionHasUnsavedChanges,
    );
    const error = useSelector(selectError);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setApplicationVersionHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    useNotificationOnPageLeave(applicationVersionHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 25%">
            <Prompt
                when={applicationVersionHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={(values: AppVersionType) => {
                    handleConfirm(values);
                }}
                initialValues={initialValues}
                render={({ handleSubmit, valid }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToMainPage}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>{t('appVersion.title')}</p>
                            </Typography>
                        </Box>
                        <FormGrid columnAmount={2}>
                            <TextField
                                name="version"
                                validators={validations.version}
                                label={t('appVersion.version')}
                                isRequired
                            />
                            <SelectField
                                name="type"
                                validators={validations.required}
                                label={t('appVersion.type')}
                                options={typeOptions}
                                isRequired
                            />
                            <CheckboxField
                                field="required"
                                label="appVersion.required"
                            />
                        </FormGrid>
                        <FormButtonGroup>
                            <ButtonComponent
                                handleClick={handleSubmit}
                                text={t('save')}
                                isDisabled={
                                    !applicationVersionHasUnsavedChanges ||
                                    !valid
                                }
                            />
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
