import React from 'react';
import CancelIcon from '@material-ui/icons/CancelTwoTone';
import { IconButton } from '@material-ui/core';
import { useStyles } from './styles';

interface Image {
    image: { url: string; type?: string };
    onDelete?: () => void;
    showDeleteButton?: boolean;
}

export const DeletableFileComponent: React.FC<Image> = ({
    image,
    onDelete,
    showDeleteButton = true,
}: Image) => {
    const classes = useStyles();

    return (
        <div className={classes.imageContainer}>
            {image.type === 'application/pdf' ? (
                <embed src={image.url} width="100%" />
            ) : (
                <img src={image.url} alt="" width="100%" />
            )}
            {showDeleteButton && onDelete && (
                <IconButton
                    onClick={() => onDelete()}
                    className={classes.deleteButton}>
                    <CancelIcon color="action" />
                </IconButton>
            )}
        </div>
    );
};
