import {
    composeValidators,
    fieldLength,
    isPhoneNumber,
    isValidEmail,
    required,
} from 'tools/validationRules';

export const validations = {
    datanalytics: (value: string) =>
        value && value.length > 0 && fieldLength(value, 2, 30),
    firstName: composeValidators(required, (value: string) =>
        fieldLength(value, 2, 30),
    ),
    lastName: composeValidators(required, (value: string) =>
        fieldLength(value, 2, 30),
    ),
    phone: composeValidators(required, (value: string) => isPhoneNumber(value)),
    profileEmail: composeValidators(required, isValidEmail, (value: string) =>
        fieldLength(value, 2, 63),
    ),
    rfidPassNumber: composeValidators((value: string) =>
        value ? fieldLength(value, 0, 20) : '',
    ),
};
