import { RootState } from '../root';
import { createSelector } from 'reselect';

export const selectLogsIds = (state: RootState) => state.logs.logsIds;
export const selectLogsById = (state: RootState) => state.logs.logsById;

export const selectFilters = (state: RootState) => state.logs.filters;
export const selectSort = (state: RootState) => state.logs.sort;
export const selectIsLoading = (state: RootState) => state.logs.loading;
export const selectPagination = (state: RootState) => state.logs.pagination;

export const selectLogsTableData = createSelector(
    selectLogsIds,
    selectLogsById,
    (logsIds, logsById) => logsIds.map(id => ({ ...logsById[id] })),
);
