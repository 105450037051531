import {
    composeValidators,
    fieldLength,
    isValidLockQrCode,
    required,
} from 'tools/validationRules';

export const validations = {
    qrCode: composeValidators(required, isValidLockQrCode),
    version: composeValidators(required, (value: string) =>
        fieldLength(value, 1, 120),
    ),
};
