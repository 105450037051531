import React, { Ref, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ButtonComponent } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/common/ModalContainer/styles';
import { useDispatch, useSelector } from 'react-redux';
import { InputComponent } from 'components/common/Input';
import {
    addEmailRecipient,
    selectCompanyError,
    resetError,
} from 'ducks/companies';
import { isValidEmail } from 'tools/validationRules';
import { AlarmReasons } from 'ducks/companies/types';
import { find } from 'lodash';
import { selectEmailRecipients } from 'ducks/companies/selectors';
import { ErrorComponent } from '../../../common/Error';

interface ModalProps {
    isOpen: boolean;
    alarmReasons: AlarmReasons;
    currentAlarmReason: string;
    handleClose: () => void;
}

// eslint-disable-next-line react/display-name
export const AddEmailModal = React.forwardRef(
    (
        { handleClose, alarmReasons, currentAlarmReason }: ModalProps,
        ref: Ref<HTMLDivElement>,
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [email, setEmail] = useState('');
        const error = useSelector(selectCompanyError);
        const currentEmailRecipients = useSelector(selectEmailRecipients);

        useEffect(() => {
            return () => {
                dispatch(resetError());
            };
        }, [dispatch]);

        const handleConfirm = useCallback(() => {
            const sameRecipientFromRecipientsArray = find(
                currentEmailRecipients,
                ['email', email],
            );

            if (!!sameRecipientFromRecipientsArray) {
                const recipientsWithoutTheSame = currentEmailRecipients.filter(
                    (item: any) => item.email !== email,
                );

                dispatch(
                    addEmailRecipient([
                        ...recipientsWithoutTheSame,
                        {
                            ...sameRecipientFromRecipientsArray,
                            [currentAlarmReason]: true,
                        },
                    ]),
                );
            } else {
                dispatch(
                    addEmailRecipient([
                        ...currentEmailRecipients,
                        { email, ...alarmReasons },
                    ]),
                );
            }
        }, [
            dispatch,
            email,
            alarmReasons,
            currentAlarmReason,
            currentEmailRecipients,
        ]);

        const handleChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value);
            },
            [setEmail],
        );

        return (
            <div ref={ref} className={classes.modal}>
                <Box textAlign="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h2">{t('company.addEmail')}</Typography>
                <Box width="300px" margin="0 auto">
                    <InputComponent
                        value={email}
                        label={t('fields.email')}
                        onChange={handleChange}
                        hasError={email ? !!isValidEmail(email) : false}
                        helperText={email ? isValidEmail(email) : ''}
                    />
                </Box>
                <Box textAlign="center" marginTop="30px">
                    <ButtonComponent
                        handleClick={handleClose}
                        text={t('cancel')}
                    />
                    &emsp;
                    <ButtonComponent
                        handleClick={handleConfirm}
                        isDisabled={!email || !!isValidEmail(email)}
                        text={t('save')}
                    />
                    <Box marginTop="10px">
                        <ErrorComponent error={error} />
                    </Box>
                </Box>
            </div>
        );
    },
);
