import { RootState } from '../root';

export const selectCurrentAgreements = (state: RootState) =>
    state.agreements.currentAgreements;

export const selectFeatureAgreements = (state: RootState) =>
    state.agreements.featureAgreements;

export const selectAgreementsHasUnsavedChanges = (state: RootState) =>
    state.agreements.agreementsHasUnsavedChanges;

export const selectIsLoading = (state: RootState) => state.agreements.loading;
