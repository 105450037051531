import React from 'react';
import { composeValidators, required, minValue } from 'tools/validationRules';
import { TextField } from '../../../common';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

type ScheduleProps = {
    prefix: string;
    id?: number;
};

export const BookingDurations: React.FC<ScheduleProps> = ({
    prefix,
    id,
}: ScheduleProps) => {
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = id
        ? accessToPages[Permissions.SERVICE_UPDATE]
        : accessToPages[Permissions.SERVICE_CREATE];
    const { t } = useTranslation();
    return (
        <Box display="grid" gridGap="10px 20px" alignItems="flex-cemter">
            <TextField
                disabled={!allowedToEdit}
                name={`${prefix}`}
                validators={composeValidators(required, (value: string) =>
                    minValue(value, 1),
                )}
                label={t('serviceList.bookingDuration')}
                isRequired
                type="number"
                InputProps={{
                    inputProps: {
                        min: 1,
                    },
                }}
            />
        </Box>
    );
};
