import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from '../../common';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectFilters,
    setStringFilter,
    StringFilterTypes,
} from 'ducks/services';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import AddIcon from '@material-ui/icons/Add';
import { selectAccessToPages } from '../../../ducks/auth';
import { Permissions } from '../../../ducks/groups/types';

export const ServiceSearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToCreate = accessToPages[Permissions.SERVICE_CREATE];
    const redirectToServiceCreation = () => dispatch(push(routes.NEW_SERVICE));
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length >= 2 || e.target.value.length === 0) {
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.ServiceName,
                    value: e.target.value,
                }),
            );
        }
    };

    return (
        <FormGrid columnAmount={2} rowHeight="20px" padding="10px 0">
            <SearchComponent
                placeholder={t('serviceList.serviceName')}
                onChange={handleSearch}
                defaultValue={filters[StringFilterTypes.ServiceName]}
            />
            {allowedToCreate && (
                <Box textAlign="right">
                    <ButtonComponent
                        text={t('serviceList.add')}
                        startIcon={<AddIcon />}
                        handleClick={redirectToServiceCreation}
                    />
                </Box>
            )}
        </FormGrid>
    );
};
