import { RootState } from '../root';
import { createSelector } from 'reselect';

export const selectSort = (state: RootState) => state.station.sort;

export const selectPagination = (state: RootState) => state.station.pagination;

export const selectStationIds = (state: RootState) => state.station.stationIds;

export const selectCurrentStation = (state: RootState) =>
    state.station.currentStation;

export const selectStationsById = (state: RootState) =>
    state.station.stationsById;

export const selectFilters = (state: RootState) => state.station.filters;

export const selectStationHasUnsavedChanges = (state: RootState) =>
    state.station.stationHasUnsavedChanges;

export const selectStationError = (state: RootState) => state.station.error;
export const selectIsLoading = (state: RootState) => state.station.loading;

export const selectStationsTableData = createSelector(
    selectStationIds,
    selectStationsById,
    (stationIds, stationsById) =>
        stationIds.map(id => ({
            ...stationsById[id],
        })),
);
