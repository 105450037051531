import { Permissions } from '../groups/types';
import { AccessToPages } from './types';

export const mapAccessGroups = (permissions: Permissions[]): AccessToPages => {
    return permissions.reduce((acc, permission) => {
        return {
            ...acc,
            [permission]: true,
        };
    }, {});
};
