import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';
import { NewsState, StringFilterTypes } from './types';
const defaultFilters = {
    [StringFilterTypes.Company]: '',
};
export const initialState: NewsState = {
    loading: false,
    error: '',
    newsHasUnsavedChanges: false,
    newsIds: [],
    newsById: {},
    currentNews: null,
    filters: defaultFilters,
    sort: {
        value: 'start',
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        fetchNews(state) {
            state.loading = true;
            state.error = '';
        },
        fetchNewsSuccess(state, action) {
            state.newsIds = action.payload.allIds;
            state.newsById = action.payload.byId;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchNewsError(state) {
            state.loading = false;
        },
        fetchOneNews: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: newsId => ({
                payload: newsId,
            }),
        },
        fetchOneNewsSuccess(state, action) {
            state.loading = false;
            state.currentNews = action.payload;
        },
        fetchOneNewsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        createNews: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: news => ({
                payload: news,
            }),
        },
        createNewsSuccess(state) {
            state.newsHasUnsavedChanges = false;
            state.loading = false;
        },
        createNewsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setNewsHasUnsavedChanges(state, action) {
            state.newsHasUnsavedChanges = action.payload;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setStringFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: StringFilterTypes;
                value: string;
            }>,
        ) {
            state.filters[payload.filterType] = payload.value;
            state.pagination = defaultPagination;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        deleteNews: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: newsId => ({
                payload: newsId,
            }),
        },
        deleteNewsSuccess(state) {
            state.loading = false;
            state.newsHasUnsavedChanges = false;
            state.error = '';
        },
        deleteNewsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateNews: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: news => ({
                payload: news,
            }),
        },
        updateNewsSuccess(state) {
            state.newsHasUnsavedChanges = false;
            state.loading = false;
        },
        updateNewsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        resetError(state) {
            state.error = '';
        },
        resetCurrentNews(state) {
            state.currentNews = null;
        },
    },
});
export const {
    fetchNews,
    fetchNewsError,
    fetchNewsSuccess,
    fetchOneNews,
    fetchOneNewsSuccess,
    fetchOneNewsError,
    setNewsHasUnsavedChanges,
    createNews,
    createNewsError,
    createNewsSuccess,
    setPage,
    setSort,
    setStringFilter,
    deleteNews,
    deleteNewsError,
    deleteNewsSuccess,
    updateNews,
    updateNewsError,
    updateNewsSuccess,
    resetFilters,
    resetError,
    resetCurrentNews,
} = newsSlice.actions;

export const newsReducer = newsSlice.reducer;
