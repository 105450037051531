import { Languages } from '../boUsers/types';

export enum AgreementTypes {
    APPLICATION_POLICY = 'APPLICATION_POLICY',
    APPLICATION_CGAU = 'APPLICATION_CGAU',
}

export type AgreementLink = {
    language: Languages;
    url: string;
};

export type InitialAgreement = {
    links: AgreementLink[];
    version: string;
};

export type Agreement = {
    id: number;
    type: AgreementTypes;
    links: AgreementLink[];
    version: string;
};

export type Agreements = {
    policy: Agreement | null;
    cgau: Agreement | null;
};

export type AgreementsState = {
    currentAgreements: Agreements;
    featureAgreements: Agreements;
    agreementsHasUnsavedChanges: boolean;
    loading: boolean;
    error: string;
};
