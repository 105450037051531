export const colors = {
    default: '#323282',
    violet: '#572C7B',
    pink: '#B4218D',
    blue: '#5DADEC',
    yellow: '#FFAD1F',
    red: '#E02451',
    green: '#17BF63',
    white: '#FFFFFF',
    black: '#000000',
};
