import { useField } from 'react-final-form';

import React from 'react';
import { DocumentsDropzone } from '../DocumentsDropzone';
import { useTranslation } from 'react-i18next';
import { FileProperties } from 'ducks/companies/types';
import { BYTES_IN_MB } from '../../../constants/common';
import { ErrorComponent } from '../Error';
import { Button } from '@material-ui/core';
import { Box } from '@material-ui/core';

interface FieldProps {
    name: string;
    maxAmount?: number;
    maxSize?: number;
    existingValues?: FileProperties[];
    isDropzone?: boolean;
}

export const UploadFileField: React.FC<FieldProps> = ({
    name,
    existingValues,
    maxAmount = 5,
    maxSize = BYTES_IN_MB,
    isDropzone = true,
}: FieldProps) => {
    const { input } = useField(name);
    const [error, setError] = React.useState('');
    const { t } = useTranslation();

    const onDrop = React.useCallback(
        acceptedFiles => {
            setError('');
            const filesArray = acceptedFiles.reduce(
                (acc: FileProperties[], file: File) => {
                    if (file.size <= maxSize) {
                        return [
                            ...acc,
                            {
                                file: file,
                                url: URL.createObjectURL(file),
                                type: file.type,
                            },
                        ];
                    } else {
                        setError(
                            t('errors.documentsSize', {
                                max: maxSize / BYTES_IN_MB,
                            }),
                        );
                        return acc;
                    }
                },
                [],
            );
            existingValues
                ? input.onChange(
                      [...existingValues, ...filesArray].slice(0, maxAmount),
                  )
                : input.onChange(filesArray[0]);
        },
        [existingValues, maxSize, maxAmount, t, input],
    );

    return (
        <Box
            justifyContent="center"
            display="flex"
            flexDirection="column"
            width="100%">
            {isDropzone ? (
                <DocumentsDropzone
                    onDrop={files => {
                        onDrop(files);
                    }}
                    text={t('maximumDocuments', {
                        maxSize: maxSize / BYTES_IN_MB,
                        maxAmount,
                    })}
                />
            ) : (
                <Button variant="contained" component="label" color="primary">
                    {t('importFile')}
                    <input
                        style={{ display: 'none' }}
                        accept="image/jpeg,image/jpg,image/png"
                        type="file"
                        onChange={event => {
                            if (
                                event.target.files &&
                                event.target.files.length
                            ) {
                                onDrop(Array.from(event.target.files));
                            }
                        }}
                    />
                </Button>
            )}
            <ErrorComponent error={error} />
        </Box>
    );
};
