import { Service, Content, ServiceResponse, Accumulator } from './types';
import { Sort } from 'ducks/common/types';

const mapService = ({ company, service }: Content): Service => ({
    companyName: company?.name,
    ...service,
});

export const mapFetchedServices = ({
    content,
}: ServiceResponse): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, { company, service }) => {
            return {
                servicesById: {
                    ...acc.servicesById,
                    [service.id]: mapService({ company, service }),
                },
                serviceIds: [...acc.serviceIds, service.id],
            };
        },
        { serviceIds: [], servicesById: {} },
    );
};

export const mapSorting = (sort: Sort) => {
    return { sort: sort.value + ',' + sort.direction };
};
