import React, { Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { setProfileHasUnsavedChanges } from 'ducks/users';
import { Box, IconButton, Typography } from '@material-ui/core';
import {
    CurrentUserProfile,
    DateTypes,
    Subscription,
    SubscriptionStatuses,
} from 'ducks/users/types';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Modals, openModal } from 'ducks/ui';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import moment from 'moment';
import { HelpComponent } from '../../common/HelpComponent';

type ProfileServicesListProps = {
    profile: CurrentUserProfile;
    setServiceForDeletion: any;
    servicesForDeletion: number[];
};

type EditProps = {
    subscriptionId: number;
    serviceTimeZone: string;
    dateType: string;
    date: Date | null;
    unchangedDate: {
        startDate?: string;
        endDate?: string;
    };
};

export const ProfileServicesList = ({
    profile,
    setServiceForDeletion,
    servicesForDeletion,
}: ProfileServicesListProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToUpdateStatus =
        accessToPages[Permissions.PROFILE_UPDATE_STATUS];

    const handleDeleteService = useCallback(
        (serviceId: number) => () => {
            setServiceForDeletion((prevState: number[]) => [
                ...prevState,
                serviceId,
            ]);
            dispatch(setProfileHasUnsavedChanges(true));
        },
        [setServiceForDeletion, dispatch],
    );

    const handleEdit = useCallback(
        (props: EditProps) => {
            allowedToUpdateStatus &&
                dispatch(
                    openModal({
                        modalType: Modals.EDIT_DATE_MODAL,
                        modalProps: {
                            ...props,
                            profileId: profile.id,
                        },
                    }),
                );
        },
        [dispatch, allowedToUpdateStatus, profile.id],
    );

    const handleChangeDate = useCallback(
        (subscription: Subscription, dateType: DateTypes) => () => {
            const anotherDateType =
                dateType === DateTypes.StartDate
                    ? DateTypes.EndDate
                    : DateTypes.StartDate;

            handleEdit({
                subscriptionId: subscription.id,
                serviceTimeZone: subscription.serviceTimezone,
                dateType: dateType,
                date:
                    (subscription[dateType] &&
                        moment(subscription[dateType]).toDate()) ||
                    null,
                unchangedDate: subscription[anotherDateType]
                    ? {
                          [anotherDateType]: subscription[anotherDateType],
                      }
                    : {},
            });
        },
        [handleEdit],
    );

    return (
        <Box
            display="grid"
            gridGap="10px 10px"
            alignItems="flex-start"
            gridTemplateRows="30px"
            gridAutoRows="65px"
            gridTemplateColumns={
                allowedToUpdateStatus
                    ? 'minmax(110px, 1fr) repeat(3, minmax(0, 1fr)) 0.25fr'
                    : 'minmax(110px, 1fr) repeat(3, minmax(0, 1fr))'
            }>
            <Typography variant="h3">
                {t('usersList.profile.serviceName')}
            </Typography>
            <Typography variant="h3">
                {t('usersList.profile.serviceType')}
            </Typography>
            <Typography variant="h3">
                {t('usersList.profile.serviceStartDate')}
            </Typography>
            <Typography variant="h3">
                {t('usersList.profile.serviceEndDate')}
            </Typography>
            {allowedToUpdateStatus && <Typography />}
            {profile.subscriptions.map((subscription: Subscription) => {
                const isShowSubscription =
                    !servicesForDeletion.includes(subscription.id) &&
                    subscription.status === SubscriptionStatuses.granted;

                if (isShowSubscription) {
                    return (
                        <Fragment
                            key={`${subscription.id} - ${subscription.serviceId}`}>
                            <Box className={classes.serviceBox}>
                                <Typography>
                                    {subscription.serviceName || '-'}
                                </Typography>
                            </Box>
                            <Box className={classes.serviceBox}>
                                <Typography>
                                    {subscription.serviceType
                                        ? t(
                                              `serviceList.${subscription.serviceType}`,
                                          )
                                        : '-'}
                                </Typography>
                            </Box>
                            <Box
                                className={
                                    allowedToUpdateStatus ? classes.date : ''
                                }
                                onClick={handleChangeDate(
                                    subscription,
                                    DateTypes.StartDate,
                                )}>
                                <Typography>
                                    {subscription.startDate
                                        ? new Date(
                                              subscription.startDate,
                                          ).toLocaleString()
                                        : '-'}
                                </Typography>
                                <HelpComponent
                                    tooltipText={t('timeZoneTooltipText', {
                                        timeZone: subscription.serviceTimezone,
                                    })}
                                />
                            </Box>
                            <Box
                                className={
                                    allowedToUpdateStatus ? classes.date : ''
                                }
                                onClick={handleChangeDate(
                                    subscription,
                                    DateTypes.EndDate,
                                )}>
                                <Typography>
                                    {subscription.endDate
                                        ? new Date(
                                              subscription.endDate,
                                          ).toLocaleString()
                                        : '-'}
                                </Typography>
                            </Box>
                            {allowedToUpdateStatus && (
                                <Box>
                                    <IconButton
                                        className={classes.deleteIcon}
                                        onClick={handleDeleteService(
                                            subscription.id,
                                        )}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </Fragment>
                    );
                }
                return null;
            })}
        </Box>
    );
};
