import React, { Ref, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ButtonComponent } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/common/ModalContainer/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchAvailableVehicles,
    selectFreeVehiclesById,
} from 'ducks/companies/index';
import { SelectComponent } from '../../../common';
import {
    resendInvitation,
    resetError,
    selectCompanyError,
} from 'ducks/companies/index';
import { ErrorComponent } from '../../../common/Error';

interface ModalProps {
    isOpen: boolean;
    serviceId: number;
    invitationId: number;
    handleClose: () => void;
}

// eslint-disable-next-line react/display-name
export const ChangeVehicleModal = React.forwardRef(
    (
        { handleClose, serviceId, invitationId }: ModalProps,
        ref: Ref<HTMLDivElement>,
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [vehicle, setVehicle] = useState('');
        const error = useSelector(selectCompanyError);
        const freeVehicles = useSelector(selectFreeVehiclesById);

        useEffect(() => {
            dispatch(fetchAvailableVehicles(serviceId));
        }, [dispatch, serviceId]);

        useEffect(() => {
            return () => {
                resetError();
            };
        }, []);

        const vehicleOptions = React.useMemo(
            () =>
                Object.values(freeVehicles) && [
                    ...Object.values(freeVehicles).map(vehicle => ({
                        label: vehicle.serial,
                        value: `${vehicle.id}`,
                    })),
                ],
            [freeVehicles],
        );

        const handleChangeVehicle = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setVehicle(e.target.value);
            },
            [setVehicle],
        );

        const handleConfirm = useCallback(() => {
            dispatch(
                resendInvitation({
                    vehicleId: +vehicle,
                    invitationId,
                    serviceId,
                }),
            );
        }, [vehicle, dispatch, invitationId, serviceId]);

        return (
            <div ref={ref} className={classes.modal}>
                <Box textAlign="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box marginBottom="20px">
                    <Typography variant="h2">
                        {t('invitations.vehicleTaken')}
                    </Typography>
                </Box>
                {vehicleOptions && (
                    <SelectComponent
                        value={vehicle}
                        onChange={e => handleChangeVehicle(e)}
                        label={t('invitations.lldVehicle')}
                        options={vehicleOptions}
                    />
                )}
                <Box textAlign="center" marginTop="30px">
                    <ButtonComponent
                        handleClick={handleClose}
                        text={t('cancel')}
                    />
                    &emsp;
                    <ButtonComponent
                        handleClick={handleConfirm}
                        isDisabled={!vehicle}
                        text={t('save')}
                    />
                    <ErrorComponent error={error} />
                </Box>
            </div>
        );
    },
);
