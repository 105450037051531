import { RootState } from '../root';
import { createSelector } from 'reselect';

export const selectPagination = (state: RootState) => state.news.pagination;
export const selectFilters = (state: RootState) => state.news.filters;
export const selectSort = (state: RootState) => state.news.sort;

export const selectNewsIds = (state: RootState) => state.news.newsIds;

export const selectNewsById = (state: RootState) => state.news.newsById;

export const selectNewsHasUnsavedChanges = (state: RootState) =>
    state.news.newsHasUnsavedChanges;

export const selectCurrentNews = (state: RootState) => state.news.currentNews;

export const selectNewsError = (state: RootState) => state.news.error;

export const selectNewsTableData = createSelector(
    selectNewsIds,
    selectNewsById,
    (newsIds, newsById) => newsIds.map(id => ({ ...newsById[id] })),
);

export const selectIsLoading = (state: RootState) => state.news.loading;
