import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchVehicles,
    resetFilters,
    selectFilters,
    setCurrentVehicleType,
    setStringFilter,
    StringFilterTypes,
} from 'ducks/vehicles/common';
import { FormGrid, SelectComponent } from '../../common';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { UpdateVehicleStatusComponent } from '../../common/UpdateVehicleStatusComponent';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';
import { routes } from '../../../constants/common';
import { useHistory } from 'react-router';
import { VehicleTypes } from '../../../ducks/vehicles/common/types';

export const Toolbar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    const filters = useSelector(selectFilters);
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const history = useHistory();

    const { pathname } = history.location as { pathname: string };

    const isBikeList = useMemo(
        () => pathname === routes.BIKES_AND_VAE,
        [pathname],
    );

    const companyOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );

    const typesOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            {
                label: 'VAE',
                value: 'VAE',
            },
            {
                label: 'BIKE',
                value: 'BIKE',
            },
        ],
        [],
    );

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        dispatch(
            setCurrentVehicleType({
                types: isBikeList
                    ? [VehicleTypes.BIKE, VehicleTypes.VAE]
                    : [VehicleTypes.SCOOTER],
                isRefreshCheck: true,
            }),
        );
        dispatch(fetchVehicles());
    }, [dispatch, isBikeList]);

    const selectCompany = React.useCallback(
        e =>
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.Company,
                    value: e.target.value,
                }),
            ),
        [dispatch],
    );

    const selectType = React.useCallback(
        e =>
            dispatch(
                setCurrentVehicleType({
                    types: e.target.value,
                    isRefreshCheck: false,
                }),
            ),
        [dispatch],
    );

    return (
        <FormGrid columnAmount={5} rowHeight="20px" padding="10px 0">
            <SelectComponent
                label={t('serviceList.companyName')}
                onChange={selectCompany}
                value={filters[StringFilterTypes.Company] || ''}
                options={companyOptions}
            />
            {isBikeList && (
                <SelectComponent
                    label={t('vehicles.type')}
                    onChange={selectType}
                    value={filters[StringFilterTypes.Types] || ''}
                    options={typesOptions}
                />
            )}
            <UpdateVehicleStatusComponent />
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};
