import request from 'helpers/request';
import { Credentials } from './types';
import { AUTH_URL, BO_USERS_URL, UPDATE_ACCOUNT_URL } from 'constants/api';

export const login = (credentials: Credentials): Promise<string> =>
    request.post(`${AUTH_URL}/token`, credentials);

export const devalidateToken = (): Promise<string> =>
    request.post(`${BO_USERS_URL}/logout`);

export const resetPasswordService = (email: string): Promise<string> =>
    request.patch(`${AUTH_URL}/resetPassword`, null, { params: { email } });

export const getMyUser = (): Promise<string> =>
    request.get(`${BO_USERS_URL}/account`);

export const updateMyUserService = (params: any): Promise<string> =>
    request.patch(`${UPDATE_ACCOUNT_URL}`, { ...params });
