import React from 'react';
import { TextField, InputAdornment, createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const StyledInput = withStyles(() =>
    createStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: `25px`,
                },
            },
            width: '100%',
            maxWidth: '400px',
        },
    }),
)(TextField);

interface IInputInterface {
    placeholder: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    helperText?: string;
    id?: string;
    defaultValue?: string;
}

export const SearchComponent: React.FC<IInputInterface> = ({
    value,
    onChange,
    helperText,
    placeholder,
    id,
    defaultValue,
}: IInputInterface) => {
    return (
        <>
            <StyledInput
                type="search"
                value={value}
                defaultValue={defaultValue}
                id={id || 'bootstrap-input'}
                variant="outlined"
                size="small"
                onChange={onChange}
                helperText={helperText}
                placeholder={placeholder}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
};
