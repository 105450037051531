import React from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    createStyles,
    Theme,
    Typography,
    withStyles,
} from '@material-ui/core';
import { colors } from '../../../themes/colors';
import PictureIcon from '@material-ui/icons/CropOriginal';
import { useTranslation } from 'react-i18next';

interface Import {
    onDrop: (files: File[]) => void;
    text: string;
}

export const ThemedBoxComponent = withStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `2px dashed ${theme.palette.secondary.main}`,
            borderRadius: '25px',
            padding: '20px',
            backgroundColor: colors.white,
            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            minWidth: 'fit-content',
            '&:focus': {
                outline: 'none',
            },
        },
    }),
)(Box);

export const DocumentsDropzone: React.FC<Import> = ({
    onDrop,
    text,
}: Import) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    const { t } = useTranslation();

    return (
        <ThemedBoxComponent {...getRootProps()}>
            <input
                {...getInputProps()}
                accept="image/jpeg,image/jpg,image/png,application/pdf"
            />
            <Box display="flex" justifyContent="center" alignItems="center">
                <PictureIcon color="primary" />
                <Typography variant="h3">
                    <p>{t('addImage')}</p>
                </Typography>
            </Box>

            {isDragActive ? (
                <p>{t('dropZoneHover')}</p>
            ) : (
                <>
                    <Typography variant="body1">
                        <span>{t('userDocuments')}</span>
                    </Typography>
                    <Typography variant="body1">
                        <span>{text}</span>
                    </Typography>
                </>
            )}
        </ThemedBoxComponent>
    );
};
