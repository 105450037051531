import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { ButtonComponent, LinkComponent } from '../../common';
import CheckIcon from '@material-ui/icons/Check';
import { Typography, Box } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { FetchedBeams } from 'ducks/stations/types';
import { useDispatch, useSelector } from 'react-redux';
import { addBeam, deleteBeam } from 'ducks/stations';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { ErrorComponent } from '../../common/Error';
import { renderColumns } from '../../common/Table/column';
import { generatePath } from 'react-router';
import { vehicleRoutes } from '../../../constants/common';

interface BeamsTableProps {
    beams: FetchedBeams[];
    stationId: number;
}

export const BeamsTable: React.FC<BeamsTableProps> = ({
    beams,
    stationId,
}: BeamsTableProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [error, setError] = useState('');
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToUpdate = accessToPages[Permissions.STATION_UPDATE];
    const columns = useMemo(() => {
        return [
            {
                title: t('stations.lockPosition'),
                field: 'position',
                sorting: false,
                render: (rowProps: FetchedBeams) => rowProps.position + 1 || '',
                editable: (): boolean => false,
            },
            {
                title: t('stations.beamId'),
                field: 'beamId',
                sorting: false,
            },
            {
                title: t('stations.readerName'),
                field: 'readerName',
                sorting: false,
            },
            {
                title: t('stations.vehicleSerial'),
                field: 'vehicleSerial',
                editable: (): boolean => false,
                // eslint-disable-next-line react/display-name
                render: ({
                    vehicleId,
                    vehicleType,
                    vehicleSerial,
                }: FetchedBeams) => (
                    <LinkComponent
                        text={vehicleSerial ? vehicleSerial.toString() : ''}
                        handleClick={event => {
                            event.stopPropagation();
                            if (vehicleType !== null) {
                                window.open(
                                    generatePath(vehicleRoutes[vehicleType], {
                                        vehicleId: vehicleId || '-',
                                    }),
                                    '_self',
                                );
                            }
                        }}
                    />
                ),
                sorting: false,
            },
        ];
    }, [t]);
    const beamsPreparedForTable = useMemo(() => {
        return beams
            ? beams.reduce((acc: any, item: FetchedBeams, index) => {
                  acc.push({ ...item, tableData: { id: index } });
                  return acc;
              }, [])
            : [];
    }, [beams]);
    const [data, setData] = useState(beamsPreparedForTable);
    React.useEffect(() => {
        setData(beamsPreparedForTable);
    }, [beamsPreparedForTable]);

    const handleRowAdd = useCallback(
        (newData: any) => {
            return new Promise<void>((resolve, reject) => {
                if (find(data, ['beamId', newData.beamId])) {
                    reject();
                    setError(t('stations.beamError'));
                    return;
                }
                dispatch(
                    addBeam({
                        stationId,
                        beamId: newData.beamId,
                        readerName: newData.readerName,
                    }),
                );
                setError('');
                setData([...data, newData]);
                resolve();
            });
        },
        [dispatch, data, setError, t, stationId],
    );

    const handleRowDelete = useCallback(
        (oldData: any) =>
            new Promise<void>(resolve => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);
                dispatch(deleteBeam({ stationId, beamId: oldData.beamId }));
                resolve();
            }),
        [dispatch, data, stationId],
    );

    const editable = useMemo(() => {
        if (allowedToUpdate) {
            return {
                isEditable: (rowData: any) => rowData.field === 'beamId',
                isDeletable: (rowData: any) => !rowData.vehicleId,
                onRowAdd: handleRowAdd,
                onRowAddCancelled: () => setError(''),
                onRowDelete: handleRowDelete,
            };
        }
    }, [allowedToUpdate, handleRowAdd, handleRowDelete, setError]);
    return (
        <Box marginBottom="20px">
            <MaterialTable
                components={{
                    Header: () => renderColumns(columns),
                }}
                columns={columns}
                data={data}
                title={
                    <Typography variant="h3" align="left">
                        {t('stations.attachedBeams')}
                    </Typography>
                }
                editable={editable}
                icons={{
                    // eslint-disable-next-line react/display-name
                    Add: forwardRef((props, ref) => (
                        <ButtonComponent
                            text={t('stations.addBeamId')}
                            outlined
                            ref={ref}
                            {...props}
                        />
                    )),
                    // eslint-disable-next-line react/display-name
                    Check: forwardRef((props, ref) => (
                        <CheckIcon {...props} ref={ref} />
                    )),
                }}
                options={{
                    actionsColumnIndex: -1,
                    rowStyle: {
                        backgroundColor: '#EEE',
                        borderTop: 'white solid',
                        borderBottom: 'white solid',
                    },
                    search: false,
                    paging: false,
                }}
            />
            <Box marginTop="10px">
                <ErrorComponent error={error} />
            </Box>
        </Box>
    );
};
