import { Pagination, Sort } from '../common/types';

export type GroupsById = {
    [code: number]: Group;
};

export type GroupState = {
    loading: boolean;
    error: string;
    groupIds: number[];
    groupsById: GroupsById;
    allGroupIds: number[];
    allGroupsById: GroupsById;
    pagination: Pagination;
    sort: Sort;
    permissions: PermissionsByCategory;
    groupHasUnsavedChanges: boolean;
    currentGroup: Group | null;
};

export type Group = {
    id: number;
    name: string;
    description: string;
    permissions: Permissions[];
};

export enum PermissionActions {
    VIEW = 'VIEW',
    UPDATE = 'UPDATE',
    EXPORT = 'EXPORT',
    STATUS = 'STATUS',
    CREATE = 'CREATE',
    DELETE = 'DELETE',
    ACCESS = 'ACCESS',
    LINK = 'LINK',
}

export enum PermissionGroups {
    BO = 'BO',
    GROUP = 'GROUP',
    COMPANY = 'COMPANY',
    SERVICE = 'SERVICE',
    END = 'END',
    PROFILE = 'PROFILE',
    INVITATION = 'INVITATION',
    VEHICLE = 'VEHICLE',
    TRIP = 'TRIP',
    NEWS = 'NEWS',
    PARTNER = 'PARTNER',
    ZONE = 'ZONE',
    STATION = 'STATION',
    OT = 'OT',
    SWAGGER = 'SWAGGER',
    AGREEMENT = 'AGREEMENT',
    MANAGE = 'MANAGE',
    SILENT = 'SILENT',
    RFID = 'RFID',
}

export enum Permissions {
    AGREEMENT_VIEW = 'AGREEMENT_VIEW',
    AGREEMENT_UPDATE = 'AGREEMENT_UPDATE',
    MANAGE_APP_VERSION = 'MANAGE_APP_VERSION',
    BO_USER_VIEW = 'BO_USER_VIEW',
    BO_USER_EXPORT = 'BO_USER_EXPORT',
    BO_USER_CREATE = 'BO_USER_CREATE',
    BO_USER_UPDATE = 'BO_USER_UPDATE',
    BO_USER_UPDATE_STATUS = 'BO_USER_UPDATE_STATUS',
    BO_USER_DELETE = 'BO_USER_DELETE',
    GROUP_VIEW = 'GROUP_VIEW',
    GROUP_EXPORT = 'GROUP_EXPORT',
    GROUP_CREATE = 'GROUP_CREATE',
    GROUP_UPDATE = 'GROUP_UPDATE',
    GROUP_UPDATE_STATUS = 'GROUP_UPDATE_STATUS',
    GROUP_DELETE = 'GROUP_DELETE',
    COMPANY_VIEW = 'COMPANY_VIEW',
    COMPANY_EXPORT = 'COMPANY_EXPORT',
    COMPANY_CREATE = 'COMPANY_CREATE',
    COMPANY_UPDATE = 'COMPANY_UPDATE',
    COMPANY_UPDATE_STATUS = 'COMPANY_UPDATE_STATUS',
    COMPANY_DELETE = 'COMPANY_DELETE',
    SERVICE_VIEW = 'SERVICE_VIEW',
    SERVICE_CREATE = 'SERVICE_CREATE',
    SERVICE_UPDATE = 'SERVICE_UPDATE',
    SERVICE_UPDATE_STATUS = 'SERVICE_UPDATE_STATUS',
    SERVICE_LINK = 'SERVICE_LINK',
    END_USER_VIEW = 'END_USER_VIEW',
    END_USER_EXPORT = 'END_USER_EXPORT',
    END_USER_UPDATE = 'END_USER_UPDATE',
    END_USER_UPDATE_STATUS = 'END_USER_UPDATE_STATUS',
    END_USER_DELETE = 'END_USER_DELETE',
    PROFILE_UPDATE = 'PROFILE_UPDATE',
    PROFILE_UPDATE_STATUS = 'PROFILE_UPDATE_STATUS',
    PROFILE_DELETE = 'PROFILE_DELETE',
    INVITATION_VIEW = 'INVITATION_VIEW',
    INVITATION_CREATE = 'INVITATION_CREATE',
    INVITATION_DELETE = 'INVITATION_DELETE',
    VEHICLE_VIEW = 'VEHICLE_VIEW',
    VEHICLE_EXPORT = 'VEHICLE_EXPORT',
    VEHICLE_CREATE = 'VEHICLE_CREATE',
    VEHICLE_UPDATE = 'VEHICLE_UPDATE',
    VEHICLE_UPDATE_STATUS = 'VEHICLE_UPDATE_STATUS',
    VEHICLE_DELETE = 'VEHICLE_DELETE',
    TRIP_VIEW = 'TRIP_VIEW',
    TRIP_EXPORT = 'TRIP_EXPORT',
    TRIP_UPDATE_STATUS = 'TRIP_UPDATE_STATUS',
    TRIP_DELETE = 'TRIP_DELETE',
    NEWS_VIEW = 'NEWS_VIEW',
    NEWS_CREATE = 'NEWS_CREATE',
    NEWS_UPDATE = 'NEWS_UPDATE',
    NEWS_DELETE = 'NEWS_DELETE',
    PARTNER_VIEW = 'PARTNER_VIEW',
    PARTNER_EXPORT = 'PARTNER_EXPORT',
    PARTNER_CREATE = 'PARTNER_CREATE',
    PARTNER_UPDATE = 'PARTNER_UPDATE',
    PARTNER_UPDATE_STATUS = 'PARTNER_UPDATE_STATUS',
    PARTNER_DELETE = 'PARTNER_DELETE',
    ZONE_VIEW = 'ZONE_VIEW',
    ZONE_CREATE = 'ZONE_CREATE',
    ZONE_UPDATE = 'ZONE_UPDATE',
    ZONE_DELETE = 'ZONE_DELETE',
    OT_APP_ACCESS = 'OT_APP_ACCESS',
    STATION_VIEW = 'STATION_VIEW',
    STATION_CREATE = 'STATION_CREATE',
    STATION_UPDATE = 'STATION_UPDATE',
    STATION_DELETE = 'STATION_DELETE',
    SEND_PUSH = 'END_USER_SEND_PUSH',
    SWAGGER_VIEW = 'SWAGGER_VIEW',
    SWAGGER_ADMIN = 'SWAGGER_ADMIN',
    SWAGGER_PARTNER = 'SWAGGER_PARTNER',
    SILENT_MODULE_VIEW = 'SILENT_MODULE_VIEW',
    SILENT_MODULE_UPDATE = 'SILENT_MODULE_UPDATE',
    SILENT_MODULE_COMMAND = 'SILENT_MODULE_COMMAND',
    RFID_API_CALL = 'RFID_API_CALL',
}

export type PermissionsByCategory = {
    [key in PermissionGroups]: {
        value: PermissionActions;
        permission: Permissions;
    }[];
};

export interface FetchedGroups extends Pagination {
    content: Group[];
}

export interface FetchedGroup {
    group: Group;
}
