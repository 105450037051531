import { TableComponent } from '../../common';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { getColumns } from './columns';
import { Pagination } from './Pagination';
import { Toolbar } from './Toolbar';
import { SearchToolbar } from './SearchToolbar';
import {
    selectNewsTableData,
    fetchNews,
    selectSort,
    selectIsLoading,
} from 'ducks/news';
import { News } from 'ducks/news/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const NewsList: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);
    const columns = getColumns(t, dispatch);
    const tableData = useSelector(selectNewsTableData);
    useEffect(() => {
        dispatch(fetchNews());
    }, [dispatch]);

    const redirectToNews = (id: number) =>
        dispatch(push(routes.ONE_NEWS.replace(':newsId', `${id}`)));

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'visibility',
                        tooltip: t('tripList.seeDetails'),
                        onClick: (event, rowData: News) =>
                            rowData && redirectToNews(rowData.id),
                    },
                ]}
                onRowClick={(event, rowData: News | undefined) =>
                    rowData && redirectToNews(rowData.id)
                }
                toolbar={Toolbar}
                searchComponent={SearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={tableData}
                title={t('news.newsList')}
                sort={sort}
            />
        </>
    );
};
