import { RootState } from 'ducks/root';
import { createSelector } from '@reduxjs/toolkit';

const basicSelector = (state: RootState) => state.booking;
export const selectFilters = (state: RootState) => basicSelector(state).filters;
export const selectSort = (state: RootState) => basicSelector(state).sort;
export const selectIsLoading = (state: RootState) =>
    basicSelector(state).loading;
export const selectPagination = (state: RootState) =>
    basicSelector(state).pagination;

const selectBookingIds = (state: RootState) => basicSelector(state).bookingIds;

const selectBookingById = (state: RootState) =>
    basicSelector(state).bookingById;

export const selectBookingTableData = createSelector(
    selectBookingIds,
    selectBookingById,
    (bookingIds, bookingById) =>
        bookingIds.map(id => ({
            ...bookingById[id],
        })),
);
