import { combineReducers } from '@reduxjs/toolkit';
import { uiReducer } from '../ui';
import { UIState } from '../ui/types';
import { authReducer, authState } from '../auth';
import { serviceReducer, ServiceState } from '../services';
import { companyReducer, CompanyState } from '../companies';
import { zoneReducer, ZoneState } from '../zones';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { groupReducer, GroupState } from '../groups';
import { boUsersReducer, BoUserState } from '../boUsers';
import { pushReducer, PushState } from '../pushes';
import { tripReducer, TripState } from 'ducks/trips';
import { userReducer, UserState } from '../users';
import { paymentReducer, PaymentState } from '../paymentHistory';
import { bookingReducer, BookingState } from '../booking';
import { stationReducer, StationState } from '../stations';
import { EventsState, eventReducer } from '../events';
import { LogsState, logsReducer } from '../logs';
import { VehiclesState, vehicleReducer } from '../vehicles/common';
import {
    scooterMaintenanceReducer,
    ScooterMaintenanceState,
} from '../scooterMaintenance';
import { agreementsReducer, AgreementsState } from '../agreements';
import {
    vehicleTelemetryEventsReducer,
    VehicleTelemetryEventsState,
} from '../vehicleTelemetryEvents';
import { newsReducer, NewsState } from '../news';
import { partnersReducer, PartnersState } from '../partners';
import { AppVersionState } from '../application/types';
import { appVersionReducer } from '../application';
import { lockReducer, LockState } from '../locks';

export const rootReducer = (history: History) =>
    combineReducers({
        ui: uiReducer,
        auth: authReducer,
        appVersion: appVersionReducer,
        service: serviceReducer,
        company: companyReducer,
        news: newsReducer,
        zone: zoneReducer,
        group: groupReducer,
        boUser: boUsersReducer,
        trip: tripReducer,
        user: userReducer,
        paymentHistory: paymentReducer,
        booking: bookingReducer,
        station: stationReducer,
        event: eventReducer,
        vehicle: vehicleReducer,
        scooterMaintenance: scooterMaintenanceReducer,
        agreements: agreementsReducer,
        vehicleTelemetryEvents: vehicleTelemetryEventsReducer,
        partners: partnersReducer,
        lock: lockReducer,
        push: pushReducer,
        logs: logsReducer,
        router: connectRouter(history),
    });

export interface State {
    ui: UIState;
    auth: authState;
    router: RouterState;
    service: ServiceState;
    company: CompanyState;
    news: NewsState;
    group: GroupState;
    boUser: BoUserState;
    zone: ZoneState;
    trip: TripState;
    user: UserState;
    station: StationState;
    event: EventsState;
    push: PushState;
    vehicle: VehiclesState;
    scooterMaintenance: ScooterMaintenanceState;
    agreements: AgreementsState;
    vehicleTelemetryEvents: VehicleTelemetryEventsState;
    partners: PartnersState;
    appVersion: AppVersionState;
    lock: LockState;
    logs: LogsState;
    paymentHistory: PaymentState;
    booking: BookingState;
}
