import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    requestExportLogs,
    requestExportLogsError,
    requestExportLogsSuccess,
    fetchLogs,
    fetchLogsError,
    fetchLogsSuccess,
    setFilter,
    setSort,
    setPage,
} from './index';
import { exportLogs, getLogs } from './service';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { Filters, AdminLogsResponse } from './types';
import {
    mapById,
    mapFilters,
    mapPagination,
    mapSorting,
} from 'ducks/common/mappers';
import { mapFetchedPagination } from 'ducks/common/pagination';
import { downloadBlob } from 'tools/file';
import { Pagination, Sort } from '../common/types';

function* fetchLogsSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: AdminLogsResponse = yield call(getLogs, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { allIds, byId } = mapById(data.content);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchLogsSuccess({
                allIds,
                byId,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchLogsError({ error: (error as Error)?.message }));
    }
}

function* exportLogsSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const { file, fileName } = yield call(exportLogs, mapFilters(filters));
        downloadBlob(file, fileName);
        yield put(requestExportLogsSuccess());
    } catch (error) {
        yield put(requestExportLogsError({ error: (error as Error)?.message }));
    }
}

export default function* logsSagas() {
    yield takeLatest(fetchLogs.type, fetchLogsSaga);
    yield takeLatest(setFilter.type, fetchLogsSaga);
    yield takeLatest(requestExportLogs.type, exportLogsSaga);
    yield takeLatest(setSort.type, fetchLogsSaga);
    yield takeLatest(setPage.type, fetchLogsSaga);
}
