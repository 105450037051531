import axios from 'axios';
import { BASE_URL, JWT_PREFIX, PARTNER_KEY } from 'config';
import qs from 'qs';
import { routes } from '../constants/common';

const request = axios.create({
    baseURL: BASE_URL || 'http://localhost:5000',
    headers: {
        'Content-Type': 'application/json',
        'Partner-Key': String(PARTNER_KEY),
    },
    paramsSerializer: (params: any) =>
        qs.stringify(params, { encodeValuesOnly: true }),
});

/**
 * Request interceptor for setting JWT to request header
 */
request.interceptors.response.use(responseMapper);

/**
 * Request interceptor for setting JWT to request header
 */
request.interceptors.request.use(setJWTHeader);

request.interceptors.response.use(responseMapper, errorHandler);

/**
 * Response interceptor for setting JWT to LocalStorage
 */
request.interceptors.response.use(setJWTInLocalStorage);

function errorHandler({ response }: any) {
    if (response && response.status === 401) {
        window.localStorage.removeItem('key');
        if (
            window.location.pathname !== routes.LOGIN &&
            window.location.pathname !== routes.PASSWORD_RESTORE
        ) {
            window.location.pathname = routes.LOGIN;
        }
    }
    if (response && response.status === 403) {
        window.location.pathname = routes.MAIN_PAGE;
    } else
        return Promise.reject(
            response
                ? {
                      status: response.status,
                      errorField: response.data.errors?.[0].field,
                      message:
                          (response.data.errors
                              ? `${response.data.errors[0]?.field} - ${response.data.errors[0]?.message}`
                              : response.data.message) ||
                          'Error occurred on server',
                      data: response.data,
                  }
                : { status: null, message: 'Error occurred on server' },
        );
}

export interface RequestError {
    status?: number;
    errorField?: string;
    message?: string;
    data: any;
}

/**
 * Set jwt token in the authorization header under the 'Authorization' key
 *
 * @param {AxiosRequestConfig} config
 * @return {AxiosRequestConfig}
 */
function setJWTHeader(config: any) {
    const newConfig = config;

    newConfig.headers.common[
        'Authorization'
    ] = `${JWT_PREFIX} ${window.localStorage.getItem('key')}`;

    return newConfig;
}

/**
 * Save JWT to the LocalStorage
 *
 * @param {AxiosResponse} response
 * @returns {Promise}
 */
function setJWTInLocalStorage(response: any) {
    if (response?.token) {
        window.localStorage.setItem('key', response.token);
    }
    return response;
}

/**
 * @param {AxiosResponse} response - server's response
 * @return {Promise}
 */
function responseMapper(response: any) {
    return response.data && !response.headers['content-disposition']
        ? response.data
        : response;
}

export default request;
