import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Check } from '../../common/CheckIcon';
import { Cross } from '../../common/CrossIcon';
import { FormGrid } from '../../common/FormGrid';
import { useSelector } from 'react-redux';
import { RootState } from 'ducks/root';
import { useTranslation } from 'react-i18next';

export const UneditableInformation = () => {
    const { t } = useTranslation();
    const { currentUser } = useSelector((state: RootState) => state.user);

    return (
        <FormGrid columnAmount={2}>
            <Box>
                <Typography variant="h3">{t('usersList.userId')}</Typography>
                <Typography>{currentUser?.user?.id}</Typography>
            </Box>
            <Box>
                <Typography variant="h3">{t('usersList.language')}</Typography>
                <Typography>{currentUser?.user?.language}</Typography>
            </Box>
            <Box>
                <Typography variant="h3">{t('usersList.news')}</Typography>
                <Typography>
                    <span>
                        {currentUser?.user?.areNewsAccepted ? (
                            <Check />
                        ) : (
                            <Cross />
                        )}
                    </span>
                </Typography>
            </Box>
            <Box>
                <Typography variant="h3">
                    {t('usersList.pushNotifications')}
                </Typography>
                <Typography>
                    <span>
                        {currentUser?.user?.arePushAccepted ? (
                            <Check />
                        ) : (
                            <Cross />
                        )}
                    </span>
                </Typography>
            </Box>
            <Box>
                <Typography variant="h3">{t('usersList.loginDate')}</Typography>
                <Typography>
                    {currentUser &&
                        new Date(currentUser?.user?.created).toLocaleString()}
                </Typography>
            </Box>
        </FormGrid>
    );
};
