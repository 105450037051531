import React, { ReactNode } from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    withStyles,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import { colors } from 'themes/colors';
import { StyledTab } from './Tab';
import { TabsContext } from './TabsContext';
import { useTranslation } from 'react-i18next';

const StyledTabs = withStyles(() =>
    createStyles({
        root: {
            overflow: 'visible',
        },
        indicator: {
            backgroundColor: 'transparent',
        },
        scroller: {
            overflow: 'visible !important',
        },
    }),
)(Tabs);

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    tabPanel: {
        position: 'relative',
        backgroundColor: colors.white,
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        border: `2px solid`,
        borderColor: theme.palette.primary.main,
    },
}));

interface ITabsProps {
    tabTitles: string[];
    children: ReactNode;
    setTab?: (value: number) => void;
    currentTab?: number;
}
export const TabsComponent: React.FC<ITabsProps> = ({
    tabTitles,
    children,
    setTab,
    currentTab = 0,
}: ITabsProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = React.useState(currentTab);

    React.useEffect(() => {
        return () => {
            setTab && setTab(0);
        };
    }, [setTab]);

    const handleChange = (
        event: React.ChangeEvent<unknown>,
        newValue: number,
    ) => {
        setValue(newValue);
        setTab && setTab(newValue);
    };

    return (
        <div className={classes.root}>
            <TabsContext.Provider value={value}>
                <AppBar position="static" className={classes.appBar}>
                    <StyledTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="simple tabs example">
                        {tabTitles.map((title, index) => (
                            <StyledTab
                                label={t(title)}
                                key={`tab-${index}`}
                                id={`simple-tab-${index}`}
                                disableFocusRipple={true}
                                disableRipple={true}
                            />
                        ))}
                    </StyledTabs>
                </AppBar>
                <div className={classes.tabPanel}>{children}</div>
            </TabsContext.Provider>
        </div>
    );
};
