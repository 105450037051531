import React, { Ref } from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ButtonComponent } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../ModalContainer/styles';

interface ModalProps {
    isOpen: boolean;
    message: string;
    handleClose: () => void;
    handleConfirm: () => void;
    confirmButtonName?: string;
}

// eslint-disable-next-line react/display-name
export const ConfirmationModal = React.forwardRef(
    (
        { handleClose, handleConfirm, message, confirmButtonName }: ModalProps,
        ref: Ref<HTMLDivElement>,
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <div ref={ref} className={classes.modal}>
                <Box textAlign="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h2">{`${t(message)}?`}</Typography>
                <Box textAlign="center" marginTop="30px">
                    <ButtonComponent
                        handleClick={handleClose}
                        text={t('cancel')}
                    />
                    &emsp;
                    <ButtonComponent
                        handleClick={handleConfirm}
                        text={confirmButtonName || t('confirm')}
                    />
                </Box>
            </div>
        );
    },
);
