import { PaginationComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { selectPagination, setPage } from 'ducks/scooterMaintenance';

export const Pagination = (props: unknown) => {
    const dispatch = useDispatch();

    const pagination = useSelector(selectPagination);

    const handleSetPage = React.useCallback(
        (page: number) => {
            dispatch(setPage({ page }));
        },
        [dispatch],
    );
    return (
        <PaginationComponent
            {...props}
            pagination={pagination}
            setPage={handleSetPage}
        />
    );
};
