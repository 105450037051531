export const parseZoneDocument = (
    file: File,
): Promise<{ zones: zone; zoneNames: string[] }> => {
    return new Promise(resolve => {
        const fileReader = new FileReader();
        fileReader.onload = e => {
            const { zones, zoneNames } = extractZones(
                (e.target?.result as string) || '',
            );
            resolve({ zones, zoneNames });
        };
        fileReader.readAsText(file);
    });
};

type zone = {
    [zoneName: string]: { lat: number; lng: number }[];
};

const extractZones = (
    plainText: string,
): { zones: zone; zoneNames: string[] } => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(plainText, 'text/xml');
    let zones: zone = {};
    const zoneNames: string[] = [];
    if (xmlDoc.documentElement.nodeName === 'kml') {
        zones = Array.from(xmlDoc.getElementsByTagName('Placemark')).reduce(
            (accum, item) => {
                const zoneName =
                    item
                        .getElementsByTagName('name')[0]
                        .childNodes[0].nodeValue?.trim() || '';
                const polygons = item.getElementsByTagName('Polygon');
                const lineStrings = item.getElementsByTagName('LineString');

                let zonesPolygons: HTMLCollectionOf<Element>;
                if (polygons.length > 0) {
                    zonesPolygons = polygons;
                } else {
                    zonesPolygons = lineStrings;
                }

                const coordinates = Array.from(zonesPolygons).reduce(
                    (acc, zone, index) => {
                        const coords = zone
                            .getElementsByTagName('coordinates')[0]
                            .childNodes[0].nodeValue?.trim();
                        const points = coords?.split(' ') || [];
                        const coordinates = [];
                        for (const point of points) {
                            const coord = point.split(',');
                            if (!isNaN(+coord[1]) && !isNaN(+coord[0])) {
                                coordinates.push({
                                    lat: +coord[1],
                                    lng: +coord[0],
                                });
                            }
                        }
                        zoneNames.push(
                            `${zoneName}${index ? `_${index}` : ''}`,
                        );
                        return {
                            ...acc,
                            [`${zoneName}${index ? `_${index}` : ''}`]:
                                coordinates,
                        };
                    },
                    {},
                );
                return { ...accum, ...coordinates };
            },
            {},
        );
    } else {
        throw Error('error while parsing');
    }
    return { zones, zoneNames };
};
