import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ButtonComponent,
    CheckboxField,
    FormGrid,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from '../../common';
import {
    fetchAllServices,
    selectAllServicesById,
    selectAllServicesIds,
} from 'ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import { Box, IconButton, Typography } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import {
    createStation,
    deleteStation,
    fetchStation,
    resetCurrentStation,
    selectIsLoading,
    selectStationError,
    selectStationHasUnsavedChanges,
    setStationHasUnsavedChanges,
    updateStation,
    changeStationActivationStatus,
    fetchSyncStation,
} from 'ducks/stations';
import { Prompt, useParams } from 'react-router';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import BackIcon from '@material-ui/icons/ArrowBack';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import {
    StationTypes,
    Station,
    StationActivationStatuses,
} from 'ducks/stations/types';
import { MapComponent } from '../../common/Map';
import { Marker } from '@react-google-maps/api';
import { validations } from './validations';
import { selectCurrentStation } from 'ducks/stations/selectors';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import { UsageTypes } from 'ducks/services/types';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { BeamsTable } from './BeamsTable';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import { useFormMutators } from 'hooks/useFormMutators';

export const CreateStationComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { stationId } = useParams<{ stationId?: string }>();

    const currentStation = useSelector(selectCurrentStation);

    const accessToPages = useSelector(selectAccessToPages);

    const allowedToEdit =
        accessToPages[
            stationId ? Permissions.STATION_UPDATE : Permissions.STATION_CREATE
        ];
    const allowedToDelete = accessToPages[Permissions.STATION_DELETE];

    const statusOfActivation = currentStation?.statusOfActivation;

    const isDuckt = currentStation?.stationType === StationTypes.DUCKT;

    const isActiveStation = React.useMemo(
        () =>
            (statusOfActivation &&
                [null, StationActivationStatuses.ACTIVE].includes(
                    statusOfActivation,
                )) ||
            false,
        [statusOfActivation],
    );

    const handleToggleStationActivation = React.useCallback(() => {
        dispatch(
            changeStationActivationStatus({
                activationStatus: isActiveStation
                    ? StationActivationStatuses.DEACTIVATED
                    : StationActivationStatuses.ACTIVE,
                id: stationId,
            }),
        );
    }, [dispatch, isActiveStation, stationId]);

    const handleConfirm = React.useCallback(
        (values: Station) => {
            const {
                ducktStationUid,
                rfidTechnology,
                rfidToken,
                rfidUrl,
                ...simpleStationValues
            } = values;
            const action = stationId ? updateStation : createStation;
            const filteredValues = simpleStationValues as Station;

            if (values.stationType !== StationTypes.Simple) {
                filteredValues.ducktStationUid = ducktStationUid;
            }
            if (values.isConnected) {
                filteredValues.rfidTechnology = rfidTechnology;
                if (rfidTechnology === 'eden') {
                    filteredValues.rfidToken = rfidToken;
                    filteredValues.rfidUrl = rfidUrl;
                }
            }
            dispatch(action(filteredValues));
        },
        [dispatch, stationId],
    );

    const handleDelete = React.useCallback(() => {
        dispatch(deleteStation(stationId));
        dispatch(closeModal());
    }, [dispatch, stationId]);

    React.useEffect(() => {
        if (stationId) {
            dispatch(fetchStation(stationId));
        }
        dispatch(
            fetchAllServices({
                onlyEnabled: false,
                type: UsageTypes.SHORT_TERM_USAGE,
            }),
        );
        return () => {
            dispatch(setStationHasUnsavedChanges(false));
            dispatch(resetCurrentStation());
        };
    }, [dispatch, stationId]);

    const stationTypeOptions = React.useMemo(
        () => [
            {
                label: t(StationTypes.Simple),
                value: StationTypes.Simple,
            },
            {
                label: t(StationTypes.DUCKT),
                value: StationTypes.DUCKT,
            },
            {
                label: t(StationTypes.KNOTT),
                value: StationTypes.KNOTT,
            },
        ],
        [t],
    );

    const rfidReaderTechnologyOptions = React.useMemo(
        () => [
            {
                label: 'Eden',
                value: 'eden',
            },
            {
                label: '-',
                value: '',
            },
        ],
        [],
    );

    const redirectToStations = React.useCallback(
        () => dispatch(push(routes.STATIONS)),
        [dispatch],
    );

    const allServicesById = useSelector(selectAllServicesById);

    const error = useSelector(selectStationError);
    const loading = useSelector(selectIsLoading);

    const allServiceIds = useSelector(selectAllServicesIds);

    const stationHasUnsavedChanges = useSelector(
        selectStationHasUnsavedChanges,
    );

    const serviceOptions = React.useMemo(
        () =>
            allServiceIds.map(id => ({
                value: id,
                label: allServicesById[id].name,
            })),
        [allServiceIds, allServicesById],
    );

    const initialValues = React.useMemo(
        () =>
            stationId
                ? {
                      ...currentStation,
                      totalPlaces: `${currentStation?.totalPlaces}`,
                      lat: `${currentStation?.lat}`,
                      lng: `${currentStation?.lng}`,
                      stationType:
                          currentStation?.stationType || StationTypes.Simple,
                      rfidTechnology: currentStation?.rfidTechnology || null,
                  }
                : {
                      isConnected: false,
                      totalPlaces: 0,
                      stationType: StationTypes.Simple,
                  },
        [stationId, currentStation],
    );

    const { stationType: initStationType } = initialValues;

    const handleClickDelete = React.useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: handleDelete,
                    message: 'stations.deleteMessage',
                },
            }),
        );
    }, [dispatch, handleDelete]);

    useNotificationOnPageLeave(stationHasUnsavedChanges);

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            modifiedSinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setStationHasUnsavedChanges(
                    submitSucceeded ? modifiedSinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    const handleChangeStationPosition = useCallback((e, values, form) => {
        form.mutators.setField({
            field: 'lat',
            value: e.latLng.lat() ? e.latLng.lat().toFixed(7) : '',
        });
        form.mutators.setField({
            field: 'lng',
            value: e.latLng.lng() ? e.latLng.lng().toFixed(7) : '',
        });
    }, []);

    const mutators = useFormMutators();

    const handleOpenConfirmSyncModal = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.STATION_SYNC_CONFIRM,
                modalProps: {
                    handleConfirm: () => {
                        dispatch(fetchSyncStation());
                        dispatch(closeModal());
                    },
                },
            }),
        );
    }, [dispatch]);

    return (
        <ThemedBoxComponent m="0 5%">
            {loading && <LoaderComponent />}
            <Prompt
                when={stationHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={handleConfirm}
                initialValues={initialValues}
                mutators={mutators}
                render={({ handleSubmit, valid, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                modifiedSinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Box display="flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToStations}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {t(
                                        stationId
                                            ? 'stations.editStation'
                                            : 'stations.addStation',
                                    )}
                                </p>
                            </Typography>
                        </Box>
                        <FormGrid columnAmount={2}>
                            <Box>
                                <FormGrid columnAmount={2}>
                                    <TextField
                                        name="name"
                                        validators={validations.name}
                                        label={t('stations.name')}
                                        disabled={!allowedToEdit}
                                        isRequired
                                    />
                                    <TextField
                                        name="totalPlaces"
                                        validators={validations.totalPlaces}
                                        type="number"
                                        disabled={
                                            !allowedToEdit || values.isConnected
                                        }
                                        label={t('stations.totalPlaces')}
                                        InputProps={{
                                            inputProps: { min: 0 },
                                        }}
                                    />
                                    <TextField
                                        name="lat"
                                        validators={validations.latitude}
                                        type="number"
                                        disabled={!allowedToEdit}
                                        label={t('stations.lat')}
                                        InputProps={{
                                            inputProps: {
                                                min: -90,
                                                max: 90,
                                            },
                                        }}
                                        isRequired
                                    />
                                    <TextField
                                        name="lng"
                                        validators={validations.longitude}
                                        type="number"
                                        disabled={!allowedToEdit}
                                        label={t('stations.lng')}
                                        InputProps={{
                                            inputProps: {
                                                min: -180,
                                                max: 180,
                                            },
                                        }}
                                        isRequired
                                    />
                                    <SelectField
                                        validators={validations.serviceId}
                                        name="serviceId"
                                        disabled={!allowedToEdit}
                                        label={t('stations.service')}
                                        options={serviceOptions}
                                        isRequired
                                    />
                                    <CheckboxField
                                        disabled={
                                            !allowedToEdit ||
                                            !!currentStation?.beams?.length
                                        }
                                        field="isConnected"
                                        label="stations.connected"
                                        handleChange={value => {
                                            form.mutators.setField({
                                                field: 'address',
                                                value: '',
                                            });
                                            form.mutators.setField({
                                                field: 'totalPlaces',
                                                value: 0,
                                            });
                                            if (!value) {
                                                form.mutators.setField({
                                                    field: 'stationType',
                                                    value: initStationType,
                                                });
                                            }
                                        }}
                                    />
                                    <TextField
                                        multiline
                                        disabled={
                                            !allowedToEdit ||
                                            !values.isConnected
                                        }
                                        name="address"
                                        label="stations.address"
                                    />
                                    <SelectField
                                        validators={validations.dockType}
                                        name="stationType"
                                        disabled={
                                            !allowedToEdit ||
                                            !!currentStation?.beams?.length ||
                                            !values.isConnected
                                        }
                                        label={t('stations.stationType')}
                                        options={stationTypeOptions}
                                        isRequired
                                    />
                                    <TextField
                                        name="ducktStationUid"
                                        label="stations.ducktStationUid"
                                        validators={validations.stationId}
                                        disabled={
                                            !allowedToEdit ||
                                            values?.stationType ===
                                                StationTypes.Simple ||
                                            !!currentStation?.beams?.length ||
                                            !values.isConnected
                                        }
                                        isRequired
                                    />

                                    {values.isConnected && (
                                        <SelectField
                                            name="rfidTechnology"
                                            disabled={!allowedToEdit}
                                            label="rfid Technology"
                                            options={
                                                rfidReaderTechnologyOptions
                                            }
                                        />
                                    )}

                                    {values?.rfidTechnology === 'eden' && (
                                        <TextField
                                            name="rfidUrl"
                                            label="rfid Url"
                                            validators={validations.rfIdUrl}
                                            disabled={!allowedToEdit}
                                        />
                                    )}
                                </FormGrid>
                                {values?.rfidTechnology === 'eden' && (
                                    <FormGrid columnAmount={1}>
                                        <TextField
                                            name="rfidToken"
                                            label="rfid Token"
                                            disabled={!allowedToEdit}
                                        />
                                    </FormGrid>
                                )}
                                {isDuckt && (
                                    <FormGrid columnAmount={2}>
                                        <FormButtonGroup>
                                            <ButtonComponent
                                                handleClick={
                                                    handleOpenConfirmSyncModal
                                                }
                                                text={t('sync')}
                                            />
                                        </FormButtonGroup>
                                    </FormGrid>
                                )}
                                {stationId && currentStation?.isConnected && (
                                    <BeamsTable
                                        stationId={currentStation.id}
                                        beams={currentStation.beams}
                                    />
                                )}
                            </Box>
                            {values.lat && values.lng && (
                                <Box m="10px 20px 20px">
                                    <MapComponent
                                        boundCoordinates={[
                                            {
                                                lat: Number(values.lat),
                                                lng: Number(values.lng),
                                            },
                                        ]}>
                                        <Marker
                                            draggable
                                            onDragEnd={e =>
                                                handleChangeStationPosition(
                                                    e,
                                                    values,
                                                    form,
                                                )
                                            }
                                            position={{
                                                lat: Number(values.lat),
                                                lng: Number(values.lng),
                                            }}
                                        />
                                    </MapComponent>
                                </Box>
                            )}
                        </FormGrid>
                        {!stationId ? (
                            allowedToEdit && (
                                <FormButtonGroup>
                                    <ButtonComponent
                                        handleClick={handleSubmit}
                                        text={t('create')}
                                        isDisabled={
                                            loading ||
                                            !stationHasUnsavedChanges ||
                                            !valid
                                        }
                                    />
                                </FormButtonGroup>
                            )
                        ) : (
                            <FormButtonGroup>
                                {allowedToEdit && (
                                    <ButtonComponent
                                        handleClick={
                                            handleToggleStationActivation
                                        }
                                        text={
                                            isActiveStation
                                                ? t('deactivate')
                                                : t('activate')
                                        }
                                        isDisabled={loading}
                                    />
                                )}
                                {allowedToDelete && (
                                    <ButtonComponent
                                        handleClick={handleClickDelete}
                                        text={t('delete')}
                                        startIcon={<DeleteIcon />}
                                        isDisabled={loading}
                                    />
                                )}
                                {allowedToEdit && (
                                    <ButtonComponent
                                        handleClick={handleSubmit}
                                        text={t('update')}
                                        isDisabled={
                                            loading ||
                                            !stationHasUnsavedChanges ||
                                            !valid
                                        }
                                        startIcon={<SaveIcon />}
                                    />
                                )}
                            </FormButtonGroup>
                        )}
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
