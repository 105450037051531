import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, useParams } from 'react-router';
import { Form, FormSpy } from 'react-final-form';
import { Box, IconButton, Typography } from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';
import {
    ButtonComponent,
    FormGrid,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from '../../common';
import { validations } from './validations';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { push } from 'connected-react-router';
import { routes } from 'constants/common';
import { Partner, PartnerCallerTypes } from 'ducks/partners/types';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import {
    selectPartnersError,
    createPartner,
    setPartnerHasUnsavedChanges,
    selectPartnerHasUnsavedChanges,
    fetchPartner,
    selectCurrentPartner,
    deletePartner,
    togglePartnerEnable,
    updatePartner,
    selectIsLoading,
} from 'ducks/partners';
import { closeModal, Modals, openModal } from 'ducks/ui';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import { LoaderComponent } from 'components/common/Loader';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import SelectPartnerCompanies from './SelectPartnerCompanies';

export const CreatePartner: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { partnerId } = useParams<{ partnerId?: string }>();
    const loading = useSelector(selectIsLoading);
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = partnerId
        ? accessToPages[Permissions.PARTNER_UPDATE]
        : accessToPages[Permissions.PARTNER_CREATE];
    const allowedToDelete = accessToPages[Permissions.PARTNER_DELETE];
    const error = useSelector(selectPartnersError);
    const partnerHasUnsavedChanges = useSelector(
        selectPartnerHasUnsavedChanges,
    );
    const currentPartner = useSelector(selectCurrentPartner);

    useEffect(() => {
        if (partnerId) {
            dispatch(fetchPartner(partnerId));
        }
    }, [dispatch, partnerId]);

    const initialValues = useMemo(() => {
        if (partnerId) {
            return {
                ...currentPartner,
                status: `${currentPartner?.isEnabled}`,
                companies: currentPartner
                    ? currentPartner.companies.map(({ id }) => id)
                    : [],
            };
        }
        return {
            name: '',
            key: '',
            caller: PartnerCallerTypes.BO,
            companies: [],
        };
    }, [currentPartner, partnerId]);

    const statusOptions = useMemo(
        () => [
            {
                label: t('partners.inactive'),
                value: 'false',
            },
            {
                label: t('partners.active'),
                value: 'true',
            },
        ],
        [t],
    );

    const callerTypeOptions = useMemo(
        () =>
            Object.values(PartnerCallerTypes).map(type => ({
                label: t(`partners.caller.${type}`),
                value: type,
            })),
        [t],
    );

    const redirectToPartners = useCallback(
        () => dispatch(push(routes.PARTNERS)),
        [dispatch],
    );

    const handleConfirm = useCallback(
        (values: Partner) =>
            partnerId
                ? dispatch(updatePartner(values))
                : dispatch(createPartner(values)),
        [dispatch, partnerId],
    );

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setPartnerHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );
    const handleDelete = React.useCallback(() => {
        dispatch(deletePartner(partnerId));
        dispatch(closeModal());
    }, [dispatch, partnerId]);

    const handleClickDelete = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: handleDelete,
                    message: 'partners.deleteMessage',
                },
            }),
        );
    }, [dispatch, handleDelete]);

    const toggleEnable = useCallback(() => {
        dispatch(togglePartnerEnable(partnerId, !currentPartner?.isEnabled));
        dispatch(closeModal());
    }, [dispatch, partnerId, currentPartner]);

    const handleClickToggleEnable = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.CHANGE_CONFIRMATION,
                modalProps: {
                    handleConfirm: toggleEnable,
                    message: t('partners.changeStatusMessage'),
                },
            }),
        );
    }, [dispatch, toggleEnable, t]);

    useNotificationOnPageLeave(partnerHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 25%">
            {loading && <LoaderComponent />}
            <Prompt
                when={partnerHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={(values: Partner) => {
                    handleConfirm(values);
                }}
                initialValues={initialValues}
                render={({ handleSubmit, valid, values }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <Box display="inline-flex">
                            <Box display="flex" alignItems="center">
                                <IconButton onClick={redirectToPartners}>
                                    <BackIcon />
                                </IconButton>
                            </Box>
                            <Typography variant="h1">
                                <p>
                                    {partnerId
                                        ? t('partners.updatePartner')
                                        : t('partners.createPartner')}
                                </p>
                            </Typography>
                        </Box>
                        <FormGrid columnAmount={2}>
                            {partnerId && (
                                <TextField
                                    name="id"
                                    label={t('partners.id')}
                                    disabled
                                />
                            )}
                            <TextField
                                name="name"
                                validators={validations.name}
                                label={t('partners.name')}
                                isRequired
                            />
                            <TextField
                                name="key"
                                validators={validations.key}
                                label={t('partners.key')}
                                isRequired
                            />
                            <SelectField
                                name="caller"
                                validators={validations.key}
                                label={t('partners.callerType')}
                                isRequired
                                options={callerTypeOptions}
                            />
                            <SelectPartnerCompanies caller={values.caller} />
                            {partnerId && (
                                <SelectField
                                    name="status"
                                    label={t('partners.status')}
                                    options={statusOptions}
                                    disabled
                                />
                            )}
                        </FormGrid>
                        <FormButtonGroup>
                            {partnerId && allowedToEdit && (
                                <ButtonComponent
                                    text={t(
                                        currentPartner?.isEnabled
                                            ? 'disable'
                                            : 'enable',
                                    )}
                                    handleClick={handleClickToggleEnable}
                                />
                            )}
                            {partnerId && allowedToDelete && (
                                <ButtonComponent
                                    handleClick={handleClickDelete}
                                    text={t('delete')}
                                />
                            )}
                            {allowedToEdit && (
                                <ButtonComponent
                                    handleClick={handleSubmit}
                                    text={partnerId ? t('save') : t('create')}
                                    isDisabled={
                                        !partnerHasUnsavedChanges ||
                                        !valid ||
                                        loading
                                    }
                                />
                            )}
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
