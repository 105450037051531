import { TFunction } from 'i18next';
import {
    ScooterMaintenance,
    ScooterMaintenanceType,
} from 'ducks/scooterMaintenance/types';

export const getColumns = (t: TFunction) => {
    const columns = [
        {
            title: t('scooterMaintenance.timeStamp'),
            field: 'updated',
            sorting: false,
            render: (rowProps: ScooterMaintenance) =>
                rowProps.updated
                    ? new Date(rowProps.updated).toLocaleString()
                    : '',
        },
        {
            title: t('scooterMaintenance.duration'),
            field: 'duration',
            sorting: false,
        },
        {
            title: t('scooterMaintenance.description'),
            field: 'description',
            sorting: false,
            render: ({ type, description }: ScooterMaintenance) =>
                type === ScooterMaintenanceType.repair ? description : '',
        },
    ];

    return columns;
};
