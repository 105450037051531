import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { setSort } from 'ducks/companies';
import { Domains, Manager } from 'ducks/companies/types';

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('company.name'),
            field: 'name',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'name',
                    }),
                ),
        },
        {
            title: t('company.servicesCount'),
            field: 'servicesCount',
            sorting: false,
        },
        {
            title: t('company.usersCount'),
            field: 'usersCount',
            sorting: false,
        },
        {
            title: t('company.managers'),
            sorting: false,
            field: 'managers',
            // eslint-disable-next-line react/display-name
            render: (rowProps: { managers: Manager[] }) => (
                <span>
                    {rowProps.managers.map(({ email }) => email).join(', ')}
                </span>
            ),
        },
        {
            title: t('company.acceptedDomains'),
            sorting: false,
            field: 'acceptedDomains',
            // eslint-disable-next-line react/display-name
            render: (rowProps: { domainLists: Domains }) => (
                <span>{rowProps.domainLists.acceptedDomains?.join(', ')}</span>
            ),
        },
        {
            title: t('company.rejectedDomains'),
            sorting: false,
            field: 'rejectedDomains',
            // eslint-disable-next-line react/display-name
            render: (rowProps: { domainLists: Domains }) => (
                <span>{rowProps.domainLists.rejectedDomains?.join(', ')}</span>
            ),
        },
    ];
};
