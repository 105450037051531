import React, { useCallback } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ButtonComponent, Check, FormGrid } from '../../common';
import { Cross } from '../../common/CrossIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
    getVehicleState,
    selectCurrentVehicle,
    sendCommand,
} from 'ducks/vehicles/common';
import {
    AvailableForUnbind,
    VehicleCommands,
    VehicleTypes,
} from 'ducks/vehicles/common/types';
import { useTranslation } from 'react-i18next';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { Modals, openModal } from 'ducks/ui';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import { useParams } from 'react-router';
import { selectCurrentVehicleState } from 'ducks/vehicles/common/selectors';
import { StationTypes } from 'ducks/stations/types';

export const LockDetails: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { vehicleId } = useParams<{ vehicleId?: string }>();
    const currentVehicle = useSelector(selectCurrentVehicle);
    const currentVehicleState = useSelector(selectCurrentVehicleState);
    const isVae = currentVehicle?.type === VehicleTypes.VAE;
    const isDuckt =
        currentVehicleState?.vehicle?.stationType === StationTypes.DUCKT;
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToUnbind = accessToPages[Permissions.VEHICLE_UPDATE_STATUS];

    const handleUnbind = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.UNBIND_BIKE,
                modalProps: { lockId: currentVehicle?.lockId },
            }),
        );
    }, [dispatch, currentVehicle]);

    const sendAction = React.useCallback(
        (command: string) => {
            currentVehicle &&
                dispatch(
                    sendCommand({ command, vehicleId: currentVehicle.id }),
                );
        },
        [dispatch, currentVehicle],
    );

    React.useEffect(() => {
        currentVehicle &&
            dispatch(getVehicleState({ vehicleId: currentVehicle.id }));
    }, [currentVehicle, dispatch]);

    const fetchVehicleState = React.useCallback(
        () =>
            currentVehicle &&
            dispatch(getVehicleState({ vehicleId: currentVehicle.id })),
        [currentVehicle, dispatch],
    );

    return (
        <>
            <FormButtonGroup>
                {allowedToUnbind && vehicleId && (
                    <ButtonComponent
                        text={t('bikes.unbind')}
                        handleClick={handleUnbind}
                        isDisabled={
                            !currentVehicle?.lockId ||
                            (currentVehicle &&
                                !AvailableForUnbind.includes(
                                    currentVehicle?.status,
                                ))
                        }
                    />
                )}
                <ButtonComponent
                    text={t('locks.beep')}
                    handleClick={() => sendAction(VehicleCommands.locate)}
                    isDisabled={!currentVehicleState?.lock?.id}
                />
                <ButtonComponent
                    text={t('locks.openLock')}
                    handleClick={() => sendAction(VehicleCommands.openLock)}
                    isDisabled={!currentVehicleState?.lock?.id}
                />
                <ButtonComponent
                    text={t('locks.openDockStation')}
                    handleClick={() =>
                        sendAction(VehicleCommands.openDockStationLock)
                    }
                    isDisabled={!isDuckt}
                />
                <ButtonComponent
                    text={t('locks.getStatus')}
                    handleClick={fetchVehicleState}
                    isDisabled={!currentVehicleState?.lock?.id}
                />
            </FormButtonGroup>
            {!currentVehicle?.lockId ? (
                <Typography>{t('locks.noLock')}</Typography>
            ) : (
                <FormGrid columnAmount={2}>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.lockQrCode')}
                        </Typography>
                        <Typography>
                            {currentVehicleState?.lock?.qrCode}
                        </Typography>
                    </Box>
                    {isVae && (
                        <Box>
                            <Typography variant="h3">
                                {t('vehicles.alarmStatus')}
                            </Typography>
                            <span>
                                {currentVehicleState?.lock?.isAlarmActive ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Box>
                    )}
                    <Box>
                        <Typography variant="h3">
                            {t('bikes.lockCloseStatus')}
                        </Typography>
                        <Typography>
                            {currentVehicleState?.lock?.isClosed
                                ? t('bikes.closed')
                                : t('bikes.open')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('bikes.lockBatteryLevel')}
                        </Typography>
                        <Typography>
                            {currentVehicleState?.lock?.batteryLevel}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h3">
                            {t('vehicles.lastGPSDate')}
                        </Typography>
                        <Typography>
                            <span>
                                {currentVehicleState?.lock?.lastSync
                                    ? new Date(
                                          currentVehicleState?.lock?.lastSync,
                                      ).toLocaleString()
                                    : ''}
                            </span>
                        </Typography>
                    </Box>
                    {isVae && (
                        <Box>
                            <Typography variant="h3">
                                {t(
                                    isVae
                                        ? 'vehicles.vaeConnected'
                                        : 'vehicles.connected',
                                )}
                            </Typography>
                            <span>
                                {currentVehicleState?.vehicle.connected ? (
                                    <Check />
                                ) : (
                                    <Cross />
                                )}
                            </span>
                        </Box>
                    )}
                </FormGrid>
            )}
        </>
    );
};
