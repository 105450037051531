import React from 'react';
import { TextField, InputAdornment, InputProps } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { colors } from 'themes/colors';
import { StyledFormControlLabel } from '../FormControlLabel';

interface IInputInterface {
    hasError?: boolean;
    label?: string;
    type?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    helperText?: string;
    valid?: boolean;
    required?: boolean;
    multiline?: boolean;
    InputProps?: InputProps;
    accept?: string;
    id?: string;
    withoutCircleIcon?: boolean;
    disabled?: boolean;
    placeholder?: string;
    rows?: number;
}

export const InputComponent: React.FunctionComponent<IInputInterface> = ({
    hasError,
    label,
    value,
    onChange,
    onBlur,
    helperText,
    valid,
    type,
    required,
    multiline,
    InputProps,
    id,
    disabled,
    withoutCircleIcon,
    placeholder,
    rows,
}: IInputInterface) => {
    return (
        <StyledFormControlLabel
            labelPlacement="top"
            label={label}
            control={
                <TextField
                    disabled={disabled}
                    fullWidth
                    type={type}
                    value={value}
                    error={hasError}
                    required={required}
                    multiline={multiline}
                    id={id || label}
                    variant="outlined"
                    size="small"
                    onChange={onChange}
                    onBlur={onBlur}
                    helperText={helperText}
                    placeholder={placeholder}
                    rows={rows}
                    InputProps={{
                        ...InputProps,
                        endAdornment: !withoutCircleIcon ? (
                            <InputAdornment position="end">
                                {hasError ? (
                                    <CancelIcon
                                        color="error"
                                        fontSize="inherit"
                                    />
                                ) : (
                                    valid && (
                                        <CheckCircleIcon
                                            fontSize="inherit"
                                            style={{ color: colors.green }}
                                        />
                                    )
                                )}
                            </InputAdornment>
                        ) : null,
                    }}
                />
            }
        />
    );
};
