import React, { Dispatch } from 'react';
import { TFunction } from 'i18next';
import { Box } from '@material-ui/core';
import { Check } from '../../common/CheckIcon';
import { Zone } from 'ducks/zones/types';
import { LinkComponent } from '../../common/Link';
import { Modals, openModal } from 'ducks/ui';
import { setSort } from 'ducks/zones';

const renderColorField = (color: string) => (
    <Box display="inline-block" bgcolor={color} width="20px" height="20px" />
);

export const getColumns = (t: TFunction, dispatch: Dispatch<any>) => {
    return [
        {
            title: t('zones.id'),
            field: 'id',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'ID',
                    }),
                ),
        },
        {
            title: t('zones.name'),
            field: 'name',
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'name',
                    }),
                ),
            // eslint-disable-next-line react/display-name
            render: (rowProps: Zone) => (
                <LinkComponent
                    text={rowProps.name}
                    handleClick={event => {
                        event.stopPropagation();
                        dispatch(
                            openModal({
                                modalType: Modals.ZONE_MAP,
                                modalProps: {
                                    zoneId: rowProps.id,
                                },
                            }),
                        );
                    }}
                />
            ),
        },
        {
            title: t('zones.borderColor'),
            field: 'borderColor',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: Zone) => renderColorField(rowProps.borderColor),
        },
        {
            title: t('zones.fillColor'),
            field: 'fillColor',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: Zone) => renderColorField(rowProps.fillColor),
        },
        {
            title: t('zones.services'),
            field: 'services',
            sorting: false,
            // eslint-disable-next-line react/display-name
            render: (rowProps: Zone) => (
                <span>{rowProps.services.join(', ')}</span>
            ),
        },
        {
            title: t('zones.forbiddenZone'),
            sorting: false,
            field: 'isForbidden',
            // eslint-disable-next-line react/display-name
            render: (rowProps: Zone) => rowProps.isForbidden && <Check />,
            onClick: () =>
                dispatch(
                    setSort({
                        value: 'isForbidden',
                    }),
                ),
        },
    ];
};
