import { FetchedService } from '../services/types';
import { Pagination, Sort } from '../common/types';

export type ZonesById = {
    [code: number]: Zone;
};

export type ZoneState = {
    loading: boolean;
    error: string;
    zoneIds: number[];
    zonesById: ZonesById;
    allZonesById: ZonesById;
    allZoneIds: number[];
    importedZones: { [zoneName: string]: { lat: number; lng: number }[] };
    zoneHasUnsavedChanges: boolean;
    filters: Filters;
    sort: Sort;
    pagination: Pagination;
    currentZone: Zone | null;
};

export enum FilterTypes {
    isForbidden = 'isForbidden',
}
export enum StringFilterTypes {
    Name = 'name',
}

export type Filters = {
    [FilterTypes.isForbidden]: boolean;
    [StringFilterTypes.Name]: string;
};

export type Zone = {
    id: number;
    name: string;
    borderColor: string;
    fillColor: string;
    description: string;
    isForbidden: boolean;
    border: { lat: number; lng: number }[];
    services: number[];
    transparency: number;
};

export type Content = {
    zone: Zone;
    services: FetchedService[];
};

export type ServiceResponse = {
    content: Content[];
} & Pagination;

export type Accumulator = {
    zoneIds: number[];
    zonesById: ZonesById;
};
