import React, { useMemo } from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
    setStringFilter,
    StringFilterTypes,
    exportVehicles,
} from 'ducks/vehicles/common';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import AddIcon from '@material-ui/icons/Add';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { selectAccessToPages } from '../../../ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { useHistory } from 'react-router';
import { selectFilters } from 'ducks/vehicles/common/selectors';

export const SearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = history.location as { pathname: string };
    const isBikesPage = useMemo(
        () => pathname === routes.BIKES_AND_VAE,
        [pathname],
    );
    const filters = useSelector(selectFilters);

    const redirectToScooterCreation = React.useCallback(
        () =>
            isBikesPage
                ? dispatch(push(routes.NEW_BIKE_OR_VAE))
                : dispatch(push(routes.NEW_SCOOTER)),
        [dispatch, isBikesPage],
    );
    const accessToPages = useSelector(selectAccessToPages);
    const saveList = React.useCallback(
        () => dispatch(exportVehicles()),
        [dispatch],
    );
    const handleSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.Search,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    return (
        <FormGrid columnAmount={2} rowHeight="20px" padding="10px 0">
            <SearchComponent
                placeholder={t('search')}
                onChange={handleSearch}
                value={
                    filters[StringFilterTypes.Search]
                        ? filters[StringFilterTypes.Search]
                        : ''
                }
            />
            <FormGrid columnAmount={2} rowHeight="20px" padding="0">
                {accessToPages[Permissions.VEHICLE_CREATE] && (
                    <ButtonComponent
                        text={t('add')}
                        startIcon={<AddIcon />}
                        handleClick={redirectToScooterCreation}
                    />
                )}
                {accessToPages[Permissions.VEHICLE_EXPORT] && (
                    <ButtonComponent
                        text={t('download')}
                        startIcon={<SaveAltIcon />}
                        handleClick={saveList}
                    />
                )}
            </FormGrid>
        </FormGrid>
    );
};
