import { ButtonComponent, FormGrid } from 'components/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendCommand } from 'ducks/vehicles/common';
import { selectCurrentVehicle } from 'ducks/vehicles/common';
import { VehicleCommands } from 'ducks/vehicles/common/types';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

export const NIUActionsComponent = () => {
    const dispatch = useDispatch();
    const currentScooter = useSelector(selectCurrentVehicle);
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = accessToPages[Permissions.VEHICLE_UPDATE_STATUS];
    const sendAction = React.useCallback(
        (command: string) => {
            currentScooter?.serial &&
                dispatch(
                    sendCommand({ command, vehicleId: currentScooter.id }),
                );
        },
        [dispatch, currentScooter],
    );
    const { t } = useTranslation();
    return allowedToEdit ? (
        <FormGrid columnAmount={3}>
            <ButtonComponent
                text={t('vehicles.ignitionOn')}
                handleClick={() => sendAction(VehicleCommands.ignitionOn)}
            />
            <ButtonComponent
                text={t('vehicles.ignitionOff')}
                handleClick={() => sendAction(VehicleCommands.ignitionOff)}
            />
            <ButtonComponent
                text={t('vehicles.tailboxOn')}
                handleClick={() => sendAction(VehicleCommands.tailboxOn)}
            />
            <ButtonComponent
                text={t('vehicles.saddleOn')}
                handleClick={() => sendAction(VehicleCommands.saddleOn)}
            />
            <ButtonComponent
                text={t('vehicles.locate')}
                handleClick={() => sendAction(VehicleCommands.locate)}
            />
            <ButtonComponent
                text={t('vehicles.tailboxOff')}
                handleClick={() => sendAction(VehicleCommands.tailboxOff)}
            />
        </FormGrid>
    ) : (
        <></>
    );
};
