import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from '../common/pagination';
import { SortDirection } from '../common/types';
import { FilterTypes, StationState } from './types';

export const initialState: StationState = {
    stationIds: [],
    stationsById: {},
    loading: false,
    error: '',
    filters: {
        search: '',
    },
    currentStation: null,
    sort: {
        value: '',
        direction: SortDirection.Desc,
    },
    pagination: defaultPagination,
    stationHasUnsavedChanges: false,
};

const stationSlice = createSlice({
    name: 'station',
    initialState,
    reducers: {
        fetchStations(state) {
            state.loading = true;
            state.error = '';
        },
        fetchStationsSuccess(state, action) {
            state.stationIds = action.payload.stationIds;
            state.stationsById = action.payload.stationsById;
            state.pagination = action.payload.pagination;
            state.loading = false;
        },
        fetchStationsError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        fetchSyncStation(state) {
            state.loading = true;
            state.error = '';
        },
        fetchSyncStationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            state.loading = true;
            commonSetSort(state, payload.value);
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string;
            }>,
        ) {
            state.loading = true;
            state.filters[payload.filterType] = payload.value;
            state.pagination = defaultPagination;
        },
        setStationHasUnsavedChanges(state, action) {
            state.stationHasUnsavedChanges = action.payload;
        },
        createStation: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: station => ({
                payload: station,
            }),
        },
        createStationSuccess(state) {
            state.loading = false;
            state.stationHasUnsavedChanges = false;
        },
        createStationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        fetchStation: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: stationId => ({
                payload: stationId,
            }),
        },
        fetchStationSuccess(state, action) {
            state.loading = false;
            state.currentStation = action.payload;
        },
        fetchStationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetCurrentStation(state) {
            state.currentStation = null;
        },
        deleteStation: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: stationId => ({
                payload: stationId,
            }),
        },
        deleteStationSuccess(state) {
            state.loading = false;
            state.currentStation = null;
            state.stationHasUnsavedChanges = false;
        },
        deleteStationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        updateStation: {
            reducer: state => {
                state.loading = true;
                state.error = '';
                state.stationHasUnsavedChanges = true;
            },
            prepare: station => ({
                payload: station,
            }),
        },
        updateStationSuccess(state, action) {
            state.loading = false;
            state.stationHasUnsavedChanges = false;
            state.currentStation = action.payload;
        },
        updateStationError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        addBeam: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ stationId, beamId, readerName }) => ({
                payload: { stationId, beamId, readerName },
            }),
        },
        addBeamSuccess(state) {
            state.loading = false;
        },
        addBeamError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        deleteBeam: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ stationId, beamId }) => ({
                payload: { stationId, beamId },
            }),
        },
        deleteBeamSuccess(state) {
            state.loading = false;
        },
        deleteBeamError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        changeStationActivationStatus: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: ({ activationStatus, id }) => ({
                payload: { activationStatus, id },
            }),
        },
        changeStationActivationStatusSuccess(state, action) {
            state.loading = false;
            state.currentStation = action.payload;
        },
        changeStationActivationStatusError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
    },
});
export const {
    fetchStations,
    fetchStationsSuccess,
    fetchStationsError,
    setSort,
    setPage,
    setFilter,
    setStationHasUnsavedChanges,
    createStation,
    createStationError,
    createStationSuccess,
    fetchStation,
    fetchStationError,
    fetchStationSuccess,
    resetCurrentStation,
    deleteStation,
    deleteStationError,
    deleteStationSuccess,
    updateStation,
    updateStationError,
    updateStationSuccess,
    addBeam,
    addBeamSuccess,
    addBeamError,
    deleteBeam,
    deleteBeamSuccess,
    deleteBeamError,
    changeStationActivationStatus,
    fetchSyncStation,
    fetchSyncStationError,
} = stationSlice.actions;

export const stationReducer = stationSlice.reducer;
