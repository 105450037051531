import request from 'helpers/request';
import { PARTNERS_EXPORT_URL, PARTNERS_URL } from 'constants/api';
import { getFileFromResponse } from 'tools/file';
import { NewPartner } from './types';

export const getPartners = (params: any): Promise<string> =>
    request.get(PARTNERS_URL, { params });

export const exportPartners = () =>
    request
        .get(PARTNERS_EXPORT_URL)
        .then(response => getFileFromResponse(response));

export const createPartnerService = (params: NewPartner): Promise<string> =>
    request.post(PARTNERS_URL, { ...params });

export const getPartner = (id: number): Promise<string> =>
    request.get(`${PARTNERS_URL}/${id}`);

export const deletePartnerService = (id: number): Promise<any> =>
    request.delete(`${PARTNERS_URL}/${id}`);

export const updatePartnerService = (
    id: number,
    params: any,
): Promise<string> => request.put(`${PARTNERS_URL}/${id}`, { ...params });

export const updatePartnerStatus = ({
    id,
    isEnabled,
}: {
    id: number;
    isEnabled: boolean;
}): Promise<string> =>
    request.patch(`${PARTNERS_URL}/${id}/updateStatus?isEnabled=${isEnabled}`);
