import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchEvents,
    resetFilters,
    selectFilters,
    setStringFilter,
    StringFilterTypes,
} from 'ducks/events';
import { FormGrid, SelectComponent } from 'components/common';
import {
    BikeAndVaeEventTypes,
    CommonEventTypes,
    ScooterEventTypes,
    SourceTypes,
} from 'ducks/events/types';
import { DateTimeInput } from '../../common/DateTimeInput';
import moment, { isMoment, Moment } from 'moment';
import { getDisabledTime } from '../../../tools/date';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';
import { selectCurrentVehicle } from '../../../ducks/vehicles/common';
import { VehicleTypes } from '../../../ducks/vehicles/common/types';

export const EventsToolbarComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentVehicle = useSelector(selectCurrentVehicle);
    const isScooter = currentVehicle?.type === VehicleTypes.SCOOTER;

    const { eventTypes, startTime, sourceTypes, endTime } =
        useSelector(selectFilters);

    const eventSourceOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...Object.values(SourceTypes).map(source => ({
                label: t(`eventSource.${source}`),
                value: source,
            })),
        ],
        [t],
    );
    const eventTypeOptions = React.useMemo(() => {
        const events = [
            {
                label: '-',
                value: '',
            },
            ...Object.values(CommonEventTypes).map(type => ({
                label: t(`eventTypes.${type}`),
                value: type,
            })),
        ];

        if (isScooter) {
            events.push(
                ...Object.values(ScooterEventTypes).map(type => ({
                    label: t(`eventTypes.${type}`),
                    value: type,
                })),
            );
        } else {
            events.push(
                ...Object.values(BikeAndVaeEventTypes).map(type => ({
                    label: t(`eventTypes.${type}`),
                    value: type,
                })),
            );
        }

        return events;
    }, [t, isScooter]);

    const getCommonError = useCallback(time => {
        if (!!time && !isMoment(moment(time))) {
            return 'errors.incorrectTimeFormat';
        }
        if (moment(time).isAfter(new Date())) {
            return 'errors.dateAfterToday';
        }
        return '';
    }, []);

    const endTimeError = useMemo(() => {
        if (
            startTime &&
            endTime &&
            moment(endTime).isBefore(moment(startTime))
        ) {
            return 'errors.endTime';
        }
        return getCommonError(endTime);
    }, [startTime, endTime, getCommonError]);

    const startTimeError = useMemo(() => {
        return getCommonError(moment(startTime));
    }, [startTime, getCommonError]);

    const handleChangeTime = React.useCallback(
        (e: Moment | string, filterType: StringFilterTypes) => {
            return dispatch(
                setStringFilter({
                    filterType: filterType,
                    value: moment(e).toISOString(),
                }),
            );
        },
        [dispatch],
    );

    const isEndTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                min: moment(startTime).startOf('day'),
                max: moment().endOf('day'),
            }),
        [startTime],
    );

    const isStartTimeDisabled = useCallback(
        (e: Moment) =>
            getDisabledTime({
                current: e,
                max: moment().endOf('day'),
            }),
        [],
    );

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        dispatch(fetchEvents());
    }, [dispatch]);

    return (
        <FormGrid columnAmount={5} rowHeight="20px" padding="10px 0">
            <SelectComponent
                label={t('events.type')}
                onChange={e =>
                    dispatch(
                        setStringFilter({
                            filterType: StringFilterTypes.EventTypes,
                            value: e.target.value,
                        }),
                    )
                }
                value={eventTypes || ''}
                options={eventTypeOptions}
            />
            <SelectComponent
                label={t('events.sourceType')}
                onChange={e =>
                    dispatch(
                        setStringFilter({
                            filterType: StringFilterTypes.SourceTypes,
                            value: e.target.value,
                        }),
                    )
                }
                value={sourceTypes || ''}
                options={eventSourceOptions}
            />
            <DateTimeInput
                id="startDate"
                value={startTime || ''}
                onChange={e => handleChangeTime(e, StringFilterTypes.StartTime)}
                label={t('tripList.startDate')}
                getDisabledTime={isStartTimeDisabled}
                hasError={!!startTimeError}
                helperText={t(startTimeError)}
            />
            <DateTimeInput
                id="endDate"
                value={endTime || ''}
                onChange={e => handleChangeTime(e, StringFilterTypes.EndTime)}
                label={t('tripList.endDate')}
                getDisabledTime={isEndTimeDisabled}
                hasError={!!endTimeError}
                helperText={t(endTimeError)}
            />
            <Box marginTop="10px">
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </FormGrid>
    );
};
