import React from 'react';
import { useDispatch } from 'react-redux';
import { setLockHasUnsavedChanges, importLocks } from 'ducks/locks';
import { ImportComponent } from 'components/common/ImportComponent';

export const ImportLocksComponent: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = React.useState<
        File | undefined | null
    >();

    React.useEffect(() => {
        selectedFile && dispatch(setLockHasUnsavedChanges(true));
    }, [dispatch, selectedFile]);

    const handleImport = React.useCallback(() => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile, selectedFile.name);
            dispatch(importLocks({ file: formData }));
        }
    }, [dispatch, selectedFile]);

    return (
        <ImportComponent
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            handleImport={handleImport}
        />
    );
};
