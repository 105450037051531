import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    fetchCompanies,
    fetchCompaniesSuccess,
    fetchCompaniesError,
    inviteUsersSuccess,
    inviteUsersError,
    inviteUsers,
    fetchCompany,
    fetchCompanyError,
    fetchCompanySuccess,
    importInviteUsers,
    importInviteUsersSuccess,
    importInviteUsersError,
    assignServiceToCompanySuccess,
    assignServiceToCompany,
    assignServiceToCompanyError,
    unlinkCompanySuccess,
    unlinkCompany,
    unlinkCompanyError,
    fetchAvailableVehicles,
    fetchAvailableVehiclesSuccess,
    fetchAvailableVehiclesError,
    fetchInvitations,
    fetchInvitationsSuccess,
    fetchInvitationsError,
    setInvitationPage,
    createCompany,
    createCompanySuccess,
    createCompanyError,
    setActiveCompanyTab,
    setPage,
    setSort,
    setStringFilter,
    exportCompanies,
    exportCompaniesSuccess,
    exportCompaniesError,
    deleteCompany,
    deleteCompanySuccess,
    deleteCompanyError,
    updateCompany,
    updateCompanySuccess,
    updateCompanyError,
    anonymizeCompany,
    anonymizeCompanySuccess,
    anonymizeCompanyError,
    fetchAllCompaniesSuccess,
    fetchAllCompaniesError,
    fetchAllCompanies,
    fetchEmailRecipients,
    fetchEmailRecipientsSuccess,
    fetchEmailRecipientsError,
    addEmailRecipient,
    addEmailRecipientSuccess,
    addEmailRecipientError,
    deleteEmailRecipient,
    deleteEmailRecipientSuccess,
    deleteEmailRecipientError,
    resendInvitation,
    resendInvitationSuccess,
    resendInvitationError,
    deleteInvitationError,
    deleteInvitationSuccess,
    deleteInvitation,
} from './companySlice';
import {
    getCompanies,
    getCompany,
    postInviteUsers,
    postImportInviteUsers,
    assignService,
    getFreeVehicles,
    getInvitations,
    postCompany,
    exportCompaniesService,
    deleteCompanyService,
    updateCompanyService,
    anonymizeCompanyService,
    getAllCompanies,
    unlinkCompanyService,
    getEmailRecipients,
    addEmailRecipientService,
    deleteEmailRecipientService,
    resendInvitationService,
    deleteInvitationService,
} from './service';
import {
    Company,
    Content,
    FetchedRecipient,
    FreeVehicle,
    Invitation,
    ServiceInvitationsResponse,
    ServiceResponse,
} from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { mapFetchedPagination, mapPagination } from '../common/pagination';
import { selectInvitationPagination } from './selectors';
import { mapById, mapFilters } from '../common/mappers';
import { Filters } from './types';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { Pagination, Sort } from '../common/types';
import { mapSorting } from '../common/mappers';
import { downloadBlob } from '../../tools/file';
import { closeModal, Modals, openModal, setNotification } from '../ui';
import { NotificationTypes } from '../ui/types';
import { RequestError } from 'helpers/request';

function* fetchAvailableVehiclesSaga({ payload }: PayloadAction<number>) {
    try {
        const data: FreeVehicle[] = yield call(getFreeVehicles, payload);
        yield put(fetchAvailableVehiclesSuccess(mapById(data)));
    } catch (error) {
        yield put(fetchAvailableVehiclesError());
    }
}

function* fetchCompaniesSaga() {
    try {
        const sort: Sort = yield select(selectSort);
        const filters: Filters = yield select(selectFilters);
        const pagination: Pagination = yield select(selectPagination);
        const data: ServiceResponse = yield call(getCompanies, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const { allIds, byId } = mapById(data.content);
        const fetchedPagination = mapFetchedPagination(data);

        yield put(
            fetchCompaniesSuccess({
                companyIds: allIds,
                companiesById: byId,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchCompaniesError());
    }
}
function* fetchCompanySaga() {
    try {
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        const data: Content = yield call(getCompany, companyId);
        yield put(
            fetchCompanySuccess({
                company: {
                    ...data.company,
                    appLogo: { url: data.company.appLogo },
                    boLogo: { url: data.company.boLogo },
                },
                companyServices: data.services,
                companyManagers: data.managers,
            }),
        );
    } catch (error) {
        yield put(fetchCompanyError({ error: (error as Error)?.message }));
    }
}
function* fetchInvitationsSaga() {
    try {
        const pagination: Pagination = yield select(selectInvitationPagination);
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        const data: ServiceInvitationsResponse = yield call(getInvitations, {
            ...mapPagination(pagination),
            companyId: companyId,
        });
        const fetchedPagination = mapFetchedPagination(data);
        const { allIds, byId } = mapById(data.content);
        yield put(
            fetchInvitationsSuccess({
                invitationIds: allIds,
                invitationsById: byId,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchInvitationsError({ error: (error as Error)?.message }));
    }
}
function* assignServiceToCompanySaga({
    payload: {
        serviceId,
        companyId,
        domainSubscriptionDuration,
        domainRecognition,
    },
}: PayloadAction<{
    serviceId: number;
    companyId: number;
    domainSubscriptionDuration: number;
    domainRecognition: boolean;
}>) {
    try {
        yield call(assignService, {
            serviceId,
            companyId,
            domainSubscriptionDuration,
            domainRecognition,
        });
        yield put(assignServiceToCompanySuccess());
        yield put(fetchCompany());
    } catch (error) {
        yield put(
            assignServiceToCompanyError({ error: (error as Error)?.message }),
        );
    }
}
function* unlinkCompanySaga({
    payload: { serviceId },
}: PayloadAction<{
    serviceId: number;
}>) {
    try {
        yield call(unlinkCompanyService, {
            serviceId,
        });
        yield put(unlinkCompanySuccess());
        yield put(fetchCompany());
    } catch (error) {
        yield put(
            setNotification({
                message: (error as Error)?.message,
                type: NotificationTypes.ERROR,
            }),
        );
        yield put(unlinkCompanyError({ error: (error as Error)?.message }));
    } finally {
        yield put(closeModal());
    }
}
function* importInviteUsersSaga({
    payload,
}: PayloadAction<{ serviceId: number; file: FormData }>) {
    try {
        yield call(postImportInviteUsers, payload);
        yield put(importInviteUsersSuccess());
        yield put(setActiveCompanyTab(2));
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        yield put(
            push(routes.COMPANY.replace(':companyId', String(companyId))),
        );
    } catch (error) {
        yield put(importInviteUsersError({ error: (error as Error)?.message }));
    }
}

function* inviteUsersSaga({ payload }: PayloadAction<Invitation>) {
    try {
        yield call(postInviteUsers, payload);
        yield put(inviteUsersSuccess());
        yield put(setActiveCompanyTab(2));
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        yield put(
            push(routes.COMPANY.replace(':companyId', String(companyId))),
        );
    } catch (error) {
        yield put(inviteUsersError({ error: (error as Error)?.message }));
    }
}

function* createCompanySaga({ payload }: PayloadAction<Company>) {
    try {
        const data: Content = yield call(postCompany, {
            ...payload,
        });

        yield put(
            createCompanySuccess({
                company: data.company,
                companyServices: data.services,
            }),
        );
        yield put(
            push(routes.COMPANY.replace(':companyId', `${data.company.id}`)),
        );
    } catch (error) {
        yield put(createCompanyError({ error: (error as Error)?.message }));
    }
}

function* exportCompaniesSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const { file, fileName } = yield call(exportCompaniesService, {
            ...mapSorting(sort),
            ...mapFilters(filters),
        });
        downloadBlob(file, fileName);
        yield put(exportCompaniesSuccess());
    } catch (error) {
        yield put(exportCompaniesError({ error: (error as Error)?.message }));
    }
}
function* deleteCompanySaga() {
    try {
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        yield call(deleteCompanyService, companyId);
        yield put(deleteCompanySuccess());
        yield put(push(routes.COMPANIES));
    } catch (error) {
        yield put(deleteCompanyError({ error: (error as Error)?.message }));
    }
}

function* updateCompanySaga({ payload }: PayloadAction<Company>) {
    try {
        yield call(updateCompanyService, payload);
        yield put(updateCompanySuccess());
        yield put(fetchCompany());
    } catch (error) {
        yield put(updateCompanyError({ error: (error as Error)?.message }));
    }
}

function* anonymizeCompanySaga() {
    try {
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        yield call(anonymizeCompanyService, companyId);
        yield put(anonymizeCompanySuccess());
        yield put(push(routes.COMPANIES));
    } catch (error) {
        yield put(anonymizeCompanyError({ error: (error as Error)?.message }));
    }
}

function* fetchAllCompaniesSaga() {
    try {
        const data: { id: number }[] = yield call(getAllCompanies);
        const { allIds, byId } = mapById(data);
        yield put(
            fetchAllCompaniesSuccess({
                allIds,
                byId,
            }),
        );
    } catch (error) {
        yield put(fetchAllCompaniesError({ error: (error as Error)?.message }));
    }
}

function* fetchEmailRecipientsSaga() {
    try {
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        const data: {
            email?: string;
            fallonState: boolean;
            lowBattery: boolean;
            shockAlarm: boolean;
            speedTooHigh: boolean;
        }[] = yield call(getEmailRecipients, companyId);
        yield put(fetchEmailRecipientsSuccess({ emailRecipients: data }));
    } catch (error) {
        yield put(
            fetchEmailRecipientsError({ error: (error as Error)?.message }),
        );
    }
}

function* addEmailRecipientSaga({
    payload,
}: PayloadAction<FetchedRecipient[]>) {
    try {
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        yield call(addEmailRecipientService, {
            companyId,
            recipients: payload,
        });
        yield put(deleteEmailRecipientSuccess());
        yield put(closeModal());
        yield put(fetchEmailRecipients());
    } catch (error) {
        yield put(
            deleteEmailRecipientError({ error: (error as Error)?.message }),
        );
    }
}

function* deleteEmailRecipientSaga({
    payload,
}: PayloadAction<{ email: string; type: string }>) {
    try {
        const companyId: number = yield select(
            state => state.company.currentCompanyId,
        );
        yield call(deleteEmailRecipientService, {
            companyId,
            email: payload.email,
            type: payload.type,
        });
        yield put(addEmailRecipientSuccess());
        yield put(fetchEmailRecipients());
    } catch (error) {
        yield put(addEmailRecipientError({ error: (error as Error)?.message }));
    }
}

function* deleteInvitationSaga({ payload }: PayloadAction<number>) {
    try {
        yield call(deleteInvitationService, { invitationId: payload });
        yield put(deleteInvitationSuccess());
    } catch (error) {
        yield put(
            setNotification({
                message: (error as Error)?.message,
                type: NotificationTypes.ERROR,
            }),
        );
        yield put(deleteInvitationError({ error: (error as Error)?.message }));
    } finally {
        yield put(fetchInvitations());
        yield put(closeModal());
    }
}

function* resendInvitationSaga({
    payload,
}: PayloadAction<{
    invitationId: number;
    vehicleId: number;
    serviceId: number;
}>) {
    try {
        yield call(resendInvitationService, {
            invitationId: payload.invitationId,
            vehicleId: payload.vehicleId,
        });
        yield put(resendInvitationSuccess());
        yield put(closeModal());
        yield put(fetchInvitations());
    } catch (error) {
        if ((error as RequestError)?.errorField === 'lldVehicle') {
            yield put(
                openModal({
                    modalType: Modals.CHANGE_VEHICLE,
                    modalProps: {
                        serviceId: payload.serviceId,
                        invitationId: payload.invitationId,
                    },
                }),
            );
        } else {
            yield put(
                resendInvitationError({
                    error: (error as RequestError)?.message,
                }),
            );
        }
    }
}

export default function* companySagas() {
    yield takeLatest(fetchCompanies.type, fetchCompaniesSaga);
    yield takeLatest(inviteUsers.type, inviteUsersSaga);
    yield takeLatest(fetchCompany.type, fetchCompanySaga);
    yield takeLatest(importInviteUsers.type, importInviteUsersSaga);
    yield takeLatest(assignServiceToCompany.type, assignServiceToCompanySaga);
    yield takeLatest(fetchAvailableVehicles.type, fetchAvailableVehiclesSaga);
    yield takeLatest(fetchInvitations.type, fetchInvitationsSaga);
    yield takeLatest(setInvitationPage.type, fetchInvitationsSaga);
    yield takeLatest(createCompany.type, createCompanySaga);
    yield takeLatest(setPage.type, fetchCompaniesSaga);
    yield takeLatest(setSort.type, fetchCompaniesSaga);
    yield takeLatest(setStringFilter.type, fetchCompaniesSaga);
    yield takeLatest(exportCompanies.type, exportCompaniesSaga);
    yield takeLatest(deleteCompany.type, deleteCompanySaga);
    yield takeLatest(updateCompany.type, updateCompanySaga);
    yield takeLatest(anonymizeCompany.type, anonymizeCompanySaga);
    yield takeLatest(fetchAllCompanies.type, fetchAllCompaniesSaga);
    yield takeLatest(unlinkCompany.type, unlinkCompanySaga);
    yield takeLatest(fetchEmailRecipients.type, fetchEmailRecipientsSaga);
    yield takeLatest(addEmailRecipient.type, addEmailRecipientSaga);
    yield takeLatest(deleteEmailRecipient.type, deleteEmailRecipientSaga);
    yield takeLatest(resendInvitation.type, resendInvitationSaga);
    yield takeLatest(deleteInvitation.type, deleteInvitationSaga);
}
