import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetFilters,
    selectFilters,
    selectSort,
    selectIsLoading,
} from 'ducks/vehicleTelemetryEvents';
import { useTranslation } from 'react-i18next';
import { TableComponent } from '../../../common/Table';
import { getColumns } from './columns';
import { selectTelemetryEventsTableData } from 'ducks/vehicleTelemetryEvents/selectors';
import { Pagination } from '../Common/Pagination';
import { FullScreenLoaderComponent } from '../../../common/Loader/FullScreenLoader';
import { ScooterToolbar } from './Toolbar';

export const ScooterTelemetryEvents = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, [dispatch]);

    const data = useSelector(selectTelemetryEventsTableData);
    const { messageCodes } = useSelector(selectFilters);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    const columns = useMemo(() => {
        return messageCodes ? getColumns(t, dispatch, messageCodes) : [];
    }, [t, dispatch, messageCodes]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                toolbar={ScooterToolbar}
                columns={columns}
                pagination={Pagination}
                data={data}
                sort={sort}
                title={t('scooterTelemetryEvents.title')}
            />
        </>
    );
};
