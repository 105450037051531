import {
    TelemetryEventsResponse,
    Accumulator,
    ScooterTelemetryEventsType,
    Events,
} from './types';
import { camelizeObject } from 'tools/camelize';

export const mapFetchedTelemetryEvents = <
    T extends ScooterTelemetryEventsType,
>({
    content,
}: TelemetryEventsResponse<T>): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, telemetryEvent) => {
            const { fields, msgCode } = telemetryEvent;
            let preparedFields = fields;
            if (msgCode === ScooterTelemetryEventsType.batteryLevel) {
                preparedFields = camelizeObject(
                    fields,
                ) as Events[typeof msgCode];
            }

            return {
                telemetryEventsById: {
                    ...acc.telemetryEventsById,
                    [telemetryEvent.id]: {
                        ...telemetryEvent,
                        fields: preparedFields,
                    },
                },
                telemetryEventIds: [
                    ...acc.telemetryEventIds,
                    telemetryEvent.id,
                ],
            };
        },
        { telemetryEventIds: [], telemetryEventsById: {} },
    );
};
