import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    resetFilters,
    selectSort,
    selectIsLoading,
    fetchVehicleTelemetryEvents,
} from 'ducks/vehicleTelemetryEvents';
import { useTranslation } from 'react-i18next';
import { TableComponent } from '../../../common/Table';
import { getColumns } from './columns';
import { Pagination } from 'components/pages/TelemetryEvents/Common/Pagination';
import { BikesAndVaeToolbar } from './Toolbar';
import { selectTelemetryEventsTableData } from 'ducks/vehicleTelemetryEvents/selectors';
import { FullScreenLoaderComponent } from '../../../common/Loader/FullScreenLoader';
import { FetchedTelemetryEvent } from 'ducks/vehicleTelemetryEvents/types';
import { DetailsPanel } from './DetailsPanel';

export const BikeAndVaeTelemetryEvents = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVehicleTelemetryEvents());
        return () => {
            dispatch(resetFilters());
        };
    }, [dispatch]);

    const data = useSelector(selectTelemetryEventsTableData);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    const columns = useMemo(() => {
        return getColumns(t, dispatch);
    }, [t, dispatch]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                detailPanel={({
                    rowData,
                }: {
                    rowData: FetchedTelemetryEvent;
                }) => <DetailsPanel rowProps={rowData} />}
                toolbar={BikesAndVaeToolbar}
                columns={columns}
                pagination={Pagination}
                data={data}
                sort={sort}
                title={t('bikeAndVaeTelemetryEvents.title')}
            />
        </>
    );
};
