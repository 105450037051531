import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    fetchZones,
    fetchZonesSuccess,
    fetchZonesError,
    createZone,
    createZoneSuccess,
    createZoneError,
    setFilter,
    setStringFilter,
    setSort,
    setPage,
    fetchAllZones,
    fetchAllZonesSuccess,
    fetchAllZonesError,
    fetchZone,
    fetchZoneError,
    fetchZoneSuccess,
    updateZoneError,
    updateZoneSuccess,
    updateZone,
    deleteZone,
    deleteZoneError,
    deleteZoneSuccess,
} from './zoneSlice';
import { mapFetchedZones, mapZone } from './mapper';
import {
    getAllZones,
    getZone,
    getZones,
    postZone,
    putZone,
    deleteZoneService,
} from './service';
import { ServiceResponse, Zone, Filters, Content } from './types';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { selectFilters, selectPagination, selectSort } from './selectors';
import { Pagination, Sort } from '../common/types';
import { mapFetchedPagination, mapPagination } from '../common/pagination';
import { mapById, mapFilters, mapSorting } from '../common/mappers';

function* zoneListSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const pagination: Pagination = yield select(selectPagination);
        const data: ServiceResponse = yield call(getZones, {
            ...mapSorting(sort),
            ...mapPagination(pagination),
            ...mapFilters(filters),
        });
        const fetchedPagination = mapFetchedPagination(data);

        const { zoneIds, zonesById } = mapFetchedZones(data.content);
        yield put(
            fetchZonesSuccess({
                zoneIds,
                zonesById,
                pagination: fetchedPagination,
            }),
        );
    } catch (error) {
        yield put(fetchZonesError({ error }));
    }
}

function* fetchAllZonesSaga() {
    try {
        const data: Zone[] = yield call(getAllZones);
        const { allIds, byId } = mapById(data);
        yield put(
            fetchAllZonesSuccess({
                allIds,
                byId,
            }),
        );
    } catch (error) {
        yield put(fetchAllZonesError({ error: (error as Error)?.message }));
    }
}

function* createZoneSaga({ payload }: PayloadAction<Zone>) {
    try {
        yield call(postZone, payload);
        yield put(createZoneSuccess());
        yield put(push(routes.ZONES));
    } catch (error) {
        yield put(createZoneError({ error: (error as Error)?.message }));
    }
}
function* updateZoneSaga({ payload }: PayloadAction<Zone>) {
    try {
        yield call(putZone, payload);
        yield put(updateZoneSuccess());
        yield put(push(routes.ZONES));
    } catch (error) {
        yield put(updateZoneError({ error: (error as Error)?.message }));
    }
}

function* fetchZoneSaga({ payload }: PayloadAction<number>) {
    try {
        const data: Content = yield call(getZone, payload);
        yield put(fetchZoneSuccess(mapZone(data)));
    } catch (error) {
        yield put(fetchZoneError({ error: (error as Error)?.message }));
    }
}

function* deleteZoneSaga({ payload }: PayloadAction<number>) {
    try {
        yield call(deleteZoneService, payload);
        yield put(deleteZoneSuccess());
        yield put(push(routes.ZONES));
    } catch (error) {
        yield put(deleteZoneError({ error: (error as Error)?.message }));
    }
}

export default function* zoneSagas() {
    yield takeLatest(fetchZones.type, zoneListSaga);
    yield takeLatest(fetchAllZones.type, fetchAllZonesSaga);
    yield takeLatest(setSort.type, zoneListSaga);
    yield takeLatest(setPage.type, zoneListSaga);
    yield takeLatest(setStringFilter.type, zoneListSaga);
    yield takeLatest(setFilter.type, zoneListSaga);
    yield takeLatest(createZone.type, createZoneSaga);
    yield takeLatest(fetchZone.type, fetchZoneSaga);
    yield takeLatest(updateZone.type, updateZoneSaga);
    yield takeLatest(deleteZone.type, deleteZoneSaga);
}
