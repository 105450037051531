import { createStyles, Accordion, Theme, withStyles } from '@material-ui/core';

export const StyledAccordion = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&::before': {
                content: 'none',
            },
            borderRadius: '25px',
            padding: '25px',
            margin: '25px',
        },
        expanded: {
            border: `1px solid ${theme.palette.primary.main}`,
        },
    }),
)(Accordion);
