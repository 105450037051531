import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureAgreements } from 'ducks/agreements/selectors';
import { updateCGAU } from 'ducks/agreements';
import { FutureForm } from './FutureForm';
import { useTranslation } from 'react-i18next';
import { Agreement, InitialAgreement } from 'ducks/agreements/types';
import { Languages } from 'ducks/boUsers/types';

export const FutureCGAUForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cgau } = useSelector(selectFeatureAgreements);

    const initialValues = useMemo(
        () =>
            cgau && Object.keys(cgau).length
                ? cgau
                : { links: [{ language: Languages.EN, url: '' }], version: '' },
        [cgau],
    );

    const handleUpdateCGAU = useCallback(
        (values: Agreement | InitialAgreement) => {
            dispatch(updateCGAU(values));
        },
        [dispatch],
    );

    return (
        <FutureForm
            title={t('legalDocuments.cgau')}
            initialValues={initialValues}
            handleUpdate={handleUpdateCGAU}
            prefix="cgau"
        />
    );
};
