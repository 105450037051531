import { RootState } from '../root';

export const selectUsers = (state: RootState) => {
    return { usersById: state.user.usersById, userIds: state.user.userIds };
};

export const selectFilters = (state: RootState) => state.user.filters;
export const selectSort = (state: RootState) => state.user.sort;
export const selectPagination = (state: RootState) => state.user.pagination;
export const selectUserError = (state: RootState) => state.user.error;
export const selectUserDocumentError = (state: RootState) =>
    state.user.documentError;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectIsLoading = (state: RootState) => state.user.loading;
export const selectCurrentUserDocumentsNames = (state: RootState) =>
    state.user.currentUser?.user.documents;

export const selectCurrentUserDocumentsById = (
    state: RootState,
    filename: string,
) => state.user.documents[filename];
export const selectIsUserLoading = (state: RootState) => state.user.loading;
export const selectUserHasUnsavedChanges = (state: RootState) =>
    state.user.userHasUnsavedChanges;

export const selectUserProfileErrorById = (
    state: RootState,
    profileId: number,
) => state.user.userProfileError[profileId];

export const selectProfileHasUnsavedChanges = (state: RootState) =>
    state.user.profileHasUnsavedChanges;
