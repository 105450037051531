import React, { Ref, useState, useCallback } from 'react';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { ButtonComponent } from 'components/common/Button';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'components/common/ModalContainer/styles';
import { InputComponent } from 'components/common/Input';
import { requiredNumber } from 'tools/validationRules';
import { PenaltyConfirm } from './UserProfiles';

interface ModalProps {
    isOpen: boolean;
    message: string;
    handleClose: () => void;
    handleConfirm: (e: PenaltyConfirm) => void;
    confirmButtonName?: string;
}

// eslint-disable-next-line react/display-name
export const AddPenaltyModal = React.forwardRef(
    (
        {
            handleClose,
            handleConfirm: onConfirm,
            message,
            confirmButtonName,
        }: ModalProps,
        ref: Ref<HTMLDivElement>,
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();

        const [comment, setComment] = useState('');
        const [amount, setAmount] = useState('');
        const [tripId, setTripId] = useState('');

        const handleChangeAmount = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setAmount(e.target.value);
            },
            [],
        );

        const handleChangeTripId = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setTripId(e.target.value);
            },
            [],
        );

        const handleChangeComment = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                setComment(e.target.value);
            },
            [],
        );

        const handleConfirm = useCallback(() => {
            onConfirm({ amount, comment, tripId });
        }, [comment, onConfirm, amount, tripId]);

        return (
            <div ref={ref} className={classes.modal}>
                <Box textAlign="right">
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography variant="h2">{`${t(message)}?`}</Typography>
                <Box width="300px" margin="0 auto">
                    <InputComponent
                        value={amount}
                        label={`${t('usersList.amount')}*`}
                        onChange={handleChangeAmount}
                        hasError={amount ? !!requiredNumber(amount) : !!amount}
                        helperText={amount ? requiredNumber(amount) : ''}
                        type="number"
                    />
                    <InputComponent
                        value={comment}
                        label={t('usersList.comment')}
                        onChange={handleChangeComment}
                    />
                    <InputComponent
                        value={tripId}
                        label={`${t('usersList.tripId')}*`}
                        onChange={handleChangeTripId}
                        hasError={tripId ? !!requiredNumber(tripId) : !!tripId}
                        helperText={tripId ? requiredNumber(tripId) : ''}
                        type="number"
                    />
                </Box>
                <Box textAlign="center" marginTop="30px">
                    <ButtonComponent
                        handleClick={handleClose}
                        text={t('cancel')}
                    />
                    &emsp;
                    <ButtonComponent
                        handleClick={handleConfirm}
                        text={confirmButtonName || t('confirm')}
                        isDisabled={
                            !!requiredNumber(amount) || !!requiredNumber(tripId)
                        }
                    />
                </Box>
            </div>
        );
    },
);
