import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter, requestCancelBooking } from 'ducks/booking';
import { TableComponent } from 'components/common/Table';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import {
    selectBookingTableData,
    selectSort,
    selectIsLoading,
} from 'ducks/booking/selectors';
import Toolbar from './Toolbar';
import Pagination from './Pagination';
import {
    BookingStatus,
    FetchedVehicleBooking,
    FilterTypes,
} from 'ducks/booking/types';
import { useParams } from 'react-router';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';
import SearchBar from './SearchBar';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

export const VehicleBookingList: React.FC = () => {
    const dispatch = useDispatch();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const { t } = useTranslation();
    const columns = getColumns(t, dispatch);
    const tableData = useSelector(selectBookingTableData);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    const accessToPages = useSelector(selectAccessToPages);
    const allowedToUpdate = accessToPages[Permissions.TRIP_UPDATE_STATUS];

    useEffect(() => {
        if (vehicleId) {
            dispatch(
                setFilter({
                    filterType: FilterTypes.VehicleId,
                    value: vehicleId,
                }),
            );
        }
    }, [vehicleId, dispatch]);

    const handleCancelBooking = useCallback(
        id => {
            dispatch(requestCancelBooking(id));
        },
        [dispatch],
    );

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                toolbar={Toolbar}
                columns={columns}
                pagination={Pagination}
                data={tableData}
                title={t('vehicles.bookingList')}
                sort={sort}
                searchComponent={SearchBar}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: t('cancel'),
                        onClick: (event, rowData: FetchedVehicleBooking) =>
                            allowedToUpdate &&
                            rowData &&
                            rowData.booking.status === BookingStatus.BOOKED &&
                            handleCancelBooking(rowData.booking.id),
                    },
                ]}
            />
        </>
    );
};
