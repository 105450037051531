import React from 'react';
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import { ButtonComponent } from '../Button';
import {
    Typography,
    Box,
    ListItem,
    List,
    Drawer,
    SvgIcon,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MenuConfig } from './config';
import { useSelector } from 'react-redux';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import { useOutsideClicker } from 'hooks/useOutsideClicker';
import { useStyles } from './styles';
import {
    selectAccessToPages,
    selectIsSuperAdmin,
    selectIsManager,
} from 'ducks/auth';
import { Link } from 'react-router-dom';
//DISCLAIMER: If you think that there is some * happening here, I have an explanation: we need to render both closed and opened menu to predict its size. And also we need to prevent 1) redirect when menu is closed 2) closing the menu by clicking one of the links.
export const Menu: React.FC = () => {
    const menuRef = React.useRef<HTMLDivElement>(null);

    const classes = useStyles();
    const [openTab, setOpenTab] = React.useState<number | null>(null);
    const [isMenuOpen, toggleMenu] = React.useState(false);
    const toggleDrawer = () => toggleMenu(!isMenuOpen);
    useOutsideClicker(menuRef, toggleDrawer, isMenuOpen);

    const accessToPages = useSelector(selectAccessToPages);
    const isSuperAdmin = useSelector(selectIsSuperAdmin);
    const isManager = useSelector(selectIsManager);
    const { t } = useTranslation();
    const menuConfig = React.useMemo(
        () => MenuConfig(accessToPages, isSuperAdmin, isManager),
        [accessToPages, isSuperAdmin, isManager],
    );
    return (
        <Drawer
            ref={menuRef}
            variant="permanent"
            className={clsx(classes.drawer)}
            classes={{
                paper: clsx(classes.paper, {
                    [classes.drawerClose]: !isMenuOpen,
                }),
            }}>
            <List className={classes.div}>
                <div className={classes.absoluteDiv}>
                    <div className={classes.div}>
                        <Typography
                            className={clsx(classes.verticalButton, {
                                [classes.hidden]: isMenuOpen,
                            })}>
                            {t('menu.open')}
                        </Typography>
                    </div>
                </div>
                <Box
                    className={clsx({
                        [classes.hidden]: !isMenuOpen,
                    })}
                    onClick={isMenuOpen ? undefined : toggleDrawer}>
                    <ListItem className={classes.list}>
                        {menuConfig.map(({ name, content, visible }, index) => (
                            <Box key={name} width="100%" mb="10px">
                                {visible && (
                                    <ListItem
                                        button
                                        onClick={() =>
                                            isMenuOpen
                                                ? setOpenTab(
                                                      openTab === index
                                                          ? null
                                                          : index,
                                                  )
                                                : undefined
                                        }>
                                        <ListItemText>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center">
                                                <Typography variant="h3">
                                                    <span>{t(name)}</span>
                                                </Typography>
                                                {openTab === index ? (
                                                    <SvgIcon color="primary">
                                                        <ArrowDropUp />
                                                    </SvgIcon>
                                                ) : (
                                                    <SvgIcon color="primary">
                                                        <ArrowDropDown />
                                                    </SvgIcon>
                                                )}
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                )}
                                {openTab === index &&
                                    content.map(
                                        ({ title, rout, visible }) =>
                                            visible && (
                                                <Box key={title} ml="20px">
                                                    <Link
                                                        to={rout}
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                            color: 'inherit',
                                                        }}>
                                                        <ListItem button>
                                                            <ListItemText
                                                                primary={t(
                                                                    title,
                                                                )}
                                                            />
                                                        </ListItem>
                                                    </Link>
                                                </Box>
                                            ),
                                    )}
                            </Box>
                        ))}
                        <ButtonComponent
                            handleClick={isMenuOpen ? toggleDrawer : undefined}
                            text={t('menu.close')}
                            outlined
                        />
                    </ListItem>
                </Box>
            </List>
        </Drawer>
    );
};
