import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../themes/colors';

export const useStyles = makeStyles(theme => ({
    backButton: {
        color: colors.default,
    },
    tripBox: {
        margin: 10,
        padding: 10,
        backgroundColor: colors.white,
        borderRadius: 25,
        border: `2px solid`,
        borderColor: theme.palette.primary.main,
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        display: 'flex',
        flexDirection: 'column',
    },
    tripRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    tripColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    fullWidth: {
        width: '100%',
    },
    companyColumn: {
        width: '40%',
    },
    pausesTitle: {
        margin: '10px 0px 0px 20px',
    },
}));
