import { takeEvery, call, put } from 'redux-saga/effects';
import {
    manageApplicationVersion,
    manageApplicationVersionFailure,
    manageApplicationVersionSuccess,
} from './appVersionSlice';
import { postAppVersion } from './service';
import { AppVersionType } from './types';
import { push } from 'connected-react-router';
import { routes } from '../../constants/common';
import { PayloadAction } from '@reduxjs/toolkit';

function* manageAppVersionSaga({ payload }: PayloadAction<AppVersionType>) {
    try {
        yield call(postAppVersion, payload);
        yield put(manageApplicationVersionSuccess());
        yield put(push(routes.MAIN_PAGE));
    } catch (error) {
        yield put(
            manageApplicationVersionFailure({
                error: (error as Error)?.message,
            }),
        );
    }
}

export default function* appVersionSagas() {
    yield takeEvery(manageApplicationVersion.type, manageAppVersionSaga);
}
