import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { ScooterMaintenance } from 'ducks/scooterMaintenance/types';
import { DetailPanel } from 'components/common/Table/DetailPanel';

interface Interface {
    rowProps: ScooterMaintenance;
    isScooter: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        column: {
            display: 'flex',
            flexDirection: 'row',
            flex: '1 100%',
            wordBreak: 'break-word',
        },
        fieldName: {
            fontWeight: 'bold',
            marginRight: '10px',
        },
    }),
);

const DetailsPanel: React.FC<Interface> = ({
    rowProps: { description, duration, created },
    isScooter,
}: Interface) => {
    const { column, fieldName } = useStyles();
    const { t } = useTranslation();

    return (
        <DetailPanel>
            <Box display="flex" padding="15px" justifyContent="space-between">
                {!isScooter && (
                    <div className={column}>
                        <div className={fieldName}>{t('date')}:</div>
                        <div>
                            {created ? new Date(created).toLocaleString() : '-'}
                        </div>
                    </div>
                )}
                <div className={column}>
                    <div className={fieldName}>
                        {t('scooterMaintenance.duration')}:
                    </div>
                    <div>{duration || '-'}</div>
                </div>
                <div className={column}>
                    <div className={fieldName}>
                        {t('scooterMaintenance.description')}:
                    </div>
                    <div>{description || '-'}</div>
                </div>
            </Box>
        </DetailPanel>
    );
};

export default React.memo(DetailsPanel);
