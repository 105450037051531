import React, { useEffect, useMemo, useCallback } from 'react';
import { TableComponent } from '../../common/Table';
import { getColumns } from './columns';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Toolbar } from './Toolbar';
import { SearchToolbar } from './SearchToolbar';
import { ScooterMaintenance } from 'ducks/scooterMaintenance/types';
import {
    fetchScooterMaintenance,
    FilterTypes,
    resetFilters,
    setFilter,
} from 'ducks/scooterMaintenance';
import { Pagination } from './Pagination';
import {
    selectMaintenanceTableData,
    selectIsLoading,
} from 'ducks/scooterMaintenance/selectors';
import { useParams } from 'react-router';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';
import { selectCurrentVehicle } from 'ducks/vehicles/common';
import { VehicleTypes } from 'ducks/vehicles/common/types';
import DetailsPanel from './DetailsPanel';

export const ScooterMaintenanceList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { vehicleId } = useParams<{ vehicleId: string }>();
    const currentVehicle = useSelector(selectCurrentVehicle);
    const isScooter = currentVehicle?.type === VehicleTypes.SCOOTER;
    const columns = useMemo(() => {
        return getColumns(t, isScooter);
    }, [t, isScooter]);
    const data = useSelector(selectMaintenanceTableData);
    const loading = useSelector(selectIsLoading);

    useEffect(() => {
        if (vehicleId) {
            dispatch(
                setFilter({
                    filterType: FilterTypes.VehicleIds,
                    value: vehicleId,
                }),
            );
        } else {
            dispatch(fetchScooterMaintenance());
        }
    }, [vehicleId, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetFilters());
        };
    }, [dispatch]);

    const getDetails = useCallback(
        ({ rowData }: { rowData: ScooterMaintenance }) => (
            <DetailsPanel rowProps={rowData} isScooter={isScooter} />
        ),
        [isScooter],
    );

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                detailPanel={getDetails}
                toolbar={Toolbar}
                searchComponent={SearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={data}
                title={t('scooterMaintenance.vehicleTitle')}
            />
        </>
    );
};
