import { TableComponent } from 'components/common';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSort,
    selectEventsTableData,
    fetchEvents,
    resetFilters,
    selectIsLoading,
} from 'ducks/events';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import { EventsToolbarComponent } from './Toolbar';
import { EventsSearchToolbar } from './EventsSearchToolbar';
import { EventsPagination } from './Pagination';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

export const ScootersEventsListComponent = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchEvents());
        return () => {
            dispatch(resetFilters());
        };
    }, [dispatch]);

    const { t } = useTranslation();

    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    const columns = React.useMemo(() => getColumns(t, dispatch), [t, dispatch]);

    const tableData = useSelector(selectEventsTableData);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                toolbar={EventsToolbarComponent}
                searchComponent={EventsSearchToolbar}
                columns={columns}
                pagination={EventsPagination}
                data={tableData}
                title={t('events.title')}
                sort={sort}
            />
        </>
    );
};
