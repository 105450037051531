import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { fetchConfig, selectLockConfig } from 'ducks/locks';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetailPanel } from '../../common/Table/DetailPanel';
import configMapper from './helpers';

interface Lock {
    lockId: number;
}

export const LockConfig: React.FC<Lock> = ({ lockId }: Lock) => {
    const dispatch = useDispatch();
    const currentLockConfig = useSelector(selectLockConfig);

    React.useEffect(() => {
        dispatch(fetchConfig(lockId));
    }, [dispatch, lockId]);

    const { t } = useTranslation();

    return (
        <Box m="20px" mt="30px" textAlign="center">
            {currentLockConfig &&
                Object.keys(currentLockConfig).map(configKey => (
                    <Box key={configKey} m="20px">
                        <Box m="20px">
                            <Typography variant="h3">
                                {t(`locks.${configKey}`)}
                            </Typography>
                        </Box>
                        <DetailPanel>
                            {Object.keys(currentLockConfig[configKey]).map(
                                key => (
                                    <Box
                                        key={key}
                                        display="flex"
                                        padding="15px"
                                        justifyContent="space-between">
                                        <Typography variant="body1">
                                            {key}
                                        </Typography>
                                        <Typography variant="body1">
                                            {configMapper(
                                                key,
                                                currentLockConfig[configKey][
                                                    key
                                                ],
                                                t,
                                            )}
                                        </Typography>
                                    </Box>
                                ),
                            )}
                        </DetailPanel>
                    </Box>
                ))}
        </Box>
    );
};
