import React, { useCallback } from 'react';
import { ToggleButtonComponent } from '../../common/ToggleButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchServices,
    FilterTypes,
    resetFilters,
    selectFilters,
    setFilter,
    setStringFilter,
    StringFilterTypes,
} from 'ducks/services';
import { FormGrid, SelectComponent } from '../../common';
import { UsageTypes } from 'ducks/services/types';
import { selectAllCompaniesById, selectAllCompanyIds } from 'ducks/companies';
import { Box } from '@material-ui/core';
import { ResetFiltersButton } from 'components/common/ResetFiltersButton';

export const ServiceToolbarComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleToggleFilter = (type: FilterTypes) => {
        dispatch(setFilter(type));
    };
    const filters = useSelector(selectFilters);
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const companyOptions = React.useMemo<
        { label: string; value: number | string }[]
    >(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );
    const serviceOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            {
                label: t('serviceList.active'),
                value: 'true',
            },
            {
                label: t('serviceList.inactive'),
                value: 'false',
            },
        ],
        [t],
    );
    const usageTypeOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            {
                label: t('serviceList.SHORT_TERM_USAGE'),
                value: UsageTypes.SHORT_TERM_USAGE,
            },
            {
                label: t('serviceList.LONG_TERM_USAGE'),
                value: UsageTypes.LONG_TERM_USAGE,
            },
        ],
        [t],
    );

    const handleResetFilters = useCallback(() => {
        dispatch(resetFilters());
        dispatch(fetchServices());
    }, [dispatch]);

    return (
        <Box display="flex" alignItems="center">
            <FormGrid columnAmount={2} padding="10px 0" rowHeight="20px">
                <FormGrid columnAmount={3} padding="0">
                    <SelectComponent
                        label={t('serviceList.companyName')}
                        onChange={e =>
                            dispatch(
                                setStringFilter({
                                    filterType: StringFilterTypes.Company,
                                    value: e.target.value,
                                }),
                            )
                        }
                        value={filters[StringFilterTypes.Company]}
                        options={companyOptions}
                    />
                    <SelectComponent
                        label={t('serviceList.type')}
                        onChange={e =>
                            dispatch(
                                setStringFilter({
                                    filterType: StringFilterTypes.Types,
                                    value: e.target.value,
                                }),
                            )
                        }
                        value={filters[StringFilterTypes.Types]}
                        options={usageTypeOptions}
                    />
                    <SelectComponent
                        label={t('serviceList.status')}
                        onChange={e =>
                            dispatch(
                                setStringFilter({
                                    filterType: StringFilterTypes.Enabled,
                                    value: e.target.value,
                                }),
                            )
                        }
                        value={filters[StringFilterTypes.Enabled]}
                        options={serviceOptions}
                    />
                </FormGrid>
                <FormGrid
                    columnAmount={5}
                    padding="14px 0 0 0"
                    rowHeight="20px">
                    <ToggleButtonComponent
                        text={t('serviceList.station')}
                        handleClick={() =>
                            handleToggleFilter(FilterTypes.StationConnected)
                        }
                        isSelected={filters[FilterTypes.StationConnected]}
                    />
                    <ToggleButtonComponent
                        text={t('serviceList.geofence')}
                        handleClick={() =>
                            handleToggleFilter(FilterTypes.GeofenceEnabled)
                        }
                        isSelected={filters[FilterTypes.GeofenceEnabled]}
                    />
                    <ToggleButtonComponent
                        text={t('serviceList.domainRecognition')}
                        handleClick={() =>
                            handleToggleFilter(FilterTypes.DomainRecognition)
                        }
                        isSelected={filters[FilterTypes.DomainRecognition]}
                    />
                    <ToggleButtonComponent
                        text={t('serviceList.booking')}
                        handleClick={() =>
                            handleToggleFilter(FilterTypes.BookingAllowed)
                        }
                        isSelected={filters[FilterTypes.BookingAllowed]}
                    />
                    <ToggleButtonComponent
                        text={t('serviceList.pause')}
                        handleClick={() =>
                            handleToggleFilter(FilterTypes.PauseAllowed)
                        }
                        isSelected={filters[FilterTypes.PauseAllowed]}
                    />
                </FormGrid>
            </FormGrid>
            <Box>
                <ResetFiltersButton handleResetFilters={handleResetFilters} />
            </Box>
        </Box>
    );
};
