import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrips, resetFilters, setFilter } from 'ducks/trips';
import { TableComponent } from 'components/common/Table';
import { useTranslation } from 'react-i18next';
import { getColumns } from './columns';
import {
    selectIsLoading,
    selectSort,
    selectTrips,
} from 'ducks/trips/selectors';
import { push } from 'connected-react-router';
import { routes } from '../../../constants/common';
import { FetchedTrip, FilterTypes, TripStatuses } from 'ducks/trips/types';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';
import { Container } from './Container';
import { LinkComponent } from '../../common/Link';
import { Box } from '@material-ui/core';

export const TripsList: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const columns = getColumns(t, dispatch, false);
    const { tripsById, tripIds } = useSelector(selectTrips);
    const sort = useSelector(selectSort);
    const loading = useSelector(selectIsLoading);

    React.useEffect(() => {
        dispatch(resetFilters());
        dispatch(
            setFilter({
                filterType: FilterTypes.Statuses,
                value: [TripStatuses.STARTED, TripStatuses.PAUSED],
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchTrips());
    }, [dispatch]);

    const redirectToTrip = (id: number) =>
        dispatch(push(routes.TRIP.replace(':tripId', `${id}`)));

    const redirectToStartedList = useCallback(() => {
        dispatch(resetFilters());
        dispatch(
            setFilter({
                filterType: FilterTypes.Statuses,
                value: [TripStatuses.STARTED, TripStatuses.PAUSED],
            }),
        );
        dispatch(push(routes.TRIPS));
    }, [dispatch]);

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={[
                    {
                        icon: 'visibility',
                        tooltip: t('tripList.seeDetails'),
                        onClick: (event, rowData: FetchedTrip) =>
                            rowData && redirectToTrip(rowData.id),
                    },
                ]}
                onRowClick={(event, rowData: FetchedTrip | undefined) =>
                    rowData && redirectToTrip(rowData.id)
                }
                columns={columns}
                data={tripIds.map(id => ({
                    ...tripsById[id],
                }))}
                pageSize={tripIds.length < 5 ? tripIds.length : 5}
                title={t('homePage.tripsInProgress')}
                sort={sort}
                container={Container}
            />
            <Box>
                <LinkComponent
                    handleClick={redirectToStartedList}
                    text={t('homePage.seeAllTripsInProgress')}
                />
            </Box>
        </>
    );
};
