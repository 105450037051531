import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ButtonComponent,
    FormGrid,
    SelectField,
    TextField,
    ThemedBoxComponent,
} from '../../common';
import { fetchServicesByCompany } from 'ducks/services';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { RootState } from 'ducks/root';
import {
    setPushHasUnsavedChanges,
    selectIsLoading,
    selectPushError,
    selectPushHasUnsavedChanges,
    sendPush,
    resetError,
} from 'ducks/pushes';
import { Prompt } from 'react-router';
import { useNotificationOnPageLeave } from 'hooks/useNotificationOnPageLeave';
import {
    fetchAllCompanies,
    selectAllCompaniesById,
    selectAllCompanyIds,
} from 'ducks/companies';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { FormState } from 'final-form';
import { ErrorComponent } from '../../common/Error';
import { validations } from './validations';
import { Push } from '../../../ducks/pushes/types';
import { useFormMutators } from 'hooks/useFormMutators';

export const PushNotification: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { servicesById, serviceIds } = useSelector(
        (state: RootState) => state.service,
    );
    const pushHasUnsavedChanges = useSelector(selectPushHasUnsavedChanges);
    const error = useSelector(selectPushError);
    const allCompanyIds = useSelector(selectAllCompanyIds);
    const allCompaniesById = useSelector(selectAllCompaniesById);
    const loading = useSelector(selectIsLoading);

    const handleConfirm = React.useCallback(
        (values: Push) => {
            dispatch(sendPush(values));
        },
        [dispatch],
    );

    React.useEffect(() => {
        dispatch(resetError());
        return () => {
            dispatch(setPushHasUnsavedChanges(false));
        };
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(fetchAllCompanies());
    }, [dispatch]);

    const companyOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...allCompanyIds.map(id => ({
                label: allCompaniesById[id].name,
                value: id,
            })),
        ],
        [allCompanyIds, allCompaniesById],
    );
    const [companyId, setCompanyId] = React.useState<number | string | null>(
        null,
    );
    React.useEffect(() => {
        companyId &&
            dispatch(fetchServicesByCompany({ companyId, onlyEnabled: false }));
    }, [companyId, dispatch]);

    const initialValues = React.useMemo(() => ({}), []);

    const serviceOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...serviceIds.map(id => ({
                value: id,
                label: servicesById[id].name,
            })),
        ],
        [serviceIds, servicesById],
    );

    const mutators = useFormMutators();

    const setUnsavedChanges = React.useCallback(
        ({
            pristine,
            submitSucceeded,
            dirtySinceLastSubmit,
        }: FormState<unknown>) =>
            dispatch(
                setPushHasUnsavedChanges(
                    submitSucceeded ? dirtySinceLastSubmit : !pristine,
                ),
            ),
        [dispatch],
    );

    useNotificationOnPageLeave(pushHasUnsavedChanges);

    return (
        <ThemedBoxComponent m="0 25%">
            {loading && <LoaderComponent />}
            <Prompt
                when={pushHasUnsavedChanges}
                message={t('leaveConfirmation')}
            />
            <Form
                onSubmit={handleConfirm}
                initialValues={initialValues}
                mutators={mutators}
                render={({ handleSubmit, valid, values, form }) => (
                    <form onSubmit={handleSubmit}>
                        <Box display="inline-flex">
                            <Typography variant="h1">
                                <p>{t('pushes.sendPush')}</p>
                            </Typography>
                        </Box>
                        <FormSpy
                            subscription={{
                                pristine: true,
                                submitSucceeded: true,
                                dirtySinceLastSubmit: true,
                            }}
                            onChange={setUnsavedChanges}
                        />
                        <FormGrid columnAmount={2}>
                            <SelectField
                                name="companyId"
                                label={t('vehicles.companyName')}
                                options={companyOptions}
                                validators={validations.company}
                                isRequired
                                handleChange={value => {
                                    setCompanyId(value);
                                    form.mutators.resetField({
                                        field: 'serviceId',
                                    });
                                }}
                            />

                            <SelectField
                                disabled={!values.companyId}
                                name="serviceId"
                                label={t('vehicles.services')}
                                options={serviceOptions}
                            />
                            <TextField
                                multiline
                                name="text"
                                validators={validations.text}
                                label={t('pushes.text')}
                                isRequired
                            />
                        </FormGrid>
                        <FormButtonGroup>
                            <ButtonComponent
                                handleClick={handleSubmit}
                                text={t('create')}
                                isDisabled={
                                    loading || !pushHasUnsavedChanges || !valid
                                }
                            />
                        </FormButtonGroup>
                        <ErrorComponent error={error} />
                    </form>
                )}
            />
        </ThemedBoxComponent>
    );
};
