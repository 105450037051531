import React from 'react';
import { FormGrid, SearchComponent } from '../../common';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FilterTypes, setFilter } from 'ducks/scooterMaintenance';

export const SearchToolbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
            setFilter({
                filterType: FilterTypes.Search,
                value: e.target.value,
            }),
        );
    };

    return (
        <FormGrid columnAmount={2} rowHeight="20px" padding="10px 0">
            <SearchComponent
                placeholder={t('scooterMaintenance.vehicleSearch')}
                onChange={handleSearch}
            />
        </FormGrid>
    );
};
