import { takeLatest, call, put, select } from 'redux-saga/effects';
import {
    fetchVehiclesError,
    fetchVehiclesSuccess,
    fetchVehicles,
    updateVehiclesStatus,
    updateVehiclesStatusSuccess,
    updateVehiclesStatusError,
    exportVehicles,
    exportVehiclesError,
    exportVehiclesSuccess,
    createVehicle,
    createVehicleError,
    createVehicleSuccess,
    importVehicles,
    importVehiclesSuccess,
    importVehiclesError,
    fetchVehicleSuccess,
    fetchVehicleError,
    fetchVehicle,
    updateVehicle,
    updateVehicleSuccess,
    updateVehicleError,
    setCurrentVehicleType,
    sendCommand,
    sendCommandError,
    sendCommandSuccess,
    getVehicleState,
    getVehicleStateError,
    getVehicleStateSuccess,
} from './vehicleSlice';
import {
    Content,
    CurrentVehicleState,
    NewVehicle,
    VehicleResponse,
    VehicleStatus,
} from './types';
import { mapFetchedVehicles, mapVehicle } from './mappers';
import {
    selectFilters,
    selectNewStatus,
    selectPagination,
    selectSelectedVehicles,
    selectSort,
    setPage,
    setSort,
    setStringFilter,
} from './';
import { mapPagination, mapSorting, mapFilters } from '../../common/mappers';
import {
    getVehicles,
    updateVehiclesStatuses,
    exportVehiclesService,
    postVehicles,
    postImportVehicles,
    getVehicle,
    patchVehicle,
    getAllVehicles,
    getVehicleStateService,
    sendCommandService,
} from './service';
import { downloadBlob } from 'tools/file';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { Pagination, Sort } from '../../common/types';
import { Filters } from './types';
import { history } from '../../root';
import { redirectConfig } from '../../../constants/redirectConfig';
import { selectFetchAll } from './selectors';
import { VehicleCommands } from './types';

function* fetchVehiclesSaga({
    payload,
}: PayloadAction<{
    isRefreshCheck?: boolean;
}>) {
    if (payload?.isRefreshCheck === true) {
        return;
    }
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const fetchAll: boolean = yield select(selectFetchAll);
        if (fetchAll) {
            const data: Content[] = yield call(getAllVehicles, {
                ...mapSorting(sort),
                ...mapFilters(filters),
            });
            const { vehicleIds, vehiclesById } = mapFetchedVehicles(data);
            yield put(
                fetchVehiclesSuccess({
                    vehicleIds,
                    vehiclesById,
                }),
            );
        } else {
            const pagination: Pagination = yield select(selectPagination);
            const data: VehicleResponse = yield call(getVehicles, {
                ...mapSorting(sort),
                ...mapPagination(pagination),
                ...mapFilters(filters),
            });
            const { totalPages, totalElements, size, number } = data;
            const { vehicleIds, vehiclesById } = mapFetchedVehicles(
                data.content,
            );
            yield put(
                fetchVehiclesSuccess({
                    vehicleIds,
                    vehiclesById,
                    pagination: { size, totalPages, totalElements, number },
                }),
            );
        }
    } catch (error) {
        yield put(fetchVehiclesError({ error: (error as Error)?.message }));
    }
}
function* updateVehiclesStatusSaga() {
    try {
        const selected: number[] = yield select(selectSelectedVehicles);
        const newStatus: VehicleStatus | null = yield select(selectNewStatus);
        yield call(updateVehiclesStatuses, {
            vehicles: selected,
            status: newStatus,
        });
        yield put(updateVehiclesStatusSuccess());
        yield put(fetchVehicles());
    } catch (error) {
        yield put(
            updateVehiclesStatusError({ error: (error as Error)?.message }),
        );
    }
}
function* updateVehicleSaga({
    payload,
}: PayloadAction<{
    vehicleId: number;
    status: VehicleStatus;
    services: number[];
    lockQrCode?: string;
    model?: string;
    stationType?: string;
    adapter?: string;
}>) {
    try {
        const data: Content = yield call(patchVehicle, { ...payload });
        yield put(updateVehicleSuccess(mapVehicle(data)));
    } catch (error) {
        yield put(updateVehicleError({ error: (error as Error)?.message }));
    }
}
function* exportVehiclesSaga() {
    try {
        const filters: Filters = yield select(selectFilters);
        const sort: Sort = yield select(selectSort);
        const { file, fileName } = yield call(exportVehiclesService, {
            ...mapSorting(sort),
            ...mapFilters(filters),
        });
        downloadBlob(file, fileName);
        yield put(exportVehiclesSuccess());
    } catch (error) {
        yield put(exportVehiclesError({ error: (error as Error)?.message }));
    }
}

function* createVehicleSaga({ payload }: PayloadAction<NewVehicle[]>) {
    try {
        yield call(postVehicles, payload);
        yield put(createVehicleSuccess());
        const pathname = history.location.pathname;
        yield put(push(redirectConfig[pathname]));
    } catch (error) {
        yield put(createVehicleError({ error: (error as Error)?.message }));
    }
}
function* fetchVehicleSaga({ payload }: PayloadAction<number>) {
    try {
        const data: Content = yield call(getVehicle, payload);
        yield put(fetchVehicleSuccess(mapVehicle(data)));
    } catch (error) {
        yield put(fetchVehicleError({ error: (error as Error)?.message }));
    }
}

function* importVehiclesSaga({ payload }: PayloadAction<{ file: FormData }>) {
    try {
        yield call(postImportVehicles, payload);
        yield put(importVehiclesSuccess());
        const pathname = history.location.pathname;
        yield put(push(redirectConfig[pathname]));
    } catch (error) {
        yield put(importVehiclesError({ error: (error as Error)?.message }));
    }
}

function* getVehicleStateSaga({
    payload,
}: PayloadAction<{ vehicleId: number }>) {
    try {
        const data: CurrentVehicleState = yield call(
            getVehicleStateService,
            payload.vehicleId,
        );
        yield put(getVehicleStateSuccess(data));
    } catch (error) {
        yield put(getVehicleStateError({ error: (error as Error)?.message }));
    }
}

function* sendCommandSaga({
    payload,
}: PayloadAction<{ command: VehicleCommands; vehicleId: number }>) {
    try {
        const data: CurrentVehicleState = yield call(
            sendCommandService,
            payload,
        );
        yield put(sendCommandSuccess(data));
        yield put(fetchVehicle(payload.vehicleId));
    } catch (error) {
        yield put(sendCommandError({ error: (error as Error)?.message }));
    }
}

export default function* vehiclesSaga() {
    yield takeLatest(fetchVehicles.type, fetchVehiclesSaga);
    yield takeLatest(setStringFilter.type, fetchVehiclesSaga);
    yield takeLatest(setCurrentVehicleType.type, fetchVehiclesSaga);
    yield takeLatest(setSort.type, fetchVehiclesSaga);
    yield takeLatest(setPage.type, fetchVehiclesSaga);
    yield takeLatest(updateVehiclesStatus.type, updateVehiclesStatusSaga);
    yield takeLatest(exportVehicles.type, exportVehiclesSaga);
    yield takeLatest(createVehicle.type, createVehicleSaga);
    yield takeLatest(importVehicles.type, importVehiclesSaga);
    yield takeLatest(fetchVehicle.type, fetchVehicleSaga);
    yield takeLatest(updateVehicle.type, updateVehicleSaga);
    yield takeLatest(getVehicleState.type, getVehicleStateSaga);
    yield takeLatest(sendCommand.type, sendCommandSaga);
}
