import { authSagas } from 'ducks/auth';
import { serviceSagas } from 'ducks/services';
import { companySagas } from 'ducks/companies';
import { all, call } from 'redux-saga/effects';
import { zoneSagas } from '../zones';
import { boUsersSagas } from '../boUsers';
import { tripSagas } from 'ducks/trips';
import { userSagas } from 'ducks/users';
import { stationsSagas } from 'ducks/stations';
import { eventSagas } from 'ducks/events';
import { vehicleSagas } from '../vehicles/common';
import { groupSagas } from 'ducks/groups';
import { scooterMaintenanceSagas } from 'ducks/scooterMaintenance';
import { agreementsSaga } from 'ducks/agreements';
import { vehicleTelemetryEventsSagas } from 'ducks/vehicleTelemetryEvents';
import { newsSagas } from 'ducks/news';
import { partnersSagas } from 'ducks/partners';
import { appVersionsSagas } from 'ducks/application';
import { lockSagas } from 'ducks/locks';
import { pushSagas } from 'ducks/pushes';
import { logsSagas } from 'ducks/logs';
import { paymentSagas } from 'ducks/paymentHistory';
import { bookingSagas } from 'ducks/booking';

export default function* rootSaga() {
    yield all([
        call(bookingSagas),
        call(paymentSagas),
        call(authSagas),
        call(serviceSagas),
        call(companySagas),
        call(newsSagas),
        call(zoneSagas),
        call(boUsersSagas),
        call(tripSagas),
        call(userSagas),
        call(eventSagas),
        call(stationsSagas),
        call(vehicleSagas),
        call(groupSagas),
        call(scooterMaintenanceSagas),
        call(agreementsSaga),
        call(appVersionsSagas),
        call(vehicleTelemetryEventsSagas),
        call(partnersSagas),
        call(lockSagas),
        call(pushSagas),
        call(logsSagas),
    ]);
}
