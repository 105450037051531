import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commonSetSort, defaultPagination } from 'ducks/common/pagination';
import { SortDirection } from 'ducks/common/types';
import { BookingState, FilterTypes } from './types';

const defaultFilters = {};

export const initialState: BookingState = {
    error: '',
    loading: false,
    bookingIds: [],
    bookingById: {},
    sort: {
        value: 'startTime',
        direction: SortDirection.Desc,
    },
    filters: defaultFilters,
    pagination: defaultPagination,
};

const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        fetchVehicleBooking(state) {
            state.loading = true;
            state.error = '';
        },
        fetchVehicleBookingSuccess(
            state,
            { payload: { bookingById, pagination, bookingIds } },
        ) {
            state = {
                ...state,
                bookingById,
                pagination,
                bookingIds,
                loading: false,
            };

            return state;
        },
        fetchVehicleBookingError(state, action) {
            state.error = action.payload.error;
            state.loading = false;
        },
        resetVehicleBooking(state) {
            state = { ...initialState };
        },
        setPage(state, { payload }: PayloadAction<{ page: number }>) {
            state.pagination.number = payload.page;
            state.loading = true;
        },
        setSort(
            state,
            {
                payload,
            }: PayloadAction<{
                value: string;
            }>,
        ) {
            commonSetSort(state, payload.value);
            state.loading = true;
        },
        setFilter(
            state,
            {
                payload,
            }: PayloadAction<{
                filterType: FilterTypes;
                value: string | string[] | boolean;
            }>,
        ) {
            state.filters = {
                ...state.filters,
                [payload.filterType]: payload.value,
            };
            state.pagination = defaultPagination;
            state.loading = true;
        },
        resetFilters(state) {
            state.filters = defaultFilters;
            state.pagination = defaultPagination;
        },
        requestExportBooking(state) {
            state.loading = true;
            state.error = '';
        },
        requestExportBookingSuccess(state) {
            state.loading = false;
            state.error = '';
        },
        requestCancelBooking: {
            reducer: state => {
                state.loading = true;
                state.error = '';
            },
            prepare: bookingId => ({
                payload: bookingId,
            }),
        },
        requestCancelBookingSuccess(state) {
            state.loading = false;
            state.error = '';
        },
    },
});

export const {
    setSort,
    setPage,
    setFilter,
    fetchVehicleBooking,
    fetchVehicleBookingSuccess,
    fetchVehicleBookingError,
    resetVehicleBooking,
    resetFilters,
    requestExportBooking,
    requestExportBookingSuccess,
    requestCancelBooking,
    requestCancelBookingSuccess,
} = bookingSlice.actions;

export const bookingReducer = bookingSlice.reducer;
