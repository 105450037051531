import { createStyles, Box, Theme, withStyles } from '@material-ui/core';
import { colors } from 'themes/colors';

export const DetailPanel = withStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '20px',
            backgroundColor: colors.white,
            boxShadow: '0 0 5px rgba(0,0,0,0.3)',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            minWidth: 'fit-content',
            position: 'relative',
            fontSize: theme.typography.body1.fontSize,
            '& > div:nth-child(2n)': {
                backgroundColor: '#f0f0f0a1',
            },
        },
    }),
)(Box);
