import { createSelector } from 'reselect';
import { RootState } from '../root';
import { getTableId } from './helpers';
import { defaultPaymentPagination, defaultPaymentSort } from './paymentSlice';

export const selectPaymentHistory = (state: RootState) => state.paymentHistory;

export const selectPaymentHistoryError = (state: RootState) =>
    selectPaymentHistory(state).error;

export const selectPaymentHistoryTables = (state: RootState) =>
    selectPaymentHistory(state).tables;

export const selectPaymentHistoryIsLoading = (state: RootState) =>
    selectPaymentHistory(state).loading;

export const selectPaymentHistorySort = (
    state: RootState,
    userId: number,
    profileId: number,
) =>
    selectPaymentHistoryTables(state)[userId + '/' + profileId]?.sort ||
    defaultPaymentSort;

export const selectPaymentHistoryPagination = (
    state: RootState,
    userId: number,
    profileId: number,
) =>
    selectPaymentHistoryTables(state)[getTableId(userId, profileId)]
        ?.pagination || defaultPaymentPagination;

export const selectPaymentHistoryIds = (
    state: RootState,
    userId: number,
    profileId: number,
) =>
    selectPaymentHistoryTables(state)[getTableId(userId, profileId)]
        ?.paymentIds || [];

export const selectPaymentHistoryById = (
    state: RootState,
    userId: number,
    profileId: number,
) =>
    selectPaymentHistoryTables(state)[getTableId(userId, profileId)]
        ?.paymentsById || {};

export const selectPaymentHistoryTableData = createSelector(
    selectPaymentHistoryIds,
    selectPaymentHistoryById,
    (paymentIds, paymentsById) =>
        paymentIds.map(id => ({
            ...paymentsById[id],
            amount: Number(paymentsById[id]?.amount || 0) / 100,
        })),
);
