export function downloadBlob(blob: Blob, filename: string) {
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
    a.remove();
}

export function getFileFromResponse(response: any) {
    const fileName = response.headers['content-disposition']
        .split(';')
        .find((content: any) => content.includes('filename='))
        .replace('filename=', '')
        .trim();
    return {
        file: new Blob([response.data], {
            type: 'text/csv',
        }),
        fileName,
    };
}
export function getFileToDisplayFromResponse(response: any, filename: string) {
    const filetype = response.headers['content-type'].split(';')[0];
    if (filetype !== 'application/octet-stream') {
        const base64 = btoa(
            new Uint8Array(response.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                '',
            ),
        );
        return {
            file: null,
            url: 'data:;base64,' + base64,
            type: filetype,
            filename: filename,
        };
    }

    return {
        file: null,
        url: URL.createObjectURL(
            new Blob([response.data], {
                type: 'application/pdf',
            }),
        ),
        type: 'application/pdf',
        filename: filename,
    };
}

export const generateUniqueFileName = () =>
    Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 30);
