import React from 'react';
import { useDispatch } from 'react-redux';
import {
    importInviteUsers,
    setInvitationHasUnsavedChanges,
} from 'ducks/companies';
import { useParams } from 'react-router';
import { ImportComponent } from '../../../common/ImportComponent';

export const ImportUsersComponent: React.FC = () => {
    const { serviceId } = useParams<{ serviceId: string }>();

    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = React.useState<
        File | undefined | null
    >();
    React.useEffect(() => {
        selectedFile && dispatch(setInvitationHasUnsavedChanges(true));
    }, [dispatch, selectedFile]);
    const handleImport = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile, selectedFile.name);
            dispatch(importInviteUsers({ file: formData, serviceId }));
        }
    };

    return (
        <ImportComponent
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            handleImport={handleImport}
        />
    );
};
