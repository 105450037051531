import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'ducks/root';
import { Form, FormSpy } from 'react-final-form';
import {
    ButtonComponent,
    FormGrid,
    TextField,
    SelectComponent,
    ThemedBoxComponent,
} from 'components/common';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save';
import { Box, Typography } from '@material-ui/core';
import { CurrentUserProfile } from 'ducks/users/types';
import {
    anonymizeProfile,
    setProfileHasUnsavedChanges,
    toggleUserProfileStatus,
    updateUserProfile,
    addEndUserPenalty,
    deleteCreditCard,
} from 'ducks/users';
import { closeModal, Modals, openModal } from 'ducks/ui';
import {
    selectIsUserLoading,
    selectUserProfileErrorById,
} from 'ducks/users/selectors';
import { ProfileServicesList } from './ProfileServicesList';
import { validations } from './validations';
import { Permissions } from 'ducks/groups/types';
import { selectAccessToPages } from 'ducks/auth';
import { useParams } from 'react-router';
import { FormButtonGroup } from '../../common/ButtonGroup';
import { LoaderComponent } from '../../common/Loader';
import { ErrorComponent } from '../../common/Error';
import { getStatusOptions } from './getStatusOptions';
import { PaymentTable } from './PaymentTable';

export type PenaltyConfirm = {
    amount: string;
    comment: string;
    tripId: string;
};

type UserProfilesProps = {
    profile: CurrentUserProfile;
};

export const UserProfiles: React.FC<UserProfilesProps> = ({
    profile,
}: UserProfilesProps) => {
    const { userId } = useParams<{ userId?: string }>();

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [servicesForDeletion, setServiceForDeletion] = useState([]);
    const loading = useSelector(selectIsUserLoading);
    const { id: profileId, status } = profile;
    const error = useSelector((state: RootState) =>
        selectUserProfileErrorById(state, profileId),
    );
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToDelete = accessToPages[Permissions.PROFILE_DELETE];
    const allowedToEdit = accessToPages[Permissions.PROFILE_UPDATE];
    const allowedToUpdateStatus =
        accessToPages[Permissions.PROFILE_UPDATE_STATUS];
    const statusSelectOptions = useMemo(() => getStatusOptions(t), [t]);

    const isCardNotExist = !profile?.cardDetails;
    const cardAlias = profile?.cardDetails?.alias || '-';
    const cardExpirationDate = profile?.cardDetails?.expirationDate || '-';

    const handleDeleteCard = useCallback(() => {
        dispatch(deleteCreditCard(userId, profileId));
    }, [dispatch, profileId, userId]);

    const handleConfirm = useCallback(
        (values: CurrentUserProfile) => {
            dispatch(updateUserProfile(values, servicesForDeletion));
        },
        [dispatch, servicesForDeletion],
    );

    const setUnsavedChanges = useCallback(
        ({ pristine }) => dispatch(setProfileHasUnsavedChanges(!pristine)),
        [dispatch],
    );

    const handleAnonymize = useCallback(() => {
        dispatch(anonymizeProfile(profileId));
    }, [dispatch, profileId]);

    const handleSelectStatus = useCallback(
        event => {
            dispatch(
                openModal({
                    modalType: Modals.CHANGE_CONFIRMATION,
                    modalProps: {
                        handleConfirm: () => {
                            dispatch(
                                toggleUserProfileStatus(
                                    userId,
                                    profile.id,
                                    event.target.value,
                                ),
                            );
                            dispatch(closeModal());
                        },
                        message: t('backofficeUsers.changeStatusMessage'),
                    },
                }),
            );
        },
        [dispatch, userId, profile, t],
    );

    const toggleEnablePenaltyModal = useCallback(() => {
        dispatch(
            openModal({
                modalType: Modals.ADD_PENALTY,
                modalProps: {
                    handleConfirm: ({
                        amount,
                        comment,
                        tripId,
                    }: PenaltyConfirm) => {
                        dispatch(
                            addEndUserPenalty({
                                userId,
                                comment,
                                amount,
                                profileId,
                                tripId,
                            }),
                        );
                        dispatch(closeModal());
                    },
                    message: t('usersList.addPenalty'),
                    userId,
                },
            }),
        );
    }, [dispatch, profileId, t, userId]);

    return (
        <Form
            onSubmit={handleConfirm}
            initialValues={profile}
            render={({ handleSubmit, valid, pristine }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{ pristine: true }}
                            onChange={setUnsavedChanges}
                        />
                        <ThemedBoxComponent>
                            {loading && <LoaderComponent />}
                            <FormGrid columnAmount={1} rowHeight="50px">
                                <Box>
                                    <Typography variant="h3" align="left">
                                        {t('usersList.profile.type')}
                                    </Typography>
                                    <Typography align="left">
                                        {profile.type}
                                    </Typography>
                                </Box>
                                {!isCardNotExist && (
                                    <ThemedBoxComponent>
                                        <Typography variant="h2">
                                            {t('usersList.profile.cardInfo')}
                                        </Typography>
                                        <FormGrid columnAmount={2}>
                                            <Box>
                                                <Typography variant="h3">
                                                    {t(
                                                        'usersList.profile.expirationDate',
                                                    )}
                                                </Typography>
                                                <Typography>
                                                    {cardExpirationDate}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="h3">
                                                    {t(
                                                        'usersList.profile.alias',
                                                    )}
                                                </Typography>
                                                <Typography>
                                                    {cardAlias}
                                                </Typography>
                                            </Box>
                                        </FormGrid>
                                    </ThemedBoxComponent>
                                )}
                                <FormGrid columnAmount={2} rowHeight="50px">
                                    <Box mt="auto" mb="auto">
                                        <SelectComponent
                                            label={t('status')}
                                            onChange={handleSelectStatus}
                                            value={status}
                                            options={statusSelectOptions}
                                            disabled={!allowedToUpdateStatus}
                                        />
                                    </Box>
                                    <TextField
                                        name="email"
                                        disabled={!allowedToEdit}
                                        validators={validations.profileEmail}
                                        label={t('usersList.profile.email')}
                                        id={`email-${profileId}`}
                                        isRequired
                                    />
                                </FormGrid>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    marginTop="10px">
                                    <Box marginBottom="20px">
                                        <Typography variant="h3" align="left">
                                            {t('usersList.profile.serviceList')}
                                        </Typography>
                                    </Box>
                                    {profile.subscriptions.length > 0 ? (
                                        <ProfileServicesList
                                            profile={profile}
                                            setServiceForDeletion={
                                                setServiceForDeletion
                                            }
                                            servicesForDeletion={
                                                servicesForDeletion
                                            }
                                        />
                                    ) : (
                                        <Typography align="left">-</Typography>
                                    )}
                                </Box>
                                <ErrorComponent error={error} />

                                <FormButtonGroup>
                                    {allowedToDelete && (
                                        <Box mb="auto">
                                            <ButtonComponent
                                                handleClick={handleAnonymize}
                                                text={t('company.anonymize')}
                                            />
                                        </Box>
                                    )}
                                    {allowedToEdit && (
                                        <Box mb="auto">
                                            <ButtonComponent
                                                handleClick={handleSubmit}
                                                text={t('save')}
                                                startIcon={<SaveIcon />}
                                                isDisabled={
                                                    servicesForDeletion.length ===
                                                        0 &&
                                                    (pristine ||
                                                        !valid ||
                                                        loading)
                                                }
                                            />
                                        </Box>
                                    )}
                                    {allowedToEdit && (
                                        <Box mb="auto">
                                            <ButtonComponent
                                                handleClick={
                                                    toggleEnablePenaltyModal
                                                }
                                                text={t('usersList.addPenalty')}
                                                isDisabled={isCardNotExist}
                                            />
                                        </Box>
                                    )}
                                    {allowedToEdit && (
                                        <Box mb="auto">
                                            <ButtonComponent
                                                handleClick={handleDeleteCard}
                                                text={t(
                                                    'usersList.deleteCreditCard',
                                                )}
                                                isDisabled={isCardNotExist}
                                            />
                                        </Box>
                                    )}
                                </FormButtonGroup>
                            </FormGrid>
                            <FormGrid columnAmount={1}>
                                <PaymentTable
                                    userId={Number(userId)}
                                    profileId={profileId}
                                />
                            </FormGrid>
                        </ThemedBoxComponent>
                    </form>
                );
            }}
        />
    );
};
