import React from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchVehicle,
    selectIsLoading,
    selectVehicleError,
} from 'ducks/vehicles/common';
import { FormGrid, ThemedBoxComponent } from '../../../common';
import { Box } from '@material-ui/core';
import { NIUActionsComponent } from './NIUActions';
import { NIUInfoComponent } from './NIUInfo';
import { VehicleUpdateForm } from 'components/pages/VehicleDetails/VehicleUpdateForm';
import { LoaderComponent } from 'components/common/Loader';
import { ErrorComponent } from '../../../common/Error';
import { MaintenanceList } from './MaintenanceList';

export const BasicInformation: React.FC = () => {
    const { vehicleId } = useParams<{ vehicleId: string }>();

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchVehicle(vehicleId));
    }, [dispatch, vehicleId]);
    const loading = useSelector(selectIsLoading);

    const error = useSelector(selectVehicleError);

    return (
        <>
            <FormGrid columnAmount={2}>
                {loading && <LoaderComponent />}
                <ThemedBoxComponent>
                    <VehicleUpdateForm />
                </ThemedBoxComponent>
                <ThemedBoxComponent>
                    <NIUActionsComponent />
                    <NIUInfoComponent />
                </ThemedBoxComponent>
            </FormGrid>
            <FormGrid columnAmount={1}>
                <MaintenanceList />
            </FormGrid>
            <Box textAlign="center">
                <ErrorComponent error={error} />
            </Box>
        </>
    );
};
