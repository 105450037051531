import { Event } from './types';
import { Content } from './types';
import { Accumulator } from '../common/types';

export const mapEvent = ({
    boUser,
    event,
    endUser,
    profiles,
}: Content): Event => {
    return {
        ...event,
        boUserId: boUser?.id,
        boUserEmail: boUser?.email || '',
        endUserId: endUser?.id,
        endUsers: profiles?.map(
            ({ email, type }) =>
                ({
                    email,
                    type,
                } || []),
        ),
    };
};

export const mapFetchedEvents = (content: Content[]) => {
    return content.reduce<Accumulator<Event>>(
        (acc, item) => {
            return {
                byId: {
                    ...acc.byId,
                    [item.event.id]: mapEvent(item),
                },
                allIds: [...acc.allIds, item.event.id],
            };
        },
        { allIds: [], byId: {} },
    );
};
