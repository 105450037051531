import React from 'react';
import { ButtonComponent, FormGrid, SearchComponent } from 'components/common';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { requestExportBooking, setFilter } from 'ducks/booking';
import { FilterTypes } from 'ducks/booking/types';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { selectFilters } from 'ducks/booking/selectors';

const SearchBar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);

    const handleExport = () => {
        dispatch(requestExportBooking());
    };
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToExport = accessToPages[Permissions.TRIP_EXPORT];

    const handleEmailSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(
                setFilter({
                    filterType: FilterTypes.Email,
                    value: e.target.value,
                }),
            );
        },
        [dispatch],
    );

    return (
        <FormGrid columnAmount={2} padding="10px 0" rowHeight="20px">
            <SearchComponent
                id="emailSearch"
                placeholder={t('vehicles.emailPlaceholder')}
                onChange={handleEmailSearch}
                defaultValue={filters[FilterTypes.Email]}
            />
            {allowedToExport && (
                <Box textAlign="right">
                    <ButtonComponent
                        text={t('download')}
                        startIcon={<SaveAltIcon />}
                        handleClick={handleExport}
                    />
                </Box>
            )}
        </FormGrid>
    );
};

export default SearchBar;
