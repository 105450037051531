import React from 'react';
import { ButtonComponent, SelectComponent } from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectFilters,
    selectNewStatus,
    selectSelectedVehicles,
    setNewStatus,
    setStringFilter,
    StringFilterTypes,
    updateVehiclesStatus,
} from 'ducks/vehicles/common';
import { useTranslation } from 'react-i18next';
import {
    vehicleAvailableStatusMap,
    VehicleStatus,
} from 'ducks/vehicles/common/types';
import { Box } from '@material-ui/core';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';

export const UpdateVehicleStatusComponent = () => {
    const dispatch = useDispatch();
    const selected = useSelector(selectSelectedVehicles);
    const newStatus = useSelector(selectNewStatus);
    const { t } = useTranslation();
    const filters = useSelector(selectFilters);
    const statusFilter = filters[StringFilterTypes.Status];
    const accessToPages = useSelector(selectAccessToPages);
    const allowedToEdit = accessToPages[Permissions.VEHICLE_UPDATE_STATUS];
    const statusOptions = React.useMemo(
        () => [
            {
                label: '-',
                value: '',
            },
            ...Object.values(VehicleStatus).map(status => ({
                label: t(`scooterStatuses.${status}`),
                value: status,
            })),
        ],
        [t],
    );
    const setStatusFilter = React.useCallback<
        React.ChangeEventHandler<HTMLInputElement>
    >(
        e =>
            dispatch(
                setStringFilter({
                    filterType: StringFilterTypes.Status,
                    value: e.target.value,
                }),
            ),
        [dispatch],
    );
    const updateStatus = React.useCallback(() => {
        dispatch(updateVehiclesStatus());
    }, [dispatch]);
    const newStatusOptions = React.useMemo(
        () =>
            (statusFilter &&
                vehicleAvailableStatusMap[statusFilter]
                    .map((status: VehicleStatus) => ({
                        label: t(`scooterStatuses.${status}`),
                        value: status,
                    }))
                    .filter(value => value.value !== statusFilter)) ||
            [],
        [statusFilter, t],
    );

    const setNewStatusFilter = React.useCallback(
        e => dispatch(setNewStatus(e.target.value)),
        [dispatch],
    );

    return (
        <>
            <SelectComponent
                label={t('vehicles.status')}
                onChange={setStatusFilter}
                value={statusFilter || ''}
                options={statusOptions}
            />
            {allowedToEdit &&
                !!newStatusOptions.length &&
                filters[StringFilterTypes.Status] && (
                    <SelectComponent
                        onChange={setNewStatusFilter}
                        value={newStatus || ''}
                        label={t('vehicles.newStatus')}
                        options={newStatusOptions}
                    />
                )}
            {allowedToEdit && newStatus && !!selected.length && (
                <Box m="15px 10px 0">
                    <ButtonComponent
                        text={t('update')}
                        handleClick={updateStatus}
                    />
                </Box>
            )}
        </>
    );
};
