import React, { Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'ducks/root';
import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MapComponent } from '../../common/Map';
import { Polygon } from '@react-google-maps/api';
import { useStyles } from '../../common/ModalContainer/styles';

export type ZoneMap = {
    zoneId: number;
    handleClose: () => void;
};
// eslint-disable-next-line react/display-name
export const ZoneMapComponent = React.forwardRef(
    ({ zoneId, handleClose }: ZoneMap, ref: Ref<HTMLDivElement>) => {
        const classes = useStyles();
        const { t } = useTranslation();

        const { zonesById } = useSelector((state: RootState) => state.zone);

        const currentZone = {
            ...zonesById[zoneId],
        };

        return (
            <div ref={ref} className={classes.modal}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h1">{`${t('zones.border')} ${
                        currentZone.name
                    }`}</Typography>
                    <Box textAlign="right">
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <MapComponent boundCoordinates={currentZone.border}>
                    <Polygon
                        path={currentZone.border}
                        options={{
                            fillOpacity: currentZone.fillColor
                                ? 1 - currentZone.transparency / 100
                                : 0,
                            strokeColor: currentZone.borderColor,
                            fillColor: currentZone.fillColor,
                        }}
                    />
                </MapComponent>
            </div>
        );
    },
);
