import { RootState } from '../root';

export const selectFilters = (state: RootState) => state.service.filters;
export const selectSort = (state: RootState) => state.service.sort;
export const selectIsLoading = (state: RootState) => state.service.loading;
export const selectCurrentService = (state: RootState) =>
    state.service.currentService;
export const selectCurrentServiceSchedule = (state: RootState) =>
    state.service.currentServiceSchedule;
export const selectServiceError = (state: RootState) => state.service.error;
export const selectPagination = (state: RootState) => state.service.pagination;
export const selectServiceHasUnsavedChanges = (state: RootState) =>
    state.service.serviceHasUnsavedChanges;
export const selectServiceIds = (state: RootState) => state.service.serviceIds;
export const selectServicesById = (state: RootState) =>
    state.service.servicesById;
export const selectAllServicesById = (state: RootState) =>
    state.service.allServicesById;
export const selectAllServicesIds = (state: RootState) =>
    state.service.allServiceIds;
