import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureAgreements } from 'ducks/agreements/selectors';
import { updatePolicy } from 'ducks/agreements';
import { FutureForm } from './FutureForm';
import { Languages } from 'ducks/boUsers/types';
import { Agreement, InitialAgreement } from 'ducks/agreements/types';

export const FuturePolicyForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { policy } = useSelector(selectFeatureAgreements);

    const handleUpdatePolicy = useCallback(
        (values: Agreement | InitialAgreement) => {
            dispatch(updatePolicy(values));
        },
        [dispatch],
    );

    const initialValues = useMemo(
        () =>
            policy && Object.keys(policy).length
                ? policy
                : { links: [{ language: Languages.EN, url: '' }], version: '' },
        [policy],
    );

    return (
        <FutureForm
            title={t('legalDocuments.policy')}
            initialValues={initialValues}
            handleUpdate={handleUpdatePolicy}
            prefix="policy"
        />
    );
};
