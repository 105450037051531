import React, { useMemo } from 'react';
import { VehicleDetails } from '../VehicleDetails';
import { BasicInformation } from './BasicInformation';
import { ScootersEventsListComponent } from '../ScooterEvents';
import { routes } from 'constants/common';
import { ScooterMaintenanceList } from '../ScooterMaintenanceList';
import { UpdateLockContainer } from '../UpdateLock/UpdateLockContainer';
import {
    selectCurrentVehicle,
    selectCurrentVehicleLockId,
} from 'ducks/vehicles/common';
import { useSelector } from 'react-redux';
import { BikeAndVaeTelemetryEvents } from '../TelemetryEvents/BikesAndVaeTelemetryEvents';
import { TripsList } from '../TripsList';
import { selectAccessToPages } from 'ducks/auth';
import { Permissions } from 'ducks/groups/types';
import { VehicleBookingList } from '../VehicleBooking';
import { VehicleTypes } from 'ducks/vehicles/common/types';

export const BikeAndVaeDetails = () => {
    const lockId = useSelector(selectCurrentVehicleLockId);
    const accessToPages = useSelector(selectAccessToPages);

    const currentVehicle = useSelector(selectCurrentVehicle);
    const isVae = currentVehicle?.type === VehicleTypes.VAE;

    const { tabs, titles } = useMemo(() => {
        const tabs: (
            | React.FC<Record<string, unknown>>
            | (() => JSX.Element)
        )[] = lockId
            ? [
                  BasicInformation,
                  ScootersEventsListComponent,
                  ScooterMaintenanceList,
                  UpdateLockContainer,
                  BikeAndVaeTelemetryEvents,
              ]
            : [
                  BasicInformation,
                  ScootersEventsListComponent,
                  ScooterMaintenanceList,
                  BikeAndVaeTelemetryEvents,
              ];
        const titles = lockId
            ? [
                  'vehicles.basicInformation',
                  'vehicles.scooterEvents',
                  'vehicles.scooterMaintenance',
                  'vehicles.lock',
                  'vehicles.bikeAndVaeTelemetryEvents',
              ]
            : [
                  'vehicles.basicInformation',
                  'vehicles.scooterEvents',
                  'vehicles.scooterMaintenance',
              ];

        if (accessToPages[Permissions.TRIP_VIEW]) {
            if (isVae) {
                tabs.push(VehicleBookingList);
                titles.push('vehicles.bookingList');
            }
            tabs.push(TripsList);
            titles.push('vehicles.scooterTrips');
        }

        return { tabs, titles };
    }, [accessToPages, lockId, isVae]);

    return (
        <VehicleDetails
            vehicleTabs={tabs}
            vehicleTitles={titles}
            listRout={routes.BIKES_AND_VAE}
        />
    );
};
