import { TableComponent } from '../../common';
import React, { Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectFilters,
    selectIsLoading,
    selectSort,
    selectVehiclesTableData,
    setCurrentVehicleType,
    setSelectedVehicles,
    StringFilterTypes,
} from 'ducks/vehicles/common';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { vehicleRoutes } from 'constants/common';
import { Toolbar } from './Toolbar';
import { SearchToolbar } from './SearchToolbar';
import { Pagination } from './Pagination';
import { Vehicle, VehicleTypes } from 'ducks/vehicles/common/types';
import { Action } from 'components/common/Table';
import { TFunction } from 'i18next';
import { ColumnType } from 'components/common/Table/column';
import { generatePath } from 'react-router';
import { FullScreenLoaderComponent } from '../../common/Loader/FullScreenLoader';

type VehiclesProps = {
    getColumns: (t: TFunction, dispatch: Dispatch<any>) => Array<ColumnType>;
    vehicleTypes: VehicleTypes[];
    title: string;
};

export const VehiclesListComponent: React.FC<VehiclesProps> = ({
    getColumns,
    vehicleTypes,
    title,
}: VehiclesProps) => {
    const dispatch = useDispatch();
    const setSelected = React.useCallback(
        (values: number[]) => {
            dispatch(setSelectedVehicles(values));
        },
        [dispatch],
    );

    const { t } = useTranslation();

    const redirectToVehicle = React.useCallback(
        (vehicle: Vehicle) =>
            dispatch(
                push(
                    generatePath(vehicleRoutes[vehicle.type], {
                        vehicleId: vehicle.id || '-',
                    }),
                ),
            ),
        [dispatch],
    );

    const sort = useSelector(selectSort);
    const filters = useSelector(selectFilters);
    const loading = useSelector(selectIsLoading);
    const columns = React.useMemo(
        () => getColumns(t, dispatch),
        [t, dispatch, getColumns],
    );
    React.useEffect(() => {
        dispatch(setCurrentVehicleType({ types: vehicleTypes }));
    }, [dispatch, vehicleTypes]);

    const tableData = useSelector(selectVehiclesTableData);
    const actions = React.useMemo(
        (): Action<Vehicle>[] => [
            {
                icon: 'edit',
                tooltip: t('edit'),
                onClick: (event, rowData: Vehicle | undefined) =>
                    rowData && redirectToVehicle(rowData),
                position: 'row',
            },
        ],
        [redirectToVehicle, t],
    );
    const onSelectionChange = React.useCallback(
        (rows: Vehicle[]) => {
            setSelected(rows.map(({ id }) => id));
        },
        [setSelected],
    );

    const handleRowClick = useCallback(
        (event, rowData: Vehicle | undefined) =>
            rowData && redirectToVehicle(rowData),
        [redirectToVehicle],
    );

    return (
        <>
            {loading && <FullScreenLoaderComponent />}
            <TableComponent
                actions={actions}
                onRowClick={handleRowClick}
                toolbar={Toolbar}
                searchComponent={SearchToolbar}
                columns={columns}
                pagination={Pagination}
                data={tableData}
                title={title}
                sort={sort}
                selection={!!filters[StringFilterTypes.Status]}
                onSelectionChange={onSelectionChange}
            />
        </>
    );
};
