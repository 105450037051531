import { useField } from 'react-final-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel } from '@material-ui/core';

type Checkbox = {
    field: string;
    label: string;
    disabled?: boolean;
    handleChange?: (checked: boolean) => void;
};
export const CheckboxField: React.FC<Checkbox> = ({
    field,
    label,
    disabled,
    handleChange,
}: Checkbox) => {
    const { t } = useTranslation();
    const { input } = useField(field, {
        type: 'checkbox',
    });
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        input.onChange(e);
        handleChange && handleChange(e.target.checked);
    };
    return (
        <FormControlLabel
            control={<Checkbox {...input} onChange={onChange} />}
            label={t(label)}
            disabled={disabled}
        />
    );
};
