import React, { useEffect } from 'react';
import { FormGrid, ThemedBoxComponent } from '../../common';
import { VehicleUpdateForm } from 'components/pages/VehicleDetails/VehicleUpdateForm';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchVehicle,
    selectIsLoading,
    selectVehicleError,
} from 'ducks/vehicles/common';
import { useParams } from 'react-router';
import { ErrorComponent } from '../../common/Error';
import { LockDetails } from './LockDetails';
import { LoaderComponent } from '../../common/Loader';
import { selectIsLoading as selectMaintenanceIsLoading } from 'ducks/scooterMaintenance/selectors';
import { MaintenanceList } from '../ScooterDetails/BasicInformation/MaintenanceList';

export const BasicInformation = () => {
    const { vehicleId } = useParams<{ vehicleId?: string }>();
    const dispatch = useDispatch();
    const loading = useSelector(selectIsLoading);
    const maintenanceIsLoading = useSelector(selectMaintenanceIsLoading);
    const error = useSelector(selectVehicleError);

    useEffect(() => {
        dispatch(fetchVehicle(vehicleId));
    }, [dispatch, vehicleId]);

    return (
        <>
            <FormGrid columnAmount={2}>
                {(loading || maintenanceIsLoading) && <LoaderComponent />}
                <ThemedBoxComponent>
                    <VehicleUpdateForm />
                </ThemedBoxComponent>
                <ThemedBoxComponent>
                    <LockDetails />
                </ThemedBoxComponent>
                <Box mb="10px" textAlign="center">
                    <ErrorComponent error={error} />
                </Box>
            </FormGrid>
            <FormGrid columnAmount={1}>
                <MaintenanceList />
            </FormGrid>
        </>
    );
};
