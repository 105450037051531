import {
    BoUserResponse,
    Accumulator,
    EditUser,
    NewUser,
    FetchedBoUser,
} from './types';

export const prepareDataForUserCreation = (data: EditUser): NewUser => {
    return {
        ...data,
        companies: Array.isArray(data.companies)
            ? data.companies
            : [data.companies],
        groups: Array.isArray(data.groups) ? data.groups : [data.groups],
    };
};

export const mapFetchedBoUsers = ({ content }: BoUserResponse): Accumulator => {
    return content.reduce<Accumulator>(
        (acc, user) => {
            return {
                boUsersById: {
                    ...acc.boUsersById,
                    [user.user.id]: user,
                },
                boUserIds: [...acc.boUserIds, user.user.id],
            };
        },
        { boUserIds: [], boUsersById: {} },
    );
};

export const mapFetchedBoUser = ({ user, ...userData }: FetchedBoUser) => {
    return {
        user: {
            ...user,
            status: `${user.isEnabled}`,
        },
        ...userData,
    };
};
